import { teamNav } from '../urls';

export const displayName = 'Request application revision';

export const getActionButtons = (notification) => [
  {
    title: 'Revise my profile',
    url: teamNav.profile(notification.data.get('event_id'), notification.data.get('team_id')),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `We've looked at your application to ${notification.event} and concluded that it requires 
   revisions to satisfy basic eligibility criteria before it can be further evaluated. Please
   update your profile to satisfy all requirements.`;
