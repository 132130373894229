import * as eventsAPI from '../services/events';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const LOAD_FOR_EVENT = 'artifacts/LOAD_FOR_EVENT';
export const LOAD_FOR_EVENT_RESULT = 'artifacts/LOAD_FOR_EVENT_RESULT';

export const load = (eventId) => ({
  [RSAA]: {
    ...eventsAPI.loadArtifacts(eventId),
    types: [
      LOAD_FOR_EVENT,
      {
        type: LOAD_FOR_EVENT_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({
            eventId,
            ...normalize(payload.artifacts, [schemas.artifact]),
          })),
      },
      LOAD_FOR_EVENT_RESULT,
    ],
  },
});
