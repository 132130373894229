import {
  ToggleButton as ToggleButtonComponent,
  ToggleButtonGroup,
} from '@valid-eval/shared-react-components';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import uuid from 'uuid-random';

import useFormFieldState from './useFormFieldState';

type ToggleButtonProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
};

const ToggleButton = ({ name }: ToggleButtonProps) => {
  const id = useRef(uuid());
  const { control } = useFormFieldState(name);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ...fieldProps } }) => {
        return (
          <ToggleButtonGroup onChange={onChange} value={value} type="radio" {...fieldProps}>
            {/* @ts-expect-error The component incorrectly flags a boolean value as an error, but it functions correctly. */}
            <ToggleButtonComponent id={`${name}-${id.current}-true`} variant="on" value={true}>
              On
            </ToggleButtonComponent>
            {/* @ts-expect-error The component incorrectly flags a boolean value as an error, but it functions correctly. */}
            <ToggleButtonComponent id={`${name}-${id.current}-false`} variant="off" value={false}>
              Off
            </ToggleButtonComponent>
          </ToggleButtonGroup>
        );
      }}
    />
  );
};

export default ToggleButton;
