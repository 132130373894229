import { Button } from '@valid-eval/shared-react-components';
import TriageModal from './TriageModal';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { Judge, Team } from '../../shared/types';

type TriageProps = {
  entity: 'teams' | 'judges';
  selected: Judge[] | Team[];
  reload(): void;
};

const Triage = ({ entity, selected, reload }: TriageProps) => {
  const [showModal, openModal, closeModal] = useBooleanFlag(false);

  return (
    <>
      {Boolean(selected.length) && (
        <small className="text-muted fw-bold me-2">{selected.length} Selected</small>
      )}
      <Button className="me-2" onClick={openModal} id="triage-button">
        Triage
      </Button>
      <TriageModal
        show={showModal}
        close={closeModal}
        entity={entity}
        selected={selected}
        reload={reload}
      />
    </>
  );
};

export default Triage;
