import { Nav } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Styles from './SignUpNav.module.scss';
import { StepStatus } from './types';

export type SignUpNavItemProps = {
  disabled?: boolean;
  eventKey: number;
  id?: string;
  label: string;
  status?: StepStatus;
  subLabel?: string;
};

const SignUpNavItem = ({ eventKey, label, subLabel, status, disabled, id }: SignUpNavItemProps) => {
  const { t } = useTranslation();

  return (
    <Nav.Item id={id}>
      <Nav.Link eventKey={eventKey} disabled={disabled}>
        {status && (
          <i
            className={cx('fa-xl me-2', {
              'fa-regular fa-circle': status === 'notStarted' || status === 'inProgress',
              'fa-solid fa-circle-check': status === 'completed',
              'text-red01': status === 'inProgress',
              'text-green01': status === 'completed',
            })}
          ></i>
        )}
        <span className={Styles.NavLinkText}>
          <span>{t(label)}</span>
          {subLabel && (
            <>
              <br />
              <small>{subLabel}</small>
            </>
          )}
        </span>
        <i className="fa-solid fa-angles-right fa-lg"></i>
      </Nav.Link>
    </Nav.Item>
  );
};

export default SignUpNavItem;
