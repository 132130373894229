import { createSelector } from 'reselect';

import { Action, ActionTypes, CurrentRole } from 'data/actions/support';

export type State = {
  currentRole: CurrentRole;
  loading: boolean;
};

const initialState: State = {
  currentRole: {},
  loading: false,
};

export const support = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_ROLE:
      return { ...state, currentRole: action.payload };
    case ActionTypes.UNSET_CURRENT_ROLE:
      return { ...state, currentRole: {} };
    case ActionTypes.SEND_PENDING:
      return { ...state, loading: true };
    case ActionTypes.SEND_FULFILLED:
    case ActionTypes.SEND_REJECTED:
      return { ...state, loading: false };
  }
  return state;
};

export const getCurrentRole = createSelector(
  // Using any because this is the global redux state
  (state: any) => state.get('support'),
  (state: State) => {
    return state.currentRole;
  },
);

export const getSupportLoading = createSelector(
  // Using any because this is the global redux state
  (state: any) => state.get('support'),
  (state: State) => {
    return state.loading;
  },
);
