import { Form } from '@valid-eval/shared-react-components';
import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, getFormValues, reduxForm } from 'redux-form/immutable';
import MembersInviteFields from './MembersInviteFields';

function MembersInviteForm({ handleSubmit, existingEmails }) {
  return (
    <Form onSubmit={handleSubmit}>
      <FieldArray
        name="invitations"
        component={MembersInviteFields}
        existingEmails={existingEmails}
      />
      <button type="submit" className="d-none" />
    </Form>
  );
}

export default connect((state) => {
  const { existingEmails } = getFormValues('MembersInviteForm')(state)?.toJS?.() || {};
  return { existingEmails };
}, {})(
  reduxForm({
    form: 'MembersInviteForm',
    enableReinitialize: true,
  })(MembersInviteForm),
);
