/* eslint no-mixed-operators: 0 */
import { Form } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { Field } from 'redux-form/immutable';
import RadioListComponent from '../RadioList';
import styles from './RadioList.module.scss';

import { dynamicFieldValidation } from './utils';

export const RadioListFieldGroup = ({
  input,
  meta: { error, warning, touched },
  id,
  label,
  helpText,
  values,
  disabled,
}) => {
  useEffect(() => {
    const valuesArray = values?.toJSON?.() || [];
    // Try to find the selected value within the values
    // If not preset set it to null
    if (
      input.value &&
      valuesArray.length &&
      !valuesArray.find((option) => option.value === input.value)
    )
      input.onChange(null);
  }, [input.value, values]);

  return (
    <Form.Group className={styles.inputTitle} controlId={id}>
      {label && (
        <Form.Label>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <RadioListComponent
        {...input}
        values={values}
        disabled={disabled}
        meta={{ error, warning, touched }}
      />
    </Form.Group>
  );
};

export const RadioList = ({ id, name, title, helpText, values, ...otherProps }) => (
  <Field
    id={id || name}
    name={name}
    label={title}
    helpText={helpText}
    component={RadioListFieldGroup}
    values={values}
    validate={dynamicFieldValidation}
    {...otherProps}
  />
);
