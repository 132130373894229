import { Col, Row } from '@valid-eval/shared-react-components';
import SettingsToggleField from './SettingsToggleField';

const PhaseSettingsFlags = () => {
  return (
    <Row>
      <Col>
        <Row>
          <Col md="6" className="mb-4">
            <SettingsToggleField
              name="feedbackEnabled"
              label="Feedback & scores available to teams"
              onLabel={
                <>
                  On: Allow Teams to access feedback for this phase{' '}
                  <span className="text-decoration-underline">right now</span>.
                </>
              }
              offLabel="Off: Prevent teams from viewing feedback and scores for this phase."
            />
          </Col>
          <Col md="6" className="mb-4">
            <SettingsToggleField
              name="individualScoresEnabled"
              label="Individual judges' scores available to teams"
              onLabel={
                <>
                  On: Display both the overall scoring report(s) and an individual scoring report
                  from each Judge on the Team "Results" views{' '}
                  <span className="text-decoration-underline">right now</span>.
                </>
              }
              offLabel={
                'Off: Display only the overall scoring report(s) on the Team "Results" views.'
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-4">
            <SettingsToggleField
              name="performanceEnabled"
              label="Show relative performance information to Teams"
              onLabel="On: Show Teams a box plot displaying their scores relative to other Teams in this Phase, including in exported PDFs."
              offLabel="Off: Hide box plots from Teams in this Phase, including in exported PDFs."
            />
          </Col>
          <Col md="6" className="mb-4">
            <SettingsToggleField
              name="commentsEnabled"
              label="Comments: add & edit"
              onLabel={
                <>
                  On: Allow Judges to add and edit comments{' '}
                  <span className="text-decoration-underline">right now</span>.
                </>
              }
              offLabel="Off: Prevent Judges from editing and adding comments for this phase."
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="mb-4">
            <SettingsToggleField
              name="navigatorsViewResultsEnabled"
              label="Navigators: View results"
              onLabel="On: Allow Navigators to view results for this Phase."
              offLabel="Off: Prevent Navigators from viewing results for this phase."
            />
          </Col>
          <Col md="6" className="mb-4">
            <SettingsToggleField
              name="finalRank"
              label="Enable Program Rank"
              onLabel="On: Drag-n-drop team rows to yield a program-selected stack-ranking in the Results view."
              offLabel="Off: Use standard 'In/Out/Bubble' selections in the Results view."
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PhaseSettingsFlags;
