import * as schemas from '../schema';
import { loadTeamDrafts } from '../services/teamDrafts';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export enum ActionTypes {
  LOAD_TEAM_DRAFTS_REQUEST = 'teamDrafts/LOAD_TEAM_DRAFTS_REQUEST',
  LOAD_TEAM_DRAFTS_RESULT = 'teamDrafts/LOAD_TEAM_DRAFTS_RESULT',
}

export type ArtifactsCount = {
  completed: number;
  total: number;
};

export type TeamDetailsCount = {
  completed: number;
  total: number;
};

export type File = {
  url: string;
};

export type Data = {
  cohort_name: string;
  event_id: string;
  team_id: string;
  team_name: string;
  user_id: string;
  user_name: string;
};

export type LatestMessage = {
  id: number;
  seen: boolean;
  visible: boolean;
  action: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  data: Data;
  file: File;
};

export type TeamDraft = {
  id?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
  artifacts_count?: ArtifactsCount;
  team_details_count?: TeamDetailsCount;
  latest_message?: LatestMessage;
};

export type Action = {
  type: ActionTypes.LOAD_TEAM_DRAFTS_RESULT;
  payload: {
    result: TeamDraft;
    eventId: string;
    entities: {
      teamDrafts: string;
    };
  };
};

export const load = (eventId: string) => ({
  [RSAA]: {
    ...loadTeamDrafts(eventId),
    types: [
      ActionTypes.LOAD_TEAM_DRAFTS_REQUEST,
      {
        type: ActionTypes.LOAD_TEAM_DRAFTS_RESULT,
        payload: (_: any, __: any, res: Response) =>
          getJSON(res).then((payload) => ({
            eventId,
            ...normalize(payload.team_drafts, [schemas.teamDrafts]),
          })),
      },
      ActionTypes.LOAD_TEAM_DRAFTS_RESULT,
    ],
  },
});
