import { Row } from '@tanstack/react-table';
import { RefObject, useEffect, useRef, useState } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';

type DraggableTableRowProps<T> = {
  index: number;
  row: Row<T>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
  children: (params: {
    dropRef: RefObject<any>;
    dragRef: RefObject<any>;
    isHover: boolean;
  }) => JSX.Element;
};

const DraggableTableRow = <T extends unknown>({
  index,
  row,
  reorderRow,
  children,
}: DraggableTableRowProps<T>) => {
  const [isHover, setIsHover] = useState(false);
  const dropRef = useRef<any>(null);
  const dragRef = useRef<any>(null);

  const [, drop] = useDrop({
    accept: 'row',
    hover(draggedRow: Row<T>, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = draggedRow.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      reorderRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      draggedRow.index = hoverIndex;
    },
  });

  const [{ item }, drag, preview] = useDrag({
    item: () => row,
    type: 'row',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  useEffect(() => {
    const isCurrentRow = item?.index === row.index;
    setTimeout(() => {
      setIsHover(isCurrentRow);
    }, 10);
  }, [item?.index, row.index]);

  return children({ dropRef, dragRef, isHover });
};

export default DraggableTableRow;
