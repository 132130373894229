import { Button, Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Styles from './Styles.module.scss';
import AppleStore from 'images/icons/apple_store.png';
import GooglePlay from 'images/icons/google_play.png';
import { StepProps } from './types';

interface InstallGuideProps extends StepProps {
  appName: string;
  appIcon: string;
  appleStoreUrl: string;
  googlePlayUrl: string;
  howToVideoUrl: string;
}

export const InstallGuide = ({
  currentStep,
  chooseStep,
  appName,
  appIcon,
  appleStoreUrl,
  googlePlayUrl,
  howToVideoUrl,
}: InstallGuideProps) => {
  const { t } = useTranslation();

  if (currentStep !== 4 && currentStep !== 5 && currentStep !== 6) {
    return null;
  }

  const handleSetupQR = () => {
    chooseStep?.(7);
  };

  return (
    <>
      <Row className="text-center">
        <Col className="d-flex flex-row">
          <div className="p-2">
            <img className={Styles.icon} src={appIcon} alt={appName} width="40" />
          </div>
          <div className="p-2 align-self-center">
            <p className={Styles.justifyText}>
              <strong className={Styles.subtitle}>
                {t(`mfa_instructions.get`, `Get`)} {appName}
              </strong>
            </p>
            <p className={Styles.justifyText}>
              {t('mfa_instructions.instructions', 'Download and install on your phone or computer')}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <a href={appleStoreUrl} className={Styles.Link}>
              <img src={AppleStore} alt={`${appName} iOs`} />
            </a>
          </Col>
          <Col className="d-flex justify-content-start">
            <a href={googlePlayUrl} className={Styles.Link}>
              <img src={GooglePlay} alt={`${appName} Android`} />
            </a>
          </Col>
        </Row>
        <Row className={cx('text-center', Styles.Link)}>
          <Col>
            <a href={howToVideoUrl}>{t('mfa_instructions.how_to_video', 'How to use video')}</a>
            <i className={cx('fa-regular fa-circle-play ms-3')} />
          </Col>
        </Row>
        <Row className={cx('text-center', Styles.Link)}>
          <Col>
            <a href={t('mfa_instructions.mobile_guide') || '#'}>
              {t('mfa_instructions.mobileGuide', 'Guide to configure accounts in mobile version')}
            </a>
            <i className={cx('fa-regular fa-arrow-up-right-from-square ms-3')} />
          </Col>
        </Row>
      </Row>
      <Row>
        <Col className={Styles.ContinueBtn}>
          <Button variant={'primary'} onClick={handleSetupQR}>
            Continue
          </Button>
        </Col>
      </Row>
      <br />
    </>
  );
};

export default InstallGuide;
