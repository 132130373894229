import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { Judge, Team } from 'screens/app/events/shared/types';

const teamsColumnHelper = createColumnHelper<Team>();

export const teamsColumns = (phase: any): ColumnDef<Team, any>[] => [
  teamsColumnHelper.accessor('id', {
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),
  teamsColumnHelper.accessor('name', {
    id: 'Company',
    header: (props) => <CommonTableHeaders.SortableHeader {...props} className="text-left" />,
    cell: (props) => <CommonTableCells.Text {...props} className="text-left py-3" />,
  }),
  phase.final_rank
    ? teamsColumnHelper.accessor('finalRank', {
        id: 'rank',
        header: ({ className, ...props }: CommonTableHeaders.TableHeaderProps) => (
          <CommonTableHeaders.TableHeader className={cx('text-center', className)} {...props}>
            Program Rank
          </CommonTableHeaders.TableHeader>
        ),
        cell: CommonTableCells.NumberOrZero,
      })
    : teamsColumnHelper.accessor('validRank', {
        id: 'valid rank',
        header: ({ className, ...props }: CommonTableHeaders.TableHeaderProps) => (
          <CommonTableHeaders.TableHeader className={cx('text-center', className)} {...props}>
            Valid Rank&trade;
          </CommonTableHeaders.TableHeader>
        ),
        cell: CommonTableCells.NumberOrZero,
      }),
  teamsColumnHelper.accessor('assignedJudges', {
    id: 'Matched Judges',
    header: ({ className, ...props }: CommonTableHeaders.TableHeaderProps) => (
      <CommonTableHeaders.TableHeader className={cx('text-center', className)} {...props}>
        Matched
      </CommonTableHeaders.TableHeader>
    ),
    cell: CommonTableCells.NumberOrZero,
  }),
  teamsColumnHelper.accessor('teamScores', {
    id: 'Incomplete Evals',
    header: ({ className, ...props }: CommonTableHeaders.TableHeaderProps) => (
      <CommonTableHeaders.TableHeader className={cx('text-center', className)} {...props}>
        Complete
      </CommonTableHeaders.TableHeader>
    ),
    cell: CommonTableCells.NumberOrZero,
  }),
];

const judgesColumnHelper = createColumnHelper<Judge>();

export const judgesColumns: ColumnDef<Judge, any>[] = [
  judgesColumnHelper.accessor('id', {
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),
  judgesColumnHelper.accessor('name', {
    id: 'Judge',
    header: (props) => <CommonTableHeaders.SortableHeader {...props} className="text-left" />,
    cell: (props) => <CommonTableCells.Text {...props} className="text-left py-3" />,
  }),
  judgesColumnHelper.accessor('assignedTeams', {
    id: 'Matched Teams',
    header: ({ className, ...props }: CommonTableHeaders.TableHeaderProps) => (
      <CommonTableHeaders.TableHeader className={cx('text-center', className)} {...props}>
        Matched
      </CommonTableHeaders.TableHeader>
    ),
    cell: CommonTableCells.NumberOrZero,
  }),
  judgesColumnHelper.accessor('judgedTeams', {
    id: 'Judged Teams',
    header: ({ className, ...props }: CommonTableHeaders.TableHeaderProps) => (
      <CommonTableHeaders.TableHeader className={cx('text-center', className)} {...props}>
        Complete
      </CommonTableHeaders.TableHeader>
    ),
    cell: CommonTableCells.NumberOrZero,
  }),
];

export const columns = {
  teams: teamsColumns,
  judges: judgesColumns,
};
