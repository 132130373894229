import { HeatmapWithLoading } from 'components/RubricScoring/Heatmap';

const HeatMap = ({ rubric, evaluation, scores, selfScore }) => (
  <HeatmapWithLoading
    rubric={rubric}
    evaluation={evaluation}
    selfScore={selfScore}
    scores={scores}
  />
);

export default HeatMap;
