import { Button, Modal } from '@valid-eval/shared-react-components';
import { getEnvVar } from 'config';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { acceptInvitation, declineInvitationWithToken } from 'data/actions/teams';
import { load } from 'data/actions/users';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { teamNav } from 'utils/urls';

const DeclineActionButton = ({ notification: { data } }) => {
  const [isOpened, setIsOpened] = useState();
  const { team_id, invitation_id, decline_token } = data.toJS();

  const toggleModal = (event) => {
    event.preventDefault();
    setIsOpened(true);
  };

  const handleConfirm = async () => {
    const dispatch = window.store.dispatch;
    const { error } = await dispatch(
      declineInvitationWithToken(team_id, invitation_id, decline_token),
    );
    if (error)
      dispatch(
        notifyError(
          `Failed to decline invitation, please contact ${getEnvVar(
            'REACT_APP_SUPPORT_EMAIL',
            'support@valideval.com',
          )} for assistance`,
          'Error',
        ),
      );
    else dispatch(notifySuccess('The invitation has been declined', 'Success'));
  };

  return (
    <>
      <Modal show={isOpened}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm you want to decline this invitation.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" id={'accept-modal-confirmation-cancel'} onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            variant="success"
            id={'accept-modal-confirmation-confirm'}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant={'link'}
        className="notification-action-button"
        style={{ float: 'right' }}
        onClick={toggleModal}
      >
        Decline
      </Button>
    </>
  );
};

const AcceptActionButton = ({ notification: { data } }) => {
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState();
  const { team_id, invitation_id, event_id } = data.toJS();

  const toggleModal = (event) => {
    event.preventDefault();
    setIsOpened(true);
  };

  const handleConfirm = async () => {
    const dispatch = window.store.dispatch;
    const { error } = await dispatch(acceptInvitation(team_id, invitation_id));
    if (error)
      dispatch(
        notifyError(
          `Failed to accept invitation, please contact ${getEnvVar(
            'REACT_APP_SUPPORT_EMAIL',
            'support@valideval.com',
          )} for assistance`,
          'Error',
        ),
      );
    else {
      dispatch(notifySuccess('The invitation has been accepted', 'Success'));
      await dispatch(load('me'));
      navigate(teamNav.results(event_id, team_id));
    }
  };

  return (
    <>
      <Modal show={isOpened}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please confirm you want to accept this invitation.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" id={'accept-modal-confirmation-cancel'} onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            variant="success"
            id={'accept-modal-confirmation-confirm'}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant={'success'}
        className="notification-action-button"
        style={{ float: 'left' }}
        onClick={toggleModal}
      >
        Accept
      </Button>
    </>
  );
};

export const getActionButtons = (notification) => [
  () => <AcceptActionButton notification={notification} />,
  () => <DeclineActionButton notification={notification} />,
];

export const getText = (notification) =>
  `${notification.data.get('team_name')} has invited you to join as a member`;
