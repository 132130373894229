import { apiHeaders, apiURL } from '../services/config';

export const loadSurvey = () => ({
  method: 'GET',
  headers: apiHeaders,
  endpoint: apiURL(`/surveys`),
});

export const validate = ({ phaseId, eventId, roleId, type }) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({
    phase_id: phaseId,
    event_id: eventId,
    role_id: roleId,
    type: type,
  }),
  endpoint: apiURL(`/surveys/validate`),
});

export const saveAnswers = ({ phaseId, eventId, roleId, type, answers }) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({
    survey: { phase_id: phaseId, event_id: eventId, role_id: roleId, type: type, answers: answers },
  }),
  endpoint: apiURL(`/surveys`),
});
