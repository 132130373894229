import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { get } from 'data/services/config';

import { Artifact } from './artifactsTypes';

// Adapter for operate and normalize the data
const initialState: {
  loading: boolean;
  contents: Record<string, string>;
  pdfReportsByEventId: Record<string, Artifact[]>;
} = {
  loading: false,
  contents: {},
  pdfReportsByEventId: {},
};

// Actions
export const fetchArtifactPdfReportContent = createAsyncThunk(
  'artifacts/fetch_pdf_report_content',
  async (
    { artifactId, teamId, phaseId }: { artifactId: string; teamId: string; phaseId: string },
    { rejectWithValue },
  ) => {
    try {
      const params = new URLSearchParams();
      if (phaseId) params.append('phase_id', phaseId);

      return await get(
        `/teams/${teamId}/artifacts/${artifactId}/pdf_report_content?${params.toString()}`,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPdfReportArtifacts = createAsyncThunk(
  'artifacts/fetch_pdf_report_artifacts',
  async (eventId: string, { rejectWithValue }) => {
    try {
      return await get(`/events/${eventId}/artifacts/pdf_reports`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const artifactsSlice = createSlice({
  name: 'artifactsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArtifactPdfReportContent.fulfilled, (state, action) => {
      const args = action.meta.arg;
      const key = `${args.artifactId}-${args.phaseId}-${args.teamId}`;

      state.contents[key] = action.payload.content;
      state.loading = false;
    });
    builder.addCase(fetchPdfReportArtifacts.fulfilled, (state, action) => {
      state.pdfReportsByEventId[action.meta.arg] = action.payload.artifacts;
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(fetchArtifactPdfReportContent.pending, fetchPdfReportArtifacts.pending),
      (state) => {
        state.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchArtifactPdfReportContent.rejected, fetchPdfReportArtifacts.rejected),
      (state) => {
        state.loading = false;
      },
    );
  },
  selectors: {
    getContent: (state, artifactId: string, teamId: string, phaseId: string) => {
      const key = `${artifactId}-${phaseId}-${teamId}`;
      return state.contents[key];
    },
    getPdfReportsByEventId: (state, eventId: string, asReport = true) =>
      state.pdfReportsByEventId[eventId].filter((pdf) => (asReport ? pdf.as_report : true)),
    getArtifact: (state, eventId: string, artifactId: number) =>
      state.pdfReportsByEventId[eventId]?.find((a) => a.id === artifactId),
  },
});

export const fromArtifacts = artifactsSlice.selectors;
export default artifactsSlice.reducer;
