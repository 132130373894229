import { Form } from '@valid-eval/shared-react-components';
import { List } from 'immutable';

const sort = (values) => values.sortBy((value) => value.get('label'));

const CheckboxList = ({
  value,
  onChange,
  disabled,
  name,
  values,
  sortOptions,
  meta: { error, warning, touched },
}) => {
  const handleChange = (item) => () => {
    const currentValue = value || new List(); // if undefined let's start with an empty array
    const { value: itemValue } = item?.toJS?.() || {};
    const newValue = currentValue.includes(itemValue)
      ? currentValue.filter((v) => v !== itemValue)
      : currentValue.concat([itemValue]);
    onChange(newValue);
  };

  const items = sortOptions ? sort(values) : values;
  const selected = value || new List();

  return (
    <div>
      {items.map((item, index) => (
        <Form.Check
          id={`${name}-checkbox-${index}`}
          key={item.get('value')}
          type={'checkbox'}
          onChange={handleChange(item)}
          checked={selected.includes(item.get('value'))}
          disabled={disabled}
          label={item.get('label')}
          isInvalid={touched && (error || warning)}
          isValid={touched && !(error || warning)}
        />
      ))}
      {touched && (error || warning) && (
        <div className="invalid-feedback d-block" style={{ position: 'absolute', bottom: 0 }}>
          {error || warning}
        </div>
      )}
    </div>
  );
};

export default CheckboxList;
