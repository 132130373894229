import { Card, Form } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';
import moment from 'moment';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';

import { Event } from 'data/features/eventsTypes';
import { getEventFromURL } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { DATE_FORMAT_WITH_TIME } from 'utils';

import GlobalSettingsDates from './GlobalSettingsDates';
import GlobalSettingsReplyTo from './GlobalSettingsReplyTo';

type GlobalSettingsProps = {
  onSubmit: (event: Partial<Event>) => void;
};

const mapStateToProps = (state: Map<string, any>, ownProps: GlobalSettingsProps) => ({
  // @ts-expect-error ownProps not recognized as parameter
  event: getEventFromURL(state, ownProps)?.toJS(),
});

const connector = connect(mapStateToProps);

type GlobalSettingsForm = {
  id: string;
  replyToEmail: string;
  replyToName: string;
  teamSignupOpen: string;
  teamSignupDeadline: string;
};

const GlobalSettings = ({
  event,
  onSubmit,
}: GlobalSettingsProps & ConnectedProps<typeof connector>) => {
  const currentTimezone = moment.tz.guess();

  const form = useForm<GlobalSettingsForm>({});

  // Reset form values when event changes
  useEffect(() => {
    if (!event?.id) return;

    form.reset({
      id: event?.id,
      replyToEmail: event?.reply_to_email,
      replyToName: event?.reply_to_name,
      teamSignupOpen: event.team_signup_open
        ? moment(event.team_signup_open).tz(currentTimezone).format(DATE_FORMAT_WITH_TIME)
        : '',
      teamSignupDeadline: event.team_signup_deadline
        ? moment(event.team_signup_deadline).tz(currentTimezone).format(DATE_FORMAT_WITH_TIME)
        : '',
    });
  }, [event?.id]);

  // Submit form when values change
  useEffect(() => {
    const unsubscribe = form.watch((_, { type }) => {
      if (type !== 'change') return;
      form.handleSubmit(handleSubmit)();
    });
    return () => unsubscribe.unsubscribe();
  }, [form.watch]);

  const handleSubmit = (data: GlobalSettingsForm) => {
    onSubmit({
      reply_to_email: data.replyToEmail,
      reply_to_name: data.replyToName,
      team_signup_open: moment(data.teamSignupOpen, DATE_FORMAT_WITH_TIME)
        .tz(currentTimezone)
        .toISOString(),
      team_signup_deadline: moment(data.teamSignupDeadline, DATE_FORMAT_WITH_TIME)
        .tz(currentTimezone)
        .toISOString(),
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Card>
          <Card.Body className="px-3 pt-3 pb-0">
            <GlobalSettingsReplyTo />
            <GlobalSettingsDates event={event} />
          </Card.Body>
        </Card>
      </Form>
    </FormProvider>
  );
};

export default withRouter(connector(GlobalSettings));
