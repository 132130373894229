import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Button, Form } from '@valid-eval/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import Input from 'components/Form/Input';
import Password from 'components/Form/Password';
import { wholeNumber } from 'components/FormFields/validations';
import { getEnvVar } from 'config';
import { error as showError } from 'data/actions/notifications';
import { load as loadUser } from 'data/actions/users';
import { fromAuthentication, resetMFA } from 'data/features/authentication';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import BoxContainer from './BoxContainer';

type ResetMFAFormProps = {
  password: string;
  code: string;
};

type OwnProps = {
  next(): void;
  otpToken: string;
  reloadOTPUserInformation(otpToken: string): Promise<void>;
};

const mapStateToProps = (state: any) => ({
  loading: fromAuthentication.getIsLoading(state.toJS()),
});

const mapDispatchToProps = { resetMFA, showError, loadUser };

const connector = connect(mapStateToProps, mapDispatchToProps);

const ResetMFA = ({
  loading,
  next,
  otpToken,
  reloadOTPUserInformation,
  resetMFA,
  showError,
  loadUser,
}: OwnProps & ConnectedProps<typeof connector>) => {
  const { t } = useTranslation();
  const [reloading, startReloading, stopReloading] = useBooleanFlag();
  const form = useForm<ResetMFAFormProps>({
    defaultValues: {
      password: '',
      code: '',
    },
  });

  const onSubmit = async (data: ResetMFAFormProps) => {
    startReloading();

    try {
      const response = await resetMFA(data);
      if (isRejectedWithValue(response)) {
        form.reset(data);
        const payload: any = response.payload;
        const message =
          payload?.response?.error ||
          t('frontend.common.generic_error', getEnvVar('REACT_APP_SUPPORT_EMAIL'));
        showError(message);
      } else {
        await Promise.all([reloadOTPUserInformation(otpToken), loadUser('me')]);
        next();
      }
    } finally {
      stopReloading();
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <BoxContainer title={t('auth.mfa_setup.reset_instructions')}>
          <div className="text-start">
            <Password
              label={t('auth.login.password')}
              id="reset-mfa-password"
              name="password"
              required
            />
            <Input
              label={t('auth.login.otp')}
              id="reset-mfa-code"
              name="code"
              required
              autoComplete="off"
              rules={{ validate: { wholeNumber } }}
              maxLength={6}
            />
          </div>
        </BoxContainer>
        <div className="mb-4 text-end">
          <Button
            id="reset-mfa-continue"
            variant="primary"
            type="submit"
            disabled={loading || reloading}
          >
            Continue
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default connector(ResetMFA);
