import Footer from './Footer';

const OPTIONS = [
  {
    title: 'Privacy Policy',
    link: 'https://valideval.com/ve-privacy-policy/',
  },
  {
    title: 'Terms of Use',
    link: 'https://valideval.com/ve-terms-of-use/',
  },
  {
    title: 'Contact',
    link: 'https://valideval.com/contact/',
  },
];

const STRINGS = [
  {
    image: '/images/icons/mark-wht.svg',
    copyright: 'Valid Evaluation, Inc. All Rights Reserved',
    homeLink: 'https://valideval.com/',
  },
];

const MENUS = [...OPTIONS];
const SHARED = [...STRINGS];

const MainFooter = () => {
  return <Footer strings={SHARED} menu={MENUS} />;
};

export default MainFooter;
