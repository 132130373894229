import classNames from 'classnames';
import { Component } from 'react';

import Image from './Image';

import csv from '../images/icons/icon_csv.png';
import docx from '../images/icons/icon_doc.png';
import generic from '../images/icons/icon_generic.png';
import pdf from '../images/icons/icon_pdf.png';
import pptx from '../images/icons/icon_ppt.png';
import txt from '../images/icons/icon_txt.png';
import mov from '../images/icons/icon_video.png';
import web from '../images/icons/icon_web.png';
import xlsx from '../images/icons/icon_xls.png';
import Styles from './Icon.module.scss';

const ICONS = {
  csv,
  docx,
  pdf,
  pptx,
  txt,
  mp4: mov,
  mov,
  web,
  xlsx,
  generic,
};

const Icon = ({ type, ...rest }) => <Image {...rest} src={ICONS[type] ? ICONS[type] : ICONS.txt} />;

class IconWrapper extends Component {
  render() {
    const { type, small, children, ...rest } = this.props;
    return (
      <div
        {...rest}
        className={classNames(Styles.icon, Styles[type] || Styles['generic'], {
          [Styles.small]: small,
        })}
      >
        <div className={Styles.content}>{children}</div>
      </div>
    );
  }
}

IconWrapper.displayName = 'IconWrapper';

export { IconWrapper };
export default Icon;
