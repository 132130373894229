import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getFormSubmitErrors,
  getFormSyncErrors,
  hasSubmitFailed,
  reduxForm,
} from 'redux-form/immutable';
import { Button, Form } from '@valid-eval/shared-react-components';

import Errors from 'components/Errors';
import { Input } from 'components/FormFields';
import { sanitizeNumber, sanitizeText, text } from 'components/FormFields/validations';
import OrphanDetailsList from 'components/OrphanDetailsList';

import LoadingButton from '../LoadingButton';
import { renderDynamicFields } from '../dynamicFields';
import styles from './ProfileForm.module.scss';

const ProfileForm = ({
  handleSubmit,
  disabled,
  submitting,
  onCancel,
  dynamicFields,
  orphanDetails,
  error,
  hasSubmitFailed,
  submitErrors,
  syncErrors,
  isOrganizerOrNavigator,
  phases,
  filterDynamicFields = (x) => x,
  applicationRevisionRequest,
}) => {
  const { t } = useTranslation();

  const nameNeedRevision = applicationRevisionRequest?.field_keys?.includes('name');

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Errors
        errors={error && [error]}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      {isOrganizerOrNavigator &&
        phases.map((phase) => {
          if (phase.matching_mode !== 'panel_matching') return null;

          return (
            <Input
              key={`panels[${phase.id}]`}
              id={`panels[${phase.id}]`}
              name={`panels[${phase.id}]`}
              className={styles.inputForm}
              title={
                <>
                  <span className={styles.panelLabel}>
                    Panel Assigment(s){' '}
                    <small className="text-muted">(Only visible for selection committee)</small>
                    <br />
                  </span>
                  <small>{phase.name}</small>
                </>
              }
              normalize={sanitizeNumber}
              disabled={disabled}
            />
          );
        })}
      {isOrganizerOrNavigator && (
        <Input
          id="prefix"
          name="prefix"
          title={
            <>
              Prefix <small className="text-muted">(Only visible for selection committee)</small>
            </>
          }
          disabled={disabled}
          normalize={sanitizeText}
          validate={text}
        />
      )}
      <Input
        id="name"
        name="name"
        title={t('event.team_profile_name_label', 'Company')}
        disabled={disabled && !nameNeedRevision}
        normalize={sanitizeText}
        validate={text}
      />
      {renderDynamicFields(
        dynamicFields.data,
        filterDynamicFields,
        disabled,
        applicationRevisionRequest,
      )}
      <OrphanDetailsList details={orphanDetails} />
      <Form.Group>
        <div className="d-flex flex-wrap mt-3 mb-3">
          {onCancel && (
            <Button variant="default" className="me-1" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <LoadingButton
            className="float-start"
            loading={submitting}
            disabled={submitting || (disabled && !applicationRevisionRequest)}
            variant="success"
            type="submit"
            hasSubmitFailed={hasSubmitFailed}
            submitErrors={submitErrors}
            syncErrors={syncErrors}
          >
            Save Profile
          </LoadingButton>
        </div>
      </Form.Group>
    </Form>
  );
};

ProfileForm.displayName = 'ProfileForm';

export default reduxForm({ form: 'team_profile', enableReinitialize: true })(
  connect((state) => {
    let submitErrors = getFormSubmitErrors('team_profile')(state) || {};
    let syncErrors = getFormSyncErrors('team_profile')(state) || {};
    return {
      hasSubmitFailed: hasSubmitFailed('team_profile')(state),
      submitErrors: submitErrors.toJS?.() || submitErrors,
      syncErrors: syncErrors.toJS?.() || syncErrors,
    };
  })(ProfileForm),
);
