import { apiHeaders, apiURL } from '../services/config';

export const loadTeamAssignment = (phaseId, teamId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/team_assignments/${teamId}`),
});

export const loadTeamSuggestions = (phaseId, teamId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/suggestions`),
});

export const assignToTeam = (phaseId, teamId, judgeProfileId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ team_assignment: { judge_profile_id: judgeProfileId } }),
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/team_assignments`),
});

export const removeAssignment = (phaseId, teamId, judgeId) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/team_assignments/${judgeId}`),
});
