/* eslint no-mixed-operators: 0 */
import { Form } from '@valid-eval/shared-react-components';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime/dist/react-datetime.umd'; // Vite and Rollup doesn't play well with cjs modules
import { Field } from 'redux-form/immutable';
import { DATE_FORMAT, isoToDate } from 'utils';
import styles from './DatePicker.module.scss';

import { dynamicFieldValidation, normalize } from './utils';

export const DatePickerFieldGroup = (
  { input, meta: { touched, error, warning }, id, label, helpText, disabled } = this.props,
) => {
  const hasError = error || warning;
  const warnVar = hasError ? 'is-invalid' : 'is-valid';
  const validationClass = touched ? warnVar : '';

  useEffect(() => {
    if (typeof input.value === 'string' && input.value.length >= 10) {
      const date = moment(new Date(input.value)).utc();
      if (date.format('YYYY-MM-DD') !== input.value?.split?.('T')?.[0]) {
        input.onChange(date.format());
      }
    }
  }, [input.value]);

  const date =
    input.value && typeof input.value === 'string' && input.value.length >= 10
      ? moment(new Date(input.value)).utc()
      : undefined;

  return (
    <Form.Group className={styles.inputTitle} controlId={id}>
      <Form.Label>
        <b>{label}</b>
      </Form.Label>
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <DateTime
        dateFormat={DATE_FORMAT}
        timeFormat={false}
        closeOnSelect={true}
        input={true}
        isInvalid={touched && (error || warning)}
        isValid={touched && !(error || warning)}
        className={classNames(styles.inputDate, validationClass)}
        {...input}
        value={date}
        inputProps={{
          id,
          autoComplete: 'off',
          disabled: disabled,
          className: classNames('form-control', validationClass),
        }}
      />
      <Form.Control.Feedback type="invalid">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const dateFormatter = normalize('date', {
  normalizer: (value) => {
    if (value === undefined || value === '0') {
      // This avoid transforming an undefined value into a date
      // which we need in order to allow optional dates and more importantly
      // do not not show an initial value when the current actual date
      // is not even set.
      return value;
    }
    return isoToDate(value);
  },
});

export const DatePicker = ({ id, name, title, helpText, validate, ...extraProps }) => {
  return (
    <Field
      className={styles.inputDate}
      id={id || name}
      name={name}
      label={title}
      helpText={helpText}
      component={DatePickerFieldGroup}
      format={dateFormatter}
      validate={validate || dynamicFieldValidation}
      {...extraProps}
    />
  );
};
