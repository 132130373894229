import { Button, Col, Nav, Row, Tab } from '@valid-eval/shared-react-components';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { fromArtifactItems } from 'data/features/artifactItems';
import { openSignUpModal } from 'data/features/authentication';
import { fromEvents } from 'data/features/events';
import { fromTeamDrafts } from 'data/features/teamDrafts';
import { getCurrentUser, getMembersByTeam } from 'data/reducers';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import Styles from './SignUpNav.module.scss';
import SignUpNavItem from './SignUpNavItem';
import SignUpProgressBar from './SignUpProgressBar';
import SignUpSubmitButton from './SignUpSubmitButton';
import SignUpWizardTitle from './SignUpWizardTitle';
import { InstructionsStep } from './signUpWizardSteps';
import useSignUpWizardState from './useSignupWizardState';

type OwnProps = {
  params: {
    draftId?: string;
    slug: string;
  };
  query: {
    code?: string;
    email?: string;
  };
  navigate(url: string): void;
};

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  const stateJS = state.toJS();
  const event = fromEvents.getEventBySlug(stateJS, ownProps.params.slug);
  const user = getCurrentUser(state)?.toJS();
  const draft = fromTeamDrafts.getTeamDraftInEvent(stateJS, {
    id: ownProps.params.draftId,
    eventId: event?.id || '',
    userId: user?.id || '',
  });
  const artifactItems = fromArtifactItems.getArtifactItemsByTeamId(stateJS, draft?.id || '');
  const saving = fromTeamDrafts.getIsSaving(stateJS);
  const savingArtifactItems = fromArtifactItems.getIsSaving(stateJS);
  // @ts-expect-error - TS doesn't understand the selector argument types
  const members = getMembersByTeam(state, draft?.id);
  const currentUserRole = members.find((m: any) => m.user_id === user?.id)?.role;
  const isTeamMemberEditor = ['editor', 'primary'].includes(currentUserRole);

  return { event, user, draft, saving, savingArtifactItems, artifactItems, isTeamMemberEditor };
};

const connector = connect(mapStateToProps, { openSignUpModal });

type SignUpWizardProps = OwnProps & ConnectedProps<typeof connector>;

const SignUpWizard = ({
  artifactItems,
  draft,
  event,
  navigate,
  openSignUpModal,
  query,
  saving,
  savingArtifactItems,
  user,
  isTeamMemberEditor,
}: SignUpWizardProps) => {
  const { t } = useTranslation();

  const {
    activeStep,
    activeStepKey,
    getStepByKey,
    hasNextStep,
    hasPrevStep,
    navigableSteps,
    nextStep,
    onStepInfoChange,
    prevStep,
    progress,
    setActiveStepKey,
    steps,
  } = useSignUpWizardState(event, user, draft);
  const [applicationStarted, start] = useBooleanFlag();

  useEffect(() => {
    if (!!draft?.id) start();
  }, [draft?.id]);

  useEffect(() => {
    if ((query.code || query.email) && !user?.id) handleNoSession(false);
  }, []);

  const instructionsStep = getStepByKey(InstructionsStep.key);

  const handleSubmitStep = () => {
    if (!user?.id) return handleNoSession(false);
    if (!applicationStarted) start();
    return nextStep();
  };

  const handleNoSession = (login = true) => {
    if (login) navigate('#login');
    openSignUpModal();
  };

  return (
    <Tab.Container
      activeKey={activeStepKey}
      onSelect={(key) => setActiveStepKey(parseInt(key || ''))}
    >
      <Row className="no-gutters mx-0 mt-4">
        <Col md={4} className={Styles.WizardNav}>
          <div className="bg-white01 p-2 rounded sticky-top" style={{ top: 65 }}>
            {instructionsStep && (
              <>
                <h2 className="m-2 fw-bolder">{t('auth.teams_sign_up.overview')}</h2>
                <Nav variant="wizard" className="flex-column">
                  <SignUpNavItem
                    id={instructionsStep.id}
                    eventKey={instructionsStep.key}
                    label={instructionsStep.title}
                    disabled={instructionsStep.disabled}
                  />
                </Nav>

                <hr className="my-4" />
              </>
            )}

            <h2 className="m-2 fw-bolder">{t('auth.teams_sign_up.application')}</h2>
            <Nav variant="wizard" className="flex-column">
              {navigableSteps.map((step, index, ss) => (
                <Fragment key={`nav-item-${step.key}`}>
                  <SignUpNavItem
                    id={step.id}
                    eventKey={step.key}
                    label={step.title}
                    subLabel={applicationStarted ? step.info?.stateText : ''}
                    status={
                      event?.signup_enabled && applicationStarted ? step.status : 'notStarted'
                    }
                    disabled={step.disabled || !applicationStarted}
                  />
                  {index < ss.length - 1 && <div className="nav-separator"></div>}
                </Fragment>
              ))}
            </Nav>
            <div className="d-grid mt-4 mb-2">
              <Button
                id="start-application"
                variant="primary"
                onClick={handleSubmitStep}
                disabled={(!hasNextStep && user) || progress.percentage === 100}
              >
                {applicationStarted
                  ? t('auth.teams_sign_up.next_step')
                  : t('auth.teams_sign_up.start_application')}
              </Button>
            </div>

            <hr className="my-4" />
            <h2 className="m-2 fw-bolder">{t('auth.teams_sign_up.progress')}</h2>
            <SignUpProgressBar
              progress={event?.signup_enabled && applicationStarted ? progress.percentage : 0}
            />
            <SignUpSubmitButton
              draft={draft}
              event={event}
              saving={saving}
              savingArtifactItems={savingArtifactItems}
              onNoSession={handleNoSession}
              progress={progress}
              isTeamMemberEditor={isTeamMemberEditor}
            />
          </div>
        </Col>
        <Col md={8} className="px-0">
          <Tab.Content className="d-flex flex-column h-100">
            <SignUpWizardTitle
              event={event}
              activeStep={activeStep}
              hasNextStep={hasNextStep}
              hasPrevStep={hasPrevStep}
              onNextStep={nextStep}
              onPrevStep={prevStep}
            />
            {steps.map((step) => (
              <Tab.Pane key={step.key} className="flex-grow-1" eventKey={step.key}>
                <step.component
                  artifactItems={artifactItems}
                  draft={draft}
                  event={event}
                  isActive={step.key === activeStepKey}
                  onInfoChange={onStepInfoChange(step.key)}
                  onNoSession={handleNoSession}
                  onSubmitStep={handleSubmitStep}
                  saving={saving}
                  savingArtifactItems={savingArtifactItems}
                  user={user}
                  isTeamMemberEditor={isTeamMemberEditor}
                />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default withRouter(connector(SignUpWizard));
