import cx from 'classnames';
import { EmailList } from 'components/FormFields';

import { Button, Col, Row } from '@valid-eval/shared-react-components';
import { useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form/immutable';
import Styles from './JudgeInvitationsModal.module.scss';

function JudgeInvitationsAddList({ handleSubmit, onCancel }) {
  const { existingInvitations = [] } =
    useSelector(getFormValues('JudgeInvitationsAddList'))?.toJS() || {};
  const existingEmails = existingInvitations.map((i) => i.email);

  return (
    <form onSubmit={handleSubmit}>
      <Row className="mt-3">
        <Col>
          <h3>Add multiple contacts as a list</h3>
          <p>
            Please type or paste a batch of email addresses in the box below, using commas,
            semicolons, and/or new-lines to separate entries from one another.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <EmailList id="list" name="list" label="List" autoFocus existingEmails={existingEmails} />
        </Col>
      </Row>
      <Row className={cx('mt-3 me-0 ms-0 pe-0 ps-0 pt-4 align-self-end', Styles.ModalButtons)}>
        <Col className="text-end p-0">
          <Button variant="link" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Next</Button>
        </Col>
      </Row>
    </form>
  );
}

export default reduxForm({ form: 'JudgeInvitationsAddList' })(JudgeInvitationsAddList);
