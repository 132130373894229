import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { StepInfo } from '../types';

const useCalculateStepInfo = ({
  user,
  form,
  requiredFields,
  onInfoChange,
}: {
  user: any;
  form: UseFormReturn<any, any, undefined>;
  requiredFields: string[];
  onInfoChange: (data: StepInfo) => void;
}) => {
  useEffect(() => {
    if (!user?.id) return;

    const requiredFieldsCount = requiredFields.length;
    const missingFields = Object.keys(form.formState.errors).reduce<number>(
      (acumm, k) => acumm + (requiredFields.includes(k) ? 1 : 0),
      0,
    );

    onInfoChange({
      requiredFieldsCount: requiredFieldsCount,
      completedFieldsCount: requiredFieldsCount - missingFields,
      visited: true,
    });
  }, [Object.keys(form.formState.errors).toString(), form.formState.isValidating, user?.id]);
};

export default useCalculateStepInfo;
