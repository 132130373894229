import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Table from 'components/Tables/Table';
import { getJudgeSummaries, getTeamSummaries } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { judgesColumns, teamsColumns } from './TriageModalTableDefinitions';

import { Judge, Team } from '../../shared/types';
import Styles from './Triage.module.scss';
import TriageModalTableControls from './TriageModalTableControls';
import { filterResults } from 'components/Tables/filteringHelpers';
import { FiltersState } from 'components/Tables/types';

const searchableFields = ['name'];

type OwnProps = {
  entity: 'teams' | 'judges';
  preselected: Judge[] | Team[];
  setSelectedEntities: (selected: Judge[] | Team[]) => void;
  phase?: { id: string; finalRank?: boolean };
};

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  data:
    (ownProps.entity === 'judges'
      ? // @ts-ignore
        getJudgeSummaries(state, ownProps)
      : // @ts-ignore
        getTeamSummaries(state, ownProps)) || [],
});
const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TriageModalTableProps = OwnProps & PropsFromRedux;

const TriageModalTable = ({
  data,
  entity,
  preselected,
  setSelectedEntities,
  phase,
}: TriageModalTableProps) => {
  const [rowSelection, onRowSelectionChange] = useState<{ [entityId: string]: boolean }>({});
  const [globalFilter, setGlobalFilter] = useState<FiltersState>({
    textFilter: '',
    otherFilters: [],
  });

  const columns = entity === 'judges' ? judgesColumns : teamsColumns(phase);

  useEffect(() => {
    if (preselected?.length) {
      onRowSelectionChange(preselected.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
    }
  }, [preselected]);

  useEffect(() => {
    setSelectedEntities(data.filter((r: Judge | Team) => rowSelection[r.id]));
  }, [rowSelection]);

  const handleTextFilterChange = (textFilter: string) => {
    setGlobalFilter({ ...globalFilter, textFilter });
  };

  const tableModel = useReactTable({
    data,
    columns: columns as ColumnDef<any, any>[],
    onRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: true,
    state: {
      globalFilter,
      rowSelection,
    },
    meta: {
      tableId: `triage-${entity}-table-modal`,
    },
    globalFilterFn: filterResults(searchableFields),
  });

  return (
    <>
      <TriageModalTableControls onTextFilterChange={handleTextFilterChange} />
      <div className={cx('mt-2', Styles.RoleSummariesTable)}>
        <Table tableModel={tableModel} responsive={false} />
      </div>
    </>
  );
};

export default withRouter(connector(TriageModalTable));
