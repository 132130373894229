import { Form } from '@valid-eval/shared-react-components';

export type HelpTextProps = {
  text?: string;
};

const HelpText = ({ text }: HelpTextProps) => {
  return text ? (
    <p className="d-print-none text-pre-wrap">
      <Form.Text muted>{text}</Form.Text>
    </p>
  ) : null;
};

export default HelpText;
