import { Dropdown } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import CheckboxesFilter from './CheckboxesFilter';
import Styles from './FiltersDropdown.module.scss';

interface FiltersDropdownProps {
  availableFilters: any[];
  filters: any[];
  onFiltersChange: (filters: any[]) => void;
}

function FiltersDropdown({
  onFiltersChange,
  availableFilters,
  filters = [],
}: FiltersDropdownProps) {
  function handleCheckboxesFilterChange(filter: any) {
    filters = filters.filter((f) => f.field !== filter.field || f.value !== filter.value);
    onFiltersChange(filter.checked ? [...filters, filter] : [...filters]);
  }

  return (
    <div className="col-auto">
      <Dropdown>
        <Dropdown.Toggle variant="default" className={cx('pe-3 ps-3', Styles.Button)}>
          <i className="fa-solid fa-filter"></i>
          Filter
        </Dropdown.Toggle>

        <Dropdown.Menu className={Styles.DropdownMenu}>
          <div className={'d-flex flex-column pe-4 ps-4 pt-2'}>
            {availableFilters.map((filter, index) => {
              if (filter.type === 'checkbox') {
                return (
                  <CheckboxesFilter
                    key={`checkbox-filter-${filter.field}-${index}`}
                    field={filter.field}
                    onChange={handleCheckboxesFilterChange}
                    options={filter.options}
                    title={filter.title}
                    filters={filters}
                  />
                );
              }
              return undefined;
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default FiltersDropdown;
