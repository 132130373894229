import { organizerNav } from '../urls';

export const getActionButtons = (notification) => [
  {
    title: 'Remind your judges',
    url: `${organizerNav.judgeManage(
      notification.data.get('event_id'),
    )}?phase=${notification.data.get('phase_id')}`,
  },
];

export const getText = (notification) => {
  return `In ${notification.data.get(
    'time_left',
  )} the judging window will close for cohort ${notification.data.get(
    'cohort_name',
  )}, phase ${notification.data.get('phase_name')}. Take action to remind your Judges.`;
};
