import * as actions from 'data/actions/assignments';
import * as api from 'data/services/assignments';

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

function* performEditJudgeAssignments(action) {
  try {
    const { phaseId, judgeId } = action.payload;
    const result = yield call(api.loadJudgeAssignment, phaseId, judgeId);

    // TODO: Remove judge loading when using EditWrapper for organizer
    // yield put(judges.load(judgeId)); // load judge profile from api

    yield put(actions.loadJudgeAssignmentResult(result));

    yield call(loadJudgeSuggestions, phaseId, judgeId);
  } catch (e) {
    yield put(actions.loadJudgeAssignmentResult(new Error(e.error || e.message)));
  }
}

function* loadJudgeSuggestions(phaseId, judgeId) {
  try {
    const result = yield call(api.loadJudgeSuggestions, phaseId, judgeId);
    yield put(actions.loadJudgeSuggestionsResult(phaseId, judgeId, result));
  } catch (e) {
    yield put(actions.loadJudgeSuggestionsResult(new Error(e.error || e.message)));
  }
}

function* watchEditJudgeAssignments() {
  yield takeEvery(actions.editJudgeAssignments, performEditJudgeAssignments);
}

const saga = function* () {
  yield all([fork(watchEditJudgeAssignments)]);
};

export default saga;
