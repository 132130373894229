import { Button } from '@valid-eval/shared-react-components';
import { CellContext } from '@tanstack/react-table';
import { JudgeEvaluation, JudgeEvaluationArtifactItem } from 'data/features/judgeEvaluationsTypes';
import LinkContainer from 'components/LinkContainer';
import LinksAndDownloads from 'components/LinksAndDownloads';
import { judgeNav } from 'utils/urls';

export const Recuse = ({ getValue, table, row }: CellContext<JudgeEvaluation, null | string>) => {
  const handleClick = () => {
    const { meta } = table.options as any;
    meta.openRecuseModal(row.original.team.id, row.original.phase.id);
  };

  return (
    <td className="text-center">
      {getValue() && (
        <a className="text-danger" onClick={handleClick} href={`#recuse-${row.original.team}`}>
          {getValue()}
        </a>
      )}
    </td>
  );
};

export const Artifacts = ({
  getValue,
  row,
}: CellContext<JudgeEvaluation, JudgeEvaluationArtifactItem[]>) => {
  const documents = (getValue() || []).map((doc) => ({
    id: doc.id,
    type: doc.artifact.type,
    name: doc.artifact.judges_and_admins_name,
    required: doc.artifact.required,
    instructions: doc.artifact.instructions,
    file_type: doc.artifact.file_type,
    file_name: doc.file_name,
    value: doc.url,
  }));

  const WindowOpenComponent = Boolean(row.original.artifact_items.length) ? (
    <LinksAndDownloads
      teamId={row.original.team.id}
      evaluationId={row.original.id}
      documents={documents}
    />
  ) : (
    <span className="text-muted">None Available</span>
  );

  return (
    <td className="text-center">
      {row.original.judging_window === 'Open' ? (
        WindowOpenComponent
      ) : (
        <span className="text-muted">Phase Closed</span>
      )}
    </td>
  );
};

export const MyScore = ({ getValue, row }: CellContext<JudgeEvaluation, number>) => {
  const qualitativeScoreAvailable = row.original.qualitative_score_abbreviation;
  return <td className={'text-center'}>{qualitativeScoreAvailable || getValue() || ''}</td>;
};

export const Actions = ({ getValue, table, row }: CellContext<JudgeEvaluation, string>) => {
  const actionText = getValue();
  const { score_id } = row.original;
  const meta = table.options.meta as any;

  if (!actionText) return <td />;
  let data = { action: '', text: actionText, variant: 'primary' };

  if (actionText === 'Edit') {
    data.variant = 'secondary';
  }

  if (!score_id) {
    const disabled = meta.judgeEvaluationIdWaitingForScore === row.original.id;
    return (
      <td className="text-center">
        <Button
          variant={data.variant}
          onClick={() => meta.onBeginEvaluation(row.original.id)}
          disabled={disabled}
        >
          {disabled ? <i className="fa-duotone fa-spinner fa-spin fa-lg p-0 m-0" /> : data.text}
        </Button>
      </td>
    );
  }
  const url = judgeNav.scoring(meta.eventId, meta.judgeId, score_id);
  return (
    <td className="text-center">
      <LinkContainer to={url}>
        <Button variant={data.variant}>{data.text}</Button>
      </LinkContainer>
    </td>
  );
};
