import * as eventsAPI from '../services/events';

import { RSAA, getJSON } from 'redux-api-middleware';

export const actions = {
  LOAD: 'documents/LOAD',
};

export const LOAD_RESULT = 'documents/LOAD_RESULT';

export const load = (event_id) => ({
  [RSAA]: {
    ...eventsAPI.loadDocuments(event_id),
    types: [
      actions.LOAD,
      {
        type: LOAD_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({ event_id: event_id, documents: payload.documents })),
      },
      LOAD_RESULT,
    ],
  },
});
