import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { get, post } from 'data/services/config';
import { ArtifactItem } from './artifactItemsTypes';
import { isPendingAction, isRejectedAction } from './common';

// Adapter for operate and normalize the data
const initialState: {
  loading: boolean;
  saving: boolean;
  byTeamId: Record<string, ArtifactItem[]>;
} = {
  loading: false,
  saving: false,
  byTeamId: {},
};

// Actions
export const fetchArtifactItems = createAsyncThunk(
  'artifactItems/fetch',
  async ({ teamId }: { teamId: string }, { rejectWithValue }) => {
    try {
      return await get(`/teams/${teamId}/artifact_items`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveArtifactItems = createAsyncThunk(
  'artifactItems/save',
  async (
    {
      eventId,
      teamId,
      artifactItems,
    }: { eventId: string; teamId: string; artifactItems: Record<string, string | null> },
    { rejectWithValue },
  ) => {
    try {
      return await post(`/events/${eventId}/teams/${teamId}/artifact_items`, {
        data: artifactItems,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const artifactItemsSlice = createSlice({
  name: 'artifactItemsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(fetchArtifactItems.fulfilled, saveArtifactItems.fulfilled),
        (state, action) => {
          state.byTeamId[action.meta.arg.teamId] = action.payload.artifact_items;
          state.saving = false;
          state.loading = false;
        },
      )
      .addMatcher(isPendingAction('artifactItems'), (state, action) => {
        if (action.type === saveArtifactItems.pending.type) state.saving = true;
        else state.loading = true;
      })
      .addMatcher(isRejectedAction('artifactItems'), (state) => {
        state.loading = false;
        state.saving = false;
      });
  },
  selectors: {
    getIsLoading: (state) => state.loading,
    getIsSaving: (state) => state.saving,
    getArtifactItemsByTeamId: (state, teamId: string) => {
      const artifactItems = state.byTeamId[teamId] || [];
      return Object.fromEntries(
        artifactItems.map((artifactItem) => [artifactItem.artifact.id, { ...artifactItem }]),
      );
    },
  },
});

export const fromArtifactItems = artifactItemsSlice.selectors;

export default artifactItemsSlice.reducer;
