import * as assignmentsAPI from '../services/assignments';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { createAction } from 'redux-actions';
import { failedFSAwithParams } from '../services/config';
import { listToObject } from '../../utils';
import { normalize } from 'normalizr';

//Action Constants

// Action Types

export const actions = {
  LOAD_FOR_STATUS: 'assignments/LOAD_FOR_STATUS',
  LOAD_SUCCESS: 'assignments/LOAD_SUCCESS',
  LOAD_FAIL: 'assignments/LOAD_FAIL',
  REMOVE: 'assignments/REMOVE',
};

export const loadForStatus = (eventId, entity, phaseId) => {
  return { type: actions.LOAD_FOR_STATUS, payload: { eventId, entity, phaseId } };
};

export const loadSuccess = (assignment) => {
  return { type: actions.LOAD_SUCCESS, payload: { assignment } };
};

export const loadFail = () => {
  return { type: actions.LOAD_FAIL };
};

export const remove = (id) => {
  return { type: actions.REMOVE, payload: { id } };
};

export const loadMultipleResult = createAction('assignments/LOAD_MULTIPLE_RESULT', (assignments) =>
  listToObject(assignments),
);

export const editJudgeAssignments = createAction(
  'assignments/EDIT_JUDGE_ASSIGNMENTS',
  (phaseId, judgeId) => ({ phaseId, judgeId }),
);

export const loadJudgeAssignmentResult = createAction(
  'assignments/LOAD_JUDGE_ASSIGNMENT_RESULT',
  (payload, phaseId) => ({
    phaseId,
    teams: listToObject(payload.teams),
    categories: listToObject(payload.categories),
    judge_assignment: payload.judge_assignment,
    user: payload.user,
  }),
);

export const loadJudgeSuggestionsResult = createAction(
  'assignments/JUDGE_SUGGESTIONS_RESULT',
  (phaseId, judgeId, suggestionList) => {
    const {
      entities: { teams, suggestions, categories },
    } = normalize(suggestionList, [schemas.judgeSuggestion]);
    return { phaseId, judgeId, teams, suggestions, categories };
  },
);

export const SAVE_JUDGE_ASSIGNMENTS = 'assignments/SAVE_JUDGE_ASSIGNMENTS';

export const assignToJudge = (phaseId, judgeId, teamId) => {
  return {
    [RSAA]: {
      ...assignmentsAPI.assignToJudge(phaseId, judgeId, teamId),
      types: [
        'assignments/ASSIGN_TO_JUDGE_REQUEST',
        {
          type: SAVE_JUDGE_ASSIGNMENTS,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              const { assignment } = payload;
              const result = normalize([assignment], [schemas.judgeAssignment]);
              return { entities: result.entities, phaseId, judgeId, new_assignment: true };
            }),
        },
        {
          type: SAVE_JUDGE_ASSIGNMENTS, // TODO: Check the failed response and how to reduce it
          payload: failedFSAwithParams({ phaseId, judgeId }),
        },
      ],
    },
  };
};

export const removeFromJudge = (phaseId, judgeId, teamId) => {
  return {
    [RSAA]: {
      ...assignmentsAPI.removeFromJudge(phaseId, judgeId, teamId),
      types: [
        'assignments/UNASSIGN_FROM_JUDGE_REQUEST',
        {
          type: 'assignments/REMOVE_ASSIGNMENT_FROM_JUDGE',
          payload: () => ({ phaseId, judgeId, teamId }),
        },
        {
          type: 'assignments/REMOVE_ASSIGNMENT_FROM_JUDGE',
          payload: failedFSAwithParams({ phaseId, judgeId, teamId }),
        },
      ],
    },
  };
};

export const removeAssignmentFromJudge = createAction(
  'assignments/REMOVE_ASSIGNMENT_FROM_JUDGE',
  (phaseId, judgeId, teamId) => ({ phaseId, judgeId, teamId }),
);

export const createMultiple = (phaseId, judgeIds, teamIds) => {
  return {
    [RSAA]: {
      ...assignmentsAPI.createMultiple(phaseId, judgeIds, teamIds),
      types: [
        'assignments/CREATE_MULTIPLE_PENDING',
        'assignments/CREATE_MULTIPLE_FULFILLED',
        'assignments/CREATE_MULTIPLE_FAILED',
      ],
    },
  };
};
