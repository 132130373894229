import { Select as ComponentSelect, Form } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { List } from 'immutable';
import { useEffect } from 'react';
import { Field } from 'redux-form/immutable';

import Styles from './Select.module.scss';

import { dynamicFieldValidation } from './utils';

const sort = (values) => values.sort((value) => value.label);

const CustomSelect = ({ items, value, isSearchable = false, disabled = false, ...rest }) => {
  return (
    <ComponentSelect
      {...rest}
      value={items.find((item) => value?.value === item.value)}
      isForm
      options={items}
      isSearchable={isSearchable}
      isDisabled={disabled}
    />
  );
};

export const SelectFieldGroup = ({
  input,
  meta: { touched, error, warning },
  id,
  label,
  helpText,
  options,
  disabled,
  sortOptions,
  placeholder,
  className,
  onKeyDown,
  isSearchable,
  isClearable,
}) => {
  const originalOnChange = input.onChange;

  options = List.isList(options) ? options.toJS() : options;
  let items = sortOptions ? sort(options) : options;

  input.onChange = (value) => originalOnChange(value?.value);

  function getSelectedValue(value) {
    return options.find((option) => value === option.value);
  }

  const selectedValue = getSelectedValue(input.value);
  const shouldHideInPrint = selectedValue === undefined;

  useEffect(() => {
    // Try to find the selected value within the options
    // If not preset set it to null
    if (input.value && options.length) {
      const valueFound = options.find((option) => option?.value === input.value);
      if (!valueFound) input.onChange(null);
    }
  }, [input.value, options]);

  return (
    <Form.Group className={cx(Styles.inputTitle, className)} controlId={id}>
      {label && (
        <Form.Label>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <Form.Control
        {...input}
        items={items}
        value={getSelectedValue(input.value)}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        className={cx('p-0', Styles.FormControl, { hideInPrint: shouldHideInPrint })}
        placeholder={placeholder}
        disabled={disabled}
        custom="true"
        as={CustomSelect}
        isInvalid={touched && (error || warning)}
        isValid={touched && !(error || warning)}
        onKeyDown={onKeyDown}
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
      <Form.Control.Feedback type="invalid">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const Select = ({
  id,
  name,
  title,
  helpText,
  options,
  disabled,
  sortOptions,
  placeholder,
  className,
  validate,
  onKeyDown,
  isSearchable,
  isClearable,
}) => (
  <Field
    as="select"
    className={className}
    component={SelectFieldGroup}
    disabled={disabled}
    helpText={helpText}
    id={id || name}
    label={title}
    name={name}
    options={options}
    placeholder={placeholder}
    sortOptions={sortOptions}
    validate={validate || dynamicFieldValidation}
    onKeyDown={onKeyDown}
    isSearchable={isSearchable}
    isClearable={isClearable}
  />
);
