import { Button } from '@valid-eval/shared-react-components';
import { getEnvVar } from 'config';

const getData = (notification) => notification.data?.toJS?.() || {};

export const getActionButtons = (notification) => {
  const data = getData(notification);

  switch (data.report_status) {
    case 'COMPLETED':
      return [
        {
          blank: true,
          title: `Download ${
            data.report_type === 'pdf_results' && !data.report_title.endsWith('teams')
              ? 'PDF'
              : 'report'
          }${
            data.report_type === 'team_files_report' ||
            data.report_type === 'team_artifact_items_download'
              ? ` (${data.report_size})`
              : ' again'
          }`,
          url: notification.url,
          toggleSeen: true,
        },
      ];
    case 'IN_PROGRESS':
      return [
        () => (
          <Button variant="link" disabled>
            <i className="fa-duotone fa-spinner fa-lg fa-spin"></i> Generating{' '}
            {data.report_type === 'pdf_results' && !data.report_title.endsWith('teams')
              ? 'PDF'
              : 'report'}
          </Button>
        ),
      ];
    default:
      return [];
  }
};

export const getText = (notification) => {
  const data = getData(notification);

  switch (data.report_status) {
    case 'COMPLETED':
      return `
        Your "${data.report_title}" ${
          data.report_type === 'pdf_results' && !data.report_title.endsWith('teams')
            ? 'PDF'
            : 'report'
        } for ${data.event_name} cohort ${
          data.phase_name ? `at ${data.phase_name} phase` : ''
        }, is ready!`;
    case 'FAILED':
      const sizeExceededMessage =
        data.report_type === 'team_files_report' && data.report_size
          ? ` due to exceeding the maximum size limit of ${data.report_size}`
          : '';
      return `
        The "${data.report_title}" ${
          data.report_type === 'pdf_results' ? 'PDF' : 'report'
        } generation has failed${sizeExceededMessage}, please contact ${getEnvVar(
          'REACT_APP_SUPPORT_EMAIL',
          'support@valideval.com',
        )} for assistance.
      `;
    case 'IN_PROGRESS':
    default:
      return `The system is generating your "${data.report_title}" ${
        data.report_type === 'pdf_results' && !data.report_title.endsWith('teams')
          ? 'PDF'
          : 'report'
      } for ${data.event_name} cohort ${
        data.phase_name ? `at ${data.phase_name} phase` : ''
      }. If it doesn't download within 10 minutes, please contact ${getEnvVar(
        'REACT_APP_SUPPORT_EMAIL',
        'support@valideval.com',
      )}.`;
  }
};
