import Image from '../../components/Image';
import Styles from '../../screens/styles.module.scss';
import { getEnvVar } from 'config';
import moment from 'moment-timezone';

const localTZ = moment.tz.guess();
const serverTZ = moment.tz('America/Denver');
const timeOut = moment.tz(moment.tz('2020-04-27 17:00', 'America/Denver'), localTZ);
const supportEmail = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');

const DefaultMaintenanceInfo = () => (
  <>
    <div className={Styles.innerContainer}>
      <h1 className={Styles.title}>Something went wrong!</h1>
      <p className={Styles.message}>
        If you are seeing this, your browser is unable to reach Valid Eval.
      </p>
    </div>
    <Image width="20%" src="/images/maintenance-mode.png" />
    <div className={Styles.innerContainer}>
      <p className={Styles.messageFirst}>There are a few possible reasons for this happening:</p>
      <ol className={Styles.list}>
        <li>You have lost your internet connection / your connection is unstable,</li>
        <li>Our servers are experiencing a hiccup and should recover within a minute or two, or</li>
        <li>
          You are accessing Valid Eval during a maintenance interval, which typically take place on
          weekdays after 5:00 PM Mountain Time
          {moment.tz(localTZ).zoneAbbr() === serverTZ.zoneAbbr()
            ? ''
            : ` (${timeOut.format('h:mm A')} in your computer's time zone)`}
          , though we occasionally must perform maintenance at other times.
          <br />
          <br />
          If Valid Eval is experiencing an extended outage, we will update this page with additional
          information -{' '}
          <span className={Styles.semiStrong}>
            refresh your browser to check for status updates.
          </span>
        </li>
      </ol>
      <p className={Styles.message}>
        <strong>What happens next?</strong>
      </p>
      <p className={Styles.finalMessage}>
        Once your internet connection returns or the servers are back up and healthy, within one
        minute you will be automatically directed to either your Valid Eval dashboard or the login
        page - no refresh required.
      </p>
      <p className={Styles.message}>
        <strong className={'text-danger'}>
          If you experience a loop of repeatedly seeing this page and then being sent back to the
          application, please contact Valid Eval support immediately at{' '}
          <a href={supportEmail}>{supportEmail}</a> for assistance.
        </strong>
      </p>
    </div>
  </>
);

export default DefaultMaintenanceInfo;
