import * as evaluationsAPI from '../services/evaluations';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { createAction } from 'redux-actions';
import { loadEvaluation as loadForTeam } from '../services/teams';
import { normalize } from 'normalizr';

export const actions = {
  LOAD: 'evaluations/LOAD',
  LOAD_FOR_PHASE: 'evaluations/LOAD_FOR_PHASE',
  LOAD_SUCCESS: 'evaluations/LOAD_SUCCESS',
  LOAD_FAIL: 'evaluations/LOAD_FAIL',
  UPDATE: 'evaluations/UPDATE',
  REVERSE_UPDATE: 'evaluations/REVERSE_UPDATE',
  SHOW_EVALUATION_FOR_TEAM: 'evaluations/SHOW_EVALUATION_FOR_TEAM',
  INIT_ORGANIZER_RESULT_DETAILS: 'evaluations/INIT_ORGANIZER_RESULT_DETAILS',
  UPDATE_VALID_RANK_PENDING: 'evaluations/UPDATE_VALID_RANK_PENDING',
  UPDATE_VALID_RANK_REJECTED: 'evaluations/UPDATE_VALID_RANK_REJECTED',
  UPDATE_VALID_RANK_FULFILLED: 'evaluations/UPDATE_VALID_RANK_FULFILLED',
};

export const LOAD_FOR_PHASE_REQUEST = 'evaluations/LOAD_FOR_PHASE_REQUEST';
export const LOAD_MULTIPLE_RESULT = 'evaluations/LOAD_MULTIPLE_RESULT';

export const loadForPhase = (phaseId, teamsOnly = false) => ({
  [RSAA]: {
    ...evaluationsAPI.forPhase(phaseId, teamsOnly),
    types: [
      LOAD_FOR_PHASE_REQUEST,
      {
        type: LOAD_MULTIPLE_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => {
            return {
              ...normalize(payload.evaluations, [schemas.evaluation]),
              phaseId,
            };
          }),
      },
      LOAD_MULTIPLE_RESULT,
    ],
  },
});

export const LOAD_FOR_PHASE_AND_TEAM_REQUEST = 'evaluations/LOAD_FOR_PHASE_AND_TEAM_REQUEST';

export const loadForPhaseAndTeam = (phaseId, teamId) => ({
  [RSAA]: {
    ...loadForTeam(phaseId, teamId),
    types: [
      LOAD_FOR_PHASE_AND_TEAM_REQUEST,
      {
        type: LOAD_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => {
            const normalized = normalize(payload.evaluation, schemas.evaluation);
            return {
              ...normalized,
              evaluation: normalized.entities.evaluations[normalized.result],
            };
          }),
      },
      LOAD_RESULT,
    ],
  },
});

export const loadSuccess = (evaluation) => ({
  type: actions.LOAD_SUCCESS,
  payload: { evaluation },
});

export const loadFail = (error) => ({
  type: actions.LOAD_FAIL,
  payload: { error },
});

export const update = (id, status) => ({
  type: actions.UPDATE,
  payload: { id, status },
});

export const reverseUpdate = (id, status) => ({
  type: actions.REVERSE_UPDATE,
  payload: { id, status },
});

export const initializeOrganizerResultDetails = (evaluationId, phaseId) => ({
  type: actions.INIT_ORGANIZER_RESULT_DETAILS,
  payload: { evaluationId, phaseId },
});

export const showEvaluationForTeam = (
  teamId,
  phaseId,
  eventId,
  isOrganizer = false,
  location,
  navigate,
) => ({
  type: actions.SHOW_EVALUATION_FOR_TEAM,
  payload: { teamId, phaseId, eventId, isOrganizer, location, navigate },
});

export const loadMultipleResult = createAction(
  'evaluations/LOAD_MULTIPLE',
  (phaseId, evaluations) => {
    return { phaseId, ...normalize(evaluations, [schemas.evaluation]) };
  },
);

export const LOAD_RESULT = 'evaluations/LOAD_RESULT';
export const LOAD_REQUEST = 'evaluations/LOAD_REQUEST';

export const load = (evaluationId) => ({
  [RSAA]: {
    ...evaluationsAPI.load(evaluationId),
    types: [
      LOAD_REQUEST,
      {
        type: LOAD_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then((payload) => {
            const normalized = normalize(payload.evaluation, schemas.evaluation);
            return {
              ...normalized,
              evaluation: normalized.entities.evaluations[normalized.result],
            };
          }),
      },
      LOAD_RESULT,
    ],
  },
});

export const updateFinalRank = (phaseId, finalRanks) => ({
  [RSAA]: {
    ...evaluationsAPI.updateFinalRank(phaseId, finalRanks),
    types: [
      actions.UPDATE_VALID_RANK_PENDING,
      actions.UPDATE_VALID_RANK_FULFILLED,
      actions.UPDATE_VALID_RANK_REJECTED,
    ],
  },
});
