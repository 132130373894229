import qs from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Col, Container, Row } from '@valid-eval/shared-react-components';
import { getPrefilledValues, save as signup } from 'data/actions/judgeProfiles';
import {
  error as notifyErrorAction,
  success as notifySuccessAction,
} from 'data/actions/notifications';
import { load as loadUser, login, logoutSuccess } from 'data/actions/users';
import {
  getBusinessDisciplines,
  getCurrentUser,
  getDocumentsForJudges,
  getEvent,
  getEventContent,
  getInitialValuesFromUser,
  getJobFunctions,
  getJudgeSignUpFields,
} from 'data/reducers';

import { BoxContainer } from 'components/Box';
import Loading from 'components/Loading';
import Title from 'components/Title';
import { getEnvVar } from 'config';
import { load as loadBusinessDisciplines } from 'data/actions/businessDisciplines';
import { load as loadContent } from 'data/actions/contents';
import { load as loadDocuments } from 'data/actions/documents';
import { load as loadEvent } from 'data/actions/events';
import { load as loadJobFunctions } from 'data/actions/jobFunctions';
import { validateInvitation } from 'data/actions/judgeInvitations';
import withRouter from 'routes/withRouter';
import SignUpLogin from 'screens/components/SignUpLogin';
import EventTitle from 'screens/teams/components/Title';
import { judgeNav } from 'utils/urls';

import Documents from '../app/components/Documents';
import ErrorBoundary from '../ErrorBoundary';
import ConfirmAddEmailModal from './components/ConfirmAddEmailModal';
import SignUpForm from './components/SignUpForm';

export class SignUp extends Component {
  state = {
    prefilledValues: {},
    errorInvitation: null,
    invitationDetails: false,
    isAddEmailModalOpened: false,
    invitationId: null,
    newEmailToAdd: null,
    validatingInvitation: true,
    values: {},
  };

  async loadPrefilledValues() {
    this.props.loadUser('me');
    const result = await this.props.getPrefilledValues();
    this.setState({ prefilledValues: result?.payload?.prefilled_values || {} });
  }

  async getInvitationDetails(id) {
    this.setState({ validatingInvitation: true });
    const result = await this.props.validateInvitation(id);

    this.setState({
      validatingInvitation: false,
      invitationDetails: true,
      errorInvitation: result?.payload?.response?.error,
      isAddEmailModalOpened: result?.payload?.affiliate_email || false,
      newEmailToAdd: result?.payload?.email,
      invitationId: id,
    });
  }

  async componentDidMount() {
    const {
      currentUser,
      loadEvent,
      loadContent,
      loadDocuments,
      loadJobFunctions,
      loadBusinessDisciplines,
      navigate,
      notifyError,
      params: { event_id },
      pathname,
      invitationId,
    } = this.props;

    this.getInvitationDetails(invitationId);

    if (this.state.errorInvitation) {
      notifyError(this.state.errorInvitation, undefined, 10000);
      navigate('/');
    }

    loadContent(event_id, 'Judge', 'Signup');
    loadDocuments(event_id);
    loadJobFunctions();
    loadBusinessDisciplines();
    const { payload } = await loadEvent(event_id);

    if (payload.status === 404) {
      notifyError(
        `The URL https://${getEnvVar('REACT_APP_HOST')}${pathname} does not exist on Valid Eval`,
      );
      navigate('/');
    }

    if (currentUser) this.loadPrefilledValues();
    else {
      if (getEnvVar('REACT_APP_OAUTH_KEYCLOAK') === '1') {
        try {
          this.props.loadUser('me');
        } catch {}
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (!this.state.invitationDetails && !this.state.validatingInvitation) {
      this.getInvitationDetails(this.props.invitationId);
    }

    if (this.state.errorInvitation) {
      this.props.notifyError(this.state.errorInvitation, undefined, 10000);
      this.props.navigate('/');
    }

    if (!prevProps.currentUser && this.props.currentUser) {
      this.setState({ invitationDetails: false });
      this.loadPrefilledValues();
    }
  }

  onJudgeSignUp = (values) => {
    const {
      signup,
      login,
      navigate,
      loadUser,
      notifyError,
      notifySuccess,
      anotherProfile,
      params: { event_id },
      invitationDataFromURL: { invitation_id },
    } = this.props;

    signup(event_id, invitation_id, anotherProfile, values).then((data) => {
      if (data.error) {
        if (data.payload?.status === 401) {
          this.onSessionFinished(values?.toJS?.());
        } else {
          notifyError(
            data.payload?.response?.error ||
              `Please try again. If you continue to receive this error, please contact ${getEnvVar(
                'REACT_APP_SUPPORT_EMAIL',
                'support@valideval.com',
              )}`,
          );
        }
      } else {
        notifySuccess('Judge registered successfully');
        // If there's anotherProfile do not login again
        const doLogin = anotherProfile
          ? Promise.resolve()
          : login(values.get('email'), values.get('password'), undefined);

        doLogin.then(() => {
          loadUser('me').then(() =>
            navigate(judgeNav.confirmation(event_id, data.payload.judge_profile.id)),
          );
        });
      }
    });
  };

  onToggleEmailModal = () => {
    this.setState({ isAddEmailModalOpened: !this.state.isAddEmailModalOpened });
  };

  onCancelAddEmailModal = () => {
    this.onToggleEmailModal();
    this.props.navigate('/');
  };

  onSessionFinished = (values) => {
    const { logoutSuccess, navigate, location } = this.props;

    this.setState({ values });

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    search.already_registered_email = 1;

    logoutSuccess();
    navigate(`${location.pathname}?${qs.stringify(search)}&already_registered_email=1`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  render() {
    const {
      loading,
      event,
      title,
      documents,
      initialValues,
      anotherProfile,
      profileSectionTitle,
      matchingSectionTitle,
      matchingSectionInstructions,
      jobFunctions,
      businessDisciplines,
      dynamicFields,
      currentUser,
    } = this.props;

    if (loading) return <Loading />;

    const formValues = {
      ...initialValues,
      ...this.state.prefilledValues,
      details: {
        ...this.state.prefilledValues,
      },
    };

    return (
      <>
        <ErrorBoundary>
          <ConfirmAddEmailModal
            show={this.state.isAddEmailModalOpened}
            onCancel={this.onCancelAddEmailModal}
            onToggle={this.onToggleEmailModal}
            emailToAdd={this.state.newEmailToAdd}
            invitationId={this.state.invitationId}
          />
          <Container>
            <SignUpLogin />
            <Row className="ms-0 me-0">
              <Col md={12} className="mt-5">
                <EventTitle
                  logo={event.get('logo_url')}
                  title={event.get('name')}
                  subTitle={title}
                  role={'judge'}
                />
              </Col>
            </Row>
            <BoxContainer>
              <Row className="m-0">
                <Col md={12}>
                  <BoxContainer>
                    <Title component="h2">{profileSectionTitle}</Title>
                  </BoxContainer>
                </Col>
              </Row>
              <Row className="m-0">
                <Col md={documents ? 7 : 12}>
                  <SignUpForm
                    initialValues={{ ...formValues, ...this.state.values }}
                    onSubmit={this.onJudgeSignUp}
                    dynamicFields={dynamicFields}
                    matchingSectionTitle={matchingSectionTitle}
                    matchingSectionInstructions={matchingSectionInstructions}
                    jobFunctions={jobFunctions}
                    businessDisciplines={businessDisciplines}
                    anotherProfile={anotherProfile}
                    currentUser={currentUser}
                  />
                </Col>
                {documents && (
                  <>
                    <Col md={1} className="clearfix" />
                    <Col md={4} className="well">
                      <Documents list={documents} />
                    </Col>
                  </>
                )}
              </Row>
            </BoxContainer>
          </Container>
        </ErrorBoundary>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { notification_id, invitation_id, first_name, last_name, email } = ownProps.query;

      const currentUser = getCurrentUser(state);
      const paramsForJudgeDetails = { ...ownProps, judge_sign_up_screen: true };

      const invitationDataFromURL = { invitation_id, first_name, last_name, email };
      const anotherProfile = !!currentUser;
      const notificationId = notification_id;
      const event = getEvent(state, ownProps.params.event_id);
      const dynamicFields = getJudgeSignUpFields(state, paramsForJudgeDetails);
      const loading = !event;
      const title = event && getEventContent(state, event.get('id'), 'Judge', 'Signup', 'Title');
      const profileSectionTitle =
        event &&
        getEventContent(state, event.get('id'), 'Judge', 'Signup', 'Profile Section Title');
      const matchingSectionTitle =
        event &&
        getEventContent(state, event.get('id'), 'Judge', 'Signup', 'Matching Section Title');
      const matchingSectionInstructions =
        event &&
        getEventContent(state, event.get('id'), 'Judge', 'Signup', 'Matching Section Instructions');
      const documents = getDocumentsForJudges(state, ownProps);
      const jobFunctions = getJobFunctions(state);
      const businessDisciplines = getBusinessDisciplines(state);

      const initialValues = anotherProfile
        ? getInitialValuesFromUser(state)
        : invitationDataFromURL;

      return {
        currentUser,
        loading,
        event,
        initialValues,
        anotherProfile,
        notificationId,
        invitationDataFromURL,
        title,
        documents,
        profileSectionTitle,
        matchingSectionTitle,
        matchingSectionInstructions,
        dynamicFields,
        jobFunctions,
        businessDisciplines,
        pathname: ownProps.location.pathname,
        invitationId: invitation_id,
      };
    },
    {
      loadEvent,
      signup,
      login,
      loadContent,
      loadDocuments,
      getDocumentsForJudges,
      loadJobFunctions,
      loadBusinessDisciplines,
      getPrefilledValues,
      loadUser,
      notifySuccess: notifySuccessAction,
      notifyError: notifyErrorAction,
      validateInvitation,
      logoutSuccess,
    },
  )(SignUp),
);
