import { Button } from '@valid-eval/shared-react-components';
import PropTypes from 'prop-types';

import Section from '../Section';
import Styles from './SigmasByJudge.module.scss';
import cx from 'classnames';
import { getRangeBasedClassName } from '../helpers';

function SigmasByJudge({ teamId, judges, isZScore, onClick }) {
  judges = Array.isArray(judges) ? judges : [];
  if (isZScore) {
    judges = judges.sort(({ sigma: a }, { sigma: b }) => b - a);
  } else {
    judges = judges.sort((a, b) => a.rank - b.rank);
  }
  const min = judges.slice().shift();
  const max = judges.slice().pop();

  return (
    <Section>
      {judges.map(({ id, name, sigma, rank, ...rest }) => (
        <div className="d-flex flex-wrap align-items-center mb-2" key={`sigma-by-judge-${id}`}>
          <div
            className={cx(
              Styles.Score,
              isZScore
                ? getRangeBasedClassName(min.sigma, max.sigma, sigma)
                : getRangeBasedClassName(max.rank, min.rank, rank, true),
            )}
            data-test-id={`sigma-by-judge-${id}-team-${teamId}`}
          >
            {isZScore ? sigma?.toFixed?.(2) || 'N/A' : rank}
          </div>
          <div className={cx('text-truncate', Styles.JudgeName)}>
            <Button
              variant={'link'}
              className="p-0"
              onClick={() => onClick?.({ id, name, sigma, rank, ...rest })}
              data-test-id={`open-scores-by-judge-${id}`}
            >
              {name}
            </Button>
          </div>
        </div>
      ))}
    </Section>
  );
}

SigmasByJudge.propTypes = {
  onClick: PropTypes.func,
  isZScore: PropTypes.bool,
  judges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      sigma: PropTypes.number,
    }),
  ),
};

export default SigmasByJudge;
