import { Tab, TabsGroup } from '@valid-eval/shared-react-components';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { initialize, reset } from 'redux-form/immutable';

import { load as loadEvent, save as saveEvent } from 'data/actions/events';
import { loadForEvent as loadPhases, update as savePhase } from 'data/actions/phases';
import { Event } from 'data/features/eventsTypes';
import { Phase } from 'data/features/phaseTypes';
import { getEvent, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import debounce from 'utils/debounce-promise';
import { organizerNav } from 'utils/urls';

import GlobalSettings from './components/Settings/GlobalSettings';
import PhaseSettings from './components/Settings/PhaseSettings';
import TeamFeedbackAvailableModal from './components/Settings/TeamFeedbackAvailableModal';

type SettingsProps = {
  event: Event | null;
  eventId: string;
  loadEvent: (eventId: string) => Promise<void>;
  loadPhases: (eventId: string) => Promise<void>;
  phase: Phase | null;
  saveEvent: (eventId: string, values: any) => Promise<void>;
  savePhase: (phaseId: string, values: any) => Promise<void>;
  navigate: (url: string) => void;
  reset: (formName: string) => void;
  initialize: (formName: string, values: any) => void;
};

const Settings = ({
  event,
  eventId,
  loadEvent,
  loadPhases,
  phase,
  saveEvent,
  savePhase,
  navigate,
  reset,
  initialize,
}: SettingsProps) => {
  const feedbackEnabledRef = useRef(phase?.feedback_enabled);
  const [showFeedbackAvailableModal, setShowFeedbackAvailableModal] = useState(false);
  const [newPhase, setNewPhase] = useState<any | null>(null);

  useEffect(() => {
    feedbackEnabledRef.current = phase?.feedback_enabled;
  }, [phase?.id]);

  // On mount, load event and phases
  useEffect(() => {
    loadEvent(eventId);
    loadPhases(eventId);
  }, [eventId, loadEvent, loadPhases]);

  useEffect(() => {
    if (phase?.id) initialize('phaseSettingsForm', phase);
  }, [phase?.id, initialize]);

  // Handle submit global settings
  const handleSubmitGlobalSettings = debounce(async (values: any) => {
    saveEvent(eventId, values);
  }, 500);

  // Handle submit phase settings
  const handleSubmitPhaseSettings = debounce(async (values: Partial<Phase>) => {
    const newFeedbackEnabled = values.feedback_enabled;
    if (newFeedbackEnabled && !feedbackEnabledRef.current) {
      setNewPhase(values);
      setShowFeedbackAvailableModal(true);
    } else {
      savePhase(values.id!!, values);
    }
    feedbackEnabledRef.current = newFeedbackEnabled;
  }, 1000);

  // Handle confirm feedback available modal
  async function handleConfirmFeedbackAvailableModal(sendNotification: boolean) {
    await savePhase(newPhase.id, newPhase);
    setShowFeedbackAvailableModal(false);
    setNewPhase(null);
    if (sendNotification) navigate(organizerNav.teamManage(eventId));
  }

  // Handle cancel feedback available modal
  function handleCancelFeedbackAvailableModal() {
    reset('phaseSettingsForm');
    setShowFeedbackAvailableModal(false);
    setNewPhase(null);
  }

  return (
    <>
      <TabsGroup className="mb-4" mountOnEnter unmountOnExit>
        <Tab eventKey="global" title="Global Settings">
          {event && <GlobalSettings onSubmit={handleSubmitGlobalSettings} />}
        </Tab>
        <Tab eventKey="phase" title="Phase Settings">
          {phase && <PhaseSettings onSubmit={handleSubmitPhaseSettings} />}
        </Tab>
      </TabsGroup>
      <TeamFeedbackAvailableModal
        show={showFeedbackAvailableModal}
        onConfirm={handleConfirmFeedbackAvailableModal}
        onCancel={handleCancelFeedbackAvailableModal}
      />
    </>
  );
};

export default compose(
  withRouter,
  connect(
    (state, props: { params: { event_id: string } }) => {
      const eventId = props.params.event_id;
      const event = getEvent(state, eventId)?.toJS();
      const phase = getSelectedPhase(state, props)?.toJS();

      return {
        eventId,
        event,
        phase,
      };
    },
    {
      initialize,
      loadEvent,
      loadPhases,
      reset,
      saveEvent,
      savePhase,
    },
  ),
)(Settings);
