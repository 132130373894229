import { Alert, Form, FormGroup } from '@valid-eval/shared-react-components';
import { Field, reduxForm } from 'redux-form/immutable';

import LoadingButton from 'components/LoadingButton';

import classNames from 'classnames';
import styles from './NotificationSettingsForm.module.scss';

const fieldLabels = {
  judgeRegistration: 'Judge registration',
};

const Errors = ({ errors = {} }) => {
  if (Object.keys(errors).length) {
    return (
      <Alert variant="danger">
        {Object.keys(errors).map((key) => (
          <div key={key}>
            <strong>{fieldLabels[key]}</strong> {errors[key]}
          </div>
        ))}
      </Alert>
    );
  }

  return null;
};

const SETTING_LABELS = {
  push: 'Display a web notification for each new',
  email: 'Send me an email for each new',
  daily_digest: 'Email me a daily digest of all new',
};

const SettingItem = ({ field, type, field_modifier, label }) => (
  <li>
    <Field
      id={`${field}.${type}`}
      name={`${field}.${type}`}
      label={label || SETTING_LABELS[type] + ' ' + field_modifier}
      className={styles.cursorStyle}
      type="checkbox"
      component={(props) => <Form.Check {...props} {...props.input} />}
    />
  </li>
);

const NotificationSettingsForm = ({ isSubmitting = false, submitErrors = {}, handleSubmit }) => {
  return (
    <div>
      {submitErrors && (
        <Errors errors={submitErrors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
      )}

      <Form onSubmit={handleSubmit}>
        <div>
          <h3>Judge Sign-Up</h3>
          <h4>Notify me when new judges sign up</h4>
          <ul className={classNames(styles.ulStyle, styles.content)}>
            <SettingItem field="judge_signup" type="push" field_modifier="judge" />
            <SettingItem field="judge_signup" type="email" field_modifier="judge" />
            <SettingItem field="judge_signup" type="daily_digest" field_modifier="judges" />
          </ul>
          <h3>Team Sign-Up</h3>
          <h4 className="text-bold">Notify me when new teams sign up</h4>
          <ul className={classNames(styles.ulStyle, styles.content)}>
            <SettingItem field="team_signup" type="push" field_modifier="team" />
            <SettingItem field="team_signup" type="email" field_modifier="team" />
            <SettingItem field="team_signup" type="daily_digest" field_modifier="teams" />
          </ul>
          {/* <h3>Judging Window Closes</h3>
          <h4>
            Notify me one week before and then again two days before the judging window closes for
            each phase of a cohort for which I am an Organizer.
          </h4>
          <ul>
            <SettingItem field='judging_window_closes' type='push' />
            <SettingItem field='judging_window_closes' type='email' />
          </ul> */}
        </div>
        <FormGroup className="mt-3 mb-3">
          <div className="mt-3 mb-3">
            <LoadingButton
              loading={isSubmitting}
              variant="primary"
              type="submit"
              className="pull-down"
            >
              Save Notification Settings
            </LoadingButton>
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: 'notificationSettings',
})(NotificationSettingsForm);
