import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { get, post } from 'data/services/config';
import { JudgeEvaluation } from './judgeEvaluationsTypes';

// Adapter for operate and normalize the data
export const judgeEvaluationsAdapter = createEntityAdapter<JudgeEvaluation>();

// Initial state
const initialState = judgeEvaluationsAdapter.getInitialState({
  loading: false,
});

// Actions
export const fetchJudgeEvaluations = createAsyncThunk(
  'judgeEvaluations/fetch',
  async (
    { eventId, judgeId, date }: { eventId: string; judgeId: string; date: string },
    { rejectWithValue },
  ) => {
    const dateParam = date !== undefined ? `?date=${date}` : '';
    try {
      return await get(`/events/${eventId}/judge_profiles/${judgeId}/evaluations${dateParam}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createScore = createAsyncThunk(
  'judgeEvaluations/createScore',
  async (
    { eventId, judgeEvaluationId }: { eventId: string; judgeEvaluationId: string },
    { rejectWithValue },
  ) => {
    const body = { score: { judge_evaluation_id: judgeEvaluationId } };
    try {
      return await post(`/events/${eventId}/scores/`, body);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Slice, reducer and async actions
export const judgeEvaluationsSlice = createSlice({
  name: 'judgeEvaluationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJudgeEvaluations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJudgeEvaluations.fulfilled, (state, action) => {
        judgeEvaluationsAdapter.setAll(state, action.payload.judge_evaluations);
        state.loading = false;
      })
      .addCase(fetchJudgeEvaluations.rejected, (state) => {
        state.loading = false;
      })
      .addCase('judgeProfiles/RECUSE_RESULT', (state, action) => {
        // @ts-expect-error TODO: redux-epic: once recuse is migrated this needs to be updated
        judgeEvaluationsAdapter.removeOne(state, action.payload?.judgeEvaluationId);
      });
  },
  selectors: {
    getIsLoading: (state) => state.loading,
    getEvaluationsByJudge: createSelector(
      judgeEvaluationsAdapter.getSelectors().selectAll,
      (_, judgeId: string) => judgeId,
      (judgeEvaluations: JudgeEvaluation[], judgeId) =>
        judgeEvaluations.filter((ev) => ev.judge_id === judgeId),
    ),
  },
});

export default judgeEvaluationsSlice.reducer;
export const fromJudgeEvaluations = judgeEvaluationsSlice.selectors;
