import { apiHeaders, apiURL } from '../services/config';

export const updateManyRoleStatuses = (phaseId, entity, active, ids = null) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ active, role: entity, ids }),
  endpoint: apiURL(`/phases/${phaseId}/role_statuses`),
});

export const updateRoleStatus = (phaseId, entity, roleId, active) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    role_status: {
      id: roleId,
      role: entity,
      active,
    },
  }),
  endpoint: apiURL(`/phases/${phaseId}/role_statuses/${roleId}`),
});

export const loadRoleSummaries = (phaseId, entity) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/role_summaries?role=${entity}`),
});

export const loadInactiveTeams = (phaseId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/role_summaries/inactive_teams`),
});
