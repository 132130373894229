import PropTypes from 'prop-types';

import Section from 'screens/app/results/components/Section';
import StatusButton from 'screens/app/results/components/StatusButton';
import Styles from './TeamStatusStats.module.scss';
import cx from 'classnames';
import { isTeamLead } from 'utils';
import { getBucketFromScore } from 'components/helpers';

function getAgreementStrengthStyle(value) {
  if (value >= 50) {
    const lighten = Math.round((1 - (value - 50) / 50) * 100);
    return `positive-value-${lighten}`;
  } else {
    const lighten = Math.round((1 - (49 - value) / 49) * 100);
    return `negative-value-${lighten}`;
  }
}

function TeamStatusStats({
  event,
  isZScore,
  status,
  evaluations,
  validScore,
  rankStrength,
  agreementStrength,
  onSelect,
  teamId,
  finalRankEnabled,
  finalRank,
}) {
  const isTeamLeadAndQualitativeMode =
    isTeamLead() && event?.judging_ux_mode === 'qualitative_only_mode';

  return (
    <Section className={'d-flex felx-column'}>
      {!finalRankEnabled && <StatusButton status={status} onSelect={onSelect} teamId={teamId} />}
      {finalRankEnabled && (
        <div
          className={cx(
            'd-flex flex-row justify-content-center align-items-center',
            Styles.FinalRank,
          )}
        >
          <span className="me-2">PROGRAM RANK</span>
          <big>{finalRank}</big>
        </div>
      )}
      <div className="mt-3">
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <span className={Styles.Label}>Evaluations</span>
          <span data-test-id={`team-status-stat-${teamId}-evalutaions`} className={Styles.Value}>
            {evaluations}
          </span>
        </div>
        {isZScore && (
          <div className="d-flex flex-row justify-content-between align-items-center mb-2">
            <span className={Styles.Label}>Valid Score&trade;</span>
            <span data-test-id={`team-status-stat-${teamId}-valid-score`} className={Styles.Value}>
              {isTeamLeadAndQualitativeMode
                ? getBucketFromScore(validScore, event.qualitative_methodology?.buckets)
                    ?.abbreviation || 'N/A'
                : validScore?.toFixed?.(2)}
            </span>
          </div>
        )}
        {!isZScore && (
          <div className="d-flex flex-row justify-content-between align-items-center mb-2">
            <span className={Styles.Label}>Rank Strength</span>
            <span
              data-test-id={`team-status-stat-${teamId}-rank-strength`}
              className={cx(
                Styles.Value,
                Styles.ValueSquare,
                getAgreementStrengthStyle(rankStrength),
              )}
            >
              {rankStrength ?? 'N/A'}
            </span>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className={Styles.Label}>Agreement Strength</span>
          <span
            data-test-id={`team-status-stat-${teamId}-agreement-strength`}
            className={cx(
              Styles.Value,
              Styles.ValueSquare,
              getAgreementStrengthStyle(agreementStrength),
            )}
          >
            {agreementStrength ?? 'N/A'}
          </span>
        </div>
      </div>
    </Section>
  );
}

TeamStatusStats.propTypes = {
  isZScore: PropTypes.bool,
  status: PropTypes.string,
  evaluations: PropTypes.number,
  validScore: PropTypes.number,
  agreementStrength: PropTypes.number,
  onSelect: PropTypes.func,
};

export default TeamStatusStats;
