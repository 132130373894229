import * as actions from '../actions/evaluations';

import { combineActions, handleActions } from 'redux-actions';
import { fromJS, is as isEqual } from 'immutable';

import { createSelector } from 'reselect';

const {
  actions: { UPDATE, REVERSE_UPDATE },
  LOAD_RESULT,
  LOAD_REQUEST,
  LOAD_MULTIPLE_RESULT,
  LOAD_FOR_PHASE_REQUEST,
  LOAD_FOR_PHASE_AND_TEAM_REQUEST,
} = actions;
const initialState = fromJS({ data: {}, loading: false, byPhase: {} });

const evaluations = handleActions(
  {
    [combineActions(LOAD_REQUEST, LOAD_FOR_PHASE_REQUEST, LOAD_FOR_PHASE_AND_TEAM_REQUEST)]: (
      state,
      action,
    ) => state.set('loading', true),
    [LOAD_RESULT]: {
      next(state, action) {
        const evaluation = fromJS(action.payload.evaluation);
        const pathKey = ['data', evaluation.get('id')];
        const updated = state.set('loading', false).mergeIn(pathKey, evaluation);
        const sameLoading = state.get('loading') === updated.get('loading');
        const sameEvaluation = isEqual(state.getIn(pathKey), evaluation);
        return sameLoading && sameEvaluation ? state : updated;
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [combineActions(UPDATE, REVERSE_UPDATE)]: (state, action) => {
      const { id, status } = action.payload;
      return state.setIn(['data', String(id), 'status'], status);
    },
    [LOAD_MULTIPLE_RESULT]: {
      next(state, action) {
        return state
          .set('loading', false)
          .setIn(['byPhase', action.payload.phaseId], action.payload.result)
          .mergeDeepIn(['data'], fromJS(action.payload.entities.evaluations));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
  },
  initialState,
);

export const getData = (state) => state.get('data');
export const getList = (state) => state.get('data').valueSeq();
export const isLoading = (state) => state.get('loading');
export const getById = (state, id) => state.getIn(['data', id]);
export const getByPhaseAndTeam = (state, phaseId, teamId) =>
  state
    .get('data')
    .find(
      (evaluation) => evaluation.get('team') === teamId && evaluation.get('phase_id') === phaseId,
    );
export const getByPhase = createSelector(
  getData,
  (state, phaseId) => state.getIn(['byPhase', phaseId], []),
  (evals, ids) => ids.map((id) => evals.get(id)),
);

export const getTeamIdsByPhase = createSelector(
  getData,
  (state, phaseId) => state.getIn(['byPhase', phaseId], []),
  (evals, ids) => ids.map((id) => evals.getIn([id, 'team'])),
);

export default evaluations;
