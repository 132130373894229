import { apiHeaders, apiURL } from '../services/config';

export const load = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/judge_profiles/${id}`),
});

export const loadJobFunctions = () => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/judge_profiles/job_functions`),
});

export const getPrefilledValues = () => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/judge_profiles/judge_profile_prefilled_values`),
});

export const loadBusinessDisciplines = () => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/judge_profiles/business_disciplines`),
});

export const update = (id, values) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ judge_profile: values }),
  endpoint: apiURL(`/judge_profiles/${id}`),
});

export const recuse = (id, teamId, phaseId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ team_id: teamId, phase_id: phaseId }),
  endpoint: apiURL(`/judge_profiles/${id}/recuse`),
});

export const create = (event_id, invitation_id, another_profile, values) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ event_id, invitation_id, another_profile, judge_profile: values }),
  endpoint: apiURL(`/judge_profiles`),
});

export const loadForEvent = (eventId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${eventId}/judge_profiles`),
});

export const unassignedTeams = (phaseId, judgeId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeId}/unassigned_teams`),
});
