import { Button, Col, Form, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { success as notifySuccess } from 'data/actions/notifications';

import BoxContainer from './BoxContainer';
import Styles from './Styles.module.scss';
import { StepProps } from './types';

export const VerifyCode = ({
  currentStep,
  configureOtp,
  otpToken,
  setError,
  onToggle,
  onMFAConfigured,
}: StepProps) => {
  const [displayedError, setDisplayedError] = useState(false);
  const [digits, setDigits] = useState<Record<string, string>>({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
  });
  const dispatch = useDispatch();

  const digitOne = useRef<any>(null);
  const digitTwo = useRef<any>(null);
  const digitThree = useRef<any>(null);
  const digitFour = useRef<any>(null);
  const digitFive = useRef<any>(null);
  const digitSix = useRef<any>(null);
  const submitVerify = useRef<any>(null);

  useEffect(() => {
    return () => {
      setError?.('');
    };
  }, []);

  if (currentStep !== 8) {
    return null;
  }

  // @ts-ignore
  const handleConfigureOtp = async (event) => {
    event.preventDefault();
    setError?.('');
    setDisplayedError(false);
    const values = Object.values(digits);
    const code = values.join('');

    if (!values.every(Boolean)) {
      setDisplayedError(true);
      return null;
    }

    try {
      const response: any = await configureOtp?.(code, otpToken);
      if (response.error) {
        setError?.('The MFA code is invalid, please retry.');
        setDisplayedError(true);
      } else {
        onMFAConfigured?.();
        dispatch(notifySuccess('Multi-Factor Authentication successfully configured', 'Success'));
        onToggle?.();
      }
    } catch {}
  };

  const handleSetDigits = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      const savedDigits = { ...digits };
      savedDigits[String(index)] = e.target.value;
      setDigits({ ...digits, ...savedDigits });
      switch (true) {
        case index === 1 && e.target.value !== '':
          digitTwo.current.focus();
          break;
        case index === 2 && e.target.value !== '':
          digitThree.current.focus();
          break;
        case index === 2 && e.target.value === '':
          digitOne.current.focus();
          break;
        case index === 3 && e.target.value !== '':
          digitFour.current.focus();
          break;
        case index === 3 && e.target.value === '':
          digitTwo.current.focus();
          break;
        case index === 4 && e.target.value !== '':
          digitFive.current.focus();
          break;
        case index === 4 && e.target.value === '':
          digitThree.current.focus();
          break;
        case index === 5 && e.target.value !== '':
          digitSix.current.focus();
          break;
        case index === 5 && e.target.value === '':
          digitFour.current.focus();
          break;
        case index === 6 && e.target.value !== '':
          submitVerify.current.focus();
          break;
        case index === 6 && e.target.value === '':
          digitFive.current.focus();
          break;
      }
    }
  };

  const handlePaste = (e: any) => {
    const copiedCode = e.clipboardData.getData('Text').split('');
    if (copiedCode.length === 6) {
      setDigits({
        1: copiedCode[0],
        2: copiedCode[1],
        3: copiedCode[2],
        4: copiedCode[3],
        5: copiedCode[4],
        6: copiedCode[5],
      });
      submitVerify.current.focus();
    }
  };

  return (
    <BoxContainer title="Enter the generated code by your authentication app:">
      <>
        <Form onSubmit={handleConfigureOtp}>
          <Row>
            <p>
              <strong>Enter the 6-digit code:</strong>
            </p>
          </Row>
          <Row>
            <Col xs={2}>
              <Form.Control
                maxLength={1}
                className={cx(Styles.InputDigit, displayedError && Styles.InputDigitError)}
                onChange={handleSetDigits(1)}
                ref={digitOne}
                autoFocus
                onPaste={handlePaste}
                value={digits?.[1] !== '' ? digits[1] : ''}
                id="first-digit"
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                maxLength={1}
                className={cx(Styles.InputDigit, displayedError && Styles.InputDigitError)}
                onChange={handleSetDigits(2)}
                ref={digitTwo}
                value={digits?.[2] !== '' ? digits[2] : ''}
                id="second-digit"
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                maxLength={1}
                className={cx(Styles.InputDigit, displayedError && Styles.InputDigitError)}
                onChange={handleSetDigits(3)}
                ref={digitThree}
                value={digits?.[3] !== '' ? digits[3] : ''}
                id="third-digit"
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                maxLength={1}
                className={cx(Styles.InputDigit, displayedError && Styles.InputDigitError)}
                onChange={handleSetDigits(4)}
                ref={digitFour}
                value={digits?.[4] !== '' ? digits[4] : ''}
                id="fourth-digit"
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                maxLength={1}
                className={cx(Styles.InputDigit, displayedError && Styles.InputDigitError)}
                onChange={handleSetDigits(5)}
                ref={digitFive}
                value={digits?.[5] !== '' ? digits[5] : ''}
                id="fifth-digit"
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                maxLength={1}
                className={cx(Styles.InputDigit, displayedError && Styles.InputDigitError)}
                onChange={handleSetDigits(6)}
                ref={digitSix}
                value={digits?.[6] !== '' ? digits[6] : ''}
                id="sixth-digit"
              />
            </Col>
          </Row>
          <Row>
            <Button
              type={'submit'}
              variant={'primary'}
              className={cx('pe-4 ps-4', Styles.VerifyDigitsBtn)}
              ref={submitVerify}
              id="verify-mfa-code-btn"
            >
              Verify
            </Button>
          </Row>
        </Form>
      </>
    </BoxContainer>
  );
};

export default VerifyCode;
