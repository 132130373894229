import { Col, Row } from '@valid-eval/shared-react-components';

import ActiveFilters from 'screens/app/events/components/filters/ActiveFilters';
import FiltersDropdown from 'screens/app/events/components/filters/FiltersDropdown';
import TextFilter from 'screens/app/events/components/filters/TextFilter';

type ControlsProps = {
  placeholder: string;
  availableFilters: any[];
  appliedFilters: any[];
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  info?: string;
};

export const CommonControls = ({
  placeholder,
  availableFilters,
  appliedFilters,
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  info,
}: ControlsProps) => {
  return (
    <Row className={'mb-1'}>
      <TextFilter placeholder={placeholder} search={onTextFilterChange} />
      <FiltersDropdown
        availableFilters={availableFilters}
        onFiltersChange={onOtherFiltersChange}
        filters={filters}
      />
      <ActiveFilters filters={appliedFilters} onFiltersChange={onOtherFiltersChange} />
      {info && (
        <Col className="col-auto flex-grow-1 d-inline-flex align-items-center justify-content-end text-muted fw-bold">
          <small>{info}</small>
        </Col>
      )}
    </Row>
  );
};
