/**
 * Work around for blocked window.open
 * @param {String} url
 */

const popupBlockerChecker = {
  check(popupWindow, callback) {
    let scope = this;
    if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        setTimeout(() => {
          scope.isPopupBlocked(scope, popupWindow, callback);
        }, 200);
      } else {
        popupWindow.onload = () => {
          scope.isPopupBlocked(scope, popupWindow, callback);
        };
      }
    } else {
      scope.displayError(callback);
    }
  },
  isPopupBlocked(scope, popupWindow, callback) {
    if (!popupWindow || popupWindow?.closed) {
      scope.displayError(callback);
    }
  },
  displayError(callback) {
    callback();
  },
};

export const openReportURL = (url, blank, callback) => {
  if (blank) {
    const popup = window.open(url, '_blank');
    popupBlockerChecker.check(popup, callback);
  } else {
    const link = document.createElement('a');
    link.setAttribute('download', 'Report');
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
