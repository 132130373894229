import * as rubricsAPI from '../services/rubrics';

import { RSAA } from 'redux-api-middleware';
import { getRubric } from '../reducers';

export const LOAD_RUBRIC_REQUEST = 'rubrics/LOAD_REQUEST';
export const LOAD_RUBRIC_RESULT = 'rubrics/LOAD_RESULT';
export const LOAD_FAIL = 'fail';

export const load = (phaseId, useCached = false) => ({
  [RSAA]: {
    ...rubricsAPI.load(phaseId),
    bailout: (state) => useCached && getRubric(state, phaseId),
    types: [LOAD_RUBRIC_REQUEST, LOAD_RUBRIC_RESULT, LOAD_RUBRIC_RESULT],
  },
});
