import copy from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import { error, success } from 'data/actions/notifications';
import {
  deleteInvitation,
  loadMembers,
  load as loadTeam,
  resendInvitation,
  updateMemberRole,
} from 'data/actions/teams';
import { load as loadUser } from 'data/actions/users';
import { getCurrentUserId, getMembersByTeam } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { publicUrlFor } from 'utils';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import MembersEditRoleModal from './components/MembersEditRoleModal';
import MembersInviteModal from './components/MembersInviteModal';
import MembersResendModal from './components/MembersResendModal';
import MembersTable from './components/MembersTable';
import ProfileNavigation from './components/ProfileNavigation';

function Members({
  onMembersLoaded,
  isSignUpForm,
  isOrganizer,
  isNavigator,
  isTeamLead,
  currentUserId,
  loadMembers,
  loadUser,
  loadTeam,
  deleteInvitation,
  resendInvitation,
  updateMemberRole,
  members,
  notifyError,
  notifySuccess,
  isTeamMemberEditor,
  params,
  teamId,
}) {
  const initialLoad = useRef(true);
  const [isLoading, loading, loaded] = useBooleanFlag();
  const [isSaving, saving, saved] = useBooleanFlag();
  const [showInviteMembersModal, setShowInviteMembersModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [showResendModal, setShowResendModal] = useState(false);

  const handleLoadMembers = async (reload = true) => {
    initialLoad.current = !reload;
    loading();
    try {
      await loadMembers(teamId);
      onMembersLoaded?.(reload);
    } finally {
      loaded();
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    loadUser('me');

    if (teamId) {
      loadTeam(teamId);
      handleLoadMembers(false);
    }
  }, [teamId]);

  const handleCloseInviteMembersModal = () => setShowInviteMembersModal(false);
  const handleShowInviteMembersModal = () => setShowInviteMembersModal(true);

  const copyInviteURLToClipboard = async (teamMember) => {
    try {
      let invitePath = `/teams/${teamId}/member_invitations/${
        teamMember.id
      }/accept?email=${encodeURIComponent(teamMember.email)}&first_name=${encodeURIComponent(
        teamMember.first_name || '',
      )}&last_name=${encodeURIComponent(teamMember.last_name || '')}`;

      let inviteURL = publicUrlFor(invitePath);
      copy(inviteURL);
      notifySuccess(t('members.copyURLSuccess', 'Sign up URL copied'));
    } catch (err) {
      notifyError(t('members.copyURLfail', 'Sign up URL could not be copied'), 'error');
    }
  };

  const handleResendInvitation = async (invitationId) => {
    const { error } = await resendInvitation(teamId, invitationId);
    if (error) notifyError('Failed to resend the invitation.');
    else {
      notifySuccess('The invitation has been sent');
      handleLoadMembers();
    }
  };

  const handleDeleteInvitation = async (invitationId, userId) => {
    const { error } = await deleteInvitation(teamId, invitationId, userId);
    if (error) notifyError('Failed to delete the invitation, please try again.');
    else {
      notifySuccess('The invitation has been deleted');
      handleLoadMembers();
    }
  };

  const handleUpdateMemberRole = (invitationId, userId, role) => {
    saving();
    try {
      setSelectedRole({ invitationId, userId, role });
      if (role === 'primary') {
        const member = members.find((m) => m.user_id === userId);
        if (!member.accepted) {
          setShowResendModal(true);
          return;
        }
      }
      setShowEditRoleModal(true);
    } finally {
      saved();
    }
  };

  const confirmRoleChange = async () => {
    if (selectedRole) {
      const { invitationId, userId, role } = selectedRole;
      const response = await updateMemberRole(teamId, invitationId, userId, role);
      if (response.error) {
        notifyError('Failed to update the role, please try again.');
      } else {
        notifySuccess('The role has been updated');
        handleLoadMembers();
      }
    }
    setShowEditRoleModal(false);
  };

  const existingEmails = members.map((member) => member.email);

  let Wrapper = ProfileNavigation;

  if (isOrganizer || isNavigator || isTeamLead) {
    Wrapper = (props) => (
      <div {...props} className={'position-absolute'} style={{ right: 10, top: 100 }} />
    );
  } else if (isSignUpForm) {
    Wrapper = (props) => <div {...props} className="text-end my-4" />;
  }

  return (
    <>
      <MembersInviteModal
        teamId={teamId}
        afterSend={handleLoadMembers}
        existingEmails={existingEmails}
        onClose={handleCloseInviteMembersModal}
        show={showInviteMembersModal}
      />
      <Wrapper>
        {(isTeamMemberEditor || isOrganizer) && (
          <Button
            variant="success"
            onClick={handleShowInviteMembersModal}
            data-test-id="add-team-member-button"
          >
            <i className="fa-solid fa-plus me-2" /> Add new
          </Button>
        )}
      </Wrapper>
      <MembersEditRoleModal
        show={showEditRoleModal}
        selectedRole={selectedRole}
        onCancel={() => setShowEditRoleModal(false)}
        onConfirm={confirmRoleChange}
        modalBody={`Are you sure you want to change the role to ${
          selectedRole ? selectedRole.label : ''
        }?`}
        isSaving={isSaving}
      />
      <MembersResendModal
        show={showResendModal}
        onCancel={() => setShowResendModal(false)}
        onConfirm={() => {
          handleResendInvitation(selectedRole?.invitationId);
          setShowResendModal(false);
        }}
        isSaving={isSaving}
      />
      {isLoading && initialLoad.current ? (
        <Loading />
      ) : (
        <MembersTable
          isSignUpForm={isSignUpForm}
          currentUserId={currentUserId}
          isTeamMemberEditor={isTeamMemberEditor || isOrganizer}
          data={members}
          onDeleteInvitation={handleDeleteInvitation}
          onResendInvitation={handleResendInvitation}
          onUpdateMemberRole={handleUpdateMemberRole}
          onCopyInviteURLToClipboard={copyInviteURLToClipboard}
        />
      )}
    </>
  );
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const teamId = ownProps.teamId || ownProps?.params?.team_id;
      const currentUserId = getCurrentUserId(state);
      const members = getMembersByTeam(state, teamId);
      const currentUserRole = members.find((m) => m.user_id === currentUserId)?.role;
      const isTeamMemberEditor = ['editor', 'primary'].includes(currentUserRole);

      return {
        teamId,
        members,
        currentUserId,
        isTeamMemberEditor,
      };
    },
    {
      loadMembers,
      loadUser,
      loadTeam,
      deleteInvitation,
      resendInvitation,
      updateMemberRole,
      notifyError: error,
      notifySuccess: success,
    },
  )(Members),
);
