import { getCurrentUserEventIds, getNotificationsArray } from 'data/reducers';

import { AlertList } from 'react-bs-notifier';
import { Component } from 'react';
import { Container } from '@valid-eval/shared-react-components';
import MainFooter from './components/MainFooter';
import MainMenu from './components/MainMenu';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { loadMultiple as loadEventsAction } from 'data/actions/events';
import { remove as removeNotification } from 'data/actions/notifications';
import ErrorBoundary from './ErrorBoundary';
import CookiesBanner from './teams/components/CookiesBanner';

class Wrapper extends Component {
  static props;

  componentDidMount() {
    // TODO: Loading all events for the current user may be an extra effort.
    // We need to check if this can be removed or if there's a case that force us to it.
    const { loadEvents, eventIds } = this.props;

    if (eventIds && !eventIds.isEmpty()) {
      loadEvents();
    }
  }

  render() {
    const { children, alerts } = this.props;

    return (
      <Container fluid>
        <MainMenu />
        <AlertList alerts={alerts} onDismiss={this.props.removeNotification} />
        <div className="full-height-container">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
        <MainFooter />
        <CookiesBanner trackingCookies={true} eventId={'general'} />
      </Container>
    );
  }
}

Wrapper.displayName = 'Wrapper';
export default compose(
  connect(
    (state) => {
      const alerts = getNotificationsArray(state);
      const eventIds = getCurrentUserEventIds(state);
      return { alerts, eventIds };
    },
    { loadEvents: loadEventsAction, removeNotification },
  ),
)(Wrapper);
