import { Notification } from './types';
import { teamSignUp } from '../urls';

interface TeamProfileDraftsReminderNotification extends Notification {}

export const displayName = 'Pending Draft';

export const getActionButtons = (notification: TeamProfileDraftsReminderNotification) => [
  {
    title: `Pending Draft`,
    url: teamSignUp(notification.data.get('event_slug')),
    toggleSeen: true,
  },
];

export const getText = (notification: TeamProfileDraftsReminderNotification) =>
  `You have a draft application "${notification.data.get('team_name')}" that needs your
  attention. Please click the button below to complete the saved draft application. Draft
  applications cannot be seen by the program until the required data is complete and the
  submit button is clicked  The submit button will be disabled until all required fields
  or attachments are completed. The deadline to complete your application is fast
  approaching. `;
