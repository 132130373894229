import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import LinkContainer from 'components/LinkContainer';
import { teamNav } from 'utils/urls';

export default function ProfileNavigation({ children }) {
  const params = useParams();
  const location = useLocation();

  const profileUrl = teamNav.profile(params.event_id, params.team_id);
  const membersUrl = teamNav.members(params.event_id, params.team_id);
  const isProfileUrl = profileUrl === location.pathname;
  const isMembersUrl = membersUrl === location.pathname;

  return (
    <Row className={'mt-4 mb-3'}>
      <Col>
        <ButtonGroup>
          <LinkContainer to={profileUrl}>
            <Button
              data-test-id="results-navigation-scores-button"
              variant={isProfileUrl ? 'primary' : 'default'}
            >
              Profile Data
            </Button>
          </LinkContainer>

          <LinkContainer to={membersUrl}>
            <Button
              data-test-id="results-navigation-heatmap-button"
              variant={isMembersUrl ? 'primary' : 'default'}
            >
              Team Members
            </Button>
          </LinkContainer>
        </ButtonGroup>
      </Col>
      <Col className="text-end">{children}</Col>
    </Row>
  );
}
