import i18n from 'config/i18n';
import { loadLocale } from 'data/actions/locale';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getEventIdOrSlugFromPathname } from 'utils/urls';

/**
 * Redux connection
 */
type OwnProps = {
  children: React.ReactNode;
};

const mapState = () => ({});

const mapDispatch = { loadLocale };

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

/**
 * Locale loader component
 */

type LocaleLoaderProps = PropsFromRedux & OwnProps;

const LocaleLoader = ({ children, loadLocale }: LocaleLoaderProps) => {
  useTranslation();
  const eventIdOrSlug = getEventIdOrSlugFromPathname(useLocation().pathname);

  useEffect(() => {
    (async () => {
      const result = (await loadLocale(eventIdOrSlug)) as any;
      i18n.addResourceBundle('en', 'translation', result.payload, true, true);
    })();
  }, [eventIdOrSlug]);

  return children;
};

export default connector(LocaleLoader);
