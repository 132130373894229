import { Component } from 'react';

const DetectPrint = (WrappedComponent) =>
  class DetectPrint extends Component {
    state = { printing: false };

    list = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');

    handleEvent = (list) => {
      this.setState({ printing: !!list.matches });
    };

    UNSAFE_componentWillMount = () => {
      if (!this.list) return;

      this.list.addListener(this.handleEvent);
      this.handleEvent(this.list);
    };

    componentWillUnmount = () => {
      this.list && this.list.removeListener(this.handleEvent);
    };

    render = () => <WrappedComponent {...this.props} printing={this.state.printing} />;
  };

export default DetectPrint;
