import { OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';

const SearchHelpTooltip = () => {
  const { t } = useTranslation();
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <strong>{t('help.search')}</strong>
          </Tooltip>
        }
      >
        <i className="fa-solid fa-circle-question fa-lg text-primary"></i>
      </OverlayTrigger>
    </>
  );
};

export default SearchHelpTooltip;
