import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import { LOAD, LOAD_RESULT } from '../actions/phase_summaries';

const initialState = fromJS({
  data: {},
  loading: false,
});

const phase_summaries = handleActions(
  {
    [LOAD]: (state, action) => state.set('loading', true),
    [LOAD_RESULT]: {
      next(state, action) {
        return state
          .set('loading', false)
          .mergeIn(['data'], fromJS(action.payload.entities.phase_summaries));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
  },
  initialState,
);

export const getById = (state, id) => state.getIn(['data', String(id)]);

export default phase_summaries;
