import {
  LOAD_FOR_EVALUATION_REQUEST,
  LOAD_FOR_EVALUATION_RESULT,
  actions,
  setScoringData,
} from '../actions/scores';

import { Map, fromJS } from 'immutable';
import { combineActions, handleActions } from 'redux-actions';

import { createSelector } from 'reselect';
import range from 'lodash/range';

const initialState = fromJS({ loading: false, data: {}, byEvaluation: {} });

const dimensionScoreKeyPath = (scoreId, dimensionId) => [
  'data',
  scoreId,
  'dim_scores',
  dimensionId,
];
const clickedCriteriaKeyPath = (scoreId) => ['data', scoreId, 'clicked_criteria'];

const scores = handleActions(
  {
    [combineActions(actions.LOAD, actions.LOAD_MULTIPLE)]: (state, action) =>
      state.set('loading', true),
    [actions.CHANGE_DIM_SCORE]: (state, action) => {
      const { scoreId, dimensionId, value } = action.payload;
      return state.mergeIn(['data', scoreId, 'dim_scores'], { [dimensionId]: value });
    },
    [LOAD_FOR_EVALUATION_REQUEST]: (state, action) => {
      return state.setIn(['byEvaluation', action.payload.evaluationId, 'loading'], true);
    },
    [LOAD_FOR_EVALUATION_RESULT]: {
      next(state, action) {
        return state
          .setIn(
            ['byEvaluation', action.payload.evaluationId],
            Map({ list: action.payload.result, loading: false }),
          )
          .mergeIn(['data'], fromJS(action.payload.entities.scores));
      },
      throw(state, action) {
        return state.setIn(
          ['byEvaluation', action.payload.evaluationId],
          Map({ list: [], loading: false }),
        );
      },
    },
    [combineActions(
      actions.LOAD_RESULT,
      actions.ADD_CRITERION_CLICK_RESULT,
      actions.REMOVE_CRITERION_CLICK_RESULT,
      actions.SAVE_DIMENSION_SCORE_RESULT,
    )]: {
      next(state, action) {
        return state
          .set('loading', false)
          .mergeIn(['data'], fromJS(action.payload.entities.scores));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [setScoringData.toString()]: (state, action) => {
      const { scoreId, clickedCriteria, dimensionId, scoreValue } = action.payload;
      const withNewClickedCriteria = state.setIn(clickedCriteriaKeyPath(scoreId), clickedCriteria);
      const scoreShouldChange = scoreValue !== getDimensionScore(state, scoreId, dimensionId);
      return scoreShouldChange
        ? withNewClickedCriteria.setIn(dimensionScoreKeyPath(scoreId, dimensionId), scoreValue)
        : withNewClickedCriteria;
    },
  },
  initialState,
);

export const getValidScoresFromClickedCriteria = (grades, dimension, clickedCriteria) => {
  let lowerBound = Number.MAX_VALUE;
  let upperBound = 0;

  const selectedCriterion = clickedCriteria.reduce((acc, id) => ({ ...acc, [id]: true }), {});
  const selectedGradeValues = dimension
    .get('subdimensions')
    .flatMap((subD) => subD.get('criteria'))
    .filter((criterion) => selectedCriterion[criterion.get('id')])
    .groupBy((criterion) => criterion.get('gradeValue'))
    .keySeq();

  grades.forEach((grade) => {
    const isGradeSelected = selectedGradeValues.some(
      (gradeValue) => grade.get('rangemin') <= gradeValue && grade.get('rangemax') >= gradeValue,
    );
    if (isGradeSelected) {
      lowerBound = Math.min(lowerBound, Number(grade.get('rangemin')));
      upperBound = Math.max(upperBound, Number(grade.get('rangemax')));
    }
  });
  const validScores =
    lowerBound !== Number.MAX_VALUE && upperBound !== 0 ? range(lowerBound, upperBound + 1) : [];

  return new Set(validScores);
};

export const isLoading = (state) => state.get('loading');
export const getList = (state) => state.get('data');
export const getByProp = (state, propName, propValue) =>
  state.get('data').filter((value) => value.get(propName) === propValue);
export const getSingle = (state, id) => state.getIn(['data', id]);

export const getSelfScoreForPhase = (state, teamId, phaseId) => {
  return state
    .get('data')
    .find(
      (score) =>
        score.get('self_score') &&
        score.get('team_id') === teamId &&
        score.get('phase_id') === phaseId,
    );
};

export const getEvaluationScores = createSelector(
  (state) => state,
  (_, evaluationId) => evaluationId,
  (state, evaluationId) => {
    const list = state.getIn(['byEvaluation', evaluationId, 'list'], []);
    return list.map((scoreId, index) => state.getIn(['data', scoreId], {}).set('index', index + 1));
  },
);

export const getIsLoadingEvaluationScores = createSelector(
  (state) => state,
  (_, evaluationId) => evaluationId,
  (state, evaluationId) => {
    return state.getIn(['byEvaluation', evaluationId, 'loading'], false);
  },
);

export const getEvaluationScoresAsOptions = createSelector(
  getEvaluationScores,
  (evaluationScores) =>
    evaluationScores.map((score) => ({
      value: score.get('id'),
      label: `Judge #${score.get('index')}`,
    })),
);

export function getDimensionScore(state, scoreId, dimensionId) {
  return state.getIn(dimensionScoreKeyPath(scoreId, dimensionId));
}

export function getClickedCriteria(state, scoreId) {
  return state.getIn(clickedCriteriaKeyPath(scoreId));
}

export default scores;
