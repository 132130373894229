import * as api from 'data/services/locale';
import { RSAA } from 'redux-api-middleware';

export enum Actions {
  LOAD_PENDING = 'locale/LOAD_PENDING',
  LOAD_FULFILLED = 'locale/LOAD_FULFILLED',
  LOAD_REJECTED = 'locale/LOAD_REJECTED',
}

export const loadLocale = (eventIdOrSlug: string | undefined) => {
  return {
    [RSAA]: {
      ...api.load(eventIdOrSlug),
      types: [Actions.LOAD_PENDING, Actions.LOAD_FULFILLED, Actions.LOAD_REJECTED],
    },
  };
};
