import { Card, Form } from '@valid-eval/shared-react-components';
import { Map } from 'immutable';
import moment from 'moment';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';

import { Phase } from 'data/features/phaseTypes';
import { getEventFromURL, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { DATE_FORMAT_WITH_TIME } from 'utils';

import PhaseSettingsDates from './PhaseSettingsDates';
import PhaseSettingsFlags from './PhaseSettingsFlags';
import PhaseSettingsRubric from './PhaseSettingsRubric';

type PhaseSettingsProps = {
  onSubmit: (data: Partial<Phase>) => void;
};

const mapStateToProps = (state: Map<string, any>, ownProps: PhaseSettingsProps) => ({
  // @ts-expect-error ownProps not recognized as parameter
  event: getEventFromURL(state, ownProps)?.toJS(),
  phase: getSelectedPhase(state, ownProps)?.toJS(),
});

const connector = connect(mapStateToProps);

type PhaseSettingsForm = {
  id: string;
  startDate: string;
  endDate: string;
  feedbackEnabled: boolean;
  individualScoresEnabled: boolean;
  performanceEnabled: boolean;
  commentsEnabled: boolean;
  navigatorsViewResultsEnabled: boolean;
  finalRank: boolean;
};

const PhaseSettings = ({
  event,
  phase,
  onSubmit,
}: PhaseSettingsProps & ConnectedProps<typeof connector>) => {
  const currentTimezone = moment.tz.guess();

  const form = useForm<PhaseSettingsForm>({});

  // Reset form values when phase changes
  useEffect(() => {
    if (!phase?.id) return;

    form.reset({
      id: phase?.id,
      startDate: moment(phase?.start_date).tz(currentTimezone).format(DATE_FORMAT_WITH_TIME),
      endDate: moment(phase?.end_date).tz(currentTimezone).format(DATE_FORMAT_WITH_TIME),
      feedbackEnabled: phase?.feedback_enabled,
      individualScoresEnabled: phase?.individual_scores_enabled,
      performanceEnabled: phase?.performance_enabled,
      commentsEnabled: phase?.comments_enabled,
      navigatorsViewResultsEnabled: phase?.navigators_view_results_enabled,
      finalRank: phase?.final_rank,
    });
  }, [phase?.id]);

  // Submit form when values change
  useEffect(() => {
    const unsubscribe = form.watch((_, { type, name }) => {
      if (type !== 'change') return;

      // if startDate or endDate change, submit form to run validations,
      // call handleSubmit with form values otherwise
      if (name === 'startDate' || name === 'endDate') {
        form.handleSubmit(handleSubmit)();
      } else {
        handleSubmit(form.getValues());
      }
    });
    return () => unsubscribe.unsubscribe();
  }, [form.watch]);

  const handleSubmit = (data: PhaseSettingsForm) => {
    onSubmit({
      id: data.id,
      start_date: moment(data.startDate, DATE_FORMAT_WITH_TIME).tz(currentTimezone).toISOString(),
      end_date: moment(data.endDate, DATE_FORMAT_WITH_TIME).tz(currentTimezone).toISOString(),
      feedback_enabled: data.feedbackEnabled,
      individual_scores_enabled: data.individualScoresEnabled,
      performance_enabled: data.performanceEnabled,
      comments_enabled: data.commentsEnabled,
      navigators_view_results_enabled: data.navigatorsViewResultsEnabled,
      final_rank: data.finalRank,
    });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <Card>
          <Card.Body className="px-3 pt-3 pb-0">
            <PhaseSettingsDates event={event} phase={phase} />
            <PhaseSettingsRubric phase={phase} />
            <PhaseSettingsFlags />
          </Card.Body>
        </Card>
      </Form>
    </FormProvider>
  );
};

export default withRouter(connector(PhaseSettings));
