import { judgeNav } from '../urls';

export const displayName = 'Start Judging Reminder';

export const getActionButtons = (notification) => [
  {
    title: 'Start judging',
    url: judgeNav.evaluations(
      notification.data.get('event_id'),
      notification.data.get('judge_profile_id'),
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Get started judging for phase ${notification.data.get('phase_name')} in the ${
    notification.event
  } cohort`;
