import { Link } from 'react-router-dom';
import { getVersionUri } from 'utils';
import Briefcase from './assets/briefcase.png';
import ErrorBoundary from './ErrorBoundary';

const DeclineInvitationSuccess = () => {
  return (
    <ErrorBoundary>
      <div className="container">
        <div className="login-container mb-5">
          <h1 className="login-title mt-3 mb-3">Decline invitation</h1>
          <div className="login-box d-flex flex-column">
            <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
            <div style={{ flex: 1 }}>
              <p className="text-center">The invitation has been declined.</p>
              <div className="mt-5 d-grid">
                <Link to="/" className="btn btn-success">
                  Ok
                </Link>
              </div>
            </div>
            <div className="text-center mt-4 mb-2">
              <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

DeclineInvitationSuccess.displayName = 'DeclineInvitationSuccess';

export default DeclineInvitationSuccess;
