import { Card, ListGroup, ListGroupItem } from '@valid-eval/shared-react-components';

import classes from './UserCategories.module.scss';

const UserCategories = ({ groupedCategories }) => {
  const parentCategories = Object.keys(groupedCategories);
  return (
    <div>
      {parentCategories.map((masterCat) => (
        <Card key={masterCat}>
          <Card.Header>
            <Card.Title className={classes.titleCategory}>{masterCat}</Card.Title>
          </Card.Header>
          <ListGroup className="list-unstyled">
            {groupedCategories[masterCat].map((category) => (
              <ListGroupItem className={classes.category} key={category.name}>
                {category.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Card>
      ))}
    </div>
  );
};

UserCategories.displayName = 'UserCategories';
export default UserCategories;
