import { Button } from '@valid-eval/shared-react-components';

import { Link } from 'react-router-dom';

const Item = ({ children, active, url, style = null }) => (
  <Link to={url} className={active ? 'current' : ''}>
    <Button style={style}>
      <span>{children}</span>
    </Button>
  </Link>
);
export default Item;
