import CategoriesStepComponent from './WizardSteps/CategoriesStep';
import ContactInfoStepComponent from './WizardSteps/ContactInfoStep';
import DetailsStep from './WizardSteps/DetailsStep';
import DisabledStepComponent from './WizardSteps/DisabledStep';
import InstructionsAndResourcesStepComponent from './WizardSteps/InstructionsAndResourcesStep';
import TeamMembersStep from './WizardSteps/TeamMembersStep';
import UploadsStepComponent from './WizardSteps/UploadsStep';
import { Step } from './types';

export const DisabledStep: Step = {
  key: -1,
  id: 'disabled-step',
  title: 'auth.teams_sign_up.steps.disabled.title',
  status: 'notStarted',
  disabled: true,
  info: {
    requiredFieldsCount: 0,
    completedFieldsCount: 0,
    visited: false,
  },
  navigation: false,
  needDraft: false,
  component: DisabledStepComponent,
};

export const InstructionsStep: Step = {
  key: 0,
  id: 'instructions-step',
  title: 'auth.teams_sign_up.steps.instructions.title',
  status: 'notStarted',
  disabled: true,
  info: {
    requiredFieldsCount: 0,
    completedFieldsCount: 0,
    visited: false,
  },
  navigation: false,
  needDraft: false,
  component: InstructionsAndResourcesStepComponent,
};

export const FormSteps: Step[] = [
  {
    key: 1,
    id: 'contact-step',
    title: 'auth.teams_sign_up.steps.contact.title',
    status: 'notStarted',
    disabled: true,
    info: {
      requiredFieldsCount: 0,
      completedFieldsCount: 0,
      visited: false,
    },
    navigation: true,
    needDraft: false,
    component: ContactInfoStepComponent,
  },
  {
    key: 2,
    id: 'members-step',
    title: 'auth.teams_sign_up.steps.members.title',
    status: 'notStarted',
    disabled: true,
    needDraft: true,
    info: {
      requiredFieldsCount: 0,
      completedFieldsCount: 0,
      visited: false,
    },
    navigation: true,
    component: TeamMembersStep,
  },
  {
    key: 3,
    id: 'details-step',
    title: 'auth.teams_sign_up.steps.details.title',
    status: 'notStarted',
    disabled: true,
    needDraft: true,
    info: {
      requiredFieldsCount: 0,
      completedFieldsCount: 0,
      visited: false,
    },
    navigation: true,
    component: DetailsStep,
  },
  {
    key: 4,
    id: 'categories-step',
    title: 'auth.teams_sign_up.steps.categories.title',
    status: 'notStarted',
    disabled: true,
    info: {
      requiredFieldsCount: 0,
      completedFieldsCount: 0,
      visited: false,
    },
    navigation: true,
    needDraft: true,
    component: CategoriesStepComponent,
  },
  {
    key: 5,
    id: 'uploads-step',
    title: 'auth.teams_sign_up.steps.uploads.title',
    status: 'notStarted',
    disabled: true,
    info: {
      requiredFieldsCount: 0,
      completedFieldsCount: 0,
      visited: false,
    },
    navigation: true,
    needDraft: true,
    component: UploadsStepComponent,
  },
];

export const SignUpWizardSteps = [DisabledStep, InstructionsStep, ...FormSteps];
