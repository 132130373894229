export function getBucketFromScore(score: number, buckets: any[]): any {
  if (!buckets || !Array.isArray(buckets)) return {};

  return [...buckets]
    .sort((a, b) => b.sort_value - a.sort_value)
    .find((bucket) => bucket.raw_score_threshold <= score);
}

export function getBucketLabel(score: number, buckets: any[]): string {
  return getBucketFromScore(score, buckets)?.abbreviation;
}
