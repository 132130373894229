import { apiHeaders, apiURL } from '../services/config';

export const loadJudgeAssignments = (phaseId, judgeId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/judge_assignments/${judgeId}`),
});

export const loadJudgeSuggestions = (phaseId, judgeId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeId}/suggestions`),
});

export const assignToJudge = (phaseId, judgeProfileId, teamId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ judge_assignment: { team_id: teamId } }),
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeProfileId}/judge_assignments`),
});

export const removeAssignment = (phaseId, judgeId, teamId) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeId}/judge_assignments/${teamId}`),
});
