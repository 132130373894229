import { Button, Form } from '@valid-eval/shared-react-components';
import { email, required } from 'components/FormFields/validations';

import { Email } from 'components/FormFields';
import Errors from '../../components/Errors';

import { reduxForm } from 'redux-form/immutable';

const validations = [required, email];

const ForgotPassword = ({ handleSubmit, error, pristine, submitting, valid, warning }) => {
  const preventSubmit = pristine || submitting || !valid || warning;
  return (
    <div>
      <Errors
        errors={error && [error]}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      <Form onSubmit={handleSubmit}>
        <div className="login-input">
          <Email name="email" label="Email Address" validate={validations} />
        </div>
        <div className="mt-5 d-grid">
          <Button variant="success" type="submit" disabled={preventSubmit}>
            Request password change
          </Button>
        </div>
      </Form>
    </div>
  );
};

const warn = (values, props) => {
  if (values.get('email') === props.lastEmailRequested)
    return {
      _warning: 'Avoid sending consecutive reset password emails to the same user',
      email: 'Email already used',
    };
  return {};
};

export default reduxForm({ form: 'forgot-password', warn })(ForgotPassword);
