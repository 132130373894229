import * as api from '../services/reports';

import { RSAA } from 'redux-api-middleware';

//Action Constants
const actions = {
  CREATE_ASYNC_REPORT_PENDING: 'reports/CREATE_ASYNC_REPORT_PENDING',
  CREATE_ASYNC_REPORT_FULFILLED: 'reports/CREATE_ASYNC_REPORT_FULFILLED',
  CREATE_ASYNC_REPORT_REJECTED: 'reports/CREATE_ASYNC_REPORT_REJECTED',
};

// Action creators
export const createAsyncReport = (event, report, phase, extraParams) => ({
  [RSAA]: {
    ...api.createAsyncReport(event, report, phase, extraParams),
    types: [
      actions.CREATE_ASYNC_REPORT_PENDING,
      actions.CREATE_ASYNC_REPORT_FULFILLED,
      actions.CREATE_ASYNC_REPORT_REJECTED,
    ],
  },
});
