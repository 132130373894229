import { apiHeaders, apiURL } from '../services/config';

import { patch } from './config';

export const load = (evaluationId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/evaluations/${evaluationId}`),
});

export const forPhase = (phaseId, teams_only = false) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/evaluations${teams_only ? '?teams_info_only=true' : ''}`),
});

export const update = (id, evaluation) =>
  patch(`/evaluations/${id}`, { evaluation }).then((payload) => payload.evaluation);

export const updateFinalRank = (phaseId, finalRanks) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ finalRanks }),
  endpoint: apiURL(`/phases/${phaseId}/evaluations/update_valid_rank`),
});
