import {
  actions,
  addCriterionClick,
  removeCriterionClick,
  setScoringData,
} from 'data/actions/scores';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  getClickedCriteria,
  getDimensionScore,
  getValidScoresFromClickedCriteria,
} from 'data/reducers/scores';

import { getScoresState } from 'data/reducers';
import { error as notifyError } from 'data/actions/notifications';

function* performChangeScoreCriteria(action) {
  try {
    const { rubric, scoreId, dimensionId, subdimensionId, criterionId, eventId, selfScore } =
      action.payload;
    const scoresState = yield select(getScoresState);
    const dimension = rubric.get('dimensions').find((d) => d.get('id') === dimensionId);
    const subdimension = dimension
      .get('subdimensions')
      .find((sub) => sub.get('id') === subdimensionId);

    const clickedCriteria = getClickedCriteria(scoresState, scoreId);
    const subDimCriteriaId = subdimension.get('criteria').map((crit) => crit.get('id'));

    const isNewClick = !clickedCriteria.includes(criterionId);

    const newClickedCriteria = isNewClick
      ? clickedCriteria.filter((id) => !subDimCriteriaId.includes(id)).push(criterionId)
      : clickedCriteria.filter((id) => id !== criterionId);

    const grades = rubric.get('grades');

    const validScores = getValidScoresFromClickedCriteria(grades, dimension, newClickedCriteria);

    let score = getDimensionScore(scoresState, scoreId, dimensionId);
    let newScore = 0;
    let setScoreAfterCriterionClick = 'same';

    if (validScores.size === 0) {
      newScore = null;
      setScoreAfterCriterionClick = 'clear';
    } else if (score && !validScores.has(score)) {
      // If there's a score but the criteria change, created a new interval
      // we need to set the score to the proper bound.
      const scores = Array.from(validScores);
      // newScore = Math.max(score, Math.min(...scores)) ? Is this equivalent
      // newScore = Math.min(newScore, Math.max(...scores)) ?
      const min = Math.min(...scores);
      const max = Math.max(...scores);
      if (score < min) {
        newScore = min;
      } else if (score > max) {
        newScore = max;
      }
      setScoreAfterCriterionClick = newScore;
    } else newScore = score;

    yield put(
      setScoringData(
        scoreId,
        dimensionId,
        subdimensionId,
        criterionId,
        newClickedCriteria,
        newScore,
      ),
    );
    const toggleCriterionClick = isNewClick ? addCriterionClick : removeCriterionClick;
    yield put(
      toggleCriterionClick(
        selfScore,
        eventId,
        scoreId,
        dimensionId,
        criterionId,
        setScoreAfterCriterionClick,
      ),
    );
  } catch (e) {
    yield put(notifyError('Can not change criterion'));
  }
}

function* watchChangeScoreCriteria() {
  yield takeEvery(actions.CHANGE_CRITERIA, performChangeScoreCriteria);
}

const saga = function* () {
  yield all([fork(watchChangeScoreCriteria)]);
};

export default saga;
