import { Row, flexRender } from '@tanstack/react-table';
import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import React, { useState } from 'react';
import truncate from 'truncate';

import DraggableTableRow from 'components/DragAndDrop/DraggableTableRow';
import { isNavigator } from 'utils';

import Styles from './ResultsTable.module.scss';
import { TeamResult } from './types';

type ResultsTableRowProps = {
  row: Row<TeamResult>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
  phase: any;
};

const ResultsTableRow = ({ row, reorderRow, phase }: ResultsTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const originalDescription = row.original.team_description || 'No description for this team';
  const truncatedDescription = truncate(originalDescription, 245);
  const isTruncable = originalDescription.length !== truncate(truncatedDescription, 245).length;

  return (
    <DraggableTableRow index={row.index} row={row} reorderRow={reorderRow}>
      {({ dropRef, dragRef, isHover }) => (
        <>
          <tr ref={dropRef} style={{ opacity: isHover ? 0 : 1 }} className={Styles.ResultsTableRow}>
            {row.getVisibleCells().map((cell) => (
              <React.Fragment key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </React.Fragment>
            ))}
            {phase.final_rank && (
              <td className="text-center">
                <button
                  disabled={isNavigator()}
                  type="button"
                  ref={dragRef}
                  className={cx(Styles.FinalRankButton, 'btn')}
                  style={{ cursor: 'move' }}
                >
                  {row.original.final_rank}
                  <i className="fa-solid fa-grip-vertical ms-2 me-0"></i>
                </button>
              </td>
            )}
          </tr>
          {row.getIsExpanded() && (
            <tr style={{ display: isHover ? 'none' : undefined }}>
              <td className="text-center align-top">
                {isTruncable && (
                  <Button
                    className={'px-2 py-1'}
                    variant={'link'}
                    onClick={() => setIsExpanded(!isExpanded)}
                    title={isExpanded ? 'Collapse team description' : 'Expand team description'}
                  >
                    <span
                      className={cx(Styles.DescriptionButtonArrow, 'fa-solid m-0', {
                        'fa-angle-down': isExpanded,
                        'fa-angle-right': !isExpanded,
                      })}
                    />
                  </Button>
                )}
              </td>
              <td colSpan={9} className={Styles.DescriptionText}>
                {isExpanded ? originalDescription : truncatedDescription}
              </td>
            </tr>
          )}
        </>
      )}
    </DraggableTableRow>
  );
};

export default ResultsTableRow;
