import { actions } from 'data/actions/judgeInvitations';

import { List, fromJS } from 'immutable';
import { getNextId } from 'utils/unique_id';

const initialState = fromJS({
  loading: false,
  data: [],
  data_preview: [],
  show_not_accepted: false,
});

const judgeInvitations = (state = initialState, action) => {
  switch (action.type) {
    case actions.PREVIEW:
      const previewId = getNextId();
      return state.updateIn(['data_preview'], (dp) =>
        dp.push(action.payload.judgeInvitation.set('preview_id', previewId)),
      );
    case actions.REMOVE_PREVIEW:
      const preview_index = getPreviewIndexById(state, action.payload.previewId);
      return state.deleteIn(['data_preview', preview_index]);
    case actions.REMOVE_ALL_PREVIEW:
      return state.set('data_preview', List());
    case actions.CONFIRM:
      return state.set('loading', true);
    case actions.CONFIRM_SUCCESS:
      return state
        .set('loading', false)
        .set('data_preview', List())
        .updateIn(['data'], (data) => data.concat(fromJS(action.payload.judgeInvitations)));
    case actions.CONFIRM_FAIL:
      return state.set('loading', false);
    case actions.LOAD_ALL:
      return state.set('loading', true);
    case actions.LOAD_ALL_SUCCESS:
      return state.set('loading', false).set('data', fromJS(action.payload.judgeInvitations));
    case actions.LOAD_ALL_FAIL:
      return state.set('loading', false);
    case actions.UPDATE_INVITATION:
      const index = getPreviewIndexById(state, action.payload.judgeInvitation.get('preview_id'));
      return state.mergeIn(['data_preview', index], action.payload.judgeInvitation);
    case actions.DELETE_SUCCESS:
      const ids = String(action.payload.id).split(',');
      let newState = state;
      for (let id of ids) {
        const invitiationIndex = getInvitationIndex(state, id);
        newState = state.deleteIn(['data', invitiationIndex]);
      }
      return newState;
    default:
      return state;
  }
};

const getInvitationIndex = (state, id) =>
  state.get('data').findIndex((d) => String(d.get('id')) === String(id));

const getPreviewIndexById = (state, preview_id) =>
  state.get('data_preview').findIndex((d) => d.get('preview_id') === preview_id);

const getFullName = (invitation) => {
  if (invitation.get('first_name') && invitation.get('last_name')) {
    return `${invitation.get('first_name')} ${invitation.get('last_name')}`;
  } else {
    return invitation.get('first_name') || invitation.get('last_name');
  }
};

const withFullName = (invitation) => invitation.set('full_name', getFullName(invitation));

export const getList = (state, eventId) => {
  return state.get('data').map(withFullName).toJS();
};

export const getListPreview = (state) => state.get('data_preview').map(withFullName).toJS();

export const getSingle = (state, index) => state.get('data_preview').get(index);

export const isLoading = (state) => state.get('loading');

export default judgeInvitations;
