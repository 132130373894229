import fetchIntercept from './fetch-intercept';
import { healthcheck } from './../data/services/utils';
import { error as notifyError } from './../data/actions/notifications';

const lastRequestSendFromMap = new Map();

const initializeInterceptor = (navigate) =>
  fetchIntercept?.register({
    request: function (url, config) {
      // Store the url from where the fetch was performed
      lastRequestSendFromMap.set(url, window.location.href);
      return [url, config];
    },
    response: function (response) {
      if (response.status === 502 || response.status === 503 || response.status === 504) {
        navigate('/maintenance-mode');
      }
      return response;
    },
    responseError: async function (error) {
      // Handle a fetch error
      try {
        // Prevent infinite loop if healthcheck fails
        if (this.url.indexOf('utils/healthcheck') === -1) {
          await healthcheck();
          // Check if the user is in the same screen from where the
          // fetch call was performed before it failed
          if (lastRequestSendFromMap.get(this.url) === window.location.href) {
            // Remove the url from the map to prevent multiple messages
            lastRequestSendFromMap.set(this.url, null);
            window.store.dispatch(
              notifyError(
                'Unfortunately either the server passed its 60s limit for responding to your request or we encountered another error. ' +
                  "If trying again in a few minutes doesn't resolve the issue, please contact Valid Eval for assistance.",
              ),
            );
          }
        }
      } catch (e) {
        navigate('/maintenance-mode');
      }
      return Promise.reject(error);
    },
  });

export default initializeInterceptor;
