import * as api from 'data/services/events';

import { actions, saveSuccess } from 'data/actions/events';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { error as notifyError } from 'data/actions/notifications';

function* performSave(action) {
  try {
    const { eventId, event } = action.payload;
    const result = yield call(api.save, eventId, event);
    yield put(saveSuccess(result));
  } catch (e) {
    yield put(notifyError(e.error || e.message));
  }
}

function* watchSave() {
  yield takeEvery(actions.SAVE, performSave);
}

const saga = function* () {
  yield all([fork(watchSave)]);
};

export default saga;
