import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';

import { getSelectedPhase } from 'data/reducers';

import PDFInputTeamStatusCheckbox from '../events/components/Reports/PDFInputTeamStatusCheckbox';
import withRouter from 'routes/withRouter';
import { Status, StatusFramework } from 'utils/statuses';

type OwnProps = {
  phaseFinalRank: boolean;
  pdfTeamRange: {
    first: number | string;
    last: number | string;
  };
  totalEvaluations: number;
  pdfTeamStatusCheckboxes: {
    [key: string]: boolean;
  };
  handleOnChangePDFTeamStatusCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPdfTeamRange: (range: { first: number | string; last: number | string }) => void;
};

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  phase: getSelectedPhase(state, ownProps)?.toJS?.(),
});

const connector = connect(mapStateToProps, null);

type StatusAndRangeSelectorProps = OwnProps & ConnectedProps<typeof connector>;

const StatusAndRangeSelectorComponent = ({
  phaseFinalRank,
  pdfTeamRange,
  totalEvaluations,
  pdfTeamStatusCheckboxes,
  handleOnChangePDFTeamStatusCheckbox,
  setPdfTeamRange,
  phase,
}: StatusAndRangeSelectorProps) => {
  const statuses = Object.values(phase.status_framework as StatusFramework)
    .filter((status: Status) => status?.active)
    .sort((a: Status, b: Status) => a.order - b.order)
    .reverse();

  return (
    <div className="d-flex flex-row my-2 align-items-center">
      {!phaseFinalRank && (
        <>
          {statuses.map((status: any) => (
            <PDFInputTeamStatusCheckbox
              key={String(status.value)}
              status={String(status.value)}
              label={status.label}
              onChange={handleOnChangePDFTeamStatusCheckbox}
              checked={pdfTeamStatusCheckboxes[status.value]}
            />
          ))}
        </>
      )}
      {phaseFinalRank && (
        <>
          <Row>
            <Col xs="8" className="d-flex flex-row align-items-center">
              <Form.Label htmlFor="first" className="me-2 mb-0 text-nowrap text-blue03">
                <small>RANGE FROM</small>
              </Form.Label>
              <Form.Control
                className="text-center p-0 text-blue03"
                size="sm"
                id="first"
                placeholder="1"
                value={pdfTeamRange.first}
                onChange={(e) =>
                  setPdfTeamRange({ ...pdfTeamRange, first: parseInt(e.target.value) || '' })
                }
              />
              <Form.Label htmlFor="last" className="me-2 ms-2 mb-0 text-nowrap text-blue03">
                <small>TO</small>
              </Form.Label>
              <Form.Control
                className="text-center p-0 text-blue03"
                size="sm"
                id="last"
                placeholder="1"
                value={pdfTeamRange.last}
                onChange={(e) =>
                  setPdfTeamRange({ ...pdfTeamRange, last: parseInt(e.target.value) || '' })
                }
              />
              <Form.Label className="ms-2 mb-0 text-nowrap text-blue03">
                <small>OF {totalEvaluations}</small>
              </Form.Label>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default withRouter(connector(StatusAndRangeSelectorComponent));
