import { Button, Col, Row } from '@valid-eval/shared-react-components';
import { getJudgeInvitations } from 'data/reducers';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import JudgeInvitationsAddList from './JudgeInvitationsAddList';
import JudgeInvitationsAddManually from './JudgeInvitationsAddManually';

const VIEWS = { manually: 'manually', list: 'list' };

function JudgeInvitationsAdd({ onCancel, onNext }) {
  const [currentView, setCurrentView] = useState(VIEWS.manually);

  const eventId = useParams()?.event_id;
  const existingInvitations = useSelector((state) => getJudgeInvitations(state, eventId));

  const initialValues = { invitations: [{}, {}], existingInvitations };
  const getButtonVariant = (viewName) => (currentView === viewName ? 'primary' : 'default');
  const makeSetView = (viewName) => () => setCurrentView(viewName);

  return (
    <>
      <Row>
        <Col className="text-end">
          <Button variant={getButtonVariant(VIEWS.manually)} onClick={makeSetView(VIEWS.manually)}>
            Manually
          </Button>
          &nbsp;
          <Button variant={getButtonVariant(VIEWS.list)} onClick={makeSetView(VIEWS.list)}>
            List of Judges
          </Button>
        </Col>
      </Row>
      {currentView === VIEWS.manually && (
        <JudgeInvitationsAddManually
          onCancel={onCancel}
          onNext={onNext}
          initialValues={initialValues}
        />
      )}
      {currentView === VIEWS.list && (
        <JudgeInvitationsAddList
          onCancel={onCancel}
          onSubmit={onNext}
          initialValues={{ existingInvitations }}
        />
      )}
    </>
  );
}

JudgeInvitationsAdd.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default JudgeInvitationsAdd;
