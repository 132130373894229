import { Col, Row } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import Title from 'components/Title';
import { recuse } from 'data/actions/judgeProfiles';
import * as notifications from 'data/actions/notifications';
import {
  createScore,
  fetchJudgeEvaluations,
  fromJudgeEvaluations,
} from 'data/features/judgeEvaluations';
import { getCurrentEventIsQualitativeMode, getEventFromURL, getJudgeRole } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { judgeNav } from 'utils/urls';

import EvaluationsTable from './components/EvaluationsTable';

const Evaluations = ({
  isLoading,
  evaluations,
  params: { judge_profile_id, event_id },
  query: { date },
  role,
  event,
  isQualitativeMode,
  recuse,
  createScore,
  notifyError,
  fetchJudgeEvaluations,
  navigate,
}) => {
  const [isCreatingScore, setIsCreatingScore] = useState(null);

  useEffect(() => {
    fetchJudgeEvaluations({ eventId: event_id, judgeId: judge_profile_id, date });
  }, []);

  const onRecuseEvaluation = (teamId, phaseId) => {
    return recuse(judge_profile_id, teamId, phaseId)
      .then(({ error, payload }) => {
        if (error) {
          const msg =
            (payload.response && payload.response.error) || 'Can not recuse from the team';
          notifyError(msg);
          throw msg;
        }
      })

      .catch(console.warn);
  };

  const navigateToNewScore = (scoreId) => {
    const url = judgeNav.scoring(event_id, judge_profile_id, scoreId);

    setIsCreatingScore(null);
    navigate(url);
  };

  const handleScoreCreationError = (message) => {
    setIsCreatingScore(null);
    notifyError(message);
  };

  const handleBeginEvaluation = async (judgeEvaluationId) => {
    setIsCreatingScore(judgeEvaluationId);
    const res = await createScore({ eventId: event_id, judgeEvaluationId });
    if (res.error)
      return handleScoreCreationError(res.payload?.response?.error || res.error.message);
    navigateToNewScore(res.payload.score?.id);
  };

  return (
    <Row>
      <Col md={12}>
        <Title component="h1" className="mt-4">
          My evaluations
        </Title>

        {isLoading ? (
          <Loading />
        ) : (
          <EvaluationsTable
            judge={role}
            data={evaluations}
            judgeId={judge_profile_id}
            onRecuseEvaluation={onRecuseEvaluation}
            onBeginEvaluation={handleBeginEvaluation}
            eventId={event_id}
            eventName={event?.name}
            isQualitativeMode={isQualitativeMode}
            judgeEvaluationIdWaitingForScore={isCreatingScore}
          />
        )}
      </Col>
    </Row>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const judgeId = ownProps.params.judge_profile_id;

      return {
        isLoading: fromJudgeEvaluations.getIsLoading(state?.toJS()),
        evaluations: fromJudgeEvaluations.getEvaluationsByJudge(state?.toJS(), judgeId),
        role: getJudgeRole(state, ownProps)?.toJS(),
        event: getEventFromURL(state, ownProps)?.toJS(),
        isQualitativeMode: getCurrentEventIsQualitativeMode(state, ownProps),
      };
    },
    { recuse, createScore, notifyError: notifications.error, fetchJudgeEvaluations },
  )(Evaluations),
);
