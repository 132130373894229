import { Col, Row } from '@valid-eval/shared-react-components';
import { BoxContainer } from 'components/Box';
import Content from 'components/Content';
import Loading from 'components/Loading';
import { load as loadContent } from 'data/actions/contents';
import { load as loadDocuments } from 'data/actions/documents';
import { load as loadEvent } from 'data/actions/events';
import { getDocumentsForJudges, getEvent, getEventContent } from 'data/reducers';
import { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import Documents from '../app/components/Documents';

export class SignUp extends Component {
  componentDidMount() {
    const {
      loadEvent,
      loadContent,
      loadDocuments,
      params: { event_id },
    } = this.props;

    loadEvent(event_id);
    loadContent(event_id, 'Judge', 'Signup Confirmation');
    loadDocuments(event_id);
  }

  render() {
    const { loading, title, notice, documents } = this.props;

    if (loading) return <Loading />;

    return (
      <BoxContainer>
        <Row>
          <Col md={6}>
            <Content html={title} />
          </Col>
        </Row>
        <Row>
          <Col md={documents ? 7 : 12}>
            <Content html={notice} />
          </Col>
          {documents && (
            <>
              <Col md={1} className="clearfix" />

              <Col md={4} className="well">
                <Documents list={documents} />
              </Col>
            </>
          )}
        </Row>
      </BoxContainer>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEvent(state, ownProps.params.event_id);
      const loading = !event;
      const title =
        event && getEventContent(state, event.get('id'), 'Judge', 'Signup Confirmation', 'Title');
      const notice =
        event && getEventContent(state, event.get('id'), 'Judge', 'Signup Confirmation', 'Notice');
      const documents = getDocumentsForJudges(state, ownProps);

      return {
        loading,
        event,
        title,
        notice,
        documents,
      };
    },
    {
      loadEvent,
      loadContent,
      loadDocuments,
      getDocumentsForJudges,
    },
  )(SignUp),
);
