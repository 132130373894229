import { Form } from '@valid-eval/shared-react-components';
import { HeaderContext } from '@tanstack/react-table';
import Styles from './CommonTableStyles.module.scss';
import cx from 'classnames';

export type TableHeaderProps = HeaderContext<any, any> & {
  children?: React.ReactNode;
  className?: string;
};

export const TableHeader = ({ children, className, column }: TableHeaderProps) => {
  const sortOrder = column.getIsSorted();
  const canSort = column.getCanSort();

  return (
    <th
      className={cx(
        {
          [Styles.SortableColumn]: canSort,
        },
        className,
      )}
      onClick={column.getToggleSortingHandler()}
    >
      {children}
      {canSort && !sortOrder && (
        <i className={cx('fa-solid fa-sort', Styles.SortableColumnIndicator)} />
      )}
      {canSort && sortOrder && (
        <i
          className={cx('fa-solid', Styles.SortableColumnIndicatorActive, {
            'fa-sort-down': sortOrder === 'desc',
            'fa-sort-up': sortOrder === 'asc',
          })}
        />
      )}
    </th>
  );
};

export const SimpleHeader = ({ header, className }: TableHeaderProps) => {
  return <th className={cx('text-center', className)}>{header.id}</th>;
};

export const SortableHeader = ({ className, children, ...props }: TableHeaderProps) => {
  const { header } = props;
  return (
    <TableHeader className={cx('text-center', className)} {...props}>
      {children ? children : header.id}
    </TableHeader>
  );
};

export const Checkbox = ({ table, column }: HeaderContext<any, any>) => {
  const meta = table.options.meta as any;
  const tableId = meta?.tableId ? `-${meta.tableId}` : '';
  return (
    <th className={cx(Styles.CheckboxColumn, 'text-center')}>
      <Form.Check
        id={`table-check-all-${column.id}${tableId}`}
        checked={table.getIsAllRowsSelected()}
        label=""
        onChange={() => table.toggleAllRowsSelected(!table.getIsAllRowsSelected())}
      />
    </th>
  );
};
