import { Button, Form } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Errors from 'components/Errors';
import Email from 'components/Form/Email';
import { getEnvVar } from 'config';

import OauthButton from '../OauthButton';
import { LoginFormType } from './types';

type LoginEmailFormProps = {
  isLoading: boolean;
  error: string | null;
  onNext: (data: LoginFormType) => void;
  registered: string | undefined;
  isTeamMemberInvitation?: boolean;
};

const LoginEmailForm = ({
  isLoading,
  error,
  onNext,
  registered,
  isTeamMemberInvitation,
}: LoginEmailFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, setFocus, reset } = useFormContext<LoginFormType>();

  useEffect(() => {
    setFocus('username');
    setTimeout(() => reset(undefined, { keepValues: true, keepIsSubmitted: false }), 0);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onNext)} noValidate>
      {(getEnvVar('REACT_APP_GOV_ENV') === '0' ||
        getEnvVar('REACT_APP_OAUTH_KEYCLOAK') === '1') && (
        <div className="d-grid gap-2 mb-4">
          <OauthButton login />
        </div>
      )}

      {getEnvVar('REACT_APP_GOV_ENV') === '1' ? null : (
        <h3 className="text-center text-white01 m-0 p-0 mb-2">Or</h3>
      )}

      <Errors
        errors={error ? [error] : null}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      <Email id="username" name="username" label={t('auth.login.username')} required />
      <div className="text-end mt-2">
        <Link to="/forgot-password" className="text-orange01">
          <Form.Label htmlFor="email">{t('auth.login.forgot_password')}</Form.Label>
        </Link>
      </div>
      <div className="d-grid gap-2 mt-4">
        <Button id="login-submit" type="submit" variant="success" disabled={isLoading}>
          {isLoading && <i className="fa-duotone fa-spinner fa-spin" />}
          {!isLoading && t('common.continue')}
        </Button>
      </div>

      {!registered && !isTeamMemberInvitation && (
        <div className="mt-4">
          {t('auth.sign_up.no_account')}{' '}
          <Link to={'#new-user'} className="text-blue01">
            {t('auth.sign_up.title')}
          </Link>
        </div>
      )}
    </Form>
  );
};

export default LoginEmailForm;
