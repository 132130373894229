import { HeaderContext } from '@tanstack/react-table';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { TableHeader } from 'components/Tables/CommonTableHeaders';
import { Team } from 'screens/app/events/shared/types';

import Styles from './TeamsAdd.module.scss';

export const TeamName = (props: HeaderContext<Team, string>) => {
  const { t } = useTranslation();
  return (
    <TableHeader className={cx(Styles.TeamNameColumn, 'text-start text-truncate')} {...props}>
      {t('event.team_profile_name_label', 'Company')}
      <br />
      Category
    </TableHeader>
  );
};
