import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import * as Headers from './Headers';
import * as Cells from './Cells';

import { JudgeEvaluation } from 'data/features/judgeEvaluationsTypes';

const columnHelper = createColumnHelper<JudgeEvaluation>();

export const columns: ColumnDef<JudgeEvaluation, any>[] = [
  columnHelper.accessor('team.name', {
    id: 'Name',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.Text className="text-left" {...props} />,
  }),
  columnHelper.accessor('categories', {
    id: 'Categories',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Array,
  }),
  columnHelper.accessor('recuse', {
    id: 'Recuse?',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.Recuse,
  }),
  columnHelper.accessor('artifact_items', {
    id: 'Materials to Review',
    header: CommonTableHeaders.SimpleHeader,
    cell: Cells.Artifacts,
  }),
  columnHelper.accessor('phase.name', {
    id: 'Phase',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Text,
  }),
  columnHelper.accessor('judging_window', {
    id: 'Judging Window',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Text,
  }),
  columnHelper.accessor('score_value', {
    id: 'My Score',
    header: Headers.MyScore,
    cell: Cells.MyScore,
    sortingFn: (a, b) => {
      const aScore = a.original.score_value || 0;
      const bScore = b.original.score_value || 0;
      return bScore - aScore;
    },
  }),
  columnHelper.accessor('actions', {
    id: 'Actions',
    header: CommonTableHeaders.SimpleHeader,
    cell: Cells.Actions,
  }),
];
