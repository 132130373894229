import { actions } from 'data/actions/communications';
import { useSelector } from 'react-redux';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  communications: [],
  loading: false,
};

export default handleActions(
  {
    [actions.LOAD_COMMUNICATIONS_PENDING]: (state) => {
      return { ...state, loading: true };
    },
    [actions.LOAD_COMMUNICATIONS_REJECTED]: (state) => {
      return { ...state, loading: false };
    },
    [actions.LOAD_COMMUNICATIONS_FULFILLED]: (state, action) => {
      const { communications } = action.payload;
      return { ...state, loading: true, communications };
    },
  },
  initialState,
);

export const getCommunicationsState = (state) => state.get('communications');
export const getCommunications = createSelector(
  getCommunicationsState,
  (state) => state.communications,
);

export const getCommunicationTemplatesByEventAndPhase = createSelector(
  getCommunications,
  (_, props) => props,
  (communications, { templates, phaseId, eventId }) => {
    return templates.reduce((result, template) => {
      const defaultTemplate = communications.find(
        (c) => c.slug === template && !c.event_id && !c.phase_id,
      );
      const byPhase = communications.find(
        (c) => c.slug === template && c.event_id === eventId && c.phase_id === phaseId,
      );
      const byEvent = communications.find(
        (c) => c.slug === template && c.event_id === eventId && !c.phase_id,
      );
      return { ...result, [template]: byPhase ?? byEvent ?? defaultTemplate };
    }, {});
  },
);

export const getCommunicationTemplates = createSelector(
  getCommunicationTemplatesByEventAndPhase,
  (templates) =>
    Object.keys(templates)
      .map((key) => templates[key])
      .filter(Boolean),
);

export const useCommunicationTemplates = ({ templates, eventId, phaseId }) => {
  return useSelector((state) => getCommunicationTemplates(state, { templates, phaseId, eventId }));
};
