import { teamNav } from '../urls';

export const displayName = 'Take Survey';

export const getActionButtons = (notification) => [
  {
    title: 'Take survey',
    url: teamNav.survey(
      notification.data.get('event_id'),
      notification.data.get('phase_id'),
      notification.data.get('team_id'),
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Valid Eval post Round of Evaluation Survey is now available for the team ${notification.data.get(
    'team_name',
  )}, for phase ${notification.data.get('phase_name')} in cohort ${notification.data.get(
    'cohort_name',
  )}.`;
