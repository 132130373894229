import { getEnvVar } from 'config';
import { error } from 'data/actions/notifications';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const NotFound = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      error(
        `The URL https://${getEnvVar('REACT_APP_HOST')}${pathname} does not exist on Valid Eval`,
      ),
    );
  }, []);

  return <Navigate to="/" />;
};

export default NotFound;
