import { ColumnDef, HeaderContext, Row, createColumnHelper } from '@tanstack/react-table';
import { sortByAggrementSrength, sortByStatus } from './helpers';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import * as TableCells from './Cells';
import * as TableHeaders from './Headers';
import { TeamResult } from './types';
import { sortStrings } from 'components/Tables/sortingHelpers';

const columnHelper = createColumnHelper<TeamResult>();

export const columns: ColumnDef<TeamResult, any>[] = [
  columnHelper.accessor('valid_score_valid_rank', {
    header: TableHeaders.ValidRank,
    cell: TableCells.ValidRank,
  }),
  columnHelper.accessor('panel', {
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.SmallText,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('team_prefix', {
    header: TableHeaders.Prefix,
    cell: TableCells.Prefix,
  }),
  columnHelper.accessor('team_name', {
    header: TableHeaders.TeamName,
    cell: TableCells.TeamName,
  }),
  columnHelper.accessor('valid_score_valid_z', {
    header: TableHeaders.ValidSigma,
    cell: TableCells.ValidSigma,
  }),
  columnHelper.accessor('valid_score', {
    header: TableHeaders.ValidScore,
    cell: TableCells.ValidScore,
  }),
  columnHelper.accessor('score_count', {
    header: (props: HeaderContext<TeamResult, number>) => (
      <TableHeaders.Evals {...props}>Evals</TableHeaders.Evals>
    ),
    cell: TableCells.Evals,
  }),
  columnHelper.accessor('valid_score_agreement_strength', {
    header: TableHeaders.AgreementStrength,
    cell: TableCells.AgreementStrength,
    // @ts-ignore
    sortingFn: (rowA: Row<TeamResult>, rowB: Row<TeamResult>) =>
      sortByAggrementSrength(rowA.original, rowB.original),
  }),
  columnHelper.accessor('high_scores', {
    header: TableHeaders.JudgesScores,
    cell: TableCells.Scorers,
    enableSorting: false,
  }),
  columnHelper.accessor('low_scores', {
    header: TableHeaders.JudgesScores,
    cell: TableCells.Scorers,
    enableSorting: false,
  }),
  columnHelper.accessor('status', {
    header: TableHeaders.Status,
    cell: TableCells.Status,
    // @ts-ignore
    sortingFn: (rowA: Row<TeamResult>, rowB: Row<TeamResult>) =>
      sortByStatus(rowA.original, rowB.original),
  }),
];
