import pick from 'lodash/pick';
import * as judgeRegistration from './judgeRegistration';
import * as judgeSignup from './judgeSignup';
import * as judgeSignUpReminder from './judgeSignUpReminder';
import * as judgingWindowCloses from './judgingWindowCloses';
import * as judgingWindowClosesReminder from './judgingWindowClosesReminder';
import * as reportGenerated from './reportGenerated';
import * as scheduledReportGenerated from './scheduledReportGenerated';
import * as startJudgingReminder from './startJudgingReminder';
import * as teamFeedbackAvailable from './teamFeedbackAvailable';
import * as teamSignup from './teamSignup';
import * as thankYouJudge from './thankYouJudge';
import * as teamSurvey from './teamSurvey';
import * as teamRejectedApplication from './teamRejectedApplication';
import * as teamRevisionApplication from './teamRevisionApplication';
import * as teamMemberRegistration from './teamMemberRegistration';
import * as notificationFailedToSend from './notificationFailedToSend';
import * as automatch from './automatch.tsx';
import * as teamProfileDraftsReminder from './teamProfileDraftsReminder';
import * as panelMatch from './panelMatch';
import * as primaryContactChanged from './primaryContactChanged';

export const allNotifications = {
  judge_registration: judgeRegistration,
  team_signup: teamSignup,
  judge_signup: judgeSignup,
  judging_window_closes: judgingWindowCloses,
  judging_window_closes_reminder: judgingWindowClosesReminder,
  start_judging_reminder: startJudgingReminder,
  judge_signup_reminder: judgeSignUpReminder,
  scheduled_report_generated: scheduledReportGenerated,
  report_generated: reportGenerated,
  team_feedback_available: teamFeedbackAvailable,
  thank_you_judge: thankYouJudge,
  team_survey: teamSurvey,
  team_rejected_application: teamRejectedApplication,
  team_revision_application: teamRevisionApplication,
  team_member_registration: teamMemberRegistration,
  notification_failed_to_send: notificationFailedToSend,
  automatch,
  team_profile_draft_reminder: teamProfileDraftsReminder,
  panel_match: panelMatch,
  team_primary_contact_changed: primaryContactChanged,
};

export const invitationsCommunications = pick(allNotifications, ['judge_signup_reminder']);

export const judgesCommunications = pick(allNotifications, [
  'start_judging_reminder',
  'judging_window_closes_reminder',
  'thank_you_judge',
]);

export const teamsCommunications = pick(allNotifications, [
  'team_feedback_available',
  'team_rejected_application',
  'team_revision_application',
]);

export const teamProfileDraftsCommunications = pick(allNotifications, [
  'team_profile_draft_reminder',
]);

export const notificationActionButtons = (notification) =>
  allNotifications[notification.action]?.getActionButtons?.(notification);
export const notificationTextFromData = (notification) =>
  allNotifications[notification.action]?.getText?.(notification);
