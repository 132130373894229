// TODO: Check that the error property of the action is truthy when all the actions are FSA
import { getEnvVar } from 'config';
import { add as notifyError } from '../data/actions/notifications';
import { logoutSuccess } from '../data/actions/users';

const isApiError = (action) => action.payload && action.payload.name === 'ApiError';

export const handleApiErrors = (store) => (next) => (action) => {
  if (!isApiError(action)) return next(action);

  if (action.payload.status === 401) {
    if (!action.payload?.response?.keycloak_auth_needed) {
      store.dispatch(logoutSuccess());
      if (action.type !== 'users/LOAD_RESULT')
        store.dispatch(
          notifyError(
            'danger',
            'Your session has expired, please login again',
            null,
            parseInt(getEnvVar('REACT_APP_ALERT_DURATION', '3000')),
            new Date(1, 1, 1979).getTime(),
          ),
        );
    } else {
      window.location.href =
        '/api/v1/auth/keycloak/start?url=' +
        encodeURIComponent(window.location.pathname + window.location.search);
    }
  }

  return next(action); // Allow the failed action to move on, so reducers can change state accordingly
};
