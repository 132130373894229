import { InactiveTeam } from '../types';

export const sortByStatus = (a: InactiveTeam, b: InactiveTeam) => {
  const phaseA = a.phases?.[0];
  const phaseB = b.phases?.[0];

  const statusA = Object.values(phaseA?.statusFramework || {}).find(
    (v) => v.value === phaseA?.outBubbleIn,
  ) || { order: -2 };
  const statusB = Object.values(phaseB?.statusFramework || {}).find(
    (v) => v.value === phaseB?.outBubbleIn,
  ) || { order: -2 };

  return statusA?.order - statusB?.order;
};

export const sortByValidScore = (a: InactiveTeam, b: InactiveTeam) => {
  const validScoreA = a.phases?.[0]?.validScore || 0;
  const validScoreB = b.phases?.[0]?.validScore || 0;

  if (validScoreA === validScoreB) {
    return 0;
  } else if (validScoreA < validScoreB) {
    return 1;
  } else {
    return -1;
  }
};

export const sortByFinalRank = (a: InactiveTeam, b: InactiveTeam) => {
  const finalRankA = a.phases?.[0]?.finalRank || Infinity;
  const finalRankB = b.phases?.[0]?.finalRank || Infinity;

  if (finalRankA === finalRankB) {
    return 0;
  } else if (finalRankA < finalRankB) {
    return 1;
  } else {
    return -1;
  }
};

export const sortByPhaseName = (a: InactiveTeam, b: InactiveTeam) => {
  const phaseNameA = a.phases?.[0]?.name || '';
  const phaseNameB = b.phases?.[0]?.name || '';

  return phaseNameA.localeCompare(phaseNameB);
};

export const filterByStatus = (filterList: any[], teamResult: InactiveTeam) => {
  if (filterList.length) {
    // Apply the decision status filter if is selected
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value], []);
    if (!status.includes(teamResult.phases[0]?.outBubbleIn ?? '')) return false;
  }
  return true;
};
