import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get } from 'data/services/config';
import { isPendingAction, isRejectedAction } from './common';
import { Document } from './documentsTypes';

// Adapter for operate and normalize the data
const initialState: {
  loading: boolean;
  byEventId: Record<string, Document[]>;
} = {
  loading: false,
  byEventId: {},
};

// Actions
export const fetchDocuments = createAsyncThunk(
  'documents/fetch',
  async ({ eventId }: { eventId: string }, { rejectWithValue }) => {
    try {
      return await get(`/events/${eventId}/documents`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const documentsSlice = createSlice({
  name: 'documentsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.byEventId[action.meta.arg.eventId] = action.payload.documents;
        state.loading = false;
      })
      .addMatcher(isPendingAction('documents'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('documents'), (state) => {
        state.loading = false;
      });
  },
  selectors: {
    getIsLoading: (state) => state.loading,
    getActiveDocuments: (state, eventId: string, recipient: 'teams' | 'judges') => {
      return state.byEventId[eventId]?.filter(
        (document) => document.active && document.recipient.includes(recipient),
      );
    },
  },
});

export const fromDocuments = documentsSlice.selectors;
export default documentsSlice.reducer;
