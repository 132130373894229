import classNames from 'classnames';
import { useLayoutEffect, useRef } from 'react';
import { Form } from '@valid-eval/shared-react-components';
import { Field } from 'redux-form/immutable';

import Styles from './Textarea.module.scss';

import { dynamicFieldValidation, resize } from './utils';

export const TextareaFieldGroup = ({
  input,
  meta: { touched, error, warning },
  id,
  label,
  placeholder,
  helpText,
  cols,
  rows,
  disabled,
  className,
}) => {
  const isPrintView = /\/print(?:\/|$)/.test(window.location.href);
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    if (textareaRef.current && !isPrintView) {
      resize(input, textareaRef);
      resize(helpText, textareaRef);
    }
  }, [helpText, isPrintView]);

  const formControlComponent = isPrintView ? (
    <div>
      <div className={'printInput'}>{input.value}</div>
    </div>
  ) : (
    <Form.Control
      {...input}
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      as="textarea"
      className={Styles.inputTextArea}
      size={'lg'}
      disabled={disabled}
      isInvalid={touched && (error || warning)}
      isValid={touched && !(error || warning)}
      ref={textareaRef}
    />
  );

  return (
    <Form.Group className={classNames(className, Styles.inputTitle)} controlId={id}>
      {label && (
        <Form.Label className={Styles.inputLabel}>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      {formControlComponent}
      <Form.Control.Feedback type="invalid">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const Textarea = ({
  id,
  name,
  title,
  cols,
  rows,
  helpText,
  validate,
  placeholder,
  ...props
}) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={title}
      cols={cols}
      rows={rows}
      component={TextareaFieldGroup}
      helpText={helpText}
      placeholder={placeholder}
      validate={validate || dynamicFieldValidation}
      {...props}
    />
  );
};
