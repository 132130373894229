import { downloadArtifactPDFTeamReport } from 'data/actions/artifactItems';

import Icon from './Icon';
import Styles from './DocumentLink.module.scss';
import { connect } from 'react-redux';

let DocumentLink = ({ value, name, type, className, fileName, downloadArtifactPDFTeamReport }) => {
  const computedType = value?.includes?.('/pdf_team_report')
    ? 'pdf'
    : fileName?.substr(fileName?.lastIndexOf('.') + 1, fileName?.length).split('?')[0];

  const isPDFTeamReport = value?.includes?.('/pdf_team_report');

  const handlePDFTeamReportClick = (e) => {
    e.preventDefault();
    downloadArtifactPDFTeamReport(value);
  };

  return (
    <>
      {value ? (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          data-test-id={computedType}
          onClick={isPDFTeamReport ? handlePDFTeamReportClick : undefined}
        >
          <Icon type={computedType} height={15} />
          <span className={Styles.text}>{name}</span>
        </a>
      ) : (
        ''
      )}
    </>
  );
};

DocumentLink.displayName = 'DocumentLink';

export default connect(null, { downloadArtifactPDFTeamReport })(DocumentLink);
