import { WizardModal } from '@valid-eval/shared-react-components';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { getEnvVar } from 'config';
import { checkOtpFlow, load, login } from 'data/actions/users';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import withRouter from 'routes/withRouter';
import LoginFormEmail from './LoginFormEmail';
import LoginFormOtp from './LoginFormOtp';
import LoginFormPassword from './LoginFormPassword';
import { LoginFormType } from './types';

const Steps = [LoginFormEmail, LoginFormPassword, LoginFormOtp];

type OwnProps = {
  query: { email?: string };
  onMFASetup(otpRequestToken: string, userName: string): void;
  registered: string | undefined;
  isTeamMemberInvitation: boolean;
  show?: boolean;
  onClose?(): void;
};

const mapDispatchToProps = { checkOtpFlow, login, load };

const connector = connect(null, mapDispatchToProps);

const LoginModal = ({
  checkOtpFlow,
  login,
  load,
  onMFASetup,
  query,
  registered,
  isTeamMemberInvitation,
  show = true,
  onClose,
}: ConnectedProps<typeof connector> & OwnProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, loading, loaded] = useBooleanFlag();

  const form = useForm<LoginFormType>({
    defaultValues: {
      username: query.email || '',
      password: '',
      otp: '',
    },
  });

  const [step, setStep] = useState(0);
  const Component = Steps[step];

  const throwOnError = (response: any, username: string) => {
    if (!response.error) return;

    if (response.payload.status === 403) {
      throw new Error(
        t('auth.login.error.account_locked', {
          email: getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com'),
          username,
        }),
      );
    } else {
      const message =
        step === 2 ? t('auth.login.error.otp_invalid') : t('auth.login.error.invalid_credentials');
      throw new Error(message);
    }
  };

  const handleBack =
    step > 0
      ? () => {
          setError(null);
          if (step === 1) form.resetField('password', { keepTouched: false });
          if (step === 2) form.resetField('otp', { keepTouched: false });
          setStep(step - 1);
        }
      : undefined;

  const handleNext = async (data: LoginFormType) => {
    setError(null);

    if (step === 0) {
      setStep(step + 1);
    } else {
      try {
        loading();
        const response: any = await checkOtpFlow(data.username, data.password);
        throwOnError(response, data.username);

        const { otpRequired, otpRequestToken, otpConfigured } = response.payload;
        if (otpRequired && !data.otp) {
          setStep(step + 1);
          if (!otpConfigured) onMFASetup(otpRequestToken, data.username);
        } else {
          throwOnError(await login(data.username, data.password, data.otp), data.username);
          throwOnError(await load('me'), data.username);
        }
      } catch (error: any) {
        setError(error.message);
      } finally {
        loaded();
      }
    }
  };

  return (
    <FormProvider {...form}>
      <WizardModal
        show={show}
        onClose={onClose}
        title={t('auth.login.title')}
        body={
          <Component
            onNext={handleNext}
            error={error}
            isLoading={isLoading}
            registered={registered}
            isTeamMemberInvitation={isTeamMemberInvitation}
          />
        }
        onBack={handleBack}
        centered
      />
    </FormProvider>
  );
};

export default withRouter(connector(LoginModal));
