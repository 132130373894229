import { DashboardRoleSelect } from '@valid-eval/shared-react-components';
import { useMemo } from 'react';
import withRouter from 'routes/withRouter';

import { dashboardUrlFromUserRole } from 'utils/urls';

const DashboardRolesSelect = ({ event, roles, navigate }) => {
  const handleChange = (role) => {
    const url = dashboardUrlFromUserRole(role, event);
    navigate(url);
  };

  const groupedOptions = useMemo(() => {
    let options = (roles || []).map((option) => {
      const status =
        option.evaluations_status?.pending === 0
          ? 'Done'
          : `${option.evaluations_status?.completed} / ${option.evaluations_status?.total} complete`;
      let badgeContent =
        option.role_specific_name === 'Evaluator' && option.evaluations_status?.total > 0
          ? status
          : null;

      return { ...option, badgeContent };
    });

    const groupedOptions = options.reduce(
      (groups, option) => {
        groups[option.name === 'TeamDraft' ? 1 : 0].options.push(option);
        return groups;
      },
      [
        { label: 'ROLES', options: [] },
        { label: 'DRAFT PROFILES', options: [] },
      ],
    );

    return groupedOptions;
  }, [roles]);

  return (
    <DashboardRoleSelect
      onChange={handleChange}
      defaultValue=""
      options={groupedOptions}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.role_specific_name}
    />
  );
};

export default withRouter(DashboardRolesSelect);
