import {
  actions,
  confirmFail,
  confirmSuccess,
  deleteInvitationFail,
  deleteInvitationSuccess,
  loadAllFail,
  loadAllSuccess,
} from 'data/actions/judgeInvitations';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import {
  createMultiple,
  deleteInvitation,
  loadMultiple,
  send,
} from 'data/services/judgeInvitations';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

function* performLoad(action) {
  try {
    const judgeInvitations = yield call(loadMultiple, action.payload.eventId);

    yield put(loadAllSuccess(judgeInvitations));
  } catch (e) {
    const message = e.error || e.message;
    yield put(loadAllFail(message));
    yield put(notifyError(message));
  }
}

function* performConfirm(action) {
  const { judgeInvitations, eventId } = action.payload;

  try {
    const results = yield call(createMultiple, judgeInvitations, eventId);

    yield put(confirmSuccess(results));
    yield put(notifySuccess('The invitations have been sent'));
  } catch (e) {
    const message = e.error || e.message;
    yield call(performLoad, action);
    yield put(confirmFail(message));
    yield put(notifyError(message));
  }
}

function* performSendInvitation(action) {
  const { id, eventId } = action.payload;
  const ids = String(id).split(',');
  try {
    yield call(send, id, eventId);
    yield put(notifySuccess(`The invitation${ids?.length > 1 ? 's have' : ' has'} been resent`));
    yield call(performLoad, action);
  } catch (e) {
    yield put(notifyError(`The invitation${ids?.length > 1 ? 's' : ''} could not be sent`));
  }
}

function* performDeleteInvitation(action) {
  const { id, eventId } = action.payload;
  const ids = String(id).split(',');
  try {
    yield call(deleteInvitation, id, eventId);
    yield put(notifySuccess(`The invitation${ids?.length > 1 ? 's have' : ' has'} been deleted`));
    yield put(deleteInvitationSuccess(id));
  } catch (e) {
    yield put(notifyError(`The invitation${ids?.length > 1 ? 's' : ''} could not be deleted`));
    yield put(deleteInvitationFail(id));
  }
}

function* watchLoadJudgeInvitations() {
  yield takeEvery(actions.LOAD_ALL, performLoad);
}

function* watchConfirmJudgeInvitations() {
  yield takeEvery(actions.CONFIRM, performConfirm);
}

function* watchSendInvitation() {
  yield takeEvery(actions.SEND, performSendInvitation);
}

function* watchDeleteInvitation() {
  yield takeEvery(actions.DELETE, performDeleteInvitation);
}

const saga = function* () {
  yield all([
    fork(watchLoadJudgeInvitations),
    fork(watchConfirmJudgeInvitations),
    fork(watchSendInvitation),
    fork(watchDeleteInvitation),
  ]);
};

export default saga;
