import { Team } from '../../../shared/types';

export const statusType = {
  Accepted: 0,
  Inactive: 0,
};

// Team Match Section

export const searchableFields = ['name', 'prefix', 'panel'];

export const filterByMatched = (filterList: any[], teamResult: Team) => {
  if (filterList.length) {
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value], []);
    const hasMatches = teamResult.assignedRoles.length > 0;
    return status.includes(hasMatches);
  }
  return true;
};
