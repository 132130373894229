import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { getEnvVar } from 'config';
import { error as notifyError } from 'data/actions/notifications';

import {
  actions,
  changeSelectedPhase,
  loadResult,
  loadStatusFail,
  saveStatus,
  updateSuccess,
} from 'data/actions/phases';

import { getSelectedPhaseId } from 'data/reducers';
import * as api from 'data/services/phases';

export function* performLoadForEvent(action, loadForTeamUser) {
  try {
    const phases = yield call(api.load, action.payload.eventId);
    yield put(loadResult(phases));
    // Set default selected phase for organizer
    if (!loadForTeamUser) {
      const selectedId = yield select((state) => getSelectedPhaseId(state, action.payload.eventId));
      const phaseFound = phases.find((p) => p.id === selectedId);
      if (!selectedId || !phaseFound) {
        const firstId = phases[0].id;
        yield put(changeSelectedPhase(action.payload.eventId, firstId));
      }
    }

    return phases;
  } catch (e) {
    if (e.status !== 401) {
      yield put(
        notifyError(
          `An error ocurred while loading data. Please refresh your browser and try again.
         If you continue to receive this error, please contact ${getEnvVar(
           'REACT_APP_SUPPORT_EMAIL',
           'support@valideval.com',
         )}.
        `,
          undefined,
          5e3,
        ),
      );
    }
    yield put(loadResult(e));
  }
}

function* performLoadPhaseStatus(action) {
  try {
    const statuses = yield call(api.loadStatuses, action.payload.phaseId);
    yield put(saveStatus(action.payload.phaseId, statuses));
  } catch (e) {
    if (e.status !== 401) {
      yield put(loadStatusFail(e.error));
      yield put(notifyError('Can not load phase status'));
    }
  }
}

function* performUpdate(action) {
  try {
    const { phaseId, phase } = action.payload;
    const result = yield call(api.update, phaseId, phase);
    yield put(updateSuccess(result));
  } catch (e) {
    yield put(notifyError('Could not update phase'));
  }
}

function* watchLoadForEvent() {
  yield takeEvery(actions.LOAD_FOR_EVENT, performLoadForEvent);
}

function* watchLoadPhaseStatus() {
  yield takeEvery(actions.LOAD_STATUS, performLoadPhaseStatus);
}

function* watchUpdate() {
  yield takeEvery(actions.UPDATE, performUpdate);
}

export default function* root() {
  yield all([fork(watchLoadForEvent), fork(watchLoadPhaseStatus), fork(watchUpdate)]);
}
