import { Event } from 'data/features/eventsTypes';
import { TeamDraft, TeamDraftPayload } from 'data/features/teamDraftTypes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { StepInfo } from '../types';
import useCalculateStepInfo from './useCalculateStepInfo';

export type CategoriesFormType = Record<string, any>;

const useCategoriesForm = ({
  draft,
  event,
  onInfoChange,
  user,
  isActive,
}: {
  draft?: TeamDraft;
  event?: Event;
  onInfoChange(data: StepInfo): void;
  user?: any;
  isActive: boolean;
}) => {
  const categories = event?.categories || [];

  const form = useForm<CategoriesFormType>({
    mode: 'onChange',
    defaultValues: {},
  });

  const requiredFields =
    categories.map((d) => (d.required ? d.field_key : '')).filter(Boolean) || [];
  useCalculateStepInfo({ form, requiredFields, onInfoChange, user });

  // Trigger form validation on load
  useEffect(() => {
    form.trigger();
  }, []);

  // Set focus
  useEffect(() => {
    if (!isActive) return;

    const category = categories[0];
    if (!category) return;

    form.setFocus(category.field_key);
  }, [isActive, event?.team_details?.toString()]);

  // Load draft categories into form
  useEffect(() => {
    if (!draft?.id) return;

    draft.categories.forEach((category) => {
      form.setValue(category.parent.id, category.id);
    });

    form.trigger();
  }, [draft?.id]);

  const getDraftWithCategories = (draft: TeamDraft, data: CategoriesFormType): TeamDraftPayload => {
    return { id: draft.id, name: draft.name, tag_ids: Object.values(data) };
  };

  return { form, getDraftWithCategories };
};

export default useCategoriesForm;
