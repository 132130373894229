import * as rolesAPI from '../services/roleSummaries';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { failedFSAwithParams } from '../services/config';
import { normalize } from 'normalizr';

export const UPDATE_ROLE_STATUS = 'roleSummaries/UPDATE_ROLE_STATUS';
export const UPDATE_ROLE_STATUSES = 'roleSummaries/UPDATE_ALL_ROLE_STATUSES';
export const UPDATE_ROLE_STATUSES_RESULT = 'roleSummaries/UPDATE_ROLE_STATUSES_RESULT';
export const LOAD = 'roleSummaries/LOAD';
export const LOAD_TEAM_SUMMARY_RESULT = 'roleSummaries/LOAD_TEAM_RESULT';
export const LOAD_JUDGE_SUMMARY_RESULT = 'roleSummaries/LOAD_JUDGE_RESULT';
export const LOAD_SUMMARY_RESULT = 'roleSummaries/LOAD_RESULT';

const ACTIONS = {
  UPDATE_ROLE_STATUS: UPDATE_ROLE_STATUS,
  UPDATE_ROLE_STATUSES: UPDATE_ROLE_STATUSES,
  UPDATE_ROLE_STATUSES_RESULT: UPDATE_ROLE_STATUSES_RESULT,
  LOAD: LOAD,
  LOAD_TEAM_SUMMARY_RESULT: LOAD_TEAM_SUMMARY_RESULT,
  LOAD_JUDGE_SUMMARY_RESULT: LOAD_JUDGE_SUMMARY_RESULT,
  LOAD_SUMMARY_RESULT: LOAD_SUMMARY_RESULT,
  LOAD_INACTIVE_TEAMS_PENDING: 'roleSummaries/LOAD_INACTIVE_TEAMS_PENDING',
  LOAD_INACTIVE_TEAMS_REJECTED: 'roleSummaries/LOAD_INACTIVE_TEAMS_REJECTED',
  LOAD_INACTIVE_TEAMS_FULFILLED: 'roleSummaries/LOAD_INACTIVE_TEAMS_FULFILLED',
};

export const updateRoleStatus = (phaseId, entity, roleId, active) => ({
  [RSAA]: {
    ...rolesAPI.updateRoleStatus(phaseId, entity, roleId, active),
    types: [
      {
        type: UPDATE_ROLE_STATUS,
        payload: { phaseId, entity, roleId, active },
      },
      {
        type: UPDATE_ROLE_STATUSES_RESULT,
        payload: (action, state, res) => ({
          phaseId,
          entity,
          ...normalize({ id: roleId, active }, schemas.roleSummary),
        }),
      },
      {
        type: UPDATE_ROLE_STATUSES_RESULT,
        payload: failedFSAwithParams({ phaseId, entity }),
      },
    ],
  },
});

export const updateManyRoleStatuses = (phaseId, entity, active, ids = null) => ({
  [RSAA]: {
    ...rolesAPI.updateManyRoleStatuses(phaseId, entity, active, ids),
    types: [
      {
        type: UPDATE_ROLE_STATUSES,
        payload: { phaseId, entity, active },
      },
      {
        type: UPDATE_ROLE_STATUSES_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => {
            const {
              role_statuses: { ids },
            } = payload;
            const statuses = ids.map((id) => ({ id, active }));
            return { phaseId, entity, ...normalize(statuses, [schemas.roleSummary]) };
          }),
      },
      {
        type: UPDATE_ROLE_STATUSES_RESULT,
        payload: failedFSAwithParams({ phaseId, entity }),
      },
    ],
  },
});

export const load = (entity, phaseId) => {
  let schema;
  switch (entity) {
    case 'teams':
      schema = schemas.teamRoleSummary;
      break;
    case 'judges':
      schema = schemas.judgeRoleSummary;
      break;
    default:
      throw new Error('Unknow entity type');
  }

  return {
    [RSAA]: {
      ...rolesAPI.loadRoleSummaries(phaseId, entity),
      types: [
        {
          type: LOAD,
          payload: (action, state, res) => ({ entity }),
        },
        {
          type: LOAD_SUMMARY_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              const { role_summaries } = payload;
              return {
                phaseId,
                entity,
                ...normalize(
                  role_summaries.map((rs) => ({
                    ...rs,
                    original_assigned_roles: rs.assigned_roles,
                  })),
                  [schema],
                ),
              };
            }),
        },
        {
          type: LOAD_SUMMARY_RESULT,
          payload: failedFSAwithParams({ phaseId, entity }),
        },
      ],
    },
  };
};

export const loadInactiveTeams = (phaseId) => ({
  [RSAA]: {
    ...rolesAPI.loadInactiveTeams(phaseId),
    types: [
      {
        type: ACTIONS.LOAD_INACTIVE_TEAMS_PENDING,
      },
      {
        type: ACTIONS.LOAD_INACTIVE_TEAMS_FULFILLED,
        payload: (action, state, res) => getJSON(res),
      },
      {
        type: ACTIONS.LOAD_INACTIVE_TEAMS_REJECTED,
      },
    ],
  },
});
