import { email } from 'components/FormFields/validations';

import Input, { InputProps } from './Input';

const Email = ({ rules, ...props }: Omit<InputProps, 'type'> & { required?: boolean }) => {
  rules = rules || { validate: {} };
  rules = { ...rules, validate: { email, ...rules.validate } };

  return (
    <Input
      type="email"
      rules={{
        validate: { email },
      }}
      {...props}
    />
  );
};

export default Email;
