import { ConnectedProps, connect } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { error as showError, success as showSuccess } from 'data/actions/notifications';
import { clearAllMatches } from 'data/actions/phases';
import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

type OwnProps = {
  close(): void;
  reload(): void;
  show: boolean;
};

const connector = connect(
  (state, ownProps) => ({
    phase: getSelectedPhase(state, ownProps)?.toJS(),
  }),
  {
    showError,
    showSuccess,
    clearAllMatches,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type AssigmentsClearMatchesModalProps = PropsFromRedux & OwnProps;

const AssigmentsClearMatchesModal = ({
  phase,
  show,
  close,
  clearAllMatches,
  reload,
  showError,
  showSuccess,
}: AssigmentsClearMatchesModalProps) => {
  const [isLoading, startLoading, finishLoading] = useBooleanFlag(false);

  const handleClearAllMatches = async () => {
    startLoading();
    try {
      await clearAllMatches(phase?.id);
      showSuccess('All matches cleared.');
    } catch (e) {
      showError('Failed to clear matches, please try again.');
    } finally {
      finishLoading();
      close();
      reload();
    }
  };

  return (
    <GenericModal
      show={show}
      onCancel={close}
      onConfirm={handleClearAllMatches}
      name={'confirm-clear-all'}
      title="Clear All Matches"
      body={
        <>
          <p className="fw-bold text-center mt-4">
            <i className="fs-1 fa-regular fa-users-slash"></i>
            <br />
            <br />
            Are you sure you want to clear all
            <br />
            non-locked matches?
            <br />
            This action cannot be undone.
          </p>
        </>
      }
      cancelButton={'Cancel'}
      confirmButton={'Clear All'}
      size={'md'}
      isButtonDisabled={isLoading}
      isCancelButtonDisabled={isLoading}
    />
  );
};

export default withRouter(connector(AssigmentsClearMatchesModal));
