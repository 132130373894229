import Title from 'components/Title';

const styles = {
  main: { margin: 0, background: 'transparent', fontFamily: 'Arial', padding: '0 40px' },
};

const FeedbackUnavailable = ({ content }) => {
  if (content) {
    return <div style={styles.main} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return (
    <div style={styles.main}>
      <Title component="h4">No evaluations have been recorded yet for this Phase</Title>
    </div>
  );
};

export default FeedbackUnavailable;
