import { ChangeEvent, ReactNode } from 'react';
import cx from 'classnames';
import { Form } from '@valid-eval/shared-react-components';

import Styles from './FormulaChoice.module.scss';

type FormulaLabeledNumberProps = {
  label: ReactNode;
  number: number;
};

type FormulaLabeledInputProps = {
  label: ReactNode;
  value: string;
  onChange: (value: string) => void;
  max: number;
  min?: number;
};

type FormulaSymbolProps = { symbol: 'equals' | 'times' | 'divide' };

type MatchOption = 'teamsPerJudge' | 'judgesPerTeam';

export type FormulaValues = {
  teamsPerJudge: number;
  judgesPerTeam: number;
  activeJudges: number;
  activeTeams: number;
  averageJudgesPerTeam: number;
  averageTeamsPerJudge: number;
};

type FormulaChoiceProps = {
  selectedOption: MatchOption;
  formulaValues: FormulaValues;
  teamsPerJudge: string;
  setTeamsPerJudge: (value: string) => void;
  judgesPerTeam: string;
  setJudgesPerTeam: (value: string) => void;
};

const FormulaLabeledNumber = ({ label, number }: FormulaLabeledNumberProps) => (
  <div className="d-flex flex-column align-items-center justify-content-end rounded p-2 w-auto">
    <small className={cx('text-center text-muted lh-sm fw-bold mb-2', Styles.FormulaLabel)}>
      {label}
    </small>
    <small className={cx('text-center fs-5 text-muted fw-bold', Styles.FormulaNumber)}>
      {number}
    </small>
  </div>
);

const FormulaLabeledInput = ({
  label,
  value,
  onChange,
  max,
  min = 1,
}: FormulaLabeledInputProps) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\D/g, '');
    onChange(newValue);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-end rounded p-2 w-auto bg-white01">
      <small className={cx('text-center text-blue03 lh-sm fw-bold mb-2', Styles.FormulaLabel)}>
        {label}
      </small>
      <Form.Control
        min={min}
        type="number"
        value={value}
        className={cx('text-center fs-5 text-primary fw-bold p-0', Styles.FormulaInput)}
        onChange={handleInputChange}
        max={max}
      />
    </div>
  );
};

const FormulaSymbol = ({ symbol }: FormulaSymbolProps) => (
  <div className="d-flex flex-column align-items-center justify-content-end rounded p-2 w-auto mx-1">
    <small className="text-center fs-6 text-muted fw-bold">
      <i className={`fa-solid fa-${symbol}`}></i>
    </small>
  </div>
);

const FormulaChoice = ({
  selectedOption,
  formulaValues,
  teamsPerJudge,
  setTeamsPerJudge,
  judgesPerTeam,
  setJudgesPerTeam,
}: FormulaChoiceProps) => {
  const formulaInputProps: FormulaLabeledInputProps = {
    label:
      selectedOption === 'teamsPerJudge' ? (
        <>
          TEAMS PER
          <br />
          JUDGE
        </>
      ) : (
        <>
          JUDGES PER
          <br />
          TEAM
        </>
      ),
    value: selectedOption === 'teamsPerJudge' ? teamsPerJudge : judgesPerTeam,
    onChange: selectedOption === 'teamsPerJudge' ? setTeamsPerJudge : setJudgesPerTeam,
    max:
      selectedOption === 'teamsPerJudge' ? formulaValues.activeTeams : formulaValues.activeJudges,
  };

  const formulaNumbers = [
    {
      label:
        selectedOption === 'teamsPerJudge' ? (
          <>
            ACTIVE
            <br />
            JUDGES
          </>
        ) : (
          <>
            ACTIVE
            <br />
            TEAMS
          </>
        ),
      number:
        selectedOption === 'teamsPerJudge' ? formulaValues.activeJudges : formulaValues.activeTeams,
    },
    {
      label:
        selectedOption === 'teamsPerJudge' ? (
          <>
            TEAM
            <br />
            PARTICIPANTS
          </>
        ) : (
          <>
            ACTIVE
            <br />
            JUDGES
          </>
        ),
      number:
        selectedOption === 'teamsPerJudge' ? formulaValues.activeTeams : formulaValues.activeJudges,
    },
    {
      label:
        selectedOption === 'teamsPerJudge' ? (
          <>
            AVG. JUDGES
            <br />
            PER TEAM
          </>
        ) : (
          <>
            AVG. TEAMS
            <br />
            PER JUDGE
          </>
        ),
      number:
        selectedOption === 'teamsPerJudge'
          ? formulaValues.averageJudgesPerTeam
          : formulaValues.averageTeamsPerJudge,
    },
  ];

  return (
    <div className="d-flex justify-content-center my-3">
      <div className={cx('d-flex border border-2 rounded p-3', Styles.FormulaBorder)}>
        <FormulaLabeledInput {...formulaInputProps} />
        <FormulaSymbol symbol="times" />
        <FormulaLabeledNumber {...formulaNumbers[0]} />
      </div>
      <div className="d-flex p-3">
        <FormulaSymbol symbol="divide" />
        <FormulaLabeledNumber {...formulaNumbers[1]} />
        <FormulaSymbol symbol="equals" />
        <FormulaLabeledNumber {...formulaNumbers[2]} />
      </div>
    </div>
  );
};

export default FormulaChoice;
