import { Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { BoxContainerProps } from './types';
import Styles from './Styles.module.scss';

export const BoxContainer = ({ title, children }: BoxContainerProps) => {
  return (
    <>
      <Row>
        <p>{title}</p>
      </Row>
      <Row className={cx(Styles.boxContainer, 'text-center')}>{children}</Row>
    </>
  );
};

export default BoxContainer;
