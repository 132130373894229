import EventTitle from 'screens/teams/components/Title';
import Nav from 'components/Navigation';
import { judgeNav } from 'utils/urls';

const Navigation = ({ event, judgeProfileId, subTitle, teamWithCategory = null }) => (
  <div className="clearfix" style={{ marginTop: '40px', marginBottom: '32px' }}>
    <div className="float-start">
      <EventTitle
        logo={event.get('logo_url')}
        title={event.get('name')}
        subTitle={subTitle}
        skipSubTitle={!Boolean(subTitle)}
        teamWithCategory={teamWithCategory}
        role={'judge'}
      />
    </div>
    <div className="float-end">
      <Nav
        pills
        items={[
          { url: judgeNav.evaluations(event.get('id'), judgeProfileId), title: 'My evaluations' },
          { url: judgeNav.profile(event.get('id'), judgeProfileId), title: 'Profile' },
          {
            url: judgeNav.confirmation(event.get('id'), judgeProfileId),
            title: 'Signup Confirmation Page',
          },
        ]}
      />
    </div>
  </div>
);

export default Navigation;
