import { ListGroup } from '@valid-eval/shared-react-components';
import CopiedOverlay from 'screens/app/components/CopiedOverlay';
import HelpCMSPage from '../components/HelpCMSPage';

import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { teamNav } from 'utils/urls';

const footerLinks = (eventId, teamId, onClick) => (
  <ListGroup horizontal={'md'}>
    <CopiedOverlay>
      <ListGroup.Item className="p-0 pb-3 pt-3 me-4" action onClick={() => onClick('/')}>
        <i className="fa-solid fa-link" />
        Valid Eval Login
      </ListGroup.Item>
    </CopiedOverlay>

    <CopiedOverlay>
      <ListGroup.Item
        className="p-0 pb-3 pt-3 me-4"
        action
        onClick={() => onClick('/forgot-password')}
      >
        <i className="fa-solid fa-link" />
        Password Reset
      </ListGroup.Item>
    </CopiedOverlay>

    <CopiedOverlay>
      <ListGroup.Item
        className="p-0 pb-3 pt-3 me-4"
        action
        onClick={() => onClick(teamNav.confirmation(eventId, teamId))}
      >
        <i className="fa-solid fa-link" />
        Team Confirmation
      </ListGroup.Item>
    </CopiedOverlay>
  </ListGroup>
);

const Help = ({ event }) => {
  const params = useParams();
  const { team_id: teamId } = params;

  const handleClick = (link) => {
    if (!link.startsWith('http')) link = `${window.location.origin}${link}`;
    copy(link);
  };

  return (
    <HelpCMSPage role="team" footerLinks={footerLinks(event.get('id'), teamId, handleClick)} />
  );
};

export default Help;
