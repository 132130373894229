import { ListGroup } from '@valid-eval/shared-react-components';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import CopiedOverlay from 'screens/app/components/CopiedOverlay';
import {
  judgeConfirmationURL,
  judgeNav,
  signUpConfirmationURL,
  teamNav,
  teamSignUp,
} from 'utils/urls';

import Styles from '../HelpCMSPage.module.scss';

type HelpLinksProps = {
  eventId: string;
  eventSlug: string;
  handleRelNotes?: () => void;
  role?: string;
  showRelNotes?: boolean;
  judgeProfileId?: string;
  teamId?: string;
  vertical?: boolean;
};

const HelpLinks = ({
  eventSlug,
  eventId,
  showRelNotes,
  handleRelNotes,
  vertical,
  role,
  teamId,
  judgeProfileId,
}: HelpLinksProps) => {
  const handleClick = (link: string) => {
    if (!link.startsWith('http')) link = `${window.location.origin}${link}`;
    copy(link);
  };

  return (
    <ListGroup horizontal={vertical ? false : 'lg'}>
      <CopiedOverlay>
        <ListGroup.Item className="p-0 pb-3 pt-3 me-4" action onClick={() => handleClick('/')}>
          <i className="fa-solid fa-link" /> Valid Eval Login
        </ListGroup.Item>
      </CopiedOverlay>
      <CopiedOverlay>
        <ListGroup.Item
          className="p-0 pb-3 pt-3 me-4"
          action
          onClick={() => handleClick('/forgot-password')}
        >
          <i className="fa-solid fa-link" /> Password Reset
        </ListGroup.Item>
      </CopiedOverlay>
      {role === 'organizer' && (
        <CopiedOverlay>
          <ListGroup.Item
            className="p-0 pb-3 pt-3 me-4"
            action
            onClick={() => handleClick(teamSignUp(eventSlug))}
          >
            <i className="fa-solid fa-link" /> Team Signup
          </ListGroup.Item>
        </CopiedOverlay>
      )}
      {(role === 'organizer' || role === 'team') && (
        <CopiedOverlay>
          <ListGroup.Item
            className="p-0 pb-3 pt-3 me-4"
            action
            onClick={() =>
              handleClick(
                role === 'organizer'
                  ? signUpConfirmationURL(eventId)
                  : teamNav.confirmation(eventId, teamId),
              )
            }
          >
            <i className="fa-solid fa-link" /> Team Confirmation
          </ListGroup.Item>
        </CopiedOverlay>
      )}
      {(role === 'organizer' || role === 'judge') && (
        <CopiedOverlay>
          <ListGroup.Item
            className="p-0 pb-3 pt-3 me-4"
            action
            onClick={() =>
              handleClick(
                role === 'organizer'
                  ? judgeConfirmationURL(eventId)
                  : judgeNav.confirmation(eventId, judgeProfileId),
              )
            }
          >
            <i className="fa-solid fa-link" /> Judge Confirmation
          </ListGroup.Item>
        </CopiedOverlay>
      )}
      {handleRelNotes && (
        <div className={Styles.btnContainer}>
          <ListGroup.Item
            className={classNames(
              showRelNotes ? Styles.btnViewHelp : Styles.btnRelNotes,
              'btn',
              showRelNotes ? 'btn-primary text-white' : 'btn-default',
            )}
            action
            onClick={() => handleRelNotes()}
          >
            {showRelNotes ? (
              <>
                <i className={classNames(Styles.icnViewHelp, 'fal', 'fa-question-circle')} /> View
                Help Content
              </>
            ) : (
              <>
                <i className={classNames(Styles.icnRelNotes, 'fad', 'fa-window-maximize')} /> View
                Release Notes
              </>
            )}
          </ListGroup.Item>
        </div>
      )}
    </ListGroup>
  );
};

export default HelpLinks;
