import Loading from 'components/Loading';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { verifyEmailWithToken } from 'data/actions/users';
import { getCurrentUserId } from 'data/reducers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import withRouter from 'routes/withRouter';

const VerifyEmail = ({ query, navigate }) => {
  const dispatch = useDispatch();

  const userId = useSelector(getCurrentUserId);

  useEffect(() => {
    const errorMessage =
      'The verification token is no longer valid, please restart the verification process';

    if (!query?.token) {
      dispatch(notifyError(errorMessage));
    } else {
      (async () => {
        const { error, payload } = await dispatch(verifyEmailWithToken(userId, query.token));

        if (error)
          dispatch(
            notifyError([
              payload?.response?.error || 'Email could not be verified, please try again',
            ]),
          );
        else dispatch(notifySuccess('Email address added', 'Success'));

        navigate('/app/account');
      })();
    }
  }, []);

  return query?.token ? <Loading /> : <Navigate to="/" />;
};

export default withRouter(VerifyEmail);
