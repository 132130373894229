import { Button } from '@valid-eval/shared-react-components';

const ConfigureOtp = ({ token, isOtpConfigured, toggleMFA }) => {
  return (
    Boolean(token) && (
      <>
        <h2 className="mt-4 mb-4">Multi-factor authentication</h2>
        <Button id="set-up-mfa" onClick={toggleMFA}>
          {isOtpConfigured ? 'Reset' : 'Add'} multi-factor authentication
        </Button>
      </>
    )
  );
};

export default ConfigureOtp;
