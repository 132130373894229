import { configureStore as configureStoreRTK } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import { persistStore } from 'redux-persist-immutable';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../data/reducers';
import rootSaga from '../data/sagas';
import { handleApiErrors } from './middlewares';

const configureStore = ({ preloadState }) => {
  const saga = createSagaMiddleware();

  const store = configureStoreRTK({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(apiMiddleware)
        .concat(saga)
        .concat(handleApiErrors),
    preloadedState: preloadState,
  });

  persistStore(store, {
    whitelist: ['users', 'selectedPhases'],
  });
  saga.run(rootSaga);

  window.store = store;
  return store;
};

export default configureStore;
