import { Form } from '@valid-eval/shared-react-components';
import PropTypes from 'prop-types';
import Styles from './FiltersDropdown.module.scss';

function CheckboxesFilter({ field, onChange, options, title, filters }) {
  const makeHandleChange = (option) => (ev) =>
    onChange({
      field,
      checked: ev.target.checked,
      label: option.label,
      type: 'checkbox',
      value: option.value,
    });

  return (
    <>
      {title && <label className={Styles.FilterTitle}>{title}</label>}
      {options.map((option) => (
        <Form.Check
          key={`checkbox-filter-${field}-${option.value}`}
          id={`checkbox-filter-${field}-${option.value}`}
          label={option.label}
          value={option.value}
          onChange={makeHandleChange(option)}
          checked={!!filters.find((f) => f.value === option.value && f.field === field)}
        />
      ))}
    </>
  );
}

CheckboxesFilter.propTypes = {
  field: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  title: PropTypes.string,
  filters: PropTypes.array,
};

export default CheckboxesFilter;
