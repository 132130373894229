import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { sortStrings } from 'components/Tables/sortingHelpers';

import { Team } from '../../../shared/types';

const columnHelper = createColumnHelper<Team>();

export const columns: ColumnDef<Team, any>[] = [
  columnHelper.accessor('id', {
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),
  columnHelper.accessor('panel', {
    id: 'Panel',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('prefix', {
    id: 'Prefix',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('name', {
    id: 'Name',
    header: (props) => <CommonTableHeaders.SortableHeader {...props} className="text-left" />,
    cell: CommonTableCells.ProfileLink,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('progress', {
    id: 'Progress',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Progress,
  }),
  columnHelper.accessor('assignedJudges', {
    id: 'Matched',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.AssignedEntities,
  }),

  columnHelper.accessor('missingScores', {
    id: 'Incomplete',
    header: CommonTableHeaders.SortableHeader,
    cell: (props) => <CommonTableCells.NumberOrZero className="text-muted" {...props} />,
  }),

  columnHelper.accessor('teamScores', {
    id: 'Complete',
    header: CommonTableHeaders.SortableHeader,
    cell: (props) => <CommonTableCells.NumberOrZero className="text-muted" {...props} />,
  }),
  columnHelper.accessor('viewed', {
    header: (props) => (
      <CommonTableHeaders.SortableHeader {...props}>
        Feedback
        <br />
        Viewed
      </CommonTableHeaders.SortableHeader>
    ),
    cell: CommonTableCells.Boolean,
  }),
  columnHelper.accessor('status', {
    id: 'Status',
    header: CommonTableHeaders.SortableHeader,
    cell: (props) => <CommonTableCells.Text className="text-left text-wrap" {...props} />,
  }),
  columnHelper.accessor('latestMessage', {
    id: 'Last Message',
    header: CommonTableHeaders.SimpleHeader,
    cell: CommonTableCells.LatestMessage,
  }),
];
