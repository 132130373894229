import { PhaseSelect } from '@valid-eval/shared-react-components';

const PhaseSelector = (props) => {
  const { phases, selectedId, onPhaseChange, disabled } = props;

  return (
    <PhaseSelect
      inputId="select-phase"
      options={phases}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={onPhaseChange}
      value={phases.find((phase) => phase.id === selectedId)}
      disabled={disabled}
    />
  );
};

export default PhaseSelector;
