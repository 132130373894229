import { Col, Row } from '@valid-eval/shared-react-components';
import ProfileForm from 'components/Judges/ProfileForm';
import Loading from 'components/Loading';
import ProfileTitle from 'components/ProfileTitle';
import { load as loadJudge, update } from 'data/actions/judgeProfiles';
import { load as loadEvent } from 'data/actions/events';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import {
  getCurrentEventIsActive,
  getCurrentJudgeMiniProfile,
  getJudgeProfileDynamicFieldsForCurrentEvent,
  getJudgeProfileFromURL,
  getJudgeProfileInitialValues,
  getJudgeProfileOrphanDetails,
} from 'data/reducers';
import { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form/immutable';
import withRouter from 'routes/withRouter';
import { judgeNav } from 'utils/urls';
import UserExperienceInfo from './components/UserExperienceInfo';

const judgeInfoStyles = { margin: '32px -15px 0' };

class EditProfile extends Component {
  componentDidMount() {
    const {
      loadEvent,
      params: { event_id },
    } = this.props;
    loadEvent(event_id);
    this.props.loadJudge(this.props.params.judge_profile_id);
  }

  handleSave = (values) => {
    const {
      update,
      navigate,
      notifySuccess,
      notifyError,
      params: { event_id, judge_profile_id },
    } = this.props;

    return update(judge_profile_id, values).then(({ error, payload }) => {
      if (error) {
        const msg = (payload.response && payload.response.error) || 'Cannot update the profile';
        notifyError(msg);
        throw new SubmissionError({ _error: msg });
      }

      notifySuccess('All changes saved');
      navigate(judgeNav.evaluations(event_id, judge_profile_id));
    });
  };

  handleCancel = (ev) => {
    const {
      navigate,
      params: { event_id, judge_profile_id },
    } = this.props;
    navigate(judgeNav.evaluations(event_id, judge_profile_id));
  };

  render() {
    const {
      fullName,
      email,
      judge,
      dynamicFields,
      judgeInitialValues,
      orphanDetails,
      eventActive,
    } = this.props;
    const hasJudge = !!judgeInitialValues;
    return (
      <>
        <Row style={judgeInfoStyles}>
          <Col md={5}>
            <ProfileTitle title={fullName} subTitle={email} />
          </Col>
          <Col md={7}>
            <UserExperienceInfo experiences={judge} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {hasJudge ? (
              <ProfileForm
                disabled={!eventActive}
                dynamicFields={dynamicFields}
                orphanDetails={orphanDetails}
                initialValues={judgeInitialValues}
                onSubmit={this.handleSave}
              />
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { fullName, email } = getCurrentJudgeMiniProfile(state, ownProps);
      const dynamicFields = getJudgeProfileDynamicFieldsForCurrentEvent(state, ownProps);

      return {
        fullName,
        email,
        dynamicFields,
        judgeInitialValues: getJudgeProfileInitialValues(state, ownProps),
        judge: getJudgeProfileFromURL(state, ownProps),
        orphanDetails: getJudgeProfileOrphanDetails(state, ownProps),
        eventActive: getCurrentEventIsActive(state, ownProps),
      };
    },
    { loadJudge, update, notifySuccess, notifyError, loadEvent },
  )(EditProfile),
);
