import { actions } from 'data/actions/users';
import { all, fork, takeEvery } from 'redux-saga/effects';
import url from 'url';
import { getEnvVar, linkedinParamSeparator } from '../../config';

const linkedInCallbackUrl = url.format({
  protocol: getEnvVar('REACT_APP_HTTPS') ? 'https' : 'http',
  hostname: getEnvVar('REACT_APP_HOST'),
  port: getEnvVar('REACT_APP_PORT'),
  pathname: 'linkedIn',
});

const linkedinUrlFor = (type, queryParams = null) => {
  const rand = Math.random().toString(36).substring(7);

  let state = `${type}${linkedinParamSeparator}${rand}`;

  if (queryParams) {
    state += `${linkedinParamSeparator}${encodeURIComponent(JSON.stringify(queryParams))}`;
  }

  return (
    `${getEnvVar('REACT_APP_LINKED_IN_AUTH_URL')}?` +
    `response_type=${getEnvVar('REACT_APP_LINKED_IN_RESPONSE_TYPE')}&` +
    `client_id=${getEnvVar('REACT_APP_LINKED_IN_CLIENT_ID')}&` +
    `redirect_uri=${linkedInCallbackUrl}&state=${state}&` +
    `scope=${getEnvVar('REACT_APP_LINKED_IN_SCOPE')}`
  );
};

function performLinkedInLogin(action) {
  window.location.href = linkedinUrlFor('LOGIN', action.payload);
}

function performLinkedGetProfile(action) {
  window.location.href = linkedinUrlFor('STORE_PROFILE');
}

function* watchLinkedInLogin() {
  yield takeEvery(actions.LINKED_IN_LOGIN, performLinkedInLogin);
}
function* watchLinkedInFetchProfile() {
  yield takeEvery(actions.LINKED_IN_FETCH_PROFILE, performLinkedGetProfile);
}

const saga = function* () {
  yield all([fork(watchLinkedInLogin), fork(watchLinkedInFetchProfile)]);
};

export default saga;
