import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import './Navigation.scss';

const Navigation = ({ items, pills, upperNav }) => (
  <ul
    className={classNames('d-flex', 'd-print-none', 'unstyled-list', 'list-inline', 'nav', {
      'justify-content-end': upperNav,
      pills: pills,
    })}
  >
    {items.map((item) => (
      <li key={item.url} className={item.disabled ? 'disabled' : null} id={`${item.title}-link`}>
        <Item {...item} />
      </li>
    ))}
  </ul>
);

const Item = ({ url, title, active, onClick }) => {
  return (
    <NavLink
      className={({ isActive }) => (active || isActive ? 'active' : undefined)}
      to={url}
      onClick={onClick}
    >
      {title}
    </NavLink>
  );
};

Navigation.displayName = 'Navigation';
export default Navigation;
