import { organizerNav } from '../urls';

export const getActionButtons = (notification) => [
  {
    title: 'View Team Profile',
    url: organizerNav.teamProfile(
      notification.data.get('event_id'),
      notification.data.get('team_profile_id'),
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `The primary contact for team "${notification.data.get('team_name')}" has been changed to ${notification.data.get('primary_contact_name')}.`;
