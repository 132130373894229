import { Button, Form, Modal } from '@valid-eval/shared-react-components';
import { Group, Input } from 'components/FormFields';
import { useEffect, useState } from 'react';
import { change, getFormSyncErrors, getFormValues, isValid, reduxForm } from 'redux-form/immutable';
import { email, maxLength, required } from 'components/FormFields/validations';
import { requestVerificationCode, verifyEmail } from 'data/actions/users';
import { useDispatch, useSelector } from 'react-redux';

import Errors from '../../../components/Errors';
import { getCurrentUserId } from 'data/reducers';
import styles from './AddEmailModal.module.scss';
import { success } from 'data/actions/notifications';

const codeLength = 6;
const codeMaxLength = maxLength(codeLength);
const formName = 'addEmailForm';

const AddEmailModal = ({ show, onCancel, reset, handleSubmit }) => {
  const [wasCodeSend, setWasCodeSend] = useState(false);
  const [errors, setErrors] = useState(null);

  const dispatch = useDispatch();
  const userId = useSelector(getCurrentUserId);
  // Form selectors
  const formValues = useSelector(getFormValues(formName));
  const formIsValid = useSelector(isValid(formName));
  let formErrors = useSelector(getFormSyncErrors(formName));
  formErrors = formErrors.toJS?.() || formErrors;

  const resetMessages = () => {
    setWasCodeSend(false);
    setErrors(null);
  };

  // Reset the form every time the modal shows up
  useEffect(() => {
    if (show) reset();
    resetMessages();
  }, [show]);

  const handleEmailChange = () => {
    dispatch(change(formName, 'code', ''));
    resetMessages();
  };

  // Request verification code handler
  const handleRequestVerificationCode = async () => {
    const emailValue = formValues?.get('email');
    const { error, payload } = await dispatch(requestVerificationCode(userId, emailValue));
    if (error)
      return setErrors([
        payload?.response?.error || 'Verification code could not be requested, please try again',
      ]);

    setWasCodeSend(true);
  };

  // Request verification code email input keypress handler
  const handleEmailInputKeypress = (e) => {
    if (e.key === 'Enter') {
      handleRequestVerificationCode();
    }
  };

  // Submit email and code
  const handleSubmitForm = async (values) => {
    const email = values.get('email');
    const code = values.get('code');
    const { error, payload } = await dispatch(verifyEmail(userId, email, code));

    if (error)
      return setErrors([
        payload?.response?.error || 'Email could not be verified, please try again',
      ]);

    onCancel();
    dispatch(success('Email address added', 'Success'));
  };

  return (
    <Modal id={'add-email-modal'} show={show} onHide={onCancel}>
      <Form onSubmit={wasCodeSend ? handleSubmit(handleSubmitForm) : (e) => e.preventDefault()}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.subTitle}>Add Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {wasCodeSend && (
            <div className="alert alert-info">
              An email should arrive to the provided address within the next few minutes. Your new
              address will be added to your profile once you provide the enclosed verification code.
            </div>
          )}
          <Errors errors={errors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
          <Group
            autoFocus
            id="email"
            name="email"
            type="email"
            label="Email"
            validate={[required, email]}
            onChange={handleEmailChange}
            onKeyPress={handleEmailInputKeypress}
            after={
              <Button
                className={styles.btnEmail}
                id={'request-verification-code'}
                variant="success"
                disabled={Boolean(formErrors.email || formErrors.get?.('email'))}
                onClick={handleRequestVerificationCode}
              >
                {wasCodeSend ? 'Resend' : 'Request'} verification code
              </Button>
            }
          />
          {wasCodeSend && (
            <Input
              id="code"
              name="code"
              title="Verification code"
              disabled={!wasCodeSend}
              maxLength={codeLength}
              validate={[required, codeMaxLength]}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" id={'add-email-modal-cancel'} onClick={onCancel}>
            Cancel
          </Button>
          {wasCodeSend && (
            <Button
              type={'submit'}
              variant="success"
              id={'add-email-modal-add'}
              disabled={!formIsValid}
            >
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default reduxForm({ form: formName })(AddEmailModal);
