import qs from 'query-string';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';

import { useTranslation } from 'react-i18next';
import { load, loginResult } from '../data/actions/users';

const connector = connect(null, { load, loginResult });

const OauthComplete = ({ load, loginResult }: ConnectedProps<typeof connector>) => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const query = qs.parse(location.search);

  useEffect(() => {
    if (!query.error) {
      (async () => {
        // @ts-ignore
        const { payload } = await load('me');

        loginResult(payload.result);
        if (query.url !== location.pathname) {
          setTimeout(() => navigate(String(query.url)), 300);
        }
      })();
    } else {
      setError(true);
    }
  }, []);

  return (
    <>
      <br />
      {!error && <Loading text="" />}
      {error && <p className="text-center">{t(`auth.${query.error}`)}</p>}
    </>
  );
};

export default connector(OauthComplete);
