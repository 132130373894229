import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { judgeNav, teamNav } from 'utils/urls';
import styles from './ProfileList.module.scss';

const getURL = (profile) => {
  switch (profile.get('name')) {
    case 'Judge':
      return judgeNav.profile(profile.get('event_id'), profile.get('role_specific_id'));
    case 'Team':
      return teamNav.profile(profile.get('event_id'), profile.get('role_specific_id'));
    default:
      return null;
  }
};

const labelClassByRole = {
  Judge: 'info',
  Team: 'success',
  Organizer: 'default',
};

const eventLabelClass = (p) => `label label-${labelClassByRole[p.get('name')]}`;

const ProfileList = ({ list, title, noElement }) => (
  <div>
    <h2 className="mt-4">{title}</h2>
    <ul className={classNames(styles.ulStyle, styles.content)}>
      {list.count() === 0 && <p>{noElement}</p>}
      {list.map((profile, key) => (
        <li key={key}>
          <h3 className="m-0">
            <Link className={styles.contenLink} to={getURL(profile)}>{`${profile.get(
              'event_name',
            )}`}</Link>{' '}
            <span className={classNames(styles.content, eventLabelClass(profile))}>
              {profile.get('role_specific_name')}
            </span>
          </h3>
        </li>
      ))}
    </ul>
  </div>
);

ProfileList.displayName = 'ProfileList';
export default ProfileList;
