import { Form, InputGroup } from '@valid-eval/shared-react-components';
import { Controller } from 'react-hook-form';

import { required as requiredVal } from 'components/FormFields/validations';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import useFormFieldState from './useFormFieldState';

export type InputProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: { validate?: Record<string, any> };
  type?: 'text' | 'email' | 'password' | 'number';
  format?(value: string): string;
  normalize?(value: string): string;
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  as?: 'textarea';
  autoComplete?: 'on' | 'off';
  maxLength?: number;
};

const Input = ({
  after,
  as,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  type = 'text',
  format = (value: string) => value,
  normalize = (value: string) => value,
  disabled,
  required,
  helpText,
  autoComplete,
  maxLength,
}: InputProps) => {
  const { control, isValid, isInvalid, error } = useFormFieldState(name);

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal, ...rules.validate } } : rules;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ...fieldProps } }) => {
          return (
            <Form.Group className="mb-2">
              <Label htmlFor={id || name} label={label} />
              <HelpText text={helpText} />
              <InputGroup hasValidation>
                {before}
                <Form.Control
                  id={id || name}
                  onChange={(value) => onChange(normalize(value.target.value))}
                  placeholder={placeholder}
                  type={type}
                  value={format(value) ?? ''}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  disabled={disabled}
                  as={as}
                  autoComplete={autoComplete}
                  maxLength={maxLength}
                  {...fieldProps}
                />
                {after}
                <FieldError show={isInvalid} message={String(error?.message)} />
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    </>
  );
};

export default Input;
