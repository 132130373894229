import { Button, Col, Row } from '@valid-eval/shared-react-components';
import BoxContainer from './BoxContainer';
import cx from 'classnames';
import Styles from './Styles.module.scss';
import AuthyAuth from 'images/icons/authy.png';
import GoogleAuth from 'images/icons/google.png';
import MicrosoftAuth from 'images/icons/ms.png';
import { StepProps } from './types';

export const AuthenticatorApps = ({ currentStep, chooseStep }: StepProps) => {
  if (currentStep !== 2) {
    return null;
  }

  const handleDownloadApp = () => {
    chooseStep?.(3);
  };

  const handleSkipDownloadApp = () => {
    chooseStep?.(7);
  };

  return (
    <>
      <BoxContainer title="First, you need an authenticator app installed on your phone that generates one-time pass codes, like one of these:">
        <>
          <Col className="d-flex flex-row">
            <div className="p-2">
              <img className={Styles.icon} src={AuthyAuth} alt={'Authy'} width="40" />
            </div>
            <div className="p-2 align-self-center">
              <p className={Styles.justifyText}>AUTHY</p>
            </div>
          </Col>
          <Col className="d-flex flex-row">
            <div className="p-2">
              <img className={Styles.icon} src={GoogleAuth} alt={'Google'} width="40" />
            </div>
            <div className="p-2 align-self-center">
              <p className={Styles.justifyText}>GOOGLE AUTHENTICATOR</p>
            </div>
          </Col>
          <Col className="d-flex flex-row">
            <div className="p-2">
              <img className={Styles.icon} src={MicrosoftAuth} alt={'Microsoft'} width="40" />
            </div>
            <div className="p-2 align-self-center">
              <p className={Styles.justifyText}>MICROSOFT AUTHENTICATOR</p>
            </div>
          </Col>
        </>
      </BoxContainer>
      <Row>
        <p className="text-center">
          <strong>Already have an authenticator app?</strong>
        </p>
      </Row>
      <Row className={cx(Styles.modalFooter, Styles.modalFullFooter)}>
        <Col>
          <Button variant={'link'} onClick={handleDownloadApp} className="pe-4 ps-4">
            No, I need one
          </Button>
        </Col>
        <Col>
          <Button
            type={'submit'}
            variant={'link'}
            className="pe-4 ps-4"
            id="have-an-app-btn"
            onClick={handleSkipDownloadApp}
          >
            Yes, I do
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AuthenticatorApps;
