import { Alert } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import Members from 'screens/app/teams/Members';
import { fetchTeamDraftInEvent } from 'data/features/teamDrafts';

import { StepComponentProps } from '../types';
import StepContainer from './StepContainer';

const connector = connect(null, { fetchTeamDraftInEvent });

type TeamMembersStepProps = StepComponentProps & ConnectedProps<typeof connector>;

const TeamMembersStep = ({
  draft,
  event,
  onInfoChange,
  fetchTeamDraftInEvent,
}: TeamMembersStepProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    onInfoChange({
      requiredFieldsCount: 1,
      completedFieldsCount: draft?.members?.length ? 1 : 0,
      visited: true,
    });
  }, [draft?.id]);

  const handleMembersLoaded = (reload: boolean) =>
    reload && fetchTeamDraftInEvent({ eventId: event?.id || '' });

  return (
    <StepContainer
      content={
        draft?.id && event?.id ? (
          <>
            <Alert variant="warning">
              <div className="d-flex">
                <div className="w-auto d-flex align-items-center justify-content-center">
                  <i className="fa-solid fa-triangle-exclamation fa-2xl text-warning" />
                </div>
                <div className="ms-4 w-100 text-justify">
                  {t('auth.teams_sign_up.team_members_alert')}
                </div>
              </div>
            </Alert>
            <Members
              teamId={draft?.id}
              eventId={event?.id}
              isSignUpForm
              onMembersLoaded={handleMembersLoaded}
            />
          </>
        ) : null
      }
    />
  );
};

export default connector(TeamMembersStep);
