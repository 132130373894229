import { Button } from '@valid-eval/shared-react-components';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { getVersionUri } from 'utils';
import { resetLoginAttempts } from '../data/actions/users';
import Briefcase from './assets/briefcase.png';
import ErrorBoundary from './ErrorBoundary';

class UnlockAccount extends Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.props.resetLoginAttempts(this.props.resetToken).then((response) => {
      this.setState({ loading: false });
      if (response.error) {
        this.setState({ error: true });
        this.props.notifyError('A problem occurred with your link');
      } else {
        this.props.notifySuccess('Account successfully unlocked');
      }
    });
  }

  onClick = () => {
    this.props.navigate(`/`);
  };

  render() {
    const err = this.state.error ? 'Invalid link.' : 'Your account has been unlocked successfully.';
    return (
      <ErrorBoundary>
        <div className="container">
          <div className="login-container mb-5">
            <h1 className="login-title mt-3 mb-3">Account information</h1>
            <div className="login-box d-flex flex-column">
              <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
              <div style={{ flex: 1 }}>
                <p className="text-center">
                  {this.state.loading ? 'Wait while your account is unlock...' : err}
                </p>
                <div className="d-grid">
                  <Button variant="success" onClick={this.onClick}>
                    Done
                  </Button>
                </div>
              </div>
              <div className="text-center mt-4 mb-2">
                <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withRouter(
  connect((_, ownProps) => ({ resetToken: ownProps.params.reset_token }), {
    resetLoginAttempts,
    notifySuccess,
    notifyError,
  })(UnlockAccount),
);
