import { Col, Row } from '@valid-eval/shared-react-components';
import ProfileForm from 'components/Judges/ProfileForm';
import Loading from 'components/Loading';
import { update } from 'data/actions/judgeProfiles';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load as loadRoleSummaries, updateRoleStatus } from 'data/actions/roleSummaries';
import {
  getCurrentEventIsActive,
  getJudgeIsActive,
  getJudgeProfileDynamicFieldsForCurrentEvent,
  getJudgeProfileInitialValues,
  getJudgeProfileOrphanDetails,
  getPhasesByEventId,
  getSelectedPhaseIdForCurrentEvent,
} from 'data/reducers';
import { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form/immutable';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

const ROLE = 'judges';

class EditJudge extends Component {
  componentDidMount() {
    const { loadRoleSummaries, selectedPhaseId } = this.props;

    loadRoleSummaries(ROLE, selectedPhaseId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId !== this.props.selectedPhaseId) {
      this.props.loadRoleSummaries(ROLE, nextProps.selectedPhaseId);
    }
  }

  handleSave = (values) => {
    const { update, params, notifySuccess, notifyError, navigate } = this.props;
    return update(params.judge_profile_id, values).then(({ error, payload }) => {
      if (error) {
        const msg = (payload.response && payload.response.error) || 'Can not update judge profile';
        notifyError(msg);
        throw new SubmissionError({ _error: msg });
      }

      notifySuccess('All changes saved');
      navigate(organizerNav.judgeAssignments(params.event_id));
    });
  };

  handleCancel = (ev) => {
    const eventId = this.props.params.event_id;
    const url = isTeamLead()
      ? organizerNav.judgeManage(eventId)
      : organizerNav.judgeAssignments(eventId);
    this.props.navigate(url);
  };

  handleToggleActivate = () => {
    const {
      selectedPhaseId,
      updateRoleStatus,
      judgeActive,
      notifyError,
      notifySuccess,
      params: { judge_profile_id },
    } = this.props;
    updateRoleStatus(selectedPhaseId, ROLE, judge_profile_id, !judgeActive).then((payload) => {
      const activated = judgeActive ? 'deactivated' : 'activated';
      return payload.error
        ? notifyError('Status could not be updated')
        : notifySuccess(`Judge ${activated}`);
    });
  };

  render() {
    const { dynamicFields, judgeInitialValues, judgeActive, orphanDetails, eventActive, phases } =
      this.props;
    const hasJudge = !!judgeInitialValues;
    return (
      <Row>
        <Col md={6} className="mt-4 mb-5">
          {hasJudge ? (
            <ProfileForm
              disabled={!eventActive || isNavigator() || isTeamLead()}
              dynamicFields={dynamicFields}
              orphanDetails={orphanDetails}
              initialValues={judgeInitialValues?.toJS?.()}
              onSubmit={this.handleSave}
              onCancel={this.handleCancel}
              canActivate={!isNavigator() && !isTeamLead()}
              onToggleActivate={this.handleToggleActivate}
              judgeActive={judgeActive}
              phases={phases}
              isOrganizerOrNavigator
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const {
        params: { event_id: eventId },
      } = ownProps;
      return {
        dynamicFields: getJudgeProfileDynamicFieldsForCurrentEvent(state, ownProps),
        judgeInitialValues: getJudgeProfileInitialValues(state, ownProps),
        selectedPhaseId,
        judgeActive: selectedPhaseId && getJudgeIsActive(state, ownProps),
        orphanDetails: getJudgeProfileOrphanDetails(state, ownProps),
        eventActive: getCurrentEventIsActive(state, ownProps),
        phases: getPhasesByEventId(state, eventId),
      };
    },
    {
      update,
      loadRoleSummaries,
      updateRoleStatus,
      notifySuccess,
      notifyError,
    },
  )(EditJudge),
);
