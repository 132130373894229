import { ReactElement, cloneElement } from 'react';

import Checkbox from 'components/Form/Checkbox';
import CheckboxList from 'components/Form/CheckboxList';
import Date from 'components/Form/Date';
import Email from 'components/Form/Email';
import Input from 'components/Form/Input';
import RadioButtons from 'components/Form/RadioButtons';
import Select from 'components/Form/Select';
import { formatNumber, formatWholeNumber, normalize } from 'components/FormFields/utils';
import {
  date,
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  taxId,
  url,
  wholeNumber,
  zipCode,
} from 'components/FormFields/validations';
import { DynamicFieldType } from 'data/features/eventsTypes';

type Option = { value: string; label: string };

const sortOptions = (options: Option[], sort: boolean) => {
  if (!sort) return options;
  return options.sort((a, b) => a.label.localeCompare(b.label));
};

export const childrenToOptions = (
  children?: { id: string; name: string }[],
  sort: boolean = false,
): Option[] => sortOptions(children?.map((c) => ({ value: c.id, label: c.name })) || [], sort);

export const staticFieldTypes = ['read_only_content', 'generated'];

const DynamicField = ({
  field,
  disabled,
  wrapper = <></>,
}: {
  field: DynamicFieldType;
  disabled?: boolean;
  wrapper?: ReactElement;
}) => {
  let fieldComponent = null;

  const fieldProps = {
    id: field.id,
    name: field.field_key,
    label: field.title,
    helpText: field.instruction_text,
    required: field.required,
    rules: { validate: {} },
    disabled,
  };

  switch (field.field_type) {
    case 'text':
      fieldProps.rules = {
        validate: {
          ...(field.min_length ? { min: minLength(field.min_length) } : {}),
          ...(field.max_length ? { max: maxLength(field.max_length) } : {}),
        },
      };
      fieldComponent = <Input {...fieldProps} />;
      break;
    case 'text_area':
      fieldProps.rules = {
        validate: {
          ...(field.min_length ? { min: minLength(field.min_length) } : {}),
          ...(field.max_length ? { max: maxLength(field.max_length) } : {}),
        },
      };
      fieldComponent = <Input {...fieldProps} as="textarea" />;
      break;
    case 'dropdown':
      fieldComponent = (
        <Select {...fieldProps} options={childrenToOptions(field.children, field.sort_options)} />
      );
      break;
    case 'single_checkbox':
      fieldComponent = <Checkbox {...fieldProps} />;
      break;
    case 'checkbox_group':
      fieldComponent = (
        <CheckboxList
          {...fieldProps}
          options={childrenToOptions(field.children, field.sort_options)}
        />
      );
      break;
    case 'radio_buttons':
      fieldComponent = (
        <RadioButtons
          {...fieldProps}
          options={childrenToOptions(field.children, field.sort_options)}
        />
      );
      break;
    case 'email':
      fieldProps.rules = {
        validate: {
          ...(field.min_length ? { min: minLength(field.min_length) } : {}),
          ...(field.max_length ? { max: maxLength(field.max_length) } : {}),
        },
      };
      fieldComponent = <Email {...fieldProps} />;
      break;
    case 'url':
      fieldProps.rules = {
        validate: {
          url,
          ...(field.min_length ? { min: minLength(field.min_length) } : {}),
          ...(field.max_length ? { max: maxLength(field.max_length) } : {}),
        },
      };
      fieldComponent = <Input {...fieldProps} />;
      break;
    case 'date':
      fieldProps.rules = { validate: { date } };
      fieldComponent = <Date {...fieldProps} />;
      break;
    case 'number':
      fieldProps.rules = {
        validate: {
          number,
          ...(field.min_length ? { min: minValue(field.min_length) } : {}),
          ...(field.max_length ? { max: maxValue(field.max_length) } : {}),
        },
      };
      fieldComponent = (
        <Input {...fieldProps} format={formatNumber} normalize={normalize('number')} />
      );
      break;
    case 'tax_id':
      fieldProps.rules = { validate: { taxId } };
      fieldComponent = <Input {...fieldProps} />;
      break;
    case 'zip_code':
      fieldProps.rules = { validate: { zipCode } };
      fieldComponent = <Input {...fieldProps} />;
      break;

    case 'whole_number':
      fieldProps.rules = {
        validate: {
          wholeNumber,
          ...(field.min_length ? { min: minValue(field.min_length) } : {}),
          ...(field.max_length ? { max: maxValue(field.max_length) } : {}),
        },
      };
      fieldComponent = (
        <Input {...fieldProps} format={formatWholeNumber} normalize={normalize('number')} />
      );
      break;
    case 'read_only_content':
      fieldComponent = <div dangerouslySetInnerHTML={{ __html: field.content || '' }} />;
      break;
    case 'generated':
    default:
      break;
  }

  return fieldComponent ? cloneElement(wrapper, { children: fieldComponent }) : null;
};

const DynamicFields = ({
  fields,
  disabled,
  fieldWrapper = <></>,
}: {
  fields: DynamicFieldType[];
  disabled?: boolean;
  fieldWrapper: ReactElement;
}) => {
  return fields.map((field) => (
    <DynamicField key={field.id} field={field} wrapper={fieldWrapper} disabled={disabled} />
  ));
};

export default DynamicFields;
