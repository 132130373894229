import { getEvent } from 'data/reducers';
import { useState } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import HelpLinks from '../components/Help/HelpLinks';
import HelpCMSPage from '../components/HelpCMSPage';

const Help = ({ event, location }) => {
  const [showRelNotes, toggleRelNotes] = useState(location.search?.includes('release-notes'));

  const handleToggleRelNotes = () => {
    toggleRelNotes(!showRelNotes);
  };

  return (
    <div className="mt-4">
      <HelpCMSPage
        role="organizer"
        footerLinks={
          <HelpLinks
            eventSlug={event.get('slug')}
            eventId={event.get('id')}
            showRelNotes={showRelNotes}
            handleRelNotes={handleToggleRelNotes}
            role="organizer"
          />
        }
        viewRelNotes={showRelNotes}
      />
    </div>
  );
};

export default withRouter(
  connect((state, ownProps) => ({
    event: getEvent(state, ownProps.params.event_id),
  }))(Help),
);
