import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import transit from 'transit-immutable-js';

import { actions, changeTeamSelectedPhase } from '../actions/phases';
import { actions as teamActions } from '../actions/teams';

const { LOAD_PHASES_FOR_SELF_EVALUATION_RESULT } = teamActions;

function retrieveInitialState(key, emptyState) {
  if (key === 'reduxPersist:users') {
    const fromServerState = localStorage.getItem('reduxPersist:users:fromServer');

    if (fromServerState) {
      return fromJS(JSON.parse(fromServerState));
    }
  }

  const restoredState = JSON.parse(localStorage.getItem(key) || 'false');
  return restoredState ? transit.fromJSON(restoredState) : fromJS(emptyState);
}

const initialState = retrieveInitialState('reduxPersist:selectedPhases', {
  byOrganizer: {},
  byTeam: {},
});

const getFirstPhaseId = (phasesIds = [], phases = {}) => {
  try {
    const activePhases = phasesIds.filter((phaseId) => phases[phaseId].active);
    return activePhases.length > 0 ? activePhases[0] : phasesIds[0];
  } catch (e) {
    console.error(e);
  }
  return phasesIds[0];
};

const selectedPhases = handleActions(
  {
    [actions.SET_SELECTED_PHASE]: (state, action) =>
      state.setIn(['byOrganizer', action.payload.eventId], action.payload.phaseId),
    [changeTeamSelectedPhase.toString()]: (state, action) =>
      state.setIn(['byTeam', action.payload.teamId], action.payload.phaseId),
    [LOAD_PHASES_FOR_SELF_EVALUATION_RESULT]: {
      next(state, action) {
        const {
          teamId,
          result,
          entities: { phases },
        } = action.payload;

        const currentPhase = getSelectedPhaseIdForTeam(state, teamId);

        // Replace current phase unless there a phase selected and such phase came in the api response
        const replaceCurrentPhase = !(currentPhase && result.includes(currentPhase));
        return replaceCurrentPhase
          ? state.setIn(['byTeam', teamId], getFirstPhaseId(result, phases))
          : state;
      },
    },
  },
  initialState,
);

export const getSelectedPhaseId = (state, eventId) => {
  return state.getIn(['byOrganizer', eventId]);
};

export const getSelectedPhaseIdForTeam = (state, teamId) => {
  return state.getIn(['byTeam', teamId]);
};

export default selectedPhases;
