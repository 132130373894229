import { Col } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import Image from 'components/Image';
import withRouter from 'routes/withRouter';
import { dashboardUrlFromUserRole, showRolesAsDropdown } from 'utils/urls';

import Styles from '../Dashboard.module.scss';
import DashboardRolesLinks from './DashboardRolesLinks';
import DashboardRolesSelect from './DashboardRolesSelect';

const RoleOrderMap = {
  Organizer: 1,
  Navigator: 2,
  TeamLead: 3,
  Judge: 4,
  Team: 5,
  TeamDraft: 6,
};

const DashboardEventItem = ({ event, user, navigate, gap }) => {
  const { event_roles: eventRoles } = user?.toJS?.();
  const { id: eventId, logo_url: logoUrl } = event?.toJS?.();

  const imageUrl = logoUrl || '/images/no-image.png';
  const roles = eventRoles
    .filter((role) => role.event_id === eventId)
    .sort((a, b) => {
      const aOrder = RoleOrderMap[a.name];
      const bOrder = RoleOrderMap[b.name];
      return aOrder - bOrder;
    });
  const isSelect = showRolesAsDropdown(roles);

  const onClickEventHandler = () => navigate(dashboardUrlFromUserRole(roles[0], event));
  const isDraft = roles.length === 1 && roles[0].name === 'TeamDraft';

  return (
    <Col
      data-test-id={`event-card-${eventId}`}
      className={cx('m-2', Styles.eventGrid, {
        [Styles.cohortClickable]: roles.length < 2,
        'me-3': !!gap,
      })}
      onClick={roles.length === 1 && !isSelect ? onClickEventHandler : null}
    >
      <div className="d-flex">
        <div
          className={cx({
            [Styles.imageContainer]: imageUrl === '/images/no-image.png',
            [Styles.imageContainerNoBorders]: imageUrl !== '/images/no-image.png',
          })}
        >
          <Image src={imageUrl} width={80} height={80} fit center />
        </div>
        <div>
          <div className={Styles.eventTitle}>COHORT NAME</div>
          <div data-test-id={`event-name-${eventId}`} className={Styles.eventName}>
            {event.get('name')}
          </div>
        </div>
      </div>
      {isSelect ? (
        <DashboardRolesSelect roles={roles} event={event} />
      ) : (
        <div>
          {!isDraft && <div className={Styles.eventTitle}>ROLE</div>}
          <div className={isDraft ? 'd-grid gap-2' : 'd-flex'}>
            <DashboardRolesLinks roles={roles} event={event} />
          </div>
        </div>
      )}
    </Col>
  );
};

export default withRouter(DashboardEventItem);
