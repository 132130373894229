import { Form } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form/immutable';
import RadioListComponent from '../RadioList';
import Styles from './RadioList.module.scss';
import { dynamicFieldValidation } from './utils';

export const RadioScaleFieldGroup = ({
  input,
  meta: { error, warning, touched },
  id,
  label,
  helpText,
  minValue,
  maxValue,
  minValueLabel,
  maxValueLabel,
  step = 1,
  disabled,
}) => {
  const [options, setOptions] = useState([]);

  // Generate the scale options
  useEffect(() => {
    const options = [];
    for (let i = minValue; i <= maxValue; i += step) {
      options.push({ label: String(i), value: String(i) });
    }
    setOptions([...options]);
  }, [minValue, maxValue]);

  useEffect(() => {
    // Try to find the selected value within the values
    // If not preset set it to null
    if (input.value && options.length && !options.find((option) => option.value === input.value))
      input.onChange(null);
  }, [input.value, options]);

  return (
    <Form.Group className={Styles.inputTitle} controlId={id}>
      {label && (
        <Form.Label>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <div
        className={cx(
          'd-flex justify-content-between align-items-center mt-4',
          Styles.verticalCheckboxes,
        )}
      >
        <label className="me-3 d-none d-sm-block">{minValueLabel}</label>
        <RadioListComponent
          {...input}
          values={options}
          disabled={disabled}
          meta={{ error, warning, touched }}
        />
        <label className="ms-3 d-none d-sm-block">{maxValueLabel}</label>
      </div>
      <div className="d-flex justify-content-between d-block d-sm-none">
        <label className="me-3">{minValueLabel}</label>
        <label className="ms-3">{maxValueLabel}</label>
      </div>
    </Form.Group>
  );
};

export const RadioScale = ({
  id,
  name,
  title,
  helpText,
  minValue,
  maxValue,
  minValueLabel,
  maxValueLabel,
  step = 1,
  ...otherProps
}) => (
  <Field
    id={id || name}
    name={name}
    label={title}
    helpText={helpText}
    component={RadioScaleFieldGroup}
    minValue={minValue}
    maxValue={maxValue}
    minValueLabel={minValueLabel}
    maxValueLabel={maxValueLabel}
    step={step}
    validate={dynamicFieldValidation}
    {...otherProps}
  />
);
