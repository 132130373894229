import { Col, Row } from '@valid-eval/shared-react-components';
import Loading from 'components/Loading';
import { changeSelectedPhase, loadStatus } from 'data/actions/phases';
import { load as loadRubric } from 'data/actions/rubrics';
import { getSelectedPhaseId } from 'data/reducers';
import { Children, cloneElement, useEffect } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';

const RubricWrapper = ({
  params,
  changeSelectedPhase,
  selectedPhaseId,
  loadRubric,
  loadingRubric,
  children,
}) => {
  useEffect(() => {
    changeSelectedPhase(params.event_id, params.phase_id);
    loadRubric(params.phase_id);
  }, []);

  useEffect(() => {
    changeSelectedPhase(params.event_id, selectedPhaseId);
    loadRubric(selectedPhaseId);
  }, [selectedPhaseId]);

  const renderChildren = () => {
    return (
      <>
        <Row className="scoring-row">
          <Col md={12}>
            {cloneElement(Children.only(children), {
              selectedPhaseId: selectedPhaseId,
            })}
          </Col>
        </Row>
      </>
    );
  };

  return <div>{loadingRubric ? <Loading /> : renderChildren()}</div>;
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
      const loadingRubric = !selectedPhaseId;

      return {
        loadingRubric,
        selectedPhaseId: selectedPhaseId,
      };
    },
    {
      loadStatus,
      changeSelectedPhase,
      loadRubric,
    },
  )(RubricWrapper),
);
