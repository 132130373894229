import { FilterFn } from '@tanstack/react-table';

// Function to filter team results table
export const filterResults =
  (
    searchableFields: string[],
    additionalFilteringFunction?: (...args: any[]) => boolean,
  ): FilterFn<any> =>
  (row, _, value, __) => {
    const { original } = row;
    const { textFilter, otherFilters } = value as { textFilter: string; otherFilters: any[] };

    return (
      (additionalFilteringFunction?.(otherFilters, original) ?? true) &&
      filterByTextFilter(textFilter, original, searchableFields)
    );
  };

export const filterByTextFilter = (textFilter: string, record: any, searchableFields: string[]) => {
  if (textFilter.length) {
    const searchTerms = getSearchTerms(textFilter);
    return filterBySearchTerms(record, searchableFields, searchTerms);
  }

  return true;
};

// Generates an array of search terms using the boolean syntax (||, &&) example:
// "test || cool thing" => [
//  {terms: ['test'], operator: '&&'},
//  {terms: ['cool', 'thing'], operator: '||'}
// ]
function getSearchTerms(searchText: string) {
  let searchTerms = [];
  let operator = null;
  let searchTerm = null;
  do {
    const index = Math.max(searchText.lastIndexOf('||'), searchText.lastIndexOf('&&'));
    if (index === -1) {
      // No more boolean operators
      operator = '&&';
      searchTerm = searchText;
      searchText = '';
    } else {
      // boolean operator found
      [searchText, operator, searchTerm] = [
        searchText.slice(0, index),
        searchText.slice(index, index + 2),
        searchText.slice(index + 2),
      ];
    }
    const terms = searchTerm
      .split(' ')
      .map((t) => t.trim().toLowerCase())
      .filter(Boolean);

    if (terms.length) {
      searchTerms.push({
        terms: terms,
        operator,
      });
    }
  } while (searchText.length);

  return searchTerms.reverse();
}

// Apply the boolean search based on the search terms
export function filterBySearchTerms(
  record: any,
  fields: string[],
  searchTerms: {
    terms: string[];
    operator: string;
  }[],
) {
  // Get the values of every searchable field
  const values = fields.map((f) => record[f]);
  const expression = [];
  for (let searchTerm of searchTerms) {
    let currentTermFound = false;
    for (let value of values) {
      const includeTerms = searchTerm.terms.every(
        (term) => (value?.toLowerCase?.() || '').indexOf(term) > -1,
      );
      // Found at least one value that matches the term, stop iterating
      if (includeTerms) {
        currentTermFound = true;
        break;
      }
    }
    expression.push([currentTermFound, searchTerm.operator]);
  }
  // iterate each expression element and apply the corresponding operator
  return expression.reduce(
    (result, [val, operator]) =>
      operator === '&&' ? result && Boolean(val) : result || Boolean(val),
    true,
  );
}
