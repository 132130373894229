import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import Table from 'components/Tables/Table';
import { filterResults } from 'components/Tables/filteringHelpers';
import { FiltersState } from 'components/Tables/types';

import { columns } from './components/TeamsAddModalTableDefinition';
import TeamsAddModalTableControls from './components/TeamsAddModalTableControls';
import { InactiveTeam, phaseStatusFramework } from './types';
import { filterByStatus } from './components/helpers';
import Styles from './components/TeamsAdd.module.scss';

type TeamsAddModalTableProps = {
  allTeams: InactiveTeam[];
  data: InactiveTeam[];
  phase: any;
  statusFramework: phaseStatusFramework;
  onSelectedTeamsChange: (teams: InactiveTeam[]) => void;
  previousPhaseId: string | null;
  scoresCount: number;
};

const searchableFields = ['name', 'categories'];

const TeamsAddModalTable = ({
  allTeams,
  data,
  phase,
  statusFramework,
  onSelectedTeamsChange,
  previousPhaseId,
  scoresCount,
}: TeamsAddModalTableProps) => {
  const phaseId = phase?.id;
  const preselectionInitialized = useRef(false);
  const [globalFilter, setGlobalFilter] = useState<FiltersState>({
    textFilter: '',
    otherFilters: [],
  });
  const [rowSelection, onRowSelectionChange] = useState({});

  function handleTextFilterChange(textFilter: string) {
    setGlobalFilter({ ...globalFilter, textFilter });
  }

  const handleOtherFiltersChange = (otherFilters: any[]) => {
    setGlobalFilter({ ...globalFilter, otherFilters: [...otherFilters] });
  };

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection)
      .map((key) => allTeams.find((d) => d.id === key))
      .filter(Boolean) as InactiveTeam[];
    onSelectedTeamsChange(selectedRows);
  }, [rowSelection]);

  // Teams preselection
  useEffect(() => {
    if (!preselectionInitialized.current) {
      // Prevent preselection to happen twice when switching between phases
      if (!previousPhaseId && !scoresCount && typeof scoresCount === 'number' && data.length) {
        // No scores, no previous phase, preselect all
        const selection = data.reduce((selection, team) => ({ ...selection, [team.id]: true }), {});
        onRowSelectionChange(selection);
      } else if (previousPhaseId && scoresCount && data.length) {
        // Pre-select all in teams
        const selection = data.reduce(
          (selection, team) =>
            team.phases?.[0]?.id === previousPhaseId &&
            team.phases?.[0]?.outBubbleIn === statusFramework?.['IN'].value
              ? { ...selection, [team.id]: true }
              : selection,
          {},
        );
        onRowSelectionChange(selection);
      }
      preselectionInitialized.current = true;
    }
  }, [previousPhaseId, scoresCount, data]);

  const tableModel = useReactTable({
    data,
    columns,
    onRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: true,
    state: {
      globalFilter,
      rowSelection,
      columnVisibility: {
        Checkbox: true,
        'Team Name': true,
        'Valid Score': !!phaseId,
        Status: !!phaseId && !phase?.final_rank,
        'Most Recent Phase': Boolean(!phaseId && (previousPhaseId || scoresCount)),
        'Most Recent Status': Boolean(!phaseId && (previousPhaseId || scoresCount)),
        'Program Rank': !!phaseId && phase?.final_rank,
      },
    },
    meta: {
      tableId: 'teams-add-modal-table',
    },
    globalFilterFn: filterResults(searchableFields, filterByStatus),
  });

  return (
    <>
      <TeamsAddModalTableControls
        data={data}
        textFilter={globalFilter.textFilter}
        filters={globalFilter.otherFilters}
        onOtherFiltersChange={handleOtherFiltersChange}
        onTextFilterChange={handleTextFilterChange}
        searchableFields={searchableFields}
        finalRank={phase?.final_rank}
        showFilterMenu={Boolean(phaseId || scoresCount)}
        phase={phase}
      />
      <div className={cx('mt-2', Styles.ModalTable)}>
        <Table key={phaseId} tableModel={tableModel} />
      </div>
    </>
  );
};

export default TeamsAddModalTable;
