import FileField from './FileField';

import UrlField from './UrlField';

const Artifacts = ({
  eventId,
  teamId,
  disabled,
  artifacts,
  onSubmit,
  onChange,
  onDropFile,
  applicationRevisionRequest = null,
  disableDeleteIfRequired = false,
}) => {
  return (
    <div>
      {artifacts &&
        artifacts.map((artifact) => {
          const needRevision = applicationRevisionRequest?.artifact_ids?.includes(artifact.id);
          if (artifact.type === 'url' || artifact.type === 'web') {
            return (
              <UrlField
                form={artifact.name}
                title={artifact.name}
                id={artifact.name}
                name={String(artifact.id)}
                disabled={disabled && !needRevision}
                isRequired={artifact.required}
                initialValues={{ [artifact.id]: artifact.value }}
                instructions={artifact.instructions}
                key={artifact.name}
                onSubmit={onSubmit(artifact.name)}
              />
            );
          } else {
            return (
              <FileField
                teamId={teamId}
                eventId={eventId}
                form={artifact.name}
                title={artifact.name}
                id={artifact.name}
                name={String(artifact.id)}
                disabled={disabled && !needRevision}
                fileExtension={artifact.type}
                acceptedFileType={artifact.file_type}
                onDropFile={onDropFile}
                current={{ name: artifact.file_name, url: artifact.value }}
                instructions={artifact.instructions}
                key={artifact.name}
                disableDelete={disableDeleteIfRequired && artifact.required}
              />
            );
          }
        })}
    </div>
  );
};

export default Artifacts;
