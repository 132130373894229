import Breadcrumb, { Item } from 'components/Breadcrumb';
import { Children, Component, cloneElement } from 'react';
import { Col, Row } from '@valid-eval/shared-react-components';
import {
  getDocuments,
  getScoreFromURL,
  getTeamWithCategoryNameForCurrentScore,
} from 'data/reducers';

import LinksAndDownloads from 'components/LinksAndDownloads';
import Loading from 'components/Loading';
import { connect } from 'react-redux';
import { judgeNav } from 'utils/urls';
import { load } from 'data/actions/scores';
import { load as loadArtifactItems } from 'data/actions/artifactItems';
import { load as loadPhase } from 'data/actions/phases';
import { load as loadTeam } from 'data/actions/teams';
import withRouter from 'routes/withRouter';

class ScoringWrapper extends Component {
  componentDidMount() {
    this.props.load(this.props.params.score_id).then(this.loadEntitiesFromScore);
    if (this.props.score) {
      this.props.loadArtifactItems(
        this.props.score.get('team_id'),
        this.props.score.get('phase_id'),
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextScoreId = nextProps.score && nextProps.score.get('id');
    const scoreId = this.props.score && this.props.score.get('id');
    if (nextScoreId && nextScoreId !== scoreId) {
      this.props.loadArtifactItems(nextProps.score.get('team_id'), nextProps.score.get('phase_id'));
    }
  }
  loadEntitiesFromScore = (res) => {
    if (res.error) return;
    const { entities, result } = res.payload;
    const score = entities.scores[result];
    return Promise.all([this.props.loadTeam(score.team_id), this.props.loadPhase(score.phase_id)]);
  };

  renderChildren() {
    const {
      params: { event_id, judge_profile_id, score_id },
      location: { pathname },
      documents,
      children,
      score,
    } = this.props;
    const scoringURL = judgeNav.scoring(event_id, judge_profile_id, score_id);
    const teamId = score && score.get('team_id');

    return (
      <>
        <Row>
          <Col md={6}>
            <div style={{ marginLeft: '-10px' }}>
              <Breadcrumb pills>
                <Item active={pathname === scoringURL} url={scoringURL} style={{ width: '160px' }}>
                  Scoring
                </Item>
              </Breadcrumb>
            </div>
          </Col>
          {teamId && (
            <Col md={6}>
              {documents.size ? (
                <LinksAndDownloads teamId={teamId} eventId={event_id} />
              ) : (
                <span className="text-muted">No materials for this Phase</span>
              )}
            </Col>
          )}
        </Row>
        <Row className="scoring-row">
          <Col md={12}>
            {cloneElement(Children.only(children), {
              score,
              judgeUser: true,
              selfScoring: false,
            })}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { loadingScore } = this.props;

    return <div>{loadingScore ? <Loading /> : this.renderChildren()}</div>;
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const score = getScoreFromURL(state, ownProps);
      const teamId = score && score.get('team_id');
      const documents = teamId && getDocuments(state, teamId);
      const teamWithCategory = getTeamWithCategoryNameForCurrentScore(state, ownProps);
      const loadingScore = !score;

      return {
        documents,
        score,
        loadingScore,
        teamWithCategory,
      };
    },
    { load, loadArtifactItems, loadTeam, loadPhase },
  )(ScoringWrapper),
);
