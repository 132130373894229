import cx from 'classnames';

const getMiddleValue = (min, max) => (min + max) / 2;

export const calculateGradientForRange = (min, max, value) => {
  const rangeSize = Math.abs(min - max);
  const gradient = Math.round(100 - (Math.abs(max - value) / rangeSize) * 100);
  return gradient;
};

export const getGradientAndPosition = (min, max, value) => {
  const middle = getMiddleValue(min, max);
  const isAboveMiddle = value >= middle;
  const rangeEnd = isAboveMiddle ? max : min;
  const gradient = calculateGradientForRange(rangeEnd, middle, value);
  return { gradient, isAboveMiddle };
};

export function getRangeBasedClassName(min, max, value, inverse = false) {
  let { gradient, isAboveMiddle } = getGradientAndPosition(min, max, value);
  gradient = isNaN(gradient) ? 100 : gradient;
  return cx({
    [`positive-value-${gradient}`]: !inverse ? isAboveMiddle : !isAboveMiddle,
    [`negative-value-${gradient}`]: !inverse ? !isAboveMiddle : isAboveMiddle,
  });
}
