import * as api from '../services/recommendations';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

//Action Constants

export const actions = {
  LOAD_FOR_TEAM: 'recommendations/LOAD_FOR_TEAM',
  LOAD_FOR_TEAM_RESULT: 'recommendations/LOAD_FOR_TEAM_RESULT',
  LOAD_FOR_SCORE: 'recommendations/LOAD_FOR_SCORE',
  LOAD_SUCCESS: 'recommendations/LOAD_SUCCESS',
  LOAD_FAIL: 'recommendations/LOAD_FAIL',
  CREATE_COMMENT: 'recommendations/CREATE_COMMENT',
  CREATE_COMMENT_RESULT: 'recommendations/CREATE_COMMENT_RESULT',
  EDIT_COMMENT: 'recommendations/EDIT_COMMENT',
  EDIT_COMMENT_RESULT: 'recommendations/EDIT_COMMENT_RESULT',
  EDIT_RECOMMENDATION_TEXT: 'recommendations/EDIT_RECOMMENDATION_TEXT',
  EDIT_RECOMMENDATION_TEXT_RESULT: 'recommendations/EDIT_RECOMMENDATION_TEXT_RESULT',
  UPDATE: 'recommendations/UPDATE',
  UPDATE_RESULT: 'recommendations/UPDATE_RESULT',
  CREATE: 'recommendations/CREATE',
  CREATE_RESULT: 'recommendations/CREATE_RESULT',
  VOTE: 'recommendations/VOTE',
  VOTE_RESULT: 'recommendations/VOTE_RESULT',
  DELETE_VOTE: 'recommendations/DELETE_VOTE',
  DELETE_VOTE_RESULT: 'recommendations/DELETE_VOTE_RESULT',
  CHANGE_PRIORITY: 'recommendations/CHANGE_PRIORITY',
  CHANGE_PRIORITY_RESULT: 'recommendations/CHANGE_PRIORITY_RESULT',
};

export const loadForTeam = (teamId) => ({
  [RSAA]: {
    ...api.load(teamId),
    types: [
      {
        type: actions.LOAD_FOR_TEAM,
        payload: { teamId },
      },
      {
        type: actions.LOAD_FOR_TEAM_RESULT,
        payload: (action, state, res) =>
          getJSON(res)
            .then((payload) => normalize(payload.recommendations, [schemas.recommendation]))
            .then((result) => ({ teamId, ...result })),
      },
      actions.LOAD_FOR_TEAM_RESULT,
    ],
  },
});

export const create = (text, userId, teamId, phaseId, attributeAuthorTo) => ({
  [RSAA]: {
    ...api.create(text, userId, teamId, phaseId, attributeAuthorTo),
    types: [
      {
        type: actions.CREATE,
        payload: { teamId },
      },
      {
        type: actions.CREATE_RESULT,
        payload: (action, state, res) =>
          getJSON(res)
            .then((payload) => normalize(payload.recommendation, schemas.recommendation))
            .then((result) => ({ teamId, ...result })),
      },
      actions.CREATE_RESULT,
    ],
  },
});

export const update = (id, status) => ({
  [RSAA]: {
    ...api.update(id, { status }),
    types: [
      actions.UPDATE,
      {
        type: actions.UPDATE_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) =>
            normalize(payload.recommendations, [schemas.recommendation]),
          ),
      },
      actions.UPDATE_RESULT,
    ],
  },
});

export const changePriority = (teamId, id, priority_rank, direction) => ({
  [RSAA]: {
    ...api.update(id, { priority_rank }, direction),
    types: [
      {
        type: actions.CHANGE_PRIORITY,
        payload: { teamId, recommendationId: id, newPriorityRank: priority_rank, direction },
      },
      {
        type: actions.CHANGE_PRIORITY_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) =>
            normalize(payload.recommendations, [schemas.recommendation]),
          ),
      },
      actions.CHANGE_PRIORITY_RESULT,
    ],
  },
});

export const setAsTopPriority = (teamId, id) => changePriority(teamId, id, 1, 'up');

export const createComment = (recommendationId, text, authorId) => ({
  [RSAA]: {
    ...api.createComment(recommendationId, text, authorId),
    types: [
      actions.CREATE_COMMENT,
      {
        type: actions.CREATE_COMMENT_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.recommendation, schemas.recommendation)),
      },
      actions.CREATE_COMMENT_RESULT,
    ],
  },
});

export const editComment = (commentId, text) => ({
  [RSAA]: {
    ...api.editComment(commentId, text),
    types: [
      actions.EDIT_COMMENT,
      {
        type: actions.EDIT_COMMENT_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.recommendation, schemas.recommendation)),
      },
      actions.EDIT_COMMENT_RESULT,
    ],
  },
});

export const editRecommendationText = (recommendationId, text) => ({
  [RSAA]: {
    ...api.editRecommendationText(recommendationId, text),
    types: [
      actions.EDIT_RECOMMENDATION_TEXT,
      {
        type: actions.EDIT_RECOMMENDATION_TEXT_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.recommendation, schemas.recommendation)),
      },
      actions.EDIT_RECOMMENDATION_TEXT_RESULT,
    ],
  },
});

export const vote = (recommendationId, authorId, value) => ({
  [RSAA]: {
    ...api.vote(recommendationId, value, authorId),
    types: [
      actions.VOTE,
      {
        type: actions.VOTE_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.recommendation, schemas.recommendation)),
      },
      actions.VOTE_RESULT,
    ],
  },
});

export const deleteVote = (recommendationId, authorId) => ({
  [RSAA]: {
    ...api.deleteVote(recommendationId, authorId),
    types: [
      actions.DELETE_VOTE,
      {
        type: actions.DELETE_VOTE_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.recommendation, schemas.recommendation)),
      },
      actions.DELETE_VOTE_RESULT,
    ],
  },
});
