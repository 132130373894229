import { Col, Row } from '@valid-eval/shared-react-components';
import { useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Loading from 'components/Loading';
import { getEventPhasesList, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';

import TeamsAddModalPhaseSelector from './TeamsAddModalPhaseSelector';
import TeamsAddModalTable from './TeamsAddModalTable';
import { InactiveTeam, InactiveTeamPhase } from './types';

type OwnProps = {
  loading: boolean;
  data: InactiveTeam[];
  selectedTeams: InactiveTeam[];
  onSelectedTeamsChange: (teams: InactiveTeam[]) => void;
  previousPhaseId: string | null;
  scoresCount: number;
};

const mapDispatchToProps = {};
const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  selectedPhase: getSelectedPhase(state, ownProps)?.toJS?.(),
  // @ts-ignore
  phases: getEventPhasesList(state, ownProps.params?.event_id),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamsAddModalTableProps = PropsFromRedux & OwnProps;

const TeamsAddModalBody = ({
  data,
  loading,
  selectedTeams,
  onSelectedTeamsChange,
  phases,
  previousPhaseId,
  scoresCount,
}: TeamsAddModalTableProps) => {
  const [phase, setPhase] = useState<any>(null); // All teams will be set on PhaseSelector mounts

  useEffect(() => {
    if (previousPhaseId) {
      const phaseFound = phases.find((phase) => phase.id === previousPhaseId);
      setPhase(phaseFound);
    }
  }, [previousPhaseId]);

  // Filter teams by selected phase in the modal
  const filteredTeams: InactiveTeam[] = useMemo(() => {
    if (!phase?.id)
      return data.map((team) => {
        const teamPhase = team.phases
          .slice()
          .reverse()
          .find((p) => p.status);
        return {
          ...team,
          phases: [teamPhase] as InactiveTeamPhase[],
        };
      });

    return data
      .map((team) => {
        const teamPhase = team.phases.find((p) => p.id === phase.id);
        return { ...team, phases: (teamPhase ? [teamPhase] : []) as InactiveTeamPhase[] };
      })
      .filter((team) => team.phases[0]?.status);
  }, [phase?.id, data]);

  if (loading) return <Loading />;

  return (
    <>
      <Row className="justify-content-center">
        <Col lg={10} md={12} className="p-0">
          <TeamsAddModalPhaseSelector
            onChange={setPhase}
            value={phase}
            selectedTeams={selectedTeams}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TeamsAddModalTable
            phase={phase}
            statusFramework={phase?.status_framework}
            allTeams={data}
            data={filteredTeams}
            previousPhaseId={previousPhaseId}
            scoresCount={scoresCount}
            onSelectedTeamsChange={onSelectedTeamsChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(connector(TeamsAddModalBody));
