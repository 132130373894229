import csv from 'images/icons/icon_csv.png';
import docx from 'images/icons/icon_doc.png';
import generic from 'images/icons/icon_generic.png';
import pdf from 'images/icons/icon_pdf.png';
import pptx from 'images/icons/icon_ppt.png';
import txt from 'images/icons/icon_txt.png';
import mov from 'images/icons/icon_video.png';
import web from 'images/icons/icon_web.png';
import xlsx from 'images/icons/icon_xls.png';

import { Document } from 'data/features/documentsTypes';

const ICONS: Record<string, string> = {
  csv,
  docx,
  pdf,
  pptx,
  txt,
  mp4: mov,
  mov,
  web,
  xlsx,
  generic,
};

const SignUpDocument = ({ document }: { document: Document }) => {
  const type = document.name.split('.').pop() || '';
  const icon = ICONS[type] || ICONS.generic;

  return (
    <div>
      <div className="d-flex mb-2 align-items-center">
        <div className="me-3">
          <img src={icon} alt={document.title} />
        </div>
        <a
          href={document.url}
          className="fw-bold"
          target="__blank
        "
        >
          {document.title}
        </a>
      </div>
      <p>{document.instructions}</p>
    </div>
  );
};

export default SignUpDocument;
