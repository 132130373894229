import { Form } from '@valid-eval/shared-react-components';
import { Field } from 'redux-form/immutable';
/* eslint no-mixed-operators: 0 */
import styles from './CheckBox.module.scss';

import { dynamicFieldValidation } from './utils';

export const CheckboxFieldGroup = ({
  input,
  id,
  meta: { touched, error, warning },
  label,
  helpText,
  disabled,
}) => {
  return (
    <Form.Group className={styles.inputTitle} controlId={id}>
      {label && (
        <Form.Label>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <Form.Check
        {...input}
        id={`${input.name}-checkbox`}
        label={label}
        disabled={disabled}
        type={'checkbox'}
        checked={input.value}
        isInvalid={touched && (error || warning)}
        isValid={touched && !(error || warning)}
        feedback={
          touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))
        }
      />
    </Form.Group>
  );
};

export const Checkbox = ({ id, name, title, helpText, disabled, validate }) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={title}
      disabled={disabled}
      helpText={helpText}
      component={CheckboxFieldGroup}
      validate={validate || dynamicFieldValidation}
    />
  );
};
