import { all, fork } from 'redux-saga/effects';

import assignments from './assignments';
import evaluations from './evaluations';
import events from './events';
import invitationTemplates from './invitationTemplates';
import judgeInvitations from './judgeInvitations';
import phases from './phases';
import scores from './scores';
import teams from './teams';
import users from './users';

export default function* root() {
  yield all([
    fork(users),
    fork(events),
    fork(teams),
    fork(assignments),
    fork(invitationTemplates),
    fork(judgeInvitations),
    fork(evaluations),
    fork(phases),
    fork(scores),
  ]);
}
