import {
  LOAD_MORE_RESULT,
  LOAD_RESULT,
  MARK_ALL_AS_SEEN_RESULT,
  TOGGLE_SEEN_RESULT,
  actions,
} from 'data/actions/userNotifications';
import { List, fromJS } from 'immutable';

import { handleActions } from 'redux-actions';
import { useSelector } from 'react-redux';

const initialState = fromJS({
  total_count: 0,
  unseen_count: 0,
  offset: null,
});

export default handleActions(
  {
    [LOAD_RESULT]: (state, action, ...rest) => {
      const { userId, entities, total_count, unseen_count, offset } = action.payload;
      const notifications = (entities && entities.userNotifications) || {};
      return state
        .mergeIn([userId], fromJS(notifications))
        .set('total_count', total_count)
        .set('unseen_count', unseen_count)
        .set('offset', offset);
    },
    [LOAD_MORE_RESULT]: (state, action) => {
      const { userId, entities, total_count, unseen_count, offset } = action.payload;
      const notifications = (entities && entities.userNotifications) || {};
      return state
        .mergeIn([userId], fromJS(notifications))
        .set('total_count', total_count)
        .set('unseen_count', unseen_count)
        .set('offset', offset);
    },
    [actions.ADD]: (state, action) => {
      const { userId, data, unseenCount, totalCount } = action.payload;
      return state
        .mergeIn([userId, String(data.id)], fromJS(data))
        .set('unseen_count', unseenCount)
        .set('total_count', totalCount);
    },
    [actions.REMOVE]: (state, action) => {
      const { userId, data, unseenCount, totalCount } = action.payload;
      return state
        .deleteIn([userId, String(data.id)])
        .set('unseen_count', unseenCount)
        .set('total_count', totalCount);
    },
    [actions.SEEN]: (state, action) => {
      const { userId, data, unseenCount, totalCount } = action.payload;
      return state
        .mergeIn([userId, String(data.id)], fromJS(data))
        .set('unseen_count', unseenCount)
        .set('total_count', totalCount);
    },
    [TOGGLE_SEEN_RESULT]: (state, action) => {
      const { userId, entities } = action.payload;
      const notifications = (entities && entities.userNotifications) || {};
      return state.mergeIn([userId], fromJS(notifications));
    },
    [MARK_ALL_AS_SEEN_RESULT]: (state, action) => {
      const { userId } = action.payload;
      return state
        .updateIn([userId], (list) => list?.map((n) => n.set('seen', true)))
        .set('unseen_count', 0);
    },
  },
  initialState,
);

export const getAllUnseen = (state, userId) => {
  if (!state.getIn([userId])) return List();
  return state
    .getIn([userId])
    .filter((notification) => !notification.get('seen'))
    .valueSeq();
};

export const getAll = (state, userId) => {
  if (!state.getIn([userId])) return List();
  return state.getIn([userId]).valueSeq();
};

export const useNotification = (userId, notificationId) =>
  useSelector((state) => state.getIn(['userNotifications', userId, notificationId])?.toJS());
