import { Col, Row } from '@valid-eval/shared-react-components';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Box } from 'components/Box';
import { loadAll } from 'data/actions/judgeInvitations';
import {
  getJudgesStatus,
  getResultsStatus,
  getSelectedPhaseId,
  getTeamsStatus,
  isLoadingJudgesStatus,
  isLoadingResultsStatus,
  isLoadingTeamsStatus,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { JudgesStatus, ResultsStatus, TeamsStatus } from './components/PhaseStatus';

class Dashboard extends Component {
  render() {
    const { loadingResults, loadingTeams, loadingJudges, results, teams, judges } = this.props;
    return (
      <Row className="mt-5 ms-0 me-0 justify-content-center">
        <Col lg={4} md={8} sm={10}>
          <h2>Results</h2>
          <Box loading={loadingResults}>
            {results ? (
              <ResultsStatus info={results} eventId={this.props.params.event_id} />
            ) : (
              <p className="text-center">No evaluations completed so far</p>
            )}
          </Box>
        </Col>
        <Col lg={4} md={8} sm={10}>
          <h2>Judges</h2>
          <Box loading={loadingJudges}>
            {judges ? (
              <JudgesStatus info={judges} eventId={this.props.params.event_id} />
            ) : (
              <p>No Judge invitations yet</p>
            )}
          </Box>
        </Col>
        <Col lg={4} md={8} sm={10}>
          <h2>Teams</h2>
          <Box loadingTeams={loadingTeams}>
            {teams ? (
              <TeamsStatus info={teams} eventId={this.props.params.event_id} />
            ) : (
              <p className="text-center">No teams so far</p>
            )}
          </Box>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
      const loadingResults = isLoadingResultsStatus(state, selectedPhaseId);
      const loadingTeams = isLoadingTeamsStatus(state, selectedPhaseId);
      const loadingJudges = isLoadingJudgesStatus(state, selectedPhaseId);
      const loadingInviteJudges = isLoadingJudgesStatus(state, selectedPhaseId);

      const results = getResultsStatus(state, selectedPhaseId);
      const teams = getTeamsStatus(state, selectedPhaseId);
      const judges = getJudgesStatus(state, selectedPhaseId);
      const inviteJudges = getJudgesStatus(state, selectedPhaseId);

      return {
        loadingResults,
        loadingTeams,
        loadingJudges,
        loadingInviteJudges,
        results,
        teams,
        judges,
        inviteJudges,
      };
    },
    { loadAll },
  )(Dashboard),
);
