import * as eventsAPI from '../services/events';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { createAction } from 'redux-actions';
import { listToObject } from '../../utils';
import { normalize } from 'normalizr';

export const actions = {
  LOAD: 'events/LOAD',
  LOAD_CONTENT: 'events/LOAD_CONTENT',
  SAVE: 'events/SAVE',
  SAVE_FAIL: 'events/SAVE_FAIL',
  SAVE_SUCCESS: 'events/SAVE_SUCCESS',
};

export const save = (eventId, event) => {
  return { type: actions.SAVE, payload: { eventId, event } };
};

export const saveFail = () => {
  return { type: actions.SAVE_FAIL };
};

export const saveSuccess = (event) => {
  return { type: actions.SAVE_SUCCESS, payload: { event } };
};

export const loadMultipleResult = createAction('events/LOAD_MULTIPLE_RESULT', (events) => {
  return listToObject(events);
});

export const LOAD_MULTIPLE_REQUEST = 'events/LOAD_MULTIPLE_REQUEST';
export const LOAD_MULTIPLE_RESULT = 'events/LOAD_MULTIPLE_RESULT';

export const loadMultiple = () => ({
  [RSAA]: {
    ...eventsAPI.loadMultiple(),
    types: [
      LOAD_MULTIPLE_REQUEST,
      {
        type: LOAD_MULTIPLE_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.events, [schemas.event])),
      },
      LOAD_MULTIPLE_RESULT,
    ],
  },
});

export const LOAD_RESULT = 'events/LOAD_RESULT';

export const load = (id) => ({
  [RSAA]: {
    ...eventsAPI.load(id),
    types: [
      actions.LOAD,
      {
        type: LOAD_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.event, schemas.eventWithCategories)),
      },
      LOAD_RESULT,
    ],
  },
});

export const EXPORT_PDF_START = 'events/EXPORT_PDF_START';
export const EXPORT_PDF_MULTIPLE_START = 'events/EXPORT_PDF_MULTIPLE_START';
export const EXPORT_PDF = 'events/EXPORT_PDF';

export const exportPDF = (
  url,
  email,
  eventName,
  teamId,
  teamName,
  phaseName,
  userId,
  eventId,
  phaseId,
) => ({
  [RSAA]: {
    ...eventsAPI.exportPDF(
      url,
      email,
      eventName,
      teamId,
      teamName,
      phaseName,
      userId,
      eventId,
      phaseId,
    ),
    types: [
      {
        type: EXPORT_PDF_START,
        payload: url,
      },
      EXPORT_PDF,
      EXPORT_PDF,
    ],
  },
});

export const exportPDFMultiple = ({
  eventId,
  eventName,
  phaseId,
  phaseName,
  statuses,
  first,
  last,
  url,
  team_ids,
}) => ({
  [RSAA]: {
    ...eventsAPI.exportPDFMultiple({
      eventId,
      eventName,
      phaseId,
      phaseName,
      statuses,
      first,
      last,
      url,
      team_ids,
    }),
    types: [
      {
        type: EXPORT_PDF_MULTIPLE_START,
      },
      EXPORT_PDF,
      EXPORT_PDF,
    ],
  },
});

export const SET_COOKIES_CONSENT = 'events/SET_COOKIES_CONSENT';

export const setCookiesConsent = (eventId, cookies) => ({
  type: SET_COOKIES_CONSENT,
  payload: { eventId, cookies },
});
