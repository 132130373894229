import { getEnvVar } from 'config';

export const actions = { ADD: 'notifications/ADD', REMOVE: 'notifications/REMOVE' };

const envTimeout = parseInt(getEnvVar('REACT_APP_ALERT_DURATION', '3000'));

export function add(type, message, headline, timeout = envTimeout, id = new Date().getTime()) {
  return { type: actions.ADD, payload: { id, type, message, headline, timeout } };
}

export function success(message, headline, timeout) {
  return add('success', message, headline, timeout);
}

export function error(message, headline, timeout) {
  return add('danger', message, headline, timeout);
}

export function info(message, headline, timeout) {
  return add('info', message, headline, timeout);
}

export function remove(notification) {
  return { type: actions.REMOVE, payload: { id: notification.id } };
}
