import { CellContext } from '@tanstack/react-table';
import cx from 'classnames';

import StatusButton from 'screens/app/results/components/StatusButton';
import { InactiveTeam, InactiveTeamPhase } from '../types';
import Styles from './TeamsAdd.module.scss';

export const TeamName = ({ getValue, row }: CellContext<InactiveTeam, string>) => {
  return (
    <td>
      <div className={cx('text-start text-truncate', Styles.TeamNameTableColumn)}>
        {getValue()}
        <br />
        <small className="text-muted">{row.original.categories}</small>
      </div>
    </td>
  );
};

export const ValidScore = ({ getValue }: CellContext<InactiveTeam, InactiveTeamPhase[]>) => {
  const phases = getValue();
  const currentPhase = phases.slice().pop() || null;

  return (
    <td className={cx('text-center')} width="100">
      {currentPhase?.validScore?.toFixed?.(2) || 'N/A'}
    </td>
  );
};

export const ProgramRank = ({ getValue }: CellContext<InactiveTeam, InactiveTeamPhase[]>) => {
  const phases = getValue();
  const currentPhase = phases.slice().pop() || null;

  return (
    <td className={cx('text-center')} width="100">
      {currentPhase?.finalRank || 'N/A'}
    </td>
  );
};

export const Status = ({ getValue, row }: CellContext<InactiveTeam, InactiveTeamPhase[]>) => {
  const phases = getValue();
  const currentPhase = phases.slice().pop() || null;

  return (
    <td width="120">
      <StatusButton
        disabled
        status={currentPhase?.outBubbleIn}
        teamId={row.original.id}
        statusFramework={currentPhase?.statusFramework}
      />
    </td>
  );
};

export const LatestPhase = ({ getValue }: CellContext<InactiveTeam, InactiveTeamPhase[]>) => {
  const phases = getValue();
  const latestPhase = phases.slice().pop() || { name: 'None' };

  return (
    <td className="text-center" width="150">
      {latestPhase?.name}
    </td>
  );
};

export const LatestStatus = ({ getValue, row }: CellContext<InactiveTeam, InactiveTeamPhase[]>) => {
  const phases = getValue();
  const latestPhase = phases.slice().pop() || {
    id: null,
    outBubbleIn: 'empty',
    phaseFinalRank: false,
  };
  const isFinalRank = latestPhase.phaseFinalRank;

  return (
    <td className="text-center" width="120">
      {latestPhase.id && (
        <>
          {isFinalRank && `Ranked: ${latestPhase?.finalRank}`}
          {!isFinalRank && (
            <StatusButton
              disabled
              status={latestPhase?.outBubbleIn}
              teamId={row.original.id}
              statusFramework={latestPhase?.statusFramework}
            />
          )}
        </>
      )}
    </td>
  );
};
