import { TeamDraft } from './types';

export const searchableFields = ['name', 'email'];

export const filterByStatus = (filterList: any[], teamDraftResult: TeamDraft) => {
  if (filterList.length && filterList.length < 2) {
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value], []);

    if (status.length > 0 && status.includes(true) && !teamDraftResult.latestMessage) return false;

    if (status.length > 0 && status.includes(false) && teamDraftResult.latestMessage) return false;
  }
  return true;
};
