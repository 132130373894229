import { LOAD_FOR_EVENT, LOAD_FOR_EVENT_RESULT } from '../actions/artifacts';
import { List, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import { Actions } from '../actions/artifactItems';

import { createSelector } from 'reselect';

const initialState = fromJS({ loading: false, data: {}, byEvent: {} });

const reducer = handleActions(
  {
    [LOAD_FOR_EVENT]: {
      next(state, action) {
        return state.set('loading', true);
      },
    },
    [Actions.LOAD_FOR_TEAM_RESULT]: {
      next(state, action) {
        const {
          entities: { artifacts },
        } = action.payload;
        return state.set('loading', false).mergeDeepIn(['data'], fromJS(artifacts));
      },
    },
    [LOAD_FOR_EVENT_RESULT]: {
      next(state, action) {
        const {
          entities: { artifacts },
          result,
          eventId,
        } = action.payload;
        return state
          .set('loading', false)
          .setIn(['byEvent', eventId], fromJS(result))
          .mergeDeepIn(['data'], fromJS(artifacts));
      },
    },
  },
  initialState,
);

export const byEvent = createSelector(
  (state) => state.get('data'),
  (state, eventId) => state.getIn(['byEvent', eventId], List()),
  (data, ids) => ids.map((id) => data.get(String(id))),
);

export const getLookup = (state) => state.get('data');

export const getIsLoadingArtifacts = (state) => state.get('loading', false);

export default reducer;
