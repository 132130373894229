import { Form, FormGroup } from '@valid-eval/shared-react-components';
import { connect } from 'react-redux';
import {
  getFormSubmitErrors,
  getFormSyncErrors,
  hasSubmitFailed,
  reduxForm,
} from 'redux-form/immutable';

import { formatNumber, normalize } from 'components/FormFields/utils';
import {
  name,
  number,
  passwordStrength,
  required,
  sanitizeName,
} from 'components/FormFields/validations';

import { BoxContainer } from 'components/Box';
import Content from 'components/Content';
import { renderDynamicFields } from 'components/dynamicFields';
import { Email, Input, Number, Select } from 'components/FormFields';
import LoadingButton from 'components/LoadingButton';
import Title from 'components/Title';
import { isGovEnv } from 'config';

const validate = (values) => {
  const errors = {};

  if (values.get('password_confirmation') !== values.get('password')) {
    errors.password_confirmation = 'Passwords must match';
  }

  if (values.get('password') && passwordStrength(values.get('password'))) {
    errors.password = passwordStrength(values.get('password'));
  }

  return errors;
};

const normalizeNumber = normalize('number');

const blankNumberFormat = (value) => (value === null || value === '' ? '' : formatNumber(value));

const blankNumberNormalize = (value) => {
  if (value === null) return 0;
  else return value !== '' ? normalizeNumber(value) : value;
};

const SignUpForm = ({
  handleSubmit,
  jobFunctions,
  businessDisciplines,
  submitting,
  anotherProfile,
  dynamicFields,
  matchingSectionTitle,
  matchingSectionInstructions,
  currentUser,
  hasSubmitFailed,
  submitErrors,
  syncErrors,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        name="first_name"
        title="First Name"
        disabled={!currentUser}
        normalize={sanitizeName}
        validate={[required, name]}
      />
      <Input
        name="last_name"
        title="Last Name"
        disabled={!currentUser}
        normalize={sanitizeName}
        validate={[required, name]}
      />
      <Email name="email" title="Email Address" disabled />
      <Select
        id="primary_job_function"
        name="primary_job_function"
        title="Primary Job Function"
        options={jobFunctions}
        disabled={!currentUser}
      />
      <Select
        id="business_discipline"
        name="business_discipline"
        title="Business Discipline"
        options={businessDisciplines}
        disabled={!currentUser}
      />
      {!isGovEnv() && (
        <>
          <Number
            id="early_stage_investing_experience"
            name="early_stage_investing_experience"
            title="Early Stage Investing Experience"
            helpText="How many startup companies have you invested in? ( ± 20%)"
            validate={number}
            disabled={!currentUser}
          />
          <Number
            id="fund_raising_experience"
            name="fund_raising_experience"
            title="Fundraising Experience"
            helpText="How many rounds of funding have you raised for startups? ( ± 20%)"
            validate={number}
            disabled={!currentUser}
          />
          <Number
            id="exits"
            name="exits"
            title="Exits"
            helpText="Number of companies you have sold for a profit ( ± 20%)"
            validate={number}
            format={blankNumberFormat}
            normalize={blankNumberNormalize}
            disabled={!currentUser}
          />
          <Number
            id="failures"
            name="failures"
            title="Failures"
            helpText="Number of companies where you have failed ( ± 20%)"
            validate={number}
            format={blankNumberFormat}
            normalize={blankNumberNormalize}
            disabled={!currentUser}
          />
        </>
      )}

      {renderDynamicFields(
        dynamicFields.data,
        (field) => {
          const name = field.get('name');
          return name && name.match(/^((?!categories).)*$/);
        },
        !currentUser,
      )}

      {matchingSectionTitle && <Title component="h3">{matchingSectionTitle}</Title>}
      {matchingSectionInstructions && <Content html={matchingSectionInstructions} />}
      <BoxContainer>
        {renderDynamicFields(
          dynamicFields.data,
          (field) => {
            const name = field.get('name');
            return name && name.match(/categories/);
          },
          !currentUser,
        )}
      </BoxContainer>

      <FormGroup className="mt-3 mb-3">
        <LoadingButton
          loading={submitting}
          variant="primary"
          type="submit"
          disabled={!currentUser}
          hasSubmitFailed={hasSubmitFailed}
          submitErrors={submitErrors}
          syncErrors={syncErrors}
        >
          Save Profile
        </LoadingButton>
      </FormGroup>
    </Form>
  );
};

export default reduxForm({ form: 'judge_signup', validate, enableReinitialize: true })(
  connect((state) => {
    let submitErrors = getFormSubmitErrors('judge_signup')(state) || {};
    let syncErrors = getFormSyncErrors('judge_signup')(state) || {};
    return {
      hasSubmitFailed: hasSubmitFailed('judge_signup')(state),
      submitErrors: submitErrors.toJS?.() || submitErrors,
      syncErrors: syncErrors.toJS?.() || syncErrors,
    };
  })(SignUpForm),
);
