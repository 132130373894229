import { Form } from '@valid-eval/shared-react-components';
import { Input } from 'components/FormFields';
import { required } from 'components/FormFields/validations';
import { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form/immutable';

type AddApiKeyFormProps = InjectedFormProps;

const AddApiKeyForm = (props: AddApiKeyFormProps) => {
  return (
    <Form onSubmit={props.handleSubmit}>
      {/* @ts-ignore needed because Input is not a TS component */}
      <Input
        className="mb-4 mt-0"
        id="name"
        name="name"
        label={'Name'}
        autoFocus
        validate={[required]}
      />
      {/* @ts-ignore needed because Input is not a TS component */}
      <Input
        className="mb-4 mt-0"
        id="organizationName"
        name="organizationName"
        label={'Organization'}
        readOnly
      />
      <input type="hidden" id="organizationId" name="organizationId" />
      <input type="submit" className="d-none" />
    </Form>
  );
};

export default reduxForm({ form: 'createApiKey' })(AddApiKeyForm);
