import { CellContext } from '@tanstack/react-table';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Team } from 'screens/app/events/shared/types';
import { isNavigator, isTeamLead } from 'utils';

import Styles from './TeamsAdd.module.scss';
import TeamRemovalBlockedTooltip from './TeamRemovalBlockedTooltip';

export const TeamName = ({
  getValue,
  row,
  className,
}: CellContext<Team, string> & { className: string }) => {
  const teamScores = row.original.teamScores;
  return (
    <td className={cx(className, Styles.TeamNameColumn, 'text-start text-truncate')}>
      <div className="d-flex align-items-center">
        {!isNavigator() && (
          <div className="me-1" style={{ width: 20 }}>
            {Boolean(teamScores) && !isNavigator() && !isTeamLead() && (
              <TeamRemovalBlockedTooltip />
            )}
          </div>
        )}
        <div className="text-truncate">
          <Link
            to={row.original.url || ''}
            data-test-id={`team-link-${row.original.id}`}
            className={Styles.TeamName}
          >
            {getValue()}
            <br />
            <span className={Styles.TeamCategory}>{row.original.categories}</span>
          </Link>
        </div>
      </div>
    </td>
  );
};
