import { WizardModal } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import { requestVerificationCode, signUp, login, load } from 'data/actions/users';
import withRouter from 'routes/withRouter';
import SignUpForm from './SignUpForm';
import { SignUpFormType } from './types';

type OwnProps = {
  onMFASetup(otpRequestToken: string, userName: string): void;
  isTeamMemberInvitation: boolean;
  location: { pathname: string; search: string };
  registered: string | undefined;
  query: {
    email?: string;
    code?: string;
    token?: string;
    first_name?: string;
    last_name?: string;
    invitation_id?: string;
  };
  params: {
    invitation_id?: string;
  };
  show?: boolean;
  onClose?(): void;
};

const mapDispatchToProps = { requestVerificationCode, signUp, login, load };

const connector = connect(null, mapDispatchToProps);

const LoginModalWizard = ({
  location,
  query,
  params,
  requestVerificationCode,
  signUp,
  login,
  load,
  registered,
  isTeamMemberInvitation,
  show = true,
  onClose,
}: ConnectedProps<typeof connector> & OwnProps) => {
  const { t } = useTranslation();
  const [wasCodeSend, codeSent, codeNotSent] = useBooleanFlag(!!query.token);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, loading, loaded] = useBooleanFlag();
  const invitationId = query.invitation_id || params.invitation_id;

  const form = useForm<SignUpFormType>({
    defaultValues: {
      email: query.email || '',
      first_name: query.first_name || '',
      last_name: query.last_name || '',
      code: query.code || '',
      password: '',
      password_confirmation: '',
    },
  });

  // Reset form if email changes
  useEffect(() => {
    const subscription = form.watch((_, { name, type }) => {
      if (name === 'email' && type === 'change') {
        codeNotSent();
        setError(null);
        form.reset({
          email: form.getValues('email'),
          first_name: '',
          last_name: '',
          code: undefined,
          password: '',
          password_confirmation: '',
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const throwOnError = (response: any) => {
    if (!response.error) return;
    throw new Error(response.payload?.response?.error || t('auth.sign_up.error.failed'));
  };

  const handleRequestVerificationCode = async () => {
    setError(null);
    loading();
    const { email } = form.getValues();
    try {
      throwOnError(await requestVerificationCode(null, email, location.pathname));
      codeSent();
    } catch (error: any) {
      setError(error.message);
    } finally {
      loaded();
    }
  };

  const handleNext = async (data: SignUpFormType) => {
    setError(null);

    // if is no code was requested and this is not an invite, request a code
    if (!wasCodeSend && !invitationId && !isTeamMemberInvitation)
      return handleRequestVerificationCode();

    try {
      loading();
      throwOnError(
        await signUp({
          ...data,
          sign_up_url: location.pathname + location.search,
          invitation_id: invitationId,
          is_team_member_invitation: isTeamMemberInvitation,
        }),
      );
      throwOnError(await login(data.email, data.password));
      throwOnError(await load('me'));
    } catch (error: any) {
      setError(error.message);
    } finally {
      loaded();
    }
  };

  return (
    <FormProvider {...form}>
      <WizardModal
        show={show}
        onClose={onClose}
        title={t('auth.sign_up.title')}
        body={
          <SignUpForm
            error={error}
            isLoading={isLoading}
            onNext={handleNext}
            wasCodeSend={wasCodeSend}
            invitationId={invitationId}
            onRequestVerificationCode={handleRequestVerificationCode}
            token={query.token}
            registered={registered}
            isTeamMemberInvitation={isTeamMemberInvitation}
          />
        }
        centered
      />
    </FormProvider>
  );
};

export default withRouter(connector(LoginModalWizard));
