import { Row, flexRender } from '@tanstack/react-table';
import cx from 'classnames';
import React from 'react';

import Styles from './CommonTableStyles.module.scss';

type TableRowProps = {
  row: Row<any>;
};

const TableRow = ({ row }: TableRowProps) => {
  return (
    <tr className={cx(Styles.TableRow)} data-test-id={`table-row-${row.id}`}>
      {row.getVisibleCells().map((cell) => (
        <React.Fragment key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </React.Fragment>
      ))}
    </tr>
  );
};

export default TableRow;
