import * as actions from '../actions/roleSummaries';

import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

const initialState = fromJS({
  loadingInactiveTeams: false,
  loading: false,
  judges: { loading: false, byPhase: {}, idsByPhase: {} },
  teams: { loading: false, byPhase: {}, idsByPhase: {} },
});

const assignments = handleActions(
  {
    [actions.LOAD]: {
      next(state, action) {
        return state.setIn([action.payload.entity, 'loading'], true);
      },
    },
    [actions.LOAD_SUMMARY_RESULT]: {
      next(state, action) {
        const {
          phaseId,
          entity,
          entities: { roleSummaries },
          result,
        } = action.payload;
        return state
          .set('loading', false)
          .setIn([entity, 'loading'], false)
          .setIn([entity, 'idsByPhase', phaseId], result)
          .setIn([entity, 'byPhase', phaseId], fromJS(roleSummaries));
      },
      throw(state, action) {
        return state.set('loading', false).setIn([action.payload.entity, 'loading'], false);
      },
    },
    [actions.UPDATE_ROLE_STATUS]: {
      next(state, action) {
        return state.setIn(
          [
            action.payload.entity,
            'byPhase',
            action.payload.phaseId,
            action.payload.roleId,
            'active',
          ],
          action.payload.active,
        );
      },
    },
    [actions.UPDATE_ROLE_STATUSES]: {
      next(state, action) {
        return state.updateIn(
          [action.payload.entity, 'byPhase', action.payload.phaseId],
          (records) => records?.map((item) => item.set('active', action.payload.active)) || [],
        );
      },
    },
    [actions.UPDATE_ROLE_STATUSES_RESULT]: {
      next(state, action) {
        return state.mergeDeepIn(
          [action.payload.entity, 'byPhase', action.payload.phaseId],
          fromJS(action.payload.entities.roleSummaries),
        );
      },
    },
  },
  initialState,
);

const getSummariesByPhase = (entity) => (state, phaseId) =>
  state
    .getIn([entity, 'idsByPhase', phaseId], [])
    .map((roleSummaryId) => state.getIn([entity, 'byPhase', phaseId, roleSummaryId]));

export const getTeamSummariesBySelectedPhase = getSummariesByPhase('teams');

export const getJudgeSummariesBySelectedPhase = getSummariesByPhase('judges');

export const isLoadingRoleSummaries = (state) => state.get('loading');

export const isLoadingJudgeRoles = (state) => state.getIn(['judges', 'loading'], false);

export const isLoadingTeamRoles = (state) => state.getIn(['teams', 'loading'], false);

export default assignments;
