import PropTypes from 'prop-types';

import TeamCompareSelectorWrapper from './TeamCompareSelectorWrapper';
import TeamSelectorControl from './TeamSelectorControl';

function TeamCompareSelector({
  teams,
  teamA,
  teamB,
  teamC,
  onSelectTeam,
  isZScore,
  eventId,
  phaseId,
  phase,
}) {
  const teamsForA = teams.filter((team) => {
    return team?.id !== teamB?.id && team?.id !== teamC?.id;
  });

  const teamsForB = teams.filter((team) => {
    return team?.id !== teamA?.id && team?.id !== teamC?.id;
  });

  const teamsForC = teams.filter((team) => {
    return team?.id !== teamA?.id && team?.id !== teamB?.id;
  });

  return (
    <TeamCompareSelectorWrapper>
      <TeamSelectorControl
        isZScore={isZScore}
        teams={teamsForA}
        team={teamA}
        letter="A"
        onSelectTeam={onSelectTeam}
        eventId={eventId}
        phaseId={phaseId}
        phase={phase}
      />
      <TeamSelectorControl
        isZScore={isZScore}
        teams={teamsForB}
        team={teamB}
        letter="B"
        onSelectTeam={onSelectTeam}
        eventId={eventId}
        phaseId={phaseId}
        phase={phase}
      />
      <TeamSelectorControl
        isZScore={isZScore}
        teams={teamsForC}
        team={teamC}
        letter="C"
        onSelectTeam={onSelectTeam}
        eventId={eventId}
        phaseId={phaseId}
        phase={phase}
      />
    </TeamCompareSelectorWrapper>
  );
}

TeamCompareSelector.propTypes = {
  onSelectTeam: PropTypes.func.isRequired,
  isZScore: PropTypes.bool,
  teamA: PropTypes.object,
  teamB: PropTypes.object,
  teamC: PropTypes.object,
  teams: PropTypes.array.isRequired,
};

export default TeamCompareSelector;
