import { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { resendLockoutPasswordEmail } from '../data/actions/users';
import ErrorBoundary from './ErrorBoundary';

class LockoutEmailRequest extends Component {
  componentDidMount() {
    this.props.resendLockoutPasswordEmail(this.props.params.email);
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="container">
          <div className="login-container mb-5">
            <h1 className="login-title mt-3 mb-3">You will receive an email shortly.</h1>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
export default withRouter(connect(null, { resendLockoutPasswordEmail })(LockoutEmailRequest));
