import { Container } from '@valid-eval/shared-react-components';
import parse from 'html-react-parser';
import { useEffect, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { getUserRole } from 'components/NotesV2/helpers';
import ProfileForm from 'components/Teams/ProfileForm';
import { load as loadEvent } from 'data/actions/events';
import { load as loadTeam } from 'data/actions/teams';
import {
  fetchArtifactPdfReportContent,
  fetchPdfReportArtifacts,
  fromArtifacts,
} from 'data/features/artifacts';
import { fetchNotes, fromNotes } from 'data/features/notes';
import {
  getEvent,
  getTeamProfileDynamicFieldsForCurrentEvent,
  getTeamProfileInitialValues,
  getTeamProfileOrphanDetails,
} from 'data/reducers';
import withRouter from 'routes/withRouter';

import PDFHeader from '../results/components/PDFHeader';
import NotesList from 'components/NotesV2/NotesList';

type OwnProps = {
  params: {
    event_id: string;
    team_id: string;
    artifact_id: string;
    phase_id: string;
  };
};

const connector = connect(
  (state: any, ownProps: OwnProps) => {
    const eventId = ownProps.params.event_id;
    const teamId = ownProps.params.team_id;
    const artifactId = ownProps.params.artifact_id;
    const phaseId = ownProps.params.phase_id;

    return {
      eventId,
      teamId,
      artifactId,
      phaseId,
      artifact: fromArtifacts.getArtifact(state?.toJS?.(), eventId, parseInt(artifactId)),
      team: getTeamProfileInitialValues(state, ownProps),
      dynamicFields: getTeamProfileDynamicFieldsForCurrentEvent(state, ownProps),
      // @ts-ignore
      orphanDetails: getTeamProfileOrphanDetails(state, ownProps),
      event: getEvent(state, ownProps.params.event_id),
      content: fromArtifacts.getContent(state?.toJS?.(), artifactId, teamId, phaseId),
      notes: fromNotes.getOverallNotes(state?.toJS(), {
        phaseId: phaseId,
        teamId: teamId,
        redacted: false,
        judgeRedactedNames: false,
        excludeDimensionNotes: false,
      }),
    };
  },
  {
    fetchArtifactPdfReportContent,
    fetchNotes,
    fetchPdfReportArtifacts,
    loadEvent,
    loadTeam,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PrintArtifactTeamPdfReportProps = PropsFromRedux & OwnProps;

const PrintArtifactTeamPdfReport = ({
  artifact,
  artifactId,
  content,
  dynamicFields,
  event,
  eventId,
  fetchArtifactPdfReportContent,
  fetchNotes,
  fetchPdfReportArtifacts,
  loadEvent,
  loadTeam,
  notes,
  orphanDetails,
  phaseId,
  team,
  teamId,
}: PrintArtifactTeamPdfReportProps) => {
  useEffect(() => {
    loadTeam(teamId);
    loadEvent(eventId);
    fetchPdfReportArtifacts(eventId);
  }, [loadTeam, loadEvent, fetchPdfReportArtifacts, eventId, teamId]);

  useEffect(() => {
    if (teamId && artifactId && phaseId) {
      fetchArtifactPdfReportContent({ artifactId, teamId, phaseId });
      fetchNotes({ phaseId, teamId, role: getUserRole(true) });
    }
  }, [phaseId, teamId, artifactId]);

  const teamDetails = useMemo(
    () => artifact?.team_details?.map((td: string) => parseInt(td)) || [],
    [artifact],
  );

  const htmlContent = useMemo(
    () =>
      parse(content || '', {
        replace: (domNode) => {
          const { data } = domNode as any;
          // Comments need to be replaced here for proper rendering
          if (
            data?.includes('{{team_program_notes}}') ||
            data?.includes('{{team_consensus_meeting_notes}}')
          ) {
            let filter = data.includes('{{team_program_notes}}')
              ? (note: Record<string, any>) => ['Organizer'].includes(note.role)
              : (note: Record<string, any>) => ['JudgeProfile', 'TeamLead'].includes(note.role);
            return (
              <NotesList
                notes={notes.filter(filter)}
                emptyLabel=""
                phaseId={phaseId}
                teamId={teamId}
                isOrganizerScreen
              />
            );
          }
          return domNode;
        },
      }),
    [content, notes],
  );

  // Render nothing if no team or no artifact found
  if (!team || !artifact) return null;

  return (
    <Container>
      <PDFHeader team={team} event={event} showReport={false} />
      <div>{htmlContent}</div>
      {teamDetails.length > 0 && (
        <ProfileForm
          // @ts-ignore
          filterDynamicFields={(field: any) => teamDetails.includes(parseInt(field.get('row_id')))}
          disabled={true}
          dynamicFields={dynamicFields}
          orphanDetails={orphanDetails}
          initialValues={team}
        />
      )}
    </Container>
  );
};

export default withRouter(connector(PrintArtifactTeamPdfReport));
