import { TeamResult } from './types';

/**
 * Returns the maximun and minimum sigma values
 */
export const getMaxAndMinSigma = (scores: TeamResult[]) => {
  const sigmas = scores.map((s) => s.valid_score_valid_z || 0).sort((a, b) => a - b);
  const maxSigma = sigmas.pop();
  const minSigma = sigmas.shift();
  return { maxSigma, minSigma };
};

export const statusType = {
  in: 0,
  bubble: 1,
  out: 2,
};

export const searchableFields = ['team_name', 'category', 'team_prefix', 'panel'];

/**
 * Used to sort by status
 */
export const sortByStatus = (a: TeamResult, b: TeamResult) => {
  const statusA = a.status || -1;
  const statusB = b.status || -1;
  return statusA - statusB;
};

export const filterByStatus = (filterList: any[], teamResult: TeamResult) => {
  if (filterList.length) {
    // Apply the decision status filter if is selected
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value ?? null], []);
    if (!status.includes(teamResult.status)) return false;
  }
  return true;
};

export const sortByAggrementSrength = (a: TeamResult, b: TeamResult): number => {
  const scoreCountA = a.score_count || 0;
  const scoreCountB = b.score_count || 0;

  // if score count is less than 2, return -1 so this score is always at the bottom
  const agrementStrengthA = scoreCountA > 1 ? a.valid_score_agreement_strength || 0 : -1;
  const agrementStrengthB = scoreCountB > 1 ? b.valid_score_agreement_strength || 0 : -1;

  return agrementStrengthA - agrementStrengthB;
};
