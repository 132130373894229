import { Badge } from '@valid-eval/shared-react-components';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Styles from '../Dashboard.module.scss';
import cx from 'classnames';
import { dashboardUrlFromUserRole } from 'utils/urls';
import uuid from 'uuid-random';

const DashboardRolesLink = ({ event, roles, user }) => {
  return roles.map((role, index) => {
    let url = dashboardUrlFromUserRole(role, event);
    return (
      <Fragment key={uuid()}>
        {index > 0 && <b className={Styles.pipeStyle}>&nbsp;&nbsp;|&nbsp;&nbsp;</b>}
        <Link
          to={url}
          data-test-id={`role-dashboard-link-${event.get('id')}-${role.role_specific_id}`}
          className={cx({
            'btn btn-secondary': role.name === 'TeamDraft',
            'btn-link': role.name !== 'TeamDraft',
          })}
        >
          {role.name === 'TeamDraft'
            ? `${role.role === 'viewer' ? 'View' : 'Finish'} Application`
            : role.role_specific_name}
        </Link>
        {role.evaluations_status?.total > 0 && role.role_specific_name === 'Evaluator' && (
          <Badge
            className={cx(
              'd-flex',
              'align-items-center',
              'justify-content-center',
              Styles.badgeInactive,
              Styles.eventProgress,
            )}
          >
            {role.evaluations_status?.pending === 0
              ? 'Done'
              : `${role.evaluations_status?.completed} / ${role.evaluations_status?.total} complete`}
          </Badge>
        )}
      </Fragment>
    );
  });
};

export default DashboardRolesLink;
