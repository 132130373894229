import { useCallback } from 'react';

/**
 * Debounces a function that returns a promise.
 * The debounced function will delay invoking the inner function until a certain amount of time has passed without any further invocations.
 * If the debounced function is invoked again before the delay has passed, the timer will be reset.
 * The debounced function returns a promise that resolves with the result of the inner function.
 *
 * @param {Function} inner - The inner function to be debounced.
 * @param {number} [ms=0] - The delay in milliseconds before invoking the inner function.
 * @returns {Function} - The debounced function.
 */
export default function debounce<T, V>(
  inner: (...args: V[]) => Promise<T>,
  ms = 0,
): (...args: any) => Promise<T> {
  let timer: NodeJS.Timeout | null = null;
  let resolves: ((value: Promise<T>) => void)[] = [];

  return function (...args) {
    // Run the function after a certain amount of time
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      let result = inner(...args);
      resolves.forEach((r) => r(result));
      resolves = [];
    }, ms);

    return new Promise<T>((r) => resolves.push(r));
  };
}

export function useDebouncedCallback<T, V>(
  inner: (...args: V[]) => Promise<T>,
  dependencies: any[] = [],
  ms = 0,
) {
  return useCallback(debounce(inner, ms), dependencies);
}
