import { del, get, post } from './config';
import { apiHeaders, apiURL } from '../services/config';

const isApiError = (error) => error && error.name === 'ApiError';

export const loadMultiple = (eventId) =>
  get(`/events/${eventId}/judge_invitations`).then((payload) => payload.judge_invitations);

export const createMultiple = (invitations, eventId) =>
  post(`/events/${eventId}/judge_invitations/create_bulk`, {
    judge_invitations: invitations,
  }).then(
    (payload) => payload.judge_invitations,
    (error) => {
      if (isApiError(error)) {
        if (error.response && error.response.error) {
          throw new Error(error.response.error);
        } else if (error.response && error.response.body && error.response.body.error) {
          throw new Error(error.response.body.error);
        }
      }
      throw error;
    },
  );

export const send = (id, eventId) => post(`/events/${eventId}/judge_invitations/${id}/invite`);

export const deleteInvitation = (id, eventId) => del(`/events/${eventId}/judge_invitations/${id}`);

export const declineInvitation = (eventId, id, notificationId) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ notification_id: notificationId }),
  endpoint: apiURL(`/events/${eventId}/judge_invitations/${id}/decline`),
});

export const declineInvitationWithToken = (eventId, id, notificationId, token) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ notification_id: notificationId, token }),
  endpoint: apiURL(`/events/${eventId}/judge_invitations/${id}/decline_with_token`),
});

export const validateInvitation = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/judge_invitations/validate_invitation?id=${id}`),
});

export const addInvitationEmailToProfile = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/judge_invitations/add_invitation_email_to_profile?id=${id}`),
});
