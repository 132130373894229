import { List, fromJS } from 'immutable';
import { combineActions, handleActions } from 'redux-actions';

import { actions } from '../actions/recommendations';
import { createSelector } from 'reselect';

const initialState = fromJS({ data: {}, loading: false, byTeam: {} });

const priorityRankKeyPath = (id) => ['data', id, 'priority_rank'];

export default handleActions(
  {
    [combineActions(actions.CREATE, actions.LOAD_FOR_TEAM)]: (state, action) =>
      state.set('loading', true),
    [actions.CREATE_RESULT]: {
      next(state, action) {
        const {
          teamId,
          result,
          entities: { recommendations },
        } = action.payload;
        const idsByTeam = state.getIn(['byTeam', teamId], List());
        return state
          .set('loading', false)
          .mergeIn(['data'], fromJS(recommendations))
          .setIn(['byTeam', teamId], idsByTeam.push(result));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [actions.LOAD_FOR_TEAM_RESULT]: {
      next(state, action) {
        const {
          teamId,
          result,
          entities: { recommendations },
        } = action.payload;
        return state
          .set('loading', false)
          .mergeIn(['data'], fromJS(recommendations))
          .setIn(['byTeam', teamId], List(result));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [combineActions(
      actions.CREATE_COMMENT_RESULT,
      actions.EDIT_COMMENT_RESULT,
      actions.EDIT_RECOMMENDATION_TEXT_RESULT,
      actions.VOTE_RESULT,
      actions.DELETE_VOTE_RESULT,
    )]: {
      next(state, action) {
        const {
          entities: { recommendations },
        } = action.payload;
        return state.mergeIn(['data'], fromJS(recommendations));
      },
    },
    [actions.CHANGE_PRIORITY]: (state, action) => {
      const { teamId, recommendationId, newPriorityRank, direction } = action.payload;
      const currentKeyPath = priorityRankKeyPath(recommendationId);
      const currentPriority = state.getIn(currentKeyPath);

      const ids = getActiveTeamRecommendationIdsToUpdateOnChangePriority(
        state,
        teamId,
        recommendationId,
        newPriorityRank,
        currentPriority,
        direction,
      );

      return state.withMutations((s1) => {
        ids.forEach((id) => {
          const keyPath = priorityRankKeyPath(id);
          const rank = s1.getIn(keyPath);
          const delta = direction === 'up' ? 1 : -1;
          s1.setIn(keyPath, rank + delta);
        });
        s1.setIn(currentKeyPath, newPriorityRank);
      });
    },
    [combineActions(actions.UPDATE_RESULT, actions.CHANGE_PRIORITY_RESULT)]: {
      next(state, action) {
        const {
          entities: { recommendations },
        } = action.payload;
        return state.set('loading', false).mergeIn(['data'], fromJS(recommendations));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
  },
  initialState,
);

const emptyList = List();

const shouldChangePriority = (currentPriority, newPriority, direction) => (priority) =>
  direction === 'up'
    ? newPriority <= priority && priority < currentPriority
    : newPriority >= priority && priority > currentPriority;

const getActiveTeamRecommendationIdsToUpdateOnChangePriority = (
  state,
  teamId,
  recommendationId,
  newPriority,
  currentPriority,
  direction,
) => {
  const shouldUpdate = shouldChangePriority(currentPriority, newPriority, direction);
  return state
    .getIn(['byTeam', teamId])
    .filter(
      (id) =>
        state.getIn(['data', id, 'status']) === 'active' &&
        recommendationId !== id &&
        shouldUpdate(state.getIn(priorityRankKeyPath(id))),
    );
};

export const getByTeam = createSelector(
  (state) => state.get('data'),
  (state, teamId) => state.getIn(['byTeam', teamId], emptyList),
  (lookup, ids) => {
    return ids.map((id) => lookup.get(id));
  },
);

export const isLoading = (state) => state.get('loading');
