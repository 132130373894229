import { Button, Form } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Errors from 'components/Errors';
import Input from 'components/Form/Input';
import { required } from 'components/FormFields/validations';

import { LoginFormType } from './types';

type LoginFormOtpType = {
  isLoading: boolean;
  error: string | null;
  onNext(data: LoginFormType): void;
  registered: string | undefined;
  isTeamMemberInvitation: boolean;
};

const LoginFormOtp = ({ isLoading, error, onNext }: LoginFormOtpType) => {
  const { t } = useTranslation();
  const { handleSubmit, setFocus, reset } = useFormContext<LoginFormType>();

  useEffect(() => {
    setFocus('otp');
    setTimeout(() => reset(undefined, { keepValues: true, keepIsSubmitted: false }), 0);
  }, []);

  const onSubmit = (data: LoginFormType) => {
    onNext(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Errors
        errors={error ? [error] : null}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      <Form.Label htmlFor="otp">{t('auth.login.otp')}</Form.Label>
      <Input id="otp" name="otp" rules={{ validate: { required } }} />
      <div className="d-grid gap-2 mt-4">
        <Button id="login-submit" type="submit" variant="success" disabled={isLoading}>
          {isLoading && <i className="fa-duotone fa-spinner fa-spin" />}
          {!isLoading && t('auth.login.title')}
        </Button>
      </div>
    </Form>
  );
};

export default LoginFormOtp;
