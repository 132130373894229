import Loading from './Loading';

import classNames from 'classnames';
import styles from './Box.module.scss';

let BoxContainer = ({ children }) => (
  <div className={classNames('clearfix', styles.container)}>{children}</div>
);

let Box = ({ loading, children }) => (
  <div className={styles.box}>
    {loading && <Loading />}
    {!loading && children}
  </div>
);

Box.displayName = 'Box';
BoxContainer.displayName = 'BoxContainer';

export { BoxContainer, Box };
