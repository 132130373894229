import * as api from 'data/services/apiKeys';
import { RSAA } from 'redux-api-middleware';

export enum Actions {
  LOAD_PENDING = 'apiKeys/LOAD_PENDING',
  LOAD_FULFILLED = 'apiKeys/LOAD_FULFILLED',
  LOAD_REJECTED = 'apiKeys/LOAD_REJECTED',
  DESTROY_PENDING = 'apiKeys/DESTROY_PENDING',
  DESTROY_FULFILLED = 'apiKeys/DESTROY_FULFILLED',
  DESTROY_REJECTED = 'apiKeys/DESTROY_REJECTED',
  ADD_PENDING = 'apiKeys/ADD_PENDING',
  ADD_FULFILLED = 'apiKeys/ADD_FULFILLED',
  ADD_REJECTED = 'apiKeys/ADD_REJECTED',
}

export type ApiKey = {
  id: string;
  name: string;
  key: string;
  created_at: string;
  updated_at: string;
  expires_at: string;
};

export type ApiKeyResponseItem = {
  organization_id: string;
  organization_name: string;
  api_keys: ApiKey[];
};

export type ApiKeysResponse = ApiKeyResponseItem[];

export type Action =
  | {
      type: Actions.LOAD_PENDING;
    }
  | {
      type: Actions.LOAD_FULFILLED;
      payload: ApiKeysResponse;
    }
  | {
      type: Actions.LOAD_REJECTED;
    }
  | {
      type: Actions.DESTROY_PENDING;
    }
  | {
      type: Actions.DESTROY_FULFILLED;
    }
  | {
      type: Actions.DESTROY_REJECTED;
    }
  | {
      type: Actions.ADD_PENDING;
    }
  | {
      type: Actions.ADD_FULFILLED;
      payload: ApiKey;
    }
  | {
      type: Actions.ADD_REJECTED;
    };

export const loadApiKeys = () => {
  return {
    [RSAA]: {
      ...api.load(),
      types: [Actions.LOAD_PENDING, Actions.LOAD_FULFILLED, Actions.LOAD_REJECTED],
    },
  };
};

export const revokeApiKey = ({ id, organizationId }: { id: string; organizationId: string }) => {
  return {
    [RSAA]: {
      ...api.destroy({ id, organizationId }),
      types: [Actions.DESTROY_PENDING, Actions.DESTROY_FULFILLED, Actions.DESTROY_REJECTED],
    },
  };
};

export const addApiKey = ({ name, organizationId }: { name: string; organizationId: string }) => ({
  [RSAA]: {
    ...api.add({ name, organizationId }),
    types: [Actions.ADD_PENDING, Actions.ADD_FULFILLED, Actions.ADD_REJECTED],
  },
});
