import { createSelector } from 'reselect';

import { Action, Actions, ApiKeysResponse } from 'data/actions/apiKeys';

export type State = {
  apiKeys: ApiKeysResponse;
  loading: boolean;
};

const initialState: State = {
  apiKeys: [],
  loading: false,
};

const apiKeys = (state = initialState, action: Action) => {
  switch (action.type) {
    case Actions.LOAD_PENDING:
      return { ...state, loading: true };
    case Actions.LOAD_FULFILLED:
      return { ...state, apiKeys: action.payload, loading: false };
    case Actions.LOAD_REJECTED:
      return { ...state, loading: false };
  }
  return state;
};

export const getApiKeysIsLoading = createSelector(
  (state: any) => state.get('apiKeys'),
  (state: State) => {
    return state.loading;
  },
);

export const getApiKeys = createSelector(
  (state: any) => state.get('apiKeys'),
  (state: State) => {
    return state.apiKeys;
  },
);

export default apiKeys;
