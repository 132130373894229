import { Col, Row } from '@valid-eval/shared-react-components';
import {
  assignToTeam,
  loadTeamAssignments,
  loadTeamSuggestions,
  removeFromTeam,
} from 'data/actions/teamAssignments';
import {
  getJudgeSuggestionsForCurrentTeam,
  getJudgesAssignedToCurrentTeam,
  getSelectedPhaseIdForCurrentEvent,
  getTeamCategories,
  isLoadingTeamAssignments,
} from 'data/reducers';

import AssignedRolesTable from 'components/AssignedRolesTable';
import AssignmentRolesSelect from 'components/AssignmentRolesSelect';
import { Component } from 'react';
import Loading from 'components/Loading';
import SuggestionsTable from '../events/components/SuggestionsTable';
import UserCategories from 'components/UserCategories';
import { connect } from 'react-redux';
import { isNavigator, isTeamLead } from 'utils';
import { loadForPhase } from 'data/actions/judgeProfiles';
import { loadUnassignedJudges } from 'data/actions/teams';
import { error as notifyError } from 'data/actions/notifications';
import withRouter from 'routes/withRouter';

const thWidths = ['10%', '10%', '30%', '30%', '10%'];

class EditAssignments extends Component {
  state = {
    judgeList: [],
  };

  componentDidMount() {
    const {
      loadTeamAssignments,
      loadTeamSuggestions,
      params: { team_id },
      selectedPhaseId,
    } = this.props;
    this.loadJudges();
    loadTeamAssignments(selectedPhaseId, team_id);
    if (isNavigator() || isTeamLead()) return;

    loadTeamSuggestions(selectedPhaseId, team_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId === this.props.selectedPhaseId) return;

    this.loadJudges();
    this.props.loadTeamAssignments(nextProps.selectedPhaseId, this.props.params.team_id);
    if (isNavigator() || isTeamLead()) return;

    this.props.loadTeamSuggestions(nextProps.selectedPhaseId, this.props.params.team_id);
  }

  loadJudges = async () => {
    if (isNavigator() || isTeamLead()) return;

    const {
      selectedPhaseId,
      params: { team_id },
    } = this.props;

    const result = await this.props.loadUnassignedJudges(selectedPhaseId, team_id);
    this.setState({ judgeList: result.payload.judge_profiles });
  };

  removeJudge = (id) => {
    this.setState({ judgeList: this.state.judgeList.filter((j) => j.id !== id) });
  };

  notifyAssignmentError = (message) => (result) => {
    if (result.error) {
      message
        ? this.props.notifyError(message)
        : this.props.notifyError(result.payload.response.error);
    }

    return result.error;
  };

  deleteAssignment = (roleId) => {
    const {
      removeFromTeam,
      selectedPhaseId,
      params: { team_id },
      loadTeamSuggestions,
    } = this.props;
    removeFromTeam(selectedPhaseId, team_id, roleId)
      .then(this.notifyAssignmentError())
      .then((error) => {
        !error && loadTeamSuggestions(selectedPhaseId, team_id);
        this.loadJudges();
      });
  };

  createAssignment = (roleId) => {
    const {
      assignToTeam,
      selectedPhaseId,
      loadTeamAssignments,
      params: { team_id },
    } = this.props;

    assignToTeam(selectedPhaseId, team_id, roleId)
      .then(this.notifyAssignmentError('Can not assign judge'))
      .then((error) => {
        !error && loadTeamAssignments(selectedPhaseId, team_id);
        this.removeJudge(roleId);
      });
  };

  handleAssignmentBySelect = (val) => {
    if (val.value) this.createAssignment(val.value);
  };

  render() {
    const {
      assigned,
      loading,
      teamCategories,
      suggestions,
      params: { team_id },
    } = this.props;
    if (loading) {
      return <Loading />;
    }

    return (
      <Row>
        <Col md={12}>
          <Row>
            <Col md={3}>
              <UserCategories groupedCategories={teamCategories} />
            </Col>
            <Col md={9}>
              <AssignedRolesTable
                list={assigned}
                roleType="Judge"
                onRemoveAssignment={this.deleteAssignment}
                thWidths={thWidths}
              />
              {!isNavigator() && !isTeamLead() && (
                <SuggestionsTable
                  list={suggestions}
                  mainRoleId={team_id}
                  roleType="Judge"
                  onAssign={this.createAssignment}
                  thWidths={thWidths}
                />
              )}
            </Col>
          </Row>
          {!isNavigator() && !isTeamLead() && (
            <AssignmentRolesSelect
              placeholder="Match to specific judge"
              handleAssignment={this.handleAssignmentBySelect}
              list={this.state.judgeList}
            />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const assigned = getJudgesAssignedToCurrentTeam(state, ownProps);
  const loading = isLoadingTeamAssignments(state);
  const teamCategories = getTeamCategories(state, ownProps);
  const suggestions = getJudgeSuggestionsForCurrentTeam(state, ownProps);
  const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);

  return {
    loading,
    assigned,
    suggestions,
    teamCategories,
    selectedPhaseId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadTeamAssignments,
    loadTeamSuggestions,
    loadForPhase,
    removeFromTeam,
    assignToTeam,
    notifyError,
    loadUnassignedJudges,
  })(EditAssignments),
);
