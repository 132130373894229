import { Dropdown } from '@valid-eval/shared-react-components';
import { useState } from 'react';

import { getEnvVar } from 'config';

import { useLocation } from 'react-router-dom';
import LinkedInButton from './LinkedInButton';

const OauthButton = ({ onLinkedInLogin, signUp }: any) => {
  const [, setShow] = useState(false);

  const location = useLocation();
  const handleToggle = (isOpened: boolean) => {
    setShow(isOpened);
  };

  const showDropdown =
    getEnvVar('REACT_APP_GOV_ENV') === '0' || getEnvVar('REACT_APP_OAUTH_KEYCLOAK');

  return showDropdown ? (
    <Dropdown onToggle={handleToggle}>
      <Dropdown.Toggle variant="success" id={`sso-buttons`} className="w-100">
        {signUp ? 'Sign up' : 'Log in'} with SSO
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100">
        {getEnvVar('REACT_APP_GOV_ENV') === '0' && onLinkedInLogin && (
          <LinkedInButton text="Login with Linkedin" login={onLinkedInLogin} />
        )}
        {getEnvVar('REACT_APP_OAUTH_KEYCLOAK') && (
          <div className="d-grid">
            <a
              href={`/api/v1/auth/keycloak/start?url=${encodeURIComponent(
                location.pathname + location.search,
              )}`}
              className="btn btn-linkedin text-left"
            >
              <span className="fa-stack fa-2x me-2" style={{ fontSize: '0.7rem' }}>
                <i className="fa-solid fa-circle fa-stack-2x"></i>
                <i className="fa-sharp fa-solid fa-key fa-stack-1x fa-inverse"></i>
              </span>
              <span>{signUp ? 'Sign Up' : 'Login'} with CAC</span>
            </a>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

export default OauthButton;
