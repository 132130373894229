import cx from 'classnames';
import PropTypes from 'prop-types';
import Styles from './CommunicationsTemplate.module.scss';

const CommunicationsTemplateFormRichTextFooter = ({ template }) => {
  const { template_variables: variables = {}, team_template_variables: teamVariables = {} } =
    template || {};

  return ({ editorRef }) => {
    const variablesValues = Object.keys(variables).sort();
    const teamVariablesValues = Object.keys(teamVariables).sort();

    const handleVariableSelected = (event) => {
      editorRef.current.selection.insertHTML(`${event.target.value}`);
    };

    return (
      <>
        <div
          className={cx(
            Styles.variablesWrapper,
            'd-flex p-2 align-items-center justify-content-between flex-wrap',
          )}
        >
          <select
            className={cx(
              Styles.customSelect,
              Styles.arrowStyle,
              Styles.inputSelectArea,
              Styles.marginBottom,
              'form-control form-control-sm',
            )}
            value=""
            onChange={handleVariableSelected}
          >
            <option value="">Add variable</option>
            {variablesValues.map((variable) => (
              <option value={variable} key={`variable-${variable}`}>
                {variable} - {variables[variable].description}
              </option>
            ))}
          </select>
          {teamVariablesValues.length > 0 && (
            <select
              className={cx(
                Styles.customSelect,
                Styles.arrowStyle,
                Styles.inputSelectArea,
                'form-control form-control-sm',
              )}
              value=""
              onChange={handleVariableSelected}
            >
              <option value="">Add team-specific variable</option>
              {teamVariablesValues.map((variable) => (
                <option value={variable} key={`team-variable-${variable}`}>
                  {variable} - {teamVariables[variable].description}
                </option>
              ))}
            </select>
          )}
        </div>
      </>
    );
  };
};

CommunicationsTemplateFormRichTextFooter.propTypes = {
  template: PropTypes.object,
};

export default CommunicationsTemplateFormRichTextFooter;
