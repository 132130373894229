import { Modal, Tab, TabsGroup } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFormValues, isInvalid } from 'redux-form/immutable';

import { save as saveInvitationTemplate } from 'data/actions/invitationTemplates';
import { confirm } from 'data/actions/judgeInvitations';
import { getInvitationTemplate } from 'data/reducers';
import { parseContactEmail } from 'utils';

import JudgeInvitationsAdd from './JudgeInvitationsAdd';
import Styles from './JudgeInvitationsModal.module.scss';
import JudgeInvitationsTemplate from './JudgeInvitationsTemplate';

function JudgeInvitationsModal({
  confirm,
  formValues,
  formIsInvalid,
  invitationTemplate,
  listFormValues,
  listFormIsInvalid,
  onClose,
  saveInvitationTemplate,
  show,
}) {
  const params = useParams();
  const invitations = formValues?.invitations?.filter((i) => i.email);
  const invitationsList = listFormValues?.list;
  const [currentTab, setCurrentTab] = useState('add-judges');

  useEffect(() => {
    if (show) setCurrentTab('add-judges');
  }, [show]);

  function handleNext() {
    setCurrentTab('edit-template');
  }

  function handleSubmit(values) {
    const eventId = params.event_id;
    if (invitations?.length && !formIsInvalid) {
      confirm(invitations, eventId);
    } else if (
      (invitations?.length && formIsInvalid) ||
      (invitationsList?.length && listFormIsInvalid)
    ) {
      return setCurrentTab('add-judges');
    } else if (invitationsList?.length && !listFormIsInvalid) {
      confirm(invitationsList.map(parseContactEmail), eventId);
    }
    saveInvitationTemplate(eventId, values?.get('invitation_template').toJS());
    onClose();
  }

  return (
    <Modal className={Styles.Modal} show={show} onHide={onClose} size="lg" enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>Invite Judges</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TabsGroup activeKey={currentTab} onSelect={setCurrentTab}>
          <Tab eventKey="add-judges" title="Add Judges">
            <JudgeInvitationsAdd onCancel={onClose} onNext={handleNext} />
          </Tab>
          <Tab eventKey="edit-template" title="Edit Template">
            {currentTab === 'edit-template' && (
              <JudgeInvitationsTemplate
                onSubmit={handleSubmit}
                onCancel={onClose}
                invitations={invitations}
                invitationsList={invitationsList}
                initialValues={invitationTemplate}
              />
            )}
          </Tab>
        </TabsGroup>
      </Modal.Body>
    </Modal>
  );
}

export default connect(
  (state) => ({
    invitationTemplate: getInvitationTemplate(state),
    formValues: getFormValues('JudgeInvitationsAddManually')(state)?.toJS() || {},
    formIsInvalid: isInvalid('JudgeInvitationsAddManually')(state),
    listFormValues: getFormValues('JudgeInvitationsAddList')(state)?.toJS() || {},
    listFormIsInvalid: isInvalid('JudgeInvitationsAddList')(state),
  }),
  { saveInvitationTemplate, confirm },
)(JudgeInvitationsModal);
