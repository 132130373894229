import * as scoreActions from '../actions/scores';
import * as schemas from '../schema';
import * as phasesApi from '../services/phases';
import * as teamsAPI from '../services/teams';

import { getJSON, RSAA } from 'redux-api-middleware';

import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import { getSelfScoreForPhase } from '../reducers';

const {
  actions: { LOAD_RESULT: SCORE_LOAD_RESULT },
} = scoreActions;

export const actions = {
  LOAD: 'teams/LOAD',
  LOAD_RESULT: 'teams/LOAD_RESULT',
  LOAD_MULTIPLE: 'teams/LOAD_MULTIPLE',
  LOAD_FAIL: 'teams/LOAD_FAIL',
  SIGNUP: 'teams/SIGNUP',
  SIGNUP_RESULT: 'teams/SIGNUP_RESULT',
  SIGNUP_REJECTED: 'teams/SIGNUP_REJECTED',
  UPDATE: 'teams/UPDATE',
  UPDATE_RESULT: 'teams/UPDATE_RESULT',
  LOAD_EVENT_TEAMS: 'teams/LOAD_EVENT_TEAMS',
  LOAD_EVENT_TEAMS_RESULT: 'teams/LOAD_EVENT_TEAMS_RESULT',
  LOAD_FOR_NAVIGATION: 'teams/LOAD_FOR_NAVIGATION',
  FETCH_SCORE_FOR_PHASE_REQUEST: 'teams/FETCH_SCORE_FOR_PHASE_REQUEST',
  LOAD_PHASES: 'teams/LOAD_PHASES',
  LOAD_PHASES_RESULT: 'teams/LOAD_PHASES_RESULT',
  LOAD_PHASES_FOR_SELF_EVALUATION: 'teams/LOAD_PHASES_FOR_SELF_EVALUATION',
  LOAD_PHASES_FOR_SELF_EVALUATION_RESULT: 'teams/LOAD_PHASES_FOR_SELF_EVALUATION_RESULT',
  LOAD_FOR_PHASE_REQUEST: 'teams/LOAD_FOR_PHASE_REQUEST',
  LOAD_FOR_PHASE_RESULT: 'teams/LOAD_FOR_PHASE_RESULT',
  LOAD_UNASSIGNED_JUDGES_REQUEST: 'teams/LOAD_UNASSIGNED_JUDGES_REQUEST',
  LOAD_UNASSIGNED_JUDGES_RESULT: 'teams/LOAD_UNASSIGNED_JUDGES_RESULT',
  LOAD_MEMBERS_PENDING: 'teams/LOAD_MEMBERS_PENDING',
  LOAD_MEMBERS_FULFILLED: 'teams/LOAD_MEMBERS_FULFILLED',
  LOAD_MEMBERS_REJECTED: 'teams/LOAD_MEMBERS_REJECTED',
  INVITE_MEMBERS_PENDING: 'teams/INVITE_MEMBERS_PENDING',
  INVITE_MEMBERS_FULFILLED: 'teams/INVITE_MEMBERS_FULFILLED',
  INVITE_MEMBERS_REJECTED: 'teams/INVITE_MEMBERS_REJECTED',
  RESEND_INVITATION_PENDING: 'teams/RESEND_INVITATION_PENDING',
  RESEND_INVITATION_FULFILLED: 'teams/RESEND_INVITATION_FULFILLED',
  RESEND_INVITATION_REJECTED: 'teams/RESEND_INVITATION_REJECTED',
  DELETE_INVITATION_PENDING: 'teams/DELETE_INVITATION_PENDING',
  DELETE_INVITATION_FULFILLED: 'teams/DELETE_INVITATION_FULFILLED',
  DELETE_INVITATION_REJECTED: 'teams/DELETE_INVITATION_REJECTED',
  UPDATE_MEMBER_ROLE_PENDING: 'teams/UPDATE_MEMBER_ROLE_PENDING',
  UPDATE_MEMBER_ROLE_FULFILLED: 'teams/UPDATE_MEMBER_ROLE_FULFILLED',
  UPDATE_MEMBER_ROLE_REJECTED: 'teams/UPDATE_MEMBER_ROLE_REJECTED',
  DECLINE_INVITATION_PENDING: 'teams/DECLINE_INVITATION_PENDING',
  DECLINE_INVITATION_FULFILLED: 'teams/DECLINE_INVITATION_FULFILLED',
  DECLINE_INVITATION_REJECTED: 'teams/DECLINE_INVITATION_REJECTED',
  ACCEPT_INVITATION_PENDING: 'teams/ACCEPT_INVITATION_PENDING',
  ACCEPT_INVITATION_FULFILLED: 'teams/ACCEPT_INVITATION_FULFILLED',
  ACCEPT_INVITATION_REJECTED: 'teams/ACCEPT_INVITATION_REJECTED',
  LOAD_INVITATION_PENDING: 'teams/LOAD_INVITATION_PENDING',
  LOAD_INVITATION_FULFILLED: 'teams/LOAD_INVITATION_FULFILLED',
  LOAD_INVITATION_REJECTED: 'teams/LOAD_INVITATION_REJECTED',
  LOAD_DRAFT_IN_EVENT_PENDING: 'teams/LOAD_DRAFT_IN_EVENT_PENDING',
  LOAD_DRAFT_IN_EVENT_FULFILLED: 'teams/LOAD_DRAFT_IN_EVENT_FULFILLED',
  LOAD_DRAFT_IN_EVENT_REJECTED: 'teams/LOAD_DRAFT_IN_EVENT_REJECTED',
  UNLOAD_DRAFT_IN_EVENT: 'teams/UNLOAD_DRAFT_IN_EVENT',
};

export const load = (id) => {
  return {
    [RSAA]: {
      ...teamsAPI.load(id),
      types: [
        actions.LOAD,
        {
          type: actions.LOAD_RESULT,
          payload: (_, __, res) =>
            getJSON(res).then((payload) => {
              return normalize(payload.team, schemas.teamWithCategoryWithParent);
            }),
        },
        actions.LOAD_RESULT,
      ],
    },
  };
};

export function loadMultiple(ids) {
  return { type: actions.LOAD_MULTIPLE, payload: { ids } };
}

export function loadFail(error) {
  return { type: actions.LOAD_FAIL, payload: { error } };
}

export const loadEventTeams = (eventId) => {
  return {
    [RSAA]: {
      ...teamsAPI.loadForEvent(eventId),
      types: [
        actions.LOAD_EVENT_TEAMS,
        {
          type: actions.LOAD_EVENT_TEAMS_RESULT,
          payload: (_, __, res) =>
            getJSON(res).then((payload) => {
              return { eventId, ...normalize(payload.teams, schemas.teamsList) };
            }),
        },
        actions.LOAD_EVENT_TEAMS_RESULT,
      ],
    },
  };
};

export function loadForNavigation(eventId, teamId) {
  return { type: actions.LOAD_FOR_NAVIGATION, payload: { eventId, teamId } };
}

export const fetchScoreForPhase = (teamId, phaseId, useCached = false) => ({
  [RSAA]: {
    ...teamsAPI.fetchSelfScore(teamId, phaseId),
    bailout: (state) => useCached && getSelfScoreForPhase(state, teamId, phaseId),
    types: [
      actions.FETCH_SCORE_FOR_PHASE_REQUEST,
      {
        type: SCORE_LOAD_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then((payload) => normalize(payload.self_score, schemas.simpleScore)),
      },
      SCORE_LOAD_RESULT,
    ],
  },
});

export const loadMultipleResult = createAction('teams/LOAD_MULTIPLE_RESULT', (teamList, schema) => {
  const {
    entities: { teams },
  } = normalize(teamList, schema);
  return teams;
});

export const update = (eventId, id, values, isDraft = false) => {
  const tag_ids = values.get('categories')?.valueSeq().flatten().toJS();

  const details = values.get('details') ? values.get('details').toJS() : {};
  const rest = values.delete('details').toJS();

  const team_payload = {
    name: values.get('name'),
    tag_ids,
    details,
    ...rest,
  };

  return {
    [RSAA]: {
      ...teamsAPI.update(eventId, id, team_payload, isDraft),
      types: [
        actions.UPDATE,
        {
          type: actions.UPDATE_RESULT,
          payload: async (_, __, res) => {
            const payload = await getJSON(res);
            return {
              ...normalize(payload.team, schemas.teamWithCategoryWithParent),
              team: payload.team,
              isDraft,
            };
          },
        },
        actions.UPDATE_RESULT,
      ],
    },
  };
};

const parsePhasesListForTeam = (teamId) => (_, __, res) =>
  getJSON(res).then((payload) => {
    return { teamId, ...normalize(payload.phases, [schemas.phase]) };
  });

export const loadPhasesForTeam = (teamId) => {
  return {
    [RSAA]: {
      ...teamsAPI.loadPhases(teamId),
      types: [
        actions.LOAD_PHASES,
        { type: actions.LOAD_PHASES_RESULT, payload: parsePhasesListForTeam(teamId) },
        { type: actions.LOAD_PHASES_RESULT, payload: { teamId } },
      ],
    },
  };
};

export const loadPhasesForTeamSelfEvaluation = (teamId) => {
  return {
    [RSAA]: {
      ...teamsAPI.loadPhasesForSelfEvaluation(teamId),
      types: [
        actions.LOAD_PHASES_FOR_SELF_EVALUATION,
        {
          type: actions.LOAD_PHASES_FOR_SELF_EVALUATION_RESULT,
          payload: parsePhasesListForTeam(teamId),
        },
        { type: actions.LOAD_PHASES_FOR_SELF_EVALUATION_RESULT, payload: { teamId } },
      ],
    },
  };
};

export const loadForPhase = (phaseId) => {
  return {
    [RSAA]: {
      ...phasesApi.teams(phaseId),
      types: [
        actions.LOAD_FOR_PHASE_REQUEST,
        actions.LOAD_FOR_PHASE_RESULT,
        actions.LOAD_FOR_PHASE_RESULT,
      ],
    },
  };
};

export const loadUnassignedJudges = (phaseId, teamId) => {
  return {
    [RSAA]: {
      ...teamsAPI.unassignedJudges(phaseId, teamId),
      types: [
        actions.LOAD_UNASSIGNED_JUDGES_REQUEST,
        actions.LOAD_UNASSIGNED_JUDGES_RESULT,
        actions.LOAD_UNASSIGNED_JUDGES_RESULT,
      ],
    },
  };
};

const _parseMembersForTeam = (teamId) => async (_, __, response) => {
  const { members } = await getJSON(response);
  return { teamId, ...normalize(members, [schemas.member]) };
};

export const loadMembers = (teamId) => {
  return {
    [RSAA]: {
      ...teamsAPI.loadMembers(teamId),
      types: [
        actions.LOAD_MEMBERS_PENDING,
        {
          type: actions.LOAD_MEMBERS_FULFILLED,
          payload: _parseMembersForTeam(teamId),
        },
        actions.LOAD_MEMBERS_REJECTED,
      ],
    },
  };
};

export const inviteMembers = (teamId, invitations) => {
  return {
    [RSAA]: {
      ...teamsAPI.inviteMembers(teamId, invitations),
      types: [
        actions.INVITE_MEMBERS_PENDING,
        actions.INVITE_MEMBERS_FULFILLED,
        actions.INVITE_MEMBERS_REJECTED,
      ],
    },
  };
};

export const deleteInvitation = (teamId, invitationId, userId) => {
  return {
    [RSAA]: {
      ...teamsAPI.deleteInvitation(teamId, invitationId, userId),
      types: [
        actions.DELETE_INVITATION_PENDING,
        actions.DELETE_INVITATION_FULFILLED,
        actions.DELETE_INVITATION_REJECTED,
      ],
    },
  };
};

export const resendInvitation = (teamId, invitationId) => {
  return {
    [RSAA]: {
      ...teamsAPI.resendInvitation(teamId, invitationId),
      types: [
        actions.RESEND_INVITATION_PENDING,
        actions.RESEND_INVITATION_FULFILLED,
        actions.RESEND_INVITATION_REJECTED,
      ],
    },
  };
};

export const updateMemberRole = (teamId, invitationId, userId, role) => {
  return {
    [RSAA]: {
      ...teamsAPI.updateMemberRole(teamId, invitationId, userId, role),
      types: [
        actions.UPDATE_MEMBER_ROLE_PENDING,
        actions.UPDATE_MEMBER_ROLE_FULFILLED,
        actions.UPDATE_MEMBER_ROLE_REJECTED,
      ],
    },
  };
};

export const declineInvitationWithToken = (teamId, invitationId, token) => ({
  [RSAA]: {
    ...teamsAPI.declineInvitationWithToken(teamId, invitationId, token),
    types: [
      actions.DECLINE_INVITATION_PENDING,
      actions.DECLINE_INVITATION_FULFILLED,
      actions.DECLINE_INVITATION_REJECTED,
    ],
  },
});

export const acceptInvitation = (teamId, invitationId) => ({
  [RSAA]: {
    ...teamsAPI.acceptInvitation(teamId, invitationId),
    types: [
      actions.ACCEPT_INVITATION_PENDING,
      actions.ACCEPT_INVITATION_FULFILLED,
      actions.ACCEPT_INVITATION_REJECTED,
    ],
  },
});

export const loadInvitation = (teamId, invitationId) => ({
  [RSAA]: {
    ...teamsAPI.loadInvitation(teamId, invitationId),
    types: [
      actions.LOAD_INVITATION_PENDING,
      actions.LOAD_INVITATION_FULFILLED,
      actions.LOAD_INVITATION_REJECTED,
    ],
  },
});
