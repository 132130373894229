import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { useCallback } from 'react';

const RichTextEditorJodit = ({ config, name, onBlur, tabIndex, value, onChange, disabled }) => {
  useCallback(onChange, []);
  useCallback(onBlur, []);

  return (
    <JoditEditor
      config={{ ...config, disabled }}
      id={name}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
    />
  );
};

RichTextEditorJodit.propTypes = {
  value: PropTypes.string,
  tabIndex: PropTypes.number,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onBeforeEnter: PropTypes.func,
  onBlur: PropTypes.func,
  dirty: PropTypes.bool,
  touched: PropTypes.bool,
};

export default RichTextEditorJodit;
