import { Form, Select } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { PureComponent } from 'react';

import Styles from './AssignmentRolesSelect.module.scss';

const CustomSelect = ({ active = [], inactive = [], recused = [], placeholder, ...props }) => {
  return (
    <Select
      {...props}
      options={[
        {
          label: 'Active',
          options: active.map((o) => ({ value: o.id, label: o.name })),
        },
        {
          label: 'Inactive',
          options: inactive.map((o) => ({ value: o.id, label: o.name })),
        },
        {
          label: 'Recused',
          options: recused.map((o) => ({ value: o.id, label: o.name })),
        },
      ]}
      className={cx('m-0 p-0 float-end', Styles.Select)}
      placeholder={placeholder}
      isClearable
    />
  );
};

class AssignmentRolesSelect extends PureComponent {
  render() {
    const { list, placeholder, handleAssignment } = this.props;
    const active = list && list.filter((item) => !!item.active && !item.recused);
    const recused = list && list.filter((item) => !!item.active && !!item.recused);
    const inactive = list && list.filter((item) => !item.active);

    return (
      <Form.Control
        inactive={inactive}
        active={active}
        recused={recused}
        placeholder={placeholder}
        as={CustomSelect}
        custom="true"
        onChange={handleAssignment}
      />
    );
  }
}

AssignmentRolesSelect.displayName = 'AssignmentRolesSelect';

export default AssignmentRolesSelect;
