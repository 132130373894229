import { Button, Form, FormGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import Errors from 'components/Errors';
import { Input, Select } from 'components/FormFields';
import OrphanDetailsList from 'components/OrphanDetailsList';
import { load as loadDisciplines } from 'data/actions/businessDisciplines';
import { load as loadFunctions } from 'data/actions/jobFunctions';
import { getBusinessDisciplines, getJobFunctions } from 'data/reducers';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getFormSubmitErrors,
  getFormSyncErrors,
  hasSubmitFailed,
  reduxForm,
} from 'redux-form/immutable';
import { renderDynamicFields } from '../dynamicFields';
import LoadingButton from '../LoadingButton';
import Styles from './ProfileForm.module.scss';
import { sanitizePipeSeparatedNumber } from 'components/FormFields/validations';

const ProfileForm = ({
  businessDisciplines,
  canActivate = false,
  disabled,
  dynamicFields,
  error,
  handleSubmit,
  hasSubmitFailed,
  jobFunctions,
  judgeActive,
  onCancel,
  onToggleActivate,
  orphanDetails,
  submitErrors,
  submitting,
  syncErrors,
  loadFunctions,
  loadDisciplines,
  phases,
  isOrganizerOrNavigator,
}) => {
  useEffect(() => {
    loadFunctions();
    loadDisciplines();
  }, []);

  return (
    <Form onSubmit={handleSubmit} className={Styles.form}>
      <Errors
        errors={error && [error]}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      {isOrganizerOrNavigator &&
        phases.map((phase) => {
          if (phase.matching_mode !== 'panel_matching') return null;

          return (
            <Input
              key={`panels[${phase.id}]`}
              id={`panels[${phase.id}]`}
              name={`panels[${phase.id}]`}
              className={Styles.inputForm}
              title={
                <>
                  <span className={Styles.panelLabel}>
                    Panel Assigment(s){' '}
                    <small className="text-muted">(Only visible for selection committee)</small>
                    <br />
                  </span>
                  <small>{phase.name}</small>
                </>
              }
              normalize={sanitizePipeSeparatedNumber}
              disabled={disabled}
            />
          );
        })}
      <Select
        id="primary_job_function_uuid"
        name="primary_job_function_uuid"
        title="Primary Job Function"
        options={jobFunctions}
        disabled={disabled}
      />
      <Select
        id="business_discipline_uuid"
        name="business_discipline_uuid"
        title="Business Discipline"
        options={businessDisciplines}
        disabled={disabled}
      />
      {renderDynamicFields(dynamicFields.data, (x) => x, disabled)}
      <OrphanDetailsList details={orphanDetails} />
      <FormGroup className={Styles.actions}>
        <div className="d-flex flex-wrap mt-3 mb-3">
          {onCancel && (
            <Button variant="default" className={'me-1'} onClick={onCancel}>
              Cancel
            </Button>
          )}
          {canActivate &&
            (judgeActive ||
              (!hasSubmitFailed &&
                Object.keys(submitErrors).length === 0 &&
                Object.keys(syncErrors).length === 0)) && (
              <LoadingButton
                className={cx('float-start', Styles.toggleActiveButton)}
                onClick={onToggleActivate}
                loading={submitting}
                variant={judgeActive ? 'danger' : 'primary'}
                type="button"
              >
                {judgeActive ? 'Deactivate' : 'Activate'}
              </LoadingButton>
            )}
          <LoadingButton
            className={onCancel ? 'float-end' : ''}
            loading={submitting}
            disabled={submitting || disabled}
            variant="success"
            type="submit"
            hasSubmitFailed={hasSubmitFailed}
            submitErrors={submitErrors}
            syncErrors={syncErrors}
          >
            Save Profile
          </LoadingButton>
        </div>
      </FormGroup>
    </Form>
  );
};

ProfileForm.displayName = 'ProfileForm';
export default reduxForm({ form: 'judge_profile', enableReinitialize: true })(
  connect(
    (state) => {
      let submitErrors = getFormSubmitErrors('judge_profile')(state) || {};
      let syncErrors = getFormSyncErrors('judge_profile')(state) || {};

      return {
        hasSubmitFailed: hasSubmitFailed('judge_profile')(state),
        submitErrors: submitErrors.toJS?.() || submitErrors,
        syncErrors: syncErrors.toJS?.() || syncErrors,
        businessDisciplines: getBusinessDisciplines(state),
        jobFunctions: getJobFunctions(state),
      };
    },
    { loadFunctions, loadDisciplines },
  )(ProfileForm),
);
