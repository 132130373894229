import { Col, Form, Row } from '@valid-eval/shared-react-components';

import Email from 'components/Form/Email';
import Input from 'components/Form/Input';

const GlobalSettingsReplyTo = () => {
  return (
    <Row>
      <Col md="6" className="mb-4">
        <Form.Label htmlFor="replyToEmail">
          <b>Reply-to Email</b>
        </Form.Label>
        <Email id="replyToEmail" name="replyToEmail" />
        <Form.Text className="text-muted">Notifications reply-to email address.</Form.Text>
      </Col>
      <Col md="6" className="mb-4">
        <Form.Label htmlFor="replyToEmail">
          <b>Reply-to Name</b>
        </Form.Label>
        <Input id="replyToName" name="replyToName" />
        <Form.Text className="text-muted">Notifications reply-to name.</Form.Text>
      </Col>
    </Row>
  );
};

export default GlobalSettingsReplyTo;
