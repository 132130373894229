import { Button, Form } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Errors from 'components/Errors';
import Password from 'components/Form/Password';

import { LoginFormType } from './types';

type LoginFormPasswordType = {
  isLoading: boolean;
  error: string | null;
  onNext(data: LoginFormType): void;
  registered: string | undefined;
  isTeamMemberInvitation: boolean;
};

const LoginFormPassword = ({ isLoading, error, onNext }: LoginFormPasswordType) => {
  const { t } = useTranslation();
  const { handleSubmit, setFocus, reset } = useFormContext<LoginFormType>();

  useEffect(() => {
    setFocus('password');
    setTimeout(() => reset(undefined, { keepValues: true, keepIsSubmitted: false }), 0);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onNext)} noValidate>
      <Errors
        errors={error ? [error] : null}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      <Password id="password" name="password" label={t('auth.login.password')} required />
      <div className="text-end mt-2">
        <Link to="/forgot-password" className="text-orange01">
          {t('auth.login.forgot_password')}
        </Link>
      </div>
      <div className="d-grid gap-2 mt-4">
        <Button id="login-submit" type="submit" variant="success" disabled={isLoading}>
          {isLoading && <i className="fa-duotone fa-spinner fa-spin" />}
          {!isLoading && t('auth.login.title')}
        </Button>
      </div>
    </Form>
  );
};

export default LoginFormPassword;
