import { Col, ListGroup, Row, OptionValue } from '@valid-eval/shared-react-components';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { isGovEnv } from 'config';
import { createAsyncReport } from 'data/actions/reports';
import withRouter from 'routes/withRouter';

import ReportsListItem from './ReportsListItem';
import TeamFilesReportModal from './TeamFilesReportModal';
import { getEvent } from 'data/reducers';
import { isTeamLead } from 'utils';

type OwnProps = {
  params: {
    event_id: string;
  };
};

const connector = connect(
  (state, ownProps: OwnProps) => ({
    eventId: ownProps.params.event_id,
    event: getEvent(state, ownProps.params.event_id)?.toJS?.(),
  }),
  {
    createAsyncReport,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type GlobalReportsProps = PropsFromRedux & OwnProps;

const GlobalReports = ({ eventId, createAsyncReport, event }: GlobalReportsProps) => {
  const [confirmTeamFilesReportModal, setConfirmTeamFilesReportModal] = useState(false);
  const isQualitativeOnlyMode = event.judging_ux_mode === 'qualitative_only_mode';

  function handleClickReport(reportName: string, extraParams?: any) {
    createAsyncReport(eventId, reportName, null, extraParams);
  }

  function handleClickTeamFilesReport() {
    setConfirmTeamFilesReportModal(true);
  }

  function handleConfirmTeamFilesReport({
    artifacts,
    teams,
  }: {
    artifacts: OptionValue[];
    teams: OptionValue[];
  }) {
    handleClickReport('team_files_report', {
      artifacts: artifacts.map((o) => o.value),
      teams: teams.map((o) => o.value),
    });
    setConfirmTeamFilesReportModal(false);
  }

  function handleCancelTeamFilesReport() {
    setConfirmTeamFilesReportModal(false);
  }

  return (
    <>
      <TeamFilesReportModal
        eventId={eventId}
        onCancel={handleCancelTeamFilesReport}
        onConfirm={handleConfirmTeamFilesReport}
        show={confirmTeamFilesReportModal}
      />
      <Row>
        <Col md={6}>
          <h2>Signup Report</h2>
          <ListGroup className="mb-3">
            <ReportsListItem
              reportName="event_signup_progress"
              title="Signup Progress"
              description="Teams and Judges who have signed up so far for your Cohort, most recent first."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="team_list"
              title="Team List"
              description="A list of all of the applying teams with member names and email addresses."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="team_profiles"
              title="Team Profiles"
              description="Profile information, including matching tags, of your teams."
              onClick={handleClickReport}
            />
            <ReportsListItem
              title="Judge List"
              reportName="judge_list"
              description="Names and email addresses of all judges currently registered."
              onClick={handleClickReport}
            />
            <ReportsListItem
              title="Judge Profiles"
              reportName="judge_profiles"
              description="Profile information, including matching tags, of your judges."
              onClick={handleClickReport}
            />
          </ListGroup>

          <h2>Scoring Process</h2>
          <ListGroup className="mb-3">
            {!isGovEnv() && (
              <ReportsListItem
                reportName="team_selfeval"
                title="Self-Scoring Progress by Teams"
                description="Self-Scoring status for your teams for each phase."
                onClick={handleClickReport}
              />
            )}
            <ReportsListItem
              reportName="judge_progress"
              title="Judge Scoring Progress"
              description="Monitor the progress of judge scores completed vs. matched."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="team_progress"
              title="Team Scoring Progress"
              description="Monitor the progress of team scores completed vs. matched."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="teams_advancing"
              title="Teams Advancing"
              description={`Teams' "status" from your Results page vs. Evaluation scores.`}
              onClick={handleClickReport}
              disabled={isQualitativeOnlyMode && isTeamLead()}
            />
          </ListGroup>
          <h2>Matching Teams and Judges</h2>
          <ListGroup className="mb-3">
            <ReportsListItem
              title="Recusals"
              reportName="recusals"
              description="All recusals made so far during your cohort."
              onClick={handleClickReport}
            />
            <ReportsListItem
              title="Team Match List"
              reportName="team_assignment_list"
              description="A list of the matched companies for each team across all phases."
              onClick={handleClickReport}
            />
            <ReportsListItem
              title="Judge Match List"
              reportName="judge_assignment_list"
              description="A list of the matched companies for each judge across all phases."
              onClick={handleClickReport}
            />
          </ListGroup>
        </Col>
        <Col md={6}>
          <h2>Reports for your Records</h2>
          <ListGroup className="mb-3">
            <ReportsListItem
              reportName="scoring_summary"
              title="Overall Scores"
              description="All overall scores from all judges."
              onClick={handleClickReport}
              disabled={isQualitativeOnlyMode && isTeamLead()}
            />
            <ReportsListItem
              reportName="dimension"
              title="Dimension Scores"
              description="Every quantitative score recorded."
              onClick={handleClickReport}
              disabled={isQualitativeOnlyMode && isTeamLead()}
            />
            <ReportsListItem
              reportName="criteria"
              title="Feedback Clicks"
              description="Every qualitative scoring click your judges recorded."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="comments"
              title="Comments"
              description="A list of all comments made during this cohort."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="intros"
              title="Introductions"
              description="A list of all introductions offered by Judges to Teams during this cohort."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="notifications_sent_to_teams"
              title="Notifications to Teams"
              description="The contents of all email notifications sent to all Teams during this cohort."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="notifications_sent_to_judges"
              title="Notifications to Judges"
              description="The contents of all email notifications sent to all Judges during this cohort."
              onClick={handleClickReport}
            />
            <ReportsListItem
              reportName="team_files_report"
              title="Team Files Report"
              description="A zip file containing all the artifacts uploaded by the Teams for this cohort."
              onClick={handleClickTeamFilesReport}
            />
            <ReportsListItem
              reportName="application_revision_report"
              title="Revision Activity"
              description="Teams application revision report."
              onClick={handleClickReport}
            />
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(connector(GlobalReports));
