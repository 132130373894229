import { Col, Container, Image, Row } from '@valid-eval/shared-react-components';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './Footer.module.scss';

const Footer = ({ menu, className, strings }) => {
  return (
    <footer className={classNames('d-print-none', styles.footerContainer, className)}>
      <Container fluid>
        <Row className={styles.linksContainer}>
          {menu.map(({ title, link, menu = [] }, key) => (
            <Col sm={'auto'} key={key}>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.linksShape}
              >
                {title}
              </a>
            </Col>
          ))}
          <Col className={styles.logoContainer}>
            <a href={strings[0].homeLink} target="_blank" rel="noopener noreferrer">
              <Image src={strings[0].image} />
            </a>
          </Col>
        </Row>
        <Row className={styles.footerRow}>
          <Col className={styles.footerDescription}>
            ©{new Date().getFullYear() + ' ' + strings[0].copyright}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      copyright: PropTypes.string.isRequired,
    }),
  ),
  className: PropTypes.string,
  strings: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      copyright: PropTypes.string.isRequired,
      homeLink: PropTypes.string.isRequired,
    }),
  ),
};

export default Footer;
