import GenericModal from 'components/GenericModal';

type MembersResendModalProps = {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isSaving: boolean;
};

const MembersResendModal = ({ show, onCancel, onConfirm, isSaving }: MembersResendModalProps) => {
  return (
    <GenericModal
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      name="members-resend-modal"
      title="Change to Primary Role"
      body="The user cannot be set as primary until they accept the invitation."
      cancelButton="Cancel"
      confirmButton="Resend Invite"
      isButtonDisabled={isSaving}
      isCancelButtonDisabled={isSaving}
    />
  );
};

export default MembersResendModal;
