/* eslint no-mixed-operators: 0 */
import { Form } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { Field } from 'redux-form/immutable';
import CheckboxListComponent from '../CheckboxList';
import styles from './CheckboxList.module.scss';

import { dynamicFieldValidation } from './utils';

export const CheckboxListFieldGroup = ({
  input,
  meta: { error, warning, touched },
  id,
  label,
  helpText,
  disabled,
  values,
  sortOptions,
}) => {
  useEffect(() => {
    const valuesArray = values?.toJSON?.() || [];
    const inputValueArray = input.value?.toJSON?.() || [];
    // Try to find the selected values within the values
    // If someone is not found is removed
    if (inputValueArray.length && valuesArray.length) {
      const filteredValues = inputValueArray.filter((value) =>
        valuesArray.find((v) => v.value === value),
      );
      filteredValues.length !== inputValueArray.length && input.onChange(filteredValues);
    }
  }, [input.value, values]);

  return (
    <Form.Group className={styles.inputTitle} controlId={id}>
      {label && (
        <Form.Label>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <CheckboxListComponent
        {...input}
        disabled={disabled}
        values={values}
        sortOptions={sortOptions}
        meta={{ error, warning, touched }}
      />
    </Form.Group>
  );
};

export const CheckboxList = ({
  id,
  name,
  title,
  helpText,
  disabled,
  values,
  value,
  validate,
  sortOptions,
}) => (
  <Field
    id={id || name}
    name={name}
    label={title}
    helpText={helpText}
    disabled={disabled}
    component={CheckboxListFieldGroup}
    value={value}
    values={values}
    validate={validate || dynamicFieldValidation}
    sortOptions={sortOptions}
  />
);
