import { Button } from '@valid-eval/shared-react-components';
import Loading from 'components/Loading';
import { getEnvVar } from 'config';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { acceptInvitation, loadInvitation } from 'data/actions/teams';
import { load } from 'data/actions/users';
import { getCurrentUser } from 'data/reducers';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from 'routes/withRouter';
import { teamNav } from 'utils/urls';

const TeamMembersAcceptInvitationConfirmation = ({
  acceptInvitation,
  loadInvitation,
  notifyError,
  notifySuccess,
  navigate,
  load,
  params,
  currentUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [invitation, setInvitation] = useState(null);

  const handleLoadInvitation = async () => {
    setLoading(true);
    const { payload, error } = await loadInvitation(params.team_id, params.invitation_id);
    if (!error) {
      let message;
      const invitation = payload?.team_member_invitation;
      if (invitation.accepted) message = 'This invitation has been already accepted.';
      if (invitation.declined) message = 'This invitation has been already declined.';
      if (invitation.expired) message = 'This invitation has already expired.';
      if (message) {
        notifyError(message);
        await load('me');
        return navigate('/app/dashboard');
      }
      setInvitation(invitation);
    } else {
      let message = 'This invitation is invalid, has expired, or has already been used.';
      if (payload.status === 403)
        message =
          "The email address in this invitation doesn't match any of the email addresses linked to your user account.";

      notifyError(message);
      await load('me');
      return navigate(currentUser?.event_roles?.length ? '/app/dashboard' : '/app/account');
    }

    setLoading(false);
  };

  useEffect(() => {
    handleLoadInvitation();
  }, []);

  const handleConfirmClick = async () => {
    const { error, payload } = await acceptInvitation(params.team_id, params.invitation_id);

    if (error) {
      notifyError(
        payload?.response?.error ||
          `Failed to accept invitation, please contact ${getEnvVar(
            'REACT_APP_SUPPORT_EMAIL',
            'support@valideval.com',
          )} for assistance`,
      );
    } else {
      notifySuccess('The invitation has been accepted', 'Success');
      await load('me');
      navigate(teamNav.results(invitation.event_id, params.team_id));
    }
  };

  return (
    <>
      {loading && <Loading />}
      {invitation && !loading && (
        <div style={{ flex: 1 }}>
          <p className="text-center">
            Please confirm you want to accept this invitation from <b>{invitation.team_name}</b>.
          </p>
          <div className="mt-5 d-grid">
            <Button
              variant="success"
              onClick={handleConfirmClick}
              data-test-id="confirm-accept-team-member-invite"
            >
              Confirm
            </Button>
          </div>
          <div className="mt-4 d-grid">
            <Link to="/" className="btn btn-link">
              Cancel
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

TeamMembersAcceptInvitationConfirmation.displayName = 'TeamMembersAcceptInvitationConfirmation';

export default withRouter(
  connect(
    (state) => ({
      currentUser: getCurrentUser(state)?.toJS?.(),
    }),
    {
      loadInvitation,
      acceptInvitation,
      notifyError,
      notifySuccess,
      load,
    },
  )(TeamMembersAcceptInvitationConfirmation),
);
