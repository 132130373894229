import { Map } from 'immutable';

import { actions } from '../actions/notifications';

const initialState = new Map();

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD:
      return state.set(action.payload.id, action.payload);
    case actions.REMOVE:
      return state.delete(action.payload.id);
    default:
      return state;
  }
};

export const getSeq = (state) => state.valueSeq();

export default notifications;
