import { cloneElement, useRef } from 'react';

import { OverlayTrigger } from '@valid-eval/shared-react-components';
import Styles from 'screens/app/components/HelpCMSPage.module.scss';

const CopiedOverlay = ({ children, entity = 'link' }) => {
  const ref = useRef();
  return (
    <OverlayTrigger
      trigger="click"
      placement={'top'}
      rootClose
      target={ref}
      overlay={({ arrowProps, show, hasDoneInitialMeasure, ...props }) => (
        <div {...props} style={{ ...props.style }} className={Styles.CopiedTooltip}>
          Copied {entity} to your clipboard
        </div>
      )}
    >
      {cloneElement(children, { ref: ref })}
    </OverlayTrigger>
  );
};

export default CopiedOverlay;
