import { useEffect } from 'react';
import { connect } from 'react-redux';

import { unsetCurrentRole } from 'data/actions/support';
import { getCurrentUser } from 'data/reducers';
import 'styles/styles.scss';

import ErrorBoundary from '../ErrorBoundary';

const Wrapper = ({ authData, children, unsetCurrentRole }) => {
  useEffect(() => {
    if (authData.get('id')) {
      setTimeout(() => {
        const uvTab = document.getElementById('uvTab');
        if (uvTab) uvTab.style.display = 'block';
      }, 5000);
    }

    return () => {
      const uvTab = document.getElementById('uvTab');
      if (uvTab) uvTab.style.display = 'none';
    };
  }, []);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default connect((state) => ({ authData: getCurrentUser(state) }), { unsetCurrentRole })(
  Wrapper,
);
