import { RadioList, RadioScale, Textarea, Number } from 'components/FormFields';
import { minValue, number, required } from 'components/FormFields/validations';

import { Button, Form } from '@valid-eval/shared-react-components';
import { reduxForm } from 'redux-form/immutable';
import Styles from '../Survey.module.scss';

const minHours = minValue(0.1);

function SurveyForm({ questions, handleSubmit, isTeam }) {
  return (
    <Form onSubmit={handleSubmit} id="survey-form">
      {questions.map((q) => {
        if (q.type === 'text')
          return (
            <Textarea
              key={q.name}
              name={q.name}
              title={isTeam ? q.team_text : q.judge_text}
              validate={q.required ? [required] : []}
            />
          );
        if (q.type === 'hours')
          return (
            <div className={Styles.hoursInput} key={q.name}>
              <Number
                name={q.name}
                title={isTeam ? q.team_text : q.judge_text}
                validate={[...(q.required ? [required] : []), number, minHours]}
              >
                <label className={Styles.hoursLabel}>Hours</label>
              </Number>
            </div>
          );
        if (q.type === 'options')
          return (
            <RadioList
              key={q.name}
              name={q.name}
              title={isTeam ? q.team_text : q.judge_text}
              values={q.options}
              validate={q.required ? [required] : []}
            />
          );
        if (q.type === 'scale')
          return (
            <RadioScale
              key={q.name}
              id={`survey-${q.name}`}
              name={q.name}
              title={isTeam ? q.team_text : q.judge_text}
              minValue={q.min_value}
              maxValue={q.max_value}
              minValueLabel={q.min_value_label}
              maxValueLabel={q.max_value_label}
              validate={q.required ? [required] : []}
            />
          );
        return null;
      })}
      <Button className="mt-4" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default reduxForm({ form: 'surveyForm', enableReinitialize: true })(SurveyForm);
