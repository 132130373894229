export type TOC = {
  level: number;
  class: string;
  subHeader: TOC[];
  target?: string;
  text?: string;
};

export const sortTOCHeaders = (toc: TOC[]) => {
  let indexH = 0;
  let indexS = 0;
  let header = [];

  for (let i = 0; i < toc.length; i++) {
    //Titles
    if (toc[i].level === 0) {
      header[indexH] = toc[i];
      header[indexH].class = 'section' + indexH;
      header[indexH].subHeader = [];
      indexH = indexH + 1;
      indexS = 0;
    } else if (toc[i].level === 1) {
      //SubTitles
      header[indexH] = toc[i];
      header[indexH].class = 'section' + indexH;
      header[indexH].subHeader = [];
      indexH = indexH + 1;
      indexS = 0;
    } else if (toc[i].level === 2) {
      //Content
      header[indexH - 1].subHeader[indexS] = toc[i];
      header[indexH - 1].subHeader[indexS].class = 'section' + (indexH - 1);
      indexS = indexS + 1;
    }
  }
  return header;
};
