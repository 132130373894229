import { Table } from '@valid-eval/shared-react-components';
import classes from './UserExperienceInfo.module.scss';
import { isGovEnv } from 'config';

const UNKNOW_BUSINESS = 'Unknown business discipline';
const UNKNOW_EARLY_STAGE = 'Unknown early stage investing experience';
const UNKNOW_FUND_RAISING = 'Unknown fund raising experience';
const UNKNOW_JOB = 'Unknown job function';

const UserExperienceInfo = ({ experiences }) => {
  const business = experiences && (experiences.get('business_discipline') || UNKNOW_BUSINESS);
  const early_stage =
    experiences && (experiences.get('early_stage_investing_experience') || UNKNOW_EARLY_STAGE);
  const fund_raising =
    experiences && (experiences.get('fund_raising_experience') || UNKNOW_FUND_RAISING);
  const job_function = experiences && (experiences.get('primary_job_function') || UNKNOW_JOB);
  return (
    <Table responsive className={classes.table}>
      <tbody>
        <tr className="no-bottom-border">
          <td>{business}</td>
          {!isGovEnv() && <td>{early_stage}</td>}
        </tr>
        <tr className="no-bottom-border">
          <td>{job_function}</td>
          {!isGovEnv() && <td>{fund_raising}</td>}
        </tr>
      </tbody>
    </Table>
  );
};

UserExperienceInfo.displayName = 'UserExperienceInfo';
export default UserExperienceInfo;
