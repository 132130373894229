import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SearchHelpTooltip from 'components/SearchHelpTooltip';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';
import Search from 'images/icons/search.png';
import withRouter from 'routes/withRouter';

import FiltersDropdown from '../../filters/FiltersDropdown';
import { InactiveTeam, phaseStatusFramework } from '../types';
import Styles from './TeamsAdd.module.scss';

type AvailableFilter = {
  title: string;
  field: string;
  type: string;
  options: { label: string; value: number | null }[];
};

const AVAILABLE_FILTERS: AvailableFilter[] = [
  {
    title: 'Decision Status',
    field: 'active',
    type: 'checkbox',
    options: [],
  },
];

type TeamsAddModalTableControlsProps = {
  data: InactiveTeam[];
  statusFramework: phaseStatusFramework;
  filters: any[];
  finalRank: boolean;
  showFilterMenu: boolean;
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  searchableFields: string[];
  textFilter: string;
  phase: any;
};

const TeamsAddModalTableControls = ({
  data,
  filters,
  finalRank,
  onOtherFiltersChange,
  onTextFilterChange,
  searchableFields,
  textFilter,
  showFilterMenu,
  phase,
}: TeamsAddModalTableControlsProps) => {
  const { t } = useTranslation();

  const filtered = data?.filter((row) => filterByTextFilter(textFilter, row, searchableFields));

  const availableFilters = useMemo(() => {
    const availableFilters = cloneDeep(AVAILABLE_FILTERS);

    // Status filters
    availableFilters[0].options = Object.keys(phase?.status_framework || {}).reduce<
      { label: string; value: number | null }[]
    >((acc, key) => {
      const status = phase.status_framework[key];
      if (status.active) {
        const count = filtered.reduce<number>((count, row) => {
          if (row.phases[0]?.outBubbleIn === status.value) {
            count++;
          }
          return count;
        }, 0);
        acc.push({
          label: `${status.label} (${count})`,
          value: status.value ?? '',
        });
      }
      return acc;
    }, []);

    return availableFilters;
  }, [data, textFilter, phase]);

  const placeholder = `Search by Prefix, Category or ${t(
    'event.team_profile_name_label',
    'Company',
  )}`;

  return (
    <Row className={'mt-4 mb-2'}>
      <Col xs={12} className="d-flex align-items-center">
        <div className={cx('mb-0 me-3', Styles.InputWrapper)}>
          <input
            className={cx('form-control', Styles.SearchInput)}
            placeholder={placeholder}
            onChange={(e) => onTextFilterChange(e.target.value)}
          />
          <img className={Styles.SearchInputIcon} src={Search} alt={placeholder} />
        </div>
        <div
          className={cx({
            'me-2': !finalRank && showFilterMenu,
          })}
        >
          <SearchHelpTooltip />
        </div>
        {!finalRank && showFilterMenu && (
          <FiltersDropdown
            availableFilters={availableFilters}
            onFiltersChange={onOtherFiltersChange}
            filters={filters}
          />
        )}
      </Col>
    </Row>
  );
};

export default withRouter(TeamsAddModalTableControls);
