import { Col, Row } from '@valid-eval/shared-react-components';
import { ConnectedProps, connect } from 'react-redux';
import parse from 'html-react-parser';

import { fromContents } from 'data/features/contents';
import { fromDocuments } from 'data/features/documents';
import withRouter from 'routes/withRouter';

import { StepComponentProps } from '../types';
import SignUpDocument from './SignUpDocument';
import StepContainer from './StepContainer';

type OwnProps = StepComponentProps;

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  const { event } = ownProps;

  return {
    instructions: fromContents.getContent(
      state.toJS(),
      event?.id || '',
      'Team',
      'Signup',
      'Instructions',
    ),
    documents: fromDocuments.getActiveDocuments(state.toJS(), event?.id || '', 'teams'),
  };
};

const connector = connect(mapStateToProps, null);

type InstructionsAndResourcesStepProps = OwnProps & ConnectedProps<typeof connector>;

const InstructionsAndResourcesStep = ({
  documents,
  instructions,
}: InstructionsAndResourcesStepProps) => {
  return (
    <StepContainer
      content={
        <>
          {parse(instructions?.content || '')}
          <Row>
            {documents?.map((document) => (
              <Col key={document.id} sm={6}>
                <SignUpDocument document={document} />
              </Col>
            ))}
          </Row>
        </>
      }
    />
  );
};

export default withRouter(connector(InstructionsAndResourcesStep));
