import { Button, Form } from '@valid-eval/shared-react-components';
import { Input, Password } from 'components/FormFields';

import Errors from '../../components/Errors';
import { Link } from 'react-router-dom';

import { reduxForm } from 'redux-form/immutable';

const LoginForm = ({ handleSubmit, error, submitting, showOtpField }) => {
  const errors = error && [error];
  return (
    <div>
      <Errors errors={errors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
      <Form onSubmit={handleSubmit}>
        <div className="login-input">
          <Input label={'Email Address'} name="username" />
        </div>
        <div className="login-input">
          <Password label={'Password'} name="password" />
        </div>
        {showOtpField && (
          <div className="login-input">
            <Input name="otp" label="Code" autoComplete={'off'} />
          </div>
        )}
        <div className="text-end mt-3">
          <Link className="login-link" to="/forgot-password">
            Forgot your password?
          </Link>
        </div>
        <div className="mt-4 d-grid">
          <Button variant="success" type="submit" disabled={submitting}>
            {showOtpField ? 'Login with MFA' : 'Enter'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default reduxForm({ form: 'login' })(LoginForm);
