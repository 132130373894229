/* eslint no-mixed-operators: 0 */
import cx from 'classnames';
import { useLayoutEffect, useRef } from 'react';
import { Form } from '@valid-eval/shared-react-components';
import { Field } from 'redux-form/immutable';
import styles from './Input.module.scss';

import { dynamicFieldValidation, resize } from './utils';

export const InputFieldGroup = ({
  input,
  meta: { touched, error, warning },
  id,
  type,
  label,
  placeholder,
  helpText,
  disabled,
  autoComplete,
  autoFocus,
  labelLight,
  className,
  onKeyDown,
  children,
  readOnly,
}) => {
  const isPrintView = /\/print(?:\/|$)/.test(window.location.href);
  const inputRef = useRef(null);
  useLayoutEffect(() => {
    if (inputRef.current && !isPrintView) {
      resize(helpText, inputRef);
    }
  }, [helpText, isPrintView]);

  if (input.value === undefined) input.value = '';

  let formControlComponent;
  if (isPrintView) {
    formControlComponent = <div className={'printInput'}>{input.value}</div>;
  } else {
    formControlComponent = (
      <Form.Control
        {...input}
        type={type}
        disabled={disabled}
        as="input"
        placeholder={placeholder}
        autoComplete={autoComplete || 'on'}
        autoFocus={autoFocus}
        className={styles.inputText}
        isInvalid={touched && (error || warning)}
        isValid={touched && !(error || warning)}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        ref={inputRef}
      />
    );
  }

  return (
    <Form.Group className={cx(styles.inputTitle, className)} controlId={id}>
      {label && (
        <Form.Label className={cx(labelLight && styles.labelLight)}>
          <b>{label}</b>
        </Form.Label>
      )}
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      {formControlComponent}
      {children}
      <Form.Control.Feedback type="invalid">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const Input = ({
  id,
  name,
  title,
  helpText,
  disabled,
  placeholder,
  type = 'text',
  validate,
  normalize,
  format,
  autoComplete,
  ...props
}) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={title}
      component={InputFieldGroup}
      type={type}
      disabled={disabled}
      helpText={helpText}
      placeholder={placeholder}
      validate={validate || dynamicFieldValidation}
      normalize={normalize}
      format={format}
      autoComplete={autoComplete}
      {...props}
    />
  );
};
