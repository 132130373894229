export type Status = {
  value: number | null;
  order: number;
  active: boolean;
};

export type StatusFramework = Record<string, Status>;

export function getStausValueWithOrder(statusFramework: StatusFramework) {
  statusFramework = statusFramework || {};
  return Object.keys(statusFramework).reduce((acc, key) => {
    if (!statusFramework[key].active) return acc;

    return { ...acc, [String(statusFramework[key].value)]: statusFramework[key].order };
  }, {});
}

export function getStatusesByValue(statusFramework: StatusFramework) {
  statusFramework = statusFramework || {};
  return Object.keys(statusFramework).reduce((acc, key) => {
    if (!statusFramework[key].active) return acc;

    return { ...acc, [String(statusFramework[key].value)]: statusFramework[key] };
  }, {});
}
