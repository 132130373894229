import { Button, Modal } from '@valid-eval/shared-react-components';
import { ConnectedProps, connect } from 'react-redux';
import { getCurrentUser, getEvent, getEventBySlug } from 'data/reducers';
import { getEventIdOrSlugFromPathname, teamSignUp } from 'utils/urls';
import { useEffect, useState } from 'react';

import { ToggleComponent } from 'components/FormFields';
import cx from 'classnames';
import { saveCookiesConsent } from 'data/actions/users';
import { setCookiesConsent } from 'data/actions/events';
import withRouter from 'routes/withRouter';
import { fromEvents } from 'data/features/events';

type OwnProps = {
  trackingCookies: boolean;
};

const mapStateToProps = (state: any, ownProps: any) => {
  const pathname = ownProps.location.pathname;
  const eventIdOrSlug = getEventIdOrSlugFromPathname(pathname);
  const event =
    (getEventBySlug(state, eventIdOrSlug) || getEvent(state, eventIdOrSlug))?.toJS?.() ||
    fromEvents.getEventBySlug(state.toJS(), eventIdOrSlug);
  // Right now we only care about the gtmId for the signup screen
  const isSignupScreen = pathname === teamSignUp(eventIdOrSlug);
  const gtmId = isSignupScreen && event?.gtmId ? event.gtmId : 'general';

  return {
    event,
    gtmId,
    trackingCookies: gtmId !== 'general',
    currentUser: getCurrentUser(state)?.toJS?.(),
  };
};

const mapDispatchToProps = { saveCookiesConsent, setCookiesConsent };

const connector = connect(mapStateToProps, mapDispatchToProps);

type CookiesBannerProps = OwnProps & ConnectedProps<typeof connector>;

const CookiesBanner = ({
  currentUser,
  event,
  trackingCookies,
  gtmId,
  saveCookiesConsent,
  setCookiesConsent,
}: CookiesBannerProps) => {
  const cookiesKey = `cookiesState:${gtmId}`;

  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState<{
    dismissed?: boolean;
    strictlyNecessary?: boolean;
    tracking?: boolean;
    saved?: boolean;
  }>({
    dismissed: false,
    strictlyNecessary: true,
    tracking: trackingCookies,
    saved: false,
  });

  /**
   * Effect for loading cookies state
   * from local storage based on the gtmId
   */
  useEffect(() => {
    const cookies = localStorage.getItem(cookiesKey);
    let cookiesJSON = {
      dismissed: false,
      strictlyNecessary: true,
      tracking: trackingCookies,
      saved: false,
    };
    if (cookies) {
      cookiesJSON = JSON.parse(cookies);
      setCookiesConsent(event?.id, cookiesJSON);
    }
    setCookiesAccepted(cookiesJSON);
  }, [gtmId]);

  /**
   * Effect for saving the cookies concent when was accepted
   * and the user is logged in
   */
  useEffect(() => {
    if (currentUser?.id && cookiesAccepted.dismissed && !cookiesAccepted.saved) {
      const newCookiesAccepted = { ...cookiesAccepted, saved: true };
      localStorage.setItem(cookiesKey, JSON.stringify(newCookiesAccepted));
      setCookiesConsent(event?.id, newCookiesAccepted);
      setCookiesAccepted(newCookiesAccepted);
      saveCookiesConsent({ eventId: event?.id, cookiesConsent: cookiesAccepted });
    }
  }, [currentUser?.id, cookiesAccepted.dismissed, cookiesAccepted.saved]);

  const handleShowCookiesModal = () => {
    setShowCookiesModal(true);
  };

  const handleHideCookiesModal = () => {
    setShowCookiesModal(false);
  };

  const handleCookiesAcceptedChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCookiesAccepted({
        ...cookiesAccepted,
        [name]: event.target.checked,
      });
    };

  const handleDismiss = (values = cookiesAccepted) => {
    const newCookiesAccepted = {
      ...values,
      dismissed: true,
    };
    setCookiesAccepted(newCookiesAccepted);
    localStorage.setItem(cookiesKey, JSON.stringify(newCookiesAccepted));
    setCookiesConsent(event?.id, newCookiesAccepted);
    handleHideCookiesModal();
  };

  const handleAcceptAll = () =>
    handleDismiss({ strictlyNecessary: true, tracking: trackingCookies });

  const handleConfirm = () => handleDismiss({ ...cookiesAccepted });

  return (
    <>
      <div
        className={cx('modal-backdrop fade show', {
          'd-none': cookiesAccepted.dismissed || showCookiesModal,
        })}
        style={{ zIndex: 1064 }}
      ></div>
      <div
        className={cx('position-fixed bottom-0 left-0 w-100 p-4 bg-white shadow', {
          'd-none': cookiesAccepted.dismissed || showCookiesModal,
        })}
        style={{ zIndex: 1065 }}
      >
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h2 className="pb-0">Valid Eval Usage Requirements</h2>
        </div>
        <p>
          The Valid Eval web application uses cookies and similar technologies. By using our web
          application and clicking the 'Accept' button below, you not only agree to our use of these
          cookies, but also to the Valid Eval&nbsp;
          <a href="https://valideval.com/ve-terms-of-use/" target="_blank" rel="noreferrer">
            Terms of Use <i className="fa-light fa-external-link" />
          </a>
          &nbsp;and Valid Eval&nbsp;
          <a href="https://valideval.com/ve-privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy <i className="fa-light fa-external-link" />
          </a>
          .
        </p>
        <div className="mt-4">
          <Button
            variant="primary"
            className="me-3"
            onClick={handleAcceptAll}
            data-test-id="cookies-banner-modal-accept"
          >
            Accept All
          </Button>
          <Button
            variant="link"
            onClick={handleShowCookiesModal}
            data-test-id="cookies-banner-modal-manage"
          >
            Manage Cookies
          </Button>
        </div>
      </div>

      <Modal onHide={handleHideCookiesModal} show={showCookiesModal && !cookiesAccepted.dismissed}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Cookies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {trackingCookies ? (
            <>
              <p className="text-justify">
                Some of the cookies Valid Eval uses are necessary for our website and services to
                function properly. You can not opt out of these.
              </p>
              <p className="text-justify">
                The Organizer team has requested that we gather analytics on where you came from to
                access this signup form and how you are using it, which requires setting one or more
                tracking cookies. We collect and track this information only during the signup
                process. Unless you opt out, we will use this anonymized tracking information and
                the resulting summary statistics with our clients to improve the signup process, the
                Valid Eval platform, and even proposal processes in general.
              </p>
            </>
          ) : (
            <>
              <p className="text-justify">
                The cookies Valid Eval uses are necessary for our website and services to function
                properly. You can not opt out of these.
              </p>
            </>
          )}
          <div>
            {trackingCookies && (
              <div className="d-flex align-items-center">
                <label className="fw-bold me-2 my-2" htmlFor="strictly-necessary-cookies-toggle">
                  Tracking Cookies
                </label>
                {/* @ts-ignore */}
                <ToggleComponent
                  input={{
                    id: 'tracking-cookies-toggle',
                    name: 'tracking-cookies-toggle',
                    value: cookiesAccepted.tracking,
                    onChange: handleCookiesAcceptedChange('tracking'),
                  }}
                />
              </div>
            )}
            <p className="text-justify">
              By clicking "Confirm & Continue" below, you will&nbsp;
              {trackingCookies ? (
                cookiesAccepted.tracking ? (
                  <span>accept the Tracking Cookies,</span>
                ) : (
                  <span>opt out of the Tracking Cookies while accepting the</span>
                )
              ) : (
                <span>accept the</span>
              )}
              &nbsp;Necessary Cookies, Valid Eval&nbsp;
              <a href="https://valideval.com/ve-terms-of-use/" target="_blank" rel="noreferrer">
                Terms of Use <i className="fa-light fa-external-link" />
              </a>
              &nbsp;and Valid Eval&nbsp;
              <a href="https://valideval.com/ve-privacy-policy/" target="_blank" rel="noreferrer">
                Privacy Policy <i className="fa-light fa-external-link" />
              </a>
              .
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={handleConfirm}>
            Confirm & Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(connector(CookiesBanner));
