import { Col, Row } from '@valid-eval/shared-react-components';
import { Email, Input, Select } from 'components/FormFields';
import * as validations from 'components/FormFields/validations';
import { email, required } from 'components/FormFields/validations';
import { List, Map } from 'immutable';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';

const invitationExists = (value, props) => {
  if (!value) return;

  const existingEmails = props.get('existingEmails')?.toJS() || [];
  return existingEmails.some((email) => email.toLowerCase() === value.toLowerCase())
    ? 'This email address has already been invited.'
    : undefined;
};

function MembersInviteFields({ fields }) {
  const values = useSelector(getFormValues('MembersInviteForm'))?.toJS() || {};

  return fields.map((field, index) => {
    const isEmailRequired =
      index === 0 ||
      values?.invitations?.[index]?.first_name ||
      values?.invitations?.[index]?.last_name;

    return (
      <Row key={`invitation-${field}`} className="m-0 mb-2">
        <Col className="ps-0">
          <Input
            className="m-0"
            name={`${field}.first_name`}
            placeholder="First Name"
            autoFocus={index === 0}
            validate={[validations.name]}
            normalize={validations.sanitizeName}
          />
        </Col>
        <Col>
          <Input
            className="m-0"
            name={`${field}.last_name`}
            placeholder="Last Name"
            validate={[validations.name]}
            normalize={validations.sanitizeName}
          />
        </Col>
        <Col>
          <Email
            className="m-0"
            name={`${field}.email`}
            placeholder="Email"
            validate={[email, invitationExists, ...(isEmailRequired ? [required] : [])]}
          />
        </Col>
        <Col className="pe-0">
          <Select
            className="m-0"
            name={`${field}.role`}
            placeholder="Choose role"
            validate={isEmailRequired ? [required] : []}
            options={List([
              Map({ label: 'Editor', value: 'editor' }),
              Map({ label: 'Viewer', value: 'viewer' }),
            ])}
            onKeyDown={(e) => {
              if ((e.code === 'Tab' || e.keyCode === 9) && index === fields.length - 1) {
                fields.push(new Map());
              }
            }}
          />
        </Col>
      </Row>
    );
  });
}

export default MembersInviteFields;
