import { ReactNode } from 'react';
import cx from 'classnames';

const StepContainer = ({ content, footer }: { content: ReactNode; footer?: ReactNode }) => {
  const classname = localStorage.getItem('__E2E_TEST__') ? 'position-relative' : 'position-sticky';

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-grow-1 p-1">{content}</div>
      {!!footer && (
        <footer
          className={cx(
            classname,
            'p-3 m-0 bg-white01 d-flex justify-content-end align-items-center',
          )}
          style={{ bottom: 0 }}
        >
          {footer}
        </footer>
      )}
    </div>
  );
};

export default StepContainer;
