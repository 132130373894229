import { ListGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';

type ReportsListItemProps = {
  description: string;
  title: string;
  reportName: string;
  onClick: (report: string) => void;
  disabled?: boolean;
};

const ReportsListItem = ({
  description,
  title,
  reportName,
  onClick,
  disabled,
}: ReportsListItemProps) => {
  function handleClick() {
    onClick(reportName);
  }

  return (
    <ListGroup.Item
      id={`report_${reportName}`}
      action
      className={cx({
        'bg-blue01-light': reportName === 'consensus_meeting',
        'opacity-50': disabled,
      })}
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      <p
        className={cx(
          {
            'text-blue03': reportName === 'consensus_meeting',
            'text-blue02': reportName !== 'consensus_meeting',
          },
          'fw-bold',
          'mb-1',
        )}
      >
        {title} <i className="fa-regular fa-download ms-1" />
      </p>
      <p className="text-body fw-normal mb-0">
        <small>{description}</small>
      </p>
    </ListGroup.Item>
  );
};

export default ReportsListItem;
