import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { sortStrings } from 'components/Tables/sortingHelpers';
import { Judge } from 'screens/app/events/shared/types';

const matchColumnHelper = createColumnHelper<Judge>();

export const matchColumns: ColumnDef<Judge, any>[] = [
  matchColumnHelper.accessor('active', {
    id: 'Active',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Active,
  }),
  matchColumnHelper.accessor('panel', {
    id: 'Panel',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.SmallText,
    sortingFn: sortStrings,
  }),
  matchColumnHelper.accessor('name', {
    id: 'Name',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: CommonTableCells.ProfileLink,
    sortingFn: sortStrings,
  }),
  matchColumnHelper.accessor('assignedRolesCount', {
    id: '# Teams',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Text,
  }),
  matchColumnHelper.accessor('assignedRoles', {
    id: 'Teams',
    header: (props) => <CommonTableHeaders.SimpleHeader className="text-left" {...props} />,
    cell: CommonTableCells.MatchedEntities,
  }),
  matchColumnHelper.accessor('matchQuality', {
    id: 'Match Quality',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.NumberOrZero,
  }),
  matchColumnHelper.accessor('id', {
    id: 'Actions',
    header: CommonTableHeaders.SimpleHeader,
    cell: CommonTableCells.MatchActions,
  }),
];
