import { ServerImage } from 'components/Image';
import Styles from './PDFHeader.module.scss';

type PDFHeaderProps = {
  event: any;
  phase?: any;
  team?: any;
  showReport?: boolean;
};

function PDFHeader({ event, phase, team, showReport = true }: PDFHeaderProps) {
  return (
    <div className={Styles.PDFHeader}>
      <div className="clearfix">
        <div className={Styles.Image}>
          <ServerImage fit src={event?.get('logo_url') || '/images/no-image.png'} />
        </div>
        <div className={Styles.EventName}>
          {event?.get('name')}
          {showReport && <h3 className={Styles.Title}>Valid Eval Feedback Report</h3>}
        </div>
        {phase && <div className={Styles.PhaseName}>{phase?.get('name')}</div>}
        {team && (
          <div className={Styles.PhaseName}>
            {team?.get('name')}
            <small>{team.get('category')}</small>
          </div>
        )}
      </div>
    </div>
  );
}

export default PDFHeader;
