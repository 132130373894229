import qs from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const query = qs.parse(location.search);

  return (
    <Component {...props} location={location} navigate={navigate} params={params} query={query} />
  );
};

export default withRouter;
