import { useFormContext } from 'react-hook-form';

const useFormFieldState = (name: string) => {
  const { control, formState, ...form } = useFormContext();

  const showValidation =
    formState.isSubmitted || formState.touchedFields[name] || formState.dirtyFields[name];

  const error = formState?.errors?.[name];

  const isInvalid = !!error && showValidation;
  const isValid = !error && showValidation;
  let validationClassname = isInvalid && showValidation ? 'is-invalid' : '';
  validationClassname = isValid && showValidation ? 'is-valid' : validationClassname;

  return { ...form, control, formState, isValid, isInvalid, validationClassname, error };
};

export default useFormFieldState;
