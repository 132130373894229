import Content from 'components/Content';
import { IconWrapper } from 'components/Icon';

import Styles from './Documents.module.scss';

let Document = ({ type, text, title, url }) => (
  <div className={Styles.container}>
    <div className={Styles.link}>
      <IconWrapper type={type}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </IconWrapper>
    </div>
    <Content html={text} />
  </div>
);

Document.displayName = 'Document';

const Documents = ({ list }) => (
  <div>
    {list.map((document, key) => (
      <Document
        key={key}
        type={document.get('file_type')}
        text={document.get('instructions')}
        title={document.get('title')}
        url={document.get('url')}
      />
    ))}
  </div>
);

export default Documents;
