import { List, fromJS } from 'immutable';

import { LOAD_RESULT } from 'data/actions/businessDisciplines';

import { createSelector } from 'reselect';
import { handleActions } from 'redux-actions';

const initialState = fromJS({ data: {}, ids: [] });

export default handleActions(
  {
    [LOAD_RESULT]: (state, action) =>
      state
        .setIn(['data'], fromJS(action.payload.entities.businessDisciplines))
        .set('ids', action.payload.result),
  },
  initialState,
);

const getIds = (state) => state.get('ids');
const getData = (state) => state.get('data');

export const getArray = createSelector(getIds, getData, (ids, data) => {
  return List(ids.map((id) => data.get(id)));
});
