import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { del, get, post } from 'data/services/config';

import { ApplicationRevisionRequest } from './applicationRevisionRequestsTypes';
import { isPendingAction, isRejectedAction } from './common';

// Adapter for operate and normalize the data
export const applicationRevisionRequestsAdapter = createEntityAdapter<
  ApplicationRevisionRequest,
  string
>({
  selectId: (applicationRevisionRequest: ApplicationRevisionRequest) =>
    applicationRevisionRequest.team_profile_id,
});

// Initial state
const initialState = applicationRevisionRequestsAdapter.getInitialState({
  loadingApplicationRevisionRequests: false,
  lastCanceledDate: null,
  lastExpiredDate: null,
});

// Actions
export const fetchApplicationRevisionRequest = createAsyncThunk(
  'applicationRevisionRequest/fetch',
  async (teamId: string, { rejectWithValue }) => {
    try {
      return await get(`/teams/${teamId}/application_revision_requests`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createApplicationRevisionRequest = createAsyncThunk(
  'applicationRevisionRequest/create',
  async (
    {
      teamIds,
      deadline,
      fieldKeys,
      artifactIds,
    }: {
      teamIds: string[];
      deadline: string | null;
      fieldKeys: string[];
      artifactIds: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      return await post(`/teams/application_revision_requests`, {
        team_ids: teamIds,
        deadline,
        field_keys: fieldKeys,
        artifact_ids: artifactIds,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const cancelApplicationRevisionRequest = createAsyncThunk(
  'applicationRevisionRequest/cancel',
  async ({ teamId, id }: { teamId: string; id: number }, { rejectWithValue }) => {
    try {
      return await del(`/teams/${teamId}/application_revision_requests/${id}`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Slice, reducer and async actions
export const applicationRevisionRequestsSlice = createSlice({
  name: 'applicationRevisionRequestsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createApplicationRevisionRequest.fulfilled, (state, action) => {
        state.loadingApplicationRevisionRequests = false;

        applicationRevisionRequestsAdapter.setMany(
          state,
          action.payload.application_revision_requests,
        );
      })
      .addCase(fetchApplicationRevisionRequest.fulfilled, (state, action) => {
        state.loadingApplicationRevisionRequests = false;

        if (action.payload.application_revision_request) {
          applicationRevisionRequestsAdapter.setOne(
            state,
            action.payload.application_revision_request,
          );
        }

        if (action.payload?.last_canceled_date) {
          state.lastCanceledDate = action.payload.last_canceled_date;
        }

        if (action.payload?.last_expired_date) {
          state.lastExpiredDate = action.payload.last_expired_date;
        }
      })
      .addCase(cancelApplicationRevisionRequest.fulfilled, (state) => {
        state.loadingApplicationRevisionRequests = false;

        applicationRevisionRequestsAdapter.removeAll(state);
      })
      .addMatcher(isPendingAction('applicationRevisionRequest'), (state) => {
        state.loadingApplicationRevisionRequests = true;
      })
      .addMatcher(isRejectedAction('applicationRevisionRequest'), (state) => {
        state.loadingApplicationRevisionRequests = false;
      });
  },
  selectors: {
    getLastCanceledDate: (state) => state.lastCanceledDate,
    getLastExpiredDate: (state) => state.lastExpiredDate,
    getIsLoadingApplicationRevisionRequest: (state) => state.loadingApplicationRevisionRequests,
    getByTeamId: (state, teamId) =>
      applicationRevisionRequestsAdapter.getSelectors().selectById(state, teamId),
  },
});

export default applicationRevisionRequestsSlice.reducer;
export const fromApplicationRevisionRequests = applicationRevisionRequestsSlice.selectors;
