import { Row } from '@tanstack/react-table';
import { JudgeInvitation } from './types';

export const statusType = {
  Accepted: 0,
  Declined: 0,
  Pending: 0,
  Expired: 0,
};

export const searchableFields = ['full_name'];

export function sortStatus(
  { original: a }: Row<JudgeInvitation>,
  { original: b }: Row<JudgeInvitation>,
) {
  if (a.accepted && !b.accepted) return 1;
  if (!a.accepted && b.accepted) return -1;
  if (a.declined && !b.declined) return 1;
  if (!a.declined && b.declined) return -1;
  if (a.has_expired && !b.has_expired) return 1;
  if (!a.has_expired && b.has_expired) return -1;
  return 0;
}

export const filterByStatus = (
  filterList: any[],
  { accepted, declined, has_expired }: JudgeInvitation,
) => {
  if (filterList.length) {
    // Apply the decision status filter if is selected
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value], []);

    let statusKey = '';

    if (!accepted && !declined && !has_expired) statusKey = 'pending';
    if (accepted) statusKey = 'accepted';
    if (declined) statusKey = 'declined';
    if (has_expired) statusKey = 'expired';

    if (!status.includes(statusKey)) return false;
  }
  return true;
};
