import * as eventsAPI from '../services/events';

import { RSAA, getJSON } from 'redux-api-middleware';

export const actions = {
  LOAD: 'contents/LOAD',
};

export const LOAD_RESULT = 'contents/LOAD_RESULT';

export const load = (id, pageGroup, pageName) => ({
  [RSAA]: {
    ...eventsAPI.loadContent(id, pageGroup, pageName),
    types: [
      actions.LOAD,
      {
        type: LOAD_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({ event_id: id, contents: payload.contents })),
      },
      LOAD_RESULT,
    ],
  },
});
