import * as phasesAPI from '../services/phases';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const LOAD = 'phaseSummaries/LOAD';
export const LOAD_RESULT = 'phaseSummaries/LOAD_RESULT';

export const load = (id) => ({
  [RSAA]: {
    ...phasesAPI.loadSummary(id),
    types: [
      LOAD,
      {
        type: LOAD_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => normalize(payload.phase_summary, schemas.phaseSummary)),
      },
      LOAD_RESULT,
    ],
  },
});
