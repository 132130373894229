import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const hardReload = () => {
  if ('caches' in window) {
    window.caches.keys().then((names) => {
      for (let name of names) window.caches.delete(name);
    });
  }
  window.location.reload(true);
};

const checkMeta = async () => {
  try {
    const response = await fetch(`/meta.json?q=${new Date().getTime()}`, { cache: 'no-cache' });
    const meta = await response.json();
    const version = JSON.parse(localStorage.getItem('APP_VERSION'));
    if (version && version !== meta.version) {
      hardReload();
    }
    localStorage.setItem('APP_VERSION', meta.version);
  } catch {}
};

const CacheBuster = ({ children }) => {
  const pathname = useSelector((state) => state.getIn(['router', 'location', 'pathname']));

  useEffect(() => {
    checkMeta();
  }, [pathname]);

  return children;
};

CacheBuster.propTypes = {
  children: PropTypes.any,
};

export default CacheBuster;
