import { apiHeaders, apiURL } from '../services/config';

export const loadForJudgeAndPhase = (phaseId, judgeId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeId}/scores`),
});

export const loadForEvaluation = (evaluationId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/evaluations/${evaluationId}/scores`),
});

export const load = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/scores/${id}`),
});

export const saveScoreComment = (id, comment, eventId) => ({
  method: 'PATCH',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${eventId}/scores/${id}`),
  body: JSON.stringify({ score: { comment } }),
});

const scoringPath = (selfScore, eventId, scoreId, suffix) => {
  const scoreType = selfScore ? 'self_scores' : 'scores';
  return `/events/${eventId}/${scoreType}/${scoreId}/${suffix}`;
};

export const addClick = (
  selfScore,
  eventId,
  scoreId,
  dimensionId,
  criterionId,
  scoreValueAfterClick = 'same',
) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(scoringPath(selfScore, eventId, scoreId, 'clicks')),
  body: JSON.stringify({
    click: {
      id: criterionId,
      dimension_id: dimensionId,
      score_value_after_click: scoreValueAfterClick,
    },
  }),
});

export const removeClick = (
  selfScore,
  eventId,
  scoreId,
  dimensionId,
  criterionId,
  scoreValueAfterClick = 'same',
) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(scoringPath(selfScore, eventId, scoreId, `clicks/${criterionId}/remove`)),
  body: JSON.stringify({
    click: { dimension_id: dimensionId, score_value_after_click: scoreValueAfterClick },
  }),
});

export const saveDimensionScore = (selfScore, eventId, scoreId, dimensionId, value) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(
    scoringPath(selfScore, eventId, scoreId, `dimensions/${dimensionId}/save_score`),
  ),
  body: JSON.stringify({ dimension: { value } }),
});

export const createSelfScore = (eventId, teamId, phaseId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${eventId}/self_scores/`),
  body: JSON.stringify({ self_score: { team_id: teamId, phase_id: phaseId } }),
});
