import { apiURL, get } from './config';

import { baseCdnUrlFor } from 'utils';

export const loadIEWarning = () =>
  get('/utils/internet_explorer_warning').then((payload) => payload.content);

export const healthcheck = () => fetch(apiURL('/utils/healthcheck'));

export const loadHelpContent = (role) => get(`/utils/help_content/?role=${role}`);

export const getMaintenanceInfo = () =>
  fetch(baseCdnUrlFor('maintenance-info.html')).then((response) => {
    if (response.status === 200) return response.text();
    throw new Error('Not found');
  });

export const getRelNotes = () =>
  fetch(baseCdnUrlFor('release-notes.json')).then((response) => {
    if (response.status === 200) return response.text();
    throw new Error('Not found');
  });
