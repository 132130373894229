import { Form, InputGroup, Select as SelectComponent } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Controller } from 'react-hook-form';
import { RefCallback } from 'react';

import { required as requiredVal } from 'components/FormFields/validations';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import Styles from './Select.module.scss';
import useFormFieldState from './useFormFieldState';

type SelectOption = {
  value: string;
  label: string;
};

export type SelectProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: { validate?: Record<string, any> };
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  options: SelectOption[];
  isClearable?: boolean;
  isSearchable?: boolean;
};

const CustomSelect = ({
  options,
  value,
  isClearable = false,
  isSearchable = false,
  disabled = false,
  _ref,
  ...props
}: {
  options: SelectOption[];
  value: string | undefined;
  isClearable?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  _ref: RefCallback<any>;
}) => {
  return (
    <SelectComponent
      {...props}
      innerRef={_ref}
      value={options.find((op) => op.value === value)}
      isForm
      options={options}
      isSearchable={isSearchable}
      isDisabled={disabled}
      isClearable={isClearable}
    />
  );
};

const Select = ({
  after,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  disabled,
  required,
  helpText,
  options = [],
  isClearable = false,
  isSearchable = false,
}: SelectProps) => {
  const { control, isValid, isInvalid, error } = useFormFieldState(name);

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal, ...rules.validate } } : rules;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ref, ...fieldProps } }) => {
          return (
            <Form.Group className="mb-2">
              <Label htmlFor={id || name} label={label} />
              <HelpText text={helpText} />
              <InputGroup hasValidation>
                {before}
                <Form.Control
                  id={id || name}
                  className={cx(Styles.FormControl)}
                  // @ts-expect-error: onChange type is different because of the Select component
                  onChange={(option: SelectOption, event) => onChange(option?.value)}
                  placeholder={placeholder}
                  value={options.find((op) => op.value === value)?.value}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  disabled={disabled}
                  options={options}
                  as={CustomSelect}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  _ref={ref}
                  {...fieldProps}
                />
                {after}
                <FieldError show={isInvalid} message={String(error?.message)} />
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    </>
  );
};

export default Select;
