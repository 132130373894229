import { CellContext } from '@tanstack/react-table';
import { OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { dateInTimeZone } from 'utils';

import { TeamDraft } from './types';

type CellProps = CellContext<TeamDraft, string> & { className?: string };

export const DraftName = ({ getValue, className, row }: CellProps) => {
  const draft = row.original;
  return (
    <td className={cx('text-start', 'text-blue01', className)}>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            <ul className="text-start list-unstyled mb-0">
              <li className="mb-1">Created:&nbsp;&nbsp;{dateInTimeZone(draft.createdAt)}</li>
              <li className="mb-1">Updated:&nbsp;{dateInTimeZone(draft.updatedAt)}</li>
            </ul>
          </Tooltip>
        }
      >
        <strong>{getValue() || ''}</strong>
      </OverlayTrigger>
    </td>
  );
};
