import * as actions from '../actions/rubrics';

import { fromJS } from 'immutable';

const initialState = fromJS({ data: {}, loading: false });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_RUBRIC_REQUEST:
      return state.set('loading', true);
    case actions.LOAD_RUBRIC_RESULT:
      const { rubric } = action.payload;
      return state.set('loading', false).setIn(['data', rubric.id], fromJS(rubric));
    case actions.LOAD_FAIL:
      return state.set('loading', false);
    default:
      return state;
  }
};

export const getById = (state, id) => state.getIn(['data', String(id)]);
export const getIsLoading = (state) => state.get('loading');

export default reducer;
