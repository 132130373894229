import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
// @ts-ignore
import mime from 'mime-types';

import { get } from 'data/services/config';
import { isPendingAction, isRejectedAction } from './common';
import { categoriesToDynamicFields } from './eventsHelpers';
import { Event } from './eventsTypes';

// Adapter for operate and normalize the data
export const eventsAdapter = createEntityAdapter<Event>();

const initialState = eventsAdapter.getInitialState({
  loading: false,
});

// Actions
export const fetchEvent = createAsyncThunk(
  'events/fetch',
  async (eventId: string, { rejectWithValue }) => {
    try {
      return await get(`/events/${eventId}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvent.fulfilled, (state, action) => {
        eventsAdapter.upsertOne(state, action.payload.event);
        state.loading = false;
      })
      .addMatcher(isPendingAction('events'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('events'), (state) => {
        state.loading = false;
      });
  },
  selectors: {
    ...eventsAdapter.getSelectors(),
    getIsLoading: (state) => state.loading,
    getEventBySlug: createSelector(
      eventsAdapter.getSelectors().selectAll,
      (_, slug: string) => slug,
      (events: Event[], slug: string) => {
        const event = events.find((event) => event.slug === slug);
        if (!event) return undefined;

        const artifacts = (event.artifacts || []).map((artifact) => {
          return {
            ...artifact,
            file_type: mime.types[artifact.file_type],
          };
        });

        return {
          ...event,
          categories: categoriesToDynamicFields(event.matching_categories),
          artifacts,
        };
      },
    ),
  },
});

export default eventsSlice.reducer;
export const fromEvents = eventsSlice.selectors;
