import { apiHeaders, apiURL } from '../services/config';
import { get } from './config';

export const loadMultiple = (ids) =>
  get(`/teams?ids=${ids.join(',')}`).then((payload) => payload.teams);

export const loadEvaluation = (phaseId, teamId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${teamId}/evaluation_for_phase/${phaseId}`),
});

export const fetchSelfScore = (teamId, phaseId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${teamId}/score_for_phase/${phaseId}`),
});

export const update = (eventId, id, values, isDraft) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ team: values, is_draft: isDraft, event_id: eventId }),
  endpoint: apiURL(`/teams/${id}`),
});

export const saveArtifactFiles = (teamId, eventId, data) => {
  return {
    method: 'POST',
    credentials: 'include',
    headers: apiHeaders,
    body: JSON.stringify({ data }),
    endpoint: apiURL(`/events/${eventId}/teams/${teamId}/artifact_items/`),
  };
};

export const saveArtifactItems = (teamId, eventId, data) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ data }),
  endpoint: apiURL(`/events/${eventId}/teams/${teamId}/artifact_items/`),
});

export const loadArtifactItems = (teamId, phaseId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(
    phaseId
      ? `/teams/${teamId}/artifact_items/${phaseId}/team_phase_artifacts`
      : `/teams/${teamId}/artifact_items/`,
  ),
});

export const loadForEvent = (eventId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${eventId}/teams`),
});

export const load = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${id}`),
});

export const loadPhases = (teamId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${teamId}/phases`),
});

export const loadPhasesForSelfEvaluation = (teamId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${teamId}/self_evaluation_phases`),
});

export const unassignedJudges = (phaseId, teamId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams/${teamId}/unassigned_judges`),
});

export const loadMembers = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${id}/members`),
});

export const inviteMembers = (id, invitations) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ invitations }),
  endpoint: apiURL(`/teams/${id}/team_member_invitations`),
});

export const resendInvitation = (id, invitationId) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${id}/team_member_invitations/${invitationId}/resend`),
});

export const deleteInvitation = (id, invitationId, userId) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ user_id: userId }),
  endpoint: apiURL(`/teams/${id}/team_member_invitations/${invitationId}`),
});

export const updateMemberRole = (id, invitationId, userId, role) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ role, user_id: userId }),
  endpoint: apiURL(`/teams/${id}/team_member_invitations/${invitationId}`),
});

export const declineInvitationWithToken = (teamId, invitationId, token) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ token }),
  endpoint: apiURL(`/teams/${teamId}/team_member_invitations/${invitationId}/decline`),
});

export const acceptInvitation = (teamId, invitationId) => ({
  method: 'PUT',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${teamId}/team_member_invitations/${invitationId}/accept`),
});

export const loadInvitation = (teamId, invitationId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/teams/${teamId}/team_member_invitations/${invitationId}`),
});

export const downloadAllArtifacts = (teamId, evaluationId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(
    `/teams/${teamId}/artifact_items/download_all?${
      evaluationId ? `evaluation_id=${evaluationId}` : ''
    }`,
  ),
});

export const downloadArtifactPDFTeamReport = (url) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: url,
});
