import { Button, Modal } from '@valid-eval/shared-react-components';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { loadIEWarning } from '../data/services/utils';
import { isRunningOnIE } from '../utils';

class IEWarningModal extends Component {
  state = {
    showModal: false,
    content:
      '<div><p>Internet Explorer has been <a href="https://en.wikipedia.org/wiki/Internet_Explorer#End_of_life">retired by Microsoft for over 5 years</a> and is not supported by Valid Eval. If you continue to use IE to access Valid Eval, you will likely encounter errors and you may lose the work you are here to accomplish.</p><p>Please do NOT contact us for support with Internet Explorer. Rather, please use one of the supported browsers: Chrome, Edge, Firefox, and Safari.</p></div>',
  };

  async componentDidMount() {
    if (isRunningOnIE({ detectEdge: false })) {
      try {
        const content = await loadIEWarning();

        this.setState({
          content,
        });
      } catch (e) {
        console.error(e);
      }

      this.setState({
        showModal: true,
      });
      localStorage.setItem('IEWarningShown', this.props.newValue);
    }
  }

  componentDidUpdate(prevProps) {
    try {
      if (
        this.props.location.pathname !== prevProps.location.pathname &&
        isRunningOnIE({ detectEdge: false }) &&
        !this.state.showModal
      ) {
        this.setState({
          showModal: true,
        });
      }
    } catch {}
  }

  handleHide = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Unsupported browser</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={this.handleHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

IEWarningModal.defaultProps = {
  newValue: 'first_render',
};

IEWarningModal.propTypes = {
  newValue: PropTypes.string,
};

const IEWarningModalWithLocation = (props) => {
  const location = useLocation();
  return <IEWarningModal {...props} location={location} />;
};

export default IEWarningModalWithLocation;
