import { Col, Row } from '@valid-eval/shared-react-components';
import {
  assignToJudge,
  loadJudgeAssignments,
  loadJudgeSuggestions,
  removeFromJudge,
} from 'data/actions/judgeAssignments';
import {
  getJudgeCategories,
  getSelectedPhaseIdForCurrentEvent,
  getTeamSuggestionsForCurrentJudge,
  getTeamsAssignedToCurrentJudge,
  isLoadingJudgeAssignments,
} from 'data/reducers';

import AssignedRolesTable from 'components/AssignedRolesTable';
import AssignmentRolesSelect from 'components/AssignmentRolesSelect';
import { Component } from 'react';
import Loading from 'components/Loading';
import SuggestionsTable from '../events/components/SuggestionsTable';
import UserCategories from 'components/UserCategories';
import { connect } from 'react-redux';
import { isNavigator, isTeamLead } from 'utils';
import { loadForPhase } from 'data/actions/teams';
import { loadUnassignedTeams } from 'data/actions/judgeProfiles';
import { error as notifyError } from 'data/actions/notifications';
import withRouter from 'routes/withRouter';

const thWidths = ['10%', '10%', '30%', '30%', '10%'];

class EditAssignments extends Component {
  state = {
    teamList: [],
  };

  componentDidMount() {
    const {
      params: { judge_profile_id },
      selectedPhaseId,
    } = this.props;
    this.loadAssignmentsAndSuggestions(selectedPhaseId, judge_profile_id);
    this.loadTeams();
  }

  loadAssignmentsAndSuggestions = (phaseId, judgeId) => {
    this.props.loadJudgeAssignments(phaseId, judgeId);
    if (isNavigator() || isTeamLead()) return;

    this.props.loadJudgeSuggestions(phaseId, judgeId);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId !== this.props.selectedPhaseId) {
      this.loadAssignmentsAndSuggestions(
        nextProps.selectedPhaseId,
        this.props.params.judge_profile_id,
      );

      this.loadTeams();
    }
  }

  loadTeams = () => {
    if (isNavigator() || isTeamLead()) return;

    const {
      selectedPhaseId,
      params: { judge_profile_id },
    } = this.props;
    this.props.loadUnassignedTeams(selectedPhaseId, judge_profile_id).then((result) => {
      this.setState({ teamList: result.payload.teams });
    });
  };

  removeTeam = (id) => {
    this.setState({ teamList: this.state.teamList.filter((t) => t.id !== id) });
  };

  notifyAssignmentError = (message) => (result) => {
    if (result.error) {
      message
        ? this.props.notifyError(message)
        : this.props.notifyError(result.payload.response.error);
    }
    return result.error;
  };

  deleteAssignment = (roleId) => {
    const {
      selectedPhaseId,
      params: { judge_profile_id },
      loadJudgeSuggestions,
    } = this.props;
    this.props
      .removeFromJudge(selectedPhaseId, judge_profile_id, roleId)
      .then(this.notifyAssignmentError())
      .then((error) => {
        !error && loadJudgeSuggestions(selectedPhaseId, judge_profile_id);
        this.loadTeams();
      });
  };

  createAssignment = (roleId) => {
    const { assignToJudge, selectedPhaseId, params } = this.props;
    const { judge_profile_id } = params;
    assignToJudge(selectedPhaseId, judge_profile_id, roleId)
      .then(this.notifyAssignmentError('Can not match team'))
      .then((error) => {
        !error && this.props.loadJudgeAssignments(selectedPhaseId, judge_profile_id);
        this.removeTeam(roleId);
      });
  };

  handleAssignmentBySelect = (val) => {
    if (val.value) this.createAssignment(val.value);
  };

  render() {
    const {
      assigned,
      loading,
      judgeCategories,
      suggestions,
      params: { judge_profile_id },
    } = this.props;
    if (loading) {
      return <Loading />;
    }

    return (
      <Row className="mt-5">
        <Col>
          <Row>
            <Col md={3}>
              <UserCategories groupedCategories={judgeCategories} />
            </Col>
            <Col md={9}>
              <AssignedRolesTable
                list={assigned}
                roleType="Team"
                onRemoveAssignment={this.deleteAssignment}
                thWidths={thWidths}
              />
              {!isTeamLead() && !isNavigator() && (
                <SuggestionsTable
                  list={suggestions}
                  mainRoleId={judge_profile_id}
                  roleType="Team"
                  onAssign={this.createAssignment}
                  thWidths={thWidths}
                />
              )}
            </Col>
          </Row>

          {!isNavigator() && !isTeamLead() && (
            <AssignmentRolesSelect
              list={this.state.teamList}
              placeholder="Match to specific team"
              handleAssignment={this.handleAssignmentBySelect}
            />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const assigned = getTeamsAssignedToCurrentJudge(state, ownProps);
  const loading = isLoadingJudgeAssignments(state);
  const judgeCategories = getJudgeCategories(state, ownProps);
  const suggestions = getTeamSuggestionsForCurrentJudge(state, ownProps);
  const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);

  return {
    loading,
    assigned,
    judgeCategories,
    suggestions,
    selectedPhaseId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    assignToJudge,
    removeFromJudge,
    loadJudgeAssignments,
    loadJudgeSuggestions,
    loadForPhase,
    notifyError,
    loadUnassignedTeams,
  })(EditAssignments),
);
