import { List, Map } from 'immutable';

import emailAddresses from 'email-addresses';
import flattenDeep from 'lodash/flattenDeep';
import { getEnvVar } from 'config';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import moment from 'moment-timezone';
import urlJoin from './url_join';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY [at] HH:mm';
export const TIME_FORMAT = 'hh:mm A';
export const DATE_FORMAT_WITH_TIME = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const getEventDocuments = (event) => event && (event.get('documents') || List());

export const humanize = (str) =>
  str
    .replace(/(^[\s_]+|[\s_]+$)/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, (m) => m.toUpperCase());

export const splitByComma = (values) => values.split(',').map((v) => v.trim());

export const splitBySpecialChars = (values) => {
  const separators = [',', ';', '\n', '\r', '\r\n'];
  return values.split(new RegExp(separators.join('|'))).map((d) => d.trim());
};

// This code converts an email entered as 'Name Last name@last.com'
// into a parsed email returned as 'Name Last <name@last.com>'
export const parseEmailAddress = (email) => {
  const tokens = email.split(/[;,\r\n\t ]+/);
  if (tokens.length > 1) {
    const last_token = tokens[tokens.length - 1];
    const is_token_valid = /^<(.*?)>/.test(last_token);

    if (!is_token_valid) {
      const valid_token = tokens.slice(0, -1).concat(`<${last_token}>`).join(' ');
      return emailAddresses.parseOneAddress({ input: valid_token, rejectTLD: true });
    }
  }
  return emailAddresses.parseOneAddress({ input: email, rejectTLD: true });
};

export const parseContactEmail = (email) => {
  const address = parseEmailAddress(email);
  const names = address.name ? address.name.split(' ') : [];
  const firstName = names.length > 0 ? names[0] : null;
  const lastName = names.length > 1 ? names[1] : null;

  return Map({
    first_name: firstName,
    last_name: lastName,
    email: address.address,
  });
};

export const labelsFromCommaList = (data) => splitByComma(data.get('options'));

export const listToObject = (items, byKey = 'id') =>
  items.reduce((acc, item) => ({ ...acc, [item[byKey]]: item }), {});

export const publicUrlFor = (path) => urlJoin(getEnvVar('REACT_APP_ASSETS_URL'), path);
// REACT_APP_API_URL and REACT_APP_ASSETS_URL refer to URLs that are dynamic per-environment
// and thus has no pre-set default value.

export const baseCdnUrlFor = (path) =>
  urlJoin(getEnvVar('REACT_APP_SERVER_BASE_URL', 'https://app.valideval.com/'), path);
// REACT_APP_SERVER_BASE_URL is static across environments and thus has a default set

const timezone = 'US/Mountain';

export const dateInTimeZone = (value) => {
  const date = moment(value);
  if (!date.isValid()) return '';

  return moment.tz(date, timezone).format('lll z');
};

export const dateFormatted = (date) => moment.tz(date, timezone).format(DATE_FORMAT);

export const dateTimeFormatted = (date) => moment.tz(date, timezone).format(DATE_TIME_FORMAT);

export const relativeTime = (value) => {
  const date = moment.tz(value, timezone);
  if (moment.tz().diff(date, 'hours') > 23) return dateTimeFormatted(value);

  return moment.tz(date, timezone).fromNow();
};

export const currentMomentInTimeZone = () => moment.tz(moment(), timezone);

// From https://github.com/sindresorhus/p-min-delay/blob/master/index.js
// I copy over here to avoid a minify error in CRA

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const pMinDelay = (promise, ms, opts) => {
  opts = Object.assign(
    {
      delayRejection: true,
    },
    opts,
  );

  let promiseErr;

  if (opts.delayRejection) {
    promise = promise.catch((err) => {
      promiseErr = err;
    });
  }

  return Promise.all([promise, delay(ms)]).then((val) =>
    promiseErr ? Promise.reject(promiseErr) : val[0],
  );
};

export const pluralizeRegularNoun = (word) => (amount) => {
  switch (amount) {
    case 0:
      return `No ${word}s`;
    case 1:
      return `${amount} ${word}`;
    default:
      return `${amount} ${word}s`;
  }
};

export const dateToIso = (value) => {
  return moment(value, DATE_FORMAT, true).isValid() ? moment(value).toISOString() : value;
};

export const isoToDate = (value) => {
  if (moment(value, DATE_FORMAT).isValid()) {
    return value;
  }
  return moment(value, DATE_FORMAT).isValid()
    ? moment(value, DATE_FORMAT).format(DATE_FORMAT)
    : value;
};

export const isDate = (value) => {
  return moment(value, DATE_FORMAT, true).isValid() || moment(value, 'YYYY-MM-DD', true).isValid();
};

export const isRunningOnIE = ({ detectEdge = true } = {}) => {
  const ie10 = /MSIE 10/i.test(window.navigator.userAgent);
  const ie9or11 =
    /MSIE 9/i.test(window.navigator.userAgent) || /rv:11.0/i.test(window.navigator.userAgent);
  const edge = /Edge\/\d./i.test(window.navigator.userAgent);

  return ie10 || ie9or11 || (detectEdge && edge);
};

export const isPrinting = () => window?.matchMedia?.('print').matches;

export const isNavigator = () => window.location.pathname.includes('/navigator/');

export const isTeamLead = () => window.location.pathname.includes('/team_lead/');

export const getVersionUri = () =>
  getEnvVar('REACT_APP_GOV_ENV') === '1' ? 'usg.valideval.com' : 'go.valideval.com';

export const getObjectPaths = (obj, parentKey = '') => {
  const paths = Object.keys(obj).map((key) => {
    const value = obj[key];
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (isObject(value) && !isArray(value)) {
      return getObjectPaths(value, newKey);
    } else {
      return newKey;
    }
  });

  return flattenDeep(paths);
};

export const isElementInViewport = (el, offset = 0) => {
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const topInView = rect.top >= 0 && rect.top < windowHeight + offset;
  const bottomInView = rect.bottom > 0 && rect.bottom <= windowHeight + offset;
  const leftInView = rect.left >= 0 && rect.left < windowWidth;
  const rightInView = rect.right > 0 && rect.right <= windowWidth;
  return (topInView || bottomInView) && (leftInView || rightInView);
};
