import { Button, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';

import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import FiltersDropdown from 'screens/app/events/components/filters/FiltersDropdown';
import TextFilter from 'screens/app/events/components/filters/TextFilter';

import { filterByTextFilter } from 'components/Tables/filteringHelpers';
import Styles from './ResultsTable.module.scss';
import { TeamResult } from './types';
import { searchableFields } from './helpers';

type AvailableFilter = {
  title: string;
  field: string;
  type: string;
  options: { label: string; value: number | null }[];
};

const AVAILABLE_FILTERS: AvailableFilter[] = [
  {
    title: 'Decision Status',
    field: 'active',
    type: 'checkbox',
    options: [],
  },
];

type OwnProps = {
  data: TeamResult[];
  expandable: boolean;
  filters: any[];
  finalRank: boolean;
  isExpanded: boolean;
  onCompare: (teamResults: TeamResult[]) => void;
  onExpand: () => void;
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  selectedRows: TeamResult[];
  textFilter: string;
};

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  phase: getSelectedPhase(state, ownProps)?.toJS?.(),
});

const connector = connect(mapStateToProps, null);

type ControlsProps = OwnProps & ConnectedProps<typeof connector>;

const Controls = ({
  data,
  expandable,
  filters,
  finalRank,
  isExpanded,
  onCompare,
  onExpand,
  onOtherFiltersChange,
  onTextFilterChange,
  selectedRows,
  textFilter,
  phase,
}: ControlsProps) => {
  const { t } = useTranslation();

  const availableFilters = useMemo(() => {
    const availableFilters = cloneDeep(AVAILABLE_FILTERS);
    availableFilters[0].options = Object.keys(phase.status_framework).reduce<
      { label: string; value: number | null }[]
    >((acc, key) => {
      const status = phase.status_framework[key];
      if (status.active) {
        const count = data
          ?.filter((row) => filterByTextFilter(textFilter, row, searchableFields))
          .reduce<number>((count, row) => {
            if (row.status === status.value) {
              count++;
            }
            return count;
          }, 0);
        acc.push({
          label: `${status.label} (${count})`,
          value: status.value ?? undefined,
        });
      }
      return acc;
    }, []);

    return availableFilters;
  }, [data, textFilter]);

  return (
    <Row className={'mb-1'}>
      <div className="col flex-1">
        <Button
          variant={'primary'}
          disabled={selectedRows?.length > 3 || selectedRows?.length < 2}
          onClick={() => onCompare(selectedRows)}
          data-test-id="compare-teams-button"
        >
          Compare
        </Button>
        {selectedRows?.length > 3 && (
          <span className="text-muted">&nbsp; Can compare up to 3 teams</span>
        )}
      </div>
      <div className={cx('col-auto d-flex', Styles.DescriptionSwitch)}>
        {expandable && (
          <div className="d-flex align-items-center">
            <span className="me-2">Show description</span>
            <label className={Styles.Switch}>
              <input
                className="checkbox"
                type="checkbox"
                checked={isExpanded}
                onChange={onExpand}
              />
              <span className={'slider'} />
            </label>
          </div>
        )}
      </div>
      <TextFilter
        placeholder={`Search by Prefix, ${t('event.team_profile_name_label')} or Category`}
        search={onTextFilterChange}
      />
      {!finalRank && (
        <FiltersDropdown
          availableFilters={availableFilters}
          onFiltersChange={onOtherFiltersChange}
          filters={filters}
        />
      )}
    </Row>
  );
};

export default withRouter(connector(Controls));
