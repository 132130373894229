import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonControls } from 'components/Tables/CommonControls';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';

import { Team } from '../../../shared/types';
import { searchableFields } from './helpers';

const AVAILABLE_FILTERS = [
  {
    title: 'Matched',
    field: 'active',
    type: 'checkbox',
    options: [
      {
        label: 'Matched',
        value: true,
      },
      {
        label: 'Unmatched',
        value: false,
      },
    ],
  },
];

type ControlsProps = {
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  data: Team[];
  textFilter: string;
  info: string;
};

const Controls = ({
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  data,
  textFilter,
  info,
}: ControlsProps) => {
  const { t } = useTranslation();
  const availableFilters = useMemo(() => {
    const statusCount = data
      ?.filter((row) => filterByTextFilter(textFilter, row, searchableFields))
      .reduce(
        (count, row) => {
          if (row.assignedRoles.length) count.matched += 1;
          else count.notMatched += 1;
          return count;
        },
        {
          matched: 0,
          notMatched: 0,
        },
      );

    const availableFilters = cloneDeep(AVAILABLE_FILTERS);
    availableFilters[0].options[0].label = `Matched (${statusCount?.matched})`;
    availableFilters[0].options[1].label = `Unmatched (${statusCount?.notMatched})`;

    return availableFilters;
  }, [data, textFilter]);

  let appliedFilters = filters.map((item) => {
    if (item.value) {
      item.label = availableFilters[0].options[0].label;
    }
    if (!item.value) {
      item.label = availableFilters[0].options[1].label;
    }
    return item;
  });

  return (
    <CommonControls
      placeholder={`Search by ${t('event.team_profile_name_label', 'Company')}`}
      availableFilters={availableFilters}
      appliedFilters={appliedFilters}
      filters={filters}
      onOtherFiltersChange={onOtherFiltersChange}
      onTextFilterChange={onTextFilterChange}
      info={info}
    />
  );
};

export default Controls;
