import { teamNav } from '../urls';

export const displayName = 'Feedback available';

export const getActionButtons = (notification) => [
  {
    title: 'View Feedback',
    url: teamNav.results(notification.data.get('event_id'), notification.data.get('team_id')),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Feedback available for the team ${notification.data.get(
    'team_name',
  )} for phase ${notification.data.get('phase_name')} in ${notification.data.get(
    'cohort_name',
  )} cohort`;
