import { apiHeaders, apiURL } from '../services/config';
import { get, post, put } from './config';

export const load = (eventId) => get(`/events/${eventId}/phases`).then((payload) => payload.phases);

export const loadSingle = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${id}`),
});

export const loadStatuses = (phaseId) =>
  get(`/phases/${phaseId}/statuses`).then((payload) => payload.statuses);

export const update = (phaseId, phase) =>
  put(`/phases/${phaseId}`, { phase }).then((payload) => payload.phase);

export const clearAllMatches = (phaseId) => post(`/phases/${phaseId}/clear_all_matches`);

export const scheduleAutomatch = (phaseId, payload) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify(payload),
  endpoint: apiURL(`/phases/${phaseId}/schedule_automatch`),
});

export const judges = (phaseId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles`),
});

export const teams = (phaseId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phases/${phaseId}/teams`),
});

export const automatchInProgress = (phaseId, token) =>
  get(`/phases/${phaseId}/automatch_in_progress`, token);

export const loadSummary = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/phase_summaries/${id}`),
});
