import '@valid-eval/shared-react-components/dist/style.css';
import 'core-js/stable';
import 'element-closest-polyfill';
import 'fast-text-encoding';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import CacheBuster from 'components/CacheBuster';
import FetchInterceptor from 'components/FetchInterceptor';
import LocaleLoader from 'components/LocaleLoader';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import configureStore from 'store/configureStore';

import 'components/Settings/main.scss';

const store = configureStore({ preloadState: undefined });

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <FetchInterceptor>
        <LocaleLoader>
          <CacheBuster>
            <Routes />
          </CacheBuster>
        </LocaleLoader>
      </FetchInterceptor>
    </BrowserRouter>
  </Provider>,
);
