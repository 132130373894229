import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { load as loadArtifactItems } from 'data/actions/artifactItems';
import {
  LOAD_RESULT,
  actions,
  load,
  loadForPhase,
  loadForPhaseAndTeam,
  loadSuccess,
  reverseUpdate,
} from 'data/actions/evaluations';
import { error as notifyError } from 'data/actions/notifications';
import { load as loadPhaseSummary } from 'data/actions/phase_summaries';
import { load as loadRubric } from 'data/actions/rubrics';
import { fetchScoreForPhase, load as loadTeam } from 'data/actions/teams';
import {
  getEvaluation,
  getEvaluationByPhaseAndTeam,
  getPhase,
  getTeamById,
} from 'data/reducers/index';
import { update } from 'data/services/evaluations';

import { loadForEvaluation } from '../actions/scores';

const TEAMS_INFO_ONLY = true;

function* performUpdateStatus(action) {
  const beforeUpdate = yield select((state) => getEvaluation(state, action.payload.id));
  try {
    const evaluation = yield call(update, action.payload.id, {
      id: action.payload.id, // Remove this when using a real api
      status: action.payload.status,
    });
    yield put(loadSuccess(evaluation));
  } catch (e) {
    yield put(reverseUpdate(action.payload.id, beforeUpdate.get('status')));
  }
}

function* performChangeEvaluation(action) {
  try {
    const { teamId, phaseId, eventId, isOrganizer, location, navigate } = action.payload;
    let evaluationId = null;
    const cachedEvaluation = yield select((state) =>
      getEvaluationByPhaseAndTeam(state, phaseId, teamId),
    );
    // Fetch again the evaluation if judges_score is missing
    if (cachedEvaluation && cachedEvaluation.get('judges_score')) {
      evaluationId = cachedEvaluation.get('id');
    } else {
      yield put(loadForPhaseAndTeam(phaseId, teamId));
      const result = yield take(LOAD_RESULT);
      if (result.payload && result.payload.evaluation) {
        evaluationId = result.payload.evaluation.id;
      }
    }

    if (evaluationId) yield put(loadForEvaluation(evaluationId));

    if (isOrganizer) {
      if (evaluationId) {
        let currentLocation = location.pathname + location.search;
        let newLocation = `/app/events/${eventId}/results/${evaluationId}`;
        if (!currentLocation.includes('/score/')) {
          newLocation = currentLocation.replace(/results\/([a-z0-9-]*)/, `results/${evaluationId}`);
        }
        navigate(newLocation);
      }
    }

    let effects = [call(loadDataForResultDetails, phaseId, teamId)];
    if (isOrganizer) {
      effects.push(put(loadForPhase(phaseId, TEAMS_INFO_ONLY)));
    } else {
      effects.push(call(loadTeamForResults, String(teamId)));
    }

    yield all(effects);
  } catch {
    yield put(notifyError('Can not change team'));
  }
}

function* loadTeamForResults(teamId) {
  const team = yield select((state) => getTeamById(state, teamId));
  if (!team) {
    yield put(loadTeam(teamId));
  }
}

function* performInitOrganizerResultDetails(action) {
  yield put(load(action.payload.evaluationId));
  yield put(loadForPhase(action.payload.phaseId, TEAMS_INFO_ONLY));
  const result = yield take(LOAD_RESULT);
  if (result.error) {
    return;
  }

  yield put(loadForEvaluation(action.payload.evaluationId));

  const {
    evaluation: { team },
  } = result.payload;
  yield all([call(loadDataForResultDetails, action.payload.phaseId, team)]);
}

const USE_CACHED_RUBRIC = true;

function* loadDataForResultDetails(phaseId, teamId) {
  try {
    yield put(loadRubric(phaseId, USE_CACHED_RUBRIC));

    const phase = yield select((state) => getPhase(state, phaseId));
    yield all([
      put(loadArtifactItems(teamId)),
      put(loadPhaseSummary(phase.get('phase_summary_id'))),
      put(fetchScoreForPhase(teamId, phaseId)),
    ]);
  } catch (e) {
    if (e.status !== 401) {
      yield put(notifyError('Can not load results'));
    }
  }
}

function* watchUpdateStatus() {
  yield takeEvery(actions.UPDATE, performUpdateStatus);
}

function* watchChangeEvaluation() {
  yield takeEvery(actions.SHOW_EVALUATION_FOR_TEAM, performChangeEvaluation);
}

function* watchInitializeResultDetailsForOrganizers() {
  yield takeEvery(actions.INIT_ORGANIZER_RESULT_DETAILS, performInitOrganizerResultDetails);
}

const saga = function* () {
  yield all([
    fork(watchUpdateStatus),
    fork(watchChangeEvaluation),
    fork(watchInitializeResultDetailsForOrganizers),
  ]);
};

export default saga;
