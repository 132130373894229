import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import Table from 'components/Tables/Table';
import { filterResults } from 'components/Tables/filteringHelpers';
import { FiltersState } from 'components/Tables/types';
import { isNavigator } from 'utils';

import { Team } from '../../shared/types';
import Controls from './components/Controls';
import { columns } from './components/Definition';

type TeamsAddTableProps = {
  data: Team[];
  onTeamSelectionChange: (teams: Team[]) => void;
  selectedTeams: Team[];
  isPanelMatching: boolean;
};

const searchableFields = ['name', 'panel', 'prefix', 'categories'];

const TeamsAddTable = ({
  data,
  onTeamSelectionChange,
  selectedTeams,
  isPanelMatching,
}: TeamsAddTableProps) => {
  const [globalFilter, setGlobalFilter] = useState<FiltersState>({
    textFilter: '',
    otherFilters: [],
  });
  const [rowSelection, setRowSelection] = useState({});

  // Set selection for external component
  useEffect(() => {
    const selectedRows = Object.keys(rowSelection).map((key) => (data as any)[key]);
    onTeamSelectionChange(selectedRows);
  }, [rowSelection]);

  // If external selection is cleared out
  useEffect(() => {
    if (selectedTeams.length === 0 && selectedTeams.length !== Object.keys(rowSelection).length) {
      setRowSelection({});
    }
  }, [selectedTeams]);

  // Table Filtering
  function handleTextFilterChange(textFilter: string) {
    setGlobalFilter({ ...globalFilter, textFilter });
  }

  const tableModel = useReactTable({
    data,
    columns: columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection(row) {
      return !row.original.teamScores;
    },
    state: {
      globalFilter,
      rowSelection,
      columnVisibility: {
        Id: !isNavigator(),
        Panel: isPanelMatching,
        Prefix: !isPanelMatching,
        'Team Name': true,
        'Match Quality': true,
        '# Judges': true,
      },
    },
    globalFilterFn: filterResults(searchableFields),
  });

  return (
    <>
      <Controls
        onTextFilterChange={handleTextFilterChange}
        info={`${data.length} Team participants`}
      />
      <Table tableModel={tableModel} />
    </>
  );
};

export default TeamsAddTable;
