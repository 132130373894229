import { Button, ButtonGroup } from '@valid-eval/shared-react-components';
import LinkContainer from 'components/LinkContainer';
import { useLocation, useParams } from 'react-router-dom';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

export const editAssignmentsAsOrganizerFromURLParams = (urlParams) => {
  const { event_id, team_id, judge_profile_id } = urlParams;
  if (team_id) return organizerNav.editAssignments(event_id, 'teams', team_id);
  else if (judge_profile_id)
    return organizerNav.editAssignments(event_id, 'judges', judge_profile_id);
  else throw new Error('Unknow url params');
};

export const editProfileAsOrganizerFromURLParams = (urlParams) => {
  const { event_id, team_id, judge_profile_id } = urlParams;
  if (team_id) return organizerNav.profile(event_id, 'teams', team_id);
  else if (judge_profile_id) return organizerNav.profile(event_id, 'judges', judge_profile_id);
  else throw new Error('Unknow url params');
};

export const editMembersAsOrganizerFromURLParams = (urlParams) => {
  const { event_id, team_id } = urlParams;
  return organizerNav.members(event_id, 'teams', team_id);
};

const EditAssignmentsNavigation = () => {
  const location = useLocation();
  const urlParams = useParams();

  return (
    <ButtonGroup aria-label="Results section">
      <LinkContainer to={editAssignmentsAsOrganizerFromURLParams(urlParams)}>
        <Button
          className="text-nowrap"
          variant={
            editAssignmentsAsOrganizerFromURLParams(urlParams).includes(location?.pathname)
              ? 'primary'
              : 'default'
          }
        >
          {isNavigator() || isTeamLead() ? 'Matches' : 'Edit Matches'}
        </Button>
      </LinkContainer>
      <LinkContainer to={editProfileAsOrganizerFromURLParams(urlParams)}>
        <Button
          className="text-nowrap"
          variant={
            editProfileAsOrganizerFromURLParams(urlParams).includes(location?.pathname)
              ? 'primary'
              : 'default'
          }
        >
          {isNavigator() || isTeamLead() ? 'Profile' : 'Edit Profile'}
        </Button>
      </LinkContainer>
      {!!urlParams.team_id && (
        <LinkContainer to={editMembersAsOrganizerFromURLParams(urlParams)}>
          <Button
            className="text-nowrap"
            variant={
              editMembersAsOrganizerFromURLParams(urlParams).includes(location?.pathname)
                ? 'primary'
                : 'default'
            }
          >
            {isNavigator() || isTeamLead() ? 'Members' : 'Edit Members'}
          </Button>
        </LinkContainer>
      )}
    </ButtonGroup>
  );
};

export default EditAssignmentsNavigation;
