import { Button, Modal } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { organizerNav } from 'utils/urls';
import { loadForJudgeAndPhase } from 'data/actions/scores';
import DimensionBoxPlot from '../DimensionBoxPlot';
import { getRangeBasedClassName } from '../helpers';
import Section from '../Section';
import Styles from './JudgeScoresModal.module.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function JudgeScoresModal({
  isOpen,
  judge,
  onHide,
  eventId,
  teamAId,
  teamBId,
  teamCId,
  loadForJudgeAndPhase,
}) {
  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && judge) {
      setIsLoading(true);
      setScores([]);
      (async () => {
        try {
          const { payload } = await loadForJudgeAndPhase(judge.phase, judge.id);
          setScores(payload);
          setIsLoading(false);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [isOpen, judge]);

  const min = scores.slice().shift();
  const max = scores.slice().pop();
  const isCompare = !!(teamAId || teamBId || teamCId);

  return (
    <Modal
      show={isOpen}
      backdrop={true}
      onHide={onHide}
      dialogClassName="modal-lg"
      data-test-id="judge-scores-modal"
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h2 className={Styles.Title}>Implicit Rank by {judge?.name}</h2>
          <Button variant={'link'} className={cx('p-0', Styles.Close)} onClick={onHide}>
            <i className="fa-regular fa-circle-xmark fa-lg" />
          </Button>
        </div>
        {isLoading && <Loading />}
        {!isLoading && (
          <Section>
            {scores.map((score, index) => (
              <div
                className={cx('d-flex flex-wrap align-items-center mb-2', {
                  [Styles.IsCompare]: isCompare,
                  'ps-2': !isCompare,
                  [Styles.SelectedTeam]: score?.team?.id === judge?.team,
                })}
                key={`score-by-judge-${index}`}
              >
                <div className={Styles.TeamMarker}>
                  {score?.team?.id === teamAId && (
                    <img src={DimensionBoxPlot.MarkerImageA} alt="Team A marker" />
                  )}
                  {score?.team?.id === teamBId && (
                    <img src={DimensionBoxPlot.MarkerImageB} alt="Team B marker" />
                  )}
                  {score?.team?.id === teamCId && (
                    <img src={DimensionBoxPlot.MarkerImageC} alt="Team C marker" />
                  )}
                </div>
                <div
                  className={cx(
                    Styles.Score,
                    getRangeBasedClassName(
                      max?.score?.valid_score_implicit_rank,
                      min?.score?.valid_score_implicit_rank,
                      score?.score?.valid_score_implicit_rank,
                      true,
                    ),
                  )}
                >
                  {score?.score?.valid_score_implicit_rank || 'N/A'}
                </div>
                <div className={cx('text-truncate', Styles.JudgeName)}>
                  <Link
                    className="p-0"
                    to={organizerNav.profile(eventId, 'teams', score?.team?.id)}
                  >
                    {score?.team?.name}
                  </Link>
                </div>
              </div>
            ))}
          </Section>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default connect(() => ({}), { loadForJudgeAndPhase })(JudgeScoresModal);
