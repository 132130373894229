import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load } from 'data/actions/roleSummaries';
import {
  getActiveTeamCount,
  getEventFromURL,
  getInactiveTeamCount,
  getSelectedPhase,
  getSelectedPhaseIdForCurrentEvent,
  getTeamSummaries,
  isLoadingRoleSummaries,
  isLoadingTeamRoles,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';

import TeamAssignmentsToolbar from './components/TeamAssignmentsToolbar';
import { TeamsAssigmentsTable } from './components/TeamsAssigmentsTable';
import TeamsLayout from './components/TeamsLayout';

const ROLE = 'teams';

const TeamAssignments = ({
  selectedPhaseId,
  loading,
  teamsLoading,
  teams,
  activeCount,
  inactiveCount,
  params,
  selectedPhase,
  load,
}) => {
  const loadTeams = () => {
    load(ROLE, selectedPhaseId);
  };

  useEffect(() => {
    loadTeams();
  }, [selectedPhaseId, load]);

  const [minTeamsPerJudge, minJudgesPerTeam] = useMemo(() => {
    let minTeamsPerJudge = 0;
    let minJudgesPerTeam = 0;

    if (teams.length >= 0) {
      minTeamsPerJudge = teams.reduce((acc, j) => Math.min(j.assignedRoles?.length, acc), Infinity);

      const judges = teams
        .map((t) => t.assignedRoles)
        .flat()
        .reduce((acc, p) => {
          acc[p.profileId] = (acc[p.profileId] || 0) + 1;
          return acc;
        }, {});

      minJudgesPerTeam = Math.min(...Object.values(judges));
    }

    return [minTeamsPerJudge, minJudgesPerTeam];
  }, [teams]);

  return (
    <TeamsLayout
      toolbar={
        isNavigator() ? null : (
          <TeamAssignmentsToolbar
            reload={loadTeams}
            minTeamsPerJudge={minTeamsPerJudge}
            minJudgesPerTeam={minJudgesPerTeam}
          />
        )
      }
    >
      {loading || teamsLoading ? (
        <Loading />
      ) : (
        <TeamsAssigmentsTable
          data={teams}
          eventId={params.event_id}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          teamsLoading={teamsLoading}
          isPanelMatching={selectedPhase?.matching_mode === 'panel_matching'}
        />
      )}
    </TeamsLayout>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEventFromURL(state, ownProps)?.toJS?.();
      const teams = getTeamSummaries(state, ownProps);
      const activeCount = getActiveTeamCount(state, ownProps);
      const inactiveCount = getInactiveTeamCount(state, ownProps);
      const loading = isLoadingRoleSummaries(state);
      const teamsLoading = isLoadingTeamRoles(state);
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const selectedPhase = getSelectedPhase(state, ownProps)?.toJS?.();
      return {
        event,
        teams,
        activeCount,
        inactiveCount,
        loading,
        teamsLoading,
        selectedPhaseId,
        selectedPhase,
      };
    },
    {
      load,
      notifyError,
      notifySuccess,
    },
  )(TeamAssignments),
);
