import Title from 'components/Title';
import styles from './ProfileTitle.module.scss';

const ProfileTitle = ({ title, subTitle }) => (
  <div className="clearfix">
    <h1>{title}</h1>
    <Title component="h2" className={styles.subTitle}>
      {subTitle}
    </Title>
  </div>
);

export default ProfileTitle;
