import { getCurrentUser } from 'data/reducers';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { getVersionUri } from 'utils';
import ErrorBoundary from './ErrorBoundary';
import Briefcase from './assets/briefcase.png';
import SignUpLogin from './components/SignUpLogin';
import TeamMemberAcceptInvitationConfirmation from './components/TeamMemberAcceptInvitationConfirmation';

const TeamMembersAcceptInvitation = ({ user, query }) => {
  return (
    <ErrorBoundary>
      <SignUpLogin />
      {!!user && (
        <div className="container">
          <div className="login-container mb-5">
            <h1 className="login-title mt-3 mb-3">Accept invitation</h1>
            <div className="login-box d-flex flex-column">
              <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
              <TeamMemberAcceptInvitationConfirmation />
              <div className="text-center mt-4 mb-2">
                <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
              </div>
            </div>
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

TeamMembersAcceptInvitation.displayName = 'TeamMembersAcceptInvitation';

export default withRouter(
  connect((state) => ({ user: getCurrentUser(state)?.toJS?.() }), {})(TeamMembersAcceptInvitation),
);
