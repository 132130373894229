import { Col, Row } from '@valid-eval/shared-react-components';
import { Component } from 'react';
import { getCurrentJudgeMiniProfile, getJudgeProfileFromURL } from 'data/reducers';

import Navigation from '../events/components/EditAssignmentsNavigation';
import ProfileTitle from 'components/ProfileTitle';
import UserExperienceInfo from './components/UserExperienceInfo';
import { connect } from 'react-redux';
import { load as loadJudge } from 'data/actions/judgeProfiles';
import withRouter from 'routes/withRouter';

const judgeInfoStyles = { marginTop: '30px', marginBottom: '30px' };

class EditWrapper extends Component {
  componentDidMount() {
    this.props.loadJudge(this.props.params.judge_profile_id);
  }

  render() {
    const { fullName, email, judge } = this.props;
    return (
      <Row>
        <Col md={12} className={'position-relative'}>
          <Row style={judgeInfoStyles}>
            <Col md={5}>
              <ProfileTitle title={fullName} subTitle={email} />
            </Col>
            <Col md={7}>
              <UserExperienceInfo experiences={judge} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Navigation urlParams={this.props.params} />
            </Col>
          </Row>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const { fullName, email } = getCurrentJudgeMiniProfile(state, ownProps);
      return {
        fullName,
        email,
        judge: getJudgeProfileFromURL(state, ownProps),
      };
    },
    { loadJudge },
  )(EditWrapper),
);
