import { Form, InputGroup } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import moment from 'moment-timezone';
import { Controller } from 'react-hook-form';
// @ts-expect-error: no types available
import DateTime from 'react-datetime/dist/react-datetime.umd'; // Vite and Rollup doesn't play well with cjs modules

import {
  required as requiredVal,
  validDate,
  validDateWithTime,
} from 'components/FormFields/validations';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME, TIME_FORMAT } from 'utils';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import useFormFieldState from './useFormFieldState';

export type DateProps = {
  after?: React.ReactNode;
  before?: React.ReactNode;
  id?: string;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: { validate?: Record<string, any> };
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  isDateTime?: boolean;
  utc?: boolean;
};

const Date = ({
  after,
  before,
  id,
  label,
  name,
  placeholder,
  rules,
  disabled,
  required,
  helpText,
  isDateTime = false,
  utc = true,
}: DateProps) => {
  const { control, error, isInvalid, validationClassname } = useFormFieldState(name);

  rules = rules || {};
  rules = {
    ...rules,
    validate: {
      ...(required ? { required: requiredVal } : {}),
      ...(isDateTime ? { validDateWithTime } : { validDate }),
      ...rules.validate,
    },
  };

  const transformDate = (value: moment.Moment | string) => {
    const format = isDateTime ? DATE_FORMAT_WITH_TIME : DATE_FORMAT;

    // @ts-expect-error: _i prop is not typed
    const stringValue = typeof value === 'string' ? value : value._i || value.format(format);
    const isValidString = stringValue?.length === (isDateTime ? 19 : 10);
    const currentMoment = isValidString ? moment(value, format) : moment(value);

    if (currentMoment.isValid() && isValidString) {
      return currentMoment.format(format);
    } else if (moment.isMoment(value) && value.isValid() && isValidString) {
      return value.format(format);
    } else {
      return stringValue;
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, name, ...fieldProps } }) => {
          return (
            <Form.Group className="mb-2">
              <Label htmlFor={id || name} label={label} />
              <HelpText text={helpText} />
              <InputGroup hasValidation>
                {before}
                <DateTime
                  className={cx('flex-grow-1', validationClassname)}
                  closeOnSelect={!isDateTime}
                  dateFormat={DATE_FORMAT}
                  timeFormat={isDateTime ? TIME_FORMAT : false}
                  input={true}
                  onChange={(newValue: moment.Moment | string) => onChange(transformDate(newValue))}
                  value={value ?? ''}
                  utc={utc}
                  inputProps={{
                    id: id || name,
                    autoComplete: 'off',
                    disabled: disabled,
                    className: cx('form-control', validationClassname),
                    placeholder: placeholder,
                    style: { minWidth: 210 },
                    value: value ?? '',
                    ...fieldProps,
                  }}
                />
                {after}
                <FieldError show={isInvalid} message={String(error?.message)} />
              </InputGroup>
            </Form.Group>
          );
        }}
      />
    </>
  );
};

export default Date;
