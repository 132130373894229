/* eslint no-mixed-operators: 0 */
import cx from 'classnames';
import { useRef } from 'react';
import { Field } from 'redux-form/immutable';

import Styles from './Toggle.module.scss';
import { dynamicFieldValidation } from './utils';

const ToggleComponent = ({ input, disabled, lg }) => (
  <>
    <label className={cx(Styles.switch, lg && Styles.switchLg)} name={'toggle-switch'}>
      <input
        {...input}
        type="checkbox"
        className={Styles.checkbox}
        disabled={disabled}
        checked={input.value}
      />
      <span className={cx(Styles.slider, lg && Styles.sliderLg)} />
    </label>
  </>
);

export const Toggle = ({ id, name, title, helpText, disabled, validate, lg }) => {
  const component = useRef(ToggleComponent);
  return (
    <Field
      id={id || name}
      name={name}
      label={title}
      disabled={disabled}
      helpText={helpText}
      component={component.current}
      validate={validate || dynamicFieldValidation}
      lg={lg}
    />
  );
};

export { ToggleComponent };
