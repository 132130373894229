import React from 'react';
import { isNavigator, isTeamLead } from 'utils';
import Members from '../teams/Members';

export default function OrganizerEditMembers(props) {
  return (
    <Members
      {...props}
      isOrganizer={!isNavigator() && !isTeamLead()}
      isNavigator={isNavigator()}
      isTeamLead={isTeamLead()}
    />
  );
}
