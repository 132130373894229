import { Col, Container, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import { load as loadEvent } from 'data/actions/events';
import { changeSelectedPhase, loadForEvent as loadPhases, loadStatus } from 'data/actions/phases';
import { setCurrentRole, unsetCurrentRole } from 'data/actions/support';
import {
  getEvent,
  getEventPhasesList,
  getResultsStatus,
  getRolesInEvent,
  getSelectedPhaseId,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { organizerNav } from 'utils/urls';

import { Navigate } from 'react-router';
import ErrorBoundary from '../../ErrorBoundary';
import Navigation from './components/Navigation';
import Styles from './Wrapper.module.scss';

const Wrapper = ({
  changeSelectedPhase,
  children,
  event,
  loadEvent,
  loading,
  loadPhases,
  loadStatus,
  location,
  params,
  navigate,
  phases,
  selectedPhaseId,
  setCurrentRole,
  unsetCurrentRole,
  roles,
}) => {
  useEffect(() => {
    loadEvent(params.event_id);
    loadPhases(params.event_id);
    setCurrentRole({ role: 'organizer', eventId: params.event_id });

    return () => unsetCurrentRole();
  }, []);

  useEffect(() => {
    if (selectedPhaseId) loadStatus(selectedPhaseId);
  }, [selectedPhaseId]);

  const handlePhaseChange = (phase) => {
    // If it's a draft phase, we don't want to change the selected phase
    if (phase.id === -1) {
      navigate(organizerNav.teamDraft(params.event_id));
    } else {
      changeSelectedPhase(params.event_id, phase.id);
      // if the user is on the draft section, we want to redirect them to the status section
      if (location.pathname === organizerNav.teamDraft(params.event_id)) {
        navigate(organizerNav.status(params.event_id));
      }
    }
  };

  const noPhaseAvailable = selectedPhaseId == null;

  if (loading) return <Loading />;

  // Redirect to the correct role if the user is not authorized
  let roleSegment;
  if (roles.Organizer) {
    roleSegment = '';
  } else if (roles.Navigator) {
    roleSegment = 'navigator/';
  } else {
    roleSegment = 'team_lead/';
  }

  if (
    (!params.role && !roles.Organizer) ||
    (params.role === 'navigator' && !roles.Navigator) ||
    (params.role === 'team_lead' && !roles.TeamLead)
  ) {
    let to =
      location.pathname.replace(/\/app\/(\w+\/)?events\//, `/app/${roleSegment}events/`) +
      location.search +
      location.hash;

    return <Navigate to={to} state={location.state} replace />;
  }

  return (
    <ErrorBoundary>
      <Container>
        <Row className={cx(Styles.MainRow, 'ms-0 me-0')}>
          <Col md={12}>
            <Navigation
              event={event}
              active={location.pathname}
              phases={phases}
              selectedPhaseId={selectedPhaseId}
              onPhaseChange={handlePhaseChange}
            />
          </Col>
          <Col md={12}>{noPhaseAvailable ? <Loading /> : children}</Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEvent(state, ownProps.params.event_id);
      const phases = getEventPhasesList(state, ownProps.params.event_id);
      const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
      const resultsStatus = selectedPhaseId != null && getResultsStatus(state, selectedPhaseId);
      const roles = getRolesInEvent(state, ownProps);

      return {
        event,
        phases,
        selectedPhaseId,
        roles,
        disableResults: resultsStatus && resultsStatus.get('evaluations_completed') === 0,
        loading: !event,
      };
    },
    {
      loadEvent,
      loadPhases,
      changeSelectedPhase,
      setCurrentRole,
      unsetCurrentRole,
      loadStatus,
    },
  )(Wrapper),
);
