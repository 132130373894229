import { Alert, Col, Row } from '@valid-eval/shared-react-components';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Loading from 'components/Loading';
import Artifacts from 'components/Teams/Artifacts';
import ProfileForm from 'components/Teams/ProfileForm';
import Title from 'components/Title';
import { getEnvVar } from 'config';
import {
  load as loadArtifactItems,
  saveFile as saveArtifactFile,
  save as saveArtifactUrl,
} from 'data/actions/artifactItems';
import { load as loadArtifacts } from 'data/actions/artifacts';
import { load as loadEvent } from 'data/actions/events';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load as loadTeam, update } from 'data/actions/teams';
import { load as loadUser } from 'data/actions/users';
import {
  fetchApplicationRevisionRequest,
  fromApplicationRevisionRequests,
} from 'data/features/applicationRevisionRequests';
import {
  getEventFromURL,
  getIsLoadingArtifacts,
  getTeamCanEditProfile,
  getTeamDocumentsForEvent,
  getTeamPrimaryContact,
  getTeamProfileDynamicFieldsForCurrentEvent,
  getTeamProfileInitialValues,
  getTeamProfileOrphanDetails,
  userIsTeamEditorOrPrimary,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { teamNav } from 'utils/urls';

import styles from './Profile.module.scss';
import ProfileNavigation from './components/ProfileNavigation';

const Profile = ({
  loadUser,
  loadEvent,
  loadTeam,
  loadArtifacts,
  loadArtifactItems,
  update,
  notifySuccess,
  notifyError,
  navigate,
  saveArtifactUrl,
  saveArtifactFile,
  isTeamMemberEditor,
  canEditProfile,
  dynamicFields,
  primaryContact,
  teamInitialValues,
  artifacts,
  loadingArtifacts,
  orphanDetails,
  params: { event_id, team_id },
  fetchApplicationRevisionRequests,
  applicationRevisionRequest,
  applicationRevisionRequestLastCanceledDate,
  applicationRevisionRequestLastExpiredDate,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    loadUser('me');
    loadTeam(team_id);
    loadArtifacts(event_id);
    loadArtifactItems(team_id);
    const loadEventInterval = setInterval(() => loadEvent(event_id), 10e3);
    return () => clearInterval(loadEventInterval);
  }, []);

  // Request for application revision
  useEffect(() => {
    if (!canEditProfile) fetchApplicationRevisionRequests(team_id);
  }, [canEditProfile]);

  const handleSave = (values) => {
    update(event_id, team_id, values)
      .then(({ error, payload }) => {
        if (error) {
          let msg = 'Can not update the profile';
          if (payload.response?.error)
            msg =
              typeof payload.response?.error === 'string'
                ? payload.response.error
                : Object.values(payload.response.error).join(', ');
          notifyError(msg);
          return;
        }

        notifySuccess('All changes saved');
        navigate(teamNav.results(event_id, team_id));
      })
      .catch((error) => {
        // Do nothing with the error
      });
  };

  const handleSubmitArtifactUrl = (name) => async (values) => {
    const email = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');
    const { error } = await saveArtifactUrl(team_id, event_id, values);
    if (error)
      notifyError(
        `Your ${name} could not be updated, please contact Valid Eval tech support at ${email} for assistance.`,
      );
    else notifySuccess('All changes saved');
  };

  const handleSubmitArtifactFile = (values) => {
    saveArtifactFile(team_id, event_id, values);
  };

  const hasTeam = !!teamInitialValues;

  let disabledMessage;
  if (applicationRevisionRequestLastCanceledDate && !applicationRevisionRequestLastExpiredDate) {
    disabledMessage = t('application_revision_request.error.canceled');
  } else if (applicationRevisionRequestLastExpiredDate) {
    disabledMessage = t('application_revision_request.error.expired');
  } else if (
    applicationRevisionRequestLastCanceledDate &&
    applicationRevisionRequestLastExpiredDate
  ) {
    disabledMessage = moment(applicationRevisionRequestLastCanceledDate).isAfter(
      applicationRevisionRequestLastExpiredDate,
    )
      ? t('application_revision_request.error.canceled')
      : t('application_revision_request.error.expired');
  } else {
    disabledMessage = 'Profile editing for this event has been disabled by its Organizer(s)';
  }

  return (
    <>
      <ProfileNavigation />
      {!canEditProfile && !applicationRevisionRequest ? (
        <Alert variant="warning" className={styles.alert}>
          <i className="fa-solid fa-triangle-exclamation fa-lg"></i> &nbsp; &nbsp; {disabledMessage}
        </Alert>
      ) : (
        <Title component="h1" className="mb-4">
          Submission Profile
        </Title>
      )}
      <Row className="pt-2">
        <Col md={7}>
          {hasTeam ? (
            <div className={styles.negMargin}>
              <ProfileForm
                disabled={!canEditProfile || !isTeamMemberEditor}
                dynamicFields={dynamicFields}
                initialValues={teamInitialValues}
                orphanDetails={orphanDetails}
                onSubmit={handleSave}
                applicationRevisionRequest={applicationRevisionRequest}
              />
            </div>
          ) : (
            <Loading />
          )}
        </Col>
        <Col md={1} />
        <Col className={'well'} md={4}>
          <h2>Primary Contact</h2>
          <p className={styles.contact}> {primaryContact && primaryContact.get('fullName')}</p>
          {primaryContact && (
            <a className={styles.email} href={`mailto:${primaryContact.get('email')}`}>
              {primaryContact.get('email')}
            </a>
          )}
          <br />
          <br />

          <Title className={classNames(styles.padding, styles.negMargin)} component="h2">
            Materials Submitted for Evaluation
          </Title>

          {!loadingArtifacts ? (
            <Artifacts
              eventId={event_id}
              teamId={team_id}
              disabled={!canEditProfile || !isTeamMemberEditor}
              artifacts={artifacts}
              onDropFile={handleSubmitArtifactFile}
              onSubmit={handleSubmitArtifactUrl}
              applicationRevisionRequest={applicationRevisionRequest}
              disableDeleteIfRequired
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        applicationRevisionRequestLastCanceledDate:
          fromApplicationRevisionRequests.getLastCanceledDate(state?.toJS()),
        applicationRevisionRequestLastExpiredDate:
          fromApplicationRevisionRequests.getLastExpiredDate(state?.toJS()),
        applicationRevisionRequest: fromApplicationRevisionRequests.getByTeamId(
          state?.toJS(),
          ownProps.params.team_id,
        ),
        event: getEventFromURL(state, ownProps)?.toJS?.(),
        primaryContact: getTeamPrimaryContact(state, ownProps),
        dynamicFields: getTeamProfileDynamicFieldsForCurrentEvent(state, ownProps),
        teamInitialValues: getTeamProfileInitialValues(state, ownProps),
        artifacts: getTeamDocumentsForEvent(state, ownProps),
        loadingArtifacts: getIsLoadingArtifacts(state, ownProps),
        canEditProfile: getTeamCanEditProfile(state, ownProps),
        orphanDetails: getTeamProfileOrphanDetails(state, ownProps),
        isTeamMemberEditor: userIsTeamEditorOrPrimary(state, ownProps),
      };
    },
    {
      loadUser,
      loadTeam,
      update,
      loadEvent,
      loadArtifacts,
      loadArtifactItems,
      saveArtifactUrl,
      saveArtifactFile,
      notifySuccess,
      notifyError,
      fetchApplicationRevisionRequests: fetchApplicationRevisionRequest,
    },
  )(Profile),
);
