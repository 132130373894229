import { List, fromJS } from 'immutable';

import { LOAD_RESULT } from 'data/actions/documents';
import { handleActions } from 'redux-actions';

const initialState = fromJS({ data: {} });

export default handleActions(
  {
    [LOAD_RESULT]: (state, action) => {
      return state.setIn(
        ['data', 'by_event', action.payload.event_id],
        fromJS(action.payload.documents),
      );
    },
  },
  initialState,
);

const docType = (document) => {
  const extension = document.get('name').split('.').pop();

  switch (extension) {
    case 'rtf':
    case 'docx':
      return 'doc';
    case 'mp4':
    case 'mov':
      return 'video';
    case 'pptx':
      return 'ppt';
    case 'xlsx':
      return 'xls';
    default:
      return extension;
  }
};

const getActiveDocuments = (state, eventId, recipient) =>
  state
    .getIn(['data', 'by_event', eventId], List())
    .filter(
      (document) =>
        document.get('active') &&
        (document.get('recipient') === recipient ||
          document.get('recipient') === 'teams_and_judges'),
    )
    .map((document) => document.set('file_type', docType(document)));

export const getActiveDocumentsForJudges = (state, eventId) =>
  getActiveDocuments(state, eventId, 'judges');

export const getActiveDocumentsForTeams = (state, eventId) =>
  getActiveDocuments(state, eventId, 'teams');
