import { Table as TableType, flexRender } from '@tanstack/react-table';
import React from 'react';
import cx from 'classnames';

import {
  TableProps as RTableProps,
  Table as TableComponent,
} from '@valid-eval/shared-react-components';

import Row from './Row';

type TableProps = RTableProps & {
  tableModel: TableType<any>;
  emptyContent?: React.ReactNode;
  fullWidthHeader?: string;
};

const Table = ({
  tableModel,
  emptyContent,
  fullWidthHeader,
  className,
  responsive = true,
  ...props
}: TableProps) => {
  return (
    <TableComponent {...props} responsive={responsive} className={cx('react-bs-table', className)}>
      {fullWidthHeader && (
        <thead>
          <tr>
            <th
              className="text-left bg-white text-primary fs-6"
              colSpan={tableModel.getFlatHeaders().length}
            >
              {fullWidthHeader}
            </th>
          </tr>
        </thead>
      )}
      <thead>
        {tableModel.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <React.Fragment key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        <>
          {tableModel.getRowModel().rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}

          {tableModel.getRowModel().rows.length === 0 && (
            <tr>
              <td
                colSpan={tableModel.getHeaderGroups()[0].headers.length}
                className={'text-center'}
              >
                {Boolean(emptyContent) ? emptyContent : 'No data to display'}
              </td>
            </tr>
          )}
        </>
      </tbody>
    </TableComponent>
  );
};

export default Table;
