import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Col, Row, Tab, TabsGroup } from '@valid-eval/shared-react-components';
import { fetchPdfReportArtifacts } from 'data/features/artifacts';
import withRouter from 'routes/withRouter';

import GlobalReports from './components/Reports/GlobalReports';
import PhaseReports from './components/Reports/PhaseReports';

type OwnProps = {
  params: {
    event_id: string;
  };
};

const connector = connect(
  (_: any, ownProps: OwnProps) => ({
    eventId: ownProps.params.event_id,
  }),
  {
    fetchPdfReportArtifacts,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ReportsProps = PropsFromRedux & OwnProps;

const Reports = ({ eventId, fetchPdfReportArtifacts }: ReportsProps) => {
  useEffect(() => {
    if (!eventId) return;

    fetchPdfReportArtifacts(eventId);
  }, [eventId, fetchPdfReportArtifacts]);

  return (
    <>
      <Row>
        <Col xs={10}>
          <h1>Reports</h1>
          <p>
            Below is a list of useful reports. Clicking these link will create and download an
            .xls-formatted spreadsheet file. These files will land in your “Downloads” folder. Some
            browsers will display the file(s) at the bottom of your browser window.
          </p>
        </Col>
      </Row>

      <TabsGroup className="mb-4" mountOnEnter unmountOnExit>
        <Tab eventKey="global" title="Global Reports">
          <GlobalReports />
        </Tab>
        <Tab eventKey="phase" title="Phase Reports">
          <PhaseReports />
        </Tab>
      </TabsGroup>
    </>
  );
};

export default withRouter(connector(Reports));
