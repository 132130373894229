import Breadcrumb, { Item } from 'components/Breadcrumb';
import Loading from 'components/Loading';
import { createSelfScore } from 'data/actions/scores';
import { fetchScoreForPhase, load as loadTeam } from 'data/actions/teams';
import {
  getScoreForCurrentTeamSelfEvaluation,
  getTeamWithCategoryNameFromURL,
} from 'data/reducers';
import { Children, cloneElement, Component } from 'react';
import { Col, Row } from '@valid-eval/shared-react-components';
import { connect } from 'react-redux';
import { teamNav } from 'utils/urls';
import withRouter from 'routes/withRouter';

class TeamScoringWrapper extends Component {
  componentDidMount() {
    this.props.loadTeam(this.props.params.team_id);
    this.loadOrCreateSelfScore(this.props.params.team_id, this.props.selectedPhaseId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId !== this.props.selectedPhaseId) {
      this.loadOrCreateSelfScore(this.props.params.team_id, nextProps.selectedPhaseId);
    }
  }

  loadOrCreateSelfScore = (teamId, phaseId) => {
    this.props.fetchScoreForPhase(teamId, phaseId).then((action) => {
      if (action.error && action.payload.status === 404) {
        this.props.createSelfScore(this.props.params.event_id, teamId, phaseId);
      }
    });
  };

  renderChildren() {
    const {
      params: { event_id, team_id },
      location: { pathname },
      children,
      score,
    } = this.props;
    const scoringURL = teamNav.selfEvaluation(event_id, team_id);
    return (
      <Row>
        <Col md={12}>
          <div style={{ marginLeft: '-10px' }}>
            <Breadcrumb pills>
              <Item active={pathname === scoringURL} url={scoringURL} style={{ width: '160px' }}>
                Scoring
              </Item>
            </Breadcrumb>
          </div>
          {cloneElement(Children.only(children), {
            score,
            teamUser: true,
            selfScoring: true,
          })}
        </Col>
      </Row>
    );
  }

  render() {
    const { score } = this.props;
    const loadingScore = !score;

    return <div>{loadingScore ? <Loading /> : this.renderChildren()}</div>;
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      return {
        score: getScoreForCurrentTeamSelfEvaluation(state, ownProps),
        teamWithCategory: getTeamWithCategoryNameFromURL(state, ownProps),
      };
    },
    { fetchScoreForPhase, createSelfScore, loadTeam },
  )(TeamScoringWrapper),
);
