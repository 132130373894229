import { Button, Col, Row } from '@valid-eval/shared-react-components';
import BoxContainer from './BoxContainer';
import Styles from './Styles.module.scss';
import BlueShield from 'images/icons/blue_shield.png';
import { StepProps } from './types';

export const StartSetup = ({ currentStep, next, extras }: StepProps) => {
  if (currentStep !== 1) {
    return null;
  }

  const { userName } = extras || {};

  const handleNext = () => {
    next?.();
  };

  return (
    <>
      <BoxContainer
        title={`Welcome ${userName}! Set up your multi-factor authentication (MFA) to add an extra layer of security to your account.`}
      >
        <Row>
          <Col>
            <img className={Styles.icon} src={BlueShield} alt="Blue Shield" />
          </Col>
          <Col xs={10}>
            <p className={Styles.justifyText}>
              <strong>How It Works</strong>
            </p>
            <p className={Styles.justifyText}>
              Every time you log in your account, you'll need your password and the code generated
              by your authenticator app.
            </p>
          </Col>
        </Row>
      </BoxContainer>
      <Row>
        <Col className={Styles.ContinueBtn}>
          <Button variant={'primary'} onClick={handleNext} id="get-started-btn">
            Get Started
          </Button>
        </Col>
      </Row>
      <br />
    </>
  );
};

export default StartSetup;
