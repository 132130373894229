import { Col, Row } from '@valid-eval/shared-react-components';
import {
  getCurrentEventIsActive,
  getEventFromURL,
  getIsLoadingArtifacts,
  getPhasesByEventId,
  getSelectedPhaseIdForCurrentEvent,
  getTeamDocumentsForEvent,
  getTeamPrimaryContact,
  getTeamProfileDynamicFieldsForCurrentEvent,
  getTeamProfileFromURL,
  getTeamProfileInitialValues,
  getTeamProfileOrphanDetails,
} from 'data/reducers';
import {
  load as loadArtifactItems,
  saveFile as saveArtifactFile,
  save as saveArtifactUrl,
} from 'data/actions/artifactItems';
import { load as loadRoleSummaries } from 'data/actions/roleSummaries';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';

import Artifacts from 'components/Teams/Artifacts';
import { Component } from 'react';
import Loading from 'components/Loading';
import ProfileForm from 'components/Teams/ProfileForm';
import { connect } from 'react-redux';
import { getEnvVar } from 'config';
import { isNavigator, isTeamLead } from 'utils';
import { load as loadArtifacts } from 'data/actions/artifacts';
import moment from 'moment-timezone';
import { organizerNav } from 'utils/urls';
import styles from './OrganizerEditTeamProfile.module.scss';
import { update } from 'data/actions/teams';
import withRouter from 'routes/withRouter';

const ROLE = 'teams';

class OrganizerEditTeamProfile extends Component {
  componentDidMount() {
    const {
      loadArtifacts,
      loadArtifactItems,
      loadRoleSummaries,
      selectedPhaseId,
      params: { event_id, team_id },
    } = this.props;
    loadArtifacts(event_id);
    loadArtifactItems(team_id);
    loadRoleSummaries(ROLE, selectedPhaseId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId !== this.props.selectedPhaseId) {
      this.props.loadRoleSummaries(ROLE, nextProps.selectedPhaseId);
    }
  }

  handleSave = (values) => {
    const {
      update,
      notifySuccess,
      notifyError,
      navigate,
      params: { event_id, team_id },
    } = this.props;

    return update(event_id, team_id, values).then(({ error, payload, ...others }) => {
      if (error) {
        let msg = 'Can not update team profile';
        if (payload.response?.error)
          msg =
            typeof payload.response?.error === 'string'
              ? payload.response.error
              : Object.values(payload.response.error).join(', ');
        notifyError(msg);
        return;
      }

      notifySuccess('All changes saved');
      navigate(organizerNav.teamAssignments(event_id));
    });
  };

  handleCancel = (ev) => {
    const {
      navigate,
      params: { event_id },
    } = this.props;
    const url = isTeamLead()
      ? organizerNav.teamManage(event_id)
      : organizerNav.teamAssignments(event_id);
    navigate(url);
  };

  handleSubmitArtifactUrl = (name) => async (values) => {
    const {
      saveArtifactUrl,
      notifyError,
      notifySuccess,
      params: { team_id, event_id },
    } = this.props;
    const email = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');
    const { error } = await saveArtifactUrl(team_id, event_id, values);
    if (error)
      notifyError(
        `Your ${name} could not be updated, please contact Valid Eval tech support at ${email} for assistance.`,
      );
    else notifySuccess('All changes saved');
  };

  handleSubmitArtifactFile = (values) => {
    const {
      saveArtifactFile,
      params: { team_id, event_id },
    } = this.props;
    saveArtifactFile(team_id, event_id, values);
  };

  render() {
    const {
      dynamicFields,
      primaryContact,
      teamInitialValues,
      artifacts,
      loadingArtifacts,
      orphanDetails,
      eventActive,
      params: { event_id, team_id },
      phases,
    } = this.props;
    const hasTeam = !!teamInitialValues;
    const createdAt = moment(this.props.team?.get('created_at'));
    const updatedAt = moment(this.props.team?.get('updated_at'));
    const rawSubmittedAt = this.props.team?.get('submitted_at');
    const submittedAt = rawSubmittedAt ? moment(rawSubmittedAt) : null;

    return (
      <Row>
        <Col md={6}>
          {hasTeam ? (
            <ProfileForm
              phases={phases}
              disabled={!eventActive || isNavigator() || isTeamLead()}
              dynamicFields={dynamicFields}
              orphanDetails={orphanDetails}
              initialValues={teamInitialValues?.toJS() || {}}
              onSubmit={this.handleSave}
              onCancel={this.handleCancel}
              isOrganizerOrNavigator
            />
          ) : (
            <Loading />
          )}
        </Col>
        <Col md={6}>
          <div className={styles.backGColor}>
            <h2 className="mb-2">Primary Contact</h2>
            <div className={styles.content}>
              {' '}
              {primaryContact && primaryContact.get('fullName')}
            </div>
            {primaryContact && (
              <div className={styles.email}>
                <a href={`mailto:${primaryContact.get('email')}`}>{primaryContact.get('email')}</a>
              </div>
            )}
            <br />
            <h3 className="mb-2">Draft Profile Creation Timestamp:</h3>
            <div className={styles.content}>
              {createdAt.format('YYYY-MM-DD HH:mm')}{' '}
              <small className={'text-muted'}>(24h clock, your browser's timezone)</small>
            </div>
            <br />
            <h3 className="mb-2">Timestamp of Most-Recent Profile Update:</h3>
            <div className={styles.content}>
              {updatedAt.format('YYYY-MM-DD HH:mm')}{' '}
              <small className={'text-muted'}>(24h clock, your browser's timezone)</small>
            </div>
            <br />
            <h3 className="mb-2">Final Profile Submission Timestamp:</h3>
            <div className={styles.content}>
              {submittedAt ? (
                <>
                  {submittedAt.format('YYYY-MM-DD HH:mm')}{' '}
                  <small className={'text-muted'}>(24h clock, your browser's timezone)</small>
                </>
              ) : (
                <small className={'text-muted'}>
                  This profile has no stored submission timestamp.
                </small>
              )}
            </div>
            <br />
            <br />
            <h2>Materials Submitted for Evaluation</h2>

            {!loadingArtifacts ? (
              <div className={styles.shiftUp}>
                <Artifacts
                  disabled={!eventActive || isNavigator() || isTeamLead()}
                  eventId={event_id}
                  teamId={team_id}
                  artifacts={artifacts}
                  onDropFile={this.handleSubmitArtifactFile}
                  onSubmit={this.handleSubmitArtifactUrl}
                />
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const {
        params: { event_id: eventId },
      } = ownProps;
      return {
        event: getEventFromURL(state, ownProps)?.toJS?.(),
        team: getTeamProfileFromURL(state, ownProps),
        primaryContact: getTeamPrimaryContact(state, ownProps),
        dynamicFields: getTeamProfileDynamicFieldsForCurrentEvent(state, ownProps),
        orphanDetails: getTeamProfileOrphanDetails(state, ownProps),
        teamInitialValues: getTeamProfileInitialValues(state, ownProps),
        artifacts: getTeamDocumentsForEvent(state, ownProps),
        loadingArtifacts: getIsLoadingArtifacts(state, ownProps),
        selectedPhaseId,
        eventActive: getCurrentEventIsActive(state, ownProps),
        phases: getPhasesByEventId(state, eventId),
      };
    },
    {
      update,
      loadArtifacts,
      loadArtifactItems,
      loadRoleSummaries,
      saveArtifactUrl,
      saveArtifactFile,
      notifySuccess,
      notifyError,
    },
  )(OrganizerEditTeamProfile),
);
