import * as schemas from '../schema';
import * as scoresAPI from '../services/scores';

import { RSAA, getJSON } from 'redux-api-middleware';

import { createAction } from 'redux-actions';
import { failedFSAwithParams } from '../services/config';
import { normalize } from 'normalizr';

export const actions = {
  LOAD: 'scores/LOAD',
  LOAD_MULTIPLE: 'scores/LOAD_MULTIPLE',
  SAVE: 'scores/SAVE',
  CHANGE_DIM_SCORE: 'scores/CHANGE_DIM_SCORE',
  CHANGE_CRITERIA: 'scores/CHANGE_CRITERIA',
  LOAD_RESULT: 'scores/LOAD_RESULT',
  ADD_CRITERION_CLICK: 'scores/ADD_CRITERION_CLICK',
  ADD_CRITERION_CLICK_RESULT: 'scores/ADD_CRITERION_CLICK_RESULT',
  REMOVE_CRITERION_CLICK: 'scores/REMOVE_CRITERION_CLICK',
  REMOVE_CRITERION_CLICK_RESULT: 'scores/REMOVE_CRITERION_CLICK_RESULT',
  SAVE_DIMENSION_SCORE_RESULT: 'scores/SAVE_DIMENSION_SCORE_RESULT',
  CREATE_SELF_SCORE: 'scores/CREATE_SELF_SCORE',
  LOAD_BY_PHASE_AND_JUDGE_PENDING: 'scores/LOAD_BY_PHASE_AND_JUDGE_PENDING',
  LOAD_BY_PHASE_AND_JUDGE_FULFILLED: 'scores/LOAD_BY_PHASE_AND_JUDGE_FULFILLED',
  LOAD_BY_PHASE_AND_JUDGE_REJECTED: 'scores/LOAD_BY_PHASE_AND_JUDGE_REJECTED',
};

export const setScoringData = createAction(
  'scores/SET_SCORING_DATA',
  (scoreId, dimensionId, subdimensionId, criterionId, clickedCriteria, scoreValue) => ({
    scoreId,
    dimensionId,
    subdimensionId,
    criterionId,
    clickedCriteria,
    scoreValue,
  }),
);

// To load score from sagas
export const loadResult = createAction(actions.LOAD_RESULT, (score) => ({ score })); // TODO: Move away from this

export function loadMultiple(ids) {
  return { type: actions.LOAD_MULTIPLE, payload: { ids } };
}

export function save(score) {
  return { type: actions.SAVE, payload: { score } };
}

export function changeCriteria(
  selfScore,
  eventId,
  rubric,
  scoreId,
  dimensionId,
  subdimensionId,
  criterionId,
) {
  return {
    type: actions.CHANGE_CRITERIA,
    payload: {
      selfScore,
      eventId,
      scoreId,
      rubric,
      dimensionId: dimensionId,
      subdimensionId: subdimensionId,
      criterionId: criterionId,
    },
  };
}

const parseSimpleScore = (action, state, res) =>
  getJSON(res).then((payload) => normalize(payload.score, schemas.simpleScore));

export const LOAD_FOR_EVALUATION_RESULT = 'scores/LOAD_FOR_EVALUATION_RESULT';
export const LOAD_FOR_EVALUATION_REQUEST = 'scores/LOAD_FOR_EVALUATION_REQUEST';

export const loadForJudgeAndPhase = (phaseId, judgeId) => {
  return {
    [RSAA]: {
      ...scoresAPI.loadForJudgeAndPhase(phaseId, judgeId),
      types: [
        actions.LOAD_BY_PHASE_AND_JUDGE_PENDING,
        actions.LOAD_BY_PHASE_AND_JUDGE_FULFILLED,
        actions.LOAD_BY_PHASE_AND_JUDGE_REJECTED,
      ],
    },
  };
};

export const load = (id) => {
  return {
    [RSAA]: {
      ...scoresAPI.load(id),
      types: [
        actions.LOAD,
        {
          type: actions.LOAD_RESULT,
          payload: parseSimpleScore,
        },
        actions.LOAD_RESULT,
      ],
    },
  };
};

export const loadForEvaluation = (evaluationId) => {
  return {
    [RSAA]: {
      ...scoresAPI.loadForEvaluation(evaluationId),
      types: [
        { type: LOAD_FOR_EVALUATION_REQUEST, payload: { evaluationId } },
        {
          type: LOAD_FOR_EVALUATION_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              return {
                ...normalize(payload.scores, [schemas.score]),
                evaluationId,
              };
            }),
        },
        {
          type: LOAD_FOR_EVALUATION_RESULT, // TODO: Check the failed response and how to reduce it
          payload: failedFSAwithParams({ evaluationId }),
        },
      ],
    },
  };
};

export const addCriterionClick = (
  selfScore,
  eventId,
  scoreId,
  dimensionId,
  criterionId,
  scoreValueAfterClick,
) => {
  return {
    [RSAA]: {
      ...scoresAPI.addClick(
        selfScore,
        eventId,
        scoreId,
        dimensionId,
        criterionId,
        scoreValueAfterClick,
      ),
      types: [
        actions.ADD_CRITERION_CLICK,
        {
          type: actions.ADD_CRITERION_CLICK_RESULT,
          payload: parseSimpleScore,
        },
        actions.ADD_CRITERION_CLICK_RESULT,
      ],
    },
  };
};

export const removeCriterionClick = (
  selfScore,
  eventId,
  scoreId,
  dimensionId,
  criterionId,
  scoreValueAfterClick,
) => {
  return {
    [RSAA]: {
      ...scoresAPI.removeClick(
        selfScore,
        eventId,
        scoreId,
        dimensionId,
        criterionId,
        scoreValueAfterClick,
      ),
      types: [
        {
          type: actions.REMOVE_CRITERION_CLICK,
          payload: { eventId, scoreId, dimensionId, criterionId, scoreValueAfterClick },
        },
        {
          type: actions.REMOVE_CRITERION_CLICK_RESULT,
          payload: parseSimpleScore,
        },
        actions.REMOVE_CRITERION_CLICK_RESULT,
      ],
    },
  };
};

export const changeDimensionScore = (selfScore, eventId, scoreId, dimensionId, value) => {
  return {
    [RSAA]: {
      ...scoresAPI.saveDimensionScore(selfScore, eventId, scoreId, dimensionId, value),
      types: [
        {
          type: actions.CHANGE_DIM_SCORE,
          payload: { scoreId, dimensionId, value },
        },
        {
          type: actions.SAVE_DIMENSION_SCORE_RESULT,
          payload: parseSimpleScore,
        },
        actions.SAVE_DIMENSION_SCORE_RESULT,
      ],
    },
  };
};

export const createSelfScore = (eventId, teamId, phaseId) => {
  return {
    [RSAA]: {
      ...scoresAPI.createSelfScore(eventId, teamId, phaseId),
      types: [
        actions.CREATE_SELF_SCORE,
        {
          type: actions.LOAD_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => normalize(payload.self_score, schemas.simpleScore)),
        },
        actions.LOAD_RESULT,
      ],
    },
  };
};
