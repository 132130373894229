import { Button, Form, Modal } from '@valid-eval/shared-react-components';

import Errors from '../../../components/Errors';
import { addInvitationEmailToProfile } from 'data/actions/judgeInvitations';
import { getEnvVar } from 'config';
import { reduxForm } from 'redux-form/immutable';
import styles from './ConfirmAddEmailModal.module.scss';
import { success } from 'data/actions/notifications';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const formName = 'addEmailForm';

const ConfirmAddEmailModal = ({ show, onCancel, onToggle, invitationId, emailToAdd }) => {
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const handleSubmitForm = async () => {
    const { error, payload } = await dispatch(addInvitationEmailToProfile(invitationId));

    if (error)
      return setErrors([
        payload?.response?.error ||
          `The email address could not be added, please try again or contact Valid Eval support at ${getEnvVar(
            'REACT_APP_SUPPORT_EMAIL',
            'support@valideval.com',
          )}`,
      ]);

    onToggle();
    dispatch(success('Email address added', 'Success'));
  };

  return (
    <Modal id={'add-email-modal'} show={show} onHide={onCancel}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title className={styles.subTitle}>Confirm action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Errors errors={errors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
          <p>
            This invitation was sent to <strong>{emailToAdd}</strong>, which is not presently
            affiliated with your user profile.
          </p>
          <p>
            Since it is very similar to another email listed on your profile, if you like we can add{' '}
            {emailToAdd} to your user profile so you can accept this invitation.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" id={'add-email-modal-confirm'} onClick={handleSubmitForm}>
            Confirm Email & Proceed to Signup
          </Button>
          <Button variant="link" id={'add-email-modal-cancel'} onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default reduxForm({ form: formName })(ConfirmAddEmailModal);
