import Styles from './TeamSelector.module.scss';

import detectPrint from './detectPrint';

import { TeamSelect } from '@valid-eval/shared-react-components';

const TeamSelector = ({ teams, selectedId, onTeamChange, hideRank }) => {
  teams = teams.sort((a, b) => a.valid_score_valid_rank - b.valid_score_valid_rank);

  return (
    <div className={Styles.Wrapper}>
      <TeamSelect
        options={teams}
        value={teams.find((team) => team.id === selectedId)}
        onChange={onTeamChange}
        hideRank={hideRank}
      />
    </div>
  );
};

TeamSelector.displayName = 'TeamSelector';
export default detectPrint(TeamSelector);
