import { schema } from 'normalizr';

export const user = new schema.Entity('users');
const category = new schema.Entity('categories');

const categoryWithParent = new schema.Entity('categories', {
  parent: category,
});

export const judgeProfile = new schema.Entity('judgeProfiles', {
  categories: [categoryWithParent],
  user: user,
});

export const teamWithCategoryWithParent = new schema.Entity('teams', {
  categories: [categoryWithParent],
});

// Top 10 suggestions for a judge
const team = new schema.Entity('teams');
const teamWithCategories = new schema.Entity('teams', {
  categories: [category],
});

export const judgeSuggestion = new schema.Entity(
  'suggestions',
  {
    role: team,
    categories: [category], // Change this to receive categories nested in role
  },
  {
    idAttribute: (value) => value.role.id,
  },
);

const judgeAsRole = new schema.Entity('judgeProfiles');

export const teamSeekingJudgesSuggestion = new schema.Entity('suggestions', {
  role: judgeAsRole,
  categories: [category],
});

export const judgeSeekingTeamsSuggestion = new schema.Entity('suggestions', {
  role: team,
  categories: [category],
});

export const judgeAssignment = new schema.Entity('judgeAssignments', {
  role: team,
  categories: [category],
});

export const teamAssignment = new schema.Entity('teamAssignments', {
  role: judgeAsRole,
  categories: [category],
});

export const teamRoleSummary = new schema.Entity(
  'roleSummaries',
  {
    role: team,
    assigned_roles: [judgeAsRole],
  },
  {
    idAttribute: (value) => value.role.id,
  },
);

export const judgeRoleSummary = new schema.Entity(
  'roleSummaries',
  {
    role: judgeAsRole,
    assigned_roles: [team],
  },
  {
    idAttribute: (value) => value.role.id,
  },
);

export const roleSummary = new schema.Entity('roleSummaries');

export const teamsList = [team];

export const evaluation = new schema.Entity('evaluations', {
  team: teamWithCategories,
});

export const event = new schema.Entity('events');
export const eventWithCategories = new schema.Entity('events', {
  matching_categories: [categoryWithParent],
});

export const score = new schema.Entity('scores', {
  judge: judgeProfile,
});

export const simpleScore = new schema.Entity('scores', {});

export const jobFunction = new schema.Entity(
  'jobFunctions',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const businessDiscipline = new schema.Entity(
  'businessDisciplines',
  {},
  {
    idAttribute: (value) => value.id,
  },
);

export const phase = new schema.Entity('phases');
export const phaseSummary = new schema.Entity('phase_summaries');

export const artifact = new schema.Entity('artifacts');
export const artifactItem = new schema.Entity('artifactItems', {
  artifact,
});

export const judgeEvaluation = new schema.Entity('judgeEvaluations', {
  team,
  phase,
  artifact_items: [artifactItem],
});

export const userNotification = new schema.Entity('userNotifications');

export const recommendation = new schema.Entity(
  'recommendations',
  {},
  {
    processStrategy(value, parent, key) {
      const voters_reactions = value.voters.reduce(
        (lookup, voter) => ({ ...lookup, [voter.author_id]: voter.reaction }),
        {},
      );
      return { ...value, voters_reactions };
    },
  },
);

export const member = new schema.Entity('member', {}, { idAttribute: 'email' });

export const teamDrafts = new schema.Entity('teamDrafts');
