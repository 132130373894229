interface Score {
  validScoreImplicitRank: number;
  dimScoreMin: number;
  judgeZscoreThisEval: number;
  created_at: number;
  completePercent: number;
  validScoreZScore: number;
  score: number;
  calculated_at: string;
  validScore: number;
  updated_at: number;
  complete: boolean;
  clicksPercent: number;
  dimScoreMax: number;
  uuid: string;
  dimsScored: number;
}

interface LowHighScores {
  firstName: string;
  lastName: string;
  score: Score;
  id: string;
}

interface OriginalData {
  id: string;
  team_name: string;
  team_prefix: string;
  team_url: string;
  team_description: null | string;
  category: string;
  overall_score: number;
  score_count: number;
  valid_score_agreement_strength: number;
  valid_score_valid_z: number;
  valid_score_st_dev: number;
  low_scores: LowHighScores[];
  valid_score: number;
  valid_score_max_z: number;
  score_range: null | number;
  valid_score_st_dev_raw: null | number;
  status: string;
  valid_score_raw_z: null | number;
  valid_score_raw_rank: null | number;
  rank_strength: number;
  final_rank: number;
  valid_score_raw: null | number;
  zero_scores: null | any;
  valid_score_min_z: number;
  team: string;
  high_scores: LowHighScores[];
  valid_score_average_rank: number;
  valid_score_valid_rank: number;
  phase_id: string;
  panel: string;
}
type OptionValue = {
  value: string;
  label: string;
};

export function filterData(
  dataArray: OriginalData[],
  pdfTeamStatusCheckboxes: { [key: string]: boolean },
): OptionValue[] {
  return dataArray
    .filter((data) => {
      const { status } = data;
      return pdfTeamStatusCheckboxes[String(status)];
    })
    .map((data) => {
      const teamPrefix = data.team_prefix;
      const teamName = data.team_name;
      const teamPanel = data.panel;
      const formattedName =
        (teamPanel ? `${teamPanel} - ` : '') +
        (teamPrefix ? `${teamPrefix} - ${teamName}` : teamName);

      return {
        value: data.team,
        label: formattedName,
      };
    });
}
