import { HeaderContext } from '@tanstack/react-table';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeader } from 'components/Tables/CommonTableHeaders';

import Styles from './ResultsTable.module.scss';
import { JudgeScore, TeamResult } from './types';

export const Prefix = (props: HeaderContext<TeamResult, string>) => {
  return (
    <TableHeader className={cx('text-start text-truncate')} {...props}>
      Prefix
    </TableHeader>
  );
};

export const TeamName = (props: HeaderContext<TeamResult, string>) => {
  const { t } = useTranslation();
  return (
    <TableHeader className={cx(Styles.TeamNameColumn, 'text-start text-truncate')} {...props}>
      {t('event.team_profile_name_label', 'Company')}
      <br />
      Category
    </TableHeader>
  );
};

export const ValidRank = (props: HeaderContext<TeamResult, number>) => {
  return (
    <TableHeader className={'text-center'} {...props}>
      Valid
      <br />
      Rank™
    </TableHeader>
  );
};

export const ValidSigma = (props: HeaderContext<TeamResult, number>) => {
  return (
    <TableHeader className={'text-center'} {...props}>
      Valid
      <br />
      Sigma™
    </TableHeader>
  );
};

export const ValidScore = (props: HeaderContext<TeamResult, number>) => {
  return (
    <TableHeader className={'text-center'} {...props}>
      Valid
      <br />
      Score™
    </TableHeader>
  );
};

type EvalsProps = { children: React.ReactNode; start?: boolean } & HeaderContext<
  TeamResult,
  number
>;

export const Evals = ({ children, start, ...props }: EvalsProps) => {
  return (
    <TableHeader
      className={cx({
        'text-start': start,
        'text-center': !start,
      })}
      {...props}
    >
      {children}
    </TableHeader>
  );
};

export const AgreementStrength = (props: HeaderContext<TeamResult, number>) => {
  return (
    <TableHeader className={'text-center'} {...props}>
      Agreement
      <br />
      Strength
    </TableHeader>
  );
};

export const Status = (props: HeaderContext<TeamResult, number | null>) => {
  return (
    <TableHeader className={(Styles.StatusColumn, 'text-center')} {...props}>
      Status
    </TableHeader>
  );
};

export const JudgesScores = ({ column }: HeaderContext<TeamResult, JudgeScore[]>) => {
  return (
    <th className={(Styles.JudgeScoresColumn, 'text-center')}>
      {column.id === 'low_scores' ? 'Lowest' : 'Highest'} Scorers
    </th>
  );
};
