import { judgeNav } from '../urls';

export const displayName = 'Thank you';

export const getActionButtons = (notification) => [
  {
    title: 'Take survey',
    url: judgeNav.survey(
      notification.data.get('event_id'),
      notification.data.get('phase_id'),
      notification.data.get('judge_profile_id'),
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `Thanks very much for completing all your assignments, for phase ${notification.data.get(
    'phase_name',
  )} in cohort ${notification.data.get('cohort_name')}.`;
