import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import {
  fetchApplicationRevisionRequest,
  fromApplicationRevisionRequests,
} from 'data/features/applicationRevisionRequests';
import {
  getApplicationRevisionFields,
  getEventArtifacts,
  getSelectedPhaseIdForCurrentEvent,
} from 'data/reducers';
import withRouter from 'routes/withRouter';

import ApplicationRevisionRequestButton from './ApplicationRevisionRequest/ApplicationRevisionRequestButton';

type OwnProps = {
  params: { team_id: string; event_id: string };
};

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  applicationRevisonRequest: fromApplicationRevisionRequests.getByTeamId(
    state.toJS(),
    ownProps.params.team_id,
  ),
  artifacts: getEventArtifacts(state, ownProps)?.toJS?.() || [],
  fields: getApplicationRevisionFields(state, ownProps)?.data?.toJS(),
  loading: fromApplicationRevisionRequests.getIsLoadingApplicationRevisionRequest(state.toJS()),
  // @ts-expect-error because it doesn't understand that this selector takes two arguments
  phaseId: getSelectedPhaseIdForCurrentEvent(state, ownProps),
});

const mapDispatchToProps = {
  fetchApplicationRevisionRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ApplicationRevisionRequestProps = ConnectedProps<typeof connector> & OwnProps;

function ApplicationRevisionRequest({
  applicationRevisonRequest,
  artifacts,
  fetchApplicationRevisionRequest,
  fields,
  loading,
  params,
  phaseId,
}: ApplicationRevisionRequestProps) {
  const { team_id } = params;

  useEffect(() => {
    fetchApplicationRevisionRequest(team_id);
  }, [team_id]);

  return (
    <ApplicationRevisionRequestButton
      loading={loading}
      applicationRevisionRequest={applicationRevisonRequest}
      artifacts={artifacts}
      fields={fields}
      phaseId={phaseId}
    />
  );
}

export default withRouter(connector(ApplicationRevisionRequest));
