import { HeatmapWithLoading } from 'components/RubricScoring/Heatmap';
import { Component } from 'react';

class ResultHeatmap extends Component {
  render() {
    const { rubric, evaluation, selfScore, scores } = this.props;
    return (
      <HeatmapWithLoading
        rubric={rubric}
        evaluation={evaluation}
        scores={scores}
        selfScore={selfScore}
      />
    );
  }
}

export default ResultHeatmap;
