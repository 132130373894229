import { FormLabel, PhaseSelect } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { getEventPhasesList, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { InactiveTeam } from './types';

type OwnProps = {
  selectedTeams: InactiveTeam[];
  onChange: (value: any) => void;
  value: any;
};

const mapDispatchToProps = {};
const mapStateToProps = (state: any, ownProps: OwnProps) => {
  return {
    // @ts-ignore
    phases: getEventPhasesList(state, ownProps.params?.event_id),
    selectedPhase: getSelectedPhase(state, ownProps)?.toJS?.(),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamsAddModalTableProps = PropsFromRedux & OwnProps;

const TeamsAddModalPhaseSelector = ({
  selectedTeams,
  phases,
  selectedPhase,
  onChange,
  value,
}: TeamsAddModalTableProps) => {
  const mergedStatusFramework = phases.reduce((acc, phase) => {
    Object.keys(phase.status_framework).forEach((key) => {
      if (acc[key]) {
        const status = acc[key].label.split(', ');
        acc[key].label = status.includes(phase.status_framework[key].label)
          ? acc[key].label
          : `${acc[key].label}, ${phase.status_framework[key].label}`;
      } else {
        acc[key] = { ...phase.status_framework[key] };
      }
    });

    return acc;
  }, {});

  const allTeamsOption = { id: null, name: 'All Teams', status_framework: mergedStatusFramework };

  const [phaseOptions, setPhaseOptions] = useState([
    { label: '', options: phases.filter((phase) => phase.id !== selectedPhase?.id) },
    { label: '', options: [allTeamsOption] },
  ]);

  useEffect(() => {
    const phasesGroup = phases
      .filter((phase) => phase.id !== selectedPhase?.id)
      .map((phase) => {
        const count = selectedTeams.reduce((c, t) => {
          const foundPhase = t.phases.find((p) => p.id === phase.id);
          return foundPhase?.status ? c + 1 : c;
        }, 0);
        const countStr = count ? ` (${count})` : '';
        return { ...phase, name: `${phase.name}${countStr}` };
      });

    const selectedCountStr = selectedTeams.length ? ` (${selectedTeams.length})` : '';
    const allTeamsGroup = [
      {
        ...allTeamsOption,
        name: `${allTeamsOption.name}${selectedCountStr}`,
      },
    ];

    const allOptions = [
      { label: '', options: phasesGroup },
      { label: '', options: allTeamsGroup },
    ];

    const selectedOption = phasesGroup.find((option) => option.id === value?.id);
    setPhaseOptions(allOptions);
    onChange(selectedOption || allTeamsGroup[0]);
  }, [selectedTeams]);

  useEffect(() => {
    onChange(allTeamsOption);
  }, []);

  return (
    <>
      <div className="text-center">
        <FormLabel className="fw-bold">Select Teams From Phase</FormLabel>
      </div>
      <PhaseSelect
        inputId="select-teams-add-phase"
        options={phaseOptions}
        getOptionValue={(option: any) => option.id}
        getOptionLabel={(option: any) => option.name}
        onChange={(value) => onChange(value)}
        value={value}
      />
    </>
  );
};

export default withRouter(connector(TeamsAddModalPhaseSelector));
