import { getMaintenanceInfo, healthcheck } from 'data/services/utils';
import { useEffect, useState } from 'react';

import DefaultMaintenanceInfo from './components/DefaultMaintenanceInfo';
import DynamicMaintenanceInfo from './components/DynamicMaintenanceInfo';
import ErrorBoundary from './ErrorBoundary';
import Loading from '../components/Loading';
import Styles from '../screens/styles.module.scss';
import { getEnvVar } from 'config';

const MaintenanceMode = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceInfo, setMaintenanceInfo] = useState('');

  // fetch the maintenance info
  useEffect(() => {
    (async () => {
      try {
        setMaintenanceInfo(await getMaintenanceInfo());
      } catch {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // health check interval
  useEffect(() => {
    const timeout = getEnvVar('REACT_APP_MAINT_CHECK_INTERVAL', 60) * 1000;

    const doHealthCheck = async () => {
      try {
        const res = await healthcheck();
        if (res.status === 200) {
          window.location = '/?timestamp=' + new Date().getTime();
        }
      } catch {}
    };

    doHealthCheck();
    const interval = setInterval(doHealthCheck, timeout);

    return () => clearInterval(interval);
  }, []);

  const MaintenanceInfoComponent = maintenanceInfo
    ? DynamicMaintenanceInfo
    : DefaultMaintenanceInfo;

  return (
    <ErrorBoundary>
      <div className={`clearfix ${Styles.container}`}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <MaintenanceInfoComponent html={maintenanceInfo} />
            <div className={'panel panel-danger ' + Styles.status}>
              <div className={'panel-heading'}>Retrieving maintenance status...</div>
              <div className={'panel-body ' + Styles.loadingPanel}>
                <Loading />
              </div>
            </div>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

MaintenanceMode.displayName = 'MaintenanceMode';
export default MaintenanceMode;
