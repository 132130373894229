import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ArtifactItem } from 'data/features/artifactItemsTypes';
import { Event } from 'data/features/eventsTypes';

import { TeamDraft } from 'data/features/teamDraftTypes';
import { StepInfo } from '../types';
import useCalculateStepInfo from './useCalculateStepInfo';

export type UploadsFormType = Record<string, any>;

const useUploadsForm = ({
  event,
  user,
  draft,
  artifactItems,
  onInfoChange,
}: {
  event?: Event;
  user?: any;
  draft?: TeamDraft;
  artifactItems?: Record<string, ArtifactItem>;
  onInfoChange(data: StepInfo): void;
}) => {
  const form = useForm<UploadsFormType>({
    mode: 'onChange',
  });

  const requiredFields =
    event?.artifacts.filter((artifact) => artifact.required).map((a) => String(a.id)) || [];

  useCalculateStepInfo({ user, form, requiredFields, onInfoChange });

  // Trigger validation on mount
  useEffect(() => {
    form.trigger();
  }, []);

  // Set form values from artifact items
  useEffect(() => {
    if (!event?.artifacts || !artifactItems) return;

    event.artifacts.forEach((artifact) => {
      if (artifact.type !== 'url' && artifact.type !== 'file') return;

      const artifactItem = artifactItems[artifact.id];

      form.setValue(String(artifact.id), artifactItem?.url);
    });
    form.trigger();
  }, [Object.keys(artifactItems || {}).toString(), event?.id, draft?.id]);

  return { form };
};

export default useUploadsForm;
