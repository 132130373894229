import { Col, Container, Row } from '@valid-eval/shared-react-components';
import { Children, useEffect } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';

import Loading from 'components/Loading';
import { load } from 'data/actions/events';
import { getEventFromURL, getTeamWithCategoryNameForCurrentScore } from 'data/reducers';
import { setCurrentRole, unsetCurrentRole } from 'data/actions/support';

import Navigation from './components/Navigation';

const Wrapper = ({
  event,
  loading,
  location,
  teamWithCategory,
  unsetCurrentRole,
  setCurrentRole,
  params,
  children,
}) => {
  useEffect(() => {
    setCurrentRole({ role: 'judge', eventId: params.event_id, id: params.judge_profile_id });
    return () => unsetCurrentRole();
  }, []);

  if (loading) {
    return <Loading />;
  }
  const subTitle = location.pathname.endsWith('confirmation') && 'Judge Registration';
  return (
    <Container>
      <Row className="ms-0 me-0">
        <Col md={12}>
          <Navigation
            event={event}
            judgeProfileId={params.judge_profile_id}
            subTitle={subTitle}
            teamWithCategory={teamWithCategory}
          />
        </Col>
        <Col md={12}>{Children.only(children)}</Col>
      </Row>
    </Container>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEventFromURL(state, ownProps);
      const teamWithCategory = getTeamWithCategoryNameForCurrentScore(state, ownProps);
      return { event, loading: !event, teamWithCategory };
    },
    { load, setCurrentRole, unsetCurrentRole },
  )(Wrapper),
);
