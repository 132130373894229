import { getEnvVar } from 'config';
import { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { isRunningOnIE } from 'utils';
import { error as notifyError } from '../data/actions/notifications';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(_) {
    return { hasError: true };
  }

  componentDidCatch() {
    this.props.notifyError(
      isRunningOnIE({ detectEdge: false })
        ? 'You have encountered a system error while using Microsoft Internet Explorer, which has been retired by Microsoft and is not supported by Valid Eval. Please use one of the supported browsers, which are Chrome, Edge, Firefox, and Safari.'
        : `Please try again. If you continue to receive this error, please contact ${getEnvVar(
            'REACT_APP_SUPPORT_EMAIL',
            'support@valideval.com',
          )}`,
      'System Error',
      10e3,
    );
    // Only redirect if is not in the dashboard or root already
    if (this.props.location.pathname !== '/app/dashboard' || this.props.location.pathname !== '/')
      this.props.navigate('/');
  }

  render() {
    return this.state.hasError ? (
      <div style={{ textAlign: 'center', padding: 20 }}>Something went wrong</div>
    ) : (
      this.props.children
    );
  }
}

export default withRouter(connect(null, { notifyError })(ErrorBoundary));
