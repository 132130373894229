import { Col, Form, Row } from '@valid-eval/shared-react-components';
import { Phase } from 'data/features/phaseTypes';

import { organizerNav } from 'utils/urls';

type PhaseSettingsRubricProps = {
  phase: Phase;
};

const PhaseSettingsRubric = ({ phase }: PhaseSettingsRubricProps) => {
  return (
    <Row>
      <Col className="mb-4">
        <Form.Group>
          <Form.Label>
            <b>Evaluation Rubric</b>
          </Form.Label>
          <Col>
            <a
              className={'form-control text-blue01 fw-bold'}
              href={organizerNav.rubric(phase?.event_id, phase.id)}
              target="_blank"
              rel="noreferrer"
            >
              {phase.rubric_name} <i className="fa-solid fa-arrow-up-right-from-square" />
            </a>
          </Col>
        </Form.Group>
        <Form.Text className="text-muted">
          Evaluation criteria used during this Phase. Please contact VE for changes.
        </Form.Text>
      </Col>
    </Row>
  );
};

export default PhaseSettingsRubric;
