import { OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';

const TeamRemovalBlockedTooltip = () => {
  const { t } = useTranslation();
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <strong>{t('help.team_removal_blocked')}</strong>
          </Tooltip>
        }
      >
        <i className="fa-duotone fa-lock-keyhole text-muted-light"></i>
      </OverlayTrigger>
    </>
  );
};

export default TeamRemovalBlockedTooltip;
