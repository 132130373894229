import cx from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getEvent, getPhaseNavigatorsViewResultsEnabled, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

import styles from './PhaseStatus.module.scss';

const Judges = ({ eventId, info }) => {
  return (
    <div className={styles.description}>
      <dl>
        <dt>{info.get('judges_invited') || 0}</dt>
        <dd>Judges Invited</dd>
        <dt>{info.get('judges_registered') || 0}</dt>
        <dd>Judges Registered</dd>
        <dt>{info.get('judges_active') || 0}</dt>
        <dd>Judges Active</dd>
      </dl>
      <div className="d-flex">
        {!isTeamLead() && (
          <>
            <Link
              className={cx('me-1 btn btn-secondary', styles.btnMultiple)}
              to={organizerNav.judgeInvitations(eventId)}
            >
              {isNavigator() ? 'Invitations' : 'Invite'}
            </Link>
            <Link
              className={cx('me-1 btn btn-secondary', styles.btnMultiple, {
                disabled: !Boolean(info.get('judges_registered')),
              })}
              to={organizerNav.judgeAssignments(eventId)}
            >
              {isNavigator() ? 'Matches' : 'Match'}
            </Link>
          </>
        )}
        <Link
          className={cx('btn btn-secondary', styles.btnMultiple, {
            disabled: !Boolean(info.get('judges_registered')),
          })}
          to={organizerNav.judgeManage(eventId)}
        >
          {isNavigator() || isTeamLead() ? 'Progress' : 'Manage'}
        </Link>
      </div>
    </div>
  );
};

const Results = ({ eventId, info, canAccessResults }) => {
  const evaluationsCompleted = info.get('evaluations_completed') || 0;
  return (
    <div className={styles.description}>
      <dl>
        <dt>{evaluationsCompleted}</dt>
        <dd>Scores Completed</dd>
        <dt>{info.get('evaluations_completed_percent')}</dt>
        <dd>% of Scores Completed</dd>
        <dt>{info.get('judges_participating')}</dt>
        <dd>Judges With At Least 1 Completed Score </dd>
      </dl>
      {canAccessResults && (
        <div className="d-grid">
          <Link
            to={organizerNav.results(eventId)}
            className={cx('btn btn-secondary btn-lg', {
              disabled: !Boolean(evaluationsCompleted),
            })}
            size="lg"
          >
            View Results
          </Link>
        </div>
      )}
    </div>
  );
};

const Teams = ({ eventId, info, phase, event }) => {
  const teamsActive = info.get('teams_active') || 0;
  const teamsRegistered = info.get('teams_registered') || 0;
  const progressLabel = isNavigator() || isTeamLead() ? 'Progress' : 'Manage';

  return (
    <div className={styles.description}>
      <dl>
        <dt>{info.get('teams_registered')}</dt>
        <dd>Teams Registered</dd>
        <dt>{info.get('teams_active')}</dt>
        <dd>Teams Participating</dd>
      </dl>
      <div className="d-flex">
        {!isTeamLead() && (
          <>
            <Link
              to={organizerNav.teamsAdd(eventId, !Boolean(teamsActive))}
              className={cx('me-1 btn btn-secondary', styles.btnMultiple, {
                disabled: !Boolean(teamsRegistered),
              })}
              size="lg"
            >
              {Boolean(teamsActive) ? 'Teams' : 'Add'}
            </Link>
            <Link
              to={organizerNav.teamAssignments(eventId)}
              className={cx('me-1 btn btn-secondary', styles.btnMultiple, {
                disabled: !Boolean(teamsActive),
              })}
              size="lg"
            >
              {isNavigator() ? 'Matches' : 'Match'}
            </Link>
          </>
        )}
        <Link
          to={organizerNav.teamManage(eventId)}
          className={cx('me-1 btn btn-secondary', styles.btnMultiple, {
            disabled: !Boolean(teamsActive),
          })}
          size="lg"
        >
          {progressLabel}
        </Link>
      </div>
    </div>
  );
};

Judges.displayName = 'JudgesStatus';
Results.displayName = 'ResultsStatus';
Teams.displayName = 'TeamsStatus';

const connector = (Component) =>
  withRouter(
    connect((state, ownProps) => ({
      canAccessResults: !isNavigator() || getPhaseNavigatorsViewResultsEnabled(state, ownProps),
      phase: getSelectedPhase(state, ownProps),
      event: getEvent(state, ownProps.params.event_id),
    }))(Component),
  );

export const JudgesStatus = connector(Judges);
export const ResultsStatus = connector(Results);
export const TeamsStatus = connector(Teams);
