import moment from 'moment-timezone';

const EmptyContent = ({
  judge,
  eventName,
}: {
  judge: { created_at: number; phase_start_date: number };
  eventName: string;
}) => {
  const hours = Math.floor((new Date().getTime() / 1000 - judge.created_at) / 3600);
  const phaseStarted = new Date().getTime() / 1000 > judge.phase_start_date;
  const date = moment(new Date(judge.phase_start_date * 1000));

  return (
    <div className="bg-light">
      {phaseStarted ? (
        <>
          <br />
          Thanks for signing up as an evaluator for {eventName}!
          <br />
          <br />
          {hours <= 120 ? (
            <>
              Your Judge Profile for this cohort was created {hours} hours ago.
              <br />
              <br />
              Your signup immediately triggered an automated notification to the program management
              team. Please give them at least one business day to choose the best proposals for you
              to review.
              <br />
              <br />
              They will send you an email when your matches are ready. Thanks for your patience!
            </>
          ) : (
            <>
              You have not yet been assigned any teams to evaluate. For more information please
              follow up with the Organizer team.
            </>
          )}
          <br />
          <br />
        </>
      ) : (
        <>
          <br />
          When the judging window is open and the program managers have matched you to proposals,
          you will see a list of proposals on this screen.
          <br />
          <br />
          Please check back on {date.format('MMM. Do YYYY')} when the judging is scheduled to begin.
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default EmptyContent;
