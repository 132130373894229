export const getTeamsSelectOptions = (evaluations) => {
  return evaluations.map(getTeamSelectOption).sort((a, b) => a.rank - b.rank);
};

export const getTeamSelectOption = (ev) => {
  return (
    ev && {
      id: ev.team,
      name: ev.team_name,
      category: ev.category,
      rank: ev.valid_score_valid_rank,
      sigma: ev.valid_score_valid_z,
      rank_strength: ev.rank_strength,
      eval_id: ev.eval_id,
      viewed: ev.viewed,
    }
  );
};

export const getScoreSigmas = (evaluation) => {
  return (
    evaluation?.scores.map((score) => ({
      id: score.judge,
      evaluation: evaluation.id,
      phase: score.phase_id,
      team: evaluation.team,
      name: score.judge_name,
      sigma: score.valid_score_z_score,
      rank: score.valid_score_implicit_rank,
    })) || []
  );
};

const getSelectionCommiteeAttribution = (writer, isOrganizer, isYou) => {
  if (!isOrganizer) return 'From Selection Committee';
  const attributionName = isYou ? 'you' : `${writer.first_name} ${writer.last_name}`;
  return `From Selection Committee to Team (entered by ${attributionName})`;
};

export const getComments = (team) => {
  return (
    team?.notes?.map((n) => {
      const writeOrg = n.writerIsOrganizer
        ? getSelectionCommiteeAttribution(n.writer, true, n.isYou)
        : 'Anonymous';
      return {
        id: n.id,
        content: n.content,
        type: n.type,
        writerIsOrganizer: n.writerIsOrganizer,
        canEdit: n.canEdit,
        author:
          n.writer && (!n.writerIsOrganizer || n.type === 'internal')
            ? [n.writer.first_name, n.writer.last_name].join(' ')
            : writeOrg,
        upVotes: n.upVotes,
        downVotes: n.downVotes,
        isYou: n.isYou,
      };
    }) || []
  );
};
