import GenericModal from 'components/GenericModal';

export const renderBodyContent = (email, isPrimary) => (
  <>
    Are you sure you want to remove <strong>{email}</strong> from your account? Once removed, this
    email address will no longer be associated with your account.{' '}
    <strong>{isPrimary && 'One of your other emails will become your primary address.'}</strong>
  </>
);

const ConfirmEmailRemoveModal = ({ show, onCancel, onConfirm, emails, index }) => {
  const emailRecord = emails.get(index);

  const isPrimary = emailRecord?.get('primary_email');
  const email = emailRecord?.get('email');

  return (
    <GenericModal
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      name={'remove-email'}
      title={'Remove Email'}
      body={renderBodyContent(email, isPrimary)}
      cancelButton={'Cancel'}
      confirmButton={'Confirm'}
    />
  );
};

export default ConfirmEmailRemoveModal;
