import { Col, Form, InputGroup, Row } from '@valid-eval/shared-react-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Date from 'components/Form/Date';
import { getEnvVar } from 'config';
import { Event } from 'data/features/eventsTypes';
import { Phase } from 'data/features/phaseTypes';
import { DATE_FORMAT_WITH_TIME, humanize } from 'utils';

type PhaseSettingsDatesProps = {
  event: Event;
  phase: Phase;
};

const PhaseSettingsDates = ({ event, phase }: PhaseSettingsDatesProps) => {
  const { t } = useTranslation();
  const email = getEnvVar('REACT_APP_SUPPORT_EMAIL', 'support@valideval.com');

  const currentTimezone = moment.tz.guess();
  const areDatesDisabled = !event?.start_date || !event?.end_date;
  const eventStartDate = moment(event?.start_date).tz(currentTimezone);
  const eventEndDate = moment(event?.end_date).tz(currentTimezone);
  const phaseStartDate = moment(phase?.start_date).tz(currentTimezone);
  const phaseEndDate = moment(phase?.end_date).tz(currentTimezone);

  const validStartDate = (value: string) => {
    const selectedDate = moment(value, DATE_FORMAT_WITH_TIME);
    const isAfterEventStartDate = selectedDate.isSameOrAfter(eventStartDate);
    const isBeforeEventEndDate = selectedDate.isSameOrBefore(eventEndDate);
    const isBeforePhaseEndDate = selectedDate.isSameOrBefore(phaseEndDate);

    if (!isAfterEventStartDate) {
      return t('settings.error.phase_start_less', {
        date: eventStartDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isBeforeEventEndDate) {
      return t('settings.error.phase_start_greater', {
        date: eventEndDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isBeforePhaseEndDate) {
      return t('settings.error.phase_start_greater', {
        date: phaseEndDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    return;
  };

  const validEndDate = (value: string) => {
    const selectedDate = moment(value, DATE_FORMAT_WITH_TIME);
    const isAfterEventStartDate = selectedDate.isSameOrAfter(eventStartDate);
    const isAfterPhaseStartDate = selectedDate.isSameOrAfter(phaseStartDate);
    const isBeforeEventEndDate = selectedDate.isSameOrBefore(eventEndDate);

    if (!isAfterEventStartDate) {
      return t('settings.error.phase_end_less', {
        date: eventStartDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isAfterPhaseStartDate) {
      return t('settings.error.phase_end_less', {
        date: phaseStartDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    if (!isBeforeEventEndDate) {
      return t('settings.error.phase_end_greater', {
        date: eventEndDate.format(DATE_FORMAT_WITH_TIME),
        email,
      });
    }
    return;
  };

  return (
    <>
      <Row>
        <Col md="6" className="mb-4">
          <Form.Label htmlFor="startDate">
            <b>Judging window opens</b>
          </Form.Label>
          <Date
            after={
              <InputGroup.Text className="overflow-hidden">
                <small className="text-truncate">
                  GTM{moment.tz(currentTimezone).format('Z')} - {humanize(currentTimezone)}
                </small>
              </InputGroup.Text>
            }
            disabled={areDatesDisabled}
            name="startDate"
            rules={{ validate: { validStartDate } }}
            utc={false}
            isDateTime
            required
          />
          <Form.Text className="text-muted">Set the start date for the judging window.</Form.Text>
        </Col>
        <Col md="6" className="mb-4">
          <Form.Label htmlFor="endDate">
            <b>Judging window closes</b>
          </Form.Label>
          <Date
            after={
              <InputGroup.Text className="overflow-hidden">
                <small className="text-truncate">
                  GTM{moment.tz(currentTimezone).format('Z')} - {humanize(currentTimezone)}
                </small>
              </InputGroup.Text>
            }
            disabled={areDatesDisabled}
            name="endDate"
            rules={{ validate: { validEndDate } }}
            utc={false}
            isDateTime
            required
          />
          <Form.Text className="text-muted">Set the end date for the judging window.</Form.Text>
        </Col>
      </Row>
      {areDatesDisabled && (
        <Row>
          <Col>
            <div className="alert alert-warning">
              {t('settings.error.judging_window_disabled', { email })}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PhaseSettingsDates;
