import { Button } from '@valid-eval/shared-react-components';
import { Notification } from './types';

interface AutomatchNotification extends Notification {}

export const getActionButtons = (notification: AutomatchNotification): Array<any> => {
  const { is_running } = notification.data.toJS?.() || notification.data;

  if (is_running) {
    return [
      () => (
        <Button variant="link" disabled>
          <i className="fa-duotone fa-spinner fa-lg fa-spin"></i> Running panel match
        </Button>
      ),
    ];
  }
  return [];
};

export const getText = (notification: AutomatchNotification): string => {
  const data = notification.data.toJS?.() || notification.data;
  const { is_running, success, error, phase_name, event_name } = data;
  if (is_running) {
    return `Panel match is running for ${event_name} cohort at phase ${phase_name}`;
  } else if (success) {
    return `Panel match completed for ${event_name} cohort at phase ${phase_name}`;
  } else {
    return `Panel match failed for ${event_name} cohort at phase ${phase_name}. ${error}`;
  }
};
