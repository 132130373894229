import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import Styles from './TeamCompareSelector.module.scss';
import cx from 'classnames';

function TeamCompareSelectorWrapper({ children }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function onScroll(e) {
      if (wrapperRef.current?.getBoundingClientRect().top <= 60) {
        wrapperRef.current?.classList.add(Styles.WrapperFloating);
      } else {
        wrapperRef.current?.classList.remove(Styles.WrapperFloating);
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={cx(Styles.Wrapper)} ref={wrapperRef}>
      <div className={Styles.Container}>
        <div className={Styles.Row}>{children}</div>
      </div>
    </div>
  );
}

TeamCompareSelectorWrapper.propTypes = {
  children: PropTypes.any,
};

export default TeamCompareSelectorWrapper;
