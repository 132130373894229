import * as judgeProfilesAPI from '../services/judgeProfiles';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const actions = {
  LOAD: 'contents/LOAD',
};

export const LOAD_RESULT = 'business_disciplines/LOAD_RESULT';

export const load = () => ({
  [RSAA]: {
    ...judgeProfilesAPI.loadBusinessDisciplines(),
    types: [
      actions.LOAD,
      {
        type: LOAD_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({
            ...normalize(payload.business_disciplines, [schemas.businessDiscipline]),
          })),
      },
      LOAD_RESULT,
    ],
  },
});
