/* eslint no-mixed-operators: 0 */
import * as validations from './validations';

import { number, wholeNumber } from './validations';

export const dynamicFieldValidation = (value, _, props, name) => {
  // Validates only of the dynamicFields information
  // describing the details is passed
  if (!props.dynamicFields) return undefined;

  const fieldDescription = props.dynamicFields.byName[name];
  if (!fieldDescription) return undefined;

  const fieldType = fieldDescription.get('field_type');
  const fieldTypeValidator = validations.byType[fieldType];

  const rules = [];

  const isNumberField = fieldType === 'number' || fieldType === 'whole_number';
  const isDateField = fieldType === 'date';
  const isTextField = !isDateField && !isNumberField;

  // Apply validation specific to the detail type
  if (fieldTypeValidator) rules.push(fieldTypeValidator);
  // Apply required validation
  if (fieldDescription.get('required')) rules.push(validations.required);
  // Apply min and max value if it is a number
  if (isNumberField && fieldDescription.get('min_length'))
    rules.push(validations.minValue(fieldDescription.get('min_length')));
  if (isNumberField && fieldDescription.get('max_length'))
    rules.push(validations.maxValue(fieldDescription.get('max_length')));
  // Apply min length and max length if it is text and not a date
  if (isTextField && fieldDescription.get('min_length'))
    rules.push(validations.minLength(fieldDescription.get('min_length')));
  if (isTextField && fieldDescription.get('max_length'))
    rules.push(validations.maxLength(fieldDescription.get('max_length')));

  const moreErrors = rules
    .map((rule) => rule(value))
    .filter((error) => error !== undefined)
    .join(', ');

  return moreErrors || undefined;
};

const convertValue = (type, value, options = {}) => {
  switch (type) {
    case 'number':
      const rValue = value.replace(/,/g, '');
      if (!value || number(rValue) || value.endsWith('.') || value.endsWith(',')) {
        return value;
      }

      return Number(rValue);
    case 'date':
      return options.normalizer(value);
    default:
      return value;
  }
};

export const formatNumber = (value) => {
  if (value === '' || value === undefined || value === null) return '';

  let result = Number(value).toLocaleString('en-US', { maximumFractionDigits: 8 });
  result = value?.endsWith?.('.') ? result + '.' : result;
  if (number(result)) {
    return value;
  } else if (result === '0') {
    return 0;
  }

  return result;
};

export const formatWholeNumber = (value) => {
  if (value === '' || value === undefined || value === null) return '';

  const result = Number(value).toLocaleString('en-US', { maximumFractionDigits: 0 });
  if (wholeNumber(result)) {
    return value;
  } else if (result === '0') {
    return 0;
  }

  return result;
};

export const normalize = (type, options) => (value) => {
  return convertValue(type, value, options);
};

export const resize = (helpText, textareaRef) => {
  if (helpText != null) {
    if (helpText !== '') {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.setProperty(
        'height',
        `${Math.max(textareaRef.current.scrollHeight, 20)}px`,
        'important',
      );
    }
  }
};
