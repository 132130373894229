import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import { isNavigator } from 'utils';

import Styles from './CommonTableStyles.module.scss';

type ToggleButtonProps = {
  active: boolean;
  record: any;
  onActiveChange: (record: any) => void;
};

export const ToggleButton = ({ record, active, onActiveChange }: ToggleButtonProps) => {
  return (
    <Button
      variant="none"
      disabled={isNavigator()}
      className={cx(active ? Styles.ToggleButtonActive : Styles.ToggleButtonInactive)}
      onClick={() => onActiveChange(record)}
      data-test-id={`toggle-button-${record.id}`}
    >
      <span className="fa-solid fa-circle-user fa-2x" />
    </Button>
  );
};
