import { Alert } from 'react-bs-notifier';

const Errors = ({ errors, alertClasses }) => {
  if (errors) {
    return (
      <Alert type="danger" classes={alertClasses}>
        {errors.length === 1 ? (
          typeof errors[0] === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: errors[0] }} />
          ) : (
            errors[0]
          )
        ) : (
          <ul>
            {errors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        )}
      </Alert>
    );
  } else {
    return null;
  }
};

export default Errors;
