import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { post } from 'data/services/config';

// Actions
export const resetMFA = createAsyncThunk(
  'authentication/resetMFA',
  async ({ password, code }: { password: string; code: string }, { rejectWithValue }) => {
    try {
      return await post(`/users/reset_mfa`, { password, code });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState: {
    loading: false,
    isSignUpModalOpen: false,
  },
  reducers: {
    openSignUpModal: (state) => {
      state.isSignUpModalOpen = true;
    },
    closeSignUpModal: (state) => {
      state.isSignUpModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetMFA.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetMFA.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetMFA.rejected, (state) => {
        state.loading = false;
      });
  },
  selectors: {
    getIsSignUpModalOpen: (state) => state.isSignUpModalOpen,
    getIsLoading: (state) => state.loading,
  },
});

export const { openSignUpModal, closeSignUpModal } = authenticationSlice.actions;
export const fromAuthentication = authenticationSlice.selectors;
export default authenticationSlice.reducer;
