import { Alert, Button, Form } from '@valid-eval/shared-react-components';
import { Input, Password } from 'components/FormFields';
import { passwordStrength, required, sameAs } from 'components/FormFields/validations';

import { reduxForm } from 'redux-form/immutable';
import styles from './UpdatePasswordFrom.module.scss';

const fieldLabels = {
  password: 'Password',
  passwordConfirmation: 'Password confirmation',
};

const validate = (values, props) => {
  const errors = {};

  const currentPassword = values;
  const otp = values.get('otp');
  const password = values.get('password');
  const passwordConfirmation = values.get('passwordConfirmation');

  errors.currentPassword = required(currentPassword) || undefined;
  errors.otp = (props.isOtpConfigured && required(otp)) || undefined;
  errors.password = required(password) || passwordStrength(password) || undefined;
  errors.passwordConfirmation =
    required(passwordConfirmation) ||
    passwordStrength(passwordConfirmation) ||
    sameAs('password', 'New password')(passwordConfirmation, { password }) ||
    undefined;

  return errors;
};

const Errors = ({ errors = {} }) => {
  if (Object.keys(errors).length) {
    return (
      <Alert variant="danger">
        {Object.keys(errors).map((key) => (
          <div key={key}>
            <strong>{fieldLabels[key]}</strong> {errors[key]}
          </div>
        ))}
      </Alert>
    );
  }

  return null;
};

const UpdatePasswordForm = ({
  isSubmitting = false,
  submitErrors = {},
  handleSubmit,
  isOtpConfigured,
}) => {
  return (
    <div>
      {submitErrors && (
        <Errors errors={submitErrors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
      )}

      <Form onSubmit={handleSubmit}>
        <Password name="currentPassword" title="Current password" />
        <Password name="password" title="New password" />
        <Password name="passwordConfirmation" title="Confirm new password" />
        {isOtpConfigured && <Input name="otp" title="MFA Code" />}
        <Form.Group className="mt-3">
          <Button
            className={styles.btnUpdate}
            variant="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Update login
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: 'updatePassword',
  validate,
})(UpdatePasswordForm);
