import * as actions from '../actions/events';

import { combineActions, handleActions } from 'redux-actions';

import { fromJS } from 'immutable';

const {
  actions: { LOAD, SAVE_SUCCESS },
  LOAD_RESULT,
  SET_COOKIES_CONSENT,
} = actions;

const initialState = fromJS({ loading: false, data: {}, cookies: {} });

const events = handleActions(
  {
    [combineActions(LOAD, actions.LOAD_MULTIPLE_REQUEST)]: (state, action) =>
      state.set('loading', true),
    [combineActions(LOAD_RESULT, actions.LOAD_MULTIPLE_RESULT)]: {
      next(state, action) {
        return state
          .set('loading', false)
          .mergeDeepIn(['data'], fromJS(action.payload.entities.events));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [SAVE_SUCCESS]: (state, action) =>
      state.mergeIn(['data', String(action.payload.event.id)], fromJS(action.payload.event)),
    [SET_COOKIES_CONSENT]: (state, action) =>
      state.setIn(['cookies', action.payload.eventId], action.payload.cookies),
  },
  initialState,
);

export const getList = (state) => state.get('data');
export const getEvent = (state, id) => state.getIn(['data', id]);
export const getEventBySlug = (state, slug) =>
  state
    .get('data')
    .filter((value) => value.get('slug') === slug)
    .first();

export const isLoading = (state) => state.get('loading');

export const getCookiesConsent = (state, eventId) => state.getIn(['cookies', eventId]);

export default events;
