import { Judge } from 'screens/app/events/shared/types';

export const statusType = {
  Finished: 0,
  Judging: 0,
  'Not Started': 0,
  Unmatched: 0,
};

// Judge Manage Section

export const searchableFields = ['panel', 'name'];

export const filterByStatus = (filterList: any[], Judge: Judge) => {
  if (filterList.length) {
    // Apply the decision status filter if is selected
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value], []);
    if (!status.includes(Judge.status)) return false;
  }
  return true;
};
