import { CommonControls } from 'components/Tables/CommonControls';
import { TeamDraft } from './types';
import cloneDeep from 'lodash/cloneDeep';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';
import { searchableFields } from './helpers';
import { useMemo } from 'react';

const AVAILABLE_FILTERS = [
  {
    title: 'Pending Draft',
    field: 'Sent',
    type: 'checkbox',
    options: [
      {
        label: 'Sent',
        value: true,
      },
      {
        label: 'Not sent',
        value: false,
      },
    ],
  },
];

type ControlsProps = {
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  data: TeamDraft[];
  textFilter: string;
};

const Controls = ({
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  data,
  textFilter,
}: ControlsProps) => {
  const availableFilters = useMemo(() => {
    const statusCount = data
      ?.filter((row) => filterByTextFilter(textFilter, row, searchableFields))
      .reduce(
        (count, row) => {
          if (row.latestMessage) count.sent += 1;
          else count.not_sent += 1;
          return count;
        },
        {
          sent: 0,
          not_sent: 0,
        },
      );

    const availableFilters = cloneDeep(AVAILABLE_FILTERS);
    availableFilters[0].options[0].label = `Sent (${statusCount?.sent})`;
    availableFilters[0].options[1].label = `Not sent (${statusCount?.not_sent})`;

    return availableFilters;
  }, [data, textFilter]);

  let appliedFilters = filters.map((item) => {
    if (item.value) {
      item.label = availableFilters[0].options[0].label;
    }
    if (!item.value) {
      item.label = availableFilters[0].options[1].label;
    }
    return item;
  });

  return (
    <CommonControls
      placeholder="Search"
      availableFilters={availableFilters}
      appliedFilters={appliedFilters}
      filters={filters}
      onOtherFiltersChange={onOtherFiltersChange}
      onTextFilterChange={onTextFilterChange}
    />
  );
};

export default Controls;
