export const actions = {
  SAVE: 'invitationTemplates/SAVE',
  SAVE_SUCCESS: 'invitationTemplates/SAVE_SUCCESS',
  LOAD: 'invitationTemplates/LOAD',
  LOAD_SUCCESS: 'invitationTemplates/LOAD_SUCCESS',
};

export const load = (eventId) => ({
  type: actions.LOAD,
  payload: { eventId },
});

export const loadSuccess = (invitationTemplate) => ({
  type: actions.LOAD_SUCCESS,
  payload: { invitationTemplate },
});

export const save = (eventId, invitationTemplate) => ({
  type: actions.SAVE,
  payload: { eventId, invitationTemplate },
});

export const saveSuccess = (invitationTemplate) => ({
  type: actions.SAVE_SUCCESS,
  payload: { invitationTemplate },
});
