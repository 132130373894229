import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { success as notifySuccess } from 'data/actions/notifications';
import { checkOtpFlow, configureOtp, otpUserInformation } from 'data/actions/users';
import { getCurrentUser } from 'data/reducers';
import AuthyAuth from 'images/icons/authy.png';
import GooogleAuth from 'images/icons/google.png';
import MSAuth from 'images/icons/ms.png';
import withRouter from 'routes/withRouter';

import { AppsList } from './components/AppsList';
import { AuthenticatorApps } from './components/AuthenticatorApps';
import { InstallGuide } from './components/InstallGuide';
import MFAModal from './components/MFAModal';
import { QRContainer } from './components/QRContainer';
import ResetMFA from './components/ResetMFA';
import { StartSetup } from './components/StartSetup';
import { MFAModalProps } from './components/types';
import { VerifyCode } from './components/VerifyCode';

const mapStateToProps = (state: any) => ({
  user: getCurrentUser(state)?.toJS(),
});

const mapDispatchToProps = {
  checkOtpFlow,
  otpUserInformation,
  configureOtp,
  notifySuccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConfigureMFA = ({
  user,
  otpRequestToken,
  show,
  otpUserInformation,
  configureOtp,
  onToggle,
  extras,
  showClose = false,
  onMFAConfigured,
}: MFAModalProps & ConnectedProps<typeof connector>) => {
  const [otpUrl, setOtpUrl] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [secret, setSecret] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const loadOTPUserInformation = async (otpToken: string) => {
    const response: any = await otpUserInformation?.(otpToken);
    const payload = response?.payload;
    setOtpUrl(payload?.otpUrl);
    setSecret(payload?.secret);
  };

  useEffect(() => {
    if (user && user.otp_configured) {
      setCurrentStep(0);
    }
  }, [user]);

  useEffect(() => {
    if (!otpToken && otpRequestToken) {
      setOtpToken(otpRequestToken);
      loadOTPUserInformation(otpRequestToken);
    }
  }, [otpToken, otpUrl, secret]);

  const subtitle = () => {
    switch (currentStep) {
      case 0:
        return 'Reset MFA';
      case 1:
        return 'Protect your account with MFA';
      case 2:
        return 'Get Authenticator App';
      case 3:
        return 'Download & Install Authenticator App';
      case 7:
        return 'Add to your Authenticator App';
      case 8:
        return 'Verify Code';
      default:
        return '';
    }
  };

  const next = () => {
    if (currentStep >= 7) {
      setCurrentStep(8);
    } else if (currentStep === 0) {
      setCurrentStep(7);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep <= 1) {
      setCurrentStep(1);
    } else {
      switch (currentStep) {
        case 5:
          setCurrentStep(3);
          break;
        case 6:
          setCurrentStep(3);
          break;
        case 7:
          setCurrentStep(2);
          break;
        default:
          setCurrentStep(currentStep - 1);
      }
    }
  };

  return (
    <div className="test">
      <MFAModal
        title={'Multi-Factor Authentication Setup'}
        subtitle={subtitle()}
        show={show}
        currentStep={currentStep}
        prev={prev}
        errorText={error}
        onCancel={onToggle}
        showClose={showClose}
      >
        {currentStep === 0 && (
          <ResetMFA
            otpToken={otpToken}
            reloadOTPUserInformation={loadOTPUserInformation}
            next={next}
          />
        )}
        {currentStep === 1 && <StartSetup currentStep={currentStep} next={next} extras={extras} />}
        {currentStep === 2 && (
          <AuthenticatorApps currentStep={currentStep} chooseStep={setCurrentStep} />
        )}
        {currentStep === 3 && <AppsList currentStep={currentStep} chooseStep={setCurrentStep} />}
        {currentStep === 4 && (
          <InstallGuide
            currentStep={currentStep}
            chooseStep={setCurrentStep}
            appName="Authy App"
            appIcon={AuthyAuth}
            appleStoreUrl={t('mfa_setup.url_authy_apple_store') || '#'}
            googlePlayUrl={t('mfa_setup.url_authy_google_play') || '#'}
            howToVideoUrl={t('mfa_setup.how_to_use_mobile_authy') || '#'}
          />
        )}
        {currentStep === 5 && (
          <InstallGuide
            currentStep={currentStep}
            chooseStep={setCurrentStep}
            appName="Google Authenticator"
            appIcon={GooogleAuth}
            appleStoreUrl={t('mfa_setup.url_google_auth_apple_store') || '#'}
            googlePlayUrl={t('mfa_setup.url_google_auth_google_play') || '#'}
            howToVideoUrl={t('mfa_setup.how_to_use_mobile_google_auth') || '#'}
          />
        )}
        {currentStep === 6 && (
          <InstallGuide
            currentStep={currentStep}
            chooseStep={setCurrentStep}
            appName="Microsoft Authenticator"
            appIcon={MSAuth}
            appleStoreUrl={t('mfa_setup.url_ms_auth_apple_store') || '#'}
            googlePlayUrl={t('mfa_setup.url_ms_auth_google_play') || '#'}
            howToVideoUrl={t('mfa_setup.how_to_use_mobile_ms_auth') || '#'}
          />
        )}
        {currentStep === 7 && (
          <QRContainer currentStep={currentStep} next={next} otpUrl={otpUrl} secret={secret} />
        )}
        {currentStep === 8 && (
          <VerifyCode
            currentStep={currentStep}
            // @ts-ignore
            configureOtp={configureOtp}
            otpToken={otpToken}
            setError={setError}
            onToggle={onToggle}
            onMFAConfigured={onMFAConfigured}
          />
        )}
      </MFAModal>
    </div>
  );
};

export default withRouter(connector(ConfigureMFA));
