import * as actions from 'data/actions/judgeProfiles';
import * as roleSummaries from '../actions/roleSummaries';
import * as teamAssignments from '../actions/teamAssignments';

import { combineActions, handleActions } from 'redux-actions';

import { LOAD_FOR_EVALUATION_RESULT } from '../actions/scores';

import { fromJS } from 'immutable';

const {
  actions: {
    LOAD,
    LOAD_SUCCESS,
    LOAD_FAIL,
    LOAD_RESULT,
    UPDATE_RESULT,
    LOAD_EVENT_JUDGES,
    LOAD_EVENT_JUDGES_RESULT,
  },
} = actions;

const initialState = fromJS({
  loading: false,
  data: {},
  byEvent: {},
});

const judgeProfiles = handleActions(
  {
    [combineActions(LOAD, LOAD_EVENT_JUDGES)]: (state, action) => state.set('loading', true),
    [LOAD_SUCCESS]: (state, action) => {
      return state
        .set('loading', false)
        .mergeIn(['data', action.payload.judgeProfile.id], fromJS(action.payload.judgeProfile));
    },
    [LOAD_EVENT_JUDGES_RESULT]: {
      next(state, action) {
        return state
          .set('loading', false)
          .mergeIn(['data'], fromJS(action.payload.entities.judgeProfiles))
          .setIn(['byEvent', action.payload.eventId], action.payload.result);
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [combineActions(LOAD_RESULT, UPDATE_RESULT)]: {
      next(state, action) {
        return state
          .set('loading', false)
          .mergeIn(['data'], fromJS(action.payload.entities.judgeProfiles));
      },
    },
    [LOAD_FAIL]: (state, action) => state.set('loading', false),
    [combineActions(
      roleSummaries.LOAD_SUMMARY_RESULT,
      teamAssignments.LOAD_TEAM_ASSIGNMENT_RESULT,
      teamAssignments.LOAD_TEAM_SUGGESTIONS_RESULT,
    )]: {
      next(state, action) {
        return state.mergeDeepIn(['data'], fromJS(action.payload.entities.judgeProfiles));
      },
    },
    [LOAD_FOR_EVALUATION_RESULT]: {
      next(state, action) {
        return state.mergeIn(['data'], fromJS(action.payload.entities.judgeProfiles));
      },
    },
  },
  initialState,
);

export const getJudgeProfileById = (state, id) => state.getIn(['data', String(id)]);

export const isLoading = (state) => state.get('loading');

export const getByEvent = (state, eventId) => {
  return state.getIn(['byEvent', eventId], []).map((judgeId) => state.getIn(['data', judgeId]));
};

export default judgeProfiles;
