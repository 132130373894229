import { Col, Row } from '@valid-eval/shared-react-components';
import { Component } from 'react';
import { getCurrentUser, getDocumentsForTeams, getEvent, getEventContent } from 'data/reducers';

import { BoxContainer } from 'components/Box';
import Content from 'components/Content';
import Documents from '../app/components/Documents';
import { Link } from 'react-router-dom';
import Title from 'components/Title';
import { connect } from 'react-redux';
import { getEnvVar } from 'config';
import { load as loadContent } from 'data/actions/contents';
import { load as loadDocuments } from 'data/actions/documents';
import { load as loadEvent } from 'data/actions/events';
import { load as loadUser } from 'data/actions/users';
import { teamNav } from 'utils/urls';
import withRouter from 'routes/withRouter';

export class SignUpConfirmation extends Component {
  componentDidMount() {
    const { loadContent, loadDocuments, event, currentUser, loadUser } = this.props;

    if (currentUser) {
      // Reload user
      loadUser(currentUser.get('id'));
    }

    loadContent(event.get('id'), 'Team', 'Signup Confirmation');
    loadDocuments(event.get('id'));
  }

  render() {
    const { title, notice, event, documents, currentUser, params } = this.props;
    const selfEvaluationUrl = teamNav.selfEvaluation(event.get('id'), params.team_id);
    const selfEvaluationRedirectUrl = currentUser
      ? selfEvaluationUrl
      : `/?redirect=${encodeURIComponent(selfEvaluationUrl)}`;
    const myProfileUrl = teamNav.profile(event.get('id'), params.team_id);

    return (
      <BoxContainer>
        <Row>
          <Col md={6}>
            <Title component="h1">{title}</Title>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md={documents ? 7 : 12}>
            <Content html={notice} />
          </Col>
          {documents && (
            <>
              <Col md={1} className="clearfix" />

              <Col md={4} className="well">
                <Documents list={documents} />
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-3">
          <Col md={12} className="text-center">
            {getEnvVar('REACT_APP_GOV_ENV') !== '1' && (
              <Link
                className="btn btn-primary"
                to={selfEvaluationRedirectUrl}
                style={{ marginRight: 20 }}
              >
                Complete Self Assessment
              </Link>
            )}
            <Link className="btn btn-success" to={myProfileUrl}>
              View Submitted Profile
            </Link>
          </Col>
        </Row>
      </BoxContainer>
    );
  }
}

export default SignUpConfirmation = withRouter(
  connect(
    (state, ownProps) => {
      const event = getEvent(state, ownProps.params.event_id);
      const params = { ...ownProps.params, event_id: event.get('id') };
      const title = getEventContent(state, event.get('id'), 'Team', 'Signup Confirmation', 'Title');
      const notice = getEventContent(
        state,
        event.get('id'),
        'Team',
        'Signup Confirmation',
        'Notice',
      );
      const documents = getDocumentsForTeams(state, { ...ownProps, params });
      const currentUser = getCurrentUser(state);

      return {
        event,
        title,
        notice,
        documents,
        currentUser,
      };
    },
    {
      loadEvent,
      loadContent,
      loadDocuments,
      loadUser,
    },
  )(SignUpConfirmation),
);
