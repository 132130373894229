import { Navigate, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import qs from 'query-string';
import withRouter from './withRouter';

function ConditionalRedirect({ selector, redirectPath, allowRedirectBack }) {
  return (Component) => {
    /**
     * This returns the given Component if the selectorResult prop is a truthy value,
     * a Navigate component to redirectPath otherwise
     */
    const _ConditionalRedirect = ({ selectorResult, redirectTo, ...props }) => {
      const location = useLocation();

      if (!selectorResult) {
        const redirect = location.pathname + location.search + location.hash;
        const redirectFullUrl = `${redirectTo}${
          allowRedirectBack ? `?${qs.stringify({ redirect })}` : ''
        }`;
        return <Navigate to={redirectFullUrl} />;
      }

      return <Component {...props} />;
    };

    /**
     * Evaluates the selector and the redirectPath, and pass the results as props to the
     * _ConditionalRedirect component
     */
    return withRouter(
      connect(
        (state, ownProps) => ({
          selectorResult: selector(state, ownProps),
          redirectTo:
            typeof redirectPath === 'string' ? redirectPath : redirectPath(state, ownProps),
        }),
        {},
      )(_ConditionalRedirect),
    );
  };
}

export default ConditionalRedirect;
