import { LinkContainer as BSLinkContainer } from 'react-router-bootstrap';

const LinkContainer = ({ to, children, ...props }: { to: string; children: React.ReactNode }) => {
  const url = new URL(to, window.location.href);

  return (
    // @ts-ignore
    <BSLinkContainer to={url.pathname} hash={url.hash} search={url.search} {...props}>
      {children}
    </BSLinkContainer>
  );
};

export default LinkContainer;
