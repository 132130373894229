import { ThunkDispatch, isRejectedWithValue } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { fetchEvent } from 'data/features/events';
import { error } from 'data/actions/notifications';
import { getEnvVar } from 'config';

export const useEventPolling = (eventId: string) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const loadEvent = async () => {
    const response = await dispatch(fetchEvent(eventId));
    if (!isRejectedWithValue(response)) return;

    dispatch(
      error(
        `The URL https://${getEnvVar('REACT_APP_HOST')}${pathname} does not exist on Valid Eval`,
      ),
    );
    navigate('/');
  };

  useEffect(() => {
    loadEvent();
    const fetchInterval = setInterval(loadEvent, 10_000);
    return () => clearInterval(fetchInterval);
  }, []);
};
