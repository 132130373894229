import { Row } from '@valid-eval/shared-react-components';
import uuid from 'uuid-random';

import DashboardEventItem from './DashboardEventItem';

export const DashboardEventsGrid = ({ user, events, title }) => {
  return (
    <>
      <Row className="mt-4">
        {events.map((event) => (
          <DashboardEventItem
            event={event}
            user={user}
            key={`${event.get('id')}-${uuid()}`}
            gap={events?.size < 3}
          />
        ))}
      </Row>
    </>
  );
};

export default DashboardEventsGrid;
