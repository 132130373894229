import { Col, Container, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Children, cloneElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import { load as loadEvent } from 'data/actions/events';
import { changeTeamSelectedPhase } from 'data/actions/phases';
import { setCurrentRole, unsetCurrentRole } from 'data/actions/support';
import { loadPhasesForTeam, loadPhasesForTeamSelfEvaluation } from 'data/actions/teams';
import { load as loadUser } from 'data/actions/users';
import {
  getEvent,
  getPhasesForCurrentTeam,
  getSelectedPhaseIdForCurrentTeam,
  getTeamWithCategoryNameFromURL,
  isLoadingPhases,
  userIsTeamEditorOrPrimary,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { teamNav } from 'utils/urls';
import { getEnvVar } from '../../../config';
import PDFHeader from '../results/components/PDFHeader';
import Navigation from './components/Navigation';
import noPhasesStyles from './Wrapper.module.scss';

const Wrapper = ({
  loadEvent,
  loadPhasesForTeam,
  loadPhasesForTeamSelfEvaluation,
  setCurrentRole,
  event,
  loading,
  phases,
  selectedPhaseId,
  params,
  teamWithCategory,
  isTeamMemberEditor,
  changeTeamSelectedPhase,
  location,
  children,
  unsetCurrentRole,
  query,
}) => {
  useEffect(() => {
    loadEvent(params.event_id);
    loadPhasesForTeam(params.team_id);
    loadPhasesForTeamSelfEvaluation(params.team_id);
    setCurrentRole({ role: 'team', eventId: params.event_id, id: params.team_id });

    return () => unsetCurrentRole();
  }, []);

  const handlePhaseChange = (phase) => {
    changeTeamSelectedPhase(params.team_id, phase.id);
  };

  if (loading) {
    return <Loading />;
  }

  const urlPhaseId = query.phase_id;
  const noPhases = phases.length === 0;
  const helpPage = location.pathname === teamNav.help(event.get('id'), params.team_id);
  const profilePage = location.pathname === teamNav.profile(event.get('id'), params.team_id);
  const membersPage = location.pathname === teamNav.members(event.get('id'), params.team_id);
  const confirmationPage =
    location.pathname === teamNav.confirmation(event.get('id'), params.team_id);

  return (
    <Container>
      <PDFHeader event={event} team={teamWithCategory} />
      <Row className="ms-0 me-0 d-block">
        <Col md={12} className={cx(noPhasesStyles.paddingTitleEvent, 'd-print-none')}>
          <Navigation
            event={event}
            phases={phases}
            teamId={params.team_id}
            teamWithCategory={teamWithCategory}
            selectedPhaseId={selectedPhaseId}
            onPhaseChange={handlePhaseChange}
            urlPhaseId={urlPhaseId}
          />
        </Col>
        <Col md={12}>
          {noPhases && !helpPage && !profilePage && !confirmationPage && !membersPage ? (
            <div>
              <div className={cx('p-5 rounded', noPhasesStyles.main)}>
                <p className={noPhasesStyles.headline}>
                  Your team is not (yet) active for evaluations.
                </p>
              </div>
              <p className={noPhasesStyles.subhead}>What does this mean?</p>
              <ul className={noPhasesStyles.ul}>
                <li className={noPhasesStyles.li}>
                  <em>
                    You have <u>successfully submitted</u> an application!
                  </em>{' '}
                  (Remember, you will NOT get a confirming email)
                </li>
                <li className={noPhasesStyles.li}>
                  One of the first steps the organizers of this cohort will take is to review your
                  application for compliance with the submission rules and relevance for the cohort
                </li>
                <li className={noPhasesStyles.li}>
                  Applicants meeting the cohort's rules are then "Activated," which makes them
                  eligible for formal review for the empaneled expert evaluators
                </li>
                <li className={noPhasesStyles.li}>
                  Once your submission is "Activated" you will see a different message when you log
                  in
                </li>
              </ul>
              <p className={noPhasesStyles.subhead}>What things can I do right now?</p>
              <ul>
                {event.get('profile_enabled') && (
                  <li className={noPhasesStyles.li}>
                    You can{' '}
                    <Link to={teamNav.profile(event.get('id'), params.team_id)}>
                      view and/or edit your Profile
                    </Link>
                  </li>
                )}
                {getEnvVar('REACT_APP_GOV_ENV') !== '1' && (
                  <li className={noPhasesStyles.li}>
                    Many people find it helpful to{' '}
                    <Link to={teamNav.selfEvaluation(event.get('id'), params.team_id)}>
                      perform a "Self-Evaluation"
                    </Link>{' '}
                    using the same workflow the expert evaluators will use to assess your
                    submissions
                  </li>
                )}
                <li className={noPhasesStyles.li}>
                  You may check out the instructions and file downloads from the{' '}
                  <Link to={teamNav.confirmation(event.get('id'), params.team_id)}>
                    Team Signup Confirmation page
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            cloneElement(Children.only(children), {
              event,
              selectedPhaseId,
              disabled: !isTeamMemberEditor,
            })
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEvent(state, ownProps.params.event_id);
      const phases = getPhasesForCurrentTeam(state, ownProps);
      const selectedPhaseId = getSelectedPhaseIdForCurrentTeam(state, ownProps);
      const loading = !event || isLoadingPhases(state);
      const teamWithCategory = getTeamWithCategoryNameFromURL(state, ownProps);
      const isTeamMemberEditor = userIsTeamEditorOrPrimary(state, ownProps);

      return {
        event,
        loading,
        phases,
        selectedPhaseId,
        teamWithCategory,
        isTeamMemberEditor,
      };
    },
    {
      loadPhasesForTeam,
      changeTeamSelectedPhase,
      loadEvent,
      loadPhasesForTeamSelfEvaluation,
      loadUser,
      setCurrentRole,
      unsetCurrentRole,
    },
  )(Wrapper),
);
