import ScoresWithLoading from 'components/Scores';
import withRouter from 'routes/withRouter';
import { isTeamLead } from 'utils';

function ResultScoring({
  rubric,
  evaluation,
  phaseSummary,
  selfScore,
  params: { score_id },
  event,
}) {
  return (
    <ScoresWithLoading
      isOrganizer={!(isTeamLead() && event.get('judging_ux_mode') === 'qualitative_only_mode')}
      rubric={rubric}
      evaluation={evaluation}
      phaseSummary={phaseSummary}
      selfScore={selfScore}
      selectedScore={score_id}
    />
  );
}

export default withRouter(ResultScoring);
