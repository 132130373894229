import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import * as Cells from './Cells';

import { TeamMember } from './types';

const columnHelper = createColumnHelper<TeamMember>();

export const columns: ColumnDef<TeamMember, any>[] = [
  columnHelper.accessor((original) => `${original.first_name || ''} ${original.last_name || ''}`, {
    id: 'Name',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.Text className="text-left" {...props} />,
  }),
  columnHelper.accessor('email', {
    id: 'Email',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
  }),
  columnHelper.accessor((original) => `${original.first_name || ''} ${original.last_name || ''}`, {
    id: 'NameAndEmail',
    header: (props) => (
      <CommonTableHeaders.SortableHeader className="text-left" {...props}>
        Name <br /> Email
      </CommonTableHeaders.SortableHeader>
    ),
    cell: Cells.NameAndEmail,
  }),
  columnHelper.accessor('role', {
    id: 'Role',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.Role,
  }),
  columnHelper.accessor('last_sent_at', {
    id: 'Last Date Sent',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.DateTime,
  }),
  columnHelper.accessor('accepted', {
    id: 'Accepted',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.Invitation,
  }),
  columnHelper.accessor('id', {
    id: 'Actions',
    header: CommonTableHeaders.SimpleHeader,
    cell: Cells.Actions,
  }),
];
