import cx from 'classnames';
import Styles from './Heat.module.scss';

const getClicksLabel = (range: number[]) => {
  const max = Math.max(...range);
  const min = Math.min(...range);
  if (min === max) return range[0];
  return `${Math.min(...range)} - ${Math.max(...range)}`;
};

export default function HeatmapPills({
  heatRanges,
}: {
  heatRanges: { [range: string]: number[] };
}) {
  return (
    <div className="flex-grow-1 d-flex">
      <div
        className={cx(
          'py-1 px-2 border border-2 rounded me-2 text-center',
          Styles.Heat1,
          Styles.HeatPill,
        )}
      >
        0 Clicks
      </div>
      {heatRanges['range-2'] && (
        <div
          className={cx(
            'py-1 px-2 border border-2 rounded me-2 text-center',
            Styles.Heat2,
            Styles.HeatPill,
          )}
        >
          {getClicksLabel(heatRanges['range-2'])}
        </div>
      )}
      {heatRanges['range-3'] && (
        <div
          className={cx(
            'py-1 px-2 border border-2 rounded me-2 text-center',
            Styles.Heat3,
            Styles.HeatPill,
          )}
        >
          {getClicksLabel(heatRanges['range-3'])}
        </div>
      )}
      {heatRanges['range-4'] && (
        <div
          className={cx(
            'py-1 px-2 border border-2 rounded me-2 text-center',
            Styles.Heat4,
            Styles.HeatPill,
          )}
        >
          {Math.min(...(heatRanges['range-4'] ? heatRanges['range-4'] : []))}+ Clicks
        </div>
      )}
    </div>
  );
}
