import { Container, Row } from '@valid-eval/shared-react-components';

import { useEffect } from 'react';
import Nav from 'components/Navigation';
import PhaseNavigation from 'components/PhaseNavigation';
import EventTitle from 'screens/teams/components/Title';

import cx from 'classnames';
import { teamNav } from 'utils/urls';
import { getEnvVar } from '../../../../config';

const noSelectorNav = (event, teamId, disableResults) => (
  <Nav
    pills
    items={[
      {
        url: teamNav.results(event.get('id'), teamId),
        title: 'Results',
        disabled: disableResults,
      },
      ...(getEnvVar('REACT_APP_GOV_ENV') !== '1'
        ? [
            {
              url: teamNav.selfEvaluation(event.get('id'), teamId),
              title: 'Self-Evaluation',
            },
          ]
        : []),
      { url: teamNav.profile(event.get('id'), teamId), title: 'My Profile' },
      { url: teamNav.confirmation(event.get('id'), teamId), title: 'Signup Confirmation Page' },
    ]}
  />
);

const selectorNav = (event, teamId, phases, onPhaseChange, selectedPhaseId) => (
  <PhaseNavigation phases={phases} onPhaseChange={onPhaseChange} selectedId={selectedPhaseId}>
    <Nav
      pills
      subNav
      items={[
        { url: teamNav.results(event.get('id'), teamId), title: 'Results' },
        ...(getEnvVar('REACT_APP_GOV_ENV') !== '1'
          ? [
              {
                url: teamNav.selfEvaluation(event.get('id'), teamId),
                title: 'Self-Evaluation',
              },
            ]
          : []),
        { url: teamNav.profile(event.get('id'), teamId), title: 'My Profile' },
        { url: teamNav.confirmation(event.get('id'), teamId), title: 'Signup Confirmation Page' },
      ]}
    />
  </PhaseNavigation>
);

const Navigation = ({
  event,
  phases,
  onPhaseChange,
  selectedPhaseId,
  teamId,
  teamWithCategory,
  urlPhaseId,
}) => {
  useEffect(() => {
    if (urlPhaseId && phases?.length) {
      const phase = phases.find((phase) => phase.id === urlPhaseId);
      if (phase) {
        onPhaseChange(phase);
      }
    }
  }, [urlPhaseId, phases, onPhaseChange]);

  return (
    <Container className={cx('clearfix d-print-none')}>
      <Row className="float-start">
        <EventTitle
          logo={event.get('logo_url')}
          title={event.get('name')}
          skipSubTitle
          teamWithCategory={teamWithCategory}
          selector={phases.length > 1}
          role={'team'}
        />
      </Row>
      <Row className="float-end">
        {phases.length <= 1
          ? noSelectorNav(event, teamId, phases.length === 0)
          : selectorNav(event, teamId, phases, onPhaseChange, selectedPhaseId)}
      </Row>
    </Container>
  );
};

Navigation.displayName = 'Navigation';
export default Navigation;
