import './styles.scss';

import Item from './Item';

import cx from 'classnames';

const Breadcrumb = ({ children, pills, inline, inlineBlock, noPadding }) => (
  <ul className={cx('steps', { pills, inline, inlineBlock, noPadding })}>{children}</ul>
);

export { Item };
export default Breadcrumb;
