import { FormGroup } from '@valid-eval/shared-react-components';

const OrphanDetailsList = ({ details }) => (
  <FormGroup className="mt-3 mb-3">
    {details.valueSeq().map((detail, i) => (
      <div key={i}>
        <strong>{detail.get('name')}</strong>: {detail.get('value')}
      </div>
    ))}
  </FormGroup>
);

export default OrphanDetailsList;
