import { CommonControls } from 'components/Tables/CommonControls';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';

import { searchableFields } from './helpers';

import { Judge } from 'screens/app/events/shared/types';

const AVAILABLE_FILTERS = [
  {
    title: 'Status',
    field: 'active',
    type: 'checkbox',
    options: [
      {
        label: 'Finished',
        value: 'Finished',
      },
      {
        label: 'Judging',
        value: 'Judging',
      },
      {
        label: 'Not Started',
        value: 'Not Started',
      },
      {
        label: 'Unmatched',
        value: 'Unmatched',
      },
    ],
  },
];

type ControlsProps = {
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  data: Judge[];
  textFilter: string;
};

const Controls = ({
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  data,
  textFilter,
}: ControlsProps) => {
  const availableFilters = useMemo(() => {
    const statusCount = data
      ?.filter((row) => filterByTextFilter(textFilter, row, searchableFields))
      .reduce(
        (count, row) => {
          (count as any)[row.status] += 1;
          return count;
        },
        {
          Finished: 0,
          Judging: 0,
          'Not Started': 0,
          Unmatched: 0,
        },
      );

    const availableFilters = cloneDeep(AVAILABLE_FILTERS);
    availableFilters[0].options[0].label = `Finished (${statusCount?.Finished})`;
    availableFilters[0].options[1].label = `Judging (${statusCount?.['Judging']})`;
    availableFilters[0].options[2].label = `Not Started (${statusCount?.['Not Started']})`;
    availableFilters[0].options[3].label = `Unmatched (${statusCount?.['Unmatched']})`;
    return availableFilters;
  }, [data, textFilter]);

  const appliedFilters = filters.map((item) => {
    if (item.value === 'Finished') {
      item.label = availableFilters[0].options[0].label;
    }
    if (item.value === 'Judging') {
      item.label = availableFilters[0].options[1].label;
    }
    if (item.value === 'Not Started') {
      item.label = availableFilters[0].options[2].label;
    }
    if (item.value === 'Unmatched') {
      item.label = availableFilters[0].options[3].label;
    }
    return item;
  });

  return (
    <CommonControls
      placeholder="Sarch by Judge Name"
      availableFilters={availableFilters}
      appliedFilters={appliedFilters}
      filters={filters}
      onOtherFiltersChange={onOtherFiltersChange}
      onTextFilterChange={onTextFilterChange}
    />
  );
};

export default Controls;
