import { Button } from '@valid-eval/shared-react-components';
import LinkContainer from 'components/LinkContainer';
import { getEvent, getSelectedPhaseId, getTeamsStatus } from 'data/reducers';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

const JudgesNavigation = ({ eventId, event, info }) => {
  const location = useLocation();

  const teamsActive = info?.teams_active || 0;
  const teamsRegistered = info?.teams_registered || 0;

  return (
    <div className={'mb-3 me-3'}>
      {!isTeamLead() && (
        <>
          <LinkContainer to={organizerNav.teamsAdd(eventId)}>
            <Button
              className="me-2"
              variant={
                location?.pathname === organizerNav.teamsAdd(eventId) ? 'primary' : 'default'
              }
              disabled={!Boolean(teamsRegistered)}
            >
              Teams
            </Button>
          </LinkContainer>
          <LinkContainer to={organizerNav.teamAssignments(eventId)}>
            <Button
              className="me-2"
              variant={
                location?.pathname === organizerNav.teamAssignments(eventId) ? 'primary' : 'default'
              }
              disabled={!Boolean(teamsActive)}
            >
              {isNavigator() ? 'Matches' : 'Match'}
            </Button>
          </LinkContainer>
        </>
      )}
      <LinkContainer to={organizerNav.teamManage(eventId)}>
        <Button
          className="me-2"
          variant={location?.pathname === organizerNav.teamManage(eventId) ? 'primary' : 'default'}
          disabled={!Boolean(teamsActive)}
        >
          {isNavigator() ? 'Progress' : 'Manage'}
        </Button>
      </LinkContainer>
    </div>
  );
};

export default withRouter(
  connect((state, ownProps) => {
    const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
    const info = getTeamsStatus(state, selectedPhaseId)?.toJS?.();
    const event = getEvent(state, ownProps.params.event_id)?.toJS?.();
    return { info, event };
  }, {})(JudgesNavigation),
);
