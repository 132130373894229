import React from 'react';
import GenericModal from 'components/GenericModal';
import StatusAndRangeSelector from './StatusAndRangeSelector'; // Adjust the import path as needed

const renderBodyContent = () => (
  <>
    Exporting the "for Team" PDF will disable your ability to modify or add additional comments for
    this team. Only selection committee comments will be available once this PDF is generated.
  </>
);

const renderBodyContentMultiple = () => (
  <div className="alert alert-warning text-justify mt-3">
    Exporting the "for Team" PDF will disable your ability to modify or add additional comments for
    the selected teams. Only selection committee comments will be available once these PDFs are
    generated.
  </div>
);

const ConfirmPrintPDFForTeamsModal = ({
  show,
  onCancel,
  onConfirm,
  multiple = false,
  isForSelectionCommittee = false,
  showStatusAndRangeSelector = false,
  phaseFinalRank,
  pdfTeamRange,
  totalEvaluations,
  pdfTeamStatusCheckboxes,
  handleOnChangePDFTeamStatusCheckbox,
  setPdfTeamRange,
}) => {
  return (
    <GenericModal
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      name={'confirm-print-pdf-for-teams'}
      title={'Export Team Results PDF'}
      body={
        <>
          {showStatusAndRangeSelector && (
            <>
              <p>Choose the status of the teams you would like to include in the PDF.</p>
              <StatusAndRangeSelector
                phaseFinalRank={phaseFinalRank}
                pdfTeamRange={pdfTeamRange}
                totalEvaluations={totalEvaluations}
                pdfTeamStatusCheckboxes={pdfTeamStatusCheckboxes}
                handleOnChangePDFTeamStatusCheckbox={handleOnChangePDFTeamStatusCheckbox}
                setPdfTeamRange={setPdfTeamRange}
              />
            </>
          )}
          {isForSelectionCommittee &&
            (multiple ? renderBodyContentMultiple() : renderBodyContent())}{' '}
          {/* <-- Conditional Rendering based on the reportType */}
        </>
      }
      cancelButton={'Cancel'}
      confirmButton={'Continue'}
    />
  );
};

export default ConfirmPrintPDFForTeamsModal;
