import * as judgeProfilesAPI from '../services/judgeProfiles';
import * as phasesApi from '../services/phases';
import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const actions = {
  LOAD: 'judgeProfiles/LOAD',
  LOAD_RESULT: 'judgeProfiles/LOAD_RESULT',
  RECUSE: 'judgeProfiles/RECUSE',
  RECUSE_RESULT: 'judgeProfiles/RECUSE_RESULT',
  UPDATE: 'judgeProfiles/UPDATE',
  UPDATE_RESULT: 'judgeProfiles/UPDATE_RESULT',
  CREATE: 'judgeProfiles/CREATE',
  CREATE_RESULT: 'judgeProfiles/CREATE_RESULT',
  LOAD_SUCCESS: 'judgeProfiles/LOAD_SUCCESS',
  LOAD_FAIL: 'judgeProfiles/LOAD_FAIL',
  SAVE: 'judgeProfiles/SAVE',
  SAVE_SUCCESS: 'judgeProfiles/SAVE_SUCCESS',
  SAVE_FAIL: 'judgeProfiles/SAVE_FAIL',
  LOAD_FOR_ASSIGNMENTS: 'judgeProfiles/LOAD_FOR_ASSIGNMENTS',
  LOAD_EVENT_JUDGES: 'judgeProfiles/LOAD_EVENT_JUDGES',
  LOAD_EVENT_JUDGES_RESULT: 'judgeProfiles/LOAD_EVENT_JUDGES_RESULT',
  LOAD_FOR_PHASE_REQUEST: 'judgeProfiles/LOAD_FOR_PHASE_REQUEST',
  LOAD_FOR_PHASE_RESULT: 'judgeProfiles/LOAD_FOR_PHASE_RESULT',
  LOAD_UNASSIGNED_TEAMS_REQUEST: 'teams/LOAD_UNASSIGNED_TEAMS_REQUEST',
  LOAD_UNASSIGNED_TEAMS_RESULT: 'teams/LOAD_UNASSIGNED_TEAMS_RESULT',
  LOAD_PREFILLED_VALUES_PENDING: 'judgeProfiles/LOAD_PREFILLED_VALUES_PENDING',
  LOAD_PREFILLED_VALUES_FULFILLED: 'judgeProfiles/LOAD_PREFILLED_VALUES_FULFILLED',
  LOAD_PREFILLED_VALUES_REJECTED: 'judgeProfiles/LOAD_PREFILLED_VALUES_REJECTED',
};

export const loadForAssignments = (eventId) => ({
  type: actions.LOAD_FOR_ASSIGNMENTS,
  payload: { eventId },
});

export const loadEventJudges = (eventId) => {
  return {
    [RSAA]: {
      ...judgeProfilesAPI.loadForEvent(eventId),
      types: [
        actions.LOAD_EVENT_JUDGES,
        {
          type: actions.LOAD_EVENT_JUDGES_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => ({
              eventId,
              ...normalize(payload.judge_profiles, [schemas.judgeProfile]),
            })),
        },
        actions.LOAD_EVENT_JUDGES_RESULT,
      ],
    },
  };
};

export const update = (id, values) => {
  const bussinessDisciplineUUID = values.get('business_discipline_uuid');
  const primaryJobFunctionUUID = values.get('primary_job_function_uuid');
  const tagIds = values.get('categories').valueSeq().flatten().toJS();
  const panels = values.get('panels')?.toJS?.() || {};

  if (bussinessDisciplineUUID) tagIds.push(bussinessDisciplineUUID);
  if (primaryJobFunctionUUID) tagIds.push(primaryJobFunctionUUID);

  const judge_payload = { tag_ids: tagIds, details: values.get('details').toJS(), panels };

  return {
    [RSAA]: {
      ...judgeProfilesAPI.update(id, judge_payload),
      types: [
        actions.UPDATE,
        {
          type: actions.UPDATE_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              return normalize(payload.judge_profile, schemas.judgeProfile);
            }),
        },
        actions.UPDATE_RESULT,
      ],
    },
  };
};

export const load = (id) => {
  return {
    [RSAA]: {
      ...judgeProfilesAPI.load(id),
      types: [
        actions.LOAD,
        {
          type: actions.LOAD_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              return normalize(payload.judge_profile, schemas.judgeProfile);
            }),
        },
        actions.LOAD_RESULT,
      ],
    },
  };
};

export const recuse = (id, teamId, phaseId) => {
  return {
    [RSAA]: {
      ...judgeProfilesAPI.recuse(id, teamId, phaseId),
      types: [
        actions.RECUSE,
        {
          type: actions.RECUSE_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => ({
              judgeId: id,
              judgeEvaluationId: payload.judge_evaluation.id,
            })),
        },
        actions.RECUSE_RESULT,
      ],
    },
  };
};

export const loadSuccess = (judgeProfile) => ({
  type: actions.LOAD_SUCCESS,
  payload: { judgeProfile },
});

export const loadFail = (error) => ({
  type: actions.LOAD_FAIL,
  payload: { error },
});

export const save = (event_id, invitation_id, another_profile, values) => {
  const tag_ids = values.get('categories').valueSeq().flatten().toJS();

  const details = values.get('details') ? values.get('details').toJS() : {};
  const rest = values.delete('details').toJS();

  const judge_payload = {
    tag_ids,
    details,
    ...rest,
  };

  return {
    [RSAA]: {
      ...judgeProfilesAPI.create(event_id, invitation_id, another_profile, judge_payload),
      types: [actions.CREATE, actions.CREATE_RESULT, actions.CREATE_RESULT],
    },
  };
};

export const saveSuccess = (judgeProfile) => ({
  type: actions.SAVE_SUCCESS,
  payload: { judgeProfile },
});

export const saveFail = (judgeProfile) => ({ type: actions.SAVE_FAIL });

export const loadForPhase = (phaseId) => {
  return {
    [RSAA]: {
      ...phasesApi.judges(phaseId),
      types: [
        actions.LOAD_FOR_PHASE_REQUEST,
        actions.LOAD_FOR_PHASE_RESULT,
        actions.LOAD_FOR_PHASE_RESULT,
      ],
    },
  };
};

export const loadUnassignedTeams = (phaseId, judgeId) => {
  return {
    [RSAA]: {
      ...judgeProfilesAPI.unassignedTeams(phaseId, judgeId),
      types: [
        actions.LOAD_UNASSIGNED_TEAMS_REQUEST,
        actions.LOAD_UNASSIGNED_TEAMS_RESULT,
        actions.LOAD_UNASSIGNED_TEAMS_RESULT,
      ],
    },
  };
};

export const getPrefilledValues = () => {
  return {
    [RSAA]: {
      ...judgeProfilesAPI.getPrefilledValues(),
      types: [
        actions.LOAD_PREFILLED_VALUES_PENDING,
        {
          type: actions.LOAD_PREFILLED_VALUES_FULFILLED,
          payload: (_, __, res) => getJSON(res),
        },
        actions.LOAD_PREFILLED_VALUES_REJECTED,
      ],
    },
  };
};
