import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { filterResults } from 'components/Tables/filteringHelpers';
import { FiltersState } from 'components/Tables/types';
import { updateFinalRank } from 'data/actions/evaluations';
import { organizerNav } from 'utils/urls';

import Controls from './components/Controls';
import { columns } from './components/Definition';
import { filterByStatus, getMaxAndMinSigma, searchableFields } from './components/helpers';
import Table from './components/Table';
import { ResultsTableProps, TeamResult } from './components/types';
import { isTeamLead } from 'utils';

const ResultsTable = ({
  list,
  phase,
  event,
  user,
  onStatusChange,
  onJudgeClick,
  updateFinalRank,
}: ResultsTableProps) => {
  const navigate = useNavigate();
  const [finalRankOrder, setFinalRankOrder] = useState<'asc' | 'desc'>('asc');
  const [filter, setFilter] = useState<FiltersState>({ textFilter: '', otherFilters: [] });
  const [data, setData] = useState<TeamResult[]>([]);
  const { maxSigma, minSigma } = getMaxAndMinSigma(data);
  const isPanelMatching = phase.matching_mode === 'panel_matching';
  const isFinalRank = phase.final_rank;
  const isQualitativeMode = event.judging_ux_mode === 'qualitative_only_mode';

  useEffect(() => {
    if (isFinalRank) {
      list.sort((a, b) => {
        const result = a.final_rank - b.final_rank;
        return finalRankOrder === 'asc' ? result : -result;
      });
      setData([...list]);
    } else {
      setData(list);
    }
  }, [list, phase, finalRankOrder]);

  // Table Filtering
  const handleTextFilterChange = (textFilter: string) => {
    setFilter({ ...filter, textFilter });
  };

  const handleOtherFiltersChange = (otherFilters: any[]) => {
    setFilter({ ...filter, otherFilters: [...otherFilters] });
  };

  // Compare teams
  const handleCompare = (teamResults: TeamResult[]) => {
    const selectedEvaluationIds = teamResults.map((r) => r.team).join(',');
    const url = organizerNav.compareTeams(
      event.id,
      selectedEvaluationIds,
      tableModel.getIsAllRowsExpanded(),
    );
    navigate(url);
  };

  // Expand rows
  const handleExpand = () => {
    tableModel.toggleAllRowsExpanded(!tableModel.getIsAllRowsExpanded());
  };

  const handleUpdateFinalRank = debounce((data: TeamResult[]) => {
    updateFinalRank(
      phase.id,
      data.map((d) => [d.id, d.final_rank]),
    );
  }, 1000);

  // Reorder rows
  const reorderRow = async (draggedRowIndex: number, targetRowIndex: number) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as TeamResult);

    let rank = finalRankOrder === 'asc' ? 0 : data.length + 1;
    let step = finalRankOrder === 'asc' ? 1 : -1;

    const newData = data.map((d) => {
      rank += step;
      return { ...d, final_rank: rank };
    });

    setData(newData);
    handleUpdateFinalRank(newData);
  };

  // Program Rank enabled
  useEffect(() => {}, [data.length, phase, finalRankOrder]);

  const tableModel = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection() {
      const rowSelection = this.state?.rowSelection || {};
      return Object.keys(rowSelection).length < 3;
    },
    meta: {
      phase,
      event,
      user,
      maxSigma,
      minSigma,
      onStatusChange,
      onJudgeClick,
    },
    state: {
      globalFilter: filter,
      columnVisibility: {
        panel: isPanelMatching,
        team_prefix: !isPanelMatching,
        valid_score_valid_z: !(isQualitativeMode && isTeamLead()),
        status: !isFinalRank,
      },
    },
    enableSorting: !isFinalRank,
    globalFilterFn: filterResults(
      isPanelMatching ? searchableFields : searchableFields.filter((f) => f !== 'panel'),
      filterByStatus,
    ),
  });

  const selectedTeamResults = tableModel.getSelectedRowModel().rows.map((r) => r.original);

  return (
    <>
      <Controls
        textFilter={filter.textFilter}
        data={data}
        expandable={event.team_description}
        isExpanded={tableModel.getIsAllRowsExpanded()}
        onExpand={handleExpand}
        filters={filter.otherFilters}
        onCompare={handleCompare}
        onOtherFiltersChange={handleOtherFiltersChange}
        onTextFilterChange={handleTextFilterChange}
        selectedRows={selectedTeamResults}
        finalRank={isFinalRank}
      />
      <Table
        finalRankOrder={finalRankOrder}
        setFinalRankOrder={setFinalRankOrder}
        tableModel={tableModel}
        phase={phase}
        onReorderRow={reorderRow}
      />
    </>
  );
};

export default connect(null, { updateFinalRank })(ResultsTable);
