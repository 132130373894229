import { Button, Form } from '@valid-eval/shared-react-components';
import { passwordStrength, required, sameAs } from 'components/FormFields/validations';

import Errors from '../../components/Errors';
import { Password } from 'components/FormFields';

import { reduxForm } from 'redux-form/immutable';

const passwordValidators = [required, passwordStrength];
const passwordConfirmationValidators = [required];

const validate = (values) => {
  const errors = {
    password_confirmation: undefined,
  };

  errors.password_confirmation = sameAs('password', 'New password')(
    values.get('password_confirmation'),
    { password: values.get('password') },
  );

  return errors;
};

const ResetPasswordForm = ({ handleSubmit, error, pristine, submitting, valid }) => {
  const preventSubmit = pristine || submitting || !valid;
  return (
    <div>
      <Errors
        errors={error && [error]}
        alertClasses={{ alert: 'alert alert-danger alert-inline' }}
      />
      <Form onSubmit={handleSubmit}>
        <div className="login-input">
          <Password label="New password" name="password" validate={passwordValidators} />
        </div>
        <div className="login-input">
          <Password
            label="Confirm new password"
            name="password_confirmation"
            validate={passwordConfirmationValidators}
          />
        </div>
        <div className="mt-5 d-grid">
          <Button variant="success" type="submit" disabled={preventSubmit}>
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default reduxForm({ form: 'reset-password', validate })(ResetPasswordForm);
