import { Button, Col, Row } from '@valid-eval/shared-react-components';
import QRCode from 'react-qr-code';
import Styles from './Styles.module.scss';
import { StepProps } from './types';

export const QRContainer = ({ currentStep, next, otpUrl, secret }: StepProps) => {
  const back = '#FFFFFF';
  const fore = '#000000';
  const size = 170;

  if (currentStep !== 7) {
    return null;
  }

  const handleNext = () => {
    next?.();
  };

  return (
    <>
      <Row>
        <p>User your authenticator app to scan the QR code shown on your screen:</p>
      </Row>
      <Row className="text-center">
        <QRCode
          id="qr-code"
          value={otpUrl as string}
          bgColor={back}
          fgColor={fore}
          size={size}
          className={Styles.QRImage}
        />
      </Row>
      <Row className="text-center">
        <p>
          <label className={Styles.subtitle}>Can't scan the code?</label> you can enter the code
          manually to your authenticator app:
        </p>
        <p>
          <strong>{secret}</strong>
        </p>
      </Row>
      <Row>
        <Col className={Styles.ContinueBtn}>
          <Button variant={'primary'} onClick={handleNext} id="set-mfa-code-btn">
            Continue
          </Button>
        </Col>
      </Row>
      <br />
    </>
  );
};

export default QRContainer;
