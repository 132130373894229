import cx from 'classnames';
import { getValidScoresFromClickedCriteria } from 'data/reducers/scores';
import { Component } from 'react';
import RubricDimension from '../RubricDimension';
import Styles from './RubricScoring.module.scss';

class JudgeEvaluation extends Component {
  heatScore = (criteriaId) =>
    this.props.clickedCriteria.count((criterion) => criteriaId === criterion) ? 0.2 : 0;

  render() {
    const { rubric, scores, criteria, clickedCriteria } = this.props;
    const grades = rubric.get('grades');
    const dimensions = rubric.get('dimensions');

    return (
      <div>
        <div className={Styles.container}>
          <div className={cx(Styles.dimensionsContainer, Styles.fullWidth, Styles.alignCenter)}>
            {dimensions.map((dimension, index) => {
              const dimId = dimension.get('id');
              return (
                <RubricDimension
                  dimension={dimension}
                  grades={grades}
                  key={`dim-${dimId}`}
                  coloredBackground={index % 2 === 0}
                  selectedCriteria={criteria.get(dimId)}
                  score={scores.get(dimId)}
                  heatmap={this.heatScore}
                  validScores={getValidScoresFromClickedCriteria(
                    grades,
                    dimension,
                    clickedCriteria,
                  )}
                  readOnly
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

JudgeEvaluation.displayName = 'JudgeEvaluation';
export default JudgeEvaluation;
