import { Col, Container, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { List, fromJS } from 'immutable';
import { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form/immutable';

import { load as loadDisciplines } from 'data/actions/businessDisciplines';
import { load as loadFunctions } from 'data/actions/jobFunctions';
import { error as notifyError, success } from 'data/actions/notifications';
import {
  changePassword,
  linkedInFetchProfile,
  linkedinCleanup,
  load as loadUser,
  save as saveUser,
} from 'data/actions/users';
import {
  getBusinessDisciplines,
  getCurrentUser,
  getCurrentUserLinkedinProfile,
  getCurrentUserProfiles,
  getCurrentUserProfilesGrouped,
  getJobFunctions,
  getUsersLoading,
} from 'data/reducers';
import ConfigureMFA from 'screens/components/MFASetup/ConfigureMFA';

import { getEnvVar } from '../../config';
import styles from './Account.module.scss';
import ApiKeys from './components/ApiKeys';
import ConfigureOTP from './components/ConfigureOTP';
import LinkedinAccount from './components/LinkedinAccount';
import NotificationSettingsForm from './components/NotificationSettingsForm';
import ProfileForm from './components/ProfileForm';
import ProfileList from './components/ProfileList';
import UpdatePasswordForm from './components/UpdatePasswordForm';

const NOTIFICATION_SETTINGS = fromJS({
  team_signup: { push: true, email: false, daily_digest: true },
  judge_signup: { push: true, email: false, daily_digest: true },
});

class Account extends Component {
  state = {
    updatePassword: {
      errors: null,
      submitting: false,
    },
    linkedin: {
      errors: null,
      submitting: false,
    },
    notificationSettings: {
      errors: null,
      submitting: false,
    },
    showMFAModal: false,
  };

  componentDidMount() {
    this.props.loadUser('me');
    this.props.loadDisciplines();
    this.props.loadFunctions();
  }

  onChangePasswordSubmit = (values) => {
    const { user, changePassword, success, notifyError } = this.props;
    const { currentPassword, password, passwordConfirmation, otp } = values.toJS();

    this.setState({ updatePassword: { errors: null, submitting: true } });

    return changePassword(
      user.get('id'),
      currentPassword,
      password,
      passwordConfirmation,
      otp,
    ).then((action) => {
      if (action.error) {
        if (action.payload.status !== 500) {
          const errors = action.payload.response.error;
          this.setState({ updatePassword: { errors, submitting: false } });
        } else {
          notifyError('There was a problem updating your password');
        }
      } else {
        success('Password updated');
        this.setState({ updatePassword: { ...this.state.updatePassword, submitting: false } });
      }
    });
  };

  onNotificationSettingsSubmit = (values) => {
    const { user, success } = this.props;

    this.setState({ notificationSettings: { errors: null, submitting: true } });
    const newUser = user.setIn(['notification_settings'], values);

    this.props
      .saveUser(newUser.toJS())
      .then(this.handleProfileError)
      .then((action) => {
        if (action) success('All changes saved', 'Success');
      })
      .then(() => {
        this.setState({
          notificationSettings: { ...this.state.notificationSettings, submitting: false },
        });
      });
  };

  handleProfileError = (action) => {
    if (action.error) {
      const errorMessage = action.payload.response.error;
      throw new SubmissionError({ _error: errorMessage });
    }
    return action;
  };

  onProfileSubmit = (values) => {
    return this.props
      .saveUser(values.toJS())
      .then(this.handleProfileError)
      .then((action) => {
        if (action) this.props.success('All changes saved', 'Success');
      });
  };

  onLinkedinLogin = () => {
    this.setState({ linkedin: { submitting: true } });
    this.props.linkedInFetchProfile();
  };

  onLinkedinCleanup = () => {
    const { user, linkedinCleanup, success } = this.props;

    this.setState({ linkedin: { submitting: true } });

    linkedinCleanup(user.get('id'))
      .then((action) => {
        if (action.error) {
          if (action.payload.status !== 500) {
            const errors = action.payload.response.error;
            this.setState({ linkedin: { errors } });
          } else {
            notifyError('There was a problem getting your LinkedIn account information');
          }

          throw new Error(action.payload.response.error);
        }
      })
      .then(() => this.props.loadUser('me'))
      .then(() => success('Successfully removed your LinkedIn account'))

      .catch((error) => console.error)
      .then(() => {
        this.setState({ linkedin: { ...this.state.linkedin, submitting: false } });
      });
  };

  toggleModal = () => {
    this.setState({ showMFAModal: !this.state.showMFAModal });
  };

  reloadUser = () => {
    this.props.loadUser('me');
  };

  render() {
    const {
      activeProfiles,
      businessDisciplines,
      inactiveProfiles,
      jobFunctions,
      linkedinProfile,
      loading,
      user,
    } = this.props;

    return (
      <Container>
        <Row className={('me-0 ms-0', styles.wrapper)}>
          <Col md={12}>
            <h1>Global Account Profile</h1>
          </Col>
        </Row>
        <Row className={styles.wrapper}>
          <Col md={6}>
            <ProfileForm
              loading={loading}
              initialValues={user}
              jobFunctions={jobFunctions}
              businessDisciplines={businessDisciplines}
              onSubmit={this.onProfileSubmit}
            />
            <ApiKeys />
            <div>
              <ProfileList
                list={activeProfiles}
                title="Editable Profiles"
                noElement="No editable profiles"
              />
            </div>
            <div>
              <ProfileList
                list={inactiveProfiles}
                title="Archived Profiles"
                noElement="No archived profiles"
              />
            </div>
          </Col>
          <Col md={6} className={cx(styles.colStyle, 'ps-2')}>
            <div>
              {getEnvVar('REACT_APP_GOV_ENV') === '0' && (
                <LinkedinAccount
                  onLogin={this.onLinkedinLogin}
                  onCleanup={this.onLinkedinCleanup}
                  profile={linkedinProfile}
                  errors={this.state.linkedin.errors}
                  submitting={this.state.linkedin.submitting}
                />
              )}
            </div>
            <div>
              <h2 className="mt-4">Change password</h2>
              <UpdatePasswordForm
                onSubmit={this.onChangePasswordSubmit}
                submitErrors={this.state.updatePassword.errors}
                isSubmitting={this.state.updatePassword.submitting}
                isOtpConfigured={user.get('otp_configured')}
              />
            </div>
            <div>
              <ConfigureOTP
                token={user.get('otp_request_token')}
                isOtpConfigured={user.get('otp_configured')}
                toggleMFA={this.toggleModal}
              />
            </div>
            {this.props.isOrganizer && (
              <div>
                <h2 className="mt-4">Notification Settings</h2>
                <NotificationSettingsForm
                  initialValues={NOTIFICATION_SETTINGS.mergeDeep(user.get('notification_settings'))}
                  onSubmit={this.onNotificationSettingsSubmit}
                  submitErrors={this.state.notificationSettings.errors}
                  isSubmitting={this.state.notificationSettings.submitting}
                />
              </div>
            )}
          </Col>
        </Row>
        {user.get('otp_request_token') && (
          <ConfigureMFA
            show={this.state.showMFAModal}
            onToggle={this.toggleModal}
            otpRequestToken={user.get('otp_request_token')}
            extras={{ userName: user.get('first_name') }}
            showClose={true}
            onMFAConfigured={this.reloadUser}
          />
        )}
      </Container>
    );
  }
}

export default connect(
  (state) => {
    const profiles = getCurrentUserProfilesGrouped(state);
    const user = getCurrentUser(state);
    const isOrganizer = user
      .get('event_roles', List())
      .toJS()
      .find((role) => role.name === 'Organizer');
    return {
      activeProfiles: profiles.get('active') || List(),
      businessDisciplines: getBusinessDisciplines(state),
      inactiveProfiles: profiles.get('inactive') || List(),
      jobFunctions: getJobFunctions(state),
      linkedinProfile: getCurrentUserLinkedinProfile(state),
      loading: getUsersLoading(state),
      profiles: getCurrentUserProfiles(state),
      user,
      isOrganizer: !!isOrganizer,
    };
  },
  {
    changePassword,
    loadUser,
    saveUser,
    success,
    notifyError,
    loadDisciplines,
    loadFunctions,
    linkedinCleanup,
    linkedInFetchProfile,
  },
)(Account);
