import TeamSelector from 'components/TeamSelector';
function ScoringSubNavigation({ teams, selectedId, onTeamChange, classes, hideRank }) {
  return (
    <TeamSelector
      teams={teams}
      selectedId={selectedId}
      onTeamChange={onTeamChange}
      hideRank={hideRank}
    />
  );
}

ScoringSubNavigation.displayName = 'ScoringSubNavigation';
export default ScoringSubNavigation;
