import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { filterResults } from 'components/Tables/filteringHelpers';
import Table from 'components/Tables/Table';
import { FiltersState } from 'components/Tables/types';

import Controls from './components/Controls';
import { columns } from './components/Definition';
import { filterByStatus, searchableFields } from './components/helpers';
import { TeamDraft } from './components/types';
import { isNavigator } from 'utils';

export type TeamDraftsTableProps = {
  data: TeamDraft[];
  selectedTeams: TeamDraft[];
  onSelectedTeamsChange: (teams: TeamDraft[]) => void;
};

const TeamDraftsTable = ({ data, selectedTeams, onSelectedTeamsChange }: TeamDraftsTableProps) => {
  const [globalFilter, setGlobalFilter] = useState<FiltersState>({
    textFilter: '',
    otherFilters: [],
  });
  const [rowSelection, setRowSelection] = useState({});

  // Set selection for external component
  useEffect(() => {
    const selectedRows = Object.keys(rowSelection).map((key) => (data as any)[key]);
    onSelectedTeamsChange(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  // Selection cleared out outside this component
  useEffect(() => {
    if (selectedTeams.length === 0 && selectedTeams.length !== Object.keys(rowSelection).length) {
      setRowSelection({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeams]);

  // Table Filtering
  const handleTextFilterChange = (textFilter: string) => {
    setGlobalFilter({ ...globalFilter, textFilter });
  };

  const handleOtherFiltersChange = (otherFilters: any[]) => {
    setGlobalFilter({ ...globalFilter, otherFilters: [...otherFilters] });
  };

  // Row selection
  const enableRowSelection = () => {
    return !isNavigator();
  };

  const tableModel = useReactTable({
    data,
    columns: columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection,
    state: {
      globalFilter,
      rowSelection,
    },
    globalFilterFn: filterResults(searchableFields, filterByStatus),
  });

  return (
    <>
      <Controls
        textFilter={globalFilter.textFilter}
        data={data}
        filters={globalFilter.otherFilters}
        onOtherFiltersChange={handleOtherFiltersChange}
        onTextFilterChange={handleTextFilterChange}
      />
      <Table tableModel={tableModel} />
    </>
  );
};

export default TeamDraftsTable;
