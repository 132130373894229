import { getVersionUri } from 'utils';

const OauthConfirmCreateUser = () => {
  return (
    <div className="container">
      <div className="login-container mb-5">
        <h1 className="login-title mt-3 mb-3">&nbsp;</h1>
        <div className="login-box d-flex flex-column">
          <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
          <div style={{ flex: 1 }}>
            <h1 className="text-center text-light mt-3 mb-3">
              Thank you for confirming your identity. <br />
              <br /> Do you already have a Valid Eval account?
            </h1>
            <div className="mt-5 d-grid">
              <a className="btn btn-success" href="/api/v1/auth/keycloak">
                Create new VE account
              </a>

              <br />
              <br />
              <a
                className="btn btn-default"
                href="/oauth-need-login?redirect=/api/v1/auth/keycloak"
              >
                Log in to existing VE account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OauthConfirmCreateUser;
