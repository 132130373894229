import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';

import { TeamDraft } from './types';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { sortLatestMessage, sortProgress, sortStrings } from 'components/Tables/sortingHelpers';
import * as Columns from './Columns';

const columnHelper = createColumnHelper<TeamDraft>();

export const columns: ColumnDef<TeamDraft, any>[] = [
  columnHelper.accessor('id', {
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),

  columnHelper.accessor('name', {
    id: 'Name',
    header: CommonTableHeaders.SortableHeader,
    cell: Columns.DraftName,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('email', {
    id: 'Email',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Text,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('teamDetailsCount', {
    id: 'Required Fields',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.ProgressWithAmounts,
    sortingFn: sortProgress,
  }),
  columnHelper.accessor('artifactsCount', {
    id: 'Required Artifacts',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.ProgressWithAmounts,
    sortingFn: sortProgress,
  }),
  columnHelper.accessor('latestMessage', {
    id: 'Last Message',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.LatestMessage,
    sortingFn: sortLatestMessage,
  }),
];
