import { Col } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';

import Loading from 'components/Loading';
import { loadForPhase, update } from 'data/actions/evaluations';
import {
  getCurrentUser,
  getEvent,
  getIsLoadingEvaluations,
  getPhase,
  getPhaseNavigatorsViewResultsEnabled,
  getResultsForSelectedPhase,
  getSelectedPhaseId,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';
import { organizerNav } from 'utils/urls';

import JudgeScoresModal from './components/JudgeScoresModal';
import ResultsNav from './components/ResultsNav';
import { ResultsTable, ZeroState } from './components/ResultsTable';

const Results = ({
  loadForPhase,
  loading,
  evaluations,
  event,
  phase,
  canAccessResults,
  user,
  selectedPhaseId,
  update,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [judge, setJudge] = useState(null);

  useEffect(() => {
    loadForPhase(selectedPhaseId);
  }, [selectedPhaseId]);

  if (loading) {
    return <Loading />;
  }

  if (!canAccessResults && phase) {
    return <Navigate to={organizerNav.status(event.id)} />;
  }

  function handleJudgeClick(judge) {
    setIsModalOpened(true);
    setJudge(judge);
  }

  function handleHideModal() {
    setIsModalOpened(false);
    setJudge(null);
  }

  return (
    <>
      <JudgeScoresModal
        judge={judge}
        eventId={event.id}
        isOpen={isModalOpened}
        onHide={handleHideModal}
      />
      <div className="shift-up">
        <ResultsNav eventId={event.id} summary disableButtons={evaluations.length < 1} />
        <Col md={12} className="p-0">
          <h1 className="mb-3 mt-5">{phase?.name} Results</h1>
          {evaluations.length > 0 && phase && event ? (
            <ResultsTable
              list={evaluations}
              event={event}
              phase={phase}
              user={user}
              onStatusChange={update}
              onJudgeClick={handleJudgeClick}
            />
          ) : (
            <ZeroState />
          )}
        </Col>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const loading = getIsLoadingEvaluations(state);
  const evaluations = getResultsForSelectedPhase(state, ownProps);
  const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
  const event = getEvent(state, ownProps.params.event_id)?.toJSON();
  const phase = getPhase(state, selectedPhaseId)?.toJSON();
  const user = getCurrentUser(state)?.toJSON();

  return {
    user,
    loading,
    evaluations,
    selectedPhaseId,
    event,
    phase,
    canAccessResults: !isNavigator() || getPhaseNavigatorsViewResultsEnabled(state, ownProps),
  };
};

export default withRouter(connect(mapStateToProps, { loadForPhase, update })(Results));
