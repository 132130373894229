import * as surveys from 'data/services/surveys';
import { RSAA, getJSON } from 'redux-api-middleware';

export const actions = {
  LOAD_PENDING: 'surveys/LOAD_PENDING',
  LOAD_FULFILLED: 'surveys/LOAD_FULFILLED',
  LOAD_REJECTED: 'surveys/LOAD_REJECTED',
  VALIDATE_PENDING: 'surveys/VALIDATE_PENDING',
  VALIDATE_FULFILLED: 'surveys/VALIDATE_FULFILLED',
  VALIDATE_REJECTED: 'surveys/VALIDATE_REJECTED',
  SAVE_PENDING: 'surveys/SAVE_PENDING',
  SAVE_FULFILLED: 'surveys/SAVE_FULFILLED',
  SAVE_REJECTED: 'surveys/SAVE_REJECTED',
};

export const loadSurvey = () => {
  return {
    [RSAA]: {
      ...surveys.loadSurvey(),
      types: [
        actions.LOAD_PENDING,
        {
          type: actions.LOAD_FULFILLED,
          payload: (_, __, res) => getJSON(res),
        },
        actions.LOAD_REJECTED,
      ],
    },
  };
};

export const validate = ({ eventId, phaseId, roleId, type }) => {
  return {
    [RSAA]: {
      ...surveys.validate({ eventId, phaseId, roleId, type }),
      types: [
        actions.VALIDATE_PENDING,
        {
          type: actions.VALIDATE_FULFILLED,
          payload: (_, __, res) => getJSON(res),
        },
        actions.VALIDATE_REJECTED,
      ],
    },
  };
};

export const saveAnswers = ({ eventId, phaseId, roleId, type, answers }) => {
  return {
    [RSAA]: {
      ...surveys.saveAnswers({ eventId, phaseId, roleId, type, answers }),
      types: [
        actions.SAVE_PENDING,
        {
          type: actions.SAVE_FULFILLED,
          payload: (_, __, res) => getJSON(res),
        },
        actions.SAVE_REJECTED,
      ],
    },
  };
};
