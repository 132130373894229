import { Team } from '../../../shared/types';

export const statusType = {
  In: 0,
  Out: 0,
  Pending: 0,
  Judging: 0,
  Unmatched: 0,
  incomplete: 0,
  feedbackViewed: 0,
  feedbackNotViewed: 0,
};

// Team Manage Section

export const searchableFields = ['name', 'prefix', 'panel'];

export const filterByStatus = (filterList: any[], team: Team) => {
  if (filterList.length) {
    const statusToShow = filterList.filter((f) => f.field === 'status').map((f) => f.value);
    const showWithIncompleteScores = filterList.find(
      (f) => f.field === 'progress' && f.value === 'incomplete',
    );
    const showWithFeedbackViewed = filterList.find((f) => f.field === 'viewed' && f.value === true);
    const showWithoutFeedbackViewed = filterList.find(
      (f) => f.field === 'viewed' && f.value === false,
    );

    if (statusToShow.length && !statusToShow.includes(team.status)) return false;
    if (showWithIncompleteScores && team.missingScores < 1) return false;
    if (showWithFeedbackViewed && !team.viewed) return false;
    if (showWithoutFeedbackViewed && team.viewed) return false;

    return true;
  }
  return true;
};
