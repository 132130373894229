import { useLocation, useParams } from 'react-router-dom';
import './index.scss';

import { getSelectedPhaseId, getTeamsStatus } from 'data/reducers';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { organizerNav } from 'utils/urls';
import PhaseSelector from './PhaseSelector';
import { isTeamLead } from 'utils';

const PhaseNavigation = ({
  phases,
  onPhaseChange,
  selectedId,
  disabled,
  drafts,
  children,
  info,
}) => {
  const location = useLocation();
  const params = useParams();
  if (drafts) {
    const pendingDrafts = info?.pending_drafts || 0;
    // If drafts is true, add a Drafts phase to the beginning of the phases array
    phases = [...(isTeamLead() ? [] : [{ id: -1, name: `Drafts (${pendingDrafts})` }]), ...phases];
    // If the url is draft section, selectedId should be -1
    selectedId = location.pathname === organizerNav.teamDraft(params.event_id) ? -1 : selectedId;
  }

  return (
    <div className="wrapper d-print-none">
      <PhaseSelector
        phases={phases}
        onPhaseChange={onPhaseChange}
        selectedId={selectedId}
        disabled={disabled}
      />
      {children}
    </div>
  );
};

export default withRouter(
  connect((state, ownProps) => {
    const selectedPhaseId = getSelectedPhaseId(state, ownProps.params.event_id);
    const info = getTeamsStatus(state, selectedPhaseId)?.toJS?.();
    return { info };
  })(PhaseNavigation),
);
