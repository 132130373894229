import { useEffect, useState } from 'react';

import { DisabledStep, InstructionsStep, SignUpWizardSteps } from './signUpWizardSteps';
import { Step, StepInfo } from './types';
import { Event } from 'data/features/eventsTypes';
import { TeamDraft } from 'data/features/teamDraftTypes';

export const filterNavegableSteps = (steps: Step[]) => steps.filter((step) => step.navigation);

const useSignUpWizardState = (
  event: Event | undefined,
  user: any | undefined,
  draft: TeamDraft | undefined,
) => {
  const [steps, setSteps] = useState(SignUpWizardSteps);
  const [activeStepKey, setActiveStepKey] = useState<number>(steps[0].key);

  // Calculate active step and disabled steps based on event, user and draft
  useEffect(() => {
    let newActiveStepKey = activeStepKey;
    if (!event?.signup_enabled) {
      // Signup is disabled
      setSteps((steps) =>
        steps.map((step) => ({
          ...step,
          disabled: step.key !== DisabledStep.key,
        })),
      );
      newActiveStepKey = DisabledStep.key;
    } else if (!user?.id) {
      // Signup enabled but no user logged in
      setSteps((steps) =>
        steps.map((step) => ({
          ...step,
          disabled: step.key !== InstructionsStep.key,
        })),
      );
    } else {
      // Signup enabled and user logged in
      setSteps((steps) =>
        steps.map((step) => ({
          ...step,
          disabled: step.key === DisabledStep.key || (step.needDraft && !draft?.id),
        })),
      );
    }

    if (activeStepKey === DisabledStep.key && event?.signup_enabled) {
      newActiveStepKey = InstructionsStep.key;
    }

    setActiveStepKey(newActiveStepKey);
  }, [event?.signup_enabled, user?.id, draft?.id]);

  // Get current active step
  const activeIndex = steps.findIndex((step) => step.key === activeStepKey);
  const activeStep = steps[activeIndex];

  const navigableSteps = filterNavegableSteps(steps);

  // Calculate previous and next steps based on the current active step
  const nextActiveStep = filterNavegableSteps(steps.slice(activeIndex + 1, steps.length)).find(
    (step) => !step.disabled,
  );

  const prevActiveStep = filterNavegableSteps(steps.slice(0, activeIndex))
    .reverse()
    .find((step) => !step.disabled);

  // Steps navigation functions
  const getStepByKey = (key: number) => steps.find((step) => step.key === key);
  const nextStep = () => (nextActiveStep ? setActiveStepKey(nextActiveStep.key) : null);
  const prevStep = () => (prevActiveStep ? setActiveStepKey(prevActiveStep.key) : null);

  // Handle step info change
  const handleStepInfoChange = (key: number) => (info: StepInfo) => {
    const completed = info?.completedFieldsCount >= info?.requiredFieldsCount;
    const stateText = `${info?.completedFieldsCount} of ${info?.requiredFieldsCount} ${completed ? 'completed' : 'required'}`;

    setSteps((steps) =>
      steps.map((step) =>
        step.key === key
          ? {
              ...step,
              info: { ...info, stateText },
              status: completed ? 'completed' : 'inProgress',
            }
          : step,
      ),
    );
  };

  // Calculate progress
  const progress = navigableSteps.reduce(
    (progress, step) => {
      if (!step.info?.requiredFieldsCount) return progress;

      const requiredFieldsCount = progress.requiredFieldsCount + step.info.requiredFieldsCount;
      const completedFieldsCount = progress.completedFieldsCount + step.info.completedFieldsCount;
      const percentage = (completedFieldsCount / requiredFieldsCount) * 100;
      return {
        requiredFieldsCount,
        completedFieldsCount,
        percentage,
      };
    },
    { requiredFieldsCount: 0, completedFieldsCount: 0, percentage: 0 },
  );

  progress.percentage = Math.round(progress.percentage);

  return {
    steps,
    progress,
    navigableSteps,
    activeStepKey,
    setActiveStepKey,
    activeStep,
    nextStep,
    prevStep,
    hasNextStep: !!nextActiveStep,
    hasPrevStep: !!prevActiveStep,
    getStepByKey,
    onStepInfoChange: handleStepInfoChange,
  };
};

export default useSignUpWizardState;
