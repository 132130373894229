import * as actions from '../actions/teamAssignments';
import * as judgeActions from '../actions/judgeAssignments';

import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

const phaseAndRoleId = (phaseId, roleId) => `${phaseId}_${roleId}`;

const rolesInitialState = fromJS({
  loading: false,
  loadingSuggestions: false,
  suggestions: {},
  suggestionsIdsByPhaseAndRole: {},
  data: {},
  assignmentsIdsByPhaseAndRole: {},
});

const roleAssignments = (roleActions, roleAsEntity, roleAsId) =>
  handleActions(
    {
      [roleActions.LOAD_ROLE_ASSIGNMENT_REQUEST]: (state, action) => {
        return state.set('loading', true);
      },
      [roleActions.LOAD_ROLE_ASSIGNMENT_RESULT]: {
        next(state, action) {
          const { entities, result, phaseId } = action.payload;
          const assignments = entities[roleAsEntity];
          const roleId = action.payload[roleAsId];
          return state
            .set('loading', false)
            .mergeIn(['data'], fromJS(assignments))
            .setIn(['assignmentsIdsByPhaseAndRole', phaseAndRoleId(phaseId, roleId)], result);
        },
      },
      [roleActions.LOAD_ROLE_SUGGESTIONS_REQUEST]: (state, action) =>
        state.set('loadingSuggestions', true),
      [roleActions.LOAD_ROLE_SUGGESTIONS_RESULT]: {
        next(state, action) {
          const {
            entities: { suggestions },
            phaseId,
            result,
          } = action.payload;
          const roleId = action.payload[roleAsId];
          return state
            .set('loadingSuggestions', false)
            .mergeIn(['suggestions'], fromJS(suggestions))
            .setIn(['suggestionsIdsByPhaseAndRole', phaseAndRoleId(phaseId, roleId)], result);
        },
        throw(state, action) {
          return state.set('loadingSuggestions', false);
        },
      },
      [roleActions.REMOVE_ASSIGNMENT_FROM_ROLE_RESULT]: {
        next(state, action) {
          const { phaseId, id } = action.payload;
          const roleId = action.payload[roleAsId];
          const key = phaseAndRoleId(phaseId, roleId);
          const withoutDeletedId = state
            .getIn(['assignmentsIdsByPhaseAndRole', key])
            .filter((pairId) => id !== pairId);
          return state
            .deleteIn(['data', id])
            .setIn(['assignmentsIdsByPhaseAndRole', key], withoutDeletedId);
        },
      },
      [roleActions.SAVE_ROLE_ASSIGNMENTS]: {
        next(state, action) {
          const { phaseId, result, entities } = action.payload;
          const roleId = action.payload[roleAsId];
          const assignments = entities[roleAsEntity];
          const key = phaseAndRoleId(phaseId, roleId);
          const keyPath = ['assignmentsIdsByPhaseAndRole', key];
          const currentIds = state.getIn(keyPath);
          return state
            .mergeIn(['data'], fromJS(assignments))
            .setIn(keyPath, currentIds.concat(Array.of(result)))
            .updateIn(
              ['suggestionsIdsByPhaseAndRole', key],
              (ids) => ids && ids.filter((id) => id !== result),
            );
        },
      },
    },
    rolesInitialState,
  );

const getTopFifteenSuggestionsInPhaseForRole = (roleAsId) =>
  createSelector(
    (state) => state.get('suggestions'),
    (state, props) =>
      state.getIn(
        ['suggestionsIdsByPhaseAndRole', phaseAndRoleId(props.phaseId, props[roleAsId])],
        [],
      ),
    (suggestions, ids) => {
      const top15 = ids.slice(0, 15);
      return top15.map((id) => suggestions.get(id));
    },
  );

export const getTopFifteenSuggestionsInPhaseForTeam =
  getTopFifteenSuggestionsInPhaseForRole('teamId');
export const getTopFifteenSuggestionsInPhaseForJudge =
  getTopFifteenSuggestionsInPhaseForRole('judgeId');

export const isLoadingRoleAssignments = (state) => state.get('loading');

const getAssignmentsByPhaseForRole = (roleAsId) =>
  createSelector(
    (state) => state.get('data'),
    (state, props) =>
      state.getIn(
        ['assignmentsIdsByPhaseAndRole', phaseAndRoleId(props.phaseId, props[roleAsId])],
        [],
      ),
    (assignments, ids) => ids.map((id) => assignments.get(id)),
  );

export const getAssignmentsByPhaseForTeam = getAssignmentsByPhaseForRole('teamId');
export const getAssignmentsByPhaseForJudge = getAssignmentsByPhaseForRole('judgeId');

const actionsForTeams = {
  LOAD_ROLE_ASSIGNMENT_REQUEST: actions.LOAD_TEAM_ASSIGNMENT_REQUEST,
  LOAD_ROLE_ASSIGNMENT_RESULT: actions.LOAD_TEAM_ASSIGNMENT_RESULT,
  LOAD_ROLE_SUGGESTIONS_REQUEST: actions.LOAD_TEAM_SUGGESTIONS_REQUEST,
  LOAD_ROLE_SUGGESTIONS_RESULT: actions.LOAD_TEAM_SUGGESTIONS_RESULT,
  REMOVE_ASSIGNMENT_FROM_ROLE_RESULT: actions.REMOVE_ASSIGNMENT_FROM_TEAM_RESULT,
  SAVE_ROLE_ASSIGNMENTS: actions.SAVE_TEAM_ASSIGNMENTS,
};
const teamAsEntity = 'teamAssignments';
const teamAsId = 'teamId';

export const teamAssignments = roleAssignments(actionsForTeams, teamAsEntity, teamAsId);

const actionsForJudges = {
  LOAD_ROLE_ASSIGNMENT_REQUEST: judgeActions.LOAD_JUDGE_ASSIGNMENTS_REQUEST,
  LOAD_ROLE_ASSIGNMENT_RESULT: judgeActions.LOAD_JUDGE_ASSIGNMENTS_RESULT,
  LOAD_ROLE_SUGGESTIONS_REQUEST: judgeActions.LOAD_JUDGE_SUGGESTIONS_REQUEST,
  LOAD_ROLE_SUGGESTIONS_RESULT: judgeActions.LOAD_JUDGE_SUGGESTIONS_RESULT,
  REMOVE_ASSIGNMENT_FROM_ROLE_RESULT: judgeActions.REMOVE_ASSIGNMENT_FROM_JUDGE_RESULT,
  SAVE_ROLE_ASSIGNMENTS: judgeActions.SAVE_JUDGE_ASSIGNMENTS,
};
const judgeAsEntity = 'judgeAssignments';
const judgeAsId = 'judgeId';

export const judgeAssignments = roleAssignments(actionsForJudges, judgeAsEntity, judgeAsId);
