import { Col, Modal, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import Errors from 'components/Errors';

import Styles from './Styles.module.scss';
import { ModalProps } from './types';

export const MFAModal = ({
  title,
  subtitle,
  children,
  show,
  onCancel,
  currentStep,
  prev,
  errorText,
  showClose = false,
}: ModalProps) => {
  const handlePrev = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    prev?.();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={showClose ? onCancel : undefined}
      enforceFocus={false}
      className={Styles.Modal}
      backdropClassName={Styles.ModalBackdrop}
      centered
    >
      <Modal.Header className={cx(Styles.modalHeader, 'text-center')} closeButton={showClose}>
        <Row>
          {currentStep > 1 && (
            <Col xs={1}>
              <Link to={'#'} className={cx(Styles.Link, Styles.GoBackLink)} onClick={handlePrev}>
                <i className={cx('fa-regular fa-arrow-left ms-3')} />
              </Link>
            </Col>
          )}
          <Col xs={currentStep > 1 ? 11 : 12}>
            <span>{title}</span>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className={Styles.body}>
        <Row className="mt-12 mb-12">
          <Col xs={12}>
            {errorText && (
              <Errors
                errors={[errorText]}
                alertClasses={{ alert: 'alert alert-danger alert-inline' }}
              />
            )}
            <strong className={Styles.subtitle}>{subtitle}</strong>
            {children}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MFAModal;
