import { Actions } from '../actions/artifactItems';
import { List, fromJS } from 'immutable';

import { createSelector } from 'reselect';
import { combineActions, handleActions } from 'redux-actions';

const initialState = fromJS({
  loading: {},
  loaded: {},
  data: {},
  byTeam: {},
  byPhase: {},
  byJudgeEvaluation: {},
});

const reducer = handleActions(
  {
    [Actions.LOAD_FOR_TEAM]: (state, action) => {
      return state.setIn(['loading', action.payload.teamId], true);
    },
    [combineActions(Actions.LOAD_FOR_TEAM_RESULT, Actions.SAVE_FOR_TEAM_RESULT)]: {
      next(state, action) {
        const {
          entities: { artifactItems },
          result,
          teamId,
        } = action.payload;
        return state
          .setIn(['loading', action.payload.teamId], false)
          .setIn(['loaded', action.payload.teamId], true)
          .setIn(['byTeam', teamId], fromJS(result))
          .mergeIn(['data'], fromJS(artifactItems));
      },
      throw(state, action) {
        return state.setIn(['loading', action.payload.teamId], false);
      },
    },
  },
  initialState,
);

export const byTeam = createSelector(
  (state) => state.get('data'),
  (state, teamId) => state.getIn(['byTeam', teamId], List()),
  (data, ids) => ids.map((id) => data.get(String(id))),
);

export const byPhase = createSelector(
  (state) => state.get('data'),
  (state, phaseId) => state.getIn(['byPhase', phaseId], List()),
  (data, ids) => ids.map((id) => data.get(String(id))),
);

export const byJudgeEvaluation = createSelector(
  (state) => state.get('data'),
  (state, judgeEvaluationId) => state.getIn(['byJudgeEvaluation', judgeEvaluationId], List()),
  (data, ids) => ids.map((id) => data.get(String(id))),
);

export const byJudgeEvaluationLookup = createSelector(
  (state) => state.get('data'),
  (state) => state.get('byJudgeEvaluation'),
  (artifactItems, idsByJudgeEvaluation) =>
    idsByJudgeEvaluation.map((ids) => {
      return ids.map((id) => artifactItems.get(String(id)));
    }),
);

export default reducer;
