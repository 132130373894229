import { Alert, Button, Card, Col, Row } from '@valid-eval/shared-react-components';
import classNames from 'classnames';
import Loading from 'components/Loading';
import LinkedInButton from 'screens/components/LinkedInButton';
import classes from './LinkedinAccount.module.scss';

const fieldLabels = {
  _error: 'Error',
};

const LinkedinProfile = ({ profile, onCleanup, submitting }) => {
  const name = `${profile.get('first_name')} ${profile.get('last_name')}`;
  const photo = profile.get('profile_photo_url');
  const headline = profile.get('headline');
  const currentPositionCompany = profile.get('current_position_company');
  const profileUrl = profile.get('profile_url');

  return (
    <div>
      <Card className={classes.profilePanel}>
        <Card.Body>
          {submitting && <Loading />}

          {!submitting && (
            <Row>
              <div className="col-auto pe-0">
                <img width={64} height={64} src={photo} alt="" className={`${classes.img} me-3`} />
              </div>
              <Col className="ps-0">
                <h4>{name}</h4>
                <h5>{headline}</h5>
                <h6>{currentPositionCompany}</h6>
                {profileUrl && (
                  <a href={profileUrl} target="_blank" rel="noopener noreferrer">
                    View LinkedIn Profile
                  </a>
                )}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {!submitting && (
        <>
          <div className="float-end">
            <Button variant="link" onClick={onCleanup}>
              Remove LinkedIn Account
            </Button>
          </div>
          <div className="clearfix" />
        </>
      )}
    </div>
  );
};

LinkedinProfile.displayName = 'LinkedinProfile';

const Placeholder = ({ height = 18, width = '100%', last = false }) => {
  return (
    <span
      style={{
        width,
        height: `${height}px`,
        display: 'block',
        backgroundColor: '#555',
        marginBottom: last ? '0' : '5px',
      }}
    />
  );
};

const LinkedinSignInForm = ({ onLogin, submitting }) => {
  return (
    <div>
      <Card className={classes.profilePanel}>
        <Card.Body>
          {submitting && <Loading />}

          {!submitting && (
            <Row>
              <div className="col-auto pe-0">
                <img
                  width={64}
                  height={64}
                  src={'/images/question-mark.png'}
                  alt="LinkedIn profile"
                  className={classes.img}
                />
              </div>

              <Col className="ps-0">
                <Placeholder />
                <Placeholder />
                <Placeholder />
                <Placeholder last />
              </Col>
            </Row>
          )}
          {!submitting && (
            <LinkedInButton
              login={onLogin}
              text="Sign In with LinkedIn"
              className={classNames(classes.subTitle, classes.signInButton)}
            />
          )}
          {!submitting && (
            <p className={classes.content}>
              Streamline your login process, complete your profile and verify your identity by
              linking your account with your LinkedIn account
            </p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

LinkedinSignInForm.displayName = 'LinkedinSignInForm';

const Errors = ({ errors = {} }) => {
  if (Object.keys(errors).length) {
    return (
      <Alert variant="danger">
        {Object.keys(errors).map((key) => (
          <div key={key}>
            <strong>{fieldLabels[key]}</strong> {errors[key]}
          </div>
        ))}
      </Alert>
    );
  }

  return null;
};

const LinkedinAccount = ({ profile, onCleanup, onLogin, submitting, errors }) => {
  return (
    <div className="clearfix pb-2">
      <h2>LinkedIn Account</h2>
      {!submitting && errors && (
        <Errors errors={errors} alertClasses={{ alert: 'alert alert-danger alert-inline' }} />
      )}
      {profile ? (
        <LinkedinProfile profile={profile} onCleanup={onCleanup} submitting={submitting} />
      ) : (
        <LinkedinSignInForm onLogin={onLogin} submitting={submitting} />
      )}
    </div>
  );
};

export default LinkedinAccount;
