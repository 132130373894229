import { branch, renderComponent } from 'recompose';

import cx from 'classnames';
import styles from './Loading.module.scss';

const Loading = ({ text = '' }) => (
  <div className={cx(styles.container)}>
    {text && <span className="me-2">{text}&nbsp;&nbsp;</span>}
    <i className={cx('fa-duotone fa-spinner fa-spin fa-2x', styles.faPulse)} />
  </div>
);

export const spinnerWhileLoading = (hasLoaded) =>
  branch((props) => !hasLoaded(props), renderComponent(Loading));

export default Loading;
