import { List as ListI, Map as MapI, fromJS } from 'immutable';

import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import * as assignments from '../actions/assignments';
import * as evaluations from '../actions/evaluations';
import * as judgeAssignments from '../actions/judgeAssignments';
import * as roleSummaries from '../actions/roleSummaries';
import { actions, loadMultipleResult } from '../actions/teams';

const { SAVE_JUDGE_ASSIGNMENTS } = assignments;

const initialState = fromJS({
  loading: false,
  data: {},
  byEvent: {},
});

export const mergeTeams = (state, teams) => {
  if (teams) {
    Object.keys(teams).forEach((key) => {
      let updatedTeam = fromJS(teams[key]);
      let currentTeam = state.getIn(['data', key]);
      if (currentTeam && updatedTeam) {
        updatedTeam = currentTeam.mergeWith((oldValue, newValue, mergeKey) => {
          if (mergeKey === 'details') {
            return newValue;
          }
          if (ListI.isList(oldValue) && ListI.isList(newValue)) {
            return newValue;
          } else if (MapI.isMap(oldValue) && MapI.isMap(newValue)) {
            return oldValue.mergeDeep(newValue);
          }

          return newValue;
        }, updatedTeam);
      }
      state = state.setIn(['data', key], updatedTeam);
    });
  }
  return state;
};

const teams = handleActions(
  {
    [actions.LOAD]: (state, action) => state.set('loading', true),
    [actions.LOAD_FAIL]: (state, action) => state.set('loading', false),
    [actions.LOAD_RESULT]: {
      next(state, action) {
        state.set('loading', false);
        return mergeTeams(state, action.payload.entities.teams);
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [loadMultipleResult]: (state, action) =>
      state.set('loading', false).mergeDeepIn(['data'], fromJS(action.payload)),
    [combineActions(assignments.loadJudgeAssignmentResult, assignments.loadJudgeSuggestionsResult)]:
      (state, action) => state.mergeDeepIn(['data'], fromJS(action.payload.teams)),
    [actions.LOAD_EVENT_TEAMS_RESULT]: {
      next(state, action) {
        const keyPath = ['byEvent', action.payload.eventId];
        state.set('loading', false);
        state = mergeTeams(state, action.payload.entities.teams);
        return state.setIn(keyPath, action.payload.result);
      },
    },
    [combineActions(
      SAVE_JUDGE_ASSIGNMENTS,
      evaluations.LOAD_MULTIPLE_RESULT,
      roleSummaries.LOAD_SUMMARY_RESULT,
      actions.UPDATE_RESULT,
      judgeAssignments.LOAD_JUDGE_ASSIGNMENTS_RESULT,
      judgeAssignments.LOAD_JUDGE_SUGGESTIONS_RESULT,
    )]: {
      next(state, action) {
        return mergeTeams(state, action.payload.entities.teams);
      },
    },
    [combineActions(
      actions.LOAD_MEMBERS_FULFILLED,
      actions.RESEND_INVITATION_FULFILLED,
      actions.DELETE_INVITATION_FULFILLED,
      actions.UPDATE_MEMBER_ROLE_FULFILLED,
    )](state, { payload }) {
      const { teamId, entities, result } = payload;
      return state.setIn(['byTeamMembers', teamId], result).mergeIn(['data'], fromJS(entities));
    },
  },
  initialState,
);

export const getTeamById = (state, id) => state.getIn(['data', id]);

export const getTeamBySlug = (state, slug) =>
  state
    .get('data')
    .filter((v, k) => v.getIn(['information', 'slug']) === slug)
    .valueSeq()
    .first();

export const getTeamsForEvent = (state, eventId) =>
  state.get('data').filter((v) => v.get('event_id') === eventId);

export const getListByEvent = (state, eventId) => getTeamsForEvent(state, eventId).valueSeq();

export const getLookup = (state) => state.get('data');

export const getByEvent = (state, eventId) =>
  state.getIn(['byEvent', eventId], []).map((teamId) => state.getIn(['data', teamId]));

export const getMembers = createSelector(
  (state, teamId) => state.getIn(['byTeamMembers', teamId], []),
  getLookup,
  (memberIds, lookup) =>
    memberIds?.map((memberId) => lookup.getIn(['member', memberId])?.toJS()).filter(Boolean),
);

export const hasTeam = (state) => (id) => state.get('data').has(String(id));

export const isLoading = (state) => state.get('loading');

export default teams;
