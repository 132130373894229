import { apiHeaders, apiURL } from '../services/config';

import { patch } from './config';

export const exportPDF = (
  url,
  email,
  eventName,
  teamId,
  teamName,
  phaseName,
  userId,
  eventId,
  phaseId,
) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    url: url,
    email: email,
    event_name: eventName,
    team_id: teamId,
    team_name: teamName,
    phase_name: phaseName,
    user_id: userId,
    event_id: eventId,
    phase_id: phaseId,
  }),

  endpoint: apiURL(`/events/export_results_pdf`),
});

export const loadMultiple = () => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events`),
});

export const load = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${id}`),
});

export const loadContent = (id, pageGroup, pageName) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${id}/contents?page_group=${pageGroup}&page_name=${pageName}`),
});

export const loadDocuments = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${id}/documents`),
});

export const loadArtifacts = (eventId) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/events/${eventId}/artifacts/`),
});

export const save = (id, event) =>
  patch(`/events/${id}`, { event }).then((payload) => payload.event);

export const exportPDFMultiple = ({
  eventId,
  eventName,
  phaseId,
  phaseName,
  statuses,
  first,
  last,
  url,
  team_ids,
}) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    event_id: eventId,
    event_name: eventName,
    phase_id: phaseId,
    phase_name: phaseName,
    first,
    last,
    statuses,
    team_ids,
    url,
  }),

  endpoint: apiURL(`/events/export_results_pdf_multiple`),
});
