import { Children, cloneElement } from 'react';

import { Marker as MarkerImage } from '@valid-eval/shared-react-components';

import PropTypes from 'prop-types';
import Styles from './DimensionBoxPlot.module.scss';

const CHART_SEGMENTS = 6;

function Square({ size }) {
  return <div className={Styles.Square} style={{ width: size, height: size }} />;
}

Square.propTypes = { size: PropTypes.number.isRequired };

function RangeMark({ position: left }) {
  return <div className={Styles.RangeMark} style={{ left }} />;
}

RangeMark.propTypes = { position: PropTypes.number.isRequired };

function RangeLine({ position: left, width }) {
  return <div className={Styles.RangeLine} style={{ left, width }} />;
}

RangeLine.propTypes = { position: PropTypes.number.isRequired, width: PropTypes.number };

function Quartile({ position: left, width }) {
  return <div className={Styles.Quartile} style={{ left, width }} />;
}

Quartile.propTypes = { position: PropTypes.number.isRequired, width: PropTypes.number };

function Median({ position: left }) {
  return <div className={Styles.Median} style={{ left }} />;
}

Median.propTypes = { position: PropTypes.number.isRequired };

function DimensionBoxPlot({
  width = 312,
  minScore,
  maxScore,
  firstQuartile,
  median,
  thirdQuartile,
  children,
}) {
  const widthWithoutBorders = width - CHART_SEGMENTS * 2;
  const squareSize = widthWithoutBorders / CHART_SEGMENTS;

  const scoreToPosition = (cord) => {
    if (cord === undefined) return 0;
    const position = (cord - 1) * (width / CHART_SEGMENTS);
    return Math.round(position);
  };

  /** Place markers in the correct position if theres any */
  const markers = Children.map(
    children,
    (el) =>
      el && cloneElement(el, { position: scoreToPosition(el.props.value) - el.props.width / 2 }),
  );

  return (
    <div className={Styles.Container} style={{ width }} data-test-id={'dimension-box-plot'}>
      {Array.from({ length: CHART_SEGMENTS }).map((_, index) => (
        <Square key={`square-${index}`} size={squareSize} />
      ))}
      <RangeMark position={scoreToPosition(minScore)} />
      <RangeLine
        position={scoreToPosition(minScore)}
        width={scoreToPosition(thirdQuartile) - scoreToPosition(minScore)}
      />
      <Quartile
        width={scoreToPosition(median) - scoreToPosition(thirdQuartile) + 1}
        position={scoreToPosition(thirdQuartile)}
      />
      <Quartile
        width={scoreToPosition(firstQuartile) - scoreToPosition(median) + 1}
        position={scoreToPosition(median) - 1}
      />
      <RangeLine
        position={scoreToPosition(firstQuartile)}
        width={scoreToPosition(maxScore) - scoreToPosition(firstQuartile)}
      />
      <RangeMark position={scoreToPosition(maxScore)} />
      <Median position={scoreToPosition(median) - 2} />
      {markers}
    </div>
  );
}

DimensionBoxPlot.propTypes = {
  width: PropTypes.number,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  firstQuartile: PropTypes.number,
  median: PropTypes.number,
  thirdQuartile: PropTypes.number,
  children: PropTypes.any,
};

function Marker({ position: left, width, height, top, src }) {
  return (
    <img
      alt="Dimensionboxplot marker"
      src={src}
      className={Styles.Marker}
      style={{
        position: 'absolute',
        left,
        top,
        width,
        height,
      }}
    />
  );
}

Marker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.number,
  position: PropTypes.number,
  src: PropTypes.any,
};

Marker.defaultProps = {
  width: 20,
  height: 24,
  top: 2,
  src: MarkerImage.blue,
};

DimensionBoxPlot.Marker = Marker;
DimensionBoxPlot.MarkerImageBlue = MarkerImage.blue;
DimensionBoxPlot.MarkerImageA = MarkerImage.a;
DimensionBoxPlot.MarkerImageB = MarkerImage.b;
DimensionBoxPlot.MarkerImageC = MarkerImage.c;
DimensionBoxPlot.MarkerImageGhost = MarkerImage.ghost;

export default DimensionBoxPlot;
