import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { sortStrings } from 'components/Tables/sortingHelpers';

import { InactiveTeam } from '../types';
import * as Cells from './TeamsAddModalTableCells';
import * as Headers from './TeamsAddModalTableHeaders';
import { sortByPhaseName, sortByFinalRank, sortByStatus, sortByValidScore } from './helpers';

const columnHelper = createColumnHelper<InactiveTeam>();

export const columns: ColumnDef<InactiveTeam, any>[] = [
  columnHelper.accessor('id', {
    id: 'Checkbox',
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),
  columnHelper.accessor('name', {
    id: 'Team Name',
    header: Headers.TeamName,
    cell: Cells.TeamName,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('phases', {
    id: 'Valid Score',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.ValidScore,
    sortingFn: (rowA: Row<InactiveTeam>, rowB: Row<InactiveTeam>) =>
      sortByValidScore(rowA.original, rowB.original),
  }),
  columnHelper.accessor('phases', {
    id: 'Status',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.Status,
    sortingFn: (rowA: Row<InactiveTeam>, rowB: Row<InactiveTeam>) =>
      sortByStatus(rowA.original, rowB.original),
  }),
  columnHelper.accessor('phases', {
    id: 'Most Recent Phase',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.LatestPhase,
    sortingFn: (rowA: Row<InactiveTeam>, rowB: Row<InactiveTeam>) =>
      sortByPhaseName(rowA.original, rowB.original),
  }),
  columnHelper.accessor('phases', {
    id: 'Most Recent Status',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.LatestStatus,
    sortingFn: (rowA: Row<InactiveTeam>, rowB: Row<InactiveTeam>) =>
      sortByStatus(rowA.original, rowB.original),
  }),
  columnHelper.accessor('phases', {
    id: 'Program Rank',
    header: CommonTableHeaders.SortableHeader,
    cell: Cells.ProgramRank,
    sortingFn: (rowA: Row<InactiveTeam>, rowB: Row<InactiveTeam>) =>
      sortByFinalRank(rowA.original, rowB.original),
  }),
];
