import { apiHeaders, apiURL } from '../services/config';

import pick from 'lodash/pick';

const UPDATE_USER_KEYS = [
  'first_name',
  'last_name',
  'fund_raising_experience',
  'primary_job_function',
  'business_discipline',
  'early_stage_investing_experience',
  'exits',
  'failures',
  'user_emails',
  'notification_settings',
];

const filterUpdateProps = (user) => pick(user, UPDATE_USER_KEYS);

export const save = (user) => {
  return {
    method: 'PATCH',
    credentials: 'include',
    headers: apiHeaders,
    body: JSON.stringify(filterUpdateProps(user)),
    endpoint: apiURL(`/users/${user.id}`),
  };
};

export const changePassword = (userId, currentPassword, password, passwordConfirmation, otp) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    current_password: currentPassword,
    password,
    password_confirmation: passwordConfirmation,
    otp,
  }),
  endpoint: apiURL(`/users/${userId}/change_password`),
});

export const login = (username, password, otp) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ auth: { username, password, otp } }),
  endpoint: apiURL(`/sessions/`),
});

export const checkOtpFlow = (username, password) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({ auth: { username, password } }),
  endpoint: apiURL(`/sessions/check_otp_flow`),
});

export const otpUserInformation = (token) => ({
  method: 'GET',
  headers: apiHeaders,
  endpoint: apiURL(`/users/otp_user_information?token=${token}`),
});

export const configureOtp = (code, token) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({ code, token }),
  endpoint: apiURL(`/users/configure_otp`),
});

export const resetLoginAttempts = (token) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({ token }),
  endpoint: apiURL(`/users/reset_login_attempts`),
});

export const resendLockoutPasswordEmail = (email) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({ email }),
  endpoint: apiURL(`/users/resend_lockout_password_email`),
});

export const loginWithLinkedIn = (code, state) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ linkedin: { code } }),
  endpoint: apiURL('/sessions_linkedin'),
});

export const logout = () => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/logout`),
});

export const linkedinCleanup = (userId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/users/${userId}/linkedin_cleanup`),
});

export const storeLinkedinProfile = (userId, code, state) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ linkedin: { code } }),
  endpoint: apiURL(`/users/${userId}/linkedin_store_profile`),
});

export const load = (id) => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/users/${id}`),
});

export const loadUserNotifications = (userId, offset) => {
  let url = `/users/${userId}/notifications`;
  if (offset) {
    url += `?offset=${offset}`;
  }

  return {
    method: 'GET',
    credentials: 'include',
    headers: apiHeaders,
    endpoint: apiURL(url),
  };
};

export const forgotPassword = (email) => ({
  method: 'POST',
  headers: apiHeaders,
  body: JSON.stringify({ password_reset: { email } }),
  endpoint: apiURL(`/password_resets`),
});

export const resetPassword = (password, passwordConfirmation, email, token) => ({
  method: 'PUT',
  headers: apiHeaders,
  body: JSON.stringify({
    email,
    password_reset: { password, password_confirmation: passwordConfirmation },
  }),
  endpoint: apiURL(`/password_resets/${token}`),
});

export const requestVerificationCode = (userId, email, url) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ email, url }),
  endpoint: apiURL(`/users${userId ? `/${userId}` : ''}/request_verification_code`),
});

export const verifyEmail = (userId, email, code) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ email, code }),
  endpoint: apiURL(`/users/${userId}/verify_email`),
});

export const verifyEmailWithToken = (userId, token) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ verification_token: token }),
  endpoint: apiURL(`/users/${userId}/verify_email`),
});

export const signUp = ({
  code,
  email,
  verification_token,
  password,
  password_confirmation,
  first_name,
  last_name,
  sign_up_url,
  invitation_id,
  is_team_member_invitation,
}) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({
    code,
    email,
    verification_token,
    password,
    password_confirmation,
    first_name,
    last_name,
    sign_up_url,
    invitation_id,
    is_team_member_invitation,
  }),
  endpoint: apiURL(`/users/sign_up`),
});

export const saveCookiesConsent = ({ eventId, cookiesConsent }) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ event_id: eventId, cookies_consent: cookiesConsent }),
  endpoint: apiURL(`/users/save_cookies_consent`),
});
