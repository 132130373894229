import * as schemas from '../schema';

import { actions, loadFail, loadMultipleResult } from 'data/actions/teams';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { loadMultiple } from 'data/services/teams';

function* performLoadMultiple(action) {
  try {
    const teams = yield call(loadMultiple, action.payload.ids);
    yield put(loadMultipleResult(teams, schemas.teamsList));
  } catch (e) {
    yield put(loadFail(e.error || e.message));
  }
}

function* watchLoadMultiple() {
  yield takeEvery(actions.LOAD_MULTIPLE, performLoadMultiple);
}

const saga = function* () {
  yield all([fork(watchLoadMultiple)]);
};

export default saga;
