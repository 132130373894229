import { List, fromJS } from 'immutable';

import { LOAD_RESULT } from 'data/actions/contents';
import { handleActions } from 'redux-actions';

const initialState = fromJS({ loading: false, data: {} });

export default handleActions(
  {
    [LOAD_RESULT]: (state, action) => {
      return state.setIn(
        ['data', 'by_event', action.payload.event_id, 'contents'],
        fromJS(action.payload.contents),
      );
    },
  },
  initialState,
);

export const getEventContent = (state, eventId, pageGroup, pageName, fieldName) => {
  const eventContent = state
    .getIn(['data', 'by_event', eventId, 'contents'], List())
    .filter(
      (v) =>
        v.get('page_group') === pageGroup &&
        v.get('page_name') === pageName &&
        v.get('field_name') === fieldName,
    );

  const content = eventContent.count() ? eventContent.first().get('content') : '';
  return content;
};
