import { useDispatch } from 'react-redux';
import { ThunkDispatch, isRejectedWithValue } from '@reduxjs/toolkit';

import { createTeamDraft, updateTeamDraft } from 'data/features/teamDrafts';
import { error } from 'data/actions/notifications';
import { TeamDraft, TeamDraftPayload } from 'data/features/teamDraftTypes';
import { useTranslation } from 'react-i18next';

const useSaveTeamDraft = ({ eventId, onNoSession }: { eventId: string; onNoSession(): void }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { t } = useTranslation();

  const saveTeamDraft = async (
    draft: TeamDraftPayload,
    isDraft: boolean = true,
    callback?: (team?: TeamDraft) => void,
  ) => {
    const action = draft.id && isDraft ? updateTeamDraft : createTeamDraft;

    const res = await dispatch(
      action({
        eventId,
        team: draft,
        isDraft,
      }),
    );

    if (isRejectedWithValue(res)) {
      const payload: any = res.payload;
      if (payload.status === 401) {
        onNoSession();
      } else {
        let message =
          typeof payload?.response.error === 'object'
            ? payload?.response.error.name
            : payload?.response.error;

        dispatch(error(message || t('auth.teams_sign_up.error.save'), 'An error has occurred'));
      }
      callback?.();
    } else {
      callback?.(res.payload.team as TeamDraft);
    }
  };

  return saveTeamDraft;
};

export default useSaveTeamDraft;
