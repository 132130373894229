import { success } from 'data/actions/notifications';
import { resetPassword } from 'data/actions/users';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { SubmissionError } from 'redux-form/immutable';
import withRouter from 'routes/withRouter';
import { getVersionUri } from 'utils';
import Briefcase from './assets/briefcase.png';
import ResetPasswordForm from './components/ResetPasswordForm';
import ErrorBoundary from './ErrorBoundary';

const ResetPassword = ({ onSubmit }) => {
  return (
    <ErrorBoundary>
      <div className="container">
        <div className="login-container mb-5">
          <h1 className="login-title mt-3 mb-3">Reset password</h1>
          <div className="login-box d-flex flex-column">
            <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
            <div style={{ flex: 1 }}>
              <ResetPasswordForm onSubmit={onSubmit} />
            </div>
            <div className="text-center mt-4 mb-2">
              <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

ResetPassword.displayName = 'ResetPassword';
export default compose(
  withRouter,
  connect(() => ({}), {
    resetPassword,
    success,
  }),
  withHandlers({
    onSubmit: (props) => (data) => {
      const {
        query: { email, token },
      } = props;
      return props
        .resetPassword(data.get('password'), data.get('password_confirmation'), email, token)
        .then((result) => {
          if (result.error) {
            const {
              response: { errors },
            } = result.payload;
            throw new SubmissionError(errors);
          } else {
            props.success(result.payload.message);
            props.navigate('/');
          }
        });
    },
  }),
)(ResetPassword);
