import { apiHeaders, apiURL } from '../services/config';

import { get } from './config';

export const load = (phaseId) =>
  get(`/phases/${phaseId}/assignments`).then((payload) => payload.assignments);

export const loadJudgeAssignment = (phaseId, judgeProfileId) =>
  get(`/phases/${phaseId}/judge_assignments/${judgeProfileId}`);

export const loadJudgeSuggestions = (phaseId, judgeProfileId) =>
  get(`/phases/${phaseId}/judge_profiles/${judgeProfileId}/suggestions`).then(
    (payload) => payload.suggestions,
  );

export const assignToJudge = (phaseId, judgeProfileId, teamId) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ assignment: { team_id: teamId } }),
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeProfileId}/assignments`),
});

export const removeFromJudge = (phaseId, judgeId, teamId) => ({
  endpoint: apiURL(`/phases/${phaseId}/judge_profiles/${judgeId}/assignments/${teamId}`),
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
});

export const createMultiple = (phaseId, judgeIds, teamIds) => ({
  endpoint: apiURL(`/phases/${phaseId}/assignments/create_multiple`),
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ judge_ids: judgeIds, team_ids: teamIds }),
});
