import * as api from 'data/services/judgeInvitations';

import { RSAA } from 'redux-api-middleware';

export const actions = {
  CONFIRM: 'judgeInvitations/CONFIRM',
  CONFIRM_SUCCESS: 'judgeInvitations/CONFIRM_SUCCESS',
  CONFIRM_FAIL: 'judgeInvitations/CONFIRM_FAIL',
  PREVIEW: 'judgeInvitations/PREVIEW',
  REMOVE_PREVIEW: 'judgeInvitations/REMOVE_PREVIEW',
  REMOVE_ALL_PREVIEW: 'judgeInvitations/REMOVE_ALL_PREVIEW',
  LOAD_ALL: 'judgeInvitations/LOAD_ALL',
  LOAD_ALL_SUCCESS: 'judgeInvitations/LOAD_ALL_SUCCESS',
  LOAD_ALL_FAIL: 'judgeInvitations/LOAD_ALL_FAIL',
  FILTER_BY_ACCEPTED: 'judgeInvitations/FILTER_BY_ACCEPTED',
  UPDATE_INVITATION: 'judgeInvitations/UPDATE_INVITATION',
  SEND: 'judgeInvitations/SEND',
  DELETE: 'judgeInvitations/DELETE',
  DELETE_SUCCESS: 'judgeInvitations/DELETE_SUCCESS',
  DELETE_FAIL: 'judgeInvitations/DELETE_FAIL',
  DECLINE_INVITATION_PENDING: 'judgeInvitations/DECLINE_INVITATION_PENDING',
  DECLINE_INVITATION_FULFILLED: 'judgeInvitations/DECLINE_INVITATION_FULFILLED',
  DECLINE_INVITATION_REJECTED: 'judgeInvitations/DECLINE_INVITATION_REJECTED',
  VALIDATE_INVITATION_PENDING: 'judgeInvitations/VALIDATE_INVITATION_PENDING',
  VALIDATE_INVITATION_FULFILLED: 'judgeInvitations/VALIDATE_INVITATION_FULFILLED',
  VALIDATE_INVITATION_REJECTED: 'judgeInvitations/VALIDATE_INVITATION_REJECTED',
  ADD_INVITATION_EMAIL_PENDING: 'judgeInvitations/ADD_INVITATION_EMAIL_PENDING',
  ADD_INVITATION_EMAIL_FULFILLED: 'judgeInvitations/ADD_INVITATION_EMAIL_FULFILLED',
  ADD_INVITATION_EMAIL_REJECTED: 'judgeInvitations/ADD_INVITATION_EMAIL_REJECTED',
};

export const preview = (judgeInvitation) => ({
  type: actions.PREVIEW,
  payload: { judgeInvitation },
});

export const removePreview = (previewId) => ({
  type: actions.REMOVE_PREVIEW,
  payload: { previewId },
});

export const removeAllPreview = () => ({
  type: actions.REMOVE_ALL_PREVIEW,
  payload: {},
});

export const confirm = (judgeInvitations, eventId) => ({
  type: actions.CONFIRM,
  payload: { judgeInvitations, eventId },
});

export const confirmSuccess = (judgeInvitations) => ({
  type: actions.CONFIRM_SUCCESS,
  payload: { judgeInvitations },
});

export const confirmFail = (error) => ({
  type: actions.CONFIRM_FAIL,
  payload: { error },
});

export const loadAll = (eventId) => ({
  type: actions.LOAD_ALL,
  payload: { eventId },
});

export const loadAllSuccess = (judgeInvitations) => ({
  type: actions.LOAD_ALL_SUCCESS,
  payload: { judgeInvitations },
});

export const loadAllFail = (error) => ({
  type: actions.LOAD_ALL_FAIL,
  payload: { error },
});

export const updateInvitation = (judgeInvitation) => ({
  type: actions.UPDATE_INVITATION,
  payload: { judgeInvitation },
});

export const send = (id, eventId) => ({
  type: actions.SEND,
  payload: { id, eventId },
});

export const deleteInvitation = (id, eventId) => ({
  type: actions.DELETE,
  payload: { id, eventId },
});

export const deleteInvitationSuccess = (id) => ({
  type: actions.DELETE_SUCCESS,
  payload: { id },
});

export const deleteInvitationFail = (id) => ({
  type: actions.DELETE_FAIL,
  payload: { id },
});

export const declineInvitation = (eventId, id, notificationId) => ({
  [RSAA]: {
    ...api.declineInvitation(eventId, id, notificationId),
    types: [
      actions.DECLINE_INVITATION_PENDING,
      actions.DECLINE_INVITATION_FULFILLED,
      actions.DECLINE_INVITATION_REJECTED,
    ],
  },
});

export const declineInvitationWithToken = (eventId, id, notificationId, token) => ({
  [RSAA]: {
    ...api.declineInvitationWithToken(eventId, id, notificationId, token),
    types: [
      actions.DECLINE_INVITATION_PENDING,
      actions.DECLINE_INVITATION_FULFILLED,
      actions.DECLINE_INVITATION_REJECTED,
    ],
  },
});

export const validateInvitation = (id) => ({
  [RSAA]: {
    ...api.validateInvitation(id),
    types: [
      actions.VALIDATE_INVITATION_PENDING,
      actions.VALIDATE_INVITATION_FULFILLED,
      actions.VALIDATE_INVITATION_REJECTED,
    ],
  },
});

export const addInvitationEmailToProfile = (id) => ({
  [RSAA]: {
    ...api.addInvitationEmailToProfile(id),
    types: [
      actions.ADD_INVITATION_EMAIL_PENDING,
      actions.ADD_INVITATION_EMAIL_FULFILLED,
      actions.ADD_INVITATION_EMAIL_REJECTED,
    ],
  },
});
