import { Accordion, Col, Row } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';

import GenericModal from 'components/GenericModal';
import { load as loadEvent } from 'data/actions/events';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { CurrentRole } from 'data/actions/support';
import { getEvent } from 'data/reducers';
import { getCurrentRole } from 'data/reducers/support';
import { loadHelpContent } from 'data/services/utils';
import { judgeNav, organizerNav, teamNav } from 'utils/urls';

import { Link } from 'react-router-dom';
import ContactSupportModal from './ContactSupportModal';
import { sortTOCHeaders, TOC } from './Help/helpers';
import HelpLinks from './Help/HelpLinks';
import Styles from './HelpCMSPage.module.scss';

type HelpModalProps = {
  show: boolean;
  onCancel: () => void;
  event: {
    slug: string;
    id: string;
  };
  currentRole: CurrentRole;
};

const HelpModal = ({ show, onCancel, event, currentRole }: HelpModalProps) => {
  const [content, setContent] = useState('');
  const [toc, setToc] = useState<TOC[]>([]);
  const [showContactSupport, setShowContactSupport] = useState(false);
  const roleName = (currentRole?.role || 'user').toLowerCase();
  const isTeam = currentRole?.role === 'team';
  const isOrganizer = currentRole?.role === 'organizer';
  const isJudge = currentRole?.role === 'judge';

  useEffect(() => {
    loadContent();
  }, [currentRole?.role]);

  async function loadContent() {
    try {
      const { toc, content } = await loadHelpContent(roleName);
      setToc(sortTOCHeaders(toc));
      setContent(content);
    } catch (err) {
      console.error(err);
    }
  }

  function getUrl(releaseNotes?: boolean) {
    if (isTeam) return teamNav.help(currentRole?.eventId, currentRole?.id);
    if (isOrganizer) return organizerNav.help(currentRole?.eventId, releaseNotes);
    if (isJudge) return judgeNav.help(currentRole?.eventId, currentRole?.id);
    return null;
  }

  function toggleShowContactForm() {
    setShowContactSupport(!showContactSupport);
    onCancel();
  }

  const extractContent = (html: string, item: TOC) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.querySelectorAll(`#${item.target} ~ *`) || [];
    return Array.from(elements)
      .map((el) => el.outerHTML)
      .join('');
  };

  const BodyContent = (
    <>
      {Boolean(roleName) && Boolean(toc.length) && (
        <Row className="mt-3">
          <Col md={12} id="help-toc">
            <h2 className="mb-0">Help categories</h2>
            <div className="d-grid gap-2 mt-3">
              {toc
                .filter((content) => content.target !== 'users-help' && content.level === 0)
                .map((i, index) => (
                  <Link
                    className={'btn btn-primary btn-block text-start px-3'}
                    id={`toc-${i.target}`}
                    to={`${getUrl()}#${i.target}`}
                    key={`${index}-${i.target}`}
                    onClick={onCancel}
                  >
                    {i.text}
                  </Link>
                ))}
            </div>
          </Col>
        </Row>
      )}

      <div className={Styles.linksContainer}>
        {Boolean(toc.length) && (
          <>
            <h2 className="mb-0">Help topics</h2>
            <Accordion>
              {toc[toc.length - 1].subHeader.map((item, index) => (
                <Accordion.Item key={index} eventKey={String(index)}>
                  <Accordion.Header>{item.text}</Accordion.Header>
                  <Accordion.Body
                    dangerouslySetInnerHTML={{
                      __html: extractContent(content, item) || '',
                    }}
                  />
                </Accordion.Item>
              ))}
            </Accordion>
          </>
        )}
        <h2 className="mb-0">Useful links</h2>
        <HelpLinks
          eventId={event?.id}
          eventSlug={event?.slug}
          role={roleName}
          teamId={isTeam ? currentRole.id : undefined}
          judgeProfileId={isJudge ? currentRole.id : undefined}
          vertical
        />
      </div>
    </>
  );

  return showContactSupport ? (
    <>
      {/* @ts-ignore */}
      <ContactSupportModal onCancel={toggleShowContactForm} />
    </>
  ) : (
    <GenericModal
      footerExtraContent={
        isOrganizer && (
          <Link to={String(getUrl(true))} className="btn btn-default" onClick={onCancel}>
            Release Notes
          </Link>
        )
      }
      className={Styles.HelpPage}
      show={show}
      onCancel={onCancel}
      onConfirm={() => setShowContactSupport(!showContactSupport)}
      name={'primary-email'}
      title={'Help Center'}
      body={BodyContent}
      confirmButton={'Contact Support'}
    />
  );
};

export default withRouter(
  connect(
    (state) => {
      const currentRole = getCurrentRole(state);
      const event = getEvent(state, currentRole?.eventId)?.toJS();

      return {
        currentRole,
        event,
      };
    },
    { loadEvent, notifyError, notifySuccess },
  )(HelpModal),
);
