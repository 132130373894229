import { Dropdown } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { ConnectedProps, connect } from 'react-redux';

import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';

import Styles from './StatusButton.module.scss';

function getLabel(status: string | null, statusFramework: any) {
  const keys = Object.keys(statusFramework);
  const key = keys.find((key) => status === statusFramework[key].value) || keys[keys.length - 1];
  return statusFramework[key].label;
}

type OwnProps = {
  status: string;
  statusFramework: string;
  onSelect(status: string | null, allBellow: boolean): void;
  allBellow: boolean;
  teamId: string;
  disabled: boolean;
};

const mapStateToProps = (state: any, ownProps: OwnProps) => {
  return {
    phase: getSelectedPhase(state, ownProps)?.toJS?.(),
  };
};

const connector = connect(mapStateToProps, {});

type StatusButtonProps = OwnProps & ConnectedProps<typeof connector>;

function StatusButton({
  status,
  onSelect,
  allBellow,
  teamId,
  disabled = false,
  statusFramework,
  phase,
}: StatusButtonProps) {
  const usedStatusFramework = statusFramework || phase.status_framework;
  const label = getLabel(status, usedStatusFramework);
  const statuses = Object.keys(usedStatusFramework).reduce<{ [key: string]: any }>((acc, key) => {
    if (usedStatusFramework[key].active) {
      acc[key] = usedStatusFramework[key];
    }
    return acc;
  }, {});

  const handleSelect = (status: string | null, allBellow: boolean) => () =>
    onSelect?.(status, allBellow);

  return (
    <Dropdown
      className={cx(Styles.Dropdown)}
      focusFirstItemOnShow={true}
      id={`team-status-dropdown-${teamId}`}
    >
      <Dropdown.Toggle variant={String(status)} disabled={!teamId || isNavigator() || disabled}>
        {label}
      </Dropdown.Toggle>

      {!disabled && (
        <Dropdown.Menu>
          {Object.keys(statuses).map((key) => (
            <Dropdown.Item
              key={`status-${key}`}
              data-test-id={`status-option-${key}`}
              onClick={handleSelect(usedStatusFramework[key].value, false)}
              active={key === status}
            >
              {usedStatusFramework[key].label}
            </Dropdown.Item>
          ))}
          {allBellow && (
            <Dropdown.Item onClick={handleSelect(null, true)}>OUT (+ ALL BELOW)</Dropdown.Item>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}

export default withRouter(connector(StatusButton));
