import { Button } from '@valid-eval/shared-react-components';
import LinkContainer from 'components/LinkContainer';
import { useLocation } from 'react-router-dom';
import { isNavigator, isTeamLead } from 'utils';
import { organizerNav } from 'utils/urls';

const JudgesNavigation = ({ eventId }) => {
  const location = useLocation();

  return (
    <div className={'mb-3 me-3'}>
      {!isTeamLead() && (
        <>
          <LinkContainer to={organizerNav.judgeInvitations(eventId)}>
            <Button
              className="me-2"
              variant={
                location?.pathname === organizerNav.judgeInvitations(eventId)
                  ? 'primary'
                  : 'default'
              }
            >
              {isNavigator() ? 'Invitations' : 'Invite'}
            </Button>
          </LinkContainer>
          <LinkContainer to={organizerNav.judgeAssignments(eventId)}>
            <Button
              className="me-2"
              variant={
                location?.pathname === organizerNav.judgeAssignments(eventId)
                  ? 'primary'
                  : 'default'
              }
            >
              {isNavigator() ? 'Matches' : 'Match'}
            </Button>
          </LinkContainer>
        </>
      )}
      <LinkContainer to={organizerNav.judgeManage(eventId)}>
        <Button
          className="me-2"
          variant={location?.pathname === organizerNav.judgeManage(eventId) ? 'primary' : 'default'}
        >
          {isNavigator() ? 'Progress' : 'Manage'}
        </Button>
      </LinkContainer>
    </div>
  );
};
export default JudgesNavigation;
