import GenericModal from 'components/GenericModal';

type OptionValue = {
  invitationId: string | null;
  userId: string;
  role: string;
};

type MembersEditRoleModalProps = {
  show: boolean;
  selectedRole: OptionValue | null;
  onCancel: () => void;
  onConfirm: () => void;
  isSaving: boolean;
};

const MembersEditRoleModal = ({
  show,
  selectedRole,
  onCancel,
  onConfirm,
  isSaving,
}: MembersEditRoleModalProps) => {
  const modalBodyContent = selectedRole ? (
    <>
      Are you sure you want to change the role to{' '}
      <label style={{ fontWeight: 'bold' }}>{selectedRole.role}</label>?
      {selectedRole.role === 'primary' && (
        <div className="mt-3">The role of the current primary user will change to editor.</div>
      )}
    </>
  ) : (
    'No role selected'
  );
  return (
    <GenericModal
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      name="members-edit-role"
      title="Change Role"
      body={modalBodyContent}
      cancelButton="Cancel"
      confirmButton="Confirm"
      isButtonDisabled={isSaving}
      isCancelButtonDisabled={isSaving}
    />
  );
};

export default MembersEditRoleModal;
