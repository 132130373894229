import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';

import { CommonControls } from 'components/Tables/CommonControls';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';

import { searchableFields } from './helpers';
import { JudgeInvitation } from './types';

const AVAILABLE_FILTERS = [
  {
    title: 'Decision Status',
    field: 'active',
    type: 'checkbox',
    options: [
      {
        label: 'Accepted',
        value: 'accepted',
      },
      {
        label: 'Declined',
        value: 'declined',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Expired',
        value: 'expired',
      },
    ],
  },
];

type ControlsProps = {
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  data: JudgeInvitation[];
  textFilter: string;
};

const Controls = ({
  data,
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  textFilter,
}: ControlsProps) => {
  const availableFilters = useMemo(() => {
    const statusCount = data
      ?.filter((row) => filterByTextFilter(textFilter, row, searchableFields))
      .reduce(
        (count, row) => {
          if (!row.accepted && !row.declined && !row.has_expired) count.pending += 1;
          else if (row.accepted) count.accepted += 1;
          else if (row.declined) count.declined += 1;
          else if (row.has_expired) count.expired += 1;
          return count;
        },
        {
          accepted: 0,
          declined: 0,
          pending: 0,
          expired: 0,
        },
      );

    const availableFilters = cloneDeep(AVAILABLE_FILTERS);
    availableFilters[0].options[0].label = `Accepted (${statusCount?.accepted})`;
    availableFilters[0].options[1].label = `Declined (${statusCount?.declined})`;
    availableFilters[0].options[2].label = `Pending (${statusCount?.pending})`;
    availableFilters[0].options[3].label = `Expired (${statusCount?.expired})`;

    return availableFilters;
  }, [data, textFilter]);

  let appliedFilters = filters.map((item) => {
    if (item.value === 'accepted') {
      item.label = availableFilters[0].options[0].label;
    }
    if (item.value === 'declined') {
      item.label = availableFilters[0].options[1].label;
    }
    if (item.value === 'pending') {
      item.label = availableFilters[0].options[2].label;
    }
    if (item.value === 'expired') {
      item.label = availableFilters[0].options[3].label;
    }
    return item;
  });

  return (
    <CommonControls
      placeholder="Search by Judge Name or Email"
      availableFilters={availableFilters}
      appliedFilters={appliedFilters}
      filters={filters}
      onOtherFiltersChange={onOtherFiltersChange}
      onTextFilterChange={onTextFilterChange}
    />
  );
};

export default Controls;
