import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import TextFilter from 'screens/app/events/components/filters/TextFilter';

type ControlsProps = {
  onTextFilterChange: (filter: string) => void;
  info: string;
};

const Controls = ({ onTextFilterChange, info }: ControlsProps) => {
  const { t } = useTranslation();

  return (
    <Row className={'mb-1'}>
      <TextFilter
        placeholder={`Search by Prefix, Category or ${t(
          'event.team_profile_name_label',
          'Company',
        )}`}
        search={onTextFilterChange}
      />
      {info && (
        <Col
          className={cx(
            'col-auto flex-grow-1 d-inline-flex align-items-center justify-content-end text-muted fw-bold',
          )}
        >
          <small>{info}</small>
        </Col>
      )}
    </Row>
  );
};

export default Controls;
