import { apiHeaders, apiURL } from '../services/config';

export type SupportFormData = {
  name: string;
  organization: string;
  role: string;
  event: string;
  email: string;
  url: string;
  message: string;
};

export const send = (supportFormData: SupportFormData) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify(supportFormData),
  endpoint: apiURL('/contact_support/send_message'),
});
