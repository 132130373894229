import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

import Table from 'components/Tables/Table';

import { columns } from './components/Definition';
import { TeamMember } from './components/types';

type MembersTableProps = {
  data: TeamMember[];
  isTeamMemberEditor: boolean;
  isSignUpForm: boolean;
  currentUserId: string;
  onDeleteInvitation: (id: string) => void;
  onResendInvitation: (id: string) => void;
  onUpdateMemberRole: (id: string) => void;
  onCopyInviteURLToClipboard: (id: string) => void;
};

export default function MembersTable({
  data,
  isTeamMemberEditor,
  isSignUpForm,
  currentUserId,
  onDeleteInvitation,
  onResendInvitation,
  onUpdateMemberRole,
  onCopyInviteURLToClipboard,
}: MembersTableProps) {
  // Table
  const tableModel = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility: {
        Name: !isSignUpForm,
        Email: !isSignUpForm,
        NameAndEmail: !!isSignUpForm,
        Actions: !!isTeamMemberEditor,
      },
    },
    meta: {
      isTeamMemberEditor,
      currentUserId,
      onDeleteInvitation,
      onResendInvitation,
      onUpdateMemberRole,
      onCopyInviteURLToClipboard,
    },
  });

  return <Table tableModel={tableModel} />;
}
