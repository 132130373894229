import i18n from 'config/i18n';
import { DynamicFieldType, MatchingCategory } from './eventsTypes';

export const categoriesToDynamicFields = (categories: MatchingCategory[]): DynamicFieldType[] => {
  const categoriesObj = categories.reduce<Record<string, DynamicFieldType>>((acc, category) => {
    const parentId = category.parent.id;

    if (!acc[category.parent.id]) {
      let { name: title } = category.parent;

      if (title === 'Stage of Venture') {
        title = i18n.t('event.stage_of_venture_label', title);
      } else if (title === 'Technology Type') {
        title = i18n.t('event.tech_type_label', title);
      }

      const name = `${parentId}`;

      acc[category.parent.id] = {
        title,
        id: name,
        field_key: name,
        field_type: 'dropdown',
        children: [],
        required: true,
        sort_options: true,
      };
    }

    acc[parentId]?.children?.push({ id: category.id, name: category.name });

    return acc;
  }, {});

  return Object.values(categoriesObj);
};
