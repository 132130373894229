import { List, Map, fromJS } from 'immutable';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import transit from 'transit-immutable-js';

import { actions as judgeActions } from 'data/actions/judgeProfiles';
import {
  LOGIN_REQUEST,
  LOGIN_RESULT,
  LOGIN_WITH_LINKED_IN_REQUEST,
  actions,
} from '../actions/users';

import { loadJudgeAssignmentResult } from '../actions/assignments';
import * as teamAssignments from '../actions/teamAssignments';

function retrieveInitialState(key, emptyState) {
  if (key === 'reduxPersist:users') {
    const fromServerState = localStorage.getItem('reduxPersist:users:fromServer');

    if (fromServerState) {
      return fromJS(JSON.parse(fromServerState));
    }
  }

  const restoredState = JSON.parse(localStorage.getItem(key) || 'false');
  return restoredState ? transit.fromJSON(restoredState) : fromJS(emptyState);
}

const initialState = retrieveInitialState('reduxPersist:users', {
  loading: false,
  auth: { loading: false, error: null, userId: null },
  data: {},
});

const users = handleActions(
  {
    [combineActions(LOGIN_WITH_LINKED_IN_REQUEST, LOGIN_REQUEST)]: (state, action) =>
      state
        .setIn(['auth', 'loading'], true)
        .setIn(['auth', 'error'], null)
        .setIn(['auth', 'userId'], null),
    [LOGIN_RESULT]: {
      next(state, action) {
        return state.set(
          'auth',
          Map({
            loading: false,
            error: null,
            userId: action.payload.userId,
          }),
        );
      },
      throw(state, action) {
        const error =
          action.payload.status === 404
            ? (action.payload.response && action.payload.response.error) ||
              'The user / password combination is invalid.'
            : action.payload.statusText;
        return state.set(
          'auth',
          Map({
            error,
            loading: false,
            userId: null,
          }),
        );
      },
    },
    [combineActions(actions.LOAD_RESULT, actions.SAVE_RESULT)]: {
      next(state, action) {
        return state.set('loading', false).mergeIn(['data'], fromJS(action.payload.entities.users));
      },
      throw(state, action) {
        return state.set('loading', false);
      },
    },
    [combineActions(actions.LOAD, actions.SAVE)]: (state, action) => state.set('loading', true),
    [combineActions(actions.LOAD_FAIL)]: (state, action) => state.set('loading', false),
    [loadJudgeAssignmentResult.toString()]: {
      next(state, action) {
        return state.mergeDeepIn(['data', action.payload.user.id], fromJS(action.payload.user));
      },
    },
    [actions.LOGOUT_SUCCESS]: (state, action) => {
      // Clear localstorage
      localStorage.clear();
      return state.mergeIn(['auth'], Map({ loading: false, error: null, userId: null }));
    },
    [combineActions(
      teamAssignments.LOAD_TEAM_ASSIGNMENT_RESULT,
      teamAssignments.LOAD_TEAM_SUGGESTIONS_RESULT,
      judgeActions.LOAD_RESULT,
    )]: (state, action) => {
      if (!action.payload?.entities?.users) return state;

      return state.mergeDeepIn(['data'], fromJS(action.payload.entities.users));
    },
    [actions.VERIFY_EMAIL_RESULT]: (state, action) =>
      action.payload.email
        ? state.updateIn(['data', action.payload.id, 'user_emails'], (emails) =>
            emails.push(fromJS(action.payload.email)),
          )
        : state,
  },
  initialState,
);

export const getAuthInfo = (state) => state.get('auth');

export const getUserById = (state, userId) => {
  return state.getIn(['data', userId]);
};

export const getList = (state) => new List(state.get('data').valueSeq());

export const getUsersLookup = (state) => {
  return state.get('data');
};

const getCurrentUserId = (state) => state.getIn(['auth', 'userId']);

export const getCurrentUser = createSelector(getUsersLookup, getCurrentUserId, (data, userId) =>
  data?.get(userId),
);

export const getCurrentUserLinkedinProfile = createSelector(getCurrentUser, (user) => {
  const profile = user.get('user_linked_in_profile');
  return profile && profile.get('linked_in_id') ? profile : null;
});

export default users;
