import { ValidateResult } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, isRejectedWithValue } from '@reduxjs/toolkit';

import { validateTeamNameUniqueness } from 'data/features/teamDrafts';
import { useDebouncedCallback } from 'utils/debounce-promise';

const useValidateTeamName = ({
  draftId,
  eventId,
  onNoSession,
}: {
  draftId: string;
  eventId: string;
  onNoSession: () => void;
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const validate = useDebouncedCallback(
    async (name: string): Promise<ValidateResult> => {
      const res = await dispatch(validateTeamNameUniqueness({ id: draftId, eventId, name }));
      if (isRejectedWithValue(res)) {
        const payload: any = res.payload;
        if (payload.status === 401) {
          onNoSession();
        } else {
          return payload.response.error.name;
        }
      }
      return undefined;
    },
    [eventId, draftId],
    500,
  );

  return validate;
};

export default useValidateTeamName;
