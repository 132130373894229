import { AsyncThunk, UnknownAction } from '@reduxjs/toolkit';

export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export function isTypeMatching(type: string, matcher: string | null) {
  return matcher ? type.includes(matcher) : true;
}

export function isStatusAction(type: any, status: string, matcher: string | null): boolean {
  return typeof type === 'string' && isTypeMatching(type, matcher) && type.endsWith(status);
}

export function isPendingAction(matcher: string | null) {
  return (action: UnknownAction): action is PendingAction =>
    isStatusAction(action.type, '/pending', matcher);
}

export function isRejectedAction(matcher: string | null) {
  return (action: UnknownAction): action is RejectedAction =>
    isStatusAction(action.type, '/rejected', matcher);
}

export function isFulfilledAction(matcher: string | null) {
  return (action: UnknownAction): action is FulfilledAction =>
    isStatusAction(action.type, '/fulfilled', matcher);
}
