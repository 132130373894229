import { useParams } from 'react-router-dom';
import HelpLinks from '../components/Help/HelpLinks';
import HelpCMSPage from '../components/HelpCMSPage';

const Help = () => {
  const { event_id: eventId, judge_profile_id: judgeProfileId } = useParams();

  return (
    <HelpCMSPage
      role="judge"
      footerLinks={<HelpLinks eventId={eventId} judgeProfileId={judgeProfileId} role="judge" />}
    />
  );
};

export default Help;
