import { ApiError, RequestError, getJSON } from 'redux-api-middleware';

import { getEnvVar } from 'config';
import urlJoin from 'utils/url_join';

const getRequestOptions = (
  params: Record<string, any> | undefined,
  method = 'GET',
  includeCredentials = true,
) => {
  let options: Record<string, any> = {
    method,
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (includeCredentials) {
    options.credentials = 'include';
  }

  return options;
};

const handleApiRequest = async (response: Response) => {
  let body;
  try {
    body = await response.json();
  } catch {
    /* body is not json */
  }

  if (response.ok) {
    return body; // Returns an empty resolved promise for 204, 205 status codes
  } else {
    throw new ApiError(response.status, response.statusText, body);
  }
};

const handleFetchError = async (error: Error) => {
  throw new RequestError(error.message);
};

export const get = (url: string, includeCredentials = true) =>
  fetch(apiURL(url), getRequestOptions(undefined, 'GET', includeCredentials)).then(
    handleApiRequest,
    handleFetchError,
  );

export const post = (url: string, params: Record<string, any>, includeCredentials = true) =>
  fetch(apiURL(url), getRequestOptions(params, 'POST', includeCredentials)).then(
    handleApiRequest,
    handleFetchError,
  );

export const put = (url: string, params: Record<string, any>, includeCredentials = true) =>
  fetch(apiURL(url), getRequestOptions(params, 'PUT', includeCredentials)).then(
    handleApiRequest,
    handleFetchError,
  );

export const patch = (url: string, params: Record<string, any>, includeCredentials = true) =>
  fetch(apiURL(url), getRequestOptions(params, 'PATCH', includeCredentials)).then(
    handleApiRequest,
    handleFetchError,
  );

export const del = (url: string, params: Record<string, any>, includeCredentials = true) =>
  fetch(apiURL(url), getRequestOptions(params, 'DELETE', includeCredentials)).then(
    handleApiRequest,
    handleFetchError,
  );

export const apiURL = (path: string) => urlJoin(getEnvVar('REACT_APP_API_URL'), path);

export const apiHeaders = {
  'Content-Type': 'application/json',
};

const BLACKLISTED_KEYS = {
  name: true,
  status: true,
  statusText: true,
  response: true,
  message: true,
};

export const failedFSAwithParams =
  (params: string[]) => (_action: string, _state: any, res: Response) => {
    return getJSON(res)
      .then((json) => new ApiError(res.status, res.statusText, json))
      .then((error) => {
        Object.keys(params).forEach((key) => {
          // @ts-ignore
          if (!BLACKLISTED_KEYS[key]) {
            // @ts-ignore
            error[key] = params[key];
          }
        });
        return error;
      });
  };
