import { Col, Row } from '@valid-eval/shared-react-components';
import PropTypes from 'prop-types';
import withRouter from 'routes/withRouter';
import Navigation from './JudgesNavigation';

const JudgesLayout = ({ params, children, toolbar }) => {
  return (
    <Row>
      <Col md={12}>
        <h1 className="mt-4">Judges</h1>
        <div className="d-flex">
          <Navigation eventId={params.event_id} />
          {toolbar}
        </div>
      </Col>
      {children}
    </Row>
  );
};

JudgesLayout.propTypes = {
  children: PropTypes.node.isRequired,
  toolbar: PropTypes.node,
};

export default withRouter(JudgesLayout);
