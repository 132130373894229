import Loading from 'components/Loading';
import { loadSurvey, saveAnswers, validate } from 'data/actions/surveys';
import {
  getLoading,
  getQuestions,
  getValid,
  getValidating,
  getAnswered,
} from 'data/reducers/surveys';
import { useEffect } from 'react';
import { Col, Container, Row } from '@valid-eval/shared-react-components';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import SurveyForm from './components/SurveyForm';
import { error } from 'data/actions/notifications';
import withRouter from 'routes/withRouter';

function Survey({
  answered,
  error,
  eventId,
  loading,
  loadSurvey,
  phaseId,
  questions,
  roleId,
  saveAnswers,
  type,
  valid,
  validate,
  validating,
}) {
  useEffect(() => {
    validate({ eventId, phaseId, roleId, type });
    loadSurvey();
  }, []);

  const handelSubmit = (values) => {
    values = values.toJS();
    saveAnswers({ eventId, phaseId, roleId, type, answers: values });
  };

  if (!validating && !valid) {
    error('The survey link is not valid', 'Error');
    return <Navigate to={'/'} />;
  }

  return (
    <Container>
      <h1 className="text-center mt-4">Valid Eval post Round of Evaluation Survey</h1>
      <Row className="justify-content-center mt-2 ms-0 me-0">
        <Col xs="11" md="10" lg="8">
          {(validating || loading || !questions?.length) && <Loading />}

          {questions?.length && !validating && !loading && !answered && (
            <SurveyForm questions={questions} onSubmit={handelSubmit} isTeam={type === 'team'} />
          )}

          {!validating && !loading && answered && (
            <p className="text-center mt-2" id="survey-answered">
              <i className="fa-solid fa-circle-check fa-3x" style={{ color: '#28a745' }} />
              <br />
              <br />
              Thank you for filling out this survey.
              <br />
              Your feedback is highly appreciated!
            </p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(
  connect(
    (state, { params }) => ({
      answered: getAnswered(state),
      eventId: params.event_id,
      loading: getLoading(state),
      phaseId: params.phase_id,
      questions: getQuestions(state),
      roleId: params.role_id,
      type: params.type,
      valid: getValid(state),
      validating: getValidating(state),
    }),
    { loadSurvey, saveAnswers, validate, error },
  )(Survey),
);
