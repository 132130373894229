import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';
import {
  assignToTeam as assign,
  loadTeamSuggestions as loadSuggestions,
  loadTeamAssignment,
  removeAssignment as remove,
} from '../services/teamAssignments';

import { failedFSAwithParams } from '../services/config';
import { normalize } from 'normalizr';

export const LOAD_TEAM_ASSIGNMENT_REQUEST = 'team_assignments/LOAD_TEAM_ASSIGNMENT_REQUEST';
export const LOAD_TEAM_ASSIGNMENT_RESULT = 'team_assignments/LOAD_TEAM_ASSIGNMENT_RESULT';

export const LOAD_TEAM_SUGGESTIONS_REQUEST = 'team_assignments/LOAD_TEAM_SUGGESTIONS_REQUEST';
export const LOAD_TEAM_SUGGESTIONS_RESULT = 'team_assignments/LOAD_TEAM_SUGGESTIONS_RESULT';

export const UNASSIGN_FROM_TEAM_REQUEST = 'team_assignments/UNASSIGN_FROM_TEAM_REQUEST';
export const REMOVE_ASSIGNMENT_FROM_TEAM_RESULT =
  'team_assignments/REMOVE_ASSIGNMENT_FROM_TEAM_RESULT';

export const loadTeamAssignments = (phaseId, teamId) => ({
  [RSAA]: {
    ...loadTeamAssignment(phaseId, teamId),
    types: [
      LOAD_TEAM_ASSIGNMENT_REQUEST,
      {
        type: LOAD_TEAM_ASSIGNMENT_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({
            phaseId,
            teamId,
            ...normalize(payload.role_assignments, [schemas.teamAssignment]),
          })),
      },
      LOAD_TEAM_ASSIGNMENT_RESULT,
    ],
  },
});

export const loadTeamSuggestions = (phaseId, teamId) => ({
  [RSAA]: {
    ...loadSuggestions(phaseId, teamId),
    types: [
      LOAD_TEAM_SUGGESTIONS_REQUEST,
      {
        type: LOAD_TEAM_SUGGESTIONS_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({
            phaseId,
            teamId,
            ...normalize(payload.suggestions, [schemas.teamSeekingJudgesSuggestion]),
          })),
      },
      LOAD_TEAM_SUGGESTIONS_RESULT,
    ],
  },
});

export const removeFromTeam = (phaseId, teamId, judgeId) => {
  return {
    [RSAA]: {
      ...remove(phaseId, teamId, judgeId),
      types: [
        UNASSIGN_FROM_TEAM_REQUEST,
        {
          type: REMOVE_ASSIGNMENT_FROM_TEAM_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => ({
              phaseId,
              teamId,
              judgeId,
              id: payload.id,
            })),
        },
        {
          type: REMOVE_ASSIGNMENT_FROM_TEAM_RESULT,
          payload: failedFSAwithParams({ phaseId, teamId, judgeId }),
        },
      ],
    },
  };
};

export const SAVE_TEAM_ASSIGNMENTS = 'assignments/SAVE_TEAM_ASSIGNMENTS';

export const assignToTeam = (phaseId, teamId, judgeId) => {
  return {
    [RSAA]: {
      ...assign(phaseId, teamId, judgeId),
      types: [
        'assignments/ASSIGN_TO_JUDGE_REQUEST',
        {
          type: SAVE_TEAM_ASSIGNMENTS,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              const { assignment } = payload;
              return {
                phaseId,
                judgeId,
                teamId,
                ...normalize(assignment, schemas.teamAssignment),
              };
            }),
        },
        {
          type: SAVE_TEAM_ASSIGNMENTS, // TODO: Check the failed response and how to reduce it
          payload: failedFSAwithParams({ phaseId, judgeId, teamId }),
        },
      ],
    },
  };
};
