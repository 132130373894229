import { Form, InputGroup } from '@valid-eval/shared-react-components';
import { dynamicFieldValidation } from './utils';

import { Field } from 'redux-form/immutable';
/* eslint no-mixed-operators: 0 */

import styles from './Group.module.scss';

let GroupFieldGroup = ({
  input,
  meta: { touched, error, warning },
  id,
  type,
  label,
  placeholder,
  helpText,
  after,
  before,
  ...extraProps
}) => {
  return (
    <Form.Group controlId={id}>
      <Form.Label>
        <b className={styles.content}>{label}</b>
      </Form.Label>
      {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
      <InputGroup>
        {before}
        <Form.Control
          {...input}
          bsPrefix={`form-control ${styles.input}`}
          type={type}
          placeholder={placeholder}
          as="input"
          isInvalid={touched && (error || warning)}
          isValid={touched && !(error || warning)}
          {...extraProps}
        />
        {after}
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

GroupFieldGroup.displayName = 'GroupFieldGroup';

export const Group = ({
  id,
  name,
  title,
  helpText,
  placeholder,
  type = 'text',
  reset,
  validate,
  ...props
}) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={title}
      component={GroupFieldGroup}
      type={type}
      helpText={helpText}
      placeholder={placeholder}
      validate={validate || dynamicFieldValidation}
      reset={reset}
      {...props}
    />
  );
};
