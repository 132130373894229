import { Dropdown } from '@valid-eval/shared-react-components';
import { ConnectedProps, connect } from 'react-redux';

import GenericModal from 'components/GenericModal';
import { updateManyRoleStatuses } from 'data/actions/roleSummaries';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { error as showError, success as showSuccess } from 'data/actions/notifications';

import { Team } from '../../shared/types';

type Props = {
  onTeamsRemoved: () => void;
  phaseId: string;
  selectedTeams: Team[];
};

const mapDispatchToProps = { updateManyRoleStatuses, showError, showSuccess };
const mapStateToProps = () => ({});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamsRemoveButtonProps = PropsFromRedux & Props;

const TeamsRemoveButton = ({
  phaseId,
  selectedTeams,
  onTeamsRemoved,
  updateManyRoleStatuses,
  showSuccess,
  showError,
}: TeamsRemoveButtonProps) => {
  const [isModalOpened, openModal, closeModal] = useBooleanFlag(false);
  const [isLoading, startLoading, finishLoading] = useBooleanFlag(false);

  async function handleRemoveTeams() {
    startLoading();
    try {
      await updateManyRoleStatuses(
        phaseId,
        'teams',
        false,
        // @ts-ignore
        selectedTeams.map((team) => team.id),
      );
      showSuccess('The selected teams were removed.', 'Success');
    } catch {
      showError('There was an error removing the selected teams, please try again.');
    } finally {
      finishLoading();
      onTeamsRemoved();
      closeModal();
    }
  }

  return (
    <>
      <GenericModal
        show={isModalOpened}
        onCancel={closeModal}
        name={'remove-selected-teams'}
        title={'Confirm Remove Teams'}
        body={<div>Are you sure you want to remove the {selectedTeams.length} teams selected?</div>}
        onConfirm={handleRemoveTeams}
        size="md"
        cancelButton={'Cancel'}
        isButtonDisabled={isLoading}
        isCancelButtonDisabled={isLoading}
      />
      <Dropdown.Item
        id={`remove-selected-teams-button`}
        eventKey={0}
        onClick={openModal}
        disabled={selectedTeams.length === 0}
      >
        Remove {selectedTeams.length} Teams
      </Dropdown.Item>
    </>
  );
};

export default connector(TeamsRemoveButton);
