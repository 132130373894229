import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation } from 'react-router';

import { closeSignUpModal, fromAuthentication } from 'data/features/authentication';
import { getCurrentUser } from 'data/reducers';
import withRouter from 'routes/withRouter';

import ConfigureMFA from './MFASetup/ConfigureMFA';
import LoginModal from './SignUpLogin/LoginModal';
import SignupModal from './SignUpLogin/SignupModal';

type OwnProps = {
  query: { already_registered_email?: string };
  location: { pathname: string; search: string };
  closable?: boolean;
};

const mapStateToProps = (state: any) => ({
  isSignUpModalOpen: fromAuthentication.getIsSignUpModalOpen(state.toJS()),
  currentUser: getCurrentUser(state)?.toJS?.(),
});

const connector = connect(mapStateToProps, { closeSignUpModal });

type SignUpLoginProps = ConnectedProps<typeof connector> & OwnProps;

const SignUpLogin = ({
  currentUser,
  query,
  location,
  closable,
  isSignUpModalOpen,
  closeSignUpModal,
}: SignUpLoginProps) => {
  const { hash } = useLocation();
  const [configureMFA, setConfigureMFA] = useState({
    show: false,
    otpToken: '',
    username: '',
  });

  const isLogin = hash === '#login' || query.already_registered_email === '1';
  const registered = query.already_registered_email === '1';
  const isTeamMemberInvitation = location.pathname.includes('member_invitations');
  const Content = isLogin ? LoginModal : SignupModal;

  if (currentUser) return null;

  const handleMFASetup = (otpRequestToken: string, username: string) => {
    setConfigureMFA({ show: true, otpToken: otpRequestToken, username });
  };

  const handleCloseMFAModal = () => {
    setConfigureMFA({ show: false, otpToken: '', username: '' });
  };

  return (
    <>
      <Content
        show={closable ? isSignUpModalOpen : undefined}
        onClose={closable ? closeSignUpModal : undefined}
        onMFASetup={handleMFASetup}
        registered={registered}
        isTeamMemberInvitation={isTeamMemberInvitation}
      />
      {configureMFA.otpToken && (
        <ConfigureMFA
          show={configureMFA.show}
          onToggle={handleCloseMFAModal}
          otpRequestToken={configureMFA.otpToken}
          extras={{ userName: configureMFA.username }}
        />
      )}
    </>
  );
};

export default withRouter(connector(SignUpLogin));
