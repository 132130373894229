import { Col, Form, Row } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import DynamicFields from 'components/FormFields/DynamicFields';
import { useDebouncedCallback } from 'utils/debounce-promise';

import { StepComponentProps } from '../types';
import StepContainer from './StepContainer';
import StepFooter from './StepFooter';
import useDetailsForm, { DetailsFormType } from './useDetailsForm';
import useSaveTeamDraft from './useSaveTeamDraft';

type DetailsStepProps = StepComponentProps;

const DetailsStep = ({
  draft,
  event,
  onInfoChange,
  onNoSession,
  onSubmitStep,
  user,
  saving,
  isActive,
  isTeamMemberEditor,
}: DetailsStepProps) => {
  const { form, getDraftWithDetails } = useDetailsForm({
    draft,
    event,
    onInfoChange,
    user,
    isActive,
  });
  const saveTeamDraft = useSaveTeamDraft({ eventId: event?.id || '', onNoSession });

  const handleSubmit = async (data: DetailsFormType) => {
    saveTeamDraft(getDraftWithDetails(draft, data), true, onSubmitStep);
  };

  const handleChange = useDebouncedCallback(
    () => {
      const data = form.getValues();
      return saveTeamDraft(getDraftWithDetails(draft, data), true, () => form.trigger());
    },
    [event?.id, draft?.id, draft],
    500,
  );

  // Listen for changes in the form and save the draft
  useEffect(() => {
    const subscription = form.watch((_, info) => {
      if (info.type !== 'change') return;
      handleChange();
    });
    return () => subscription.unsubscribe();
  }, [draft]);

  return (
    <FormProvider {...form}>
      <Form className="h-100" onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <StepContainer
          content={
            <Row>
              <DynamicFields
                fields={event?.team_details || []}
                fieldWrapper={<Col sm={12} className="mb-3" />}
                disabled={!user?.id || !draft?.id || !isTeamMemberEditor}
              />
            </Row>
          }
          footer={
            isTeamMemberEditor && (
              <StepFooter
                active={isActive}
                disabled={saving || form.formState.isSubmitting || !user?.id || !draft?.id}
                loading={saving || form.formState.isSubmitting}
              />
            )
          }
        />
      </Form>
    </FormProvider>
  );
};

export default DetailsStep;
