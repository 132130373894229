import { useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Loading from 'components/Loading';
import { loadApiKeys } from 'data/actions/apiKeys';
import { getApiKeys, getApiKeysIsLoading } from 'data/reducers/apiKeys';
import ApiKeysTable from './ApiKeysTable';

/** Redux types */
interface OwnProps {}

const mapStateToProps = (state: any, _: OwnProps) => ({
  isLoading: getApiKeysIsLoading(state),
  apiKeys: getApiKeys(state),
});

const mapDispatchToProps = {
  loadApiKeys,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ApiKeysProps = PropsFromRedux;

/** Component */
const ApiKeys = ({ loadApiKeys, isLoading, apiKeys }: ApiKeysProps) => {
  const loaded = useRef(false);

  useEffect(() => {
    loadApiKeys();
    loaded.current = true;
  }, []);

  return (
    <>
      {isLoading && !loaded.current && <Loading text={undefined} />}
      {apiKeys?.length > 0 && <h2 className="mt-2">API Keys</h2>}
      {apiKeys?.map((apiKey) => (
        <section className="mt-2" key={apiKey.organization_id}>
          <ApiKeysTable {...apiKey} />
        </section>
      ))}
    </>
  );
};

export default connector(ApiKeys);
