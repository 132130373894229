import { success } from 'data/actions/notifications';
import { forgotPassword } from 'data/actions/users';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { SubmissionError } from 'redux-form/immutable';
import { getVersionUri } from 'utils';
import Briefcase from './assets/briefcase.png';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ErrorBoundary from './ErrorBoundary';

const ForgotPassword = (props) => {
  const { onSubmit, lastSuccessfulRequest } = props;
  return (
    <ErrorBoundary>
      <div className="container">
        <div className="login-container mb-5">
          <h1 className="login-title mt-3 mb-3">Forgot password?</h1>
          <div className="login-box d-flex flex-column">
            <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
            <div style={{ flex: 1 }}>
              <ForgotPasswordForm onSubmit={onSubmit} lastEmailRequested={lastSuccessfulRequest} />
            </div>
            <div className="text-center mt-4 mb-2">
              <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

ForgotPassword.displayName = 'ForgotPassword';
export default compose(
  connect(null, { forgotPassword, success }),
  withState('lastSuccessfulRequest', 'setSuccessfulRequest', undefined),
  withHandlers({
    onSubmit: (props) => (data) =>
      props.forgotPassword(data.get('email')).then((result) => {
        if (result.error) {
          const {
            response: { error: errorMessage },
          } = result.payload;
          throw new SubmissionError({ _error: errorMessage });
        } else {
          const { message } = result.payload;
          props.success(message);
          props.setSuccessfulRequest(data.get('email'));
        }
      }),
  }),
)(ForgotPassword);
