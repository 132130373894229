import { Table as TableType, flexRender } from '@tanstack/react-table';
import cx from 'classnames';
import React from 'react';

import { Table as TableComponent } from '@valid-eval/shared-react-components';

import Styles from './Table.module.scss';
import { JudgeInvitation } from './types';

type ResultsTableProps = {
  tableModel: TableType<JudgeInvitation>;
};

const Table = ({ tableModel }: ResultsTableProps) => {
  return (
    <TableComponent className="react-bs-table" responsive>
      <thead>
        {tableModel.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <React.Fragment key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableModel.getRowModel().rows.map((row) => (
          <tr
            className={cx(Styles.TableRow, {
              [Styles.DefaultRow]: row.original.accepted,
              [Styles.DangerRow]: row.original.has_expired || row.original.declined,
            })}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => (
              <React.Fragment key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </React.Fragment>
            ))}
          </tr>
        ))}
        {tableModel.getRowModel().rows.length === 0 && (
          <tr>
            <td colSpan={tableModel.getHeaderGroups()[0].headers.length} className={'text-center'}>
              No data to display
            </td>
          </tr>
        )}
      </tbody>
    </TableComponent>
  );
};

export default Table;
