import * as schemas from '../schema';

import { RSAA, getJSON } from 'redux-api-middleware';
import {
  assignToJudge as assign,
  loadJudgeAssignments as loadAssignments,
  loadJudgeSuggestions as loadSuggestions,
  removeAssignment as remove,
} from '../services/judgeAssignments';

import { failedFSAwithParams } from '../services/config';
import { normalize } from 'normalizr';

export const LOAD_JUDGE_ASSIGNMENTS_REQUEST = 'assignments/LOAD_JUDGE_ASSIGNMENTS_REQUEST';
export const LOAD_JUDGE_ASSIGNMENTS_RESULT = 'assignments/LOAD_JUDGE_ASSIGNMENTS_RESULT';

export const LOAD_JUDGE_SUGGESTIONS_REQUEST = 'assignments/LOAD_JUDGE_SUGGESTIONS_REQUEST';
export const LOAD_JUDGE_SUGGESTIONS_RESULT = 'assignments/LOAD_JUDGE_SUGGESTIONS_RESULT';

export const UNASSIGN_FROM_JUDGE_REQUEST = 'assignments/UNASSIGN_FROM_JUDGE_REQUEST';
export const REMOVE_ASSIGNMENT_FROM_JUDGE_RESULT =
  'assignments/REMOVE_ASSIGNMENT_FROM_JUDGE_RESULT';

export const loadJudgeAssignments = (phaseId, judgeId) => ({
  [RSAA]: {
    ...loadAssignments(phaseId, judgeId),
    types: [
      LOAD_JUDGE_ASSIGNMENTS_REQUEST,
      {
        type: LOAD_JUDGE_ASSIGNMENTS_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({
            phaseId,
            judgeId,
            ...normalize(payload.role_assignments, [schemas.judgeAssignment]),
          })),
      },
      LOAD_JUDGE_ASSIGNMENTS_RESULT,
    ],
  },
});

export const loadJudgeSuggestions = (phaseId, judgeId) => ({
  [RSAA]: {
    ...loadSuggestions(phaseId, judgeId),
    types: [
      LOAD_JUDGE_SUGGESTIONS_REQUEST,
      {
        type: LOAD_JUDGE_SUGGESTIONS_RESULT,
        payload: (action, state, res) =>
          getJSON(res).then((payload) => ({
            phaseId,
            judgeId,
            ...normalize(payload.suggestions, [schemas.judgeSeekingTeamsSuggestion]),
          })),
      },
      LOAD_JUDGE_SUGGESTIONS_RESULT,
    ],
  },
});

export const removeFromJudge = (phaseId, judgeId, teamId) => {
  return {
    [RSAA]: {
      ...remove(phaseId, judgeId, teamId),
      types: [
        UNASSIGN_FROM_JUDGE_REQUEST,
        {
          type: REMOVE_ASSIGNMENT_FROM_JUDGE_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => ({
              phaseId,
              teamId,
              judgeId,
              id: payload.id,
            })),
        },
        {
          type: REMOVE_ASSIGNMENT_FROM_JUDGE_RESULT,
          payload: failedFSAwithParams({ phaseId, teamId, judgeId }),
        },
      ],
    },
  };
};

export const SAVE_JUDGE_ASSIGNMENTS = 'assignments/SAVE_JUDGE_ASSIGNMENTS';

export const assignToJudge = (phaseId, judgeId, teamId) => {
  return {
    [RSAA]: {
      ...assign(phaseId, judgeId, teamId),
      types: [
        'assignments/ASSIGN_TO_JUDGE_REQUEST',
        {
          type: SAVE_JUDGE_ASSIGNMENTS,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => {
              const { assignment } = payload;
              return {
                phaseId,
                judgeId,
                teamId,
                ...normalize(assignment, schemas.judgeAssignment),
              };
            }),
        },
        {
          type: SAVE_JUDGE_ASSIGNMENTS, // TODO: Check the failed response and how to reduce it
          payload: failedFSAwithParams({ phaseId, judgeId, teamId }),
        },
      ],
    },
  };
};
