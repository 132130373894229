import { useState } from 'react';

/**
 * Custom hook to manage a boolean state.
 *
 * @param {boolean} initialValue - The initial value of the boolean state.
 * @returns {Array} An array containing the current state value and two functions to set the state to true or false.
 * The first function sets the state to true, the second function sets the state to false, last function toggles the value.
 *
 * @example
 * const [value, setTrue, setFalse, toggle] = useBooleanFlag(false);
 */
export default function useBooleanFlag(
  initialValue = false,
): [boolean, () => void, () => void, () => void] {
  const [value, setValue] = useState(initialValue);

  /**
   * Factory function to generate a function that sets the state to a specific value.
   *
   * @param {boolean} value - The value to set the state to.
   * @returns {Function} A function that sets the state to the specified value when called.
   */
  function setValueFactory(value: boolean) {
    return () => setValue(value);
  }

  /**
   * Toggles the current boolean state value.
   * If the current state is true, it will be set to false, and vice versa.
   */
  function toggleValue() {
    setValue(!value);
  }

  return [value, setValueFactory(true), setValueFactory(false), toggleValue];
}
