import { apiHeaders, apiURL } from '../services/config';

export const load = () => ({
  method: 'GET',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/api_keys`),
});

export const add = ({ name, organizationId }: { name: string; organizationId: string }) => ({
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/api_keys`),
  body: JSON.stringify({ name, organization_id: organizationId }),
});

export const destroy = ({ id, organizationId }: { id: string; organizationId: string }) => ({
  method: 'DELETE',
  credentials: 'include',
  headers: apiHeaders,
  endpoint: apiURL(`/api_keys/${id}`),
  body: JSON.stringify({ organization_id: organizationId }),
});
