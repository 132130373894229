import { ThunkDispatch, isRejectedWithValue } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { error } from 'data/actions/notifications';
import { saveArtifactItems } from 'data/features/artifactItems';
import { useTranslation } from 'react-i18next';

const useSaveArtifactItems = ({
  eventId,
  teamId,
  onNoSession,
}: {
  eventId: string;
  teamId: string;
  onNoSession(): void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const save = async (artifactItems: Record<string, string>, callback?: () => void) => {
    const res = await dispatch(saveArtifactItems({ eventId, teamId, artifactItems }));
    if (isRejectedWithValue(res)) {
      const payload: any = res.payload;
      if (payload.status === 401) {
        onNoSession();
      } else {
        dispatch(
          error(
            payload?.response.error || t('auth.teams_sign_up.error.save'),
            'An error has occurred',
          ),
        );
      }
    }
    callback?.();
  };

  return save;
};

export default useSaveArtifactItems;
