import { actions } from 'data/actions/surveys';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  answered: false,
  validating: true,
  valid: true,
  loading: true,
  questions: null,
};

const reducers = {
  [actions.LOAD_PENDING](state) {
    return { ...state, loading: true };
  },
  [actions.LOAD_FULFILLED](state, { payload }) {
    return { ...state, loading: false, questions: payload };
  },
  [actions.LOAD_REJECTED](state) {
    return { ...state, loading: false };
  },
  [actions.VALIDATE_PENDING](state) {
    return { ...state, validating: true, valid: true };
  },
  [actions.VALIDATE_FULFILLED](state, { payload }) {
    return { ...state, validating: false, valid: true, answered: payload.answered };
  },
  [actions.VALIDATE_REJECTED](state) {
    return { ...state, validating: false, valid: false };
  },
  [actions.SAVE_PENDING](state) {
    return { ...state };
  },
  [actions.SAVE_FULFILLED](state, { payload }) {
    return { ...state, answered: true };
  },
  [actions.SAVE_REJECTED](state) {
    return { ...state };
  },
};

// Reducer and State
export default handleActions(reducers, initialState);

// Selectors
export const getState = (state) => state.get('surveys');
export const getLoading = createSelector(getState, (state) => state.loading);
export const getQuestions = createSelector(getState, (state) => state.questions);
export const getValidating = createSelector(getState, (state) => state.validating);
export const getValid = createSelector(getState, (state) => state.valid);
export const getAnswered = createSelector(getState, (state) => state.answered);
