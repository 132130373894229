import {
  Container,
  OptionValue,
  ReactSelect,
  SelectMultiple,
  Tab,
  TabsGroup,
} from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';

import GenericModal from 'components/GenericModal';
import { load } from 'data/actions/roleSummaries';
import {
  getEventArtifactsAsOptions,
  getSelectedPhaseIdForCurrentEvent,
  getTeamsAsOptions,
} from 'data/reducers';
import { ConnectedProps, connect } from 'react-redux';
import withRouter from 'routes/withRouter';

type OwnProps = {
  eventId: string;
  show: boolean;
  customModalTitle?: string;
  selectArtifacts?: boolean;
  onCancel(): void;
  onConfirm({ artifacts, teams }: { artifacts: OptionValue[]; teams: OptionValue[] }): void;
};

const connector = connect(
  (state, ownProps: OwnProps) => ({
    teamOptions: getTeamsAsOptions(state, ownProps),
    // @ts-ignore
    artifactOptions: getEventArtifactsAsOptions(state, ownProps.eventId),
    // @ts-ignore
    selectedPhaseId: getSelectedPhaseIdForCurrentEvent(state, ownProps),
  }),
  {
    load,
  },
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamFilesReportModalProps = PropsFromRedux & OwnProps;

const TeamFilesReportModal = ({
  load,
  teamOptions,
  show,
  onCancel,
  onConfirm,
  artifactOptions,
  selectedPhaseId,
  customModalTitle,
  selectArtifacts = true,
}: TeamFilesReportModalProps) => {
  const [selectedArtifacts, setSelectedArtifacts] = useState<OptionValue[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<OptionValue[]>([]);

  // Load the role summaries
  useEffect(() => {
    load('teams', selectedPhaseId);
  }, [selectedPhaseId]);

  // Set the default selected artifacts to all artifacts
  useEffect(() => {
    if (artifactOptions?.length) setSelectedArtifacts(artifactOptions);
  }, [artifactOptions]);

  // Set the default selected teams to all teams
  useEffect(() => {
    if (teamOptions?.length) setSelectedTeams(teamOptions);
  }, [teamOptions]);

  const handleArtifactsChange = (
    value: ReactSelect.MultiValue<OptionValue> | ReactSelect.SingleValue<OptionValue>,
  ) => setSelectedArtifacts(value as OptionValue[]);

  const handleTeamsChange = (
    value: ReactSelect.MultiValue<OptionValue> | ReactSelect.SingleValue<OptionValue>,
  ) => setSelectedTeams(value as OptionValue[]);

  const selectedTeamsLabel =
    selectedTeams?.length === teamOptions?.length
      ? '(All teams)'
      : `(${selectedTeams?.length} selected)`;

  const selectedArtifactsLabel =
    selectedArtifacts?.length === artifactOptions?.length
      ? '(All artifacts)'
      : `(${selectedArtifacts?.length} selected)`;

  return (
    <GenericModal
      cancelButton="Cancel"
      className={undefined}
      confirmButton="Export Artifacts"
      body={
        <Container>
          <TabsGroup>
            {selectArtifacts && (
              <Tab eventKey="artifacts" title={`Artifacts ${selectedArtifactsLabel}`}>
                <SelectMultiple
                  onChange={handleArtifactsChange}
                  options={artifactOptions}
                  value={selectedArtifacts}
                  placeholder="Filter by name"
                  backspaceRemovesValue={false}
                />
              </Tab>
            )}
            <Tab eventKey="teams" title={`Teams ${selectedTeamsLabel}`}>
              <SelectMultiple
                placeholder="Filter by name"
                options={teamOptions}
                value={selectedTeams}
                onChange={handleTeamsChange}
                backspaceRemovesValue={false}
              />
            </Tab>
          </TabsGroup>
        </Container>
      }
      name={'team-files-report-confirm'}
      title={customModalTitle || 'Team Files Report'}
      onCancel={onCancel}
      onConfirm={() => onConfirm({ artifacts: selectedArtifacts, teams: selectedTeams })}
      show={show}
    />
  );
};

export default withRouter(connector(TeamFilesReportModal));
