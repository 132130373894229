import { actions, loadSuccess, saveSuccess } from 'data/actions/invitationTemplates';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { load, save } from 'data/services/invitationTemplates';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';

function* performLoad(action) {
  try {
    const invitationTemplates = yield call(load, action.payload.eventId);
    yield put(loadSuccess(invitationTemplates));
  } catch (e) {
    yield put(notifyError('Could not retrieve invitation template'));
  }
}

function* performSave(action) {
  const { invitationTemplate, eventId } = action.payload;

  try {
    const template = yield call(save, invitationTemplate, eventId);
    yield put(saveSuccess(template));
    yield put(notifySuccess('Email template updated'));
  } catch (e) {
    const message = e.error || e.message;
    yield put(notifyError(message));
  }
}

function* watchLoadInvitationTemplate() {
  yield takeEvery(actions.LOAD, performLoad);
}

function* watchSaveInvitationTemplate() {
  yield takeEvery(actions.SAVE, performSave);
}

const saga = function* () {
  yield all([fork(watchLoadInvitationTemplate), fork(watchSaveInvitationTemplate)]);
};

export default saga;
