import { Button, Spinner } from '@valid-eval/shared-react-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type StepFooterProps = {
  active: boolean;
  disabled: boolean;
  loading: boolean;
};

const StepFooter = ({ disabled, loading, active }: StepFooterProps) => {
  const { t } = useTranslation();

  const { formState } = useFormContext();
  const errorCount = Object.keys(formState.errors).length;

  return (
    <>
      <div className="flex-grow-1 text-danger ">
        {errorCount > 0 && formState.isSubmitted && (
          <>
            <i className="fa-regular fa-circle-exclamation me-1"></i>
            {t(`auth.teams_sign_up.error.form`, { count: errorCount })}
          </>
        )}
      </div>
      <div>
        {active ? (
          <Button id="save-application" variant="secondary" type="submit" disabled={disabled}>
            {loading && <Spinner animation="border" size="sm" className="me-2" />}
            {t('auth.teams_sign_up.save_application')}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default StepFooter;
