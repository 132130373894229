import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { sortStrings } from 'components/Tables/sortingHelpers';

import { Team } from '../../../shared/types';
import * as Cells from './Cells';
import * as Headers from './Headers';

const columnHelper = createColumnHelper<Team>();

export const columns: ColumnDef<Team, any>[] = [
  columnHelper.accessor('id', {
    id: 'Id',
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),
  columnHelper.accessor('panel', {
    id: 'Panel',
    header: CommonTableHeaders.SortableHeader,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('prefix', {
    id: 'Prefix',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('name', {
    id: 'Team Name',
    header: Headers.TeamName,
    cell: (props) => <Cells.TeamName {...props} className="w-100" />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('matchQuality', {
    id: 'Match Quality',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.NumberOrZero,
  }),
  columnHelper.accessor('assignedRolesCount', {
    id: '# Judges',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.NumberOrZero,
  }),
];
