import { Button, ButtonGroup } from '@valid-eval/shared-react-components';

import LinkContainer from 'components/LinkContainer';

import { organizerNav } from 'utils/urls';
import Styles from '../Results.module.scss';

function ResultsNav({ eventId, details, summary, compare, disableButtons = false }) {
  return (
    <div className="text-end shift-up d-print-none">
      <ButtonGroup aria-label="Results section" className={Styles.resultsNavButtons}>
        <LinkContainer to={organizerNav.results(eventId)}>
          <Button
            variant={summary ? 'primary' : 'default'}
            style={{ pointerEvents: summary ? 'none' : 'inherit' }}
          >
            Summary
          </Button>
        </LinkContainer>
        <LinkContainer to={organizerNav.compareTeams(eventId, ',,')} disabled={disableButtons}>
          <Button
            disabled={disableButtons}
            variant={compare ? 'primary' : 'default'}
            style={{ pointerEvents: compare ? 'none' : 'inherit' }}
          >
            Compare
          </Button>
        </LinkContainer>
        <LinkContainer to={organizerNav.scores(eventId, 'empty')} disabled={disableButtons}>
          <Button
            disabled={disableButtons}
            variant={details ? 'primary' : 'default'}
            style={{ pointerEvents: details ? 'none' : 'inherit' }}
          >
            Team
          </Button>
        </LinkContainer>
      </ButtonGroup>
    </div>
  );
}

export default ResultsNav;
