import { Form } from '@valid-eval/shared-react-components';

export type FieldErrorProps = {
  show: boolean;
  message?: string;
};

const FieldError = ({ show, message }: FieldErrorProps) => {
  return show && message ? (
    <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
  ) : null;
};

export default FieldError;
