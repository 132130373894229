import { Col, Image, Row } from '@valid-eval/shared-react-components';
import JudgeEvaluation from 'components/RubricScoring/JudgeEvaluation';
import { getEventFromURL, getScore } from 'data/reducers';
import { connect } from 'react-redux';
import { getJudgeProfile } from '../../data/reducers/index';
import UserExperienceInfo from '../../screens/app/judges/components/UserExperienceInfo';
import Styles from './IndividualEvaluation.module.scss';
import withRouter from 'routes/withRouter';
import { isTeamLead } from 'utils';
import { getBucketFromScore } from 'components/helpers';

let IndividualEvaluation = ({ rubric, score, judge, event }) => {
  if (!score) return null;

  let scoreValue = score.get('score') && score.get('score').toFixed(2);
  if (isTeamLead() && event?.judging_ux_mode === 'qualitative_only_mode') {
    scoreValue =
      getBucketFromScore(score.get('score'), event.qualitative_methodology?.buckets)
        ?.abbreviation || 'N/A';
  }

  return (
    <div>
      <header className={Styles.judgeInfo}>
        <Row>
          <Col md={1}>
            <div className={Styles.scaleBackground}>
              <Image src="/images/scale.svg" />
            </div>
          </Col>
          <Col md={5} className={Styles.judgeFunction}>
            <UserExperienceInfo experiences={judge} />
          </Col>
          <Col md={5} className="text-end">
            <p className={Styles.judgeEvaluation}>
              Judge Score
              <span className={Styles.scoreValue}>{scoreValue}</span>
            </p>
          </Col>
        </Row>
      </header>
      <JudgeEvaluation
        rubric={rubric}
        scores={score.get('dim_scores')}
        criteria={score.get('criteria')}
        clickedCriteria={score.get('clicked_criteria')}
      />
    </div>
  );
};

const connector = connect((state, props) => {
  const { selectedScore } = props;
  const score = selectedScore && getScore(state, selectedScore);
  const judge = score && getJudgeProfile(state, score.get('judge'));
  const event = getEventFromURL(state, props)?.toJS?.();

  return { score, judge, event };
});

export default withRouter(connector(IndividualEvaluation));
