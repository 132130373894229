import { formatNumber, normalize } from './utils';

import { Input } from './Input';

const normalizeNumber = normalize('number');

export const Number = (props) =>
  Input({
    normalize: normalizeNumber,
    format: formatNumber,
    ...props,
    type: 'text',
  });
