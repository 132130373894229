import { TeamDraft } from 'data/features/teamDraftTypes';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { StepInfo } from '../types';
import useCalculateStepInfo from './useCalculateStepInfo';

export type ContactInfoFormType = {
  id: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
};

const useContactInfoForm = ({
  user,
  primaryContact,
  draft,
  isActive,
  onInfoChange,
}: {
  user: any;
  primaryContact: any;
  draft: TeamDraft | undefined;
  isActive: boolean;
  onInfoChange: (data: StepInfo) => void;
}) => {
  const requiredFields = ['first_name', 'last_name', 'name', 'email'];
  const form = useForm<ContactInfoFormType>({
    defaultValues: {
      id: '',
      first_name: '',
      last_name: '',
      name: '',
      email: '',
    },
  });

  useCalculateStepInfo({ form, requiredFields, onInfoChange, user });

  // Set focus
  useEffect(() => {
    if (isActive) {
      form.setFocus('name');
    }
  }, [isActive]);

  // Trigger form validation on load
  useEffect(() => {
    form.trigger();
  }, []);

  // Set user data
  useEffect(() => {
    if (!primaryContact?.id) return;

    form.setValue('first_name', primaryContact?.first_name || '');
    form.setValue('last_name', primaryContact?.last_name || '');
    form.setValue('email', primaryContact?.email || '');
    form.trigger();
  }, [primaryContact?.id]);

  // Set draft data
  useEffect(() => {
    if (!draft?.id) return;

    form.setValue('id', draft?.id || '');
    form.setValue('name', draft?.name || '');
    form.trigger();
  }, [draft?.id]);

  return form;
};

export default useContactInfoForm;
