import { Action, ActionTypes, TeamDraft } from 'data/actions/teamDrafts';

import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

const initialState = fromJS({ data: {}, byEvent: {}, loading: true });

// @ts-ignore
export default handleActions(
  {
    // @ts-ignore
    [ActionTypes.LOAD_TEAM_DRAFTS_REQUEST]: (state: any, action: Action) =>
      state.set('loading', true),
    // @ts-ignore
    [ActionTypes.LOAD_TEAM_DRAFTS_RESULT]: (state: any, action: Action) =>
      state
        .mergeIn(['data'], fromJS(action.payload.entities.teamDrafts))
        .setIn(['byEvent', action.payload.eventId], action.payload.result)
        .set('loading', false),
  },
  initialState,
);

export const getByEvent = (state: any, eventId: string) => {
  return state
    .getIn(['byEvent', eventId], [])
    .map((teamDraft: TeamDraft) => state.getIn(['data', teamDraft]));
};
