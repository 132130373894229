import { Button, Form, InputGroup, Modal } from '@valid-eval/shared-react-components';
import { submit } from 'redux-form';
import copy from 'copy-to-clipboard';

import { ConnectedProps, connect } from 'react-redux';
import AddApiKeyForm from './AddApiKeyForm';
import CopiedOverlay from './CopiedOverlay';

type OwnProps = {
  loading: boolean;
  show: boolean;
  generatedKey: string | null;
  onCancel(): void;
  onFinish(): void;
  onConfirmAddApiKey(values: {}): void;
  organizationId: string;
  organizationName: string;
};

const mapDispatchToProps = {
  submit,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type AddApiKeyModalProps = PropsFromRedux & OwnProps;

const AddApiKeyModal = ({
  loading,
  generatedKey,
  onCancel,
  onFinish,
  onConfirmAddApiKey,
  organizationId,
  organizationName,
  show,
  submit,
}: AddApiKeyModalProps) => {
  function onSubmit() {
    submit('createApiKey');
  }

  function handleCopyApiKey() {
    copy(generatedKey || '');
  }

  return (
    <Modal id={`add-api-key-modal`} show={show} onHide={onCancel} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>{generatedKey ? 'Your API Key' : 'Create API Key'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && !generatedKey && (
          <AddApiKeyForm
            initialValues={{ name: '', organizationName, organizationId }}
            onSubmit={onConfirmAddApiKey}
          />
        )}
        {show && generatedKey && (
          <>
            <p>
              API Key has been generated successfully. Please copy this Key and save it somewhere
              safe. For security reasons, we can't show it to you again.
            </p>
            <InputGroup className="mb-3">
              <Form.Control value={generatedKey} readOnly data-test-id="generated-api-key" />
              <CopiedOverlay entity="the API Key">
                <Button variant="link" onClick={handleCopyApiKey}>
                  <i className="fa-solid fa-copy" />
                  Copy
                </Button>
              </CopiedOverlay>
            </InputGroup>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {generatedKey && (
          <Button variant="success" onClick={onFinish} data-test-id="done-api-key">
            Done
          </Button>
        )}
        {!generatedKey && (
          <>
            <Button variant="link" onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={onSubmit}
              disabled={loading}
              data-test-id="generate-api-key"
            >
              Generate
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default connector(AddApiKeyModal);
