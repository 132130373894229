import { Card, Col, Row, Table } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';

import ErrorBoundary from 'screens/ErrorBoundary';
import Loading from 'components/Loading';
import classes from './RelNotes.module.scss';
import { getRelNotes } from 'data/services/utils';

const RelNoteItem = ({ relNoteObject }) => {
  return (
    <Card className="mb-5">
      <Card.Header className={classes.cardHeader} as="h2">
        Release {relNoteObject.versionName}
        <span className="float-end">{relNoteObject.releaseDate}</span>
      </Card.Header>
      <Card.Body className="p-4">
        <Row>
          <Col sm={12}>
            <p className={classes.versionDesc}>
              <span className={classes.lblSummary}>Release Summary:</span>{' '}
              {relNoteObject.versionDesc}
            </p>
          </Col>
        </Row>
        {relNoteObject.items.jqlsection.results.length > 0 && (
          <Row>
            <Col sm={12} className={classes.tblHeader}>
              Specific items of note:
            </Col>
            <Col sm={12}>
              <Table striped hover responsive>
                <tbody>
                  {relNoteObject.items.jqlsection.results.map((result, index) => (
                    <tr key={`${relNoteObject.versionName}-item-${index}`}>
                      <td
                        className={classes.itemTitle}
                        dangerouslySetInnerHTML={{ __html: result.customfield_10037 }}
                      />
                      <td className={classes.itemDetails}>
                        {result.customfield_10040 && (
                          <div dangerouslySetInnerHTML={{ __html: result.customfield_10040 }} />
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr key={'asstd-bug-fixes'}>
                    <td className={classes.itemTitle}>
                      Various bug fixes and product enhancements affecting all users
                    </td>
                    <td className={classes.itemDetails} />
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

const RelNotes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [relNotes, setRelNotes] = useState([]);

  // fetch release notes
  useEffect(() => {
    (async () => {
      try {
        const relNotesObj = await getRelNotes();
        setRelNotes(JSON.parse(`[${relNotesObj}]`));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <ErrorBoundary>
      <Col md={12}>
        <h1>Valid Eval Release Notes</h1>
        {isLoading ? (
          <Loading />
        ) : relNotes ? (
          relNotes.map((relNote) => {
            return <RelNoteItem relNoteObject={relNote} key={`version-${relNote.versionName}`} />;
          })
        ) : (
          <Row>
            <Col>
              <p>No release notes source data found, please contact Valid Eval for assistance.</p>
            </Col>
          </Row>
        )}
      </Col>
    </ErrorBoundary>
  );
};

export default RelNotes;
RelNotes.displayName = 'ReleaseNotes';
