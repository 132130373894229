import { Button } from '@valid-eval/shared-react-components';
import Loading from './Loading';
import cx from 'classnames';
import styles from './LoadingButton.module.scss';

const countErrors = (errors) =>
  errors
    ? Object.keys(errors).reduce((count, key) => {
        if (typeof errors[key] === 'string') return count + 1;
        if (typeof errors[key] === 'object') return count + Object.keys(errors[key]).length;
        return count;
      }, 0)
    : 0;

const LoadingButton = ({
  loading,
  children,
  className,
  hasSubmitFailed = false,
  syncErrors = {},
  submitErrors = {},
  ...rest
}) => {
  let errorCount = countErrors(syncErrors);
  errorCount += countErrors(submitErrors);

  return (
    <>
      <Button className={cx('d-flex', styles.Loading, className)} {...rest}>
        {loading && <Loading noFadeIn />}
        {<span>{children}</span>}
      </Button>
      {hasSubmitFailed && Boolean(errorCount) && (
        <div className="mt-3">
          <span className={cx(styles.invalid)}>
            {errorCount} VALIDATION ERROR{errorCount > 1 ? 'S' : ''} - PLEASE REVIEW FORM ENTRIES
          </span>
        </div>
      )}
    </>
  );
};

LoadingButton.displayName = 'LoadingButton';
export default LoadingButton;
