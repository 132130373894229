import { Form } from '@valid-eval/shared-react-components';
import { Controller } from 'react-hook-form';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import useFormFieldState from './useFormFieldState';

export type CheckboxListProps = {
  id?: string;
  label?: string;
  name: string;
  rules?: { validate?: Record<string, any> };
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  options: { value: string; label: string }[];
};

const CheckboxList = ({
  id,
  label,
  name,
  rules,
  disabled,
  required,
  helpText,
  options,
}: CheckboxListProps) => {
  const { control, isValid, isInvalid, error, validationClassname, getValues, clearErrors } =
    useFormFieldState(name);

  rules = rules || {};
  rules = required
    ? {
        ...rules,
        validate: {
          required() {
            // Required validation for checkbox list is defined here,
            // since it depends upon the value of all checkboxes in the list.
            const values = getValues();
            clearErrors(name); // Clear errors on the other checkboxes before validating
            return values[name]?.filter(Boolean).length > 0 ? undefined : 'Required';
          },
          ...rules.validate,
        },
      }
    : rules;

  const fieldId = `${name || id}-checkbox`;
  const value = getValues()?.[name] || [];

  return (
    <>
      <Form.Group className="mb-2">
        <Label htmlFor={fieldId} label={label} />
        <HelpText text={helpText} />
        {options.map((option, index) => (
          <Controller
            key={option.value}
            control={control}
            name={`${name}.${index}`}
            rules={rules}
            defaultValue={false}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <Form.Check
                  id={fieldProps.name}
                  className={validationClassname}
                  label={option.label}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  disabled={disabled}
                  type={'checkbox'}
                  checked={value.includes(option.value)}
                  onChange={(e) => onChange(e.target.checked ? option.value : false)}
                  {...fieldProps}
                />
              );
            }}
          />
        ))}
        {/* @ts-expect-error: Error object is an array in this case */}
        <FieldError show={isInvalid} message={String(error?.find(Boolean)?.message)} />
      </Form.Group>
    </>
  );
};

export default CheckboxList;
