import * as schemas from '../schema';
import * as usersAPI from '../services/users';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const actions = {
  LOAD: 'users/LOAD',
  LOAD_RESULT: 'users/LOAD_RESULT',
  LOAD_FAIL: 'users/LOAD_FAIL',
  LINKED_IN_LOGIN: 'users/LINKED_IN_LOGIN',
  LINKED_IN_FETCH_PROFILE: 'users/LINKED_IN_FETCH_PROFILE',
  LOGOUT_REQUEST: 'users/LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'users/LOGOUT_SUCCESS',
  SAVE: 'users/SAVE',
  SAVE_RESULT: 'users/SAVE_RESULT',
  FORGOT_PASSWORD: 'users/FORGOT_PASSWORD',
  FORGOT_PASSWORD_RESULT: 'users/FORGOT_PASSWORD_RESULT',
  RESET_PASSWORD: 'users/RESET_PASSWORD',
  RESET_PASSWORD_RESULT: 'users/RESET_PASSWORD_RESULT',
};

export const LOGIN_REQUEST = 'users/LOGIN_REQUEST';
export const LOGIN_RESULT = 'users/LOGIN_RESULT';

const payloadForSuccessfulLogin = (action, state, res) =>
  getJSON(res).then((payload) => {
    const { user_id } = payload;
    return { userId: user_id };
  });

export const login = (email, password, otp) => {
  return {
    [RSAA]: {
      ...usersAPI.login(email, password, otp),
      types: [
        LOGIN_REQUEST,
        { type: LOGIN_RESULT, payload: payloadForSuccessfulLogin },
        LOGIN_RESULT,
      ],
    },
  };
};

export const LOGIN_WITH_LINKED_IN_REQUEST = 'users/LOGIN_WITH_LINKED_IN_REQUEST';

export const loginWithLinkedIn = (code, state) => {
  return {
    [RSAA]: {
      ...usersAPI.loginWithLinkedIn(code, state),
      types: [
        LOGIN_WITH_LINKED_IN_REQUEST,
        { type: LOGIN_RESULT, payload: payloadForSuccessfulLogin },
        LOGIN_RESULT,
      ],
    },
  };
};

export const CHANGE_PASSWORD_REQUEST = 'users/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_RESULT = 'users/CHANGE_PASSWORD_RESULT';

export const changePassword = (userId, currentPassword, password, passwordConfirmation, otp) => {
  return {
    [RSAA]: {
      ...usersAPI.changePassword(userId, currentPassword, password, passwordConfirmation, otp),
      types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_RESULT, CHANGE_PASSWORD_RESULT],
    },
  };
};

export function linkedInLogin(queryParams) {
  return { type: actions.LINKED_IN_LOGIN, payload: queryParams };
}

export function linkedInFetchProfile() {
  return { type: actions.LINKED_IN_FETCH_PROFILE };
}

export const STORE_LINKED_IN_PROFILE_REQUEST = 'users/STORE_LINKED_IN_PROFILE_REQUEST';
export const STORE_LINKED_IN_PROFILE_RESULT = 'users/STORE_LINKED_IN_PROFILE_RESULT';

export const linkedInStoreProfile = (userId, code, state) => {
  return {
    [RSAA]: {
      ...usersAPI.storeLinkedinProfile(userId, code, state),
      types: [
        STORE_LINKED_IN_PROFILE_REQUEST,
        { type: STORE_LINKED_IN_PROFILE_RESULT, payload: payloadForSuccessfulLogin },
        STORE_LINKED_IN_PROFILE_RESULT,
      ],
    },
  };
};

export function load(id) {
  return {
    [RSAA]: {
      ...usersAPI.load(id),
      types: [
        actions.LOAD,
        {
          type: actions.LOAD_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => normalize(payload.user, schemas.user)),
        },
        actions.LOAD_RESULT,
      ],
    },
  };
}

export function loadSuccess(user) {
  return { type: actions.LOAD_RESULT, payload: { user } };
}

export function loadFail(error) {
  return { type: actions.LOAD_FAIL, payload: { error } };
}

export const logout = (navigate = true) => {
  return {
    [RSAA]: {
      ...usersAPI.logout(),
      types: [
        actions.LOGOUT_REQUEST,
        { type: actions.LOGOUT_SUCCESS, payload: { navigate } },
        actions.LOGOUT_SUCCESS,
      ],
    },
  };
};

export function logoutSuccess() {
  return { type: actions.LOGOUT_SUCCESS };
}

export function save(user) {
  return {
    [RSAA]: {
      ...usersAPI.save(user),
      types: [
        actions.SAVE,
        {
          type: actions.SAVE_RESULT,
          payload: (action, state, res) =>
            getJSON(res).then((payload) => normalize(payload.user, schemas.user)),
        },
        actions.SAVE_RESULT,
      ],
    },
  };
}

export const forgotPassword = (email) => {
  return {
    [RSAA]: {
      ...usersAPI.forgotPassword(email),
      types: [
        actions.FORGOT_PASSWORD,
        actions.FORGOT_PASSWORD_RESULT,
        actions.FORGOT_PASSWORD_RESULT,
      ],
    },
  };
};

export const resetPassword = (password, passwordConfirmation, email, token) => {
  return {
    [RSAA]: {
      ...usersAPI.resetPassword(password, passwordConfirmation, email, token),
      types: [actions.RESET_PASSWORD, actions.RESET_PASSWORD_RESULT, actions.RESET_PASSWORD_RESULT],
    },
  };
};

export const LINKEDIN_CLEANUP_REQUEST = 'users/LINKEDIN_CLEANUP_REQUEST';
export const LINKEDIN_CLEANUP_RESULT = 'users/LINKEDIN_CLEANUP_RESULT';

export const linkedinCleanup = (userId) => {
  return {
    [RSAA]: {
      ...usersAPI.linkedinCleanup(userId),
      types: [LINKEDIN_CLEANUP_REQUEST, LINKEDIN_CLEANUP_RESULT, LINKEDIN_CLEANUP_RESULT],
    },
  };
};

export const CHECK_OTP_FLOW = 'users/CHECK_OTP_FLOW';
export const CHECK_OTP_FLOW_RESULT = 'users/CHECK_OTP_FLOW_RESULT';

export const checkOtpFlow = (email, password) => {
  return {
    [RSAA]: {
      ...usersAPI.checkOtpFlow(email, password),
      types: [
        CHECK_OTP_FLOW,
        {
          type: CHECK_OTP_FLOW_RESULT,
          payload: (_action, _state, res) =>
            getJSON(res).then((payload) => {
              const { user_id, otp_required, otp_configured, otp_request_token } = payload;

              return {
                userId: user_id,
                otpRequired: otp_required,
                otpConfigured: otp_configured,
                otpRequestToken: otp_request_token,
              };
            }),
        },
        CHECK_OTP_FLOW_RESULT,
      ],
    },
  };
};

export const OTP_USER_INFORMATION = 'users/OTP_USER_INFORMATION';
export const OTP_USER_INFORMATION_RESULT = 'users/OTP_USER_INFORMATION_RESULT';

export const otpUserInformation = (token) => {
  return {
    [RSAA]: {
      ...usersAPI.otpUserInformation(token),
      types: [
        OTP_USER_INFORMATION,
        {
          type: OTP_USER_INFORMATION_RESULT,
          payload: (_action, _state, res) =>
            getJSON(res).then((payload) => {
              return {
                otpUrl: payload.otp_url,
                email: payload.email,
                secret: payload.secret,
              };
            }),
        },
        OTP_USER_INFORMATION_RESULT,
      ],
    },
  };
};

export const CONFIGURE_OTP = 'users/CONFIGURE_OTP';
export const CONFIGURE_OTP_RESULT = 'users/CONFIGURE_OTP_RESULT';

export const configureOtp = (code, token) => {
  return {
    [RSAA]: {
      ...usersAPI.configureOtp(code, token),
      types: [CONFIGURE_OTP, CONFIGURE_OTP_RESULT, CONFIGURE_OTP_RESULT],
    },
  };
};

export const RESET_LOGIN_ATTEMPTS = 'users/RESET_LOGIN_ATTEMPTS';
export const RESET_LOGIN_ATTEMPTS_RESULT = 'users/RESET_LOGIN_ATTEMPTS_RESULT';

export const resetLoginAttempts = (token) => {
  return {
    [RSAA]: {
      ...usersAPI.resetLoginAttempts(token),
      types: [RESET_LOGIN_ATTEMPTS, RESET_LOGIN_ATTEMPTS_RESULT, RESET_LOGIN_ATTEMPTS_RESULT],
    },
  };
};

export const RESEND_LOCKOUT_PASSOWORD_EMAIL = 'users/RESEND_LOCKOUT_PASSOWORD_EMAIL';
export const RESEND_LOCKOUT_PASSOWORD_EMAIL_RESULT = 'users/RESEND_LOCKOUT_PASSOWORD_EMAIL_RESULT';

export const resendLockoutPasswordEmail = (email) => {
  return {
    [RSAA]: {
      ...usersAPI.resendLockoutPasswordEmail(email),
      types: [
        RESEND_LOCKOUT_PASSOWORD_EMAIL,
        RESEND_LOCKOUT_PASSOWORD_EMAIL_RESULT,
        RESEND_LOCKOUT_PASSOWORD_EMAIL_RESULT,
      ],
    },
  };
};

actions.REQUEST_VERIFICATION_CODE = 'users/REQUEST_VERIFICATION_CODE';
actions.REQUEST_VERIFICATION_CODE_RESULT = 'users/REQUEST_VERIFICATION_CODE_RESULT';

export const requestVerificationCode = (userId, email, url) => {
  return {
    [RSAA]: {
      ...usersAPI.requestVerificationCode(userId, email, url),
      types: [
        actions.REQUEST_VERIFICATION_CODE,
        actions.REQUEST_VERIFICATION_CODE_RESULT,
        actions.REQUEST_VERIFICATION_CODE_RESULT,
      ],
    },
  };
};

actions.VERIFY_EMAIL = 'users/VERIFY_EMAIL';
actions.VERIFY_EMAIL_RESULT = 'users/VERIFY_EMAIL_RESULT';

export const verifyEmail = (userId, email, code) => {
  return {
    [RSAA]: {
      ...usersAPI.verifyEmail(userId, email, code),
      types: [actions.VERIFY_EMAIL, actions.VERIFY_EMAIL_RESULT, actions.VERIFY_EMAIL_RESULT],
    },
  };
};

actions.VERIFY_EMAIL = 'users/VERIFY_EMAIL';
actions.VERIFY_EMAIL_RESULT = 'users/VERIFY_EMAIL_RESULT';

export const verifyEmailWithToken = (userId, token) => {
  return {
    [RSAA]: {
      ...usersAPI.verifyEmailWithToken(userId, token),
      types: [actions.VERIFY_EMAIL, actions.VERIFY_EMAIL_RESULT, actions.VERIFY_EMAIL_RESULT],
    },
  };
};

actions.SIGN_UP = 'users/SIGN_UP';
actions.SIGN_UP_RESULT = 'users/SIGN_UP_RESULT';

export const signUp = (payload) => {
  return {
    [RSAA]: {
      ...usersAPI.signUp(payload),
      types: [actions.SIGN_UP, actions.SIGN_UP_RESULT, actions.SIGN_UP_RESULT],
    },
  };
};

export const loginResult = (userId) => {
  return {
    type: LOGIN_RESULT,
    payload: { userId },
  };
};

actions.SAVE_COOKIES_CONSENT = 'users/SAVE_COOKIES_CONSENT';
actions.SAVE_COOKIES_CONSENT_RESULT = 'users/SAVE_COOKIES_CONSENT_RESULT';

export const saveCookiesConsent = ({ eventId, cookiesConsent }) => {
  return {
    [RSAA]: {
      ...usersAPI.saveCookiesConsent({ eventId, cookiesConsent }),
      types: [
        actions.SAVE_COOKIES_CONSENT,
        actions.SAVE_COOKIES_CONSENT_RESULT,
        actions.SAVE_COOKIES_CONSENT_RESULT,
      ],
    },
  };
};
