import * as schemas from '../schema';
import * as phasesAPI from '../services/phases';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';

//Action Constants

export const actions = {
  LOAD: 'phases/LOAD',
  LOAD_RESULT: 'phases/LOAD_RESULT',
  LOAD_FOR_EVENT: 'phases/LOAD_FOR_EVENT',
  SET_SELECTED_PHASE: 'phases/SET_SELECTED_PHASE',
  LOAD_STATUS: 'phases/LOAD_STATUS',
  SAVE_STATUS: 'phases/SAVE_STATUS',
  LOAD_STATUS_FAIL: 'phases/LOAD_STATUS_FAIL',
  UPDATE: 'phases/UPDATE',
  UPDATE_SUCCESS: 'phases/UPDATE_SUCCESS',
  SCHEDULE_AUTOMATCH: 'phases/SCHEDULE_AUTOMATCH',
  SCHEDULE_AUTOMATCH_RESULT: 'phases/SCHEDULE_AUTOMATCH_RESULT',
  CLEAR_ALL_MATCHES_PENDING: 'phases/CLEAR_ALL_MATCHES_PENDING',
  CLEAR_ALL_MATCHES_FULFILLED: 'phases/CLEAR_ALL_MATCHES_FULFILLED',
  CLEAR_ALL_MATCHES_REJECTED: 'phases/CLEAR_ALL_MATCHES_REJECTED',
};

export const load = (phaseId) => {
  return {
    [RSAA]: {
      ...phasesAPI.loadSingle(phaseId),
      types: [
        {
          type: actions.LOAD,
          payload: { phaseId },
        },
        {
          type: actions.LOAD_RESULT,
          payload: (_, __, res) =>
            getJSON(res).then((payload) => normalize(payload.phase, schemas.phase)),
        },
        actions.LOAD_RESULT,
      ],
    },
  };
};

export const loadResult = createAction(actions.LOAD_RESULT, (phases) =>
  normalize(phases, Array.isArray(phases) ? [schemas.phase] : schemas.phase),
);

export const loadForEvent = (eventId) => ({
  type: actions.LOAD_FOR_EVENT,
  payload: { eventId },
});

export const changeSelectedPhase = (eventId, phaseId) => ({
  type: actions.SET_SELECTED_PHASE,
  payload: { eventId, phaseId },
});

export const changeTeamSelectedPhase = createAction(
  'phases/CHANGE_TEAM_SELECTED_PHASE',
  (teamId, phaseId) => ({ teamId, phaseId }),
);

export const loadStatus = (phaseId) => ({
  type: actions.LOAD_STATUS,
  payload: { phaseId },
});

export const saveStatus = (phaseId, statuses) => {
  const statusLookup = statuses.reduce(
    (lookup, status) => ({ ...lookup, [status.type]: status }),
    {},
  );
  return {
    type: actions.SAVE_STATUS,
    payload: { phaseId: String(phaseId), status: statusLookup },
  };
};

export const loadStatusFail = (error) => ({
  type: actions.LOAD_STATUS_FAIL,
  payload: { error },
});

export const update = (phaseId, phase) => ({
  type: actions.UPDATE,
  payload: { phaseId, phase },
});

export const updateSuccess = (phase) => ({
  type: actions.UPDATE_SUCCESS,
  payload: { phase },
});

export const clearAllMatches = (phaseId) => {
  return {
    [RSAA]: {
      ...phasesAPI.clearAllMatches(phaseId),
      types: [
        actions.CLEAR_ALL_MATCHES_PENDING,
        actions.CLEAR_ALL_MATCHES_FULFILLED,
        actions.CLEAR_ALL_MATCHES_REJECTED,
      ],
    },
  };
};

export const scheduleAutomatch = (phaseId, payload) => {
  return {
    [RSAA]: {
      ...phasesAPI.scheduleAutomatch(phaseId, payload),
      types: [
        {
          type: actions.SCHEDULE_AUTOMATCH,
          payload: { phaseId },
        },
        {
          type: actions.SCHEDULE_AUTOMATCH_RESULT,
          payload: { phaseId },
        },
        {
          type: actions.SCHEDULE_AUTOMATCH_RESULT,
        },
      ],
    },
  };
};
