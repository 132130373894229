import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import Table from 'components/Tables/Table';
import { ApiKey, ApiKeyResponseItem } from 'data/actions/apiKeys';
import ApiKeysTableActions from './ApiKeysTableActions';

type ApiKeysTableProps = ApiKeyResponseItem;

const columnHelper = createColumnHelper<ApiKey>();

const columns = [
  columnHelper.accessor('id', {
    header: CommonTableHeaders.Checkbox,
    cell: CommonTableCells.Checkbox,
  }),

  columnHelper.accessor('name', {
    id: 'Name',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
  }),

  columnHelper.accessor('key', {
    id: 'Api Key',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
  }),

  columnHelper.accessor('created_at', {
    id: 'Created',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Date,
  }),

  columnHelper.accessor('expires_at', {
    id: 'Expires',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Date,
  }),
];

const ApiKeysTable = (props: ApiKeysTableProps) => {
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState<ApiKey[]>([]);

  const tableModel = useReactTable({
    data: props.api_keys,
    columns: columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    state: {
      rowSelection,
    },
  });

  function clearSelection() {
    tableModel.resetRowSelection();
  }

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection).map((key) => (props.api_keys as any)[key]);
    setSelectedRows(selectedRows);
  }, [rowSelection]);

  return (
    <>
      <Table className="m-0" fullWidthHeader={props.organization_name} tableModel={tableModel} />
      <ApiKeysTableActions
        selectedRows={selectedRows}
        organizationId={props.organization_id}
        organizationName={props.organization_name}
        clearSelection={clearSelection}
      />
    </>
  );
};

export default ApiKeysTable;
