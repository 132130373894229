import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get } from 'data/services/config';
import { isPendingAction, isRejectedAction } from './common';
import { Content } from './contentsTypes';

// Adapter for operate and normalize the data
const initialState: {
  loading: boolean;
  byEventId: Record<string, Content[]>;
} = {
  loading: false,
  byEventId: {},
};

// Actions
export const fetchContents = createAsyncThunk(
  'contents/fetch',
  async (
    { eventId, pageGroup, pageName }: { eventId: string; pageGroup: string; pageName: string },
    { rejectWithValue },
  ) => {
    try {
      return await get(`/events/${eventId}/contents?page_group=${pageGroup}&page_name=${pageName}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const contentsSlice = createSlice({
  name: 'contentsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContents.fulfilled, (state, action) => {
        state.byEventId[action.meta.arg.eventId] = action.payload.contents;
        state.loading = false;
      })
      .addMatcher(isPendingAction('contents'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('contents'), (state) => {
        state.loading = false;
      });
  },
  selectors: {
    getIsLoading: (state) => state.loading,
    getContent: (state, eventId: string, pageGroup: string, pageName: string, fieldName) => {
      return state.byEventId[eventId]?.find(
        (content) =>
          content.page_group === pageGroup &&
          content.page_name === pageName &&
          content.field_name === fieldName,
      );
    },
  },
});

export const fromContents = contentsSlice.selectors;
export default contentsSlice.reducer;
