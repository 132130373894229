import { Col, Row } from '@valid-eval/shared-react-components';
import { Component } from 'react';
import { connect } from 'react-redux';

import ProfileTitle from 'components/ProfileTitle';
import { load as loadTeam } from 'data/actions/teams';
import { getTeamById } from 'data/reducers';
import withRouter from 'routes/withRouter';

import ApplicationRevisionRequest from '../events/components/ApplicationRevisionRequest';
import Navigation from '../events/components/EditAssignmentsNavigation';
import { organizerNav } from 'utils/urls';
import { isNavigator, isTeamLead } from 'utils';

const roleInfoStyles = { marginTop: '30px', marginBottom: '30px' };

class EditWrapper extends Component {
  componentDidMount() {
    this.props.loadTeam(this.props.params.team_id);
  }

  render() {
    const { name } = this.props;

    const isProfile =
      organizerNav.profile(this.props.params.event_id, 'teams', this.props.params.team_id) ===
      this.props.location.pathname;

    return (
      <Row>
        <Col md={12} className={'position-relative'}>
          <Row style={roleInfoStyles}>
            <Col md={5}>
              <ProfileTitle title={name} />
            </Col>
          </Row>
          <Row className="mt-4 mb-5">
            <Col md={8}>
              <Navigation urlParams={this.props.params} />
            </Col>
            {isProfile && !isNavigator() && !isTeamLead() && (
              <Col md={4} className="d-flex align-items-center justify-content-end">
                <ApplicationRevisionRequest />
              </Col>
            )}
          </Row>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const team = getTeamById(state, ownProps.params.team_id);
      return {
        name: team && team.get('name'),
      };
    },
    { loadTeam },
  )(EditWrapper),
);
