import { CellContext } from '@tanstack/react-table';
import { Form } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { getBucketFromScore } from 'components/helpers';
import { isTeamLead } from 'utils';

import { getGradientAndPosition, getRangeBasedClassName } from '../../helpers';
import StatusButton from '../../StatusButton';
import Styles from './ResultsTable.module.scss';
import { JudgeScore, TeamResult } from './types';

export const ValidRank = ({ getValue, row, table }: CellContext<TeamResult, number>) => {
  const meta = table.options.meta as any;
  const showCheckboxOnly = meta.event.judging_ux_mode === 'qualitative_only_mode' && isTeamLead();

  return (
    <td className="text-center" width="65">
      {showCheckboxOnly ? (
        <div data-test-id={`select-compare-team-button-${row.original.team}`}>
          <Form.Check
            checked={row.getIsSelected()}
            id={`select-compare-team-button-${row.original.team}`}
            label=""
            onChange={() => row.toggleSelected(!row.getIsSelected())}
            disabled={!row.getCanSelect() && !row.getIsSelected()}
          />
        </div>
      ) : (
        <button
          data-test-id={`select-compare-team-button-${row.original.team}`}
          className={cx('text-center', {
            [Styles.RankSelect]: true,
            [Styles.RankSelectDisabled]: !row.getCanSelect(),
            [Styles.RankSelectSelected]: row.getIsSelected(),
          })}
          onClick={() => row.toggleSelected(!row.getIsSelected())}
        >
          {String(getValue()).padStart(2, '0')}
        </button>
      )}
    </td>
  );
};

export const Prefix = ({ getValue }: CellContext<TeamResult, string>) => {
  return <td className={cx(Styles.PrefixColumn, 'text-center text-truncate')}>{getValue()}</td>;
};

export const TeamName = ({ getValue, row }: CellContext<TeamResult, string>) => {
  return (
    <td className={cx(Styles.TeamNameColumn, 'text-start text-truncate')}>
      <Link
        to={row.original.team_url}
        data-test-id={`team-link-${row.original.team}`}
        className={Styles.TeamName}
      >
        {getValue()}
        <br />
        <span className={Styles.TeamCategory}>{row.original.category}</span>
      </Link>
    </td>
  );
};

export const ValidSigma = ({ getValue, table }: CellContext<TeamResult, number>) => {
  const { minSigma, maxSigma } = table.options.meta as any;
  return (
    <td className="text-center" width="80">
      <div className={cx(Styles.Sigma, getRangeBasedClassName(minSigma, maxSigma, getValue()))}>
        {getValue()?.toFixed(2) || 'N/A'}
      </div>
    </td>
  );
};

export const ValidScore = ({ getValue, table }: CellContext<TeamResult, any>) => {
  const { event } = table.options.meta as any;

  let score;
  if (event.judging_ux_mode === 'qualitative_only_mode' && isTeamLead()) {
    score =
      getBucketFromScore(getValue(), event.qualitative_methodology?.buckets)?.abbreviation || 'N/A';
  } else {
    score = getValue()?.toFixed(2) || 'N/A';
  }

  return (
    <td className="text-center" width="65">
      {score}
    </td>
  );
};

export const Evals = ({ getValue }: CellContext<TeamResult, any>) => (
  <td className="text-center" width="65">
    {getValue() || 0}
  </td>
);

function getAgreementStrengthClassName(value: number) {
  let { gradient, isAboveMiddle } = getGradientAndPosition(0, 100, value);
  gradient = Math.round(gradient * 0.7); // Gradient between 0 and 70
  return cx({
    [`negative-value-${gradient}`]: !isAboveMiddle,
    [Styles.AgreementStrengthNoBorder]: !isAboveMiddle,
  });
}

export const AgreementStrength = ({ row, getValue }: CellContext<TeamResult, number>) => {
  const scoreCount = row.original?.score_count || 0;

  return (
    <td className="text-center" width="85">
      <div className={cx(Styles.AgreementStrength, getAgreementStrengthClassName(getValue()))}>
        {scoreCount > 1 && getValue() ? getValue() : 'N/A'}
      </div>
    </td>
  );
};

export const Scorers = ({
  getValue,
  row: { original },
  column,
  table,
}: CellContext<TeamResult, JudgeScore[]>) => {
  const lowest = column.id === 'low_scores';
  let scores = getValue();
  const hasScores = !!scores?.length;
  scores = hasScores ? scores : original.zero_scores;
  scores ||= [];

  function handleJudgeClick(score: JudgeScore) {
    const meta = table.options.meta as any;
    const judge = {
      phase: meta.phase.id,
      id: score.id,
      name: `${score.firstName} ${score.lastName}`,
      team: original.team,
    };
    meta?.onJudgeClick?.(judge);
  }

  return (
    <td className={Styles.JudgeScoresColumn} width="154">
      <div className={Styles.ScoresList}>
        <ul>
          {scores?.map?.(
            (score) =>
              score && (
                <li className="text-truncate" key={score.id}>
                  <a
                    className={cx({
                      [Styles.ScoresListPositive]: !lowest && hasScores,
                      [Styles.ScoresListNegative]: lowest && hasScores,
                      [Styles.ScoresListZero]: !hasScores,
                    })}
                    href={'#open-implicit-rank-modal'}
                    onClick={(e) => {
                      e.preventDefault();
                      handleJudgeClick(score);
                    }}
                  >
                    {score.score?.validScoreZScore?.toFixed?.(2) || 'N/A'} {score.firstName}{' '}
                    {score.lastName}
                  </a>
                </li>
              ),
          )}
        </ul>
      </div>
    </td>
  );
};

export const Status = ({ getValue, row: { original }, table }: CellContext<TeamResult, string>) => {
  const status = getValue();

  const handleSelect = (status: string) => {
    const meta = table.options.meta as any;
    meta?.onStatusChange?.(original.id, status);
  };

  return (
    <td className={Styles.StatusColumn} width="100">
      <StatusButton status={status} onSelect={handleSelect} teamId={original.team} />
    </td>
  );
};
