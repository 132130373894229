let interceptors = [];

function interceptor(fetch, ...args) {
  const reversedInterceptors = interceptors.reduce(
    (array, interceptor) => [interceptor].concat(array),
    [],
  );
  let promise = Promise.resolve(args);

  // Register request interceptors
  reversedInterceptors.forEach(({ request, requestError }) => {
    if (request || requestError) {
      promise = promise.then((args) => request(...args), requestError);
    }
  });

  // Register fetch call
  promise = promise.then((args) => fetch(...args));

  // Register response interceptors
  reversedInterceptors.forEach(({ response, responseError }) => {
    if (response || responseError) {
      if (responseError) {
        // Bind the url and config to the response error callback
        // So the method can tell which request failed
        responseError = responseError.bind({ url: args[0], config: args[1] });
      }
      promise = promise.then(response, responseError);
    }
  });

  return promise;
}
export default (function attach(env) {
  // Make sure fetch is avaibale in the given environment
  if (!env.fetch) {
    console.warn('No fetch avaibale. Unable to register fetch-intercept');
    return;
  }
  env.fetch = (function (fetch) {
    return function (...args) {
      return interceptor(fetch, ...args);
    };
  })(env.fetch);

  return {
    register: function (interceptor) {
      interceptors.push(interceptor);
      return () => {
        const index = interceptors.indexOf(interceptor);
        if (index >= 0) {
          interceptors.splice(index, 1);
        }
      };
    },
    clear: function () {
      interceptors = [];
    },
  };
})(window);
