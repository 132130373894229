import * as schemas from '../schema';
import * as teamsAPI from '../services/teams';

import { RSAA, getJSON } from 'redux-api-middleware';

import { normalize } from 'normalizr';

export const Actions = {
  LOAD_FOR_TEAM: 'artifactItems/LOAD_FOR_TEAM',
  LOAD_FOR_TEAM_RESULT: 'artifactItems/LOAD_FOR_TEAM_RESULT',
  SAVE_FOR_TEAM: 'artifactItems/SAVE_FOR_TEAM',
  SAVE_FOR_TEAM_RESULT: 'artifactItems/SAVE_FOR_TEAM_RESULT',
  DOWNLOAD_ALL_ARTIFACTS_PENDING: 'artifactItems/DOWNLOAD_ALL_ARTIFACTS_PENDING',
  DOWNLOAD_ALL_ARTIFACTS_SUCCESS: 'artifactItems/DOWNLOAD_ALL_ARTIFACTS_SUCCESS',
  DOWNLOAD_ALL_ARTIFACTS_REJECTED: 'artifactItems/DOWNLOAD_ALL_ARTIFACTS_REJECTED',
  DOWNLOAD_ARTIFACT_PDF_TEAM_REPORT_PENDING:
    'artifactItems/DOWNLOAD_ARTIFACT_PDF_TEAM_REPORT_PENDING',
  DOWNLOAD_ARTIFACT_PDF_TEAM_REPORT_SUCCESS:
    'artifactItems/DOWNLOAD_ARTIFACT_PDF_TEAM_REPORT_SUCCESS',
  DOWNLOAD_ARTIFACT_PDF_TEAM_REPORT_REJECTED:
    'artifactItems/DOWNLOAD_ARTIFACT_PDF_TEAM_REPORT_REJECTED',
};

export const load = (teamId, phaseId) => ({
  [RSAA]: {
    ...teamsAPI.loadArtifactItems(teamId, phaseId),
    types: [
      { type: Actions.LOAD_FOR_TEAM, payload: { teamId } },
      {
        type: Actions.LOAD_FOR_TEAM_RESULT,
        payload: (_, __, res) =>
          getJSON(res).then((payload) => ({
            teamId,
            ...normalize(payload.artifact_items, [schemas.artifactItem]),
          })),
      },
      { type: Actions.LOAD_FOR_TEAM_RESULT, payload: { teamId } },
    ],
  },
});

export const saveFile = (teamId, eventId, data) => {
  return {
    [RSAA]: {
      ...teamsAPI.saveArtifactFiles(teamId, eventId, data),
      types: [
        Actions.SAVE_FOR_TEAM,
        {
          type: Actions.SAVE_FOR_TEAM_RESULT,
          payload: (_, __, res) =>
            getJSON(res).then((payload) => ({
              teamId,
              ...normalize(payload.artifact_items, [schemas.artifactItem]),
            })),
        },
        Actions.SAVE_FOR_TEAM_RESULT,
      ],
    },
  };
};

export const save = (teamId, eventId, data) => {
  return {
    [RSAA]: {
      ...teamsAPI.saveArtifactItems(teamId, eventId, data),
      types: [
        Actions.SAVE_FOR_TEAM,
        {
          type: Actions.SAVE_FOR_TEAM_RESULT,
          payload: (_, __, res) =>
            getJSON(res).then((payload) => ({
              teamId,
              ...normalize(payload.artifact_items, [schemas.artifactItem]),
            })),
        },
        Actions.SAVE_FOR_TEAM_RESULT,
      ],
    },
  };
};

export const downloadAllArtifacts = (teamId, evaluationId) => ({
  [RSAA]: {
    ...teamsAPI.downloadAllArtifacts(teamId, evaluationId),
    types: [
      { type: Actions.DOWNLOAD_ALL_ARTIFACTS_PENDING },
      { type: Actions.DOWNLOAD_ALL_ARTIFACTS_SUCCESS },
      { type: Actions.DOWNLOAD_ALL_ARTIFACTS_REJECTED },
    ],
  },
});

export const downloadArtifactPDFTeamReport = (url) => ({
  [RSAA]: {
    ...teamsAPI.downloadArtifactPDFTeamReport(url),
    types: [
      { type: Actions.DOWNLOAD_ALL_ARTIFACTS_PENDING },
      { type: Actions.DOWNLOAD_ALL_ARTIFACTS_SUCCESS },
      { type: Actions.DOWNLOAD_ALL_ARTIFACTS_REJECTED },
    ],
  },
});
