import { Button } from '@valid-eval/shared-react-components';
import { useTranslation } from 'react-i18next';
import { Event } from 'data/features/eventsTypes';
import moment from 'moment-timezone';
import { DisabledStep } from './signUpWizardSteps';
import { Step } from './types';
import { dateTimeFormatted, humanize } from 'utils';
import { useEffect, useState } from 'react';

type SignUpWizardTitleProps = {
  event?: Event;
  activeStep: Step;
  onNextStep: () => void;
  onPrevStep: () => void;
  hasNextStep: boolean;
  hasPrevStep: boolean;
};

const SignUpWizardTitle = ({
  event,
  activeStep,
  onNextStep,
  onPrevStep,
  hasNextStep,
  hasPrevStep,
}: SignUpWizardTitleProps) => {
  const { t } = useTranslation();

  const [hoursLeft, setHoursLeft] = useState<string>('0');
  const [minutesLeft, setMinutesLeft] = useState<string>('0');
  const timezone = moment.tz.guess();
  const teamSignupDeadline = event?.team_signup_deadline
    ? `${dateTimeFormatted(event.team_signup_deadline)} GTM${moment.tz(timezone).format('Z')} - ${humanize(timezone)}`
    : null;

  const calculateHoursLeft = () => {
    let hours = event?.team_signup_deadline
      ? moment.duration(moment(event.team_signup_deadline).diff(moment())).asHours()
      : 0;
    const minutes = Math.floor((hours - Math.floor(hours)) * 60);
    hours = Math.floor(hours);

    setHoursLeft(hours.toString().padStart(2, '0'));
    setMinutesLeft(minutes.toString().padStart(2, '0'));
  };

  useEffect(() => {
    if (!event?.team_signup_deadline) return;

    calculateHoursLeft();
    const interval = setInterval(calculateHoursLeft, 1000);

    return () => clearInterval(interval);
  }, [event?.team_signup_deadline]);

  return activeStep.key === DisabledStep.key ? null : (
    <>
      {!!teamSignupDeadline && (
        <div className="alert alert-success py-3 d-flex align-items-center justify-content-center">
          <i className="fa-solid fa-circle-check me-2 text-success" />
          <span
            dangerouslySetInnerHTML={{
              __html:
                parseInt(hoursLeft) < 24
                  ? t('auth.teams_sign_up.steps.enabled.notice_less_24_hours', {
                      hours: hoursLeft,
                      minutes: minutesLeft,
                    })
                  : t('auth.teams_sign_up.steps.enabled.notice', { date: teamSignupDeadline }),
            }}
          />
        </div>
      )}
      <div className="d-flex justify-content-between mb-2">
        <div>
          <h1 className="mb-0">{t(activeStep.title)}</h1>
          {!!activeStep.info?.stateText && (
            <small className="fw-normal">({activeStep.info?.stateText})</small>
          )}
        </div>
        {activeStep.navigation && (
          <div>
            <Button
              variant="link"
              className="py-2 px-3"
              disabled={!hasPrevStep}
              onClick={onPrevStep}
            >
              <span className="fa-regular fa-lg fa-arrow-left m-0" />
            </Button>
            <Button
              variant="link"
              className="py-2 px-3"
              disabled={!hasNextStep}
              onClick={onNextStep}
            >
              <span className="fa-regular fa-lg fa-arrow-right m-0" />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SignUpWizardTitle;
