import { useMemo, useRef } from 'react';
import { Form } from '@valid-eval/shared-react-components';
import { Field } from 'redux-form/immutable';
import RichTextEditorJodit from './RichTextEditor.jodit';

import { dynamicFieldValidation } from './utils';

export const RichTextEditorFieldGroup = ({ input, meta, config = {}, children, disabled }) => {
  const editorRef = useRef();
  const configMemoized = useMemo(
    () => ({
      defaultActionOnPaste: 'insert_clear_html',
      showPlaceholder: false,
      readonly: false,
      spellcheck: true,
      events: {
        afterInit: (editor) => {
          editorRef.current = editor;
        },
      },
      askBeforePasteHTML: false,
      processPasteHTML: false,
      ...config,
    }),
    [],
  );

  const hasError = meta.touched && meta.error;
  const hasWarning = meta.touched && meta.warning;

  return (
    <>
      <RichTextEditorJodit
        style={{ border: 'solid red 2px' }}
        config={configMemoized}
        editorRef={editorRef}
        value={input.value}
        name={input.name}
        onBlur={(value) => {
          input.onBlur?.(value);
          input.onChange?.(value);
        }}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid" className={hasError || hasWarning ? 'd-block' : ''}>
        <b>
          {hasError && <span>{meta.error}</span>}
          {hasWarning && <span>{meta.warning}</span>}
          <br />
        </b>
      </Form.Control.Feedback>
      {children?.({ editorRef })}
    </>
  );
};

export const RichTextEditor = ({
  id,
  name,
  title,
  helpText,
  validate,
  onDialogShow,
  onDialogHide,
  ...props
}) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={title}
      component={RichTextEditorFieldGroup}
      helpText={helpText}
      validate={validate || dynamicFieldValidation}
      onDialogShow={onDialogShow}
      onDialogHide={onDialogHide}
      {...props}
    />
  );
};
