type PDFInputTeamStatusCheckboxProps = {
  checked: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  status: string;
};

const PDFInputTeamStatusCheckbox = ({
  checked,
  label,
  onChange,
  status,
}: PDFInputTeamStatusCheckboxProps) => {
  return (
    <span className="form-check me-4">
      <input
        id={`pdf-input-teams-status-${status}`}
        value={status}
        name="pdf-input-teams-status"
        type="checkbox"
        className="form-check-input"
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={`pdf-input-teams-status-${status}`} className="form-check-label">
        <small className="text-blue03">{label}</small>
      </label>
    </span>
  );
};

export default PDFInputTeamStatusCheckbox;
