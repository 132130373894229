import { Table as TableType, flexRender } from '@tanstack/react-table';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cx from 'classnames';

import { Table as TableComponent } from '@valid-eval/shared-react-components';

import Row from './Row';
import { TeamResult } from './types';
import Styles from './ResultsTable.module.scss';

type ResultsTableProps = {
  finalRankOrder: 'asc' | 'desc';
  setFinalRankOrder: (order: 'asc' | 'desc') => void;
  tableModel: TableType<TeamResult>;
  phase: any;
  onReorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
};

const Table = ({
  finalRankOrder,
  setFinalRankOrder,
  tableModel,
  phase,
  onReorderRow,
}: ResultsTableProps) => {
  const handleSortByFinalRank = () => {
    setFinalRankOrder(finalRankOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <TableComponent responsive className="react-bs-table">
        <thead>
          {tableModel.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <React.Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </React.Fragment>
              ))}
              {phase.final_rank && (
                <th className={Styles.SortableColumn} onClick={handleSortByFinalRank}>
                  Program Rank
                  {!Boolean(finalRankOrder) && (
                    <i className={cx('fa-solid fa-sort', Styles.SortableColumnIndicator)} />
                  )}
                  {Boolean(finalRankOrder) && (
                    <i
                      className={cx('fa-solid', Styles.SortableColumnIndicatorActive, {
                        'fa-sort-down': finalRankOrder === 'asc',
                        'fa-sort-up': finalRankOrder === 'desc',
                      })}
                    />
                  )}
                </th>
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableModel.getRowModel().rows.map((row) => (
            <Row key={row.id} row={row} reorderRow={onReorderRow} phase={phase} />
          ))}
        </tbody>
      </TableComponent>
    </DndProvider>
  );
};

export default Table;
