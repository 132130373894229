import cx from 'classnames';
import PropTypes from 'prop-types';

import Styles from './ActiveFilters.module.scss';

function ActiveFilters({ filters, onFiltersChange }) {
  const makeHandleRemoveFilter = (filter) => () => {
    filters = filters.filter(
      (f) => f.field !== filter.field || f.label !== filter.label || f.value !== filter.value,
    );
    onFiltersChange(filters);
  };

  return (
    <div className={cx('col d-flex align-items-center flex-wrap', Styles.Container)}>
      {!!filters.length && <span className={Styles.Label}>Active filters:</span>}
      {filters?.map((filter) => (
        <div className={Styles.Filter} key={`filter-${filter.field}-${filter.value}`}>
          {filter.label}
          <button type="button" className="close" onClick={makeHandleRemoveFilter(filter)}>
            {'×'}
          </button>
        </div>
      ))}
    </div>
  );
}

ActiveFilters.propTypes = {
  filters: PropTypes.array,
  onFiltersChange: PropTypes.func,
};

export default ActiveFilters;
