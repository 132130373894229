import { Email, Input } from 'components/FormFields';
import { email, required } from 'components/FormFields/validations';
import { Map } from 'immutable';

import { Col, Row } from '@valid-eval/shared-react-components';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';

const invitationExists = (value, props) => {
  if (!value) return;

  const existingInvitations = props.get('existingInvitations')?.toJS() || [];
  return existingInvitations.some((i) => i.email.toLowerCase() === value.toLowerCase())
    ? 'This email address has already been invited.'
    : undefined;
};

function JudgeInvitationsAddManuallyFields({ fields }) {
  const values = useSelector(getFormValues('JudgeInvitationsAddManually'))?.toJS() || {};

  return fields.map((field, index) => {
    const isEmailRequired =
      index === 0 ||
      values?.invitations?.[index]?.first_name ||
      values?.invitations?.[index]?.last_name;

    return (
      <Row key={`invitation-${field}`} className="m-0 mb-2">
        <Col className="ps-0">
          <Input
            className="m-0"
            name={`${field}.first_name`}
            placeholder="First Name"
            autoFocus={index === 0}
          />
        </Col>
        <Col>
          <Input className="m-0" name={`${field}.last_name`} placeholder="Last Name" />
        </Col>
        <Col className="pe-0">
          <Email
            className="m-0"
            name={`${field}.email`}
            placeholder="Email"
            validate={[email, invitationExists, ...(isEmailRequired ? [required] : [])]}
            onKeyDown={(e) => {
              if ((e.code === 'Tab' || e.keyCode === 9) && index === fields.length - 1) {
                fields.push(new Map());
              }
            }}
          />
        </Col>
      </Row>
    );
  });
}

export default JudgeInvitationsAddManuallyFields;
