import { Button, Col, Modal, Row } from '@valid-eval/shared-react-components';
import { error, success } from 'data/actions/notifications';
import { inviteMembers } from 'data/actions/teams';
import { connect } from 'react-redux';
import { arrayPush, submit } from 'redux-form/immutable';
import MembersInviteForm from './MembersInviteForm';

function MembersInviteModal({
  afterSend,
  arrayPush,
  existingEmails,
  inviteMembers,
  notifyError,
  notifySuccess,
  onClose,
  show,
  submit,
  teamId,
}) {
  const initialValues = { invitations: [{}, {}], existingEmails };
  const handleAddMore = () => arrayPush('MembersInviteForm', 'invitations', new Map());
  const handleNext = () => submit('MembersInviteForm');

  async function handleSubmit(mapValues) {
    const { invitations } = mapValues?.toJS?.();
    const { error, payload } = await inviteMembers(
      teamId,
      invitations.filter((i) => !!i.email),
    );

    if (error) {
      notifyError(payload?.response?.error || 'Failed to send the invitations, please try again.');
    } else {
      notifySuccess('The invitations have been sent');
      afterSend();
    }

    onClose();
  }

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Invite Members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MembersInviteForm initialValues={initialValues} onSubmit={handleSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col className="p-0">
            <Button variant="link" onClick={handleAddMore}>
              <i className="fa-solid fa-circle-plus fa-lg" />
              Add more
            </Button>
          </Col>
          <Col className="text-end p-0">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" data-test-id="send-team-member-invitation" onClick={handleNext}>
              Send Invitation
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default connect(() => ({}), {
  arrayPush,
  submit,
  inviteMembers,
  notifyError: error,
  notifySuccess: success,
})(MembersInviteModal);
