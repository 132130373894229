/* eslint-disable no-unused-expressions */

import { Component } from 'react';
import { baseCdnUrlFor } from 'utils';

const getURL = (url, fromServer = false) => {
  return fromServer && !url.startsWith('http') ? baseCdnUrlFor(url) : url;
};

const noImage = '/images/no-image.png';

class Image extends Component {
  state = { isImageError: false };

  render() {
    const { props } = this;
    const { isImageError } = this.state;
    const backgroundUrl = `url(${isImageError ? '/images/no-image.png' : props.src})`;

    if (props.fit) {
      return (
        <>
          <img
            alt="presentation"
            style={{ display: `none` }}
            src={props.src}
            onError={() => this.setState({ isImageError: true })}
          />
          <div
            ref={(node) => {
              node?.style.setProperty('width', `${props.width || 160}px`);
              node?.style.setProperty('height', `${props.height || 160}px`);
              node?.style.setProperty('background-image', backgroundUrl, 'important');
              node?.style.setProperty('background-size', props.type || `contain`, 'important');
              node?.style.setProperty('background-repeat', `no-repeat`, 'important');
              if (props.center) {
                node?.style.setProperty('background-position', `center center`, 'important');
              }
            }}
          />
        </>
      );
    } else {
      return (
        <img
          alt="presentation"
          {...props}
          src={props.src}
          style={this.state.isImageError ? { display: 'none' } : {}}
          onError={(e) => {
            if (!e.target.src.endsWith(noImage)) {
              e.target.onerror = null;
              e.target.src = noImage;
            } else {
              this.setState({ isImageError: true });
            }
          }}
        />
      );
    }
  }
}

const ServerImage = ({ src, ...props }) => <Image src={getURL(src, true)} {...props} />;

export { ServerImage };
export default Image;
