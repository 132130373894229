import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Button, Modal } from '@valid-eval/shared-react-components';
import Table from 'components/Tables/Table';
import { useState } from 'react';

import { columns } from './components/Definition';
import EmptyContent from './components/EmptyContent';
import { Judge } from './components/types';
import { JudgeEvaluation } from 'data/features/judgeEvaluationsTypes';

type EvaluationsTableProps = {
  data: JudgeEvaluation[];
  judgeId: string;
  judge: Judge;
  eventId: string;
  eventName: string;
  judgeEvaluationIdWaitingForScore: string;
  onRecuseEvaluation: (teamId: string | null, evaluationId: string | null) => void;
  onBeginEvaluation: (evaluationId: string | null) => void;
  isQualitativeMode: boolean;
};

const EvaluationsTable = ({
  data,
  eventId,
  eventName,
  judgeId,
  judge,
  judgeEvaluationIdWaitingForScore,
  onBeginEvaluation,
  onRecuseEvaluation,
  isQualitativeMode,
}: EvaluationsTableProps) => {
  const [recuseModal, setRecuseModal] = useState<boolean>(false);
  const [recuseTeamId, setRecuseTeamId] = useState<string | null>(null);
  const [recusePhaseId, setRecusePhaseId] = useState<string | null>(null);

  // Recuse
  const openRecuseModal = (teamId: string, phaseId: string) => {
    setRecuseTeamId(teamId);
    setRecusePhaseId(phaseId);
    setRecuseModal(true);
  };

  const toggleModal = () => setRecuseModal(!recuseModal);

  const handleRecuseEvaluation = () => {
    onRecuseEvaluation(recuseTeamId, recusePhaseId);
    toggleModal();
  };

  // Table
  const tableModel = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      judgeId,
      eventId,
      openRecuseModal,
      onBeginEvaluation,
      judgeEvaluationIdWaitingForScore,
      isQualitativeMode,
    },
  });

  return (
    <>
      <Modal show={recuseModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recuse judge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to recuse yourself from evaluating this team?</p>
          <p>Doing so will immediately remove this team from your list of evaluations.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleModal} variant="link">
            Cancel
          </Button>
          <Button onClick={handleRecuseEvaluation} variant="success">
            Recuse
          </Button>
        </Modal.Footer>
      </Modal>
      <Table
        striped
        tableModel={tableModel}
        emptyContent={<EmptyContent judge={judge} eventName={eventName} />}
      />
    </>
  );
};

export default EvaluationsTable;
