import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ListGroup, OptionValue } from '@valid-eval/shared-react-components';
import { fromArtifacts } from 'data/features/artifacts';
import { getEvaluationsCountForSelectedPhase, getEvent, getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { organizerNav } from 'utils/urls';
import { exportPDFMultiple } from 'data/actions/events';
import { error as notifyError } from 'data/actions/notifications';

import ReportsListItem from './ReportsListItem';
import TeamSelectorModal from './TeamSelectorModal';

type OwnProps = {
  params: {
    event_id: string;
  };
};

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  event: getEvent(state, ownProps?.params.event_id)?.toJS(),
  phase: getSelectedPhase(state, ownProps)?.toJS(),
  pdfReportArtifacts: fromArtifacts.getPdfReportsByEventId(state.toJS(), ownProps?.params.event_id),
  // @ts-expect-error own props argument is not recognized
  totalEvaluations: getEvaluationsCountForSelectedPhase(state, ownProps),
});

const mapDispatchToProps = { exportPDFMultiple, notifyError };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps;

const PDFReportArtifacts = ({
  pdfReportArtifacts,
  event,
  phase,
  totalEvaluations,
  exportPDFMultiple,
  notifyError,
}: PropsFromRedux) => {
  const [isSinglePDF, setIsSinglePDF] = useState(false);
  const [showModal, openModal, closeModal] = useBooleanFlag(false);
  const [pdfTeamRange, setPdfTeamRange] = useState<{
    first: number | string;
    last: number | string;
  }>({ first: 1, last: 1 });

  const [pdfTeamStatusCheckboxes, setPdfTeamStatusCheckboxes] = useState(
    Object.values(phase.status_framework).reduce<{ [key: string]: boolean }>((acc, status: any) => {
      if (status.active) acc[String(status.value)] = true;
      return acc;
    }, {}),
  );

  function handleOnChangePDFTeamStatusCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setPdfTeamStatusCheckboxes({ ...pdfTeamStatusCheckboxes, [e.target.value]: e.target.checked });
  }

  const handleConfirmTeamFilesReport = async ({
    teams,
    isSinglePDF,
    selectedPdfReportArtifact,
  }: {
    teams: OptionValue[];
    isSinglePDF: boolean;
    selectedPdfReportArtifact: OptionValue | null;
  }) => {
    const teamIds = teams.map((t) => t.value);

    const payload = {
      eventId: event.id,
      eventName: event.name,
      phaseId: phase.id,
      phaseName: phase.name,
      url: organizerNav.printPDFReportArtifact(
        event.id,
        phase.id,
        ':team_id',
        selectedPdfReportArtifact?.value,
        isSinglePDF,
      ),
      team_ids: teamIds,
      first: pdfTeamRange.first,
      last: pdfTeamRange.last,
    };

    // @ts-expect-error payload is not typed
    const result = await exportPDFMultiple(payload);

    // @ts-ignore
    if (result?.error && result?.payload?.response?.error)
      // @ts-ignore
      notifyError(result.payload.response.error);
    closeModal();
  };

  if (!pdfReportArtifacts?.length) return null;

  return (
    <>
      <TeamSelectorModal
        isSinglePDF={isSinglePDF}
        setIsSinglePDF={setIsSinglePDF}
        eventId={event.id}
        onCancel={closeModal}
        onConfirm={handleConfirmTeamFilesReport}
        show={showModal}
        customModalTitle="Team PDF Reports"
        phaseFinalRank={phase.final_rank}
        pdfTeamRange={pdfTeamRange}
        totalEvaluations={totalEvaluations}
        pdfTeamStatusCheckboxes={pdfTeamStatusCheckboxes}
        handleOnChangePDFTeamStatusCheckbox={handleOnChangePDFTeamStatusCheckbox}
        setPdfTeamRange={setPdfTeamRange}
        pdfReportArtifacts={pdfReportArtifacts.map((p) => ({
          value: p.id,
          label: p.teams_and_organizers_name,
        }))}
      />
      <h2>Other PDF Reports</h2>
      <ListGroup>
        <ReportsListItem
          key={'pdf-report-artifacts'}
          reportName={'pdf-report-artifacts'}
          title={'Team PDF Reports'}
          description={'Custom PDF reports for your teams'}
          onClick={openModal}
        />
      </ListGroup>
    </>
  );
};

export default withRouter(connector(PDFReportArtifacts));
