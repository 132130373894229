import { Judge } from 'screens/app/events/shared/types';

export const statusType = {
  active: 0,
  inactive: 0,
};

// Judge Match Section

export const searchableFields = ['panel', 'name'];

export const filterByStatus = (filterList: any[], judgeResult: Judge) => {
  if (filterList.length) {
    // Apply the decision status filter if is selected
    const status = filterList.reduce((acumm, filter) => [...acumm, filter.value], []);

    if (status.length > 1) {
      if (
        status.includes(true) &&
        !judgeResult.active &&
        status.includes(false) &&
        judgeResult.active
      )
        return false;
    } else {
      if (status.includes(!judgeResult.active)) return false;
    }
  }
  return true;
};
