import { Nav, NavItem } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import HelpModal from 'screens/app/components/HelpModal';

import './UnauthorizedNav.scss';
import { connect } from 'react-redux';
import { openSignUpModal } from 'data/features/authentication';

const connector = connect(null, { openSignUpModal });

const UnauthorizedNav = ({ showModal, onShowModal, openSignUpModal }) => {
  const locationObj = useLocation();
  const { pathname, search } = locationObj;

  let displayLogInLink = false;
  if (
    (pathname.indexOf('signup') > -1 && search.indexOf('already_registered_email') > -1) ||
    (pathname.indexOf('teams') > -1 && pathname.indexOf('signup') > -1)
  ) {
    displayLogInLink = true;
  }

  return (
    <>
      <Nav style={{ flex: 1 }}>
        <div className="d-flex align-items-center" style={{ flex: 1 }}>
          <div className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Nav.Item className="item">
              <Nav.Link href="https://valideval.com/who/">Who We Serve</Nav.Link>
            </Nav.Item>
            <Nav.Item className="item">
              <Nav.Link href="https://valideval.com/what">What We Do</Nav.Link>
            </Nav.Item>
            <Nav.Item className="item">
              <Nav.Link href="https://valideval.com/why">Why Us?</Nav.Link>
            </Nav.Item>
            <Nav.Item className="item">
              <Nav.Link href="https://valideval.com/about">About</Nav.Link>
            </Nav.Item>
            <NavItem className="item">
              <Nav.Link href="https://valideval.com/contact">Contact</Nav.Link>
            </NavItem>
          </div>
          <div className="d-flex justify-content-end">
            <Nav.Item className="main-menu-nav-item pe-2">
              <Nav.Link id="item" onClick={onShowModal} eventKey={1}>
                <i className={cx('demo', 'fa-solid fa-circle-question fa-lg')} />
              </Nav.Link>
            </Nav.Item>
            {displayLogInLink && (
              <NavItem className="loginItem">
                <Nav.Link href="#login" onClick={openSignUpModal}>
                  Log In
                  <img className="logoImg" src="/images/icons/icn-login.png" alt="" />
                </Nav.Link>
              </NavItem>
            )}
          </div>
        </div>
      </Nav>
      <HelpModal show={showModal} onCancel={onShowModal} />
    </>
  );
};

export default connector(UnauthorizedNav);
