import Styles from './EvaluationQuartile.module.scss';
import cx from 'classnames';

const addSuffix = (number) => {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const formatJudges = (judges) => {
  if (!judges || judges === 0) {
    return 'No Judge Reporting';
  } else if (judges === 1) {
    return '1 Judge Reporting';
  } else {
    return `${judges} Judges Reporting`;
  }
};

const quartileClassName = (quartile) => Styles[`Quartile-${quartile}`];

const EvaluationQuartile = ({ quartile, judges }) => (
  <div className={cx(Styles.Container, quartileClassName(quartile))}>
    <h1 className={cx(Styles.Title, 'colored', 'text-end')}>{addSuffix(quartile)} Quartile</h1>
    <div className={Styles.Bar}>
      <span
        className={cx(Styles.Quartile, quartileClassName(quartile), {
          [Styles.Filled]: quartile === 4,
        })}
      />
      <span
        className={cx(Styles.Quartile, quartileClassName(quartile), {
          [Styles.Filled]: quartile === 3,
        })}
      />
      <span
        className={cx(Styles.Quartile, quartileClassName(quartile), {
          [Styles.Filled]: quartile === 2,
        })}
      />
      <span
        className={cx(Styles.Quartile, quartileClassName(quartile), {
          [Styles.Filled]: quartile === 1,
        })}
      />
    </div>
    <p>{formatJudges(judges)}</p>
  </div>
);

EvaluationQuartile.displayName = 'EvaluationQuartile';
export default EvaluationQuartile;
