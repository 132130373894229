import cx from 'classnames';
import { ProgressBar } from '@valid-eval/shared-react-components';

import Styles from './SignUpNav.module.scss';

type SignUpProgressBarProps = {
  progress: number;
  disabled?: boolean;
};

const SignUpProgressBar = ({ progress, disabled }: SignUpProgressBarProps) => {
  return (
    <div className="mt-1 mb-2 p-1">
      <ProgressBar
        now={progress}
        label={`${progress}%`}
        variant="success"
        className={cx(Styles.ProgressBar, {
          [Styles.ProgressBarDisabled]: disabled,
          [Styles.ProgressBarEmpty]: progress === 0,
        })}
      />
    </div>
  );
};

export default SignUpProgressBar;
