import { actions } from 'data/actions/invitationTemplates';
import { fromJS } from 'immutable';

const initialState = fromJS({
  from: '',
  subject: '',
  content: '',
});

const invitationTemplates = (state = initialState, action) => {
  if (action.type === actions.LOAD_SUCCESS) return state.merge(action.payload.invitationTemplate);
  if (action.type === actions.SAVE_SUCCESS) return state.merge(action.payload.invitationTemplate);
  return state;
};

export const getInvitationTemplate = (state) => state.toJS();

export default invitationTemplates;
