import { Component } from 'react';

import { Form } from '@valid-eval/shared-react-components';

export default class RadioList extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: props.value || '' };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ selected: nextProps.value });
    }
  }

  handleChange = (ev) => {
    const { value } = ev.target.dataset;
    this.setState({ selected: value }, () => this.props.onChange(value));
  };

  render() {
    const {
      disabled,
      name,
      meta: { error, warning, touched },
    } = this.props;
    return (
      <>
        {this.props.values.map((data, index) => {
          data = data?.toJS?.() || data;
          const { value, label } = data;
          return (
            <Form.Check
              id={`${name}-radio-${index}`}
              key={value}
              data-value={value}
              type={'radio'}
              onChange={this.handleChange}
              checked={value === this.state.selected}
              disabled={disabled}
              label={label}
              isInvalid={touched && (error || warning)}
              isValid={touched && !(error || warning)}
            />
          );
        })}
        {touched && (error || warning) && (
          <div className="invalid-feedback d-block" style={{ position: 'absolute', bottom: 0 }}>
            {error || warning}
          </div>
        )}
      </>
    );
  }
}
