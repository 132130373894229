import { Button, Modal } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { error as showError, success as showSuccess } from 'data/actions/notifications';
import { loadInactiveTeams, updateManyRoleStatuses } from 'data/actions/roleSummaries';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import withRouter from 'routes/withRouter';
import { getTeamSummaries } from 'data/reducers';

import Styles from './components/TeamsAdd.module.scss';
import TeamsAddModalBody from './TeamsAddModalBody';
import { InactiveTeam } from './types';

type OwnProps = {
  onDismiss: () => void;
  phaseId: string;
  show: boolean;
};

const mapDispatchToProps = { loadInactiveTeams, updateManyRoleStatuses, showError, showSuccess };
const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  // @ts-ignore
  currentTeams: getTeamSummaries(state, ownProps),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamsAddModalProps = PropsFromRedux & OwnProps;

const TeamsAddModal = ({
  loadInactiveTeams,
  onDismiss,
  phaseId,
  show,
  showError,
  showSuccess,
  updateManyRoleStatuses,
  currentTeams,
}: TeamsAddModalProps) => {
  const [areTeamsLoading, setTeamsLoading, setTeamsCompletedLoading] = useBooleanFlag(false);
  const [isLoading, startLoading, finishLoading] = useBooleanFlag(false);

  const [teams, setTeams] = useState<InactiveTeam[]>([]);
  const [scoresCount, setScoresCount] = useState<number | null>(null);
  const [previousPhaseId, setPreviousPhaseId] = useState<string | null>(null);
  const [selectedTeams, setSelectedTeams] = useState<InactiveTeam[]>([]);

  async function loadInactiveTeamsData() {
    setTeamsLoading();
    try {
      const result = (await loadInactiveTeams(phaseId)) as any;
      setTeams((result.payload.teams as InactiveTeam[]) || []);
      setScoresCount((result.payload.scores_count as number) || 0);
      setPreviousPhaseId((result.payload.previous_phase_id as string) || null);
    } finally {
      setTeamsCompletedLoading();
    }
  }

  useEffect(() => {
    if (show) loadInactiveTeamsData();
  }, [show]);

  async function handleConfirm() {
    startLoading();
    try {
      await updateManyRoleStatuses(
        phaseId,
        'teams',
        true,
        // @ts-ignore
        selectedTeams.map((team) => team.id),
      );
      showSuccess('The selected teams were added.', 'Success');
    } catch {
      showError('There was an error adding the selected teams, please try again.');
    } finally {
      finishLoading();
      onDismiss();
    }
  }

  const currentTeamsCount = currentTeams?.length || 0;
  const selectedTeamsCount = selectedTeams?.length || 0;
  const newTotalTeamsCount = currentTeamsCount + selectedTeamsCount;

  return (
    <Modal
      id={`add-teams-modal`}
      show={show}
      onHide={onDismiss}
      size={'xl'}
      contentClassName={Styles.ModalContent}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Teams</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 'calc(100vh - 250px)' }}>
        <TeamsAddModalBody
          loading={areTeamsLoading}
          data={teams}
          selectedTeams={selectedTeams}
          onSelectedTeamsChange={setSelectedTeams}
          previousPhaseId={previousPhaseId}
          scoresCount={scoresCount}
        />
      </Modal.Body>

      <Modal.Footer className="justify-content-start">
        <div className="flex-grow-1 d-flex align-items-end ps-3">
          <p className="p-0 m-0 text-center lh-sm fw-bold text-muted">
            <small>
              Current
              <br />
              Teams
              <br />
            </small>
            {currentTeamsCount}
          </p>
          <p className="py-0 px-4 m-0 text-center fw-bold text-muted">+</p>
          <p className="p-0 m-0 text-center lh-sm fw-bold text-primary">
            <small>
              New
              <br />
              Teams
              <br />
            </small>
            {selectedTeamsCount}
          </p>
          <p className="py-0 px-4 m-0 text-center fw-bold text-muted">=</p>
          <p className="p-0 m-0 text-center lh-sm fw-bold">
            <small>
              New
              <br />
              Total
              <br />
            </small>
            {newTotalTeamsCount}
          </p>
        </div>
        <Button
          id={`add-teams-modal-cancel`}
          onClick={onDismiss}
          variant={'link'}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type={'submit'}
          variant={'success'}
          id={`add-teams-modal-confirm`}
          onClick={handleConfirm}
          disabled={selectedTeamsCount === 0 || isLoading}
        >
          Add{selectedTeamsCount ? ` ${selectedTeamsCount} ` : ' '}Teams
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(connector(TeamsAddModal));
