import { organizerNav } from '../urls';

export const getActionButtons = (notification) => [
  {
    title: 'Manage Team',
    url: organizerNav.profile(
      notification.data.get('event_id'),
      'teams',
      notification.data.get('team_profile_id'),
    ),
    toggleSeen: true,
  },
];

export const getText = (notification) =>
  `New team has signed up: ${notification.data.get('team_name')} for the ${
    notification.event
  } cohort`;
