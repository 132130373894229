import cx from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import { Button, Col, Row } from '@valid-eval/shared-react-components';
import { useDispatch } from 'react-redux';
import { arrayPush, FieldArray, reduxForm } from 'redux-form/immutable';
import JudgeInvitationsAddManuallyFields from './JudgeInvitationsAddManuallyFields';
import Styles from './JudgeInvitationsModal.module.scss';

function JudgeInvitationsAddManually({ handleSubmit, onCancel, onNext }) {
  const dispatch = useDispatch();

  function handleFormSubmit(values) {
    // Return only invitations that have an email address set
    const { invitations } = values?.toJS();
    onNext(invitations.filter((v) => !!v.email));
  }

  function handleAddMore(event) {
    event.preventDefault();
    dispatch(arrayPush('JudgeInvitationsAddManually', 'invitations', new Map()));
  }

  return (
    <form className={cx('w-100 h-100')} onSubmit={handleSubmit(handleFormSubmit)}>
      <div className={cx('mt-2', Styles.FieldsContainer)}>
        <FieldArray name="invitations" component={JudgeInvitationsAddManuallyFields} />
      </div>
      <Row className={cx('mt-3 me-0 ms-0 pe-0 ps-0 pt-4 align-self-end', Styles.ModalButtons)}>
        <Col className="p-0">
          <Button variant="link" onClick={handleAddMore}>
            <i className="fa-solid fa-circle-plus fa-lg" />
            Add more
          </Button>
        </Col>
        <Col className="text-end p-0">
          <Button variant="link" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Next</Button>
        </Col>
      </Row>
    </form>
  );
}

JudgeInvitationsAddManually.propTypes = {
  onNext: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'JudgeInvitationsAddManually',
  enableReinitialize: true,
})(JudgeInvitationsAddManually);
