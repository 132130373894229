import {
  FieldArray,
  getFormSubmitErrors,
  getFormSyncErrors,
  hasSubmitFailed,
  reduxForm,
} from 'redux-form/immutable';
import { Form, FormGroup } from '@valid-eval/shared-react-components';
import { Input, Number } from 'components/FormFields';
import {
  number as invalidNumber,
  name,
  required,
  sanitizeName,
} from 'components/FormFields/validations';

import Emails from './Emails';
import LoadingButton from 'components/LoadingButton';

import { connect } from 'react-redux';
import { isGovEnv } from 'config';

const validate = (values) => {
  const positives = [
    'early_stage_investing_experience',
    'fund_raising_experience',
    'exits',
    'failures',
  ];
  const fields = [
    'first_name',
    'last_name',
    'primary_job_function',
    'business_discipline',
    ...positives,
  ];
  let errors = {};

  errors = fields.reduce((acc, field) => {
    const value = values.get(field);

    // allow 0 as a value for positives
    if (positives.includes(field) && !value) {
      return acc;
    }

    if (!value || value === '-1') {
      acc[field] = 'This value should not be blank.';
    }

    if (positives.indexOf(field) !== -1 && (invalidNumber(value) || parseInt(value, 10) < 0)) {
      acc[field] = 'This value should be 0 or positive number.';
    }
    return acc;
  }, {});

  const userEmails = values.get('user_emails');
  const allEmailsPresent = userEmails
    ? userEmails.reduce((acc, email) => acc && !!email.get('email'), true)
    : false;

  if (!allEmailsPresent) errors.emails = { _error: 'This value should not be blank.' };

  return errors;
};

const ProfileForm = ({ handleSubmit, loading, hasSubmitFailed, submitErrors, syncErrors }) => {
  return (
    <div>
      <FieldArray name="user_emails" title="Emails" component={Emails} />
      <h2 className="mt-2">Profile Details</h2>
      <Form onSubmit={handleSubmit}>
        <Input
          id="first_name"
          name="first_name"
          title="First Name"
          validate={[required, name]}
          normalize={sanitizeName}
        />
        <Input
          id="last_name"
          name="last_name"
          title="Last Name"
          validate={[required, name]}
          normalize={sanitizeName}
        />
        {!isGovEnv() && (
          <>
            <Number
              id="early_stage_investing_experience"
              name="early_stage_investing_experience"
              label="Early Stage Investing Experience"
              title="How many startup companies have you invested in? ( ± 20%)"
            />
            <Number
              id="fund_raising_experience"
              name="fund_raising_experience"
              label="Fundraising Experience"
              title="How many rounds of funding have you raised for startups? ( ± 20%)"
            />
            <Number
              id="exits"
              name="exits"
              label="Exits"
              title="Number of companies you have sold for a profit ( ± 20%)"
            />
            <Number
              id="failures"
              name="failures"
              label="Failures"
              title="Number of companies where you have failed ( ± 20%)"
            />
          </>
        )}
        <FormGroup className="mt-3 mb-3">
          <div className="mt-3 mb-3">
            <LoadingButton
              loading={loading}
              variant="primary"
              type="submit"
              className="pull-down"
              hasSubmitFailed={hasSubmitFailed}
              submitErrors={submitErrors}
              syncErrors={syncErrors}
            >
              Save Profile
            </LoadingButton>
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};
export default reduxForm({
  form: 'profileForm',
  validate,
  enableReinitialize: true,
})(
  connect((state) => {
    let submitErrors = getFormSubmitErrors('profileForm')(state) || {};
    let syncErrors = getFormSyncErrors('profileForm')(state) || {};
    return {
      hasSubmitFailed: hasSubmitFailed('profileForm')(state),
      submitErrors: submitErrors.toJS?.() || submitErrors,
      syncErrors: syncErrors.toJS?.() || syncErrors,
    };
  })(ProfileForm),
);
