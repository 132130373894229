import GenericModal from 'components/GenericModal';

export const renderBodyContent = (email) => (
  <>
    Are you sure you want to use <strong>{email}</strong> as your primary email address?
  </>
);

const ConfirmEmailPrimaryModal = ({ show, onCancel, onConfirm, emails, index }) => {
  const emailRecord = emails.get(index);
  const email = emailRecord?.get('email');

  return (
    <GenericModal
      show={show}
      onCancel={onCancel}
      onConfirm={onConfirm}
      name={'primary-email'}
      title={'Primary Email'}
      body={renderBodyContent(email)}
      cancelButton={'Cancel'}
      confirmButton={'Confirm'}
    />
  );
};

export default ConfirmEmailPrimaryModal;
