import ScoresWithLoading from 'components/Scores';

const Scores = ({
  evaluation,
  phasePerformanceEnabled,
  phaseSummary,
  rubric,
  score_id,
  selfScore,
}) => {
  return (
    <ScoresWithLoading
      isTeam
      rubric={rubric}
      evaluation={evaluation}
      phaseSummary={phaseSummary}
      selfScore={selfScore}
      selectedScore={score_id}
      phasePerformanceEnabled={phasePerformanceEnabled}
    />
  );
};

export default Scores;
