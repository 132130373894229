import { apiHeaders, apiURL } from '../services/config';

export const createAsyncReport = (event, report, phase, extraParams) => ({
  endpoint: apiURL(
    `/events/${event}/reports/create_async?type=${report}${
      phase !== null && phase !== undefined ? '&phase_id=' + phase : ''
    }`,
  ),
  method: 'POST',
  credentials: 'include',
  headers: apiHeaders,
  body: JSON.stringify({ extra_params: extraParams }),
});
