import { Form } from '@valid-eval/shared-react-components';
import { useEffect, useRef } from 'react';
import { Field } from 'redux-form/immutable';
import FileDropzone from './FileDropzone';
/* eslint no-mixed-operators: 0 */
import styles from './FileUpload.module.scss';

import { dynamicFieldValidation } from './utils';

export const RenderDropzoneInput = ({
  input,
  meta: { error, warning, touched },
  id,
  label,
  disabled,
  onDropFile,
  name,
  acceptedFileType,
  helpText,
  current,
  eventId,
  teamId,
  fileExtension,
  disableDelete,
}) => {
  const initialized = useRef(false);

  useEffect(() => {
    if (current.name && !initialized.current) {
      input.onChange({ [input.name]: current.url, saved: true });
      initialized.current = true;
    }
  }, [current]);

  const style = warning ? styles.isInvalid : styles.isValid;

  return (
    <Form.Group controlId={id} className={styles.fileUpload}>
      <div className={touched ? error || style : undefined}>
        {label && (
          <Form.Label>
            <b className={styles.fileUpload}>{label}</b>
          </Form.Label>
        )}
        {fileExtension && <span className={styles.fileExtension}> ({fileExtension} file)</span>}

        {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}

        <FileDropzone
          eventId={eventId}
          teamId={teamId}
          disabled={disabled}
          disableDelete={disableDelete}
          onDropFile={(filesToUpload) => {
            if (onDropFile) onDropFile(filesToUpload);
            if (!filesToUpload?.[input.name]?.length) {
              input.onChange(filesToUpload);
              setTimeout(() => input.onChange(null), 1000);
            }
          }}
          acceptedFileType={acceptedFileType}
          input={input}
          name={name}
          current={current}
          {...input}
        />

        <Form.Control.Feedback
          type="invalid"
          className={touched && (error || warning) ? 'd-block' : undefined}
        >
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
};

export const FileUpload = ({
  id,
  name,
  title,
  onDropFile,
  acceptedFileType,
  current,
  helpText,
  disabled,
  validate,
  eventId,
  teamId,
  fileExtension,
  disableDelete,
}) => (
  <Field
    id={id || name}
    name={name}
    label={title}
    helpText={helpText}
    current={current}
    acceptedFileType={acceptedFileType}
    component={RenderDropzoneInput}
    validate={validate || dynamicFieldValidation}
    onDropFile={onDropFile}
    disabled={disabled}
    eventId={eventId}
    teamId={teamId}
    fileExtension={fileExtension}
    disableDelete={disableDelete}
  />
);
