import * as assignments from 'data/actions/assignments';
import * as evaluations from 'data/actions/evaluations';
import * as eventActions from 'data/actions/events';
import * as judgeAssignments from 'data/actions/judgeAssignments';
import * as teamAssignments from 'data/actions/teamAssignments';

import { combineActions, handleActions } from 'redux-actions';

import { fromJS } from 'immutable';
import { actions as judgeActions } from 'data/actions/judgeProfiles';
import { actions as teamActions } from 'data/actions/teams';

const { LOAD_RESULT } = eventActions;

const { SAVE_JUDGE_ASSIGNMENTS } = assignments;

const initialState = fromJS({ loading: false, data: {} });

export default handleActions(
  {
    [combineActions(assignments.loadJudgeAssignmentResult, assignments.loadJudgeSuggestionsResult)]:
      {
        next(state, action) {
          return state.mergeDeepIn(['data'], fromJS(action.payload.categories));
        },
      },
    [combineActions(
      judgeActions.LOAD_RESULT,
      SAVE_JUDGE_ASSIGNMENTS,
      evaluations.LOAD_MULTIPLE_RESULT,
      teamAssignments.LOAD_TEAM_ASSIGNMENT_RESULT,
      teamAssignments.LOAD_TEAM_SUGGESTIONS_RESULT,
      LOAD_RESULT,
      teamActions.UPDATE_RESULT,
      teamActions.LOAD_RESULT,
      judgeAssignments.LOAD_JUDGE_ASSIGNMENTS_RESULT,
      judgeAssignments.LOAD_JUDGE_SUGGESTIONS_RESULT,
    )]: {
      next(state, action) {
        return state.mergeDeepIn(['data'], fromJS(action.payload.entities.categories));
      },
    },
  },
  initialState,
);

export const getLookup = (state) => state.get('data');
