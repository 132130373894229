import { RSAA } from 'redux-api-middleware';

import * as api from 'data/services/support';

export enum ActionTypes {
  SET_CURRENT_ROLE = 'users/SET_CURRENT_ROLE',
  UNSET_CURRENT_ROLE = 'users/UNSET_CURRENT_ROLE',
  SEND_PENDING = 'users/SEND_PENDING',
  SEND_REJECTED = 'users/SEND_REJECTED',
  SEND_FULFILLED = 'users/SEND_FULFILLED',
}

export type CurrentRole = {
  role?: string;
  eventId?: string;
  id?: string;
};

export type Action =
  | {
      type: ActionTypes.SET_CURRENT_ROLE;
      payload: CurrentRole;
    }
  | {
      type: ActionTypes.UNSET_CURRENT_ROLE;
    }
  | {
      type: ActionTypes.SEND_PENDING;
    }
  | {
      type: ActionTypes.SEND_FULFILLED;
    }
  | {
      type: ActionTypes.SEND_REJECTED;
    };

export const setCurrentRole = (payload: CurrentRole): Action => ({
  type: ActionTypes.SET_CURRENT_ROLE,
  payload,
});

export const unsetCurrentRole = (): Action => ({
  type: ActionTypes.UNSET_CURRENT_ROLE,
});

export const send = (supportFormData: api.SupportFormData) => {
  return {
    [RSAA]: {
      ...api.send(supportFormData),
      types: [ActionTypes.SEND_PENDING, ActionTypes.SEND_FULFILLED, ActionTypes.SEND_REJECTED],
    },
  };
};
