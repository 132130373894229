import cx from 'classnames';
import { HeatmapWithLoading } from 'components/RubricScoring/Heatmap';
import ScoresWithLoading from 'components/Scores';
import Title from 'components/Title';
import { getEvent, getPhase, getTeamById } from 'data/reducers';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import Styles from './ResultPage.module.scss';
import { isTeamLead } from 'utils';

const ResultPage = ({
  rubric,
  evaluation,
  phaseSummary,
  selfScore,
  scores,
  phase,
  query,
  params,
  event,
}) => {
  const isUnredacted = 'unredacted' in query;
  const displayDetails = 'display_details' in query;
  const judgePanel = 'judge_panel' in query;
  const isTeamLeadAndQualitativeMode =
    event.get('judging_ux_mode') === 'qualitative_only_mode' && isTeamLead();

  return (
    <>
      {!isTeamLeadAndQualitativeMode &&
      ((phase && (isUnredacted || phase.get('performance_enabled')) && !judgePanel) ||
        (judgePanel && !isUnredacted)) ? (
        <div>
          <div className={Styles.wrapper}>
            <Title component="h4">Quantitative Feedback</Title>
          </div>
          <ScoresWithLoading
            displayDetails={displayDetails}
            rubric={rubric}
            evaluation={evaluation}
            phaseSummary={phaseSummary}
            selfScore={selfScore}
            selectedScore={null}
          />
        </div>
      ) : null}

      <div
        className={cx(Styles.wrapper, {
          [Styles.wrapperBreak]: phase?.get('performance_enabled'),
        })}
      >
        <Title component="h4">Qualitative Feedback</Title>
      </div>

      <HeatmapWithLoading
        rubric={rubric}
        evaluation={evaluation}
        scores={scores}
        selfScore={selfScore}
      />
    </>
  );
};

ResultPage.displayName = 'ResultPage';

export default withRouter(
  connect((state, ownProps) => {
    const team = getTeamById(state, ownProps.selectedTeamId);
    const phase = getPhase(state, ownProps.selectedPhaseId);
    return {
      event: getEvent(state, ownProps.params.event_id),
      teamName: team && team.get('name'),
      phase,
    };
  })(ResultPage),
);
