import { Button } from '@valid-eval/shared-react-components';
import { getEnvVar } from 'config';
import { declineInvitationWithToken } from 'data/actions/judgeInvitations';
import { error as notifyError } from 'data/actions/notifications';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from 'routes/withRouter';
import { getVersionUri } from 'utils';
import Briefcase from './assets/briefcase.png';
import ErrorBoundary from './ErrorBoundary';

const DeclineInvitation = ({ params, query, navigate }) => {
  const dispatch = useDispatch();

  const handleConfirmClick = async () => {
    const { error, payload } = await dispatch(
      declineInvitationWithToken(
        params.event_id,
        params.invitation_id,
        query.notification_id,
        query.token,
      ),
    );
    if (error)
      dispatch(
        notifyError(
          payload?.response?.error ||
            `Failed to decline invitation, please contact ${getEnvVar(
              'REACT_APP_SUPPORT_EMAIL',
              'support@valideval.com',
            )} for assistance`,
          'Error',
        ),
      );
    else
      navigate(
        `/events/${params.event_id}/judge_invitations/${params.invitation_id}/decline-success`,
      );
  };

  return (
    <ErrorBoundary>
      <div className="container">
        <div className="login-container mb-5">
          <h1 className="login-title mt-3 mb-3">Decline invitation</h1>
          <div className="login-box d-flex flex-column">
            <h2 className="login-subtitle mb-4">{getVersionUri()}</h2>
            <div style={{ flex: 1 }}>
              <p className="text-center">Please confirm you want to decline this invitation.</p>
              <div className="mt-5 d-grid">
                <Button variant="success" onClick={handleConfirmClick}>
                  Confirm
                </Button>
              </div>
              <div className="mt-4 d-grid">
                <Link to="/" className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </div>
            <div className="text-center mt-4 mb-2">
              <img src={Briefcase} className="login-footer-image" alt="Briefcase little icon" />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

DeclineInvitation.displayName = 'DeclineInvitation';

export default withRouter(DeclineInvitation);
