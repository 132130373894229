import { linkedinParamSeparator } from 'config';
import {
  error as notifyErrorAction,
  success as notifySuccessAction,
} from 'data/actions/notifications';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form/immutable';
import withRouter from 'routes/withRouter';
import { existingTeamSignUp } from 'utils/urls';
import Errors from '../components/Errors';
import Loading from '../components/Loading';
import { linkedInStoreProfile, load, loginWithLinkedIn } from '../data/actions/users';
import { getCurrentUser, getLoginError } from '../data/reducers';
import ErrorBoundary from './ErrorBoundary';

const throwOnError = (action) => {
  if (action.error) {
    const errorMessage = action.payload.response.error;
    throw new SubmissionError({ _error: errorMessage });
  }
};

class LinkedIn extends Component {
  state = {
    linkedin: {
      error: null,
    },
  };

  componentDidMount() {
    const { code, state, error, error_description } = this.props.query;
    const { user, notifySuccess, notifyError } = this.props;

    let type;

    try {
      type = state.split(linkedinParamSeparator)[0];
    } catch (error) {
      console.error(error);
      return;
    }

    if (type === 'STORE_PROFILE') {
      if (error) {
        if (error === 'user_cancelled_login') {
          notifyError('You have canceled the LinkedIn login.');
          this.navigateToAccount();
        } else {
          this.setState({ linkedin: { error: error_description } });
        }

        return;
      }

      this.props
        .linkedInStoreProfile(user.get('id'), code, state)
        .then((action) => {
          if (action.error) {
            this.setState({
              linkedin: {
                error:
                  action.payload.status === 500
                    ? 'There was a problem getting your LinkedIn Account information'
                    : action.payload.response.error,
              },
            });
            throw new Error(action.payload.response.error);
          }
        })
        .then(this.fetchLoggedInUser)
        .then(this.navigateToAccount)
        .then(() => notifySuccess('LinkedIn Account associated!'))

        .catch(console.error);

      return;
    }

    this.props
      .loginWithLinkedIn(code, state)
      .then(throwOnError)
      .then(this.fetchLoggedInUser)
      .then(
        ({
          payload: {
            entities: { users },
          },
        }) => {
          const user = users[Object.keys(users)[0]];
          if (state.split(linkedinParamSeparator)[2]) {
            // Query params as JSON
            const { slug, email, first_name, last_name } = JSON.parse(
              decodeURIComponent(state.split(linkedinParamSeparator)[2]),
            );

            let redirectUrl;
            if (user.user_emails.find((e) => e.email === email)) {
              redirectUrl = existingTeamSignUp(slug, email, first_name, last_name);
            } else {
              redirectUrl = existingTeamSignUp(slug, user.email, user.first_name, user.last_name);
            }

            return this.props.navigate(redirectUrl);
          }

          this.navigateToApp();
        },
      )

      .catch(console.warn);
  }

  fetchLoggedInUser = () => this.props.load('me');
  navigateToApp = () => this.props.navigate();
  navigateToAccount = () => this.props.navigate('/app/account');

  render() {
    const { loginError } = this.props;

    if (loginError) {
      return (
        <div>
          <Errors
            errors={[loginError]}
            alertClasses={{ alert: 'alert alert-danger alert-inline' }}
          />
          <span>
            Please try to <Link to="/">login</Link> again
          </span>
        </div>
      );
    }

    if (this.state.linkedin.error) {
      return (
        <ErrorBoundary>
          <div>
            <Errors
              errors={[this.state.linkedin.error]}
              alertClasses={{ alert: 'alert alert-danger alert-inline' }}
            />
            Back to <Link to="/app/account">My Account & Profiles</Link>
          </div>
        </ErrorBoundary>
      );
    }

    return <Loading />;
  }
}

export default withRouter(
  connect(
    (state) => ({
      loginError: getLoginError(state),
      user: getCurrentUser(state),
    }),
    {
      loginWithLinkedIn,
      load,
      linkedInStoreProfile,
      notifySuccess: notifySuccessAction,
      notifyError: notifyErrorAction,
    },
  )(LinkedIn),
);
