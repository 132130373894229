import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import * as CommonTableCells from 'components/Tables/CommonTableCells';
import * as CommonTableHeaders from 'components/Tables/CommonTableHeaders';
import { sortStrings } from 'components/Tables/sortingHelpers';
import { Team } from '../../../shared/types';

const columnHelper = createColumnHelper<Team>();

export const matchColumns: ColumnDef<Team, any>[] = [
  columnHelper.accessor('panel', {
    id: 'Panel',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.SmallText,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('prefix', {
    id: 'Prefix',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: (props) => <CommonTableCells.SmallText className="text-left" {...props} />,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('name', {
    id: 'Name',
    header: (props) => <CommonTableHeaders.SortableHeader className="text-left" {...props} />,
    cell: CommonTableCells.ProfileLink,
    sortingFn: sortStrings,
  }),
  columnHelper.accessor('assignedRolesCount', {
    id: '# Judges',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.Text,
  }),
  columnHelper.accessor('assignedRoles', {
    id: 'Judges',
    header: (props) => <CommonTableHeaders.SimpleHeader className="text-left" {...props} />,
    cell: CommonTableCells.MatchedEntities,
  }),
  columnHelper.accessor('matchQuality', {
    id: 'Match Quality',
    header: CommonTableHeaders.SortableHeader,
    cell: CommonTableCells.NumberOrZero,
  }),
  columnHelper.accessor('id', {
    id: 'Actions',
    header: CommonTableHeaders.SimpleHeader,
    cell: CommonTableCells.MatchActions,
  }),
];
