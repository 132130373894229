import { Col, Row } from '@valid-eval/shared-react-components';
import cx from 'classnames';

import SearchHelpTooltip from 'components/SearchHelpTooltip';
import Search from 'images/icons/search.png';

import Styles from './Triage.module.scss';

type TriageModalTableControlsProps = {
  onTextFilterChange: (filter: string) => void;
};

const TriageModalTableControls = ({ onTextFilterChange }: TriageModalTableControlsProps) => {
  const placeholder = `Search`;

  return (
    <Row className={'mt-4 mb-2'}>
      <Col xs={12} className="d-flex align-items-center">
        <div className={cx('mb-0 me-3', Styles.InputWrapper)}>
          <input
            className={cx('form-control', Styles.SearchInput)}
            placeholder={placeholder}
            onChange={(e) => onTextFilterChange(e.target.value)}
          />
          <img className={Styles.SearchInputIcon} src={Search} alt={placeholder} />
        </div>
        <div className="pe-1">
          <SearchHelpTooltip />
        </div>
      </Col>
    </Row>
  );
};

export default TriageModalTableControls;
