import { Row } from '@valid-eval/shared-react-components';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { getSelectedPhase } from 'data/reducers';
import withRouter from 'routes/withRouter';
import ActiveFilters from 'screens/app/events/components/filters/ActiveFilters';
import FiltersDropdown from 'screens/app/events/components/filters/FiltersDropdown';
import TextFilter from 'screens/app/events/components/filters/TextFilter';

import { Team } from '../../../shared/types';
import { searchableFields } from './helpers';

type AvailableFilter = {
  title: string;
  field: string;
  type: string;
  options: { label: string; value: boolean | string | number | null }[];
};

const AVAILABLE_FILTERS: AvailableFilter[] = [
  {
    title: 'Decision Status',
    field: 'status',
    type: 'checkbox',
    options: [],
  },
  {
    title: 'Scoring Status',
    field: 'status',
    type: 'checkbox',
    options: [
      {
        label: 'Judging',
        value: 'Judging',
      },
      {
        label: 'Unmatched',
        value: 'Unmatched',
      },
    ],
  },
  {
    title: '',
    field: 'progress',
    type: 'checkbox',
    options: [
      {
        label: 'Has Incomplete Scores',
        value: 'incomplete',
      },
    ],
  },
  {
    title: 'Feedback Status',
    field: 'viewed',
    type: 'checkbox',
    options: [
      {
        label: 'Feedback Viewed',
        value: true,
      },
      {
        label: 'Feedback Not Viewed',
        value: false,
      },
    ],
  },
];

type OwnProps = {
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  data: Team[];
  textFilter: string;
};

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  phase: getSelectedPhase(state, ownProps)?.toJS?.(),
});

const connector = connect(mapStateToProps, null);

type ControlsProps = OwnProps & ConnectedProps<typeof connector>;

const Controls = ({
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  data,
  textFilter,
  phase,
}: ControlsProps) => {
  const { t } = useTranslation();

  const filtered = data?.filter((row) => filterByTextFilter(textFilter, row, searchableFields));

  const availableFilters = useMemo(() => {
    const availableFilters = cloneDeep(AVAILABLE_FILTERS);

    // Status filters
    availableFilters[0].options = Object.keys(phase?.status_framework || {}).reduce<
      { label: string; value: number | null }[]
    >((acc, key) => {
      const status = phase.status_framework[key];
      if (status.active) {
        const count = filtered.reduce<number>((count, row) => {
          if (row.status === status.label) {
            count++;
          }
          return count;
        }, 0);
        acc.push({
          label: `${status.label} (${count})`,
          value: status.label ?? undefined,
        });
      }
      return acc;
    }, []);

    // Other filters
    const statusCount = filtered.reduce(
      (count, row) => {
        if (row.missingScores > 0) count.incomplete += 1;
        if (row.viewed) count.feedbackViewed += 1;
        if (!row.viewed) count.feedbackNotViewed += 1;
        return count;
      },
      {
        Judging: 0,
        Unmatched: 0,
        incomplete: 0,
        feedbackViewed: 0,
        feedbackNotViewed: 0,
      },
    );
    availableFilters[1].options[0].label += ` (${statusCount['Judging']})`;
    availableFilters[1].options[1].label += ` (${statusCount['Unmatched']})`;
    availableFilters[2].options[0].label += ` (${statusCount.incomplete})`;
    availableFilters[3].options[0].label += ` (${statusCount.feedbackViewed})`;
    availableFilters[3].options[1].label += ` (${statusCount.feedbackNotViewed})`;
    return availableFilters;
  }, [data, textFilter]);

  // update applied filter labels
  const allFilters = availableFilters.flatMap((filter) => filter.options);

  const updatedFilters =
    filters?.map((filter) => {
      const updatedFilter = allFilters.find((f) => f.value === filter.value);
      return { ...filter, label: updatedFilter?.label };
    }) || [];

  return (
    <Row className={'mb-1'}>
      <TextFilter
        placeholder={`Search by ${t('event.team_profile_name_label', 'Company')}`}
        search={onTextFilterChange}
      />
      <FiltersDropdown
        availableFilters={availableFilters}
        onFiltersChange={onOtherFiltersChange}
        filters={filters}
      />
      <ActiveFilters filters={updatedFilters} onFiltersChange={onOtherFiltersChange} />
    </Row>
  );
};

export default withRouter(connector(Controls));
