import EmailListComponent from '../EmailList';
import { Field } from 'redux-form/immutable';
import { Form } from '@valid-eval/shared-react-components';
/* eslint no-mixed-operators: 0 */

export const EmailListFieldGroup = ({
  input,
  meta: { dirty, error, warning, touched },
  id,
  label,
  helpText,
  values,
  ...extraProps
}) => (
  <Form.Group>
    <Form.Label>
      <b>{label}</b>
    </Form.Label>
    <EmailListComponent {...input} {...extraProps} />
    {helpText && <p className="autoSize mb-2 text-pre-wrap d-print-none">{helpText}</p>}
    <Form.Control.Feedback type="invalid">
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </Form.Control.Feedback>
  </Form.Group>
);

export const EmailList = ({ id, name, title, helpText, value, ...extraProps }) => (
  <Field
    id={id || name}
    name={name}
    label={title}
    helpText={helpText}
    component={EmailListFieldGroup}
    value={value}
    {...extraProps}
  />
);
