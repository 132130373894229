import Content from 'components/Content';
import {
  Checkbox,
  CheckboxList,
  DatePicker,
  Email,
  FileUpload,
  Input,
  Textarea as LongText,
  Number,
  RadioList,
  Select,
  TaxID,
  Url,
  ZipCode,
} from 'components/FormFields';
import {
  sanitizeLongText,
  sanitizeText,
  text as textValidation,
  wholeNumber,
} from 'components/FormFields/validations';
import { Map } from 'immutable';
import { dynamicFieldValidation, formatWholeNumber } from './FormFields/utils';

const optionsFromChildren = (options) =>
  options.map((item) => Map({ value: item.get('id'), label: item.get('name') }));

const componentFromData = (data, disabled) => {
  const componentType = data.get('field_type');
  const required = data.get('required');
  const helpText = data.get('instruction_text');
  const name = data.get('name');
  const title = data.get('title');
  const min = data.get('min_length') && parseInt(data.get('min_length'), 10);
  const max = data.get('max_length') && parseInt(data.get('max_length'), 10);
  const minValue = data.get('min_value') && parseInt(data.get('min_value'), 10);
  const maxValue = data.get('max_value') && parseInt(data.get('max_value'), 10);

  const props = {
    name,
    title,
    required,
    helpText,
    min,
    max,
    minValue,
    maxValue,
    disabled,
  };

  switch (componentType) {
    case 'checkbox_group':
      return (
        <CheckboxList
          name={name}
          key={name}
          title={title}
          helpText={helpText}
          required={required}
          disabled={disabled}
          sortOptions={data.get('sort_options')}
          values={optionsFromChildren(data.get('children'))}
        />
      );
    case 'radio_buttons':
      return <RadioList key={name} {...props} values={optionsFromChildren(data.get('children'))} />;
    case 'text':
      return (
        <Input
          key={name}
          {...props}
          normalize={sanitizeText}
          validate={[textValidation, dynamicFieldValidation]}
        />
      );
    case 'text_area':
      return (
        <LongText
          key={name}
          {...props}
          normalize={sanitizeLongText}
          validate={[textValidation, dynamicFieldValidation]}
        />
      );
    case 'read_only_content':
      return <Content key={name} html={data.get('content')} />;
    case 'single_checkbox':
      return <Checkbox key={name} {...props} />;
    case 'email':
      return <Email key={name} {...props} />;
    case 'url':
    case 'artifact_url':
      return <Url key={name} {...props} />;
    case 'artifact_file':
      return <FileUpload key={name} {...props} />;
    case 'number':
      return <Number key={name} {...props} />;
    case 'zip_code':
      return <ZipCode key={name} {...props} />;
    case 'tax_id':
      return <TaxID key={name} {...props} />;
    case 'dropdown': {
      return (
        <Select
          key={name}
          {...props}
          title={title}
          disabled={disabled}
          sortOptions={data.get('sort_options')}
          options={optionsFromChildren(data.get('children'))}
        />
      );
    }
    case 'date':
      return <DatePicker key={name} {...props} />;
    case 'whole_number':
      return (
        <Number
          key={name}
          {...props}
          format={formatWholeNumber}
          validate={[wholeNumber, dynamicFieldValidation]}
        />
      );
    case 'generated':
      return null;
    default:
      return null;
  }
};

export const renderDynamicFields = (
  fields,
  filter = (x) => x,
  disabled = false,
  applicationRevisionRequest = null,
) =>
  fields &&
  fields.filter(filter).map((field) => {
    const needRevisionFields = applicationRevisionRequest?.field_keys || [];
    const needRevision =
      needRevisionFields.includes(field.get('field_key')) ||
      needRevisionFields.includes(field.get('id'));
    return componentFromData(field, disabled && !needRevision);
  });
