import cx from 'classnames';
import PropTypes from 'prop-types';

import Search from 'images/icons/search.png';
import Styles from './TextFilter.module.scss';
import SearchHelpTooltip from 'components/SearchHelpTooltip';

function TextFilter({ placeholder, search }) {
  return (
    <>
      <div className="col-auto">
        <div className={cx('mb-0', Styles.InputWrapper)}>
          <input
            className={cx('form-control', Styles.SearchInput)}
            placeholder={placeholder}
            onChange={(e) => search(e.target.value)}
          />
          <img className={Styles.SearchInputIcon} src={Search} alt={placeholder} />
        </div>
      </div>
      <div className="col-auto d-flex align-items-center">
        <SearchHelpTooltip />
      </div>
    </>
  );
}

TextFilter.propTypes = {
  search: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextFilter;
