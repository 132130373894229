import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';

import { CommonControls } from 'components/Tables/CommonControls';
import { filterByTextFilter } from 'components/Tables/filteringHelpers';
import { Judge } from 'screens/app/events/shared/types';

import { searchableFields } from './helpers';

const AVAILABLE_FILTERS = [
  {
    title: 'Status',
    field: 'active',
    type: 'checkbox',
    options: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
  },
];

type ControlsProps = {
  filters: any[];
  onOtherFiltersChange: (filters: any[]) => void;
  onTextFilterChange: (filter: string) => void;
  data: Judge[];
  textFilter: string;
  info: string;
};

const Controls = ({
  filters,
  onOtherFiltersChange,
  onTextFilterChange,
  data,
  textFilter,
  info,
}: ControlsProps) => {
  const availableFilters = useMemo(() => {
    const statusCount = data
      ?.filter((row) => filterByTextFilter(textFilter, row, searchableFields))
      .reduce(
        (count, row) => {
          if (row.active) count.active += 1;
          else count.inactive += 1;
          return count;
        },
        {
          active: 0,
          inactive: 0,
        },
      );

    const availableFilters = cloneDeep(AVAILABLE_FILTERS);
    availableFilters[0].options[0].label = `Active (${statusCount?.active})`;
    availableFilters[0].options[1].label = `Inactive (${statusCount?.inactive})`;

    return availableFilters;
  }, [data, textFilter]);

  let appliedFilters = filters.map((item) => {
    if (item.value) {
      item.label = availableFilters[0].options[0].label;
    }
    if (!item.value) {
      item.label = availableFilters[0].options[1].label;
    }

    return item;
  });

  return (
    <CommonControls
      placeholder="Sarch by Judge Name"
      availableFilters={availableFilters}
      appliedFilters={appliedFilters}
      filters={filters}
      onOtherFiltersChange={onOtherFiltersChange}
      onTextFilterChange={onTextFilterChange}
      info={info}
    />
  );
};

export default Controls;
